import { h, t } from '@app/utils';
import { convertMetrics } from '@app/core';
import { Button, Form, Tile, Card, Avatar, Balloon, Counter } from '@app/elements';

import logoStepMan from '@app/img/register/areyou-man.png';
import logoStepManx2 from '@app/img/register/areyou-man@2x.png';

import logoStepWoman from '@app/img/register/areyou-woman.png';
import logoStepWomanx2 from '@app/img/register/areyou-woman@2x.png';

export const ModalView = (props, actions) => (
    <Card classes={'btzInformations-btzInformationsModal'} style={{ 'display': 'table', 'min-height': '500px' }}>
        {props.displayed === props.current.kind &&
            <dummy style={{ 'display': 'table', 'width': '100%' }}>
                <Card.Header classes={'btzCard-btzHeader-btzModal'} style={{ 'padding': '60px 35px 0px 35px', 'vertical-align': (props.displayed === 'activity' ? 'bottom' : 'middle') }}>
                    {((props.current !== null) && (props.current.kind !== 'none')) &&
                        <div key={props.current.kind}>
                            <p class='btzTitle btzXl' style={{ 'margin': '0 0 45px 0' }}>{t(props.current.question)}</p>

                            <Form onsubmit={event => event.preventDefault()} style={{ 'padding': '0px', 'height': 'calc(100vh - 520px)', 'display': 'table' }}>

                                {props.current.kind === 'gender' ?
                                    <div key={'information-gender'} style={{ 'display': 'table-cell' }}>
                                        <Form.Group style={{ 'white-space': 'nowrap', 'text-align': 'center' }}>
                                            <Form.Radio
                                                placeholder={t('Homme', {ns: 'generals'})}
                                                id={props.current.kind}
                                                name={props.current.kind}
                                                image={logoStepMan}
                                                imagex2={logoStepManx2}
                                                onclick={() => {
                                                    props.send({
                                                        ns: 'gender',
                                                        key: 'value',
                                                        value: true
                                                    })
                                                    props.send({
                                                        ns: 'gender',
                                                        key: 'isValid',
                                                        value: true
                                                    })
                                                    props.updateCurrent(props.current.kind);
                                                    props.onSubmit(props.current.kind)
                                                }}
                                                required
                                                checked={props.current.value == true}
                                            />
                                            <Form.Radio
                                                placeholder={t('Femme', {ns: 'generals'})}
                                                id={props.current.kind}
                                                name={props.current.kind}
                                                image={logoStepWoman}
                                                imagex2={logoStepWomanx2}
                                                onclick={() => {
                                                    props.send({
                                                        ns: 'gender',
                                                        key: 'value',
                                                        value: false
                                                    })
                                                    props.send({
                                                        ns: 'gender',
                                                        key: 'isValid',
                                                        value: true
                                                    })
                                                    props.updateCurrent(props.current.kind);
                                                    props.onSubmit(props.current.kind);
                                                }}
                                                required
                                                checked={props.current.value == false}
                                            />
                                        </Form.Group>
                                    </div>
                                    :
                                    <div style={{ 'display': 'table-cell', 'vertical-align': (props.displayed !== 'activity' ? 'middle': 'bottom') }}>

                                        {props.current.kind.indexOf('date') > -1 ?
                                            <div data-type={props.current.type}>
                                                <Form.Group>
                                                    <Form.Input
                                                        classes={'btzFullWidth'}
                                                        placeholderDay={(t('jj', {ns: 'generals'}))}
                                                        placeholderMonth={(t('mm', {ns: 'generals'}))}
                                                        placeholderYear={(t('aaaa', {ns: 'generals'}))}
                                                        type={props.current.type}
                                                        id={props.current.kind}
                                                        name={props.current.kind}
                                                        value={props.current.value}
                                                        oninput={props.waitForLastInput}
                                                        onblur={props.onBlur}
                                                        error={(props.current.isTouched.year && props.current.isTouched.month && props.current.isTouched.day) && (!props.current.isValid.year && !props.current.isValid.month && !props.current.isValid.day)}
                                                        classes={(props.current.isTouched.year && props.current.isTouched.month && props.current.isTouched.day) ? ((props.current.isValid.year && props.current.isValid.month && props.current.isValid.day) ? 'is-success' : 'is-error') : ''}
                                                    />
                                                </Form.Group>
                                            </div>
                                            :
                                            <div data-type={props.current.type}>
                                                {props.current.type === 'dropdown' ?
                                                    <Form.Group>
                                                        <Form.Listing
                                                            classes={'btzFullWidth'}
                                                            type={props.current.type}
                                                            id={props.current.kind}
                                                            name={props.current.kind}
                                                            value={props.current.value}
                                                            listing={props.listing}
                                                            withsearch={props.withsearch}
                                                            placeholder={t(props.placeholder)}
                                                            onclick={(event) => {
                                                                props.send({
                                                                    ns: 'current',
                                                                    key: 'value',
                                                                    value: event.currentTarget.getAttribute('data-value')
                                                                });
                                                                props.send({
                                                                    ns: 'current',
                                                                    key: 'isValid',
                                                                    value: true
                                                                });
                                                                props.onSubmit(props.current.kind);
                                                            }}
                                                            error={props.current.isTouched && !props.current.isValid}
                                                            classes={(props.current.isTouched) ? (props.current.isValid ? 'is-success' : 'is-error') : ''}
                                                        />
                                                    </Form.Group>
                                                    :
                                                    <div>
                                                        {props.current.type === 'counter' ?
                                                            <div class='btzOnboarding-btzButtonsWrapper btzOnboarding-btzButtonsWrapper-number'>
                                                                <Counter id={props.current.kind} placeholder={props.current.placeholder} count={(props.current.value === '' ? '' : convertMetrics(props.displayed, '', props.metric, +props.current.value))} name={props.current.kind} key={props.current.kind} class='btzCurrentEvaluationMarker' min={0} max={1000} kind='number' tag={props.current.kind} label={props.current.questionLabel} onfocus={props.onFocus} oninput={props.waitForLastInput} initialCount={props.current.placeholder}></Counter>
                                                            </div>
                                                            :
                                                            <Form.Group>
                                                                <Form.Input
                                                                    classes={'btzFullWidth'}
                                                                    placeholder={t(props.current.placeholder)}
                                                                    type={props.current.type}
                                                                    id={props.current.kind}
                                                                    name={props.current.kind}
                                                                    value={props.current.value}
                                                                    onfocus={props.onFocus}
                                                                    oninput={props.waitForLastInput}
                                                                    error={props.current.isTouched && !props.current.isValid}
                                                                    classes={(props.current.isTouched) ? (props.current.isValid ? 'is-success' : 'is-error') : ''}
                                                                />
                                                            </Form.Group>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        }

                                        {props.current.kind === 'email' &&
                                            <Form.Group>
                                                <Form.Input
                                                    classes={'btzFullWidth'}
                                                    placeholder={'••••••••'}
                                                    type={'password'}
                                                    id={'passwordemail'}
                                                    name={'passwordemail'}
                                                    onfocus={props.onFocus}
                                                    oninput={props.waitForLastInput}
                                                    error={props.passwordemail.isTouched && !props.passwordemail.isValid}
                                                    classes={(props.passwordemail.isTouched) ? (props.passwordemail.isValid ? 'is-success' : 'is-error') : ''}
                                                />
                                            </Form.Group>
                                        }

                                        {props.current.kind === 'password' &&
                                            <div data-type={'password'}>
                                                <Form.Group>
                                                    <Form.Input
                                                        classes={'btzFullWidth'}
                                                        placeholder={t(props.newpassword.placeholder)}
                                                        type={'password'}
                                                        id={'newpassword'}
                                                        name={'newpassword'}
                                                        onfocus={props.onFocus}
                                                        oninput={props.waitForLastInput}
                                                        error={props.newpassword.isTouched && !props.newpassword.isValid}
                                                        classes={(props.newpassword.isTouched) ? (props.newpassword.isValid ? 'is-success' : 'is-error') : ''}
                                                    />
                                                </Form.Group>
                                            </div>
                                        }

                                        {((props.displayed !== 'activity') && (props.displayed !== 'gender')) &&
                                            <Form.Group classes='btzForm-btzFooter' style={{ 'bottom': '0px', 'padding-bottom': '30px' }}>
                                                <Button primary flat active={((props.current.kind.indexOf('date') === -1) && props.current.isValid) || ((props.current.kind.indexOf('date') > -1) && (props.current.isValid.year && props.current.isValid.month && props.current.isValid.day))} onclick={() => {
                                                    props.onSubmit(props.current.kind);
                                                }}>
                                                    {t('Enregistrer', {ns: 'generals'})}
                                                </Button>
                                            </Form.Group>
                                        }
                                    </div>
                                }
                            </Form>

                            {props.current.type !== 'dropdown' &&
                                <Tile classes={'btzAlfred btzAlfred-btzFormComputation btzAlfred-' + props.current.kind} style={{ 'white-space': 'nowrap' }}>
                                    <Tile.Icon>
                                        <Avatar.Alfred classes={'centered'} />
                                    </Tile.Icon>
                                    <Tile.Content>
                                        <Balloon kind={props.current.api.kind} style={{ 'white-space': 'normal' }}>
                                            {t(props.current.api.message)}
                                        </Balloon>
                                    </Tile.Content>
                                </Tile>
                            }
                        </div>
                    }
                </Card.Header>

                {props.displayed === 'activity' &&
                    <dummy style={{ 'display': 'table-row' }}>
                        <div class='btzFood-btzActivity-btzNotes'>
                            <div class='btzPageContentTitle-smallhr'></div>
                            <p class='btzPageContentTitle'>{t('Note', {ns: 'food'}) + ' :'}</p>
                            <div class='btzFood-btzActivity-btzNotes-btzNote'>
                                <p class='btzAboveSubTitle'>*{t('Inactif', {ns: 'food'}) + ' :'}</p>
                                <p class='btzPageContentSubTexte'>{' ' + t('peu d‘activité de loisir, travail assis', {ns: 'food'})}</p>
                            </div>
                            <div class='btzFood-btzActivity-btzNotes-btzNote'>
                                <p class='btzAboveSubTitle'>*{t('Activité modérée', {ns: 'food'}) + ' :'}</p>
                                <p class='btzPageContentSubTexte'>{' ' + t('1 à 3h de sport par semaine ou travail debout', {ns: 'food'})}</p>
                            </div>
                            <div class='btzFood-btzActivity-btzNotes-btzNote'>
                                <p class='btzAboveSubTitle'>*{t('Activité importante', {ns: 'food'}) + ' :'}</p>
                                <p class='btzPageContentSubTexte'>{' ' + t('plus de 3h de sport par semaine ou travail intense', {ns: 'food'})}</p>
                            </div>
                            <div class='btzFood-btzActivity-btzNotes-btzNote'>
                                <p class='btzAboveSubTitle'>*{t('Activité très importante', {ns: 'food'}) + ' :'}</p>
                                <p class='btzPageContentSubTexte'>{' ' + t('plus de 6 heures de sport par semaine', {ns: 'food'})}</p>
                            </div>
                        </div>
                    </dummy>
                }
            </dummy>
        }
    </Card>
);