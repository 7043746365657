import { isLength, debounce } from '@app/utils';
import { User } from '@app/api';

export const actions = {
    onComponentCreate: (props) => (state, actions) => {
        if (props.initial) {

        }
    },

    setState: ({ ns, key, value }) => (state, actions) => ({
        [ns]: { ...state[ns], ...{ [key]: value } }
    }),

    setEntrepriseValue: (string) => (state, actions) => actions.setState({
        ns: 'entreprise',
        key: 'value',
        value: string
    }),

    setEntrepriseTouched: (boolean) => (state, actions) => actions.setState({
        ns: 'entreprise',
        key: 'isTouched',
        value: boolean
    }),

    setEntrepriseValidity: (boolean) => (state, actions) => actions.setState({
        ns: 'entreprise',
        key: 'isValid',
        value: boolean
    }),

    setEntrepriseApproval: (bool) => (state, actions) => actions.setState({
        ns: 'entreprise',
        key: 'isApproved',
        value: bool
    }),

    setEntrepriseId: (object) => (state, actions) => actions.setState({
        ns: 'entreprise',
        key: 'id',
        value: object
    }),

    setApiStatus: number => (state, actions) => actions.setState({
        ns: 'api',
        key: 'status',
        value: number
    }),

    setApiMessage: string => (state, actions) => actions.setState({
        ns: 'api',
        key: 'message',
        value: string
    }),

    onEntrepriseInputUpdate: ({string, props}) => (state, actions) => {
        actions.setEntrepriseValue(string)

        if (string) {
            User.checkInvitationCode(string)
                .then(({ data }) => {
                    if (data.id) {
                        actions.setEntrepriseValidity(isLength(string, { min: 1 }));
                        actions.setEntrepriseApproval(true);
                        actions.setEntrepriseTouched(true);
                        actions.setEntrepriseId(data.id);

                        props.send({
                            ns: 'user',
                            key: 'company',
                            value: string
                        });

                        props.send({
                            ns: 'user',
                            key: 'companyId',
                            value: data.id
                        });
                    }
                })
        }
    },

    onEntrepriseInputDebouncedUpdate: ({string, props}) => (state, actions) => debounce(
        1200,
        'onEntrepriseInputUpdate',
        {string : string, props : props}
    ),

    onEntrepriseInputTouched: () => (state, actions) => {
        if (!state.entreprise.isTouched) {
            actions.setEntrepriseTouched(true)
        }
    },

    updateParentProps: (props) => (state, actions) => {
        if (props) {
            props.send({
                ns: 'user',
                key: 'company',
                value: state.entreprise.value
            })

            props.send({
                ns: 'user',
                key: 'companyId',
                value: state.entreprise.id
            })
        }
    },

    goToNextStep: () => (state, actions) => {
        window.main.location.go('/home/register/objectives');
    },

    onStepSubmit: event => (state, actions) => {
        event.preventDefault()

        if (state.entreprise.isTouched && state.entreprise.value.length === 0) {
            actions.setApiMessage('CompanyCodeNoEntry')
            return false
        }

        if (state.entreprise.isTouched
            && state.entreprise.value.length > 0
            && !state.entreprise.isApproved
        ) {
            actions.setApiMessage('CompanyCodeNotValid')
            return false
        }

        if (state.entreprise.isApproved) {
            actions.goToNextStep()
        }
    },
}
