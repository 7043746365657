import { addCSRFToken, compareByKey, isDefined, deepCopy } from '@app/core';
import { allRoutes } from '@app/settings';
import { Programs, Objectives } from '@app/api';
import { t, h, Component } from '@app/utils';
import { Button } from '@app/elements';
import './index.scss';
import * as constants from '@app/constants';

const state = {
    all: [],
    filter: false,
    filterByObjctive: false,
    filterObjectives: [],
    filters: constants.custo.ARRTHEMES,
    filtering: [],
    userThemes: [],
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        console.log('OBJ : ', props);
        if (props.customer === 'klesia_netflix') {
            let storedLanguage = localStorage.getItem('language');
            Objectives.getObjectives(storedLanguage, 'klesianetflix').then((res) => {
                actions.setFilterByObjective(true);
                actions.setFilterObjectives(res.data);
                getRecommanded();
                console.log('OBJ : ', res);
            });
        } else {
            getRecommanded();
        }
        //
        function getRecommanded() {
            if (isDefined(props.displayedon) && (props.displayedon === 'preview')) {
                let program = props;
                program.program = deepCopy(program);
                parseRecommanded([program]);
            } else {
                Programs.getRecommanded().then(function(res) {
                    parseRecommanded(res.data);
                });
            }
        }
        //
        function parseRecommanded(resData) {
            if (!isDefined(resData)) {
                return false;
            }
            console.log('OBJ : parseRecommanded : ', resData);
            var all = [], card =  null;
            let parsedUserThemes = [];
            for (var reco in resData) {
                card = resData[reco];
                var cardTheme = actions.getTheme(+card.theme);

                card.valueTheme = cardTheme.value;
                card.labelTheme = cardTheme.label;
                card.order = cardTheme.order;

                if (parsedUserThemes.indexOf(card.theme) === -1) {
                    parsedUserThemes.push(card.theme);
                }
                all.push(card);
            }
            all.sort(compareByKey('order'));
            actions.setAllPrograms(all);

            parsedUserThemes.push(0); // all theme
            actions.setUserThemes(parsedUserThemes);
        }
    },
    setAllPrograms: newState => state => ({
        all: newState
    }),
    beginTheseProgram: (programid) => (state, actions) => {
        window.location.href = addCSRFToken(allRoutes['private.program.start'].pathname.replace(':programid', programid));
    },
    getTheme: (intTheme) => {
        for (var _t in constants.custo.THEMES) {
            if (constants.custo.THEMES[_t].key === intTheme) {
                return constants.custo.THEMES[_t];
            }
        }
        return {value : '', label : '', order : 0};
    },
    onFilter: () => state => ({
        filter: !state.filter,
    }),
    addFilter: (idTheme) => (state, actions) => {
        if (idTheme == '0') {
            actions.setFiltering([]);
        } else {
            let filtering = state.filtering;
            if (filtering.indexOf(idTheme) > -1) {
                filtering.splice(filtering.indexOf(idTheme), 1);
            } else {
                filtering.push(idTheme);
            }
            actions.setFiltering(filtering);
        }
    },
    setFilterByObjective: newState => state => ({
        filterByObjctive: newState,
    }),
    setFilterObjectives: newState => state => ({
        filterObjectives: newState,
    }),
    setFiltering: newState => state => ({
        filtering: newState,
    }),
    setUserThemes: newState => state => ({
        userThemes: newState,
    }),
};

const view = (state, actions) => (props, children) => (
    <div id={'allPrograms'} class='btzSimplePageBlock btzSimplePageBlock-btzSimplePageBlockNoPadding' style={{ 'padding-bottom': '25px' }}>
        <p class='btzTitle btzXl'>{t('Tous les programmes', {ns: 'programs'})}</p>
        <div class='col-12 p-0'>
            {props.customer === 'cerba' ?
                <p class='col-10 btzSubTitle' style={{ 'vertical-align': 'middle' }}>{t('Découvrez ci-dessous la liste de toutes les activités que nous vous proposons pour atteindre vos objectifs.', {ns: 'programs'})}</p>
                :
                <p class='col-10 btzSubTitle' style={{ 'vertical-align': 'middle' }}>{t('Vous pouvez aussi accéder à tous nos programmes, qu’ils soient recommandés pour vos objectifs ou simplement selon la thématique qui vous intéresse.', {ns: 'programs'})}</p>
            }
            {props.customer !== 'cerba' &&
                <div class={'col-2 btzProgram-btzProgramFilter'} style={{ 'vertical-align': 'middle' }}>
                    <Button flat active primary onclick={actions.onFilter}>{t('Filtrer', {ns: 'programs'})}</Button>
                </div>
            }
        </div>

        <div class={'btzProgram-btzProgramPreviousStep-btzWrapper ' + (state.filter ? 'active' : '')}>
            {state.filters.filter((filt) => state.userThemes.indexOf(filt.key) > -1).map(filt =>
                <Button flat active={state.filtering.indexOf(filt.key) > -1} onclick={() => {
                    actions.addFilter(filt.key)}}
                >
                    {filt.label}
                </Button>
            )}
        </div>

        {state.all.length == 0 ?
            <div style={{ 'padding': '0 40px' }}>
                <div class='col-4 col-md-6 col-sm-12'>
                    <div class='btzLoadingContainer-btzWrapper'>
                        <div class='btzLoadingContainer'></div>
                    </div>
                </div>
                <div class='col-4 col-md-6 col-sm-12'>
                    <div class='btzLoadingContainer-btzWrapper'>
                        <div class='btzLoadingContainer'></div>
                    </div>
                </div>
                <div class='col-4 col-md-6 col-sm-12'>
                    <div class='btzLoadingContainer-btzWrapper'>
                        <div class='btzLoadingContainer'></div>
                    </div>
                </div>
            </div>
            :
            <dummy>
                {(state.filterByObjctive === true) ?
                    <dummy>
                        {state.filterObjectives.map((obj) =>
                            <div style={{ 'text-align': 'left' }}>
                                <div class='btzBackgroundColored'><img class='' src={obj.media.url} alt='' /><p class=''>{obj.title}</p></div>
                                <div style={{ 'padding': '0 40px', 'text-align': 'left' }}>
                                    {state.all.filter((f) => isDefined(f.program.objectives.filter((o) => obj.id === o.id)[0])).map(item =>
                                        <div class='col-4 col-md-6 col-sm-12' style={{ 'margin-bottom': '40px', 'vertical-align': 'top', 'display': (state.filtering.length > 0 ? ((state.filtering.indexOf(item.theme) > -1) ? 'inline-block' : 'none') : 'inline-block') }}>
                                            <div key={item.id} class='btzProgram-btzProgramAll-btzProgramAllWrapper' onclick={() => { if ((isDefined(props.displayedon) && (props.displayedon === 'preview')) || item.program.inProgress || item.program.alreadyDone) { return false; } else { actions.beginTheseProgram(item.program.id); } }}>
                                                <div class={'btzBackgroundCover ' + (item.program.new ? 'btzProgramAll-btzNewProgram' : (item.program.alreadyDone ? 'btzProgramAll-btzAlreadyDoneProgram' : (item.program.inProgress ? 'btzProgramAll-btzRunningProgram' : '')))} data-new={t('nouveau', {ns: 'programs'})} data-alreadydone={t('déjà fait', {ns: 'programs'})} data-running={t('en cours', {ns: 'programs'})} style={{ 'background-image': 'url(' + (((item.media !== null) && (item.media !== undefined) && (item.media.url !== undefined)) ? item.media.url : '') + ')' }}></div>

                                                <div class='btzProgramsAll-btzProgramAll'>
                                                    <p class='btzPageContentTexte'>{item.program.title}</p>
                                                    <p class='btzPageContentSubTitle btzTextColored' data-theme={item.valueTheme}>{t(item.labelTheme)}</p>
                                                    <p class='btzPageContentSubTexte'>{t('Durée', {ns: 'programs'})} : {item.program.duration}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </dummy>
                    :
                    <div style={{ 'padding': '0 40px', 'text-align': 'left' }}>
                        {state.all.map(item =>
                            <div class='col-4 col-md-6 col-sm-12' style={{ 'margin-bottom': '40px', 'vertical-align': 'top', 'display': (state.filtering.length > 0 ? ((state.filtering.indexOf(item.theme) > -1) ? 'inline-block' : 'none') : 'inline-block') }}>
                                <div key={item.id} class='btzProgram-btzProgramAll-btzProgramAllWrapper' onclick={() => { if ((isDefined(props.displayedon) && (props.displayedon === 'preview')) || item.program.inProgress || item.program.alreadyDone) { return false; } else { actions.beginTheseProgram(item.program.id); } }}>
                                    <div class={'btzBackgroundCover ' + (item.program.new ? 'btzProgramAll-btzNewProgram' : (item.program.alreadyDone ? 'btzProgramAll-btzAlreadyDoneProgram' : (item.program.inProgress ? 'btzProgramAll-btzRunningProgram' : '')))} data-new={t('nouveau', {ns: 'programs'})} data-alreadydone={t('déjà fait', {ns: 'programs'})} data-running={t('en cours', {ns: 'programs'})} style={{ 'background-image': 'url(' + (((item.media !== null) && (item.media !== undefined) && (item.media.url !== undefined)) ? item.media.url : '') + ')' }}></div>

                                    <div class='btzProgramsAll-btzProgramAll'>
                                        <p class='btzPageContentTexte'>{item.program.title}</p>
                                        <p class='btzPageContentSubTitle btzTextColored' data-theme={item.valueTheme}>{t(item.labelTheme)}</p>
                                        <p class='btzPageContentSubTexte'>{t('Durée', {ns: 'programs'})} : {item.program.duration}</p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                }
            </dummy>
        }
    </div>
);

export default Component(state, actions, view, 'allPrograms');
