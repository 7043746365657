import { t, h } from '@app/utils';
import { Button, Card, Modal, Social, Media, Convert } from '@app/elements';

import imgCoachWord from './cerba/coach-word.png';
import imgCoachWordx2 from './cerba/coach-word@2x.png';

import { ModalViewWelcome } from './modalView';

export const view = (state, actions) => (props, children) => (
    <Card kind={'advice'} theme={props.valueTheme}>
        {props.displayedon == 'timeline' &&
            <div>
                <Card.Header>
                    <Social kindcard={'advice'} displayedon={'timeline'} title={props.title} idfavorite={props.favoriteId} idtimeline={props.timeline} id={props.id} favorite={state.favorite} propagate={actions.updateParentProps}></Social>
                    <Card.Image kind={props.media} style={{ 'min-height': '100px' }}>
                        {props.media !== undefined &&
                            <Media src={props.media}></Media>
                        }
                    </Card.Image>
                    <div class='' style={{ 'clear': 'both', 'text-align': 'left', 'margin': '0 20px' }}>
                        <img src={imgCoachWord} srcset={`${imgCoachWordx2} 2x`} alt='' class='' />
                        <div class='' style={{ 'display': 'inline-block' }}>
                            <p class='btzTimeline-btzCard-btzTitle btzTitle'>{props.title}</p>
                            <p class='btzTimeline-btzCard-btzSubTitle btzSubTitle btzTextColored' data-theme='reading'>{t('Le mot du coach', {ns: 'timeline'})}</p>
                        </div>
                    </div>
                </Card.Header>

                <Card.Body classes='btzTopCustomDotted'>
                    <p class='btzTimeline-btzCard-btzDescription btzTexte' style={{ 'padding-bottom': '25px' }} innerHTML={<Convert separator={''} is_xhtml={true} type='nl2br'>{props.description}</Convert>}></p>
                </Card.Body>
            </div>
        }

        {props.displayedon == 'favorite' &&
            <Modal modalDisplayed={state.modalDisplayed} content={<ModalViewWelcome {...props} favorite={state.favorite} propagate={props.propagate} displayedon={'favorite'} showTagDetail={actions.showTagDetail} detailAliment={state.detailAliment} setDetailAliment={actions.setDetailAliment} kindOfInfos={state.kindOfInfos} setKindOfInfos={actions.setKindOfInfos} propagateConfirmClosing={() => actions.setModalDisplayed(false)}></ModalViewWelcome>}>
                <Button primary data-id={props.id} onclick={() => actions.setModalDisplayed(true)} >
                    <div>
                        <Card.Header>
                            {props.media !== undefined &&
                                <Card.Image kind={props.media}><Media src={props.media}></Media></Card.Image>
                            }
                            <div class='' style={{ 'clear': 'both', 'text-align': 'left', 'margin': '0 20px' }}>
                                <img src={imgCoachWord} srcset={`${imgCoachWordx2} 2x`} alt='' class='' />
                                <div class='' style={{ 'display': 'inline-block' }}>
                                    <p class='btzTimeline-btzCard-btzTitle btzTitle'>{props.title}</p>
                                    <p class='btzTimeline-btzCard-btzSubTitle btzSubTitle btzTextColored' data-theme='reading'>{t('Le mot du coach', {ns: 'timeline'})}</p>
                                </div>
                            </div>
                            <p class='btzLightTexte' oncreate={(el) => actions.getDate({'el' : el, 'dat' : props.date.date}) }></p>
                        </Card.Header>
                    </div>
                </Button>
            </Modal>
        }

        {props.displayedon == 'share' &&
            <div>
                <Card.Header>
                    {props.media !== undefined &&
                        <Card.Image kind={props.media}><Media src={props.media}></Media></Card.Image>
                    }
                    <div class='' style={{ 'clear': 'both', 'text-align': 'left', 'margin': '0 20px' }}>
                        <img src={imgCoachWord} srcset={`${imgCoachWordx2} 2x`} alt='' class='' />
                        <div class='' style={{ 'display': 'inline-block' }}>
                            <p class='btzTimeline-btzCard-btzTitle btzTitle'>{props.title}</p>
                            <p class='btzTimeline-btzCard-btzSubTitle btzSubTitle btzTextColored' data-theme='reading'>{t('Le mot du coach', {ns: 'timeline'})}</p>
                        </div>
                    </div>
                </Card.Header>

                <Card.Body classes='btzDottedSpaced btzTopCustomDotted'>
                    <p class='btzTimeline-btzCard-btzDescription btzTexte' style={{ 'padding-bottom': '25px' }} innerHTML={<Convert separator={''} is_xhtml={true} type='nl2br'>{props.description}</Convert>}></p>
                </Card.Body>
            </div>
        }
    </Card>
);
