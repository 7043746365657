export const state = {
    secret: {
        questions: [],
        choosen: null,
    },
    answer: {
        value: '',
        isTouched: false,
        isValid: false,
        isApproved: null,
    },
    api: {
        message: '',
        kind: 'info',
    },
};
