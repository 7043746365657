import { t } from '@app/utils';
import { Programs, Cardline, City, User } from '@app/api';
import { dynMod, addCSRFToken, dynLoad, getTodayYMD, getYMDfromDateObj, eraseCookie, readCookie, createCookie, isDefined, createUTCDate } from '@app/core';

import logoUKnow from '@app/img/logo/logo-uknow.png';
import * as staticsconstants from '@app/constants/constants-statics';

import './../index.scss';

export const routes = dynLoad('notif-routes', 'Timeline/user/notifications/actions/custo');
const NOTIF_ROUTES = routes.NOTIF_ROUTES;

module.exports = {
    initAction: function() {
        let action = {
            onComponentCreate: (props) => (state, actions) => {
                console.log('CITY : ', props);
                var d = createUTCDate();
                let dayUntilTomorrow = (((-d + d.setUTCHours(23,59,59,0)) / 6e4) / 60 / 24); // to get day between 0-1
                actions.setDayUntilTomorrow(dayUntilTomorrow);
                actions.setCookieName('hiddenNotif-' + props.profil.id);

                actions.getNotifications();
                if (staticsconstants.CUSTOMERS[props.customer.toLowerCase()].modules.programsSeparateNotification === false) {
                    actions.getPrograms();
                }

                if (dynMod('gamifications', 'isactive')) {
                    actions.getGamification();
                }

                if (props.profil) {
                    state.userProfil = props.profil.profile;

                    if (props.profil.profile) {
                        let city = null;
                        if (isDefined(props.profil.profile.city)) {
                            city = props.profil.profile.city;
                            // if (!isDefined(city.name) && isDefined(props.profil.profile)) {
                            if (isDefined(props.profil.cityName)) {
                                city.name = props.profil.cityName;
                            }
                        }
                    }

                    if ((props.profil.settings !== undefined) && (props.profil.settings.filter((f) => f.name === 'FAKEMEMBERID')[0] !== undefined)) {
                        // isFakedMemberId = true;
                    } else {
                        if ((props.profil.customer !== undefined) && (props.profil.customer !== null) && (props.profil.customerId !== null)) {
                            if (props.customer === 'vyv') {
                                let ssos = props.profil.ssos;
                                if (isDefined(ssos, true)) {
                                    let customerName = props.profil.customer;
                                    let membership = props.profil.membership;
                                    let sso = ssos.filter((f) => ((f.name === customerName) && (f.id === membership)));
                                    if (!isDefined(sso, true) || !isDefined(membership)) {
                                        actions.getADHnumber();
                                    }
                                } else {
                                    actions.getADHnumber();
                                }
                            }
                        }
                    }
                }
            },
            getNotifications: () => (state, actions) => {
                let notifs = [];
                let notifId = null;
                let notifMedia = null;
                let notifKind = null;
                let notifTitle = '';
                let notifContent = '';
                let notifRedirect = '';
                let notifImgNormal = '';
                let notifImgRetina = '';
                let hiddenNotif = readCookie(state.cookieName);
                if (hiddenNotif !== null) {
                    hiddenNotif = hiddenNotif.split('-');
                } else {
                    hiddenNotif = [];
                }
                Cardline.getCards(54).then((res) => {
                    for (var notif in res.data) {
                        notif = res.data[notif];
                        notifId = notif.id;
                        notifMedia = notif.media;
                        notifKind = NOTIF_ROUTES[notif.redirectUrl];
                        notifTitle = t('Le saviez-vous ?', {ns: 'timeline'});
                        notifContent = notif.description;
                        if ((notifKind === undefined) || (notifKind === null) || (notifKind === '')) {
                            notifKind = 'redirect';
                            notifRedirect = notif.redirectUrl;
                            if ((notif.media !== null) && (notif.media.url !== undefined)) {
                                notifImgNormal = notif.media.url;
                                notifImgRetina = notif.media.url;
                            }
                        } else {
                            notifRedirect = addCSRFToken(notifKind.url);
                            notifImgNormal = notifKind.normal;
                            notifImgRetina = notifKind.retina;
                        }
                        if (hiddenNotif.indexOf(notifId) === -1) {
                            notifs.push({
                                'id' : notifId,
                                'title' : notifTitle,
                                'content' : notifContent,
                                'redirect': notifRedirect,
                                'media': notifMedia,
                                'image' : {
                                    'normal' : notifImgNormal,
                                    'retina' : notifImgRetina
                                }
                            });
                        }
                    }

                    actions.setNotifications(notifs);
                });
            },
            getADHnumber: () => (state, actions) => {
                state.adh = {
                    'title' : t('Numéro d‘adhérent', {ns: 'timeline'}),
                    'content' : t('Renseignez le numéro d’adhérent de votre mutuelle pour continuer à profiter du service.', {ns: 'timeline'}),
                    'image' : {
                        'normal' : NOTIF_ROUTES['ADH'].normal,
                        'retina' : NOTIF_ROUTES['ADH'].retina
                    }
                }
            },
            getPrograms: () => (state, actions) => {
                let today = getTodayYMD();
                let hiddenNotif = readCookie(state.cookieName);
                if (hiddenNotif !== null) {
                    hiddenNotif = hiddenNotif.split('-');
                } else {
                    hiddenNotif = [];
                }
                Programs.getRunning().then(function(res) {
                    var running = [];
                    for (var reco in res.data) {
                        let program = res.data[reco].program;
                        program.media = res.data[reco].media;
                        let programDate = (program.dateNextActiveSession !== null ? program.dateNextActiveSession.date : null);
                        if (programDate !== null) {
                            programDate = getYMDfromDateObj(new Date(programDate.split(' ')[0]));
                            if (programDate.day !== undefined) {
                                programDate = (programDate.year + '' + programDate.month + '' + programDate.day);
                            }
                        }
                        if (hiddenNotif.indexOf(program.id) === -1) {
                            if ((programDate === null) || (+programDate <= +today)) {
                                running.push(program);
                            }
                        }
                    }
                    actions.setPrograms(running);
                });
            },
            getGamification: () => (state, actions) => {
                let hiddenNotif = readCookie(state.cookieName);
                if (hiddenNotif !== null) {
                    hiddenNotif = hiddenNotif.split('-');
                } else {
                    hiddenNotif = [];
                }
                if (hiddenNotif.indexOf('gamification') === -1) {
                    User.getUserGamificationStatus().then((res) => {
                        let gamif = res.data;
                        if ((gamif === undefined) || (gamif === null)) {
                            gamif = {};
                        }
                        gamif.title = t('Statut', {ns: 'timeline'});
                        gamif.redirect = addCSRFToken(NOTIF_ROUTES['BADGES'].url);
                        gamif.image = {
                            'normal' : NOTIF_ROUTES['BADGES'].normal,
                            'retina' : NOTIF_ROUTES['BADGES'].retina
                        };

                        actions.setGamification(gamif);
                    });
                }
            },
            removeAllNotifications: () => (state, actions) => {
                let notifNotif = state.notifications;
                for (var notifN in notifNotif) {
                    actions.hideNotifUntilTomorrow(notifNotif[notifN].id);
                }
                let notifPrograms = state.programs;
                for (var notifP in notifPrograms) {
                    actions.hideNotifUntilTomorrow(notifPrograms[notifP].id);
                }
                // actions.hideNotifUntilTomorrow('gamification');
                //
                actions.setNotifications([]);
                actions.setPrograms([]);
                // actions.setGamification(null);
            },
            addNotification: (notif) => (state, actions) => {
                state.notifications[notif.id] = notif;
            },
            removeNotification: (el) => (state, actions) => {
                if (el.kind === 'program') {
                    state.programs.splice(el.idx, 1);
                    actions.hideNotifUntilTomorrow(el.id);
                } else if (el.kind === 'gamification') {
                    // nothing
                } else if (el.kind === 'adh') {
                    state.adh = null;
                } else {
                    state.notifications.splice(el.idx, 1);
                    actions.hideNotifUntilTomorrow(el.id);
                }
                actions.rendering();
            },
            hideNotifUntilTomorrow: (notifid) => (state, actions) => {
                let hiddenNotif = readCookie(state.cookieName);
                if (!isDefined(hiddenNotif)) {
                    createCookie(state.cookieName, notifid, state.dayUntilTomorrow);
                } else {
                    eraseCookie(state.cookieName);
                    let existingNofis = hiddenNotif.split('-');
                    existingNofis.push(notifid);
                    existingNofis = existingNofis.join('-');
                    createCookie(state.cookieName, existingNofis, state.dayUntilTomorrow);
                }
            },
            displayGeolocError: newState => state => ({
                geolocError: newState,
            }),
            setNotifications: newState => state => ({
                notifications: newState,
            }),
            setMeteo: newState => state => ({
                meteo: newState,
            }),
            setGeolocLoading: newState => state => ({
                geolocIsLoading: newState,
            }),
            setPrograms: newState => state => ({
                programs: newState,
            }),
            setGamification: newState => state => ({
                gamification: newState,
            }),
            setDayUntilTomorrow: newState => state => ({
                dayUntilTomorrow: newState,
            }),
            setCookieName: newState => state => ({
                cookieName: newState,
            }),
            trim: (str) => {
                if (str.length > 250) {
                    return (str.substr(0, 250) + '...');
                }
                return str;
            },
            setState: ({ ns, key, value }) => state => ({
                [ns]: { ...state[ns], ...{ [key]: value } },
            }),
            rendering: () => state => ({
                rendering: !state.rendering,
            }),
        }
        return action;
    }
}