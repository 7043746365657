export const actions = {
    onComponentCreate: (props) => (state, actions) => {

    },
    rendering: () => state => ({
        rendering: !state.rendering,
    }),
    updateParentPropsWHYfalse: (props) => (state, actions) => {
        if (props) {
            props.send({
                ns: 'display',
                key: 'why',
                value: false
            })
        }
    },
};
