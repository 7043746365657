import { t, h } from '@app/utils';
import { Avatar, Balloon, Button, Form, Tile } from '@app/elements';

import logoStep from '@app/img/register/your-secret.png';
import logoStepx2 from '@app/img/register/your-secret@2x.png';
import icoReload from '@app/img/ico/ico-reload.png'
import icoReloadx2 from '@app/img/ico/ico-reload@2x.png'

import './../index.scss';

module.exports = {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <div style={{ position: 'relative' }}>
                <Tile>
                    <div class='btzTitle-btzImage btzTitle-btzImage-absolute col-md-12 col-4'>
                        <img src={logoStep} srcset={`${logoStepx2} 2x`} alt='' class='' />
                    </div>
                    <Tile.Content classes='btzLeftHrArrowed col-xs-12 col-8'>
                        <p class='btzTitle btzXl'>{t('Votre question secrète', {ns: 'register'})}</p>
                        <p class='btzTexte'>{t('Si jamais vos identifiants de connexion sont perdus. Nous vous poserons une question dont vous seul connaissez la réponse.', {ns: 'register'})}</p>
                        <div class='' style={{ 'display': 'table' }}>
                            <p class='btzTitle btzXl' style={{ 'width': '90%', 'display': 'inline-block', 'vertical-align': 'middle', 'margin': '0px' }}>{state.secret.questions[state.secret.choosen]}</p>
                            <button class='changequestion' style={{ 'width': '10%', 'height': '18px', 'display': 'inline-block', 'vertical-align': 'middle', 'margin-right': '0' }} onclick={() => actions.onSecretQuestionClickThrottledUpdate()}>
                                <img style={{ 'height': '100%' }} src={icoReload} srcset={icoReloadx2} />
                            </button>
                        </div>

                        <Form onsubmit={event => event.preventDefault()} style={{ 'margin-top': '25px' }}>
                            <Form.Group>
                                <Form.Input
                                    placeholder={t('Votre réponse', {ns: 'register'})}
                                    type='text'
                                    id='secret'
                                    name='secret'
                                    value={state.answer.value}
                                    oninput={(event) => actions.onAnswerInputDebouncedUpdate(event.target.value)}
                                    onfocus={() => actions.onAnswerInputTouched()}
                                    classes={(state.answer.isTouched) ? (state.answer.isValid ? 'is-success' : 'is-error') : ''}
                                    required
                                />
                            </Form.Group>

                            <Form.Group classes='btzForm-btzFooter'>
                                <Button primary
                                    active={(state.answer.isValid)}
                                    onclick={(event) => {
                                        actions.updateParentProps(props)
                                        actions.onStepSubmit(event)
                                    }}>
                                    {t('Valider', {ns: 'generals'})}
                                </Button>
                            </Form.Group>
                        </Form>

                        {state.api.message != '' && (
                            <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap' }}>
                                <Tile.Icon>
                                    <Avatar.Alfred kind={state.api.kind} classes={'centered'} />
                                </Tile.Icon>
                                <Tile.Content>
                                    <Balloon kind={state.api.kind} style={{ 'white-space': 'normal' }} actionAfterError={() => {actions.setApiErrors({'message': '','kind': 'info'});}}>
                                        {state.api.message}
                                    </Balloon>
                                </Tile.Content>
                            </Tile>
                        )}
                    </Tile.Content>
                </Tile>
            </div>
        );
        return view;
    }
}