import browser from 'bowser';
import { isDefined } from '@app/core';

import * as staticsconstants from '@app/constants/constants-statics';

export const actions = {
    onComponentCreate: (props) => (state, actions) => {
        if (isDefined(browser)) {
            let device = browser.name + ' (' + browser.osname + ', ' + ((browser.mobile || browser.tablet) ? 'Tablet' : 'Desktop') + ')';
            actions.updateParentProps({props: props, device: device});
        }

        if (props.initial) {
            actions.getInitialStateAndUpdate(props.initial)
        }
    },

    getInitialStateAndUpdate: (initial) => (state, actions) => {
        for (var ntl in initial) {
            let found = false;
            for (var stt in state) {
                if (stt === ntl)
                    found = true;
            }
            if (!found)
                continue;

            if (initial[ntl] == 'null') {
                initial[ntl] = '';
            }

            actions.setState({
                ns: ntl,
                key: 'value',
                value: initial[ntl]
            });
        }
    },

    setState: ({ ns, key, value }) => (state, actions) => ({
        [ns]: { ...state[ns], ...{ [key]: value } }
    }),

    setPanelVisibility: boolean => (state, actions) => actions.setState({
        ns: 'panel',
        key: 'isVisible',
        value: boolean
    }),

    onPanelClickUpdate: () => (state, actions) => {
        actions.setPanelVisibility(!state.panel.isVisible);
    },

    goToNextStep: (props) => {
        if (!staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()].modules.registerWorkflow.cguAfterDevice) {
        // if (props.customer && ((props.customer === 'feelgood') || (props.customer === 'klesia'))) {
            window.main.location.go('/home/register/end');
        } else {
            window.main.location.go('/home/register/cgu');
        }
    },

    updateParentProps: ({props, device}) => (state, actions) => {
        if (props) {
            props.send({
                ns: 'user',
                key: 'device',
                value: device
            })
        }
    },

    onStepSubmit: ({event: event, props: props}) => (state, actions) => {
        event.preventDefault();

        actions.goToNextStep(props)
    },
};
