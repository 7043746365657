const getYMDfromDateObj = function (obj) {
    if (!obj) {
        return null;
    }

    let dateDay = obj.getUTCDate();
    let dateMonth = obj.getUTCMonth() + 1;
    let dateYear = obj.getUTCFullYear();

    if (+dateDay < 10) {
        dateDay = ('0' + dateDay);
    }
    if (+dateMonth < 10) {
        dateMonth = ('0' + dateMonth);
    }

    return {
        'day': dateDay,
        'month': dateMonth,
        'year': dateYear,
    }
}

export { getYMDfromDateObj };