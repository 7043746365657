
export const actions = {
    onComponentCreate: (props) => (state, actions) => {
        state.display.accepted = (props.accepted || false);
    },
    trim: (str) => {
        if (str.length > 250) {
            return (str.substr(0, 250) + '...');
        }
        return str;
    },
    rendering: () => state => ({
        rendering: !state.rendering,
    }),
    setState: ({ ns, key, value }) => state => ({
        [ns]: { ...state[ns], ...{ [key]: value } },
    }),
    removeEndedChallenge: (props) => (state, actions) => {
        // send ajax
        if (props) {
            props.propagateEndedChallenge();
        }
    },
};