import { h, t, Component } from '@app/utils';
import { Lunch } from '@app/api';
import { Tile, Avatar, Balloon, Replace, Button } from '@app/elements';

import icoInfo from '@app/img/ico/ico-ask-white.png';
import icoInfox2 from '@app/img/ico/ico-ask-white@2x.png';

import imgPrevWhite from '@app/img/ico/ico-prev-light-white.png';
import imgPrevWhitex2 from '@app/img/ico/ico-prev-light-white@2x.png';

import decoBlankBG from '@app/img/food/deco-blank-bg.png';
import decoBlankBGx2 from '@app/img/food/deco-blank-bg@2x.png';

import icoLike from '@app/img/ico/ico-like.png';
import icoLikex2 from '@app/img/ico/ico-like@2x.png';

import icoDislike from '@app/img/ico/ico-dislike.png';
import icoDislikex2 from '@app/img/ico/ico-dislike@2x.png';

import { ViewMainInformations } from './viewMainInformations';
import { ViewGlycemicLoad } from './viewGlycemicLoad';
import { ViewMineralUse } from './viewMineralUse';
import { ViewVitamineUse } from './viewVitamineUse';

import './index.scss';

const ViewAlimentDetail = Component(
    {
        lunchAliment: null,
        detailAliment: null,
        kindOfInfos: null,
        modeDisplayed: 'portion',
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.tagaliment) {
                Lunch.getAlimentByAlias(props.tagaliment).then((res) => {
                    thenParseAlimentDetail(res);
                });
            } else if (props.idaliment) {
                Lunch.getAliments(props.idaliment).then((res) => {
                    thenParseAlimentDetail(res);
                });
            }

            function thenParseAlimentDetail(res) {
                let dataPortion = JSON.parse(JSON.stringify(res.data));
                let prefix = '';
                let tmp = 0;
                for (var dat in res.data) {
                    prefix = '';
                    if ((dat[0] === dat[0].toUpperCase()) && ((res.data[dat] !== null) && (res.data[dat] !== false) && (!Array.isArray(res.data[dat])))) {
                        tmp = dataPortion[dat];
                        if (res.data[dat].indexOf('<') > -1) {
                            prefix = '< ';
                            tmp = +tmp.replace('<', '');
                        }
                        if ((dataPortion[dat] !== null) && (dataPortion[dat] !== undefined) && (dataPortion[dat] !== '')
                            && (dataPortion[dat] !== false) && (dataPortion[dat] !== 0) && (dataPortion[dat] !== 1)) {
                            tmp = (+tmp * +dataPortion.portion) / 100;
                        } else {
                            tmp = dataPortion[dat];
                        }
                        if (!isNaN(tmp) && (tmp !== null) && (tmp !== '')) {
                            tmp = prefix + (Math.round(tmp * 100) / 100);
                        }
                    } else {
                        tmp = res.data[dat];
                    }
                    dataPortion[dat] = tmp;
                }
                let detailData = {};
                detailData.data100g = res.data;
                detailData.dataportion = dataPortion;
                actions.setDetailAliment(detailData);
            }
        },
        onComponentUpdate: (props) => (state, actions) => {

        },
        setDetailAliment: newState => state => ({
            detailAliment: newState,
        }),
        setKindOfInfos: newState => state => ({
            kindOfInfos: newState,
        }),
        setDisplayedMode: newState => state => ({
            modeDisplayed: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <div>
            {state.detailAliment !== null &&
                <div style={{ 'display': (state.kindOfInfos === null ? 'block' : 'none') }}>
                    <div class='btzLunch-btzAliment-btzDetail-btzHeader'>
                        <img src={imgPrevWhite} srcset={`${imgPrevWhitex2} 2x`} style={{ 'cursor': 'pointer' }} alt='' class='btzLunch-btzAliment-btzDetail-btzClose' onclick={() => {
                            if (props.displayAlimentDetail && (props.displayAlimentDetail !== undefined)) {
                                props.displayAlimentDetail(false);
                            }
                            actions.setDetailAliment(null);
                        }} />
                        <div style={{ 'line-height': '0' }}>
                            <div class='btzOverlayBlack'></div>
                            {((state.detailAliment['data' + state.modeDisplayed] !== undefined) && ((state.detailAliment['data' + state.modeDisplayed].media === null) || (state.detailAliment['data' + state.modeDisplayed].media === undefined))) ?
                                <img src={decoBlankBG} srcset={`${decoBlankBGx2} 2x`} alt='' class='' />
                                :
                                <img src={state.detailAliment['data' + state.modeDisplayed].media.url} srcset={`${state.detailAliment['data' + state.modeDisplayed].media.url} 2x`} alt='' class='' />
                            }
                        </div>
                        <div class='btzLunch-btzAliment-btzDetail-btzHeader-btzTitles'>
                            <p class='btzPageContentTexte btzXl' style={{ 'text-align': 'left', 'margin': '0px' }}>{state.detailAliment['data' + state.modeDisplayed].name}</p>
                            <p class='btzPageContentSubTexte' style={{ 'text-align': 'left' }}>{state.detailAliment['data' + state.modeDisplayed].families.join(', ')}</p>
                        </div>
                    </div>

                    <div class='btzLunch-btzAliment-btzDetail-Details'>
                        {((state.detailAliment['data' + state.modeDisplayed].advantageFiber === true)
                            || (state.detailAliment['data' + state.modeDisplayed].advantageCG === true)
                            || (state.detailAliment['data' + state.modeDisplayed].flawFat === true)
                            || (state.detailAliment['data' + state.modeDisplayed].flawSugar === true)
                            || (state.detailAliment['data' + state.modeDisplayed].flawSalt === true)
                            || (state.detailAliment['data' + state.modeDisplayed].flawCG === true)) ?
                            <Tile classes={'btzAlfred btzAlfredAlimentDetail'} style={{ 'white-space': 'nowrap' }}>
                                <Tile.Icon>
                                    <Avatar.Alfred classes={'centered'} />
                                </Tile.Icon>
                                <Tile.Content>
                                    <Balloon kind={'info'} style={{ 'white-space': 'normal' }}>
                                        <div style={{ 'text-align': 'left' }}>
                                            <span class='btzBaloon-btzTitle'>{t('QUALITÉS & DÉFAUTS', {ns: 'food'})}</span>
                                            <div>
                                                {state.detailAliment['data' + state.modeDisplayed].advantageFiber === true &&
                                                    <div style={{ 'margin-top': '0px', 'text-align': 'left' }}>
                                                        <img class='btzAlfredAlimentDetail-btzLike' src={icoLike} srcset={`${icoLikex2} 2x`} alt='' />
                                                        <p class=''>{t('Riche en fibres', {ns: 'food'})}</p>
                                                    </div>
                                                }
                                                {state.detailAliment['data' + state.modeDisplayed].advantageCG === true &&
                                                    <div style={{ 'margin-top': '3px', 'text-align': 'left' }}>
                                                        <img class='btzAlfredAlimentDetail-btzLike' src={icoLike} srcset={`${icoLikex2} 2x`} alt='' />
                                                        <p class=''>{t('Charge glycémique basse', {ns: 'food'})}</p>
                                                    </div>
                                                }

                                                {state.detailAliment['data' + state.modeDisplayed].flawFat === true &&
                                                    <div style={{ 'margin-top': '3px', 'text-align': 'left' }}>
                                                        <img class='btzAlfredAlimentDetail-btzDislike' src={icoDislike} srcset={`${icoDislikex2} 2x`} alt='' />
                                                        <p class=''>{t('Trop gras', {ns: 'food'})}</p>
                                                    </div>
                                                }
                                                {state.detailAliment['data' + state.modeDisplayed].flawSugar === true &&
                                                    <div style={{ 'margin-top': '3px', 'text-align': 'left' }}>
                                                        <img class='btzAlfredAlimentDetail-btzDislike' src={icoDislike} srcset={`${icoDislikex2} 2x`} alt='' />
                                                        <p class=''>{t('Trop sucré', {ns: 'food'})}</p>
                                                    </div>
                                                }
                                                {state.detailAliment['data' + state.modeDisplayed].flawSalt === true &&
                                                    <div style={{ 'margin-top': '3px', 'text-align': 'left' }}>
                                                        <img class='btzAlfredAlimentDetail-btzDislike' src={icoDislike} srcset={`${icoDislikex2} 2x`} alt='' />
                                                        <p class=''>{t('Trop salé', {ns: 'food'})}</p>
                                                    </div>
                                                }
                                                {state.detailAliment['data' + state.modeDisplayed].flawCG === true &&
                                                    <div style={{ 'margin-top': '3px', 'text-align': 'left' }}>
                                                        <img class='btzAlfredAlimentDetail-btzDislike' src={icoDislike} srcset={`${icoDislikex2} 2x`} alt='' />
                                                        <p class=''>{t('Charge glycémique élevée', {ns: 'food'})}</p>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </Balloon>
                                </Tile.Content>
                            </Tile>
                            :
                            <div></div>
                        }

                        <div class='btzData-btzFood-btzListing-btzHeader'>
                            <div style={{ 'text-align': 'left' }}>
                                <p style={{ 'width': '115px', 'line-height': '1.5' }} class={'btzData-btzFood-btzListing-btzHeader-btzEntry ' + (state.modeDisplayed === 'portion' ? 'active' : '')} onclick={() => actions.setDisplayedMode('portion')}>
                                    {t('Pour 1 portion', {ns: 'food'})}
                                </p>
                                <p style={{ 'width': '90px', 'line-height': '1.5' }} class={'btzData-btzFood-btzListing-btzHeader-btzEntry ' + (state.modeDisplayed === '100g' ? 'active' : '')} onclick={() => actions.setDisplayedMode('100g')}>
                                    {t('Pour 100g', {ns: 'food'})}
                                </p>
                            </div>
                        </div>
                        <div class='btzLunch-btzAliment-btzDetail-Details-btzHeader'>
                            <div class='col-10 p-0'>
                                <p class='btzPageContentTexte btzXl' style={{ 'width': '100%' }}>{t('INFORMATIONS PRINCIPALES', {ns: 'food'})}</p>
                                {state.modeDisplayed === 'portion' ?
                                    <p class='btzPageContentSubTexte'>{t('pour 1 portion', {ns: 'food'}) + ' (' + state.detailAliment['data' + state.modeDisplayed].portionLabel + ')'}</p>
                                    :
                                    <p class='btzPageContentSubTexte'>{t('pour 100g', {ns: 'food'})}</p>
                                }
                            </div>
                            <div class='col-1 p-0'>
                                <div class='btzInformationsAliment-btzImage' onclick={() => {actions.setKindOfInfos('maininfos')}}><img src={icoInfo} srcset={`${icoInfox2} 2x`} alt='' /></div>
                            </div>
                        </div>
                        <div class='btzModal-btzPaddingWrapper'>
                            <div class='btzLunch-btzAliment-btzDetail-Entry'>
                                <p class='btzPageContentTexte'>{t('Protéines', {ns: 'food'})}</p><p class='btzPageContentTitleBlue' style={{ 'color': 'black' }}><Replace mode={'isempty'} isempty={state.detailAliment['data' + state.modeDisplayed].Proteines} replacewith={'-'}>{state.detailAliment['data' + state.modeDisplayed].Proteines + ' g'}</Replace></p>
                            </div>
                            <div class='btzLunch-btzAliment-btzDetail-Entry'>
                                <p class='btzPageContentTexte'>{t('Glucides', {ns: 'food'})}</p><p class='btzPageContentTitleBlue' style={{ 'color': 'black' }}><Replace mode={'isempty'} isempty={state.detailAliment['data' + state.modeDisplayed].Glucides} replacewith={'-'}>{state.detailAliment['data' + state.modeDisplayed].Glucides + ' g'}</Replace></p>
                                <div class='btzLunch-btzAliment-btzDetail-Hr'></div>
                                <p class='btzPageContentSubTexte'>{t('dont Sucres', {ns: 'food'})}</p><p class='btzPageContentTitleBlue' style={{ 'color': 'black' }}><Replace mode={'isempty'} isempty={state.detailAliment['data' + state.modeDisplayed].Sucres} replacewith={'-'}>{state.detailAliment['data' + state.modeDisplayed].Sucres + ' g'}</Replace></p>
                            </div>
                            <div class='btzLunch-btzAliment-btzDetail-Entry'>
                                <p class='btzPageContentTexte'>{t('Lipides', {ns: 'food'})}</p><p class='btzPageContentTitleBlue' style={{ 'color': 'black' }}><Replace mode={'isempty'} isempty={state.detailAliment['data' + state.modeDisplayed].Lipides} replacewith={'-'}>{state.detailAliment['data' + state.modeDisplayed].Lipides + ' g'}</Replace></p>
                                <div class='btzLunch-btzAliment-btzDetail-Hr'></div>
                                <p class='btzPageContentSubTexte'>{t('dont Acides Gras saturés', {ns: 'food'})}</p><p class='btzPageContentTitleBlue' style={{ 'color': 'black' }}><Replace mode={'isempty'} isempty={state.detailAliment['data' + state.modeDisplayed].AGSatures} replacewith={'-'}>{state.detailAliment['data' + state.modeDisplayed].AGSatures + ' g'}</Replace></p>
                                <div class='btzLunch-btzAliment-btzDetail-Hr'></div>
                                <p class='btzPageContentSubTexte'>{t('dont Acides Gras mono-insaturés', {ns: 'food'})}</p><p class='btzPageContentTitleBlue' style={{ 'color': 'black' }}><Replace mode={'isempty'} isempty={state.detailAliment['data' + state.modeDisplayed].AGMonoInsatures} replacewith={'-'}>{state.detailAliment['data' + state.modeDisplayed].AGMonoInsatures + ' g'}</Replace></p>
                                <div class='btzLunch-btzAliment-btzDetail-Hr'></div>
                                <p class='btzPageContentSubTexte'>{t('dont Acides Gras poly-insaturés', {ns: 'food'})}</p><p class='btzPageContentTitleBlue' style={{ 'color': 'black' }}><Replace mode={'isempty'} isempty={state.detailAliment['data' + state.modeDisplayed].AGPolyInsatures} replacewith={'-'}>{state.detailAliment['data' + state.modeDisplayed].AGPolyInsatures + ' g'}</Replace></p>
                            </div>
                            <div class='btzLunch-btzAliment-btzDetail-Entry'>
                                <p class='btzPageContentTexte'>{t('Fibres', {ns: 'food'})}</p><p class='btzPageContentTitleBlue' style={{ 'color': 'black' }}><Replace mode={'isempty'} isempty={state.detailAliment['data' + state.modeDisplayed].FibresAlimentaires} replacewith={'-'}>{state.detailAliment['data' + state.modeDisplayed].FibresAlimentaires + ' g'}</Replace></p>
                            </div>
                            <div class='btzLunch-btzAliment-btzDetail-Entry'>
                                <p class='btzPageContentTexte'>{t('Sel', {ns: 'food'})}</p><p class='btzPageContentTitleBlue' style={{ 'color': 'black' }}><Replace mode={'isempty'} isempty={state.detailAliment['data' + state.modeDisplayed].Sel} replacewith={'-'}>{state.detailAliment['data' + state.modeDisplayed].Sel + ' g'}</Replace></p>
                            </div>
                            <div class='btzLunch-btzAliment-btzDetail-Entry'>
                                <p class='btzPageContentTexte'>{t('Énergie', {ns: 'food'})}</p><p class='btzPageContentTitleBlue' style={{ 'color': 'black' }}><Replace mode={'isempty'} isempty={state.detailAliment['data' + state.modeDisplayed].Energie1} replacewith={'-'}>{state.detailAliment['data' + state.modeDisplayed].Energie1 + ' Kcal'}</Replace></p>
                                <div class='btzLunch-btzAliment-btzDetail-Hr'></div>
                                <p class='btzPageContentSubTexte'>{t('en kilo Joule', {ns: 'food'})}</p><p class='btzPageContentTitleBlue' style={{ 'color': 'black' }}><Replace mode={'isempty'} isempty={state.detailAliment['data' + state.modeDisplayed].Energie2} replacewith={'-'}>{state.detailAliment['data' + state.modeDisplayed].Energie2 + ' KJ'}</Replace></p>
                            </div>
                        </div>

                        <div class='btzLunch-btzAliment-btzDetail-Details-btzHeader'>
                            <div class='col-10 p-0'>
                                <p class='btzPageContentTexte btzXl' style={{ 'width': '100%' }}>{t('INDEX / CHARGE GLYCÉMIQUE', {ns: 'food'})}</p>
                                {state.modeDisplayed === 'portion' ?
                                    <p class='btzPageContentSubTexte'>{t('pour 1 portion', {ns: 'food'}) + ' (' + state.detailAliment['data' + state.modeDisplayed].portionLabel + ')'}</p>
                                    :
                                    <p class='btzPageContentSubTexte'>{t('pour 100g', {ns: 'food'})}</p>
                                }
                            </div>
                            <div class='col-1 p-0'>
                                <div class='btzInformationsAliment-btzImage' onclick={() => {actions.setKindOfInfos('glycload')}}><img src={icoInfo} srcset={`${icoInfox2} 2x`} alt='' /></div>
                            </div>
                        </div>
                        <div class='btzModal-btzPaddingWrapper'>
                            <div class='btzLunch-btzAliment-btzDetail-Entry'>
                                <p class='btzPageContentTexte'>{t('Index glycémique', {ns: 'food'})}</p><p class='btzPageContentTitleBlue' style={{ 'color': 'black' }}><Replace mode={'isempty'} isempty={state.detailAliment['data' + state.modeDisplayed].glycemyIndex} replacewith={'-'}>{state.detailAliment['data' + state.modeDisplayed].glycemyIndex}</Replace></p>
                            </div>
                            <div class='btzLunch-btzAliment-btzDetail-Entry'>
                                <p class='btzPageContentTexte'>{t('Charge glycémique', {ns: 'food'})}</p><p class='btzPageContentTitleBlue' style={{ 'color': 'black' }}><Replace mode={'isempty'} isempty={state.detailAliment['data' + state.modeDisplayed].glycemyLoad} replacewith={'-'}>{state.detailAliment['data' + state.modeDisplayed].glycemyLoad}</Replace></p>
                            </div>
                        </div>

                        <div class='btzLunch-btzAliment-btzDetail-Details-btzHeader'>
                            <div class='col-10 p-0'>
                                <p class='btzPageContentTexte btzXl' style={{ 'width': '100%' }}>{t('MINÉRAUX', {ns: 'food'})}</p>
                                {state.modeDisplayed === 'portion' ?
                                    <p class='btzPageContentSubTexte'>{t('pour 1 portion', {ns: 'food'}) + ' (' + state.detailAliment['data' + state.modeDisplayed].portionLabel + ')'}</p>
                                    :
                                    <p class='btzPageContentSubTexte'>{t('pour 100g', {ns: 'food'})}</p>
                                }
                            </div>
                            <div class='col-1 p-0'>
                                <div class='btzInformationsAliment-btzImage' onclick={() => {actions.setKindOfInfos('mineraluse')}}><img src={icoInfo} srcset={`${icoInfox2} 2x`} alt='' /></div>
                            </div>
                        </div>
                        <div class='btzModal-btzPaddingWrapper'>
                            <div class='btzLunch-btzAliment-btzDetail-Entry'>
                                <p class='btzPageContentTexte'>{t('Calcium', {ns: 'food'})}</p><p class='btzPageContentTitleBlue' style={{ 'color': 'black' }}><Replace mode={'isempty'} isempty={state.detailAliment['data' + state.modeDisplayed].Calcium} replacewith={'-'}>{state.detailAliment['data' + state.modeDisplayed].Calcium + ' mg'}</Replace></p>
                            </div>
                            <div class='btzLunch-btzAliment-btzDetail-Entry'>
                                <p class='btzPageContentTexte'>{t('Cuivre', {ns: 'food'})}</p><p class='btzPageContentTitleBlue' style={{ 'color': 'black' }}><Replace mode={'isempty'} isempty={state.detailAliment['data' + state.modeDisplayed].Cuivre} replacewith={'-'}>{state.detailAliment['data' + state.modeDisplayed].Cuivre + ' mg'}</Replace></p>
                            </div>
                            <div class='btzLunch-btzAliment-btzDetail-Entry'>
                                <p class='btzPageContentTexte'>{t('Fer', {ns: 'food'})}</p><p class='btzPageContentTitleBlue' style={{ 'color': 'black' }}><Replace mode={'isempty'} isempty={state.detailAliment['data' + state.modeDisplayed].Fer} replacewith={'-'}>{state.detailAliment['data' + state.modeDisplayed].Fer + ' mg'}</Replace></p>
                            </div>
                            <div class='btzLunch-btzAliment-btzDetail-Entry'>
                                <p class='btzPageContentTexte'>{t('Iode', {ns: 'food'})}</p><p class='btzPageContentTitleBlue' style={{ 'color': 'black' }}><Replace mode={'isempty'} isempty={state.detailAliment['data' + state.modeDisplayed].Iode} replacewith={'-'}>{state.detailAliment['data' + state.modeDisplayed].Iode + ' µg'}</Replace></p>
                            </div>
                            <div class='btzLunch-btzAliment-btzDetail-Entry'>
                                <p class='btzPageContentTexte'>{t('Magnésium', {ns: 'food'})}</p><p class='btzPageContentTitleBlue' style={{ 'color': 'black' }}><Replace mode={'isempty'} isempty={state.detailAliment['data' + state.modeDisplayed].Magnesium} replacewith={'-'}>{state.detailAliment['data' + state.modeDisplayed].Magnesium + ' mg'}</Replace></p>
                            </div>
                            <div class='btzLunch-btzAliment-btzDetail-Entry'>
                                <p class='btzPageContentTexte'>{t('Manganèse', {ns: 'food'})}</p><p class='btzPageContentTitleBlue' style={{ 'color': 'black' }}><Replace mode={'isempty'} isempty={state.detailAliment['data' + state.modeDisplayed].Manganese} replacewith={'-'}>{state.detailAliment['data' + state.modeDisplayed].Manganese + ' mg'}</Replace></p>
                            </div>
                            <div class='btzLunch-btzAliment-btzDetail-Entry'>
                                <p class='btzPageContentTexte'>{t('Phosphore', {ns: 'food'})}</p><p class='btzPageContentTitleBlue' style={{ 'color': 'black' }}><Replace mode={'isempty'} isempty={state.detailAliment['data' + state.modeDisplayed].Phosphore} replacewith={'-'}>{state.detailAliment['data' + state.modeDisplayed].Phosphore + ' mg'}</Replace></p>
                            </div>
                            <div class='btzLunch-btzAliment-btzDetail-Entry'>
                                <p class='btzPageContentTexte'>{t('Potassium', {ns: 'food'})}</p><p class='btzPageContentTitleBlue' style={{ 'color': 'black' }}><Replace mode={'isempty'} isempty={state.detailAliment['data' + state.modeDisplayed].Potassium} replacewith={'-'}>{state.detailAliment['data' + state.modeDisplayed].Potassium + ' mg'}</Replace></p>
                            </div>
                            <div class='btzLunch-btzAliment-btzDetail-Entry'>
                                <p class='btzPageContentTexte'>{t('Sélénium', {ns: 'food'})}</p><p class='btzPageContentTitleBlue' style={{ 'color': 'black' }}><Replace mode={'isempty'} isempty={state.detailAliment['data' + state.modeDisplayed].Selenium} replacewith={'-'}>{state.detailAliment['data' + state.modeDisplayed].Selenium + ' µg'}</Replace></p>
                            </div>
                            <div class='btzLunch-btzAliment-btzDetail-Entry'>
                                <p class='btzPageContentTexte'>{t('Sodium', {ns: 'food'})}</p><p class='btzPageContentTitleBlue' style={{ 'color': 'black' }}><Replace mode={'isempty'} isempty={state.detailAliment['data' + state.modeDisplayed].Sodium} replacewith={'-'}>{state.detailAliment['data' + state.modeDisplayed].Sodium + ' mg'}</Replace></p>
                            </div>
                            <div class='btzLunch-btzAliment-btzDetail-Entry'>
                                <p class='btzPageContentTexte'>{t('Zinc', {ns: 'food'})}</p><p class='btzPageContentTitleBlue' style={{ 'color': 'black' }}><Replace mode={'isempty'} isempty={state.detailAliment['data' + state.modeDisplayed].Zinc} replacewith={'-'}>{state.detailAliment['data' + state.modeDisplayed].Zinc + ' mg'}</Replace></p>
                            </div>
                        </div>

                        <div class='btzLunch-btzAliment-btzDetail-Details-btzHeader'>
                            <div class='col-10 p-0'>
                                <p class='btzPageContentTexte btzXl' style={{ 'width': '100%' }}>{t('VITAMINES', {ns: 'food'})}</p>
                                {state.modeDisplayed === 'portion' ?
                                    <p class='btzPageContentSubTexte'>{t('pour 1 portion', {ns: 'food'}) + ' (' + state.detailAliment['data' + state.modeDisplayed].portionLabel + ')'}</p>
                                    :
                                    <p class='btzPageContentSubTexte'>{t('pour 100g', {ns: 'food'})}</p>
                                }
                            </div>
                            <div class='col-1 p-0'>
                                <div class='btzInformationsAliment-btzImage' onclick={() => {actions.setKindOfInfos('vitamineuse')}}><img src={icoInfo} srcset={`${icoInfox2} 2x`} alt='' /></div>
                            </div>
                        </div>
                        <div class='btzModal-btzPaddingWrapper'>
                            <div class='btzLunch-btzAliment-btzDetail-Entry'>
                                <p class='btzPageContentTexte'>{t('Beta-Carotène', {ns: 'food'})}</p><p class='btzPageContentTitleBlue' style={{ 'color': 'black' }}><Replace mode={'isempty'} isempty={state.detailAliment['data' + state.modeDisplayed].BetaCarotene} replacewith={'-'}>{state.detailAliment['data' + state.modeDisplayed].BetaCarotene + ' µg'}</Replace></p>
                            </div>
                            <div class='btzLunch-btzAliment-btzDetail-Entry'>
                                <p class='btzPageContentTexte'>{t('Vitamine D', {ns: 'food'})}</p><p class='btzPageContentTitleBlue' style={{ 'color': 'black' }}><Replace mode={'isempty'} isempty={state.detailAliment['data' + state.modeDisplayed].VitamineD} replacewith={'-'}>{state.detailAliment['data' + state.modeDisplayed].VitamineD + ' µg'}</Replace></p>
                            </div>
                            <div class='btzLunch-btzAliment-btzDetail-Entry'>
                                <p class='btzPageContentTexte'>{t('Vitamine E', {ns: 'food'})}</p><p class='btzPageContentTitleBlue' style={{ 'color': 'black' }}><Replace mode={'isempty'} isempty={state.detailAliment['data' + state.modeDisplayed].VitamineD} replacewith={'-'}>{state.detailAliment['data' + state.modeDisplayed].VitamineD + ' mg'}</Replace></p>
                            </div>
                            <div class='btzLunch-btzAliment-btzDetail-Entry'>
                                <p class='btzPageContentTexte'>{t('Vitamine K1', {ns: 'food'})}</p><p class='btzPageContentTitleBlue' style={{ 'color': 'black' }}><Replace mode={'isempty'} isempty={state.detailAliment['data' + state.modeDisplayed].VitamineK1} replacewith={'-'}>{state.detailAliment['data' + state.modeDisplayed].VitamineK1 + ' mg'}</Replace></p>
                            </div>
                            <div class='btzLunch-btzAliment-btzDetail-Entry'>
                                <p class='btzPageContentTexte'>{t('Vitamine K2', {ns: 'food'})}</p><p class='btzPageContentTitleBlue' style={{ 'color': 'black' }}><Replace mode={'isempty'} isempty={state.detailAliment['data' + state.modeDisplayed].VitamineK2} replacewith={'-'}>{state.detailAliment['data' + state.modeDisplayed].VitamineK2 + ' mg'}</Replace></p>
                            </div>
                            <div class='btzLunch-btzAliment-btzDetail-Entry'>
                                <p class='btzPageContentTexte'>{t('Vitamine C', {ns: 'food'})}</p><p class='btzPageContentTitleBlue' style={{ 'color': 'black' }}><Replace mode={'isempty'} isempty={state.detailAliment['data' + state.modeDisplayed].VitamineC} replacewith={'-'}>{state.detailAliment['data' + state.modeDisplayed].VitamineC + ' mg'}</Replace></p>
                            </div>
                            <div class='btzLunch-btzAliment-btzDetail-Entry'>
                                <p class='btzPageContentTexte'>{t('Vitamine B1', {ns: 'food'})}</p><p class='btzPageContentTitleBlue' style={{ 'color': 'black' }}><Replace mode={'isempty'} isempty={state.detailAliment['data' + state.modeDisplayed].VitamineB1} replacewith={'-'}>{state.detailAliment['data' + state.modeDisplayed].VitamineB1 + ' mg'}</Replace></p>
                            </div>
                            <div class='btzLunch-btzAliment-btzDetail-Entry'>
                                <p class='btzPageContentTexte'>{t('Vitamine B2', {ns: 'food'})}</p><p class='btzPageContentTitleBlue' style={{ 'color': 'black' }}><Replace mode={'isempty'} isempty={state.detailAliment['data' + state.modeDisplayed].VitamineB2} replacewith={'-'}>{state.detailAliment['data' + state.modeDisplayed].VitamineB2 + ' mg'}</Replace></p>
                            </div>
                            <div class='btzLunch-btzAliment-btzDetail-Entry'>
                                <p class='btzPageContentTexte'>{t('Vitamine B3', {ns: 'food'})}</p><p class='btzPageContentTitleBlue' style={{ 'color': 'black' }}><Replace mode={'isempty'} isempty={state.detailAliment['data' + state.modeDisplayed].VitamineB3} replacewith={'-'}>{state.detailAliment['data' + state.modeDisplayed].VitamineB3 + ' mg'}</Replace></p>
                            </div>
                            <div class='btzLunch-btzAliment-btzDetail-Entry'>
                                <p class='btzPageContentTexte'>{t('Vitamine B5', {ns: 'food'})}</p><p class='btzPageContentTitleBlue' style={{ 'color': 'black' }}><Replace mode={'isempty'} isempty={state.detailAliment['data' + state.modeDisplayed].VitamineB5} replacewith={'-'}>{state.detailAliment['data' + state.modeDisplayed].VitamineB5 + ' mg'}</Replace></p>
                            </div>
                            <div class='btzLunch-btzAliment-btzDetail-Entry'>
                                <p class='btzPageContentTexte'>{t('Vitamine B6', {ns: 'food'})}</p><p class='btzPageContentTitleBlue' style={{ 'color': 'black' }}><Replace mode={'isempty'} isempty={state.detailAliment['data' + state.modeDisplayed].VitamineB6} replacewith={'-'}>{state.detailAliment['data' + state.modeDisplayed].VitamineB6 + ' mg'}</Replace></p>
                            </div>
                            <div class='btzLunch-btzAliment-btzDetail-Entry'>
                                <p class='btzPageContentTexte'>{t('Vitamine B9', {ns: 'food'})}</p><p class='btzPageContentTitleBlue' style={{ 'color': 'black' }}><Replace mode={'isempty'} isempty={state.detailAliment['data' + state.modeDisplayed].VitamineB9} replacewith={'-'}>{state.detailAliment['data' + state.modeDisplayed].VitamineB9 + ' µg'}</Replace></p>
                            </div>
                            <div class='btzLunch-btzAliment-btzDetail-Entry' style={{ 'border-bottom': '0px' }}>
                                <p class='btzPageContentTexte'>{t('Vitamine B12', {ns: 'food'})}</p><p class='btzPageContentTitleBlue' style={{ 'color': 'black' }}><Replace mode={'isempty'} isempty={state.detailAliment['data' + state.modeDisplayed].VitamineB12} replacewith={'-'}>{state.detailAliment['data' + state.modeDisplayed].VitamineB12 + ' µg'}</Replace></p>
                            </div>
                        </div>

                        <div class='btzLunch-btzAliment-btzDetail-Details-btzHeader' style={{ 'margin': '20px 0 0 0' }}>
                            <div style={{ 'background-color': 'black', 'height': '1px', 'width': '11px', 'margin-bottom': '5px' }}></div>
                            <p class='btzPageContentTexte btzXl' style={{ 'font-size': '22px' }}>{t('Note', {ns: 'food'}) + ' :'}</p>
                            <p class='btzPageContentSubTexte' style={{ 'color': 'black', 'white-space': 'normal', 'line-height': '18px', 'margin-top': '20px' }}>{t('Ces informations nutritionnelles sont issues de la Table de composition nutritionnelle des aliments Ciqual 2017.', {ns: 'food'})}</p>
                        </div>
                    </div>

                    {(props.showAddButton || ((props.lunchAliment !== null) && (props.lunchAliment !== undefined) && (props.lunchAliment.count !== undefined))) &&
                        <div style='padding: 15px 0;display: block;margin: auto;text-align: center;'>
                            <Button primary flat active onclick={() => {
                                props.addOneAliment(props);
                                if (props.displayAlimentDetail && (props.displayAlimentDetail !== undefined)) {
                                    props.displayAlimentDetail(false);
                                }
                                actions.setDetailAliment(null);
                            }}>
                                {t('Ajouter', {ns: 'food'}) + ' (1) ' + t('à votre repas', {ns: 'food'})}
                            </Button>
                        </div>
                    }
                </div>
            }

            {state.kindOfInfos === 'maininfos' &&
                <ViewMainInformations closeView={() => {actions.setKindOfInfos(null)}}></ViewMainInformations>
            }
            {state.kindOfInfos === 'glycload' &&
                <ViewGlycemicLoad closeView={() => {actions.setKindOfInfos(null)}}></ViewGlycemicLoad>
            }
            {state.kindOfInfos === 'mineraluse' &&
                <ViewMineralUse closeView={() => {actions.setKindOfInfos(null)}}></ViewMineralUse>
            }
            {state.kindOfInfos === 'vitamineuse' &&
                <ViewVitamineUse closeView={() => {actions.setKindOfInfos(null)}}></ViewVitamineUse>
            }
        </div>
    ),
    'viewalimentdetail'
);

export { ViewAlimentDetail };