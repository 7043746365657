import { t, h } from '@app/utils';
import { Card, Social, Media, Convert } from '@app/elements';

import '@app/modules/Food/index-addmeal.scss';

import { ViewAlimentDetail } from '@app/modules/Food/AddMeal/step-listing-entry/viewAlimentDetail';

export const ModalViewArticle = (props, actions) => (
    <div class='article-opened' style={{ 'height': '100%' }}>
        {props.alimentDetailDisplayed === true ?
            <div class='btzLunch-btzAliment-btzDetail-btzWrapper' style={{ 'overflow': 'auto', 'height': '100%', 'position': 'relative' }}>
                <ViewAlimentDetail displayAlimentDetail={props.displayAlimentDetail} tagaliment={props.tagaliment} setKindOfInfos={props.setKindOfInfos} setDetailAliment={props.setDetailAliment} lunchAliment={null} addOneAliment={null}></ViewAlimentDetail>
            </div>
            :
            <Card kind={'article'}>
                {((props !== null) && (props !== undefined) && (props !== false) && (props.title !== undefined)) &&
                    <div>
                        <Card.Header classes={'btzCard-btzHeader-btzModal'}>
                            <Social kindcard={'article'} title={props.title} idfavorite={props.favoriteId} idtimeline={props.timeline} id={props.id} optfavid={props.optfavid} favorite={props.favorite} render={actions.rendering} propagate={props.propagate} displayedon={props.displayedon} cancelAction={props.propagateConfirmClosing}></Social>
                            {props.media !== undefined &&
                                <Card.Image kind={props.media}><Media src={props.media}></Media></Card.Image>
                            }
                            <p class='btzTimeline-btzCard-btzTitle btzTitle'>{props.title}</p>
                            <p class='btzTimeline-btzCard-btzSubTitle btzSubTitle btzTextColored' data-theme={'reading'}>{t('Article', {ns: 'timeline'})}</p>
                        </Card.Header>

                        <Card.Body classes={'btzCard-btzBody-btzModal btzTopCustomDotted'} style={{ 'margin-bottom': '100px' }}>
                            <p class='btzTimeline-btzCard-btzDescription btzTexte' innerHTML={<Convert separator={''} is_xhtml={true} type='nl2br'>{props.description}</Convert>}></p>
                            <p class='btzTimeline-btzCard-btzDescription btzTimeline-btzCard-btzContent btzTexte btzTopCustomDotted' innerHTML={<Convert separator={''} is_xhtml={true} type='nl2br'>{props.content}</Convert>}></p>

                            <div class='btzTimeline-btzCard-btzTimeline-btzRecipe-btzTimeline-btzTitles'>
                                {((props.tags) && (props.tags.length > 0)) &&
                                    <div class='btzTags-btzListing'>
                                        {props.tags.map((item) =>
                                            <div class='btzTag-btzEntry' data-theme={'reading'} onclick={() => { props.displayAlimentDetail(true); props.setTag(item.tag) }}>{item.name}</div>
                                        )}
                                    </div>
                                }
                            </div>
                        </Card.Body>
                    </div>
                }

                {((props === null) || (props === undefined) || (props === false) || (props.title === undefined)) &&
                    <div class='btzLoadingContainer-btzWrapper'>
                        <div class='btzLoadingContainer'></div>
                    </div>
                }
            </Card>
        }
    </div>
);