import { t } from '@app/utils';
import { Programs, Cardline, City, User } from '@app/api';
import { dynMod, addCSRFToken, dynLoad, getTodayYMD, getYMDfromDateObj, eraseCookie, readCookie, createCookie, isDefined, createUTCDate, capitalize } from '@app/core';
import { allRoutes } from '@app/settings';

import './../index.scss';

module.exports = {
    initAction: function() {
        let action = {
            onComponentCreate: (props) => (state, actions) => {
                actions.getPrograms();
            },
            getPrograms: () => (state, actions) => {
                let today = getTodayYMD();
                Programs.getRunning().then(function(res) {
                    let programs = res.data;
                    if (isDefined(programs) && (programs.length > 0)) {
                        let program = null, mostRecentStartDate = null, mostRecentStartHour = null, currentStartDate = null, currentStartHour = null;
                        console.log('programs ', programs);
                        for (let prog in programs) {
                            if (!isDefined(mostRecentStartDate)) {
                                mostRecentStartDate = programs[prog].startDate.date.split(' ')[0].replace(/-/, '');
                                mostRecentStartHour = programs[prog].startDate.date.split(' ')[1].split('.')[0].replace(/:/, '');
                                program = programs[prog];
                            } else {
                                currentStartDate = programs[prog].startDate.date.split(' ')[0].replace(/-/, '');
                                currentStartHour = programs[prog].startDate.date.split(' ')[1].split('.')[0].replace(/:/, '');
                            }
                            if ((currentStartDate + '' + currentStartHour) < (mostRecentStartDate + '' + mostRecentStartHour)) { // searching for oldest program
                                mostRecentStartDate = currentStartDate;
                                mostRecentStartHour = currentStartHour;
                                program = programs[prog];
                            }
                        }
                        if (isDefined(program)) {
                            program.nextSessionReadableDate = null;
                            if (program.nextSessionDate && program.nextSessionDate.date) {
                                program.nextSessionReadableDate = capitalize(actions.getReadableFromYMD(program.nextSessionDate.date.split(' ')[0].split('-').join('')));
                            }
                            program.sessionToday = false;
                            if ((program.nextSessionDate === null) || (actions.getRemainingTime(program) !== 0)) {
                                program.sessionToday = true;
                            }
                            actions.setProgram(program);
                        }
                        console.log('programs ', programs, program);
                    } else {
                        Programs.getRecommandedFollowingObjectives().then(function(res) {
                            var running = [];
                            for (var reco in res.data) {
                                let program = res.data[reco].program;
                                program.media = res.data[reco].media;
                                let programDate = (isDefined(program.dateNextActiveSession) ? program.dateNextActiveSession.date : null);
                                if (programDate !== null) {
                                    programDate = getYMDfromDateObj(new Date(programDate.split(' ')[0]));
                                    if (programDate.day !== undefined) {
                                        programDate = (programDate.year + '' + programDate.month + '' + programDate.day);
                                    }
                                }
                                if ((programDate === null) || (+programDate <= +today)) {
                                    running.push(program);
                                }
                            }
                            actions.setPrograms(running);
                        });
                    }
                });
            },
            startProgramSession: (programid) => (state, actions) => {
                window.location.href = addCSRFToken(allRoutes['private.program.session'].pathname.replace(':programid', programid));
            },
            showProgramDetail: (programid) => (state, actions) => {
                window.location.href = addCSRFToken(allRoutes['private.program.start'].pathname.replace(':programid', programid));
                // Programs.getUserProgram(programid).then((res) => {
                //     console.log('showProgramDetail : ', programid, res.data);
                //     let program = res.data;
                //     program.nextSessionReadableDate = null;
                //     if (program.nextSessionDate && program.nextSessionDate.date) {
                //         program.nextSessionReadableDate = capitalize(actions.getReadableFromYMD(program.nextSessionDate.date.split(' ')[0].split('-').join('')));
                //     }
                //     program.sessionToday = false;
                //     if ((program.nextSessionDate === null) || (actions.getRemainingTime(program) !== 0)) {
                //         program.sessionToday = true;
                //     }
                //     //
                //     actions.setProgram(program);
                // });
            },
            getRemainingTime: (card) => {
                if (!isDefined(card.nextSessionDate)) {
                    return 0;
                }
                var startDate = new Date();
                var endDate = new Date(card.nextSessionDate.date.split(' ')[0]);

                if (endDate.getTime() > startDate.getTime()) {
                    return 0;
                }

                // get total seconds between the times
                var delta = Math.abs(endDate.getTime() - startDate.getTime()) / 1000;

                // calculate (and subtract) whole days
                var days = Math.floor(delta / 86400);
                delta -= days * 86400;
                if (days === 0) {
                    days = '';
                } else {
                    days = days + 'j';
                }

                // calculate (and subtract) whole hours
                var hours = Math.floor(delta / 3600) % 24;
                delta -= hours * 3600;
                if (hours === 0) {
                    hours = '00';
                }
                hours = hours + 'h';

                // calculate (and subtract) whole minutes
                var minutes = Math.floor(delta / 60) % 60;
                delta -= minutes * 60;
                if (minutes === 0) {
                    '00';
                }
                minutes = minutes + 'min';
                return (days + hours + minutes);
            },
            getReadableFromYMD: (ymd) => (state, actions) => {
                var date = new Date(Date.parse(new Date(ymd.substr(0, 4) + '-' + ymd.substr(4, 2) + '-' + ymd.substr(6, 2))));
                var options = { weekday: 'long', month: 'long', day: 'numeric' };
                var readableDate = date.toLocaleDateString('fr-FR', options);
                return readableDate;
            },
            setProgram: newState => state => ({
                program: newState,
            }),
            setPrograms: newState => state => ({
                programs: newState,
            }),
            setState: ({ ns, key, value }) => state => ({
                [ns]: { ...state[ns], ...{ [key]: value } },
            }),
        }
        return action;
    }
}