import { h } from '@app/utils';

import CAsk from './state/ask';
import CWhy from './state/why';
import CRun from './state/run';

export const view = (state, actions) => (props, children) => (
    <div>
        {(!state.display.why && !state.display.accepted && (props.id !== undefined)) &&
            <div key={props.id}><CAsk {...props} send={actions.setState}></CAsk></div>
        }
        {state.display.why &&
            <div key={props.id}><CWhy {...props} send={actions.setState}></CWhy></div>
        }
        {state.display.accepted &&
            <div key={props.id}><CRun {...props} send={actions.setState} removeEndedChallenge={actions.removeEndedChallenge}></CRun></div>
        }
    </div>
);
