import { t, h } from '@app/utils';
import { Button, Tile, Card, Modal, Avatar, Balloon } from '@app/elements';

import Objectif from './objectif';

export const ModalView = (props, actions) => (
    <Card kind={'objectif'}>
        <Card.Header classes={'btzCard-btzHeader-btzModal'}>
            {props.customer === 'vyv' ?
                <p class='btzTitle btzXl'>{t('Quel(s) objectif(s) bien-être souhaitez-vous suivre ?', {ns: 'objectives'})}</p>
                :
                <p class='btzTitle btzXl'>{t('Quels objectifs santé souhaitez-vous suivre ?', {ns: 'objectives'})}</p>
            }
            <div class='btzObjectives-btzObjectivesModalWrapper'>
                {props.objectives.map(item =>
                    <Objectif key={'obj-' + item.id} {...item} chooseObjectif={props.chooseObjectif} displayedon={'modal'}></Objectif>
                )}
            </div>

            {props.error !== '' &&
                <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap', 'margin-top': '50px' }}>
                    <Tile.Icon classes={''}>
                        <Avatar.Alfred classes={'centered'} />
                    </Tile.Icon>
                    <Tile.Content>
                        <Balloon kind={'error'} style={{ 'white-space': 'normal' }} actionAfterError={props.updateError}>{props.error}</Balloon>
                    </Tile.Content>
                </Tile>
            }

            <Modal content={''} confirm={true} confirmConfirm={() => {
                props.confirmObjectif(props);
            }} title={t('Changements d’objectifs', {ns: 'objectives'})} description={t('Vous venez de modifier vos objectifs. Confirmez-vous votre choix ?', {ns: 'objectives'})}>
                <Button primary active onclick={(event) => {
                    props.confirmObjectifRules({ev: event, props: props})
                }}>
                    {t('Mettre à jour', {ns: 'generals'})}
                </Button>
            </Modal>
        </Card.Header>
    </Card>
);