import { t, h } from '@app/utils';
import { Button, Tile, Avatar, Balloon } from '@app/elements';
import { allRoutes } from '@app/settings';

import logoExpire from '@app/img/public/expire/you-are-expire.png';
import logoExpirex2 from '@app/img/public/expire/you-are-expire@2x.png';

import PublicLayout from '@app/layouts/public/layout';

export const view = (state, actions) => (props, children) => (
    <div key='publicexpire'>
        <PublicLayout page='expire' customer={props.customer}>
            <div class='publicexpire-expiresponsor'>
                <div style={{ position: 'relative' }}>
                    {state.kind === 'expire' ?
                        <Tile>
                            <div class='btzTitle-btzImage btzTitle-btzImage-absolute col-md-12 col-4'>
                                <img src={logoExpire} srcset={`${logoExpirex2} 2x`} alt='' class='' />
                            </div>
                            <Tile.Content classes='btzLeftHrArrowed col-xs-12 col-8'>
                                <p class='btzTitle btzXl'>{t('La licence de votre parrain est expirée', {ns: 'sponsor'})}</p>
                                <p class='btzTexte'>{t('Vous bénéficiez d‘un accès gratuit à la plateforme. Ceci grâce à votre parrain', {ns: 'sponsor'})}</p>

                                <div class='' style={{ 'margin': '30px 0' }}>
                                    <p class=''>
                                        {t('Mais cela ne vous empêche pas de poursuivre l’aventure !', {ns: 'sponsor'})}<br/>
                                        {t('Pour continuer à profiter de nos conseils, nos programmes, et préserver votre santé, vous pouvez souscrire à l’abonnement pour 14,90 euros par mois.', {ns: 'sponsor'})}
                                    </p>
                                </div>

                                <div class='' style={{ 'margin': '30px 0' }}>
                                    <p class=''>
                                        {t('Choisissez, ci-dessous, l‘option que vous souhaitez et laissez vous guider.', {ns: 'sponsor'})}
                                    </p>
                                </div>

                                <Button flat active onclick={() => {document.location.href = allRoutes['public.cb'].pathname}}>{t('Souscrire', {ns: 'sponsor'})}</Button>

                                <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap' }}>
                                    <Tile.Icon>
                                        <Avatar.Alfred classes={'centered'} />
                                    </Tile.Icon>
                                    <Tile.Content>
                                        <Balloon kind={'info'} style={{ 'white-space': 'normal' }}>
                                            {t('Pour toute question, n’hésitez pas à me contacter', {ns: 'sponsor'}) + ' : ' + t('alfred@betterise.me', {ns: 'generals'})}
                                            {t('Je me ferai un plaisir de vous aider.', {ns: 'sponsor'})}
                                        </Balloon>
                                    </Tile.Content>
                                </Tile>
                            </Tile.Content>
                        </Tile>
                        :
                        <Tile>
                            <div class='btzTitle-btzImage btzTitle-btzImage-absolute col-md-12 col-4'>
                                <img src={logoExpire} srcset={`${logoExpirex2} 2x`} alt='' class='' />
                            </div>
                            <Tile.Content classes='btzLeftHrArrowed col-xs-12 col-8'>
                                <p class='btzTitle btzXl'>{t('Votre parrain a résilié sa licence', {ns: 'sponsor'})}</p>
                                <p class='btzTexte'>{t('Vous bénéficiez d‘un accès gratuit à la plateforme. Ceci grâce à votre parrain.', {ns: 'sponsor'})}</p>

                                <p class='btzTexte' style={{ 'margin': '0' }}>{t('Celui-ci a résilié son compte.', {ns: 'sponsor'})}</p>

                                <div class='' style={{ 'margin': '30px 0' }}>
                                    <p class=''>
                                        {t('Mais cela ne vous empêche pas de poursuivre l’aventure !', {ns: 'sponsor'}, {ns: 'sponsor'})}<br/>
                                        {t('Pour continuer à profiter de nos conseils, nos programmes, et préserver votre santé, vous pouvez souscrire à l’abonnement pour 14,90 euros par mois.', {ns: 'sponsor'})}
                                    </p>
                                </div>

                                <Button flat active onclick={() => {document.location.href = allRoutes['public.cb'].pathname}}>{t('Souscrire', {ns: 'sponsor'})}</Button>

                                <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap' }}>
                                    <Tile.Icon>
                                        <Avatar.Alfred classes={'centered'} />
                                    </Tile.Icon>
                                    <Tile.Content>
                                        <Balloon kind={'info'} style={{ 'white-space': 'normal' }}>
                                            {t('Pour toute question, n’hésitez pas à me contacter', {ns: 'sponsor'}) + ' : ' + t('alfred@betterise.me', {ns: 'sponsor'})}
                                            {t('Je me ferai un plaisir de vous aider.', {ns: 'sponsor'})}
                                        </Balloon>
                                    </Tile.Content>
                                </Tile>
                            </Tile.Content>
                        </Tile>
                    }
                </div>
            </div>
        </PublicLayout>
    </div>

);