import { Component } from '@app/utils';
import { state } from './state';


import { dynLoad } from '@app/core';
const view = dynLoad('view', 'Register/step-cgu/views');
const actions = dynLoad('action', 'Register/step-cgu/actions');

export { state, actions, view };

export default Component(state, actions, view, 'step-cgu');
