import { t, h } from '@app/utils';
import { Button, Form } from '@app/elements';

import PublicLayout from '@app/layouts/public/layout';

export const view = (state, actions) => (props, children) => (
    <div key='publicsurvey'>
        <PublicLayout page='survey' customer={props.customer}>
            <div class='btzSurvey-btzSurveyHeader'>
                <p class='btzSurvey-btzSurveyHeader-btzLabel'>
                    {t('Merci de nous accorder quelques secondes de votre temps pour laisser votre impression. Vos réponses à ces questions aideront à améliorer le service.', {ns: 'public'})}
                </p>
            </div>

            <Form onsubmit={event => event.preventDefault()}>

                <div class='btzSurvey-btzSurveyBlock'>
                    <div class='btzSurvey-btzSurveyBlock-btzSurveyTitle'>
                        <p class='btzSurvey-btzSurveyBlock-btzSurveyTitle-btzPuce'>1</p>
                        <p class='btzSurvey-btzSurveyBlock-btzSurveyTitle-btzLabel'>
                            {t('Quel(s) objectif(s) avez-vous suivi(s) jusqu’à maintenant ?', {ns: 'public'})}

                            <font class='btzSurvey-btzSurveyBlock-btzSurveyTitle-btzSubLabel'>
                                {t('Plusieurs réponses possibles', {ns: 'public'})}
                            </font>
                        </p>
                    </div>

                    {state.objectives &&
                        <div>
                            {state.objectives.map(item =>
                                <div class='btzSurvey-btzSurveyBlock-btzSurveyEntry'>
                                    <Form.CheckBox
                                        placeholder={item.title}
                                        id={item.id}
                                        name='objectives'
                                        custom='btzSurvey'
                                    />
                                </div>
                            )}
                        </div>
                    }
                </div>

                <div class='btzSurvey-btzSurveyBlock'>
                    <div class='btzSurvey-btzSurveyBlock-btzSurveyTitle'>
                        <p class='btzSurvey-btzSurveyBlock-btzSurveyTitle-btzPuce'>2</p>
                        <p class='btzSurvey-btzSurveyBlock-btzSurveyTitle-btzLabel'>
                            {t('Qu’avez-vous apprécié dans ces objectifs ?', {ns: 'public'})}
                        </p>
                    </div>

                    <div class='btzSurvey-btzSurveyBlock-btzSurveyEntry'>
                        <Form.TextArea
                            placeholder={t('Écrivez votre réponse ici', {ns: 'public'})}
                            id='howversion'
                            name='howversion'
                            rows={7}
                        />
                    </div>
                </div>

                <div class='btzSurvey-btzSurveyBlock'>
                    <div class='btzSurvey-btzSurveyBlock-btzSurveyTitle'>
                        <p class='btzSurvey-btzSurveyBlock-btzSurveyTitle-btzPuce'>3</p>
                        <p class='btzSurvey-btzSurveyBlock-btzSurveyTitle-btzLabel'>
                            {t('Si vous deviez changer quelque chose dans ces objectifs, qu’est-ce que ce serait ?', {ns: 'public'})}
                        </p>
                    </div>

                    <div class='btzSurvey-btzSurveyBlock-btzSurveyEntry'>
                        <Form.TextArea
                            placeholder={t('Écrivez votre réponse ici', {ns: 'public'})}
                            id='howversion'
                            name='howversion'
                            rows={7}
                        />
                    </div>
                </div>

                <div class='btzSurvey-btzSurveyBlock'>
                    <div class='btzSurvey-btzSurveyBlock-btzSurveyTitle'>
                        <p class='btzSurvey-btzSurveyBlock-btzSurveyTitle-btzPuce'>4</p>
                        <p class='btzSurvey-btzSurveyBlock-btzSurveyTitle-btzLabel'>
                            {t('Ces objectifs vous ont-ils aidé à changer quelques mauvaises habitudes ?', {ns: 'public'})}
                        </p>
                    </div>

                    <div class='btzSurvey-btzSurveyBlock-btzSurveyEntry'>
                        <Form.Radio
                            placeholder={t('Oui', {ns: 'generals'})}
                            id='changehabits'
                            name='changehabits'
                            custom='btzSurvey'
                        />
                    </div>

                    <div class='btzSurvey-btzSurveyBlock-btzSurveyEntry'>
                        <Form.Radio
                            placeholder={t('Non', {ns: 'generals'})}
                            id='changehabits'
                            name='changehabits'
                            custom='btzSurvey'
                        />
                    </div>
                </div>

                <div class='btzSurvey-btzSurveyBlock'>
                    <div class='btzSurvey-btzSurveyBlock-btzSurveyTitle'>
                        <p class='btzSurvey-btzSurveyBlock-btzSurveyTitle-btzPuce'>5</p>
                        <p class='btzSurvey-btzSurveyBlock-btzSurveyTitle-btzLabel'>
                            {t('Une remarque supplémentaire ?', {ns: 'public'})}
                        </p>
                    </div>

                    <div class='btzSurvey-btzSurveyBlock-btzSurveyEntry'>
                        <Form.TextArea
                            placeholder={t('Écrivez votre réponse ici', {ns: 'public'})}
                            id='howversion'
                            name='howversion'
                            rows={7}
                        />
                    </div>
                </div>

                <Form.Group classes='btzForm-btzFooter'>
                    <Button primary flat onclick={actions.onSubmit}>
                        {t('Envoyer', {ns: 'generals'})}
                    </Button>
                </Form.Group>
            </Form>



        </PublicLayout>
    </div>

);