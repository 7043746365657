import { t, isAlphanumeric, isLength, debounce, throttle } from '@app/utils';

/*
TODO: wire to API when API is available
TODO: send secret choosen question and answer
*/

export const actions = {

    onComponentCreate: (props) => (state, actions) => {
        if (props.initial) {
            actions.getInitialStateAndUpdate(props.initial)
        }

        actions.setSecretQuestions([
            t('Quel est le nom de jeune fille de votre mère ?', {ns: 'register'}),
            t('Quel est le nom de votre premier animal de compagnie ?', {ns: 'register'}),
            t('Quel est le titre de votre film préféré ?', {ns: 'register'}),
            t('Quel était le prénom de votre maître(sse) d‘école ?', {ns: 'register'})
        ])
        actions.setSecretQuestionChoosen(0)
    },

    getInitialStateAndUpdate: (initial) => (state, actions) => {
        for (var ntl in initial) {
            let found = false;
            for (var stt in state) {
                if (stt === ntl)
                    found = true;
            }
            if (!found)
                continue;

            if (initial[ntl] == 'null') {
                initial[ntl] = '';
            }

            actions.setState({
                ns: ntl,
                key: 'value',
                value: initial[ntl]
            });
            if ((initial[ntl] !== null) && (initial[ntl].length > 0)) {
                actions.setAnswerValidity(isLength(initial[ntl], { min: 1 }));
                actions.setAnswerTouched(true);
            }
        }
    },

    setState: ({ ns, key, value }) => (state, actions) => ({
        [ns]: { ...state[ns], ...{ [key]: value } }
    }),

    setSecretQuestions: array => (state, actions) => actions.setState({
        ns: 'secret',
        key: 'questions',
        value: array
    }),

    setSecretQuestionChoosen: number => (state, actions) => actions.setState({
        ns: 'secret',
        key: 'choosen',
        value: number
    }),

    setAnswerValue: string => (state, actions) => actions.setState({
        ns: 'answer',
        key: 'value',
        value: string
    }),

    setAnswerTouched: boolean => (state, actions) => actions.setState({
        ns: 'answer',
        key: 'isTouched',
        value: boolean
    }),

    setAnswerValidity: boolean => (state, actions) => actions.setState({
        ns: 'answer',
        key: 'isValid',
        value: boolean
    }),

    setAnswerApproval: boolean => (state, actions) => actions.setState({
        ns: 'answer',
        key: 'isApproved',
        value: boolean
    }),

    setApiErrors: newState => state => ({
        api: newState,
    }),

    setApiStatus: number => (state, actions) => actions.setState({
        ns: 'api',
        key: 'status',
        value: number
    }),

    setApiMessage: string => (state, actions) => actions.setState({
        ns: 'api',
        key: 'message',
        value: string
    }),

    onSecretQuestionClickUpdate: () => (state, actions) => {
        actions.setSecretQuestionChoosen(
            ++state.secret.choosen % state.secret.questions.length
        )
    },

    onSecretQuestionClickThrottledUpdate: () => (state, actions) => throttle(
        600,
        'onSecretQuestionClickUpdate'
    ),

    onAnswerInputUpdate: string => (state, actions) => {
        actions.setAnswerValue(string)
        actions.setAnswerValidity(isLength(string, { min: 1 }))
    },

    onAnswerInputDebouncedUpdate: (string) => (state, actions) => debounce(
        600,
        'onAnswerInputUpdate',
        string
    ),

    onAnswerInputTouched: () => (state, actions) => {
        actions.setApiErrors({
            'message': '',
            'kind': 'info',
        });
        if (!state.answer.isTouched) {
            actions.setAnswerTouched(true)
        }
    },

    goToNextStep: () => (state, actions) => {
        window.main.location.go('/home/register/cgu');
    },

    updateParentProps: (props) => (state, actions) => {
        if (props) {
            // props.send({
            //     ns: 'secret',
            //     key: 'passed',
            //     value: true
            // })

            props.send({
                ns: 'user',
                key: 'question',
                value: state.secret.questions[state.secret.choosen]
            })

            props.send({
                ns: 'user',
                key: 'answer',
                value: state.answer.value
            })
        }
    },

    onStepSubmit: (event) => (state, actions) => {
        event.preventDefault();

        if (!state.answer.isValid) {
            actions.setApiErrors({
                'message': t('Merci de saisir une réponse à votre question secrète.', {ns: 'register'}),
                'kind': 'error',
            });
        } else {
            actions.goToNextStep()
        }
    },
};
