import { debounce } from 'hyperapp-fx';
import { t, isLength } from '@app/utils'
import { Session, User } from '@app/api';
import { enableTheseButton, desableTheseButton, getQueryVariable, displayErrors } from '@app/core';
import { allRoutes } from '@app/settings';

export const actions = {
    onComponentCreate: (props) => (state, actions) => {
        state.email = 'default@betterise.me';
        let email = getQueryVariable('email');
        let token = getQueryVariable('xtoken', true);
        if (token && (token !== null) && (token !== false)) {
            localStorage.setItem('apitoken', token);
        }

        if ((email === false) || (email === '')) {
            displayErrors('no user email provided, need to add ?email=[email] in URI.', 5000);
        } else {
            state.email = email;
        }

        actions.setApiErrors({
            'message' : t('En cas de doute, n’hésitez pas à me contacter', {ns: 'public'}) + ' : ' + 'alfred@betterise.me.',
            'kind' : 'info',
        });
    },

    displayResiliateForm: () => state => ({
        resiliateFormDisplay: true
    }),

    setState: ({ ns, key, value }) => state => ({
        [ns]: { ...state[ns], ...{ [key]: value } },
    }),

    setApiErrors: newState => state => ({
        api: newState,
    }),

    onUpdate: ({ target: { name, value } }) => (state, actions) => {
        let isValid = actions.isInputValid({ name, value });

        actions.setState({ns: name, key: 'isValid', value: isValid});
        actions.setState({ns: name, key: 'value', value: value});
    },

    isInputValid: ({ name, value }) => (state, actions) => {
        if (name === undefined) {
            return false;
        }

        switch (name) {
            case 'password':
                return isLength(value, { min: 1});
            default:
                throw Error(`${name} has no validator assigned`);
        }
    },

    waitForLastInput: ({ target }) => debounce(
        500,
        'onUpdate',
        { target },
    ),

    onBlur: ({ target: { name } }) => (state, actions) => {
        actions.setApiErrors({
            'message' : t('En cas de doute, n’hésitez pas à me contacter', {ns: 'public'}) + ' : ' + t('alfred@betterise.me.', {ns: 'public'}),
            'kind' : 'info',
        });
        actions.setState({ns: name, key: 'isTouched', value: true});
    },

    sendResiliation: () => (state, actions) => {
        if (state.submitting) {
            return false;
        }
        let pwd = state.password.value;
        let isValid = state.password.isValid;

        if (!isValid) {
            if (pwd === '') {
                actions.setApiErrors({
                    'message' : t('Merci de saisir votre mot de passe.', {ns: 'public'}),
                    'kind' : 'error',
                });
            } else if (pwd.length < 1) {
                actions.setApiErrors({
                    'message' : t('Votre mot de passe doit être composé de 1 caractère minimum.', {ns: 'public'}),
                    'kind' : 'error',
                });
            }
        } else {
            state.submitting = true;
            Session.createSession(state.email, pwd).then((res) => {
                if (res.data && res.data.code) {
                    state.submitting = false;
                    actions.setApiErrors(res.data);
                } else {
                    if (res.data === false) {
                        actions.setApiErrors({
                            'message' : t('Votre mot de passe semble invalide.', {ns: 'public'}),
                            'kind' : 'error',
                        });
                        state.submitting = false;
                    } else {
                        User.resiliate().then(() => {
                            actions.setApiErrors({
                                'message' : t('Votre résiliation est effective, vous allez être redirigé ...', {ns: 'public'}),
                                'kind' : 'success',
                            });
                            setTimeout(() => {
                                window.location.href = allRoutes['index'].pathname;
                            }, 5000);
                        });
                    }
                }
            });
        }
    },
};
