import * as staticsconstants from '@app/constants/constants-statics';
import { allRoutes } from '@app/settings';
import { isDefined } from '@app/core';
import { addCSRFToken } from './csrf';

const logoutAndRedirect = function(customer, mustBeRedirected = true) {
    if (!isDefined(customer)) {
        customer = process.env.APP_CUSTOMISATION;
    }
    //
    let persistLocalStorage = [];
    for (var i = 0, len = localStorage.length; i < len; ++i) {
        if ((localStorage.key(i).indexOf('csrftoken') > -1) || (localStorage.key(i).indexOf('onboarding-viewed-') > -1) || (localStorage.key(i).indexOf('language') > -1)) {
            persistLocalStorage.push({
                key: localStorage.key(i),
                value: localStorage.getItem(localStorage.key(i))
            });
        }
    }
    localStorage.clear();
    for (var j = 0, lenj = persistLocalStorage.length; j < lenj; ++j) {
        localStorage.setItem(persistLocalStorage[j].key, persistLocalStorage[j].value);
    }
    //
    let redirect = allRoutes['home.index'].pathname;
    if (isDefined(staticsconstants.CUSTOMERS[customer.toLowerCase()]) && isDefined(staticsconstants.CUSTOMERS[customer.toLowerCase()].logout)) {
        redirect = staticsconstants.CUSTOMERS[customer.toLowerCase()].logout;
    }

    if (mustBeRedirected) {
        setTimeout(() => {
            window.location.href = addCSRFToken(redirect);
        }, 210);
    }
}

const idleTime = 3600000; // 3600000 ms = 1 hour
let idleTimeout = null;
const resetIdleLogoutTimer = function() {
    clearTimeout(idleTimeout);
    idleTimeout = setTimeout(() => {
        logoutAndRedirect();
    }, idleTime);
}

const idleLogout = function() {
    window.onload = resetIdleLogoutTimer;
    window.onmousemove = resetIdleLogoutTimer;
    window.onmousedown = resetIdleLogoutTimer;  // catches touchscreen presses as well
    window.ontouchstart = resetIdleLogoutTimer; // catches touchscreen swipes as well
    window.onclick = resetIdleLogoutTimer;      // catches touchpad clicks as well
    window.onkeypress = resetIdleLogoutTimer;
    window.addEventListener('scroll', resetIdleLogoutTimer, true);
}

export { logoutAndRedirect, idleLogout };