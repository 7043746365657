import { Cardline, User } from '@app/api';
import { t, isLength } from '@app/utils';
import { isDefined, addCSRFToken } from '@app/core';
import { allRoutes } from '@app/settings';
import { debounce } from 'hyperapp-fx';


export const actions = {
    onComponentCreate: (props) => (state, actions) => {
        //
    },
    updateParentProps: ({props, e, kind}) => (state, actions) => {
        e.preventDefault(),
        e.stopPropagation();
        if (props) {
            props.removeNotification(props);
        }
        if (isDefined(kind)) {
            if (kind === 'knowit') {
                window.location.href = props.redirect;
                // actions.markCardAsRead({id: props.id, redirect: props.redirect})
            } else if (kind === 'program') {
                window.location.href = addCSRFToken(allRoutes['private.program.session'].pathname.replace(':programid', props.id));
            }
        }
        return false;
    },
    trim: (str) => {
        if (str.length > 250) {
            return (str.substr(0, 250) + '...');
        }
        return str;
    },
    rendering: () => state => ({
        rendering: !state.rendering,
    }),
    updateParentGeoloc: (props) => (state, actions) => {
        if (props) {
            props.getGeolocalisation();
        }
    },
    updateParentADHProps: (props) => (state, actions) => {
        // actions.setFavoriteState();
    },

    onUpdate: ({ target: { name, value } }) => (state, actions) => {
        actions.setValues({ [name]: value });
        actions.setValidity({
            [name]: actions.isInputValid({ name, value }),
        });
    },

    waitForLastInput: ({ target }) => debounce(
        500,
        'onUpdate',
        { target },
    ),

    setValues: newState => state => ({
        values: { ...state.values, ...newState },
    }),

    setTouched: newState => state => ({
        isTouched: { ...state.isTouched, ...newState },
    }),

    setValidity: newState => state => ({
        isValid: { ...state.isValid, ...newState },
    }),

    setApiErrors: newState => state => ({
        api: newState,
    }),

    markCardAsRead: ({id, redirect}) => (state, actions) => {
        if (id !== null) {
            Cardline.markCardAsRead(id).then(() => {
                window.location.href = redirect;
            });
        } else {
            window.location.href = redirect;
        }
    },

    displaySuccessMessage: newState => state => ({
        messageActive: newState,
    }),

    setModalDisplayed: newState => state => ({
        modalDisplayed: newState,
    }),

    isInputValid: ({ name, value }) => (state, actions) => {
        if (name === undefined) {
            return false;
        }

        switch (name) {
            case 'adh':
                return isLength(value, { min: 4});
            default:
                throw Error(`${name} has no validator assigned`);
        }
    },

    onBlur: ({ target: { name } }) => (state, actions) => {
        actions.setTouched({ [name]: true });
        actions.setApiErrors({
            'message' : t('Le numéro d‘adhérent est composé d‘au minimum 4 chiffres.', {ns: 'timeline'}),
            'kind' : 'info',
        });
    },

    onSubmit: ({event, props}) => (state, actions) => {
        event.preventDefault();

        const { adh } = state.values;

        if ((state.values.adh === '') || (state.values.adh === undefined)) {
            actions.setApiErrors({
                'message' : t('Merci de renseigner un numéro d‘adhérent.', {ns: 'timeline'}),
                'kind' : 'error',
            });
        } else if (!state.isValid.adh) {
            actions.setApiErrors({
                'message' : t('Veuillez renseigner un numéro d‘adhérent valide.', {ns: 'timeline'}),
                'kind' : 'error',
            });
        }

        if (state.isValid.adh) {
            User.updateUser({'membership': adh}).then(() => {
                actions.displaySuccessMessage(true);
                actions.setModalDisplayed(false);
                props.removeNotification(props);
            });
        }
    },
};
