import { t, h } from '@app/utils';
import { Card, Modal, MessagePopup } from '@app/elements';
import { dynMod, isDefined } from '@app/core';

import icoClose from '@app/img/ico/ico-close-black.png';
import icoClosex2 from '@app/img/ico/ico-close-black@2x.png';

import { ModalViewADH } from './modalView';

export const view = (state, actions) => (props, children) => (
    <div key={props.id} class='btzTimeline-btzNotification'>
        {props.kind === 'knowit' &&
            <div class='btzNotification-btzKnowIt'>
                <div class='btzTimeline-btzNotification-btzClose' onclick={(e) => { actions.updateParentProps({props: props, e: e}) }}>
                    <Card.Image><img src={icoClose} srcset={`${icoClosex2} 2x`} alt='' class='' /></Card.Image>
                </div>
                <div onclick={(e) => {actions.updateParentProps({props: props, e: e, kind: 'knowit'})}} style={{ 'cursor': 'pointer' }}>
                    <div class='col-9 btzCard-btzHeader btzTimeline-btzNotification-btzContent p-0'>
                        <p class='btzTimeline-btzCard-btzTitle btzTitle'>{t(props.title)}</p>
                        <p class='btzTexte' style={{ 'padding-right': '5px' }}>{props.content}</p>
                    </div>
                    <div class='col-3' style={{ 'padding': '0px', 'vertical-align': 'bottom' }}>
                        <Card.Image>
                            {(isDefined(props.media) && (props.media.url)) ?
                                <img src={props.media.url} srcset={`${props.media.url} 2x`} alt='' class='' />
                                :
                                <img src={props.image.normal} srcset={`${props.image.retina} 2x`} alt='' class='' />
                            }
                        </Card.Image>
                    </div>
                </div>
            </div>
        }
        {(dynMod('gamifications', 'isactive') && (props.kind === 'gamification')) &&
            <div class='btzNotification-btzGamification'>
                <div style={{ 'cursor': 'pointer' }} onclick={() => { window.location.href = props.redirect; }}>
                    <div class='col-9 btzCard-btzHeader btzTimeline-btzNotification-btzContent p-0'>
                        <p class='btzTimeline-btzCard-btzTitle btzTitle'>{t(props.title)}</p>
                        <p class='btzTexte' style={{ 'padding-right': '5px' }}>
                            {t('Vous êtes au', {ns: 'timeline'}) + ' '}<font>{t('niveau', {ns: 'timeline'}) + ' ' + (((props.current === null) || (props.current === undefined)) ? 'Ø' : props.current.value) + ' '}</font>{t('avec', {ns: 'timeline'})}<font>{' ' + (props.points === null ? 'Ø' : props.points) + ' ' + t('points', {ns: 'timeline'})}</font> !<br/>
                            {t('Rendez-vous sur la page Badges pour en savoir plus.', {ns: 'timeline'})}
                        </p>
                    </div>
                    <div class='col-3' style={{ 'padding': '0px', 'vertical-align': 'middle' }}>
                        <Card.Image><img src={props.image.normal} srcset={`${props.image.retina} 2x`} alt='' class='' /></Card.Image>
                    </div>
                </div>
            </div>
        }
        {(props.kind === 'adh') &&
            <div class='btzNotification-btzADH'>
                {state.messageActive &&
                    <MessagePopup kind={'success'} active={'active'} duration={3000} durationCallback={() => actions.displaySuccessMessage(false)} message={t('Votre numéro d‘adhérent a bien été enregistré !', {ns: 'timeline'})}></MessagePopup>
                }
                <Modal modalDisplayed={state.modalDisplayed} content={<ModalViewADH {...state} onSubmit={actions.onSubmit} onBlur={actions.onBlur} waitForLastInput={actions.waitForLastInput} propagate={actions.updateParentADHProps} removeNotification={props.removeNotification}></ModalViewADH>}>
                    <div onclick={() => { actions.setModalDisplayed(true); }}>
                        <div class='btzTimeline-btzNotification-btzClose' onclick={(e) => { actions.updateParentProps({props: props, e: e}) }}>
                            <Card.Image><img src={icoClose} srcset={`${icoClosex2} 2x`} alt='' class='' /></Card.Image>
                        </div>
                        <div class='col-9 btzCard-btzHeader btzTimeline-btzNotification-btzContent p-0'>
                            <p class='btzTimeline-btzCard-btzTitle btzTitle'>{t(props.title)}</p>
                            {/*<div class='btzTexte' style={{ 'padding': '0px' }}>
                                {t(props.content)}
                                <Modal content={<ModalViewADH {...state} onSubmit={actions.onSubmit} onBlur={actions.onBlur} waitForLastInput={actions.waitForLastInput} propagate={actions.updateParentADHProps}></ModalViewADH>}>
                                    <font class='btzFontA' style={{ 'float': 'right', 'display': 'inline-block' }} data-id={props.id}>{t('cliquez ici', {ns: 'generals'})}</font>
                                </Modal>
                            </div>*/}
                            <p class='btzTexte' style={{ 'padding-right': '5px' }}>{t(props.content) + ' '}<font class='btzFontA'>{t('Cliquez ici', {ns: 'timeline'})}</font>.</p>
                        </div>
                        <div class='col-3' style={{ 'padding': '0px', 'vertical-align': 'bottom' }}>
                            <Card.Image><img src={props.image.normal} srcset={`${props.image.retina} 2x`} alt='' class='' /></Card.Image>
                        </div>
                    </div>
                </Modal>
            </div>
        }
        {(dynMod('programs', 'isactive') && (props.kind === 'program')) &&
            <div onclick={(e) => {actions.updateParentProps({props: props, e: e, kind: 'program'})}} style={{ 'cursor': 'pointer' }}>
                <div class='btzNotification-btzProgram'>
                    <div class='btzTimeline-btzNotification-btzClose' onclick={(e) => { actions.updateParentProps({props: props, e: e}) }}>
                        <Card.Image><img src={icoClose} srcset={`${icoClosex2} 2x`} alt='' class='' /></Card.Image>
                    </div>
                    <div class='col-9 btzCard-btzHeader btzTimeline-btzNotification-btzContent p-0'>
                        <p class='btzTimeline-btzCard-btzTitle btzTitle'>{t('Programme', {ns: 'timeline'})} #{+props.idx + 1}</p>
                        <p class='btzTexte' style={{ 'padding-right': '5px' }}>{t('Vous avez une séance du programme', {ns: 'timeline'}) + ' '}<font>« {props.title} »</font>{' ' + t('à faire aujourd’hui.', {ns: 'timeline'})}</p>
                    </div>
                    {((props.media !== undefined) && (props.media.url !== undefined)) &&
                        <div class='col-3 p-0' style={{ 'text-align': 'right', 'height': '59px', 'max-width': '59px' }}>
                            <div class='btzBackgroundCover' style={{ 'display': 'inline-block', 'width': '100%', 'height': '100%', 'border-radius': '50px', 'padding': '0px', 'vertical-align': 'middle', 'background-image': 'url(' + props.media.url + ')' }}></div>
                        </div>
                    }
                </div>
            </div>
        }
    </div>
);
