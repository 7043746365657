import { t, h, Component } from '@app/utils';
import { Grid, Link, Navbar, TopSticked, Enter } from '@app/elements';
import * as constants from '@app/constants';

import logoBurger from '@app/img/ico/ico-burger.png';
import logoBurgerx2 from '@app/img/ico/ico-burger@2x.png';

import imgHeaderFullWidth from '@app/img/timeline/img-timeline-header.png';
import imgHeaderFullWidthx2 from '@app/img/timeline/img-timeline-header@2x.png';

import { TopMenuView } from '@app/layouts/topmenu';
import { TopLogoView } from '@app/layouts/toplogo';

const actions = {
    goBack: () => state => {
        window.history.back();
    },
}

const view = (state, actions) => (props, children) => (
    <div key={'parameters'} class={props.page} data-theme={props.theme}>
        <Enter time={constants.custo.ANIM_DURATION} easing={constants.custo.ANIM_EASING} css={constants.custo.ANIM_CSS}>
            <Grid classes={'btzHeader'}>
                <Grid.Row classes={'btzHeaderWrapper'}>
                    <Grid.Col x={10} mx={'auto'}>
                        <Navbar>
                            <label for='show-menu' class='btzMenu-btzResponsiveMenu'>
                                <img id='btzBurgerMenu-btzBurgerMenuImg' src={logoBurger} srcset={`${logoBurgerx2} 2x`} alt='' />
                            </label>
                            <input id='show-menu' role='button' type='checkbox' />

                            <Navbar.Section classes='btzHeader-btzHeaderLeftHand'>
                                <TopLogoView {...props} customer={(props.customer || 'betterise')}></TopLogoView>
                            </Navbar.Section>

                            <Navbar.Section classes='btzHeader-btzHeaderRightHand'>
                                <TopMenuView {...props} customer={(props.customer || 'betterise')}></TopMenuView>
                            </Navbar.Section>
                        </Navbar>

                    </Grid.Col>
                </Grid.Row>
            </Grid>
            <Grid.Row classes={'btzHeaderImageFullWidthWrapper'}>
                <Grid.Col x={10} mx={'auto'}>
                    <img src={imgHeaderFullWidth} srcset={`${imgHeaderFullWidthx2} 2x`} alt='' />

                    <div class='btzHeaderImageFullWidthWrapper-btzContent'>
                        <div class='btzHeaderImageFullWidthWrapper-btzContent-btzWrapper'>
                            <p class='btzTitle btzXl' style={{ 'text-transform': 'capitalize' }}>{props.readablePageLabel}</p>
                            <p class='btzSubTitle'>{props.readablePageDescription}</p>
                        </div>
                    </div>
                </Grid.Col>
            </Grid.Row>

            <Grid classes={''} style={{ 'padding-bottom': '100px', 'padding-top': '20px' }}>
                <Grid.Row classes={'col-10 col-mx-auto'}>
                    <Grid.Col x={3} mx={'auto'} classes={'hide-md'} style={{ 'position': 'relative' }}>
                        <TopSticked topdetection={180} classes={'btzStickedWithNoStyle'} top={180}>
                            <Navbar.Section classes='btzDatasMenu'>
                                <ul style={{ 'text-align': 'center' }}>
                                    <li data-theme={'shape'} class={props.desabledDash.indexOf('5') > -1 ? 'eyeoff' : 'eyeon'}><Link scrollto={'shape'}>{t('Moral', {ns: 'datas'})}</Link><div onclick={() => {props.toggleDash({dash: '5', mode: 0})}} class='btzEyeOn' alt=''></div><div onclick={() => {props.toggleDash({dash: '5', mode: 1})}} class='btzEyeOff' alt=''></div></li>
                                    <li data-theme={'activity'} class={props.desabledDash.indexOf('2') > -1 ? 'eyeoff' : 'eyeon'}><Link scrollto={'activity'}>{t('Marche', {ns: 'datas'})}</Link><div onclick={() => {props.toggleDash({dash: '2', mode: 0})}} class='btzEyeOn' alt=''></div><div onclick={() => {props.toggleDash({dash: '2', mode: 1})}} class='btzEyeOff' alt=''></div></li>
                                    <li data-theme={'sport'} class={props.desabledDash.indexOf('19') > -1 ? 'eyeoff' : 'eyeon'}><Link scrollto={'sport'}>{t('Sport', {ns: 'datas'})}</Link><div onclick={() => {props.toggleDash({dash: '19', mode: 0})}} class='btzEyeOn' alt=''></div><div onclick={() => {props.toggleDash({dash: '19', mode: 1})}} class='btzEyeOff' alt=''></div></li>
                                    <li data-theme={'denivele'} class={props.desabledDash.indexOf('20') > -1 ? 'eyeoff' : 'eyeon'}><Link scrollto={'denivele'}>{t('Dénivelé', {ns: 'datas'})}</Link><div onclick={() => {props.toggleDash({dash: '20', mode: 0})}} class='btzEyeOn' alt=''></div><div onclick={() => {props.toggleDash({dash: '20', mode: 1})}} class='btzEyeOff' alt=''></div></li>
                                    <li data-theme={'sleep'} class={props.desabledDash.indexOf('3') > -1 ? 'eyeoff' : 'eyeon'}><Link scrollto={'sleep'}>{t('Sommeil', {ns: 'datas'})}</Link><div onclick={() => {props.toggleDash({dash: '3', mode: 0})}} class='btzEyeOn' alt=''></div><div onclick={() => {props.toggleDash({dash: '3', mode: 1})}} class='btzEyeOff' alt=''></div></li>
                                    <li data-theme={'tiredness'} class={props.desabledDash.indexOf('18') > -1 ? 'eyeoff' : 'eyeon'}><Link scrollto={'tiredness'}>{t('Fatigue', {ns: 'datas'})}</Link><div onclick={() => {props.toggleDash({dash: '18', mode: 0})}} class='btzEyeOn' alt=''></div><div onclick={() => {props.toggleDash({dash: '18', mode: 1})}} class='btzEyeOff' alt=''></div></li>
                                    <li data-theme={'stress'} class={props.desabledDash.indexOf('4') > -1 ? 'eyeoff' : 'eyeon'}><Link scrollto={'stress'}>{t('Stress', {ns: 'datas'})}</Link><div onclick={() => {props.toggleDash({dash: '4', mode: 0})}} class='btzEyeOn' alt=''></div><div onclick={() => {props.toggleDash({dash: '4', mode: 1})}} class='btzEyeOff' alt=''></div></li>
                                    <li data-theme={'tobacco'} class={props.desabledDash.indexOf('6') > -1 ? 'eyeoff' : 'eyeon'}><Link scrollto={'tobacco'}>{t('Tabac', {ns: 'datas'})}</Link><div onclick={() => {props.toggleDash({dash: '6', mode: 0})}} class='btzEyeOn' alt=''></div><div onclick={() => {props.toggleDash({dash: '6', mode: 1})}} class='btzEyeOff' alt=''></div></li>
                                    <li data-theme={'alcool'} class={props.desabledDash.indexOf('8') > -1 ? 'eyeoff' : 'eyeon'}><Link scrollto={'alcool'}>{t('Alcool', {ns: 'datas'})}</Link><div onclick={() => {props.toggleDash({dash: '8', mode: 0})}} class='btzEyeOn' alt=''></div><div onclick={() => {props.toggleDash({dash: '8', mode: 1})}} class='btzEyeOff' alt=''></div></li>
                                    <li data-theme={'followed-weight'} class={props.desabledDash.indexOf('16') > -1 ? 'eyeoff' : 'eyeon'}><Link scrollto={'followed-weight'}>{t('Poids', {ns: 'datas'})}</Link><div onclick={() => {props.toggleDash({dash: '16', mode: 0})}} class='btzEyeOn' alt=''></div><div onclick={() => {props.toggleDash({dash: '16', mode: 1})}} class='btzEyeOff' alt=''></div></li>
                                </ul>
                            </Navbar.Section>
                        </TopSticked>
                    </Grid.Col>
                    <Grid.Col x={9} md={12} mx={'auto'} style={{ 'position': 'relative' }}>
                        {children}
                    </Grid.Col>
                </Grid.Row>
            </Grid>
        </Enter>
    </div>
);

export default Component({}, actions, view, 'layout');