import { t } from '@app/utils'
import { Session } from '@app/api';
import { getQueryVariable, isEmail } from '@app/core';
import { debounce } from 'hyperapp-fx';

export const actions = {

    onComponentCreate: (props) => (state, actions) => {
        actions.resetApiError();

        let token = getQueryVariable('xtoken', false);
        if (token && (token !== null)) {
            localStorage.setItem('apitoken', token);
        }

        let email = getQueryVariable('email', false);
        if ((email !== '') && (email !== null) && (email !== undefined) && (email !== false)) {
            let name = 'email';
            actions.setValues({ 'email': email });
            let isValid = actions.isInputValid({ name, email });
            // let isValid = actions.isInputValid({name: name, email: email});
            if (isValid) {
                actions.setValidity({
                    'email': isValid,
                });
            } else {
                actions.displayEmailInput(true);
            }
        } else {
            actions.displayEmailInput(true);
        }
    },

    sendEmail: () => (state, actions) => {
        if ((state.values.email !== null) && (state.values.email !== '')) {
            actions.setFormIsSubmitting(true);
            Session.resetSession(state.values.email, null).then((res) => {
                actions.setFormIsSubmitting(false);
                if (res.data) {
                    actions.displaySuccessMessage(true);
                } else {
                    actions.setApiErrors({
                        'message' : t('Adresse email non reconnue.', {ns: 'login'}),
                        'code' : 'error',
                    });
                }
            });
        } else {
            actions.setApiErrors({
                'message' : t('Veuillez renseigner un email valide.', {ns: 'login'}),
                'code' : 'error',
            });
        }
    },

    isInputValid: ({ name, value }) => (state, actions) => {
        if (name === undefined) {
            return false;
        }
        switch (name) {
            case 'email':
                return isEmail(value);
            default:
                throw Error(`${name} has no validator assigned`);
        }
    },

    onBlur: ({ target: { name } }) => (state, actions) => {
        actions.resetApiError();
        actions.setTouched({ [name]: true });
    },

    waitForLastInput: ({ target }) => debounce(
        500,
        'onUpdate',
        { target },
    ),

    onUpdate: ({ target: { name, value } }) => (state, actions) => {
        actions.setValues({ [name]: value });
        actions.setValidity({
            [name]: actions.isInputValid({ name, value }),
        });
    },

    resetApiError: () => (state, actions) => {
        actions.setApiErrors({
            'message' : t('Vous avez une question ?', {ns: 'login'}) + '\n' + t('Contactez-moi :', {ns: 'login'}),
            'code' : 'info',
        });
    },

    setValues: newState => state => ({
        values: { ...state.values, ...newState },
    }),

    setTouched: newState => state => ({
        isTouched: { ...state.isTouched, ...newState },
    }),

    setValidity: newState => state => ({
        isValid: { ...state.isValid, ...newState },
    }),

    setFormIsSubmitting: newState => state => ({
        formIsSubmitting: newState,
    }),

    displaySuccessMessage: newState => state => ({
        messageActive: newState,
    }),

    displayEmailInput: newState => state => ({
        emailInputDisplayed: newState,
    }),

    setApiErrors: newState => state => ({
        api: newState,
    }),
}
