export const actions = {
    onComponentCreate: (props) => (state, actions) => {
        if (props.ymdMealDate) {
            actions.parseReadableMealDate(props.ymdMealDate);
        }
    },

    parseReadableMealDate: (dat) => (state, actions) => {
        dat = dat.substr(0, 4) + '-' + dat.substr(4, 2) + '-' + dat.substr(6, 2);
        var date = new Date(Date.parse(new Date(dat)));
        var options = { month: 'long', day: 'numeric', weekday: 'long' };
        actions.setReadableMealDate(date.toLocaleDateString('fr-FR', options))
    },

    setReadableMealDate: newState => state => ({
        readableMealDate: newState,
    }),
};
