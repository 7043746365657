export const state = {
    rendering: false,
    notifications: [],
    programs: null,
    userProfil: null,
    meteo: null,
    adh: null,
    gamification: null,
    googleApiKey: '',
    dayUntilTomorrow: 1,
    geolocIsLoading: false,
    geolocError: false,
};
