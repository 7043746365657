import { t, h } from '@app/utils';
import { isDefined } from '@app/core';
import { Avatar, Balloon, Button, Form, Tile } from '@app/elements';

module.exports = {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <div key={state.loginStep} style={{ position: 'relative' }} class={'login-step-' + state.loginStep}>
                {(state.loginStep === 0) ?
                    <dummy>
                        <div class='btzClickOverlay' onclick={actions.nextStep}></div>
                        <Tile>
                            <Tile.Content>
                                <p class='btzSubTitle btzXl' style={{ 'margin-bottom': '0' }}>
                                    {t('Dans le cadre de votre télésurveillance, merci de répondre aux questions suivantes', {ns: 'login'})}
                                </p>
                                <div class='btzHr btzHrSmallAndHeavy'></div>
                                <p class='btzSubTitle btzXl' style={{ 'margin-bottom': '0' }}>
                                    {t('Appuyez sur Continuer', {ns: 'login'})}
                                </p>
                            </Tile.Content>
                        </Tile>

                        {isDefined(state.medicalInfos) &&
                            <div class='ovrFixed ovrFixedAuthent'>
                                <p class=''>{t('Version', {ns: 'login'}) + ' : ' + (process.env.APP_VERSION || 'N/A')}</p>
                                <p class=''>{(state.medicalInfos.user || 'N/A')}</p>
                                <p class=''>{(state.medicalInfos.medicalStudy || 'N/A')}</p>
                            </div>
                        }

                        <Form onsubmit={event => event.preventDefault()}>
                            <Form.Group classes='btzForm-btzFooter'>
                                <Button primary active>
                                    {t('Continuer', {ns: 'login'})}
                                </Button>
                            </Form.Group>
                        </Form>
                    </dummy>
                    :
                    <dummy>
                        <Tile>
                            <Tile.Content>
                                <p class='btzTitle btzXl' style={{ 'margin-bottom': '0' }}>
                                    {t('Vous identifier', {ns: 'login'})}
                                </p>
                                <p class='btzSubTitle btzXl' style={{ 'margin-bottom': '0' }}>
                                    {t('Renseignez votre date de naissance au format jj/mm/aaaa pour confirmer votre identité', {ns: 'login'})}
                                </p>
                            </Tile.Content>
                        </Tile>

                        <Form onsubmit={event => event.preventDefault()}>

                            <Form.Group>
                                <Form.Input
                                    classes={'btzFullWidth'}
                                    placeholderYear={t('1950', {ns: 'login'})}
                                    placeholderMonth={t('01', {ns: 'login'})}
                                    placeholderDay={t('01', {ns: 'login'})}
                                    type={'date'}
                                    isMobile={true}
                                    autofocus={true}
                                    id={'birthdate'}
                                    name={'birthdate'}
                                    value={state.birthdate}
                                    onblur={actions.onBlur}
                                    oninput={actions.onUpdate}
                                    error={(state.isTouched.year && state.isTouched.month && state.isTouched.day) && (!state.isValid.year && !state.isValid.month && !state.isValid.day)}
                                    classes={(state.isTouched.year && state.isTouched.month && state.isTouched.day) ? ((state.isValid.year && state.isValid.month && state.isValid.day) ? 'is-success' : 'is-error') : ''}
                                />
                            </Form.Group>

                            <Form.Group classes='btzForm-btzFooter'>
                                <Button primary active={(state.isValid.year && state.isValid.month && state.isValid.day)} loading={(state.formIsSubmitting)} onclick={actions.onSubmit}>
                                    {t('Continuer', {ns: 'login'})}
                                </Button>
                            </Form.Group>
                        </Form>

                        <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap' }}>
                            <Tile.Icon>
                                <Avatar.Alfred kind={state.api.kind} classes={'centered'} />
                            </Tile.Icon>
                            <Tile.Content>
                                <Balloon kind={state.api.kind} style={{ 'white-space': 'normal' }}>
                                    {state.api.message}
                                </Balloon>
                            </Tile.Content>
                        </Tile>
                    </dummy>
                }
            </div>
        );
        return view;
    }
}
