import { h, Component } from '@app/utils';
import './index.scss';

import LoginLayout from '@app/layouts/register/layout';

import Authentication from './authentication';
import Recovery from './recovery';
import Blockd from './blockd';

const state = {
    email: {
        value: '',
        isValid: false,
        isApproved: null,
        isResetted: null
    },
    password: {
        value: '',
        isValid: false,
        isApproved: null,
        isResetted: null
    },
    day: {
        value: '',
        isValid: false,
        isApproved: null,
        isResetted: null
    },
    month: {
        value: '',
        isValid: false,
        isApproved: null,
        isResetted: null
    },
    year: {
        value: '',
        isValid: false,
        isApproved: null,
        isResetted: null
    },
    specialClass: {
        value: '',
    },
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        // localStorage.clear();
    },
    setState: ({ ns, key, value }) => state => ({
        [ns]: { ...state[ns], ...{ [key]: value } },
    })
};

const view = (state, actions) => ({ location, match, customer, language }, children) => (
    <div key='login' class={state.specialClass.value + ' specificFullHeightMobile'}>
        <LoginLayout page='login' customer={customer}>

            {/* Default view */}
            {match.isExact && (
                <Authentication initial={state.email} customer={customer} send={actions.setState} language={language} isMedical={false} />
            )}

            {(location.pathname.indexOf('/medical-study/') > -1) && (
                <Authentication location={location} match={match} initial={state.email} customer={customer} send={actions.setState} isMedical={true} />
            )}

            {location.pathname === '/home/login/recovery' && (
                <Recovery initial={state.email} customer={customer} send={actions.setState} />
            )}

            {location.pathname === '/home/login/blocked' && (
                <Blockd initial={state.email} customer={customer} send={actions.setState} />
            )}

        </LoginLayout>
    </div>
);

export {
    Authentication,
    Recovery,
    Blockd,
};

export default Component(state, actions, view, 'login');
