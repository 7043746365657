import { User } from '@app/api';
import { t, h, Component } from '@app/utils';
import { Button, Card, MessagePopup } from '@app/elements';
import { getCustomerInfos } from '@app/core';
import './index.scss';

const state = {
    messageActive: false,
}

const actions = {
    onComponentCreate: () => (state, actions) => {

    },
    displaySuccessMessage: newState => state => ({
        messageActive: newState,
    }),
    downloadRGPDDatas: () => (state, actions) => {
        User.downloadRGPDDatas().then((res) => {
            if (res.data.message === 'ok') {
                actions.displaySuccessMessage(true);
            }
        });
    }
};

const view = (state, actions) => (props, children) => (
    <Card id={'dataprofil'} key={'param-data'} classes={'btzPageCard btzPageCard-btzPageCardNoPadding'} kind={'param'}>
        {state.messageActive &&
            <MessagePopup kind={'success'} active={'active'} duration={3000} durationCallback={() => actions.displaySuccessMessage(false)} message={t('Le mail a été envoyé avec succès !', {ns: 'dataprofil'})}></MessagePopup>
        }

        <Card.Header classes={'btzCard-btzHeader-btzPage'}>
            <div class='col-12'>
                <p class='btzTimeline-btzCard-btzTitle btzPageContentTitle'>{t('Données de profil', {ns: 'dataprofil'})}</p>
                <p class='btzTimeline-btzCard-btzSubTitle btzPageContentTexte'>
                    {t('Vous souhaitez connaître les données que vous partagez sur votre profil ?', {ns: 'dataprofil'})}
                    <br/>
                    {t('Cliquez sur le bouton ci-dessous pour recevoir un mail contenant vos données.', {ns: 'dataprofil'})}
                </p>
            </div>
        </Card.Header>

        <Card.Body>
            <div>
                <Button primary active flat style={{ 'color': 'white' }} onclick={() => {
                    actions.downloadRGPDDatas()
                }}>
                    {t('Télécharger mes données', {ns: 'dataprofil'})}
                </Button>
            </div>

            <p class='col-12 btzTimeline-btzCard-btzSubTitle btzPageContentTexte' style={{ 'margin-top': '20px' }}>
                {t('Vous pouvez aussi choisir de recevoir ces données cryptées. Pour ce faire, contactez', {ns: 'dataprofil'}) + ' ' + getCustomerInfos(props.customer)['helper'] + ' ' + t('à l‘adresse suivante', {ns: 'dataprofil'}) + ' :'}
                <br/><a href={'mailto:' + getCustomerInfos(props.customer)['mailto']}>{getCustomerInfos(props.customer)['mailto']}</a>
            </p>
        </Card.Body>

        <Card.Footer></Card.Footer>
    </Card>
);

export default Component(state, actions, view, 'dataprofil');
