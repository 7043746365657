import { t, h, Component } from '@app/utils';
import { allRoutes } from '@app/settings';
import { Link, Button } from '@app/elements';
import { createCookie, readCookie, getCustomerInfos } from '@app/core';
import * as staticsconstants from '@app/constants/constants-statics';
import './index.scss';


const BotDisclamerView = Component(
    {
        displayed: true,
        urlPdfCgu: allRoutes['pdf.cgu'].pathname,
    },
    {
        onComponentUpdate: (props) => (state, actions) => {
            if (props.customer) {
                if (staticsconstants.CUSTOMERS[props.customer] !== undefined) {
                    let customerInformations = getCustomerInfos(props.customer);
                    let lang = 'fr';

                    if (customerInformations.pdf !== undefined) {
                        if (customerInformations.pdf.cgu !== undefined) {
                            let cguUrl = allRoutes[customerInformations.pdf.cgu];
                            if (cguUrl && (cguUrl !== undefined) && (cguUrl.pathname !== undefined)) {
                                cguUrl = cguUrl.pathname;
                                if (cguUrl.indexOf(':lang') > -1) {
                                    cguUrl = cguUrl.replace(':lang', lang);
                                }
                                actions.setUrlPdfCgu(cguUrl);
                            }
                        }
                    }
                }
            }
        },
        removeDisclamer: () => state => ({
            displayed: false
        }),
        setUrlPdfCgu: newState => state => ({
            urlPdfCgu: newState
        }),
    },
    (state, actions) => (props, children) => (
        <div>
            {(((readCookie('rgpd') !== 'accepted') || (readCookie('rgpd') === null)) && (state.displayed)) &&
                <div id='btzDisclamer-btzDisclamers' class='btzDisclamer-btzDisclamers'>
                    <div class='col-12 p-0 btzDisclamer-btzDisclamers-btzWrapper'>
                        <div class='col-8 col-xs-12'>
                            <p class='btzDisclamer-btzDisclamer'>{t('En poursuivant votre navigation vous acceptez les CGU et par conséquent l‘utilisation de cookies pour vous proposer des services et offres adaptés à vos centres d‘intérêts.', {ns: 'generals'})} <Link to={state.urlPdfCgu} target={'_blank'}>{t('En savoir plus', {ns: 'generals'})}</Link>.</p>
                        </div>
                        <div class='col-4 col-xs-12'>
                            <p class='btzDisclamer-btzDisclamer btzDisclamer-btzDisclamer-btzLinks'>
                                <Button flat active onclick={() => {
                                    createCookie('rgpd', 'accepted', '');
                                    actions.removeDisclamer();
                                }}>{t('J‘ai compris', {ns: 'generals'})}</Button>
                            </p>
                        </div>
                    </div>
                </div>
            }
        </div>
    ),
    'botdisclamerview'
);

export { BotDisclamerView };