import { debounce } from 'hyperapp-fx';
import { t, isLength } from '@app/utils'
import { isDefined } from '@app/core'
import { User } from '@app/api';

export const actions = {
    onComponentCreate: (props) => (state, actions) => {
        User.getUser().then((res) => {
            if (isDefined(res.data)) {
                User.getUserQuestion(res.data.email).then((ress) => {
                    actions.setState({ns: 'question', key: 'value', value: ress.data.question});
                });
            }
        });
    },

    onUpdate: ({ target: { name, value } }) => (state, actions) => {
        let isValid = actions.isInputValid({ name, value });

        state.api.message = '';
        state.api.kind = 'info';
        actions.setState({ns: name, key: 'value', value: value});
        actions.setState({ns: name, key: 'isValid', value: isValid});
    },

    waitForLastInput: ({ target }) => debounce(
        500,
        'onUpdate',
        { target },
    ),

    isInputValid: ({ name, value }) => (state, actions) => {
        if (name === undefined) {
            return false;
        }

        switch (name) {
            case 'secret':
                return isLength(value, { min: 1});
            default:
                throw Error(`${name} has no validator assigned`);
        }
    },

    setState: ({ ns, key, value }) => state => ({
        [ns]: { ...state[ns], ...{ [key]: value } },
    }),

    onBlur: ({ target: { name } }) => (state, actions) => {
        state.api.message = '';
        state.api.kind = 'info';
        actions.setState({ns: name, key: 'isTouched', value: true});
    },

    onSubmit: (event) => (state, actions) => {
        event.preventDefault();

        let secret = state.secret.value;

        if (secret === '') {
            state.api.message = t('Merci de renseigner une réponse à la question secrète.', {ns: 'public'});
            state.api.kind = 'error';
            actions.rendering();
        }

        /*User.sendSecret(secret).then((res) => {
            if (res.data.message === 'ok') {
                state.api.message = t('La réponse est bonne! Vous allez être redirigé vers la modification de mot de passe.', {ns: 'public'});
                state.api.kind = 'success';
            } else {
                state.api.message = t('La réponse est éronnée! Veuillez ré-essayer.', {ns: 'public'});
                state.api.kind = 'error';
            }
            actions.rendering();
        });*/
    },

    rendering: () => state => ({
        rendering: !state.rendering
    }),
};
