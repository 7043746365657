import { t, h } from '@app/utils';
import { allRoutes } from '@app/settings';
import { User } from '@app/api';

export const actions = {
    onComponentCreate: (props) => (state, actions) => {
        actions.resetApiError();
    },

    setErrorMessage: (string) => ({ apiErrors: string }),
    setErrorStatus: (string) => ({ apiErrorsStatus: string }),

    displayCbForm: () => state => ({
        cbFormDisplay: true,
    }),

    setState: ({ ns, key, value }) => state => ({
        [ns]: { ...state[ns], ...{ [key]: value } },
    }),
    setApiErrors: newState => state => ({
        api: newState,
    }),
    setStripeElementCardNumber: (cardNumber) => (state, actions) => {
        state.stripeElementCardNumber = cardNumber;
    },

    setStripeElementCardExpiry: (cardExpiry) => (state, actions) => {
        state.stripeElementCardExpiry = cardExpiry;
    },

    setStripeElementCardCvc: (cardCvc) => (state, actions) => {
        state.stripeElementCardCvc = cardCvc;
    },

    setAllFieldsAreValid: newState => state => ({
        allFieldsAreValid: newState,
    }),

    setEnsureOneSubmit: newState => state => ({
        ensureOneSubmit: newState,
    }),

    resetApiError: () => (state, actions) => {
        actions.setErrorMessage(t('Pour toute question, n’hésitez pas à me contacter', {ns: 'generals'}) + ' : ' + t('alfred@betterise.me', {ns: 'generals'}) + '\n' + t('Je me ferai un plaisir de vous aider.', {ns: 'generals'}));
        actions.setErrorStatus('info');
    },

    onStepSubmit: ({event, props}) => (state, actions) => {
        event.preventDefault();
        if (state.stripeElementCardNumber._empty) {
            actions.setErrorMessage(t('Merci de saisir votre numéro de carte bancaire.', {ns: 'cb'}));
            actions.setErrorStatus('error');
            return false
        } else if (!state.stripeElementCardNumber._complete || state.stripeElementCardNumber._invalid) {
            actions.setErrorMessage('Veuillez renseigner un numéro de carte bancaire valide.', {ns: 'cb'})
            actions.setErrorStatus('error');
            return false
        }

        if (state.stripeElementCardExpiry._empty) {
            actions.setErrorMessage(t('Merci de saisir une date d‘expiration', {ns: 'cb'}));
            actions.setErrorStatus('error');
            return false
        } else if (!state.stripeElementCardExpiry._complete || state.stripeElementCardExpiry._invalid) {
            actions.setErrorMessage('Veuillez renseigner une date d‘expiration valide.', {ns: 'cb'})
            actions.setErrorStatus('error');
            return false
        }

        if (state.stripeElementCardCvc._empty) {
            actions.setErrorMessage(t('Merci de saisir une clé de vérification', {ns: 'cb'}));
            actions.setErrorStatus('error');
            return false
        } else if (!state.stripeElementCardCvc._complete || state.stripeElementCardCvc._invalid) {
            actions.setErrorMessage('Veuillez renseigner une clé de vérification valide', {ns: 'cb'})
            actions.setErrorStatus('error');
            return false
        }

        actions.resetApiError();
        if (state.ensureOneSubmit) {
            return false;
        }
        actions.setEnsureOneSubmit(true);

        state.stripe.createSource(state.stripeElementCardNumber).then(function(result) {
            if (result.error) {
                actions.setErrorStatus('error')
                switch (event.elementType) {
                    case 'cardNumber':
                        actions.setErrorMessage(t('Veuillez renseigner un numéro de carte bancaire valide.', {ns: 'cb'}));
                        actions.setErrorStatus('error');
                        break;
                    case 'cardExpiry':
                        actions.setErrorMessage(t('Veuillez renseigner une date d‘expiration valide.', {ns: 'cb'}));
                        actions.setErrorStatus('error');
                        break;
                    case 'cardCvc':
                        actions.setErrorMessage(t('Veuillez renseigner une clé de vérification valide', {ns: 'cb'}));
                        actions.setErrorStatus('error');
                        break;
                }
                actions.setEnsureOneSubmit(false);
                return false
            } else {
                stripeSourceHandler(result.source);
            }
        });

        function stripeSourceHandler(source) {
            var data = {
                redirect_url: window.location.origin + '/home/register/payment/validation',
                stripeSource: source.id,
                three_d_secure: (source.card.three_d_secure || 'none')
            };

            User.getUserMoney(data).then((res) => {
                if (typeof res.data.status !== 'undefined') {
                    if (res.data.status == 'redirect') {
                        window.location.href = res.data.url;
                        return false;
                    } else if (res.data.status == 'success') {
                        // no 3D secure, user paid
                        window.location.href = allRoutes['private.timeline'].pathname;
                        return false;
                    }
                }
                actions.setEnsureOneSubmit(false);
                actions.setErrorMessage(t('Les données bancaires renseignées sont invalides, veuillez réessayer.', {ns: 'cb'}));
                actions.setErrorStatus('error');
            });
        }
    },
};
