import { t, h, isLength, isNumeric } from '@app/utils';
import { createAndSaveCSRFToken, addCSRFToken, isDefined, getQueryVariable, createUTCDate, getYMDfromDateObj } from '@app/core';
import { allRoutes } from '@app/settings';
import { debounce } from 'hyperapp-fx';
import { User } from '@app/api';

export const actions = {
    onComponentCreate: (props) => (state, actions) => {
        console.log('REGISTER > sex : is guest > ', props.initial);
        let kind = getQueryVariable('kind', false);
        if (kind) {
            actions.setPregnancyValue(kind);
        } else {
            if (props.pregnancyKind) {
                actions.setPregnancyValue(props.pregnancyKind);
            }
        }
        if (props.initial) {
            if (props.initial.isGuest !== null) {
                actions.setIsGuest(props.initial.isGuest);
            }
        }
        if (props.customer) {
            actions.setCustomer(props.customer);
        }
        if (props.displayed) {
            actions.setDisplayed(props.displayed);
        }
    },

    setState: ({ ns, key, value }) => (state, actions) => ({
        [ns]: { ...state[ns], ...{ [key]: value } }
    }),

    setPregnancyValue: string => (state, actions) => actions.setState({
        ns: 'pregnancy',
        key: 'value',
        value: string
    }),

    setIsGuest: newState => (state) => ({
        isGuest: newState,
    }),
    setCustomer: newState => (state) => ({
        customer: newState,
    }),
    setDisplayed: newState => (state) => ({
        displayed: newState,
    }),

    onStepSubmit: ({ev, kind}) => (state, actions) => {
        console.log('onStepSubmit : ', ev, kind);
        ev.preventDefault();
        let message = '', nowDate = null;
        if (kind === 'sa') {
            if (state.pregnancysa.isValid.sa && state.pregnancysa.isValid.day) {
                console.log('onStepSubmit : SENDING ', state.pregnancysa.value.sa, state.pregnancysa.value.day);
                let totalPregDays = 41 * 7;
                let remainingPregDays = totalPregDays - ((+state.pregnancysa.value.sa * 7) + +state.pregnancysa.value.day);
                console.log('onStepSubmit nowDate  ', totalPregDays, remainingPregDays);
                if (+remainingPregDays > 0) {
                    nowDate = createUTCDate(null, false);
                    nowDate.setDate(nowDate.getDate() + remainingPregDays);
                    let deliveryDate = getYMDfromDateObj(nowDate);
                    let data = {
                        delivery: {
                            date: deliveryDate.year + '-' + deliveryDate.month + '-' + deliveryDate.day + ' 12:00:00.000000',
                            timezone: 'UTC',
                            timezone_type: 3
                        }
                    }
                    User.setUserPregnancy(data).then((res) => {
                        if (isDefined(res.data) && (res.data.message === 'ok')) {
                            createAndSaveCSRFToken();
                            if (state.displayed === 'register') {
                                window.main.location.go(addCSRFToken('/onboarding'));
                            } else if (state.displayed === 'onboarding') {
                                window.location.href = addCSRFToken(allRoutes['private.timeline'].pathname);
                            }
                        }
                    });
                    console.log('onStepSubmit nowDate ', nowDate, getYMDfromDateObj(nowDate), data);
                    return false;
                } else {
                    message = t('Merci de renseigner un nombre de semaines d’aménorrhées (incluant les jours) strictement inférieur à 41 semaines.', {ns: 'register'});
                }
            } else {
                if ((state.pregnancysa.value.sa === '') || (state.pregnancysa.value.day === '')) {
                    message = t('Merci de renseigner votre nombre de semaines d’aménorrhées.', {ns: 'register'});
                    if (state.pregnancysa.value.sa === '') {
                        actions.setDateState({ns: 'pregnancysa', key: 'isValid', subkey: 'sa', value: false});
                    } else {
                        actions.setDateState({ns: 'pregnancysa', key: 'isValid', subkey: 'day', value: false});
                    }
                    actions.setDateState({ns: 'pregnancysa', key: 'isTouched', subkey: 'day', value: true});
                    actions.setDateState({ns: 'pregnancysa', key: 'isTouched', subkey: 'sa', value: true});
                } else if (+state.pregnancysa.value.sa > 41) {
                    message = t('Merci de saisir un nombre de semaines amhénorrées entre 0 et 41.', {ns: 'register'});
                    actions.setDateState({ns: 'pregnancysa', key: 'isValid', subkey: 'sa', value: false});
                    actions.setDateState({ns: 'pregnancysa', key: 'isTouched', subkey: 'sa', value: true});
                } else if (+state.pregnancysa.value.day > 6) {
                    message = t('Merci de saisir un nombre de jours entre 0 et 6.', {ns: 'register'});
                    actions.setDateState({ns: 'pregnancysa', key: 'isValid', subkey: 'day', value: false});
                    actions.setDateState({ns: 'pregnancysa', key: 'isTouched', subkey: 'day', value: true});
                }
            }
        } else if (kind === 'ddr') {
            let dateDay = state.pregnancyddrdate.value.day;
            let dateMonth = state.pregnancyddrdate.value.month;
            if (+dateDay < 10) {
                dateDay = ('0' + +dateDay);
            }
            if (+dateMonth < 10) {
                dateMonth = ('0' + +dateMonth);
            }
            //
            if (state.pregnancyddrdate.isValid.year && state.pregnancyddrdate.isValid.month && state.pregnancyddrdate.isValid.day) {
                nowDate = createUTCDate(null, false);
                var pregDate = createUTCDate(state.pregnancyddrdate.value.year + '-' + dateMonth + '-' + dateDay + 'T00:00:00Z');
                console.log('pregDate : onStepSubmit >  ', pregDate);
                console.log('onStepSubmit : pregDate ', pregDate, nowDate, pregDate.getTime() + ' > ' + nowDate.getTime());
                if (pregDate.getTime() > nowDate.getTime()) {
                    message = t('La date que vous avez saisie doit être antérieure à aujourd\'hui.', {ns: 'register'});
                } else {
                    let data = {
                        lmp: {
                            date: state.pregnancyddrdate.value.year + '-' + dateMonth + '-' + dateDay + ' 12:00:00.000000',
                            timezone: 'UTC',
                            timezone_type: 3
                        }
                    }
                    console.log('onStepSubmit : SENDING ', state.pregnancyddrdate.value.year + '/' + dateMonth + '/' + dateDay, data);
                    User.setUserPregnancy(data).then((res) => {
                        if (isDefined(res.data) && (res.data.message === 'ok')) {
                            createAndSaveCSRFToken();
                            window.main.location.go(addCSRFToken('/onboarding'));
                        }
                    });
                    return false;
                }
            } else {
                actions.setDateState({ns: 'pregnancyddrdate', key: 'isTouched', subkey: 'day', value: true});
                actions.setDateState({ns: 'pregnancyddrdate', key: 'isTouched', subkey: 'month', value: true});
                actions.setDateState({ns: 'pregnancyddrdate', key: 'isTouched', subkey: 'year', value: true});
                //
                if ((state.pregnancyddrdate.value.year === '') || (state.pregnancyddrdate.value.month === '') || (state.pregnancyddrdate.value.day === '')) {
                    message = t('Merci de renseigner votre date de dernières règles.', {ns: 'register'});
                    if (state.pregnancyddrdate.value.day === '') {
                        actions.setDateState({ns: 'pregnancyddrdate', key: 'isValid', subkey: 'day', value: false});
                    }
                    if (state.pregnancyddrdate.value.month === '') {
                        actions.setDateState({ns: 'pregnancyddrdate', key: 'isValid', subkey: 'month', value: false});
                    }
                    if (state.pregnancyddrdate.value.year === '') {
                        actions.setDateState({ns: 'pregnancyddrdate', key: 'isValid', subkey: 'year', value: false});
                    }
                } else {
                    console.log('onStepSubmit : pregDate ', state.pregnancyddrdate);
                    message = t('La date que vous avez saisie semble incorrecte.', {ns: 'register'});
                }
            }
            console.log('onStepSubmit : DDR ', state.pregnancyddrdate);
        }
        actions.setState({
            ns: 'api',
            key: 'message',
            value: message
        });
        console.log('onStepSubmit : message ', message);

        // createAndSaveCSRFToken();
        // window.main.location.go(addCSRFToken('/onboarding'));
    },

    waitForLastInput: ({ target }) => debounce(
        1,
        'onUpdate',
        { target },
    ),

    onUpdate: ({ target: { name, value } }) => (state, actions) => {
        let isValid = actions.isInputValid({ name, value });
        if (name.indexOf('date') > -1) {
            let refPeriod = name.split('-')[1];

            state.api.message = '';
            state.api.kind = 'info';
            actions.setDateState({ns: 'pregnancyddrdate', key: 'value', subkey: refPeriod, value: value});
            actions.setDateState({ns: 'pregnancyddrdate', key: 'isValid', subkey: refPeriod, value: isValid});
        } else {
            let refPeriod = name.split('-')[1];

            state.api.message = '';
            state.api.kind = 'info';
            actions.setDateState({ns: 'pregnancysa', key: 'value', subkey: refPeriod, value: value});
            actions.setDateState({ns: 'pregnancysa', key: 'isValid', subkey: refPeriod, value: isValid});
        }
    },

    isInputValid: ({ name, value }) => (state, actions) => {
        if (name === undefined) {
            return false;
        }

        console.log('isInputValid : ', name, value, typeof value);
        switch (name) {
            case 'pregnancyddrdate-day':
                return (isNumeric(value) && isLength(value, { min: 1, max: 2}) && (+value > 0) && (+value < 32));
            case 'pregnancyddrdate-month':
                return (isNumeric(value) && isLength(value, { min: 1, max: 2}) && (+value > 0) && (+value < 13));
            case 'pregnancyddrdate-year':
                return (isNumeric(value) && isLength(value, { min: 4, max: 4}) && (+value > 1900) && (+value < 2999));
            case 'pregnancysa-sa':
                return (isNumeric(value) && (+value < 42));
            case 'pregnancysa-day':
                return (isNumeric(value) && (+value < 7));
            default:
                throw Error(`${name} has no validator assigned`);
        }
    },

    setDateState: ({ ns, key, subkey, value }) => state => ({
        [ns]: { ...state[ns], [key] : { ...state[ns][key], [subkey] : value } },
    }),
};
