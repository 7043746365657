import { StripeID } from './stripeWrapper';

export const state = {
    // eslint-disable-next-line
    stripe: Stripe(StripeID),
    stripeElementCardNumber: null,
    stripeElementCardExpiry: null,
    stripeElementCardCvc: null,
    apiErrors: 'Renseignez un moyen de paiement.\nBetterise est satisfait ou remboursé pendant un mois.',
    apiErrorsStatus: 'info',
    payment: [],
    allFieldsAreValid: false,
    onlyOneSubmit: false,
};
