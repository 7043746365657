import abonnement from './abonnement.json';
import account from './account.json';
import cb from './cb.json';
import dataprofil from './dataprofil.json';
import datas from './datas.json';
import favorites from './favorites.json';
import follow from './follow.json';
import food from './food.json';
import generals from './generals.json';
import health from './health.json';
import home from './home.json';
import informations from './informations.json';
import login from './login.json';
import objectives from './objectives.json';
import oc from './oc.json';
import onboarding from './onboarding.json';
import programs from './programs.json';
import publiq from './public.json';
import register from './register.json';
import resiliate from './resiliate.json';
import rewards from './rewards.json';
import sponsor from './sponsor.json';
import timeline from './timeline.json';

export default { ...abonnement, ...account, ...cb, ...dataprofil, ...datas, ...favorites, ...follow, ...food, ...generals, ...health, ...home, ...informations, ...login, ...objectives, ...oc, ...onboarding, ...programs, ...publiq, ...register, ...resiliate, ...rewards, ...sponsor, ...timeline };