import { h, Component } from '@app/utils';
import { isDefined } from '@app/core';

import './index.scss';

const DoubleInput = Component(
    {
        count: {'first': 0, 'second': 0},
        max: {'first': 5, 'second': 5},
        min: {'first': 0, 'second': 0},
        tag: {'first': '', 'second': ''},
        tmout: null,
        onfocus: null,
        oninput: null,
        name: 'doubleinput',
        floating: false,
        isMobile: false,
        placeholder: {'first': 0, 'second': 0},
        initialCount: {'first': 0, 'second': 0},
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            console.log('PROPS : uppp / then ', props)
            state.max = (props.max || state.max);
            state.min = (props.min || state.min);
            state.count = (props.count || state.count);
            if (props.onfocus) {
                state.onfocus = props.onfocus;
            }
            if (props.oninput) {
                state.oninput = props.oninput;
            }
            if (props.name) {
                state.name = props.name;
            }
            if (props.tag) {
                state.tag = props.tag;
            }
            if (props.floating) {
                state.floating = props.floating;
            }
            if (props.isMobile) {
                state.isMobile = props.isMobile;
            }
            if (props.initialCount) {
                state.initialCount = props.initialCount;
                actions.setPlaceHolder(props.initialCount);
            } else {
                if (props.placeholder) {
                    actions.setPlaceHolder(props.placeholder);
                }
            }
        },
        onComponentUpdate: (props) => (state, actions) => {
            console.log('PROPS : uppp ', props)
            if (props.initialCount) {
                state.initialCount = props.initialCount;
                actions.setPlaceHolder(props.initialCount);
            } else {
                if (props.placeholder) {
                    actions.setPlaceHolder(props.placeholder);
                }
            }
        },
        setCountFirst: (value) => (state, actions) => {
            let count = state.count;
            count.first = value;
            actions.setCount(count);
        },
        setCountSecond: (value) => (state, actions) => {
            let count = state.count;
            count.second = value;
            actions.setCount(count);
        },
        setCount: newState => state => ({
            count: newState,
        }),
        setPlaceHolder: newState => state => ({
            placeholder: newState,
        }),
        isNumberKey: ({evt, num}) => (state, actions) => {
            let charCode = (evt.which) ? evt.which : evt.keyCode;
            let pattern = /^[+-]?\d+((\.|,)\d+)?$/;
            if (!state.floating) {
                pattern = /^[0-9]+$/;
            }
            if (evt.target.value === '') {
                if (num === 'first') {
                    actions.setCountFirst('');
                } else if (num === 'second') {
                    actions.setCountSecond('');
                }
            } else if (!pattern.test(evt.target.value)) {
                return true;
            }

            state.tmout = setTimeout(function() {
                clearTimeout(state.tmout);
                state.tmout = null;
                if ((state.floating) || (!isNaN(+evt.target.value))) {
                    if (num === 'first') {
                        actions.setCountFirst(evt.target.value.replace(',', '.'));
                    } else if (num === 'second') {
                        actions.setCountSecond(evt.target.value.replace(',', '.'));
                    }
                }
            }, 100);
            console.log('evt.target.value : return false');
            return false;
        }
    },
    (state, actions) => (props, children) => (
        <div class='btzDoubleInput' id={props.key} data-id={props.did} data-tag={{'first': state.tag.first, 'second': state.tag.second}} data-value={{'first': state.count.first, 'second': state.count.second}} data-kind={state.kind}>
            <div class=''>
                <input id={'input-first-' + props.key} class='btzDoubleInput-btzDoubleInputValue btzCurrentEvaluationMarker' data-id={props.did} data-tag={state.tag.first} data-value={state.count.first} data-min={state.min.first} data-max={state.max.first} data-kind={state.kind} inputmode={(state.isMobile ? 'numeric' : '')} type={(state.isMobile ? 'tel' : 'text')} pattern={(state.isMobile ? '[0-9]*' : '')} name={props.name} value={((state.count.first === 0) || (state.count.first === '') || (state.count.first === 'null')) ? '' : state.count.first} placeholder={state.placeholder.first} onkeyup={(evt) => {
                    actions.isNumberKey({'evt': evt, 'num': 'first'});
                }} onfocus={state.onfocus} oninput={state.oninput} onclick={() => {
                    if ((state.count.first === 0) || (state.count.first === '') || (state.count.first === 'null')) {
                        actions.setCountFirst(state.initialCount.first);
                        //
                        setTimeout(() => {
                            if (state.oninput !== null) {
                                state.oninput({target : document.getElementById('input-first-' + props.key)});
                            }
                        }, 100);
                    } else {
                        state.count.first;
                    }
                }} oncreate={(el) => { if (props.mobileOnboarding && el) { el.focus(); } if ((state.count.first === 0) && isDefined(props.default.first) && (props.default.first !== '')) { actions.setCountFirst(props.default.first); } }} />
                <p class='btzDoubleInput-btzDoubleInputSeparator'>{'/'}</p>
                <input id={'input-second-' + props.key} class='btzDoubleInput-btzDoubleInputValue btzCurrentEvaluationMarker' data-id={props.did} data-tag={state.tag.second} data-value={state.count.second} data-min={state.min.second} data-max={state.max.second} data-kind={state.kind} inputmode={(state.isMobile ? 'numeric' : '')} type={(state.isMobile ? 'tel' : 'text')} pattern={(state.isMobile ? '[0-9]*' : '')} name={props.name} value={((state.count.second === 0) || (state.count.second === '') || (state.count.second === 'null')) ? '' : state.count.second} placeholder={state.placeholder.second} onkeyup={(evt) => {
                    actions.isNumberKey({'evt': evt, 'num': 'second'});
                }} onfocus={state.onfocus} oninput={state.oninput} onclick={() => {
                    if ((state.count.second === 0) || (state.count.second === '') || (state.count.second === 'null')) {
                        actions.setCountSecond(state.initialCount.second);
                        //
                        setTimeout(() => {
                            if (state.oninput !== null) {
                                state.oninput({target : document.getElementById('input-second-' + props.key)});
                            }
                        }, 100);
                    } else {
                        state.count.second;
                    }
                }} oncreate={(el) => { if (props.mobileOnboarding && el) { el.focus(); } if ((state.count.second === 0) && isDefined(props.default.second) && (props.default.second !== '')) { actions.setCountSecond(props.default.second); } }} />
            </div>
            {props.label &&
                <div class='btzDoubleInput-btzDoubleInputLabel'>{props.label}</div>
            }
        </div>
    ),
    'doubleinput'
);

export { DoubleInput };
