import { Trackers } from '@app/api';
import { h, Component } from '@app/utils';
import { dynLoad, getUser, getQueryVariable, createAndSaveCSRFToken, isDefined, displayErrors, uuidv4 } from '@app/core';
import './index.scss';

export const exclude = dynLoad('exclude-oc', 'Account/oc/custo');
const EXCLUDE_OC = exclude.EXCLUDE_OC;

// jawbone doesnt exist anymore, apple & android must be synch client side
const initalState = {
    uniqId: null,
    test: [],
    userTracker: [],
    connected: [],
    brands: [],
    trackers: {},
    exclude: EXCLUDE_OC.exclude,
    currentDisplayedTrackersList: [],
    // scheme: window.location,
    scheme: null, // ignoring CSRF, because API set ?error=, instead of detecting if it need ? or &
    conflictObjFirst: null,
    conflictObjSecond: null,
    selectedConflictObj: null,
    selectedConflictHistorique: null,
    conflictStep: '',
    conflictKind: '',
    userMedia: null,
    informationDisplayed: true,
};

const state = initalState;

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        state.scheme = window.location.protocol + '//' + window.location.host + props.location.pathname;
        let removeOcInformation = localStorage.getItem('removeOcInformation');
        if ((removeOcInformation !== null) && (removeOcInformation !== undefined) && (removeOcInformation !== '')) {
            if (removeOcInformation == 'true') {
                actions.removeInformation();
            }
        }

        state.uniqId = uuidv4();

        let error = getQueryVariable('error', true);
        if (error) {
            displayErrors(error, 5000);
        }

        let csrf = getQueryVariable('csrftoken', false);
        if (!csrf || (csrf === null) || (csrf === undefined) || (csrf === '')) {
            createAndSaveCSRFToken();
        }

        let userCallback = (sessionUser) => {
            state.userTracker = sessionUser.trackers;
            state.userMedia = sessionUser.media.url;
            getTrackersInformations();
        };
        getUser(userCallback, true);

        function getTrackersInformations() {
            var chars = {' ':'','/':'-','é':'e'};
            Trackers.getTrackersList().then(function(res) {
                var parsedTrackers = {}, parsedTracker = {}, parsedBrands = [], connectedTrackers = [];
                for (var tracker in res.data) {
                    tracker = res.data[tracker];
                    var tName = tracker.name;
                    var tBrand = tracker.brand;
                    var tState = tracker.state;
                    var tCategories = tracker.categories;
                    var tLastUpdate = null;

                    for (var uTracker in state.userTracker) {
                        if (state.userTracker[uTracker].name === tName) {
                            if (isDefined(state.userTracker[uTracker].lastupdate) && isDefined(state.userTracker[uTracker].lastupdate.date)) {
                                tLastUpdate = state.userTracker[uTracker].lastupdate.date;
                            }
                        }
                    }

                    if (parsedBrands.indexOf(tBrand) === -1) {
                        parsedBrands.push(tBrand);
                    }

                    var publicUrl = `${process.env.PUBLIC_URL}`;
                    var img = tName.replace(/[ /é]/g, m => chars[m]);
                    parsedTracker = {
                        name: tName,
                        img: publicUrl + '/img/trackers/tracker/' + tBrand.toLowerCase() + '_' + img.toLowerCase() + '@2x.png',
                        imgbrand: publicUrl + '/img/trackers/brand/logo-' + tBrand.toLowerCase() + '@2x.png',
                        brand: tBrand,
                        key: tBrand.toLowerCase(),
                        categories: tCategories,
                        state: tState,
                        lastupdate: tLastUpdate,
                    };

                    if (parsedTrackers[tBrand.toLowerCase()] === undefined) {
                        parsedTrackers[tBrand.toLowerCase()] = []
                    }
                    parsedTrackers[tBrand.toLowerCase()].push(parsedTracker)
                    if (tState === 1) {
                        connectedTrackers.push(parsedTracker);
                    }
                }
                actions.setConnectedTrackers(connectedTrackers);
                actions.ressampleMyTrackerHeight();

                var parsedBrandsAgain = [];
                for (var brand in parsedBrands) {
                    brand = parsedBrands[brand]
                    if (state.exclude.indexOf(brand) > -1) {
                        continue;
                    }

                    parsedBrandsAgain.push({
                        name: brand,
                        img: publicUrl + '/img/trackers/brand/logo-' + brand.toLowerCase() + '@2x.png',
                        key: brand.toLowerCase(),
                    });
                }
                actions.setTrackers(parsedTrackers);
                actions.setBrands(parsedBrandsAgain);
            });
        }
    },
    onComponentUpdate: () => (state, actions) => {
        actions.ressampleMyTrackerHeight();
    },
    ressampleMyTrackerHeight: () => (state, actions) => {
        let tmt = setTimeout(() => {
            clearTimeout(tmt);
            tmt = null;
            let trg = document.getElementById('oc-connected');
            if (trg !== null) {
                trg.style.height = trg.offsetHeight + 'px';
            }
        }, 1000);
    },
    setBrands: newState => state => ({
        brands: newState,
    }),
    setTrackers: newState => state => ({
        trackers: newState,
    }),
    setConnectedTrackers: newState => state => ({
        connected: newState,
    }),
    setCurrentTrackers: newState => state => ({
        currentDisplayedTrackersList: state.trackers[newState.key],
    }),
    resetCurrentTrackers: () => state => ({
        currentDisplayedTrackersList: [],
    }),
    connectThisTracker: (props) => (state, actions) => {
        if (actions.thereAreConflicts(props)) {
            return false;
        }

        Trackers.connectTracker(props.brand, props.name, state.scheme).then(function(res) {
            if ((res.data !== null) && (typeof res.data.redirect !== 'undefined')) {
                window.location.href = res.data.redirect;
            }
        });
    },
    thereAreConflicts: (props) => (state, actions) => {
        var thereIsConflict = false;
        for (var cat in props.categories) {
            for (var connected in state.connected) {
                for (var catt in state.connected[connected].categories) {
                    if (props.categories[cat] === state.connected[connected].categories[catt]) {
                        actions.setFirstObjConflicted(props);
                        actions.setSecondObjConflicted(state.connected[connected]);
                        actions.setConflictKind(props.categories[cat]);
                        thereIsConflict = true;
                    }
                }
            }
        }

        if (!thereIsConflict) {
            return false;
        }
        actions.resetCurrentTrackers();
        actions.setConflictStep('tracker');
    },
    sendConflicts: (props) => (state, actions) => {
        var data = {};
        data.conflict = {};
        data.conflict[state.conflictKind] = {
            synchronize: state.selectedConflictObj.name,
            history: state.selectedConflictHistorique.name
        }

        Trackers.connectConflictedTracker(state.conflictObjFirst.brand, state.conflictObjFirst.name, state.scheme, data).then(function(res) {
            actions.cancelTrackerConnection();
            if ((res.data !== null) && (typeof res.data.redirect !== 'undefined')) {
                window.location.href = res.data.redirect;
            }
        });
    },
    setConflictKind: newState => state => ({
        conflictKind: newState,
    }),
    setFirstObjConflicted: newState => state => ({
        conflictObjFirst: newState,
    }),
    setSecondObjConflicted: newState => state => ({
        conflictObjSecond: newState,
    }),
    selectSelectedConlictedObj: (props) => (state, actions) => {
        /*if ((state.conflictObjSecond.name === props.name) && (state.conflictObjSecond.brand === props.brand)) {
            actions.cancelTrackerConnection();
            return;
        }*/
        actions.setSelectedConflictedObj(props);
    },
    setSelectedConflictedObj: newState => state => ({
        selectedConflictObj: newState,
    }),
    setSelectedConflictedHistorique: newState => state => ({
        selectedConflictHistorique: newState,
    }),
    setConflictStep: newState => state => ({
        conflictStep: newState,
    }),
    cancelTrackerConnection: (props) => (state, actions) => {
        actions.setFirstObjConflicted(null);
        actions.setSecondObjConflicted(null);
        actions.setSelectedConflictedObj(null);
        actions.setSelectedConflictedHistorique(null);
        actions.setConflictStep('');
    },
    confirmTrackerSelection: (props) => (state, actions) => {
        if (state.conflictStep === 'tracker') {
            if ((state.conflictObjSecond.name === state.selectedConflictObj.name) && (state.conflictObjSecond.brand === state.selectedConflictObj.brand)) {
                actions.cancelTrackerConnection();
                return;
            }
        }
        actions.setConflictStep('historique');
    },
    deleteThisTracker: (props) => (state, actions) => {
        Trackers.removeTracker(props.brand, props.name).then(function() {
            for (var tracker in state.trackers[props.brand]) {
                if (state.trackers[props.brand][tracker].name === props.name) {
                    state.trackers[props.brand][tracker].state = 0;
                }
            }

            let newConnected = [];
            for (var connected in state.connected) {
                if (state.connected[connected].name !== props.name) {
                    newConnected.push(state.connected[connected])
                }
            }
            actions.setConnectedTrackers(newConnected);
            actions.ressampleMyTrackerHeight();
        });
    },
    removeInformation: () => state => ({
        informationDisplayed: false,
    }),
    noMoreInformation: () => (state, actions) => {
        let newbool = true;
        let existingbool = localStorage.getItem('removeOcInformation');
        if ((existingbool !== null) && (existingbool !== undefined) && (existingbool !== '')) {
            newbool = (existingbool === 'true' ? false : true);
        }
        localStorage.setItem('removeOcInformation', newbool);
    },
};

const view = dynLoad('view', 'Account/oc/views');

export default Component(state, actions, view, 'oc');
