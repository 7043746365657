import { allRoutes } from '@app/settings';
import { h, t, Component } from '@app/utils';
import { Grid, Link, Navbar } from '@app/elements';

import logoBT from '@app/img/logo/logo-btz-small.png';
import logoBTx2 from '@app/img/logo/logo-btz-small@2x.png';

import logoBackTo from '@app/img/ico/ico-prev-light.png';
import logoBackTox2 from '@app/img/ico/ico-prev-light@2x.png';

const actions = {
    goBack: () => state => {
        window.history.back();
    }
}

const view = (state, actions) => (props, children) => (
    <div class={props.page}>
        <Grid classes={'btzHeader'}>
            <Grid.Row classes={'btzHeaderWrapper'}>
                <Grid.Col x={10} mx={'auto'}>
                    <Navbar>
                        <Navbar.Section classes='btzHeader-btzHeaderLeftHand'>
                            <Link class={'btn btn-link'} to={allRoutes['home.index'].pathname}>
                                <img src={logoBT} srcset={`${logoBTx2} 2x`} alt='' />
                            </Link>
                        </Navbar.Section>

                        <Navbar.Section classes='btzHeader-btzHeaderRightHand'>
                            {props.page == 'login' && (
                                <span>{t('Vous n‘avez pas encore de compte ?', {ns: 'register'})} <Link to={allRoutes['home.register'].pathname}>{t('Inscrivez-vous', {ns: 'register'})}</Link></span>
                            )}
                            {props.page == 'register' && (
                                <span>{t('Vous avez déjà un compte ?', {ns: 'register'})} <Link to={allRoutes['home.login'].pathname}>{t('Connectez-vous', {ns: 'register'})}</Link></span>
                            )}
                        </Navbar.Section>
                    </Navbar>
                </Grid.Col>
            </Grid.Row>
        </Grid>
        <Grid classes={'btzWrapper btzWrapperFullHeight btzVerticalAlign'} style={{ 'padding-bottom': '130px', 'padding-top': '85px' }}>
            <Grid.Row classes={'btzWrapper-verticalAlignContent'}>
                <div class='btzLink btzLink-btzAbsoluteLink' onclick={actions.goBack}>
                    <img src={logoBackTo} srcset={`${logoBackTox2} 2x`} alt='' />
                </div>
                <Grid.Col x={10} mx={'auto'} style={{ 'position': 'relative' }}>
                    {children}
                </Grid.Col>
            </Grid.Row>
        </Grid>
    </div>
);

export default Component({}, actions, view, 'layout');