import * as staticsconstants from '@app/constants/constants-statics';
import { allRoutes } from '@app/settings';
import { isDefined } from './isDefined';
import { getQueryVariable } from './getQueryVariable';
import { isMobile } from './isMobile';

const redirectToWebOrApp = function(customer) {
    let schemelink = getQueryVariable('schemelink', false);
    if (!schemelink) {
        schemelink = getQueryVariable('scheme', false);
    }
    if (isMobile() && schemelink && (schemelink !== '') && (schemelink !== null) && (schemelink !== undefined)) {
        window.location.href = schemelink;
    } else {
        let redirectObj = staticsconstants.CUSTOMERS[customer.toLowerCase()].redirect;
        if (isDefined(redirectObj)) {
            if (isDefined(redirectObj.forced) && (redirectObj.forced !== '')) {
                window.location.href = redirectObj.forced;
                return false;
            }
        }
        if ((staticsconstants.CUSTOMERS[customer.toLowerCase()].modules.login === false) || (staticsconstants.CUSTOMERS[customer.toLowerCase()].modules.appredirect)) {
            window.location.href = allRoutes['public.transit.webtoapp'].pathname + '?customerssoname=' + customer;
        } else {
            window.location.href = allRoutes['home.index'].pathname;
        }
    }
}

export { redirectToWebOrApp };