import { h, t } from '@app/utils';
import { Card } from '@app/elements';

export const ModalViewBalance = (props, actions) => (
    <Card classes={'btzInformations-btzInformationsModal btzInformations-btzBalanceModal'}>
        <Card.Header classes={'btzCard-btzHeader-btzModal'}>
            <div class='btzModal-btzPaddingWrapper'>
                <p class='btzTitle btzXl'>{t('Qu’est-ce que l’équilibre alimentaire ?', {ns: 'food'})}</p>
                <p class='col-12 btzLightTexte btzXl' style={{ 'font-size': '16px', 'text-align': 'left', 'color': 'black', 'padding': '0px' }}>
                    {t('Selon l’âge, l’indice de masse corporelle, le sexe et l’intensité de l’activité physique, chaque personne a des besoins énergétiques quotidiens différents. Ces besoins sont couverts grâce à une l’alimentation suffisante, c’est ce qu’on appelle l’AETQ (Apport Énergétique Total Quotidien).', {ns: 'food'})}
                </p>
                <br/><br/>
                {t('L’équilibre alimentaire est atteint lorsque votre AETQ est réparti de la manière suivante :', {ns: 'food'})}
                <div style={{ 'padding': '35px 0 0 0' }}>
                    <div class='btzFoodBalance'>
                        <div class='btzFoodBalance-btzDataviz btzFoodBalance-btzDataviz-btzLegend' style={{ 'width': '50%' }}><p>~50%</p></div>
                        <div class='btzFoodBalance-btzDataviz btzFoodBalance-btzDataviz-btzLegend' style={{ 'width': '35%' }}><p>~35%</p></div>
                        <div class='btzFoodBalance-btzDataviz btzFoodBalance-btzDataviz-btzLegend' style={{ 'width': '15%' }}><p>~15%</p></div>
                    </div>
                </div>
                <div class='btzFoodBalance-btzLegendes' style={{ 'padding': '5px 0 35px', 'margin': '0px' }}>
                    <div class='col-4' style={{ 'padding-left': '0px' }}>
                        <div class='btzFoodBalance-btzLegende'>
                            <div class='btzFoodBalance-btzLegende-btzPuce' data-kind='glucides'></div>
                            <p class='btzAboveTitle'>{t('Glucides', {ns: 'food'})}</p>
                        </div>
                    </div>
                    <div class='col-4'>
                        <div class='btzFoodBalance-btzLegende'>
                            <div class='btzFoodBalance-btzLegende-btzPuce' data-kind='lipides'></div>
                            <p class='btzAboveTitle'>{t('Lipides', {ns: 'food'})}</p>
                        </div>
                    </div>
                    <div class='col-4' style={{ 'padding-left': '0px' }}>
                        <div class='btzFoodBalance-btzLegende'>
                            <div class='btzFoodBalance-btzLegende-btzPuce' data-kind='proteines'></div>
                            <p class='btzAboveTitle'>{t('Protéines', {ns: 'food'})}</p>
                        </div>
                    </div>
                </div>
                <p class='col-12 btzLightTexte btzXl' style={{ 'font-size': '16px', 'text-align': 'left', 'color': 'black', 'padding': '0px' }}>
                    {t('Connaissant les valeurs nutritionnelles de chaque aliment que vous déclarez consommer, nous sommes donc capables de vous indiquer si votre équilibre alimentaire suit les recommandations ou non.', {ns: 'food'})}
                </p>
                <p class='col-12 btzLightTexte btzXl' style={{ 'font-size': '16px', 'text-align': 'left', 'color': 'black', 'padding': '0px', 'margin-top': '25px' }}>
                    {t('La légende ci-dessous vous aidera à situer correctement votre consommation par rapport à l‘équilibre alimentaire recommandé :', {ns: 'food'})}
                </p>
                <div class='btzFoodBalance-btzLegendes btzFoodBalance-btzLegendes-second' style={{ 'padding': '15px 0 35px', 'margin': '0px' }}>
                    <div class=''>
                        <div class='btzFoodSurvey-btzJaugeContent'>
                            <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzPercent-btzLeftLimit'></div>
                            <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzPercent-btzRightLimit'></div>
                            <p class='btzTexte'>{t('Recommandations', {ns: 'food'})}</p>
                        </div>
                        <div class='btzFoodSurvey-btzJaugeContent'>
                            <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzPercent-btzMiddle'></div>
                            <p class='btzTexte'>{t('Consommation dans les recommandations', {ns: 'food'})}</p>
                        </div>
                        <div class='btzFoodSurvey-btzJaugeContent'>
                            <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzPercent-btzHighest'></div>
                            <p class='btzTexte'>{t('Consommation supérieure aux recommandations', {ns: 'food'})}</p>
                        </div>
                        <div class='btzFoodSurvey-btzJaugeContent'>
                            <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzPercent-btzLowest'></div>
                            <p class='btzTexte'>{t('Consommation inférieure aux recommandations', {ns: 'food'})}</p>
                        </div>
                    </div>
                </div>
            </div>
        </Card.Header>
    </Card>
);