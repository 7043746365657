import { t, h } from '@app/utils';
import { dynMod, addCSRFToken, logoutAndRedirect } from '@app/core';
import { allRoutes } from '@app/settings';
import { Link } from '@app/elements';

import * as staticsconstants from '@app/constants/constants-statics';

import imgDefaultAvatar from '@app/img/default/def-avatar.png';
import imgDefaultAvatarx2 from '@app/img/default/def-avatar@2x.png';

export const TopMenuView = (props, actions) => (
    <div>
        {(props.customer === 'cerba') ? (
            <ul style={{ 'float': 'right' }}>
                <li style={{ 'width': '125px' }}><Link class={props.page == 'timeline' ? 'active' : ''} to={addCSRFToken(allRoutes['private.timeline'].pathname)}>{t('Journal', {ns: 'generals'})}</Link></li>
                <li style={{ 'width': '130px' }}><Link class={props.page == 'datas' ? 'active' : ''} to={addCSRFToken(allRoutes['private.datas'].pathname)}>{t('Données', {ns: 'generals'})}</Link></li>
                <li style={{ 'width': '105px' }}><Link class={props.page == 'follow' ? 'active' : ''} to={addCSRFToken(allRoutes['private.follow'].pathname)}>{t('Suivis', {ns: 'generals'})}</Link></li>
                <li style={{ 'width': '170px' }}><Link class={props.page == 'food' ? 'active' : ''} to={addCSRFToken(allRoutes['private.food'].pathname)}>{t('Alimentation', {ns: 'generals'})}</Link></li>
                <li style={{ 'width': '135px' }}><Link class={props.page == 'programs' ? 'active' : ''} to={addCSRFToken(allRoutes['private.programs'].pathname)}>{t('Activités', {ns: 'generals'})}</Link></li>
                <li style={{ 'width': '180px' }}>
                    <Link class={(['account', 'favorites', 'parameter', 'objectives', 'oc', 'rewards'].indexOf(props.page) > -1) ? 'btzThumbMarker active' : 'btzThumbMarker'} to={addCSRFToken(allRoutes['account.parameters'].pathname)} style={{ 'text-transform': 'capitalize' }}>
                        <div class='btzAvatarHeaderTop'>
                            {((props.userMedia !== undefined) && (props.userMedia !== null)) ?
                                <img id='avatarThumb' src={props.userMedia} alt='' class='' />
                                :
                                <img id='avatarThumb' src={imgDefaultAvatar} srcset={`${imgDefaultAvatarx2} 2x`} data-customer={props.customer || 'betterise'} alt='' class='' />
                            }
                        </div>
                        <p>{t('Compte', {ns: 'generals'})}</p>
                    </Link>
                </li>
            </ul>
        ) : (
            <ul style={{ 'float': 'right' }}>
                {dynMod('timeline', 'isactive') &&
                    <li style={{ 'min-width': '125px' }}><Link class={props.page == 'timeline' ? 'active' : ''} to={addCSRFToken(allRoutes['private.timeline'].pathname)}>{t(staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()].menuNames.timeline.menu)}</Link></li>
                }
                {dynMod('nutrition', 'isactive') &&
                    <li style={{ 'min-width': '170px' }}><Link class={props.page == 'food' ? 'active' : ''} to={addCSRFToken(allRoutes['private.food'].pathname)}>{t(staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()].menuNames.nutrition.menu)}</Link></li>
                }
                {dynMod('dashboard', 'isactive') &&
                    <li style={{ 'min-width': '130px' }}><Link class={props.page == 'datas' ? 'active' : ''} to={addCSRFToken(allRoutes['private.datas'].pathname)}>{t(staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()].menuNames.dashboard.menu)}</Link></li>
                }
                {dynMod('programs', 'isactive') &&
                    <li style={{ 'min-width': '155px' }}><Link class={props.page == 'programs' ? 'active' : ''} to={addCSRFToken(allRoutes['private.programs'].pathname)}>{t(staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()].menuNames.programs.menu)}</Link></li>
                }
                {(process.env.APP_CUSTOMISATION.toLowerCase() === 'klesia_netflix') &&
                    <li style={{ 'min-width': '155px' }}><Link class={props.page == 'specificpage' ? 'active' : ''} to={addCSRFToken(allRoutes['private.what.is.klesia_netflix'].pathname)}>{t('Klesia, c’est quoi ?')}</Link></li>
                }
                {dynMod('account', 'isactive') ?
                    <li style={{ 'min-width': '180px' }}>
                        <Link class={(['account', 'favorites', 'parameter', 'objectives', 'oc', 'rewards'].indexOf(props.page) > -1) ? 'btzThumbMarker active' : 'btzThumbMarker'} to={addCSRFToken(allRoutes['account.parameters'].pathname)} style={{ 'text-transform': 'capitalize' }}>
                            <div class='btzAvatarHeaderTop'>
                                {((props.userMedia !== undefined) && (props.userMedia !== null)) ?
                                    <img id='avatarThumb' src={props.userMedia} alt='' class='' />
                                    :
                                    <img id='avatarThumb' src={imgDefaultAvatar} srcset={`${imgDefaultAvatarx2} 2x`} data-customer={props.customer || 'betterise'} alt='' class='' />
                                }
                            </div>
                            <p>{t(staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()].menuNames.account.menu)}</p>
                        </Link>
                    </li>
                    :
                    <li style={{ 'min-width': '180px' }}>
                        <div class='btzLogout' style={{ 'text-align': 'left', 'cursor': 'pointer' }}><div style={{ 'display': 'inline-block' }} onclick={() => {logoutAndRedirect();}}>{t('Se déconnecter', {ns: 'generals'})}</div></div>
                    </li>
                }
            </ul>
        )}
    </div>
);