import { h } from '@app/utils';
import { Card, Modal } from '@app/elements';

import { ModalView } from './modalView';

import icoAskWhite from '@app/img/ico/ico-ask-white.png';
import icoAskWhitex2 from '@app/img/ico/ico-ask-white@2x.png';

export const view = (state, actions) => (props, children) => (
    <div class='btzRewards-btzReward' data-done={'rew-' + props.done}>
        <Modal content={<ModalView {...props}></ModalView>}>
            {(props.mediaOff !== null) ?
                <dummy>
                    {props.done === false ?
                        <Card.Image classes={'no-radius'}>{props.mediaOff !== null && <img src={props.mediaOff.url} alt='' class='' />}</Card.Image>
                        :
                        <Card.Image>{props.media !== null && <img src={props.media.url} alt='' class='' />}</Card.Image>
                    }
                </dummy>
                :
                <Card.Image>{props.media !== null && <img src={props.media.url} alt='' class='' />}
                    {props.done === false &&
                        <Card.Image classes={'btzRewards-btzReward-btzNotDone'}><img src={icoAskWhite} srcset={`${icoAskWhitex2} 2x`} alt='' class='' /></Card.Image>
                    }
                </Card.Image>
            }
        </Modal>
    </div>
);