import { h } from '@app/utils';

module.exports = {
    initAction: function() {
        let action = {
            onComponentCreate: (props) => (state, actions) => {
                if (props.initial) {

                }

                actions.setCguInitialized(true);
            },

            setState: ({ ns, key, value }) => (state, actions) => ({
                [ns]: { ...state[ns], ...{ [key]: value } }
            }),

            setCguInitialized: newState => state => ({
                cguInitialized: newState,
            }),

            setAlfredVisibility: boolean => (state, actions) => actions.setState({
                ns: 'alfred',
                key: 'isVisible',
                value: boolean
            }),

            setCguAcceptability: boolean => (state, actions) => actions.setState({
                ns: 'cgu',
                key: 'isAccepted',
                value: boolean
            }),

            onRefusedTerms: () => (state, actions) => {
                actions.setAlfredVisibility(true);
                actions.setCguAcceptability(false);

                let tmtout = setTimeout(() => {
                    clearTimeout(tmtout);
                    tmtout = null;
                    actions.setAlfredVisibility(false);
                }, 3500);
            },

            onAcceptedTerms: () => (state, actions) => {
                actions.setAlfredVisibility(false);
                actions.setCguAcceptability(true);
            },

            goToNextStep: (props) => (state, actions) => {
                window.main.location.go('/home/register/ids');
            },

            updateParentProps: (props) => (state, actions) => {
                if (props) {
                    // props.send({
                    //     ns: 'cgu',
                    //     key: 'passed',
                    //     value: true
                    // })

                    props.send({
                        ns: 'user',
                        key: 'cgu',
                        value: state.cgu.isAccepted
                    })
                }
            },

            onStepSubmit: ({event, props}) => (state, actions) => {
                event.preventDefault();

                actions.goToNextStep(props);
            },
        }
        return action;
    }
}
