import { t, h } from '@app/utils';
import { isDefined } from '@app/core';
import { Button, Card } from '@app/elements';

export const view = (state, actions) => (props, children) => (
    <Card kind={'quizz'} theme={props.valueTheme}>
        <Card.Header>
            <p class='btzTimeline-btzCard-btzTitle btzTitle'>• {t('QUIZZ', {ns: 'timeline'})} •</p>
            <p class='btzTimeline-btzCard-btzSubTitle btzSubTitle'>{props.title}</p>
        </Card.Header>

        <Card.Footer>
            {props.forms.map(form =>
                <Button primary style={{ 'width': '100%' }}
                    error={((!state.quizzAnswer.goodResponse) && (state.quizzAnswer.value == form.datas[0].value)) || ((props.state == 1) && (form.goodResponse == 0))}
                    success={((state.quizzAnswer.goodResponse) && (state.quizzAnswer.value == form.datas[0].value)) || ((props.state == 2) && (form.goodResponse == 1))}
                    onclick={() => {
                        if (state.quizzAnswer.goodResponse === null) {
                            actions.chooseQuizzAnswer({goodResponse : form.goodResponse, value : form.datas[0].value, idtimeline: props.timeline, idcard: props.id})
                        }
                    }}
                    disabled={(props.state > 0) ? true : false} >
                    <p class='btzTextColored'>{(isDefined(form.datas.filter((f) => f.lang === props.language)[0]) ? t(form.datas.filter((f) => f.lang === props.language)[0].name) : t(form.datas[0].name))}</p>
                </Button>
            )}

            {((state.quizzAnswer.goodResponse != null) || (props.state != 0)) &&
                <div class='btzTimeline-btzCard-btzQuizzAnswerType' style={{ 'margin-top': '15px' }}>
                    {(props.forms[0].goodResponse == 1) &&
                        <div>{t('LA RÉPONSE EST :', {ns: 'timeline'})} {(isDefined(props.forms[0].datas.filter((f) => f.lang === props.language)[0]) ? t(props.forms[0].datas.filter((f) => f.lang === props.language)[0].name).toUpperCase() : t(props.forms[0].datas[0].name))} !</div>
                    }
                    {(props.forms[0].goodResponse == 0) &&
                        <div>{t('LA RÉPONSE EST :', {ns: 'timeline'})} {(isDefined(props.forms[1].datas.filter((f) => f.lang === props.language)[0]) ? t(props.forms[1].datas.filter((f) => f.lang === props.language)[0].name).toUpperCase() : t(props.forms[1].datas[0].name))} !</div>
                    }
                    <p class='btzTimeline-btzCard-btzDescription btzTexte'>{props.description}</p>
                </div>
            }
            <p class='btzTimeline-btzCard-btzDescription btzLightTexte' style={{ 'padding': '0px' }}>
                {(state.quizzAnswer.goodResponse !== null) || (props.state != 0) ?
                    <div>
                        {((state.quizzAnswer.goodResponse == 1) || (props.state == 2)) &&
                            <div>{t('Bravo, bonne réponse !', {ns: 'timeline'})}</div>
                        }
                        {((state.quizzAnswer.goodResponse == 0) || (props.state == 1)) &&
                            <div>{t('Oups, mauvaise réponse !', {ns: 'timeline'})}</div>
                        }
                    </div>
                    :
                    <div style={{ 'margin-top': '15px' }}>{t('Sélectionnez la bonne réponse.', {ns: 'timeline'})}</div>
                }
            </p>
        </Card.Footer>
    </Card>
);
