export const state = {
    values: { fname: '' },
    isValid: { fname: false },
    isTouched: { fname: false },
    // added
    fname: {
        value: '',
        isTouched: false,
        isValid: false,
        isApproved: null,
        isVisible: false,
    },
    //
    api: {
        message: '',
        kind: '',
    },
};
