import { Programs } from '@app/api';
import { t, h, Component } from '@app/utils';
import { Card, VerticalScroll, TopSticked, Convert } from '@app/elements';
import './index.scss';
import { getUser, compareByKey, isDefined } from '@app/core';
import * as constants from '@app/constants';

import PageLayout from '@app/layouts/page/splitted/program/session/layout';

import Advice from './steps/advice';
import Exercice from './steps/exercice';
import Evaluation from './steps/evaluation';
import CoachWord from './steps/coach-word';
import Objectif from './steps/objectif';
import Recovery from './steps/recovery';
import Seance from './steps/seance';
import Warmup from './steps/warmup';
import Duration from './steps/duration';
import Security from './steps/security';
import Default from './steps/default';
import Ended from './steps/ended';

const state = {
    program: null,
    coach: null,
    steps: null,
    equipment: null,
    prevSteps: false,
    sessionNumber: '',
    currentSessionNumber: '',
    warningNoSubmit: false,
    values: {},
    userMedia: null,
    separateWay: false,
    programid: null,
    sessionid: null,
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        console.log('card: PROg SEssions : ', props);
        let programid = null, sessionid = null;
        //
        if (isDefined(props.displayedon) && (props.displayedon === 'preview')) {
            /*var cardTheme = actions.getTheme(card.theme);
            card.valueTheme = cardTheme.value;
            card.labelTheme = cardTheme.label;*/
            //
            if (isDefined(props.program)) {
                programid = props.programid;
                sessionid = props.sessionid;
                actions.parseProgram(props);
            }
            actions.setProgramId(programid);
            actions.setSessionId(sessionid);
        } else {
            programid = props.match.params.programid;
            if (!programid) {
                return false;
            }
            actions.setProgramId(programid);
            actions.setSessionId(sessionid);
            //
            let userCallback = (sessionUser) => {
                if (isDefined(sessionUser.media)) {
                    actions.setUserMedia(sessionUser.media.url);
                }
            };
            getUser(userCallback);

            actions.getDayProgram();
        }
    },
    getDayProgram: () => (state, actions) => {
        Programs.getUserProgram(state.programid).then(function(res) {
            actions.parseProgram(res.data);
        });
    },
    parseProgram: (resData) => (state, actions) => {
        console.log('resData : ', resData);
        if (!isDefined(resData)) {
            return false;
        }
        var program = resData.program;
        program.sessionNumber = +resData.sessionNumber;
        program.sessionsTotal = resData.sessionsTotal;

        actions.setSessionNumber(program.sessionNumber);
        actions.setCurrentSessionNumber(program.sessionNumber);

        var programTheme = actions.getTheme(program.theme);

        program.valueTheme = programTheme.value;
        program.labelTheme = programTheme.label;

        if ([2, 11].indexOf(resData.program.theme) > -1) {
            actions.setSeperateWay(true);
        }

        actions.setProgram(program);
        if (isDefined(state.sessionid)) {
            // call to getProgramSessionById(progid, sessionid)
        } else {
            actions.getProgramSession();
        }
    },
    getProgramSession: () => (state, actions) => {
        Programs.getProgramSession(state.programid).then(function(res) {
            var pSteps = [];
            for (var step in res.data.steps) {
                step = res.data.steps[step];
                pSteps.push(step);
            }
            actions.setSteps(pSteps);
            actions.setCoach(res.data.coach);
            console.log('resData : pSteps, coach, equipment ', pSteps, res.data.coach, res.data.equipment);

            if (res.data.equipment && (res.data.equipment !== null) && (res.data.equipment !== undefined)) {
                actions.setEquipment(res.data.equipment.replace(new RegExp('-', 'g'), ''));
            }
        });
    },
    setUserMedia: newState => state => ({
        userMedia: newState,
    }),
    setSeperateWay: newState => state => ({
        separateWay: newState,
    }),
    setProgram: newState => state => ({
        program: newState
    }),
    setCoach: newState => state => ({
        coach: newState
    }),
    setSteps: newState => state => ({
        steps: newState
    }),
    setProgramId: newState => state => ({
        programid: newState
    }),
    setSessionId: newState => state => ({
        sessionid: newState
    }),
    setEquipment: newState => state => ({
        equipment: newState
    }),
    getTheme: (intTheme) => {
        for (var _t in constants.custo.THEMES) {
            if (constants.custo.THEMES[_t].key === intTheme) {
                return constants.custo.THEMES[_t];
            }
        }
        return {value : '', label : ''};
    },
    displayPreviousStep: () => state => ({
        prevSteps: !state.prevSteps
    }),
    sendToPreviousStep: (idstep) => (state, actions) => {
        Programs.getPreviousProgramSession(state.program.id, idstep).then((res) => {
            var pSteps = [];

            for (var step in res.data.steps) {
                step = res.data.steps[step];
                pSteps.push(step);
            }
            actions.setSessionNumber((idstep + 1));
            state.warningNoSubmit = true;

            pSteps.sort(compareByKey('theme'));
            actions.setSteps(pSteps);
        });
    },
    addValues: ({name, value, toadd}) => (state, actions) => {
        let newValues = state.values;
        if (toadd) {
            newValues[(name + '')] = value;
        } else {
            for (var val in newValues) {
                if ((val === name) && (newValues[val] === value)) {
                    delete newValues[val]
                    break;
                }
            }
        }
        actions.setValues(newValues)
    },
    setSessionNumber: newState => state => ({
        sessionNumber: newState,
    }),
    setCurrentSessionNumber: newState => state => ({
        currentSessionNumber: newState,
    }),
    setValues: newState => state => ({
        values: newState,
    }),
};

const view = (state, actions) => (props, children) => (
    <div>
        {state.program !== null &&
            <PageLayout theme={state.program.valueTheme} page='programs' readablePageLabel={t('Séance #', {ns: 'programs'}) + state.sessionNumber} readablePageDescription={state.program.title} numSteps={state.program.sessionNumber} displayPreviousStep={actions.displayPreviousStep} sendToPreviousStep={actions.sendToPreviousStep} sendToDayStep={actions.getDayProgram} prevSteps={state.prevSteps} userMedia={state.userMedia} customer={props.customer}>
                <div>
                    <div class='col-3 col-sm-12' style={{ 'vertical-align': 'top', 'position': 'relative' }}>
                        <TopSticked topdetection={80} classes={'btzStickedWithNoStyle'} top={80}>
                            {state.steps !== null &&
                                <div class='btzProgram-btzLeftMenu' style={{ 'vertical-align': 'top' }}>
                                    {((state.equipment !== null) && (state.equipment !== '')) &&
                                        <div class='btzProgram-btzLeftMenu-btzMateriel'>
                                            <p class='btzTitle'>Matériel nécessaire</p>
                                            <p class='btzTexte' innerHTML={<Convert separator={'- '} is_xhtml={true} type='nl2br'>{state.equipment}</Convert>}></p>
                                        </div>
                                    }
                                    {state.separateWay === false ?
                                        <div class='hide-xs'>
                                            {state.steps.map(item =>
                                                <div style={{ 'position': 'relative' }} key={item.id}>
                                                    {item.type == 4 &&
                                                        <Advice {...item} theme={state.program.valueTheme} displayedon='summary'></Advice>
                                                    }
                                                    {item.type == 3 &&
                                                        <Exercice {...item} theme={state.program.valueTheme} displayedon='summary'></Exercice>
                                                    }
                                                    {item.type == 2 &&
                                                        <Evaluation {...item} theme={state.program.valueTheme} displayedon='summary' addValues={actions.addValues}></Evaluation>
                                                    }
                                                    {item.type == 1 &&
                                                        <CoachWord {...item} theme={state.program.valueTheme} displayedon='summary'></CoachWord>
                                                    }
                                                    {item.type == 0 &&
                                                        <Objectif {...item} theme={state.program.valueTheme} displayedon='summary'></Objectif>
                                                    }
                                                </div>
                                            )}
                                        </div>
                                        :
                                        <div style={{ 'position': 'relative' }}>
                                            <CoachWord theme={state.program.valueTheme} displayedon='summary'></CoachWord>
                                            <Warmup theme={state.program.valueTheme} displayedon='summary'></Warmup>
                                            <Seance theme={state.program.valueTheme} displayedon='summary'></Seance>
                                            <Recovery theme={state.program.valueTheme} displayedon='summary'></Recovery>
                                        </div>
                                    }
                                    {(state.currentSessionNumber === state.sessionNumber) &&
                                        <dummy>
                                            <div style={{ 'position': 'relative', 'margin-top': '-15px' }}>
                                                <Ended key={state.program.id + '-summary'} programid={state.program.id} seanceNumber={state.program.sessionNumber} displayedon='summary' noSubmit={state.warningNoSubmit} values={state.values}></Ended>
                                            </div>
                                            <Ended key={state.program.id + '-summaryLikeSession'} programid={state.program.id} seanceNumber={state.program.sessionNumber} displayedon='summaryLikeSession' noSubmit={state.warningNoSubmit} values={state.values}></Ended>
                                        </dummy>
                                    }
                                </div>
                            }
                        </TopSticked>
                    </div>

                    {state.steps !== null &&
                        <div class='col-9 col-sm-12' style={{ 'vertical-align': 'top', 'padding-bottom': '100px' }}>
                            {state.separateWay === true &&
                                <div>
                                    <div id={'coachword-undefined'} class='btzSimplePageBlock btzSimplePageBlock-btzSimplePageBlockNoPadding'>
                                        <div class='col-12 p-0' style={{ 'vertical-align': 'top' }}>
                                            {isDefined(state.steps[0]) &&
                                                <div key={state.steps[0].id} class='btzProgram-btzProgramRecommanded-btzProgramRecommandedWrapper'>
                                                    {state.steps[0].mediaUrl !== undefined &&
                                                        <div style={{ 'overflow': 'hidden' }}>
                                                            {state.steps[0].mediaUrl !== null &&
                                                                <Card.Image class='btzCard-btzImageMedia'>
                                                                    <Card.Media src={state.steps[0].mediaUrl}></Card.Media>
                                                                </Card.Image>
                                                            }
                                                            {state.steps[0].mediaUrl === null &&
                                                                <Default valueTheme={state.program.valueTheme} title={'Bonjour'} firstofblock={true}></Default>
                                                            }
                                                        </div>
                                                    }

                                                    <div class='btzProgramsRecommanded-btzProgramRecommanded btzProgram-btzActivity-btzSubtitle' style={{ 'padding': '20px' }}>
                                                        <div class='btzCoachWord-btzCoachWordWrapper'>
                                                            {(state.coach && (state.coach !== undefined) && (state.coach.media !== null) && (state.coach.media.url !== undefined)) &&
                                                                <img src={state.coach.media.url} alt='' class='btzCoachWord' style={{ 'margin-right': '15px', 'display': 'inline-block', 'vertical-align': 'middle', 'height': '60px' }} />
                                                            }
                                                            <div style={{ 'display': 'inline-block', 'vertical-align': 'middle' }}>
                                                                <p class='btzPageContentTexte' style={{ 'margin': '0px' }}>{state.steps[0].title}</p>
                                                                <p class='btzTextColored' data-theme={state.program.valueTheme} style={{ 'margin-bottom': '0px' }}>{t('Mot du coach', {ns: 'programs'})}</p>
                                                            </div>
                                                        </div>
                                                        <div class='btzTopCustomDotted' style={{ 'padding-top': '20px' }}>
                                                            <p class='btzPageContentSubTitle' innerHTML={<Convert is_xhtml={true} type='nl2br'>{state.steps[0].description}</Convert>}></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    <div id={'warmup'} class='btzSimplePageBlock btzSimplePageBlock-btzSimplePageBlockNoPadding'>
                                        <div class='btzProgram-btzActivity-btzSubtitle' style={{ 'margin': '0', 'padding': '20px 0 0 20px' }}>
                                            <p class='btzTitle btzXl'>{t('Échauffement', {ns: 'programs'})}</p>
                                            <p class='btzTextColored' data-theme={state.program.valueTheme}>{state.steps.filter((step) => step.category === 0).length + ' ' + t('exercices', {ns: 'programs'})}</p>
                                        </div>

                                        <VerticalScroll>
                                            {state.steps.filter((step) => step.category === 0).map((item, idx) =>
                                                <div class='col-11' style={{ 'vertical-align': 'top' }}>
                                                    <div key={item.id} class='btzProgram-btzProgramRecommanded-btzProgramRecommandedWrapper'>
                                                        {item.mediaUrl !== undefined &&
                                                            <div style={{ 'border-radius': '10px 10px 0 0', 'overflow': 'hidden' }}>
                                                                {item.mediaUrl !== null &&
                                                                    <Card.Image class='btzCard-btzImageMedia'>
                                                                        <Card.Media src={item.mediaUrl}></Card.Media>
                                                                    </Card.Image>
                                                                }
                                                                {((item.mediaUrl === null) && ([0, 1].indexOf(item.type) === -1)) &&
                                                                    <Default valueTheme={state.program.valueTheme} title={'Echauffement'}></Default>
                                                                }
                                                                {((item.mediaUrl === null) && ([0, 1].indexOf(item.type) > -1)) &&
                                                                    <CoachWord {...item} theme={state.program.valueTheme} displayedon='session' coach={state.coach}></CoachWord>
                                                                }
                                                            </div>
                                                        }
                                                        {([0, 1].indexOf(item.type) === -1) &&
                                                            <Duration valueTheme={state.program.valueTheme} {...item} index={idx}></Duration>
                                                        }
                                                        {item.security &&
                                                            <Security {...item}></Security>
                                                        }
                                                    </div>
                                                </div>
                                            )}
                                        </VerticalScroll>
                                    </div>

                                    <div id={'seance'} class='btzSimplePageBlock btzSimplePageBlock-btzSimplePageBlockNoPadding'>
                                        <div class='btzProgram-btzActivity-btzSubtitle' style={{ 'margin': '0', 'padding': '20px 0 0 20px' }}>
                                            <p class='btzTitle btzXl'>{t('Séance', {ns: 'programs'})}</p>
                                            <p class='btzTextColored' data-theme={state.program.valueTheme}>{state.steps.filter((step) => step.category === 1).length + ' ' + t('exercices', {ns: 'programs'})}</p>
                                        </div>

                                        <VerticalScroll>
                                            {state.steps.filter((step) => step.category === 1).map((item, idx) =>
                                                <div class='col-11' style={{ 'vertical-align': 'top' }}>
                                                    <div key={item.id} class='btzProgram-btzProgramRecommanded-btzProgramRecommandedWrapper'>
                                                        {item.mediaUrl !== undefined &&
                                                            <div style={{ 'border-radius': '10px 10px 0 0', 'overflow': 'hidden' }}>
                                                                {item.mediaUrl !== null &&
                                                                    <Card.Image class='btzCard-btzImageMedia'>
                                                                        <Card.Media src={item.mediaUrl}></Card.Media>
                                                                    </Card.Image>
                                                                }
                                                                {((item.mediaUrl === null) && ([0, 1].indexOf(item.type) === -1)) &&
                                                                    <Default valueTheme={state.program.valueTheme} title={'Séance'}></Default>
                                                                }
                                                                {((item.mediaUrl === null) && ([0, 1].indexOf(item.type) > -1)) &&
                                                                    <CoachWord {...item} theme={state.program.valueTheme} displayedon='session' coach={state.coach}></CoachWord>
                                                                }
                                                            </div>
                                                        }

                                                        {([0, 1].indexOf(item.type) === -1) &&
                                                            <Duration valueTheme={state.program.valueTheme} {...item} index={idx}></Duration>
                                                        }
                                                        {item.security &&
                                                            <Security {...item}></Security>
                                                        }
                                                    </div>
                                                </div>
                                            )}
                                        </VerticalScroll>
                                    </div>

                                    <div id={'recovery'} class='btzSimplePageBlock btzSimplePageBlock-btzSimplePageBlockNoPadding'>
                                        <div class='btzProgram-btzActivity-btzSubtitle' style={{ 'margin': '0', 'padding': '20px 0 0 20px' }}>
                                            <p class='btzTitle btzXl'>{t('Récupération', {ns: 'programs'})}</p>
                                            <p class='btzTextColored' data-theme={state.program.valueTheme}>{state.steps.filter((step) => step.category === 2).length + ' ' + t('exercices', {ns: 'programs'})}</p>
                                        </div>

                                        <VerticalScroll>
                                            {state.steps.filter((step) => step.category === 2).map((item, idx) =>
                                                <div class='col-11' style={{ 'vertical-align': 'top' }}>
                                                    <div key={item.id} class='btzProgram-btzProgramRecommanded-btzProgramRecommandedWrapper'>
                                                        {item.mediaUrl !== undefined &&
                                                            <div style={{ 'border-radius': '10px 10px 0 0', 'overflow': 'hidden' }}>
                                                                {item.mediaUrl !== null &&
                                                                    <Card.Image class='btzCard-btzImageMedia'>
                                                                        <Card.Media src={item.mediaUrl}></Card.Media>
                                                                    </Card.Image>
                                                                }
                                                                {((item.mediaUrl === null) && ([0, 1].indexOf(item.type) === -1)) &&
                                                                    <Default valueTheme={state.program.valueTheme} title={'Exercice'}></Default>
                                                                }
                                                                {((item.mediaUrl === null) && ([0, 1].indexOf(item.type) > -1)) &&
                                                                    <CoachWord {...item} theme={state.program.valueTheme} displayedon='session' coach={state.coach}></CoachWord>
                                                                }
                                                            </div>
                                                        }

                                                        {([0, 1].indexOf(item.type) === -1) &&
                                                            <Duration valueTheme={state.program.valueTheme} {...item} index={idx}></Duration>
                                                        }
                                                        {item.security &&
                                                            <Security {...item}></Security>
                                                        }
                                                    </div>
                                                </div>
                                            )}
                                        </VerticalScroll>
                                    </div>
                                </div>
                            }

                            {state.separateWay === false &&
                                <div>
                                    {state.steps.map(item =>
                                        <div key={item.id}>
                                            {item.type == 4 &&
                                                <Advice {...item} theme={state.program.valueTheme} displayedon='session'></Advice>
                                            }
                                            {item.type == 3 &&
                                                <Exercice {...item} theme={state.program.valueTheme} displayedon='session'></Exercice>
                                            }
                                            {item.type == 2 &&
                                                <Evaluation {...item} theme={state.program.valueTheme} displayedon='session' addValues={actions.addValues}></Evaluation>
                                            }
                                            {item.type == 1 &&
                                                <CoachWord {...item} theme={state.program.valueTheme} displayedon='session'></CoachWord>
                                            }
                                            {item.type == 0 &&
                                                <Objectif {...item} coach={state.coach} theme={state.program.valueTheme} displayedon='session'></Objectif>
                                            }
                                        </div>
                                    )}
                                </div>
                            }

                            {(state.currentSessionNumber === state.sessionNumber) &&
                                <Ended programid={state.program.id} seanceNumber={state.program.sessionNumber} displayedon='session' noSubmit={state.warningNoSubmit} values={state.values}></Ended>
                            }
                        </div>
                    }
                </div>
            </PageLayout>
        }
    </div>
);

export default Component(state, actions, view, 'programSession');
