import { Cardline } from '@app/api';

export const actions = {
    onComponentCreate: (props) => (state, actions) => {
        //
    },
    chooseQuizzAnswer: ({goodResponse, value, idtimeline, idcard}) => (state, actions) => {
        Cardline.postCardline(idtimeline, {id: idcard, tag: '-1', value: (goodResponse === 0 ? 1 : 2)});
        actions.setQuizzAnswer({goodResponse, value});
    },
    setQuizzAnswer: ({goodResponse, value}) => (state, actions) => ({
        quizzAnswer: {goodResponse, value}
    }),
    trim: (str) => {
        if (str.length > 250) {
            return (str.substr(0, 250) + '...');
        }
        return str;
    },
    rendering: () => state => ({
        rendering: !state.rendering,
    }),
};
