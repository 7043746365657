import { allRoutes } from '@app/settings';
import { isLength } from '@app/utils'
import { Objectives, User } from '@app/api';
import { getQueryVariable } from '@app/core';

export const actions = {
    onComponentCreate: (props) => (state, actions) => {
        Objectives.getObjectives().then(function(res) {
            actions.setObjectives(res.data);
        });
    },

    setObjectives: newState => state => ({
        objectives: newState,
    }),

    onSubmit: () => (state, actions) => {
        if (state.onceSending) {
            return false;
        }

        let userId = getQueryVariable('id', false);
        if ((userId === undefined) || (userId === false) || (userId === null)) {
            console.error('Missing mandatory [userId]');
            return false;
        }
        let data = {};
        let radios = document.querySelectorAll('input[type="radio"]');
        let checks = document.querySelectorAll('input[type="checkbox"]');
        let inputs = document.querySelectorAll('input[type="text"]');

        radios.forEach((el) => {
            let name = el.getAttribute('name');
            let value = el.value;
            let isChecked = el.checked;
            if (isChecked) {
                if (data[name] === undefined) {
                    data[name] = [];
                }
                data[name].push(value);
            }
        });
        checks.forEach((el) => {
            let name = el.getAttribute('name');
            let value = el.value;
            let isChecked = el.checked;
            if (isChecked) {
                if (data[name] === undefined) {
                    data[name] = [];
                }
                data[name].push(value);
            }
        });
        inputs.forEach((el) => {
            let name = el.getAttribute('name');
            let value = el.value;
            if (data[name] === undefined) {
                data[name] = [];
            }
            data[name].push(value);
        });
        data.id = userId;

        state.onceSending = true;
        User.sendFeedback(data).then(() => {
            window.location.href = allRoutes['home.index'].pathname
        });
    },
};
