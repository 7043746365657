import { t, h } from '@app/utils';
import { Link } from '@app/elements';

import HomeLayout from '@app/layouts/home/layout';

export const view = (state, actions) => (props, children) => (
    <div key='developer'>
        <HomeLayout style={{ position: 'relative' }} page='developer'>
            <div class='btzHome-btzHeader btzHome-btzHeader-blue'>
                <div class='btzHome-btzHeader-btzContent'>
                    <div class='col-10'>
                        <p class='btzTitle btzXl'>{t('Développeurs', {ns: 'home'})}</p>
                        <p class='btzTexte col-10 col-md-12'>{t('Parce que nous pensons que Betterise peut véritablement changer les choses, Betterise a été conçu, à la base, comme une plateforme technologique ouverte. Ainsi, l’ensemble des contenus, de l’intelligence de Betterise est accessible via des API documentées, fonctionnelles et ultra complètes.', {ns: 'home'})}</p>
                    </div>
                </div>
            </div>

            <div class='btzHome-btzContent column'>
                <div class='col-10'>
                    <div class='col-6 col-md-12' style={{ 'vertical-align': 'top' }}>
                        <p class='btzTexte'>{t('Parce que nous pensons que Betterise peut véritablement changer les choses, Betterise a été conçu, à la base, comme une plateforme technologique ouverte. Ainsi, l’ensemble des contenus, de l’intelligence de Betterise est accessible via des API documentées, fonctionnelles et ultra complètes.', {ns: 'home'})}</p>
                        <p class='btzTexte'>{t('Que vous vouliez développer un site autour de la santé, et utiliser notre approche éditoriale unique, ou que vous souhaitez mettre en place une application mobile utilisant l’intelligence de Betterise : tout a été pensé pour vous permettre une incorporation de notre plateforme, au sein de votre service, en marque blanche, ou non.', {ns: 'home'})}</p>
                        <p class='btzTexte'>{t('Vous proposez vous même un service, objet connecté, permettant d’enrichir l’expérience de Betterise ? Contactez-nous également, que l’on réfléchisse ensemble à intégrer votre solution, au service des utilisateurs de Betterise.', {ns: 'home'})}</p>
                        <p class='btzTexte'>{t('Betterise est une plateforme conçue par la société Betterise Health Tech. Pour en savoir plus sur le projet Betterise, mais également nos autres plateformes d’accompagnement santé, rendez-vous sur', {ns: 'home'})} <Link class='btzLink btzLink-btzUltraBasic' target='_blank' to={'http://www.betterise-healthtech.com'}>http://www.betterise-healthtech.com</Link></p>
                    </div>
                    <div class='col-6 col-md-12 btzHome-btzContent-btzParticularContent' style={{ 'padding-top': '35px' }}>
                        <p class='btzTexte col-9 col-md-6 col-xs-12'>{t('Un seul mail pour tous', {ns: 'home'})} <Link class='btzLink btzLink-btzUltraBasic' to={'mailto:api@betterise.me'}>api@betterise.me</Link></p>
                    </div>
                </div>
            </div>
        </HomeLayout>
    </div>
);
