import { allRoutes } from '@app/settings';
import { t, h, Component } from '@app/utils';
import * as constants from '@app/constants';
import { Grid, Link, Navbar, Enter } from '@app/elements';

import logoBT from '@app/img/home/accueil/logo-betterise-blanc.png';
import logoBTx2 from '@app/img/home/accueil/logo-betterise-blanc@2x.png';

import logoBurger from '@app/img/ico/ico-burger.png';
import logoBurgerx2 from '@app/img/ico/ico-burger@2x.png';

import { TopBannerView } from '@app/layouts/topbanner';

const state = {

}

const actions = {
    onComponentCreate: () => (state, actions) => {

    },
};

const view = (state, actions) => (props, children) => (
    <div key={'home'}>
        <Enter time={constants.custo.ANIM_DURATION} easing={constants.custo.ANIM_EASING} css={constants.custo.ANIM_CSS}>
            <TopBannerView customer={props.customer} browserlang={props.browserlang}></TopBannerView>
            <Grid classes={'btzHeader'}>
                <Grid.Row classes={'btzHeaderWrapper btzHeaderWrapper-btzHeaderWrapperBlue'}>
                    <Grid.Col x={10} mx={'auto'}>
                        <Navbar>
                            <label for='show-menu' class='btzMenu-btzResponsiveMenu'>
                                <img id='btzBurgerMenu-btzBurgerMenuImg' src={logoBurger} srcset={`${logoBurgerx2} 2x`} alt='' />
                            </label>
                            <input id='show-menu' role='button' type='checkbox' />

                            <Navbar.Section classes='btzHeader-btzHeaderLeftHand'>
                                <ul>
                                    <li style={{ 'display': 'block' }}>
                                        <Link class={'btn btn-link'} to={allRoutes['home.index'].pathname} style={{ 'opacity': 1 }}>
                                            <img src={logoBT} srcset={`${logoBTx2} 2x`} alt='' />
                                        </Link>
                                    </li>
                                </ul>
                            </Navbar.Section>

                            <Navbar.Section classes='btzHeader-btzHeaderRightHand'>
                                <ul>
                                    <li><Link class={props.page == 'home' ? 'active' : ''} to={allRoutes['home.index'].pathname}>{t('Accueil', {ns: 'home'})}</Link></li>
                                    <li><Link class={props.page == 'technology' ? 'active' : ''} to={allRoutes['home.technology'].pathname}>{t('Technologie', {ns: 'home'})}</Link></li>
                                    <li><Link class={props.page == 'scientific' ? 'active' : ''} to={allRoutes['home.scientific'].pathname}>{t('Scientifique', {ns: 'home'})}</Link></li>
                                    <li><Link class={props.page == 'enterprise' ? 'active' : ''} to={allRoutes['home.enterprise'].pathname}>{t('Entreprise', {ns: 'home'})}</Link></li>
                                    <li><Link class={props.page == 'about' ? 'active' : ''} to={allRoutes['home.about'].pathname}>{t('À propos', {ns: 'home'})}</Link></li>

                                    <li class='btzHzader-btzHeaderRegisterMarker'><Link to={allRoutes['home.login'].pathname} class='btzHeader-btzConnect'>{t('Se connecter', {ns: 'home'})}</Link></li>
                                    <li class='btzHzader-btzHeaderRegisterMarker'><Link to={allRoutes['home.register'].pathname} class='btzHeader-btzRegister'>{t('S‘inscrire', {ns: 'home'})}</Link></li>
                                    <li class='p-0'><a class='btzHeader-btzLanguage' onclick={() => window.main.changeLanguage({lang: 'fr_FR', updateStorage: true})}>FR</a></li>
                                    <li class='p-0'><a class='btzHeader-btzLanguage' onclick={() => window.main.changeLanguage({lang: 'en_GB', updateStorage: true})}>UK</a></li>
                                    <li class='p-0'><a class='btzHeader-btzLanguage' onclick={() => window.main.changeLanguage({lang: 'en_US', updateStorage: true})}>US</a></li>
                                </ul>
                            </Navbar.Section>
                        </Navbar>

                    </Grid.Col>
                </Grid.Row>
            </Grid>
            <Grid classes={'btzWrapper btzWrapperFullHeight btzVerticalAlign btzWrapperFullWidth'}>
                <Grid.Row classes={'btzWrapper-verticalAlignContent'}>
                    <Grid.Col x={12} mx={'auto'} class='m-0'>
                        {children}
                    </Grid.Col>
                </Grid.Row>
            </Grid>
            <Grid classes={'btzHomeFooter p-0'}>
                <Grid.Row classes={'btzFooterWrapper m-0'}>
                    <Grid.Col x={10} mx={'auto'}>
                        <div class='col-2 col-md-4 col-xs-12 btzFooter-btzFooterLeftHand'>
                            <ul>
                                <li style={{ 'display': 'table-cell' }}>
                                    <Link class={'btn btn-link'} to={'/'} style={{ 'opacity': 1 }}>
                                        <img style={{ 'opacity': '0.4' }} src={logoBT} srcset={`${logoBTx2} 2x`} alt='' />
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div class='col-10 col-md-8 col-xs-12 btzFooter-btzFooterRightHand'>
                            <ul style={{ 'padding': '15px' }}>
                                <li><Link to={allRoutes['home.index'].pathname}>{t('Accueil', {ns: 'home'})}</Link></li>
                                <li><Link to={allRoutes['home.technology'].pathname}>{t('Technologie', {ns: 'home'})}</Link></li>
                                <li><Link to={allRoutes['home.scientific'].pathname}>{t('Scientifique', {ns: 'home'})}</Link></li>
                                <li><Link to={allRoutes['home.enterprise'].pathname}>{t('Entreprises', {ns: 'home'})}</Link></li>
                                <li><Link to={allRoutes['home.developer'].pathname}>{t('Développeurs', {ns: 'home'})}</Link></li>
                                <li><Link to={allRoutes['home.about'].pathname}>{t('À propos', {ns: 'home'})}</Link></li>
                            </ul>
                        </div>
                    </Grid.Col>

                    <Grid.Col x={12} mx={'auto'} class='btzFooterRed'>
                        <Link to={allRoutes['home.enterprise'].pathname}>{t('Un produit Betterise Health Tech', {ns: 'home'})}</Link>
                    </Grid.Col>
                </Grid.Row>
            </Grid>
        </Enter>
    </div>
);

export default Component(state, actions, view, 'layout');
