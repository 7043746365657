import * as staticsconstants from '@app/constants/constants-statics';
const dynMod = function(mod, type = 'isactive', isin = '') { // type can be [isactive, isin]
    let customerConf = staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()];
    if ((customerConf !== undefined) && (customerConf.modules !== undefined)) {
        let customerMod = null;
        if (mod.indexOf('.') > -1) {
            customerMod = customerConf.modules[mod.split('.')[0]][mod.split('.')[1]];
        } else {
            customerMod = customerConf.modules[mod];
        }
        if (customerMod !== undefined) {
            if (type === 'isactive') {
                return customerMod;
            } else if (type === 'isin') {
                return (customerMod.indexOf(isin) > -1);
            }
        }
    }
    return false;
}

export { dynMod };