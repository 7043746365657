import { getClosest, isDefined } from '@app/core';
import { t, h, Component } from '@app/utils';
import { Data } from '@app/api';
import { Tile, Balloon, Form, Button, Avatar, CustomInput } from '@app/elements';
import './index.scss';

const SleepForm = Component(
    {
        questionStep: 0,
        question: ['Combien de temps avez-vous dormi ?', 'Comment avez-vous dormi ?'],
        i18nquestion: [t('Combien de temps avez-vous dormi ?', {ns: 'datas'}), t('Comment avez-vous dormi ?', {ns: 'datas'})],
        form: {
            type: ['number', 'qcm'],
            tag: ['SLEEP_DURATION', 'SLEEP_QUALITY'],
            values: [
                {key: 'très mal dormi', value: '1'},
                {key: 'mal dormi', value: '4'},
                {key: 'bien dormi', value: '7'},
                {key: 'très bien dormi', value: '10'},
            ],
            i18nvalues: [
                {key: t('très mal dormi', {ns: 'datas'}), value: '1'},
                {key: t('mal dormi', {ns: 'datas'}), value: '4'},
                {key: t('bien dormi', {ns: 'datas'}), value: '7'},
                {key: t('très bien dormi', {ns: 'datas'}), value: '10'},
            ],
            label: ['', '']
        },
        source: 'betterise',
        date: null,
        readableDate: null,
        help: ['Saisissez la durée de sommeil.', 'Une seule réponse possible.'],
        i18nhelp: [t('Saisissez la durée de sommeil.', {ns: 'datas'}), t('Une seule réponse possible.', {ns: 'datas'})],
        error: '',
        activeValidation: false,
        formIsSubmitting: false,
        values: [],
        defaultValue: [{h: 0, min: 0}],
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.date) {
                actions.setReadableDate(actions.getDate(props.date));
                actions.setDate(actions.createDateObj(props.date));
            }
            if (props.defaultValue) {
                let newValues = [...state.defaultValue, ...props.defaultValue];
                actions.setDefaultValue(newValues);
            }
        },
        onComponentUpdate: (props) => (state) => {

        },
        switchValidationActivation: () => state => ({
            activeValidation: !state.activeValidation,
        }),
        selectThisAnswer: (el) => (state, actions) => {
            actions.setError('');

            var els = getClosest(el.target, '.btzOnboarding-btzButtonsWrapper').querySelectorAll('.btzOnBoarding-btzButton');
            for (var elem in els) {
                if (els[elem] && (els[elem].classList !== undefined)) {
                    els[elem].classList.remove('active');
                    els[elem].classList.remove('btzCurrentEvaluationMarker');
                }
            }

            el.target.classList.add('active');
            el.target.classList.add('btzCurrentEvaluationMarker');

            if (!state.activeValidation) {
                actions.switchValidationActivation();
            }
        },
        setDataSleeping: () => (state, actions) => {
            var sleepHours = (document.querySelectorAll('input[name="sleep-hours-custom"]')[0].value || 0);
            var sleepMinutes = (document.querySelectorAll('input[name="sleep-minutes-custom"]')[0].value || 0);
            var sleepValue = (+sleepHours * 60) + +sleepMinutes;
            state.values.push({ tag: 'SLEEP_DURATION', value: sleepValue, date: state.date, source: state.source });
        },
        sendData: (props) => (state, actions) => {
            var parent = document.querySelectorAll('.btzDashboards-btzDashboardWrapper#' + props.theme)[0];
            var sleepQuality = (isDefined(parent.querySelectorAll('div.btzOnBoarding-btzButton.btzOnBoarding-btzQcm-btzLabel.btzCurrentEvaluationMarker')[0]) ? (parent.querySelectorAll('div.btzOnBoarding-btzButton.btzOnBoarding-btzQcm-btzLabel.btzCurrentEvaluationMarker')[0].getAttribute('data-value') || null) : null);
            if (sleepQuality === null) {
                actions.setError(t('Veuillez remplir le formulaire.', {ns: 'generals'}));
                return false;
            }
            state.values.push({tag: 'SLEEP_QUALITY', value: sleepQuality, date: state.date, source: state.source});

            if (state.values.length === 0) {
                actions.setError(t('Veuillez remplir le formulaire.', {ns: 'generals'}));
            } else {
                actions.setFormIsSubmitting(true);
                Data.postData(state.values).then(function() {
                    props.updateDataViz(props);
                    state.values = [];
                    props.cancelAction();
                    actions.setFormIsSubmitting(false);
                });
            }
        },
        setError: newState => state => ({
            error: newState,
        }),
        setFormIsSubmitting: (active) => (state, actions) => ({
            formIsSubmitting: active
        }),
        setReadableDate: newState => state => ({
            readableDate: newState
        }),
        setDate: newState => state => ({
            date: newState
        }),
        updateDefaultValue: (newValue) => (state, actions) => {
            if (newValue[0].h === null) {
                newValue[0].h = state.defaultValue[0].h;
            } else if (newValue[0].min === null) {
                newValue[0].min = state.defaultValue[0].min;
            }
            if (newValue[0].h === '') {
                newValue[0].h = '';
            } else if (newValue[0].min === '') {
                newValue[0].min = '';
            }
            actions.setDefaultValue(newValue);
        },
        setDefaultValue: newState => state => ({
            defaultValue: newState
        }),
        createDateObj: (date) => (state, actions) => {
            var parsedDate = {
                date: new Date(date.substr(0, 4) + '-' + date.substr(4, 2) + '-' + date.substr(6, 2)),
                timezone: 'UTC',
                timezone_type: 3
            }

            return parsedDate;
        },
        getDate: (dat) => {
            var date = new Date(Date.parse(new Date(dat.substr(0, 4) + '-' + dat.substr(4, 2) + '-' + dat.substr(6, 2))));
            var options = { month: 'long', day: 'numeric', year: 'numeric' };
            return date.toLocaleDateString('fr-FR', options);
        },
        updateQuestionStep: newState => state => ({
            questionStep: newState,
        })
    },
    (state, actions) => (props, children) => (
        <div class='btzDashboard-btzDashboardForm' id={props.id} idtimeline={props.idtimeline} idfavorite={props.idfavorite} data-id={props.did} data-tag={props.tag} data-value={state.count} data-kind={state.kind}>
            <div style={{ 'position': 'relative', 'height': '100%', 'display': 'table', 'width': '100%' }}>
                <Tile>
                    <div class='btzTitle-btzImage btzTitle-btzImage-absolute'>
                        <p class='btzSubTitle'>{state.readableDate}</p>
                        <p class='btzTitle btzXl'>{t(state.question[state.questionStep])}</p>
                    </div>
                    {state.questionStep === 0 &&
                        <Tile.Content classes='btzLeftHrArrowedOnboarding' style={{ 'text-align': 'center' }}>
                            {state.form && (
                                <div>
                                    <div class='col-6 col-xs-6' style={{ 'max-width': '120px' }}>
                                        <CustomInput key={'sleep-hours'} uniqName={'sleep-hours'} maxValue={23} uniqLabel={t('h', {ns: 'datas'})} uniqMaxLength={2} uniqDefaultValue={state.defaultValue[0].h} oninput={(el) => {actions.updateDefaultValue([{h: +el.target.value, min: null}])}} onclick={(el) => {actions.updateDefaultValue([{h: (+el.target.value === 0 ? '' : +el.target.value), min: null}])}} uniqClass={'btzCurrentEvaluationMarker'}></CustomInput>
                                    </div>
                                    <div class='col-6 col-xs-6' style={{ 'max-width': '120px' }}>
                                        <CustomInput key={'sleep-minutes'} uniqName={'sleep-minutes'} maxValue={59} uniqLabel={t('min', {ns: 'datas'})} uniqMaxLength={2} uniqDefaultValue={state.defaultValue[0].min} oninput={(el) => {actions.updateDefaultValue([{h: null, min: +el.target.value}])}} onclick={(el) => {actions.updateDefaultValue([{h: null, min: (+el.target.value === 0 ? '' : +el.target.value)}])}} uniqClass={'btzCurrentEvaluationMarker'}></CustomInput>
                                    </div>
                                </div>
                            )}
                        </Tile.Content>
                    }

                    {state.questionStep === 1 &&
                        <Tile.Content classes='btzLeftHrArrowedOnboarding' style={{ 'text-align': 'center' }}>
                            {state.form && (
                                <div>
                                    <div class='btzOnboarding-btzButtonsWrapper btzOnboarding-btzButtonsWrapper-qcm'>
                                        {state.form.values.map(item =>
                                            <div class='col-6 col-md-12'>
                                                <div onclick={actions.selectThisAnswer} data-id={'single'} data-label={item.key} data-tag={item.value} data-kind='qcm' data-value={item.value} class={'btzOnBoarding-btzButton btzOnBoarding-btzQcm-btzLabel ' + (state.defaultValue[1] == item.value ? 'active btzCurrentEvaluationMarker' : '')}>
                                                    <p>{t(item.key)}</p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </Tile.Content>
                    }
                </Tile>

                <Form onsubmit={() => null} style={{ 'min-height': 0 }}>
                    <Form.Group classes='btzForm-btzFooter'>
                        <Button primary small loading={(state.formIsSubmitting)} onclick={() => { if (!state.formIsSubmitting) { props.cancelAction(); } }} >
                            {t('Annuler', {ns: 'generals'})}
                        </Button>

                        <Button primary active={state.activeValidation} loading={(state.formIsSubmitting)} onclick={() => {
                            if (!state.formIsSubmitting) {
                                if (state.questionStep === 0) {
                                    actions.setDataSleeping();
                                    actions.updateQuestionStep(1);
                                } else {
                                    actions.sendData(props);
                                }
                            }
                        }} >
                            {t('Valider', {ns: 'generals'})}
                        </Button>
                    </Form.Group>
                </Form>

                <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap', 'margin-top': '50px' }}>
                    <Tile.Icon classes={''}>
                        <Avatar.Alfred classes={'centered'} />
                    </Tile.Icon>
                    <Tile.Content>
                        <Balloon kind={state.error !== '' ? 'error' : 'info'} title={t('QUESTION', {ns: 'datas'}) + ' - ' + (state.questionStep + 1) + '/' + state.question.length} style={{ 'white-space': 'normal' }}>{(state.error !== '' ? state.error : t(state.help[state.questionStep]))}</Balloon>
                    </Tile.Content>
                </Tile>
            </div>
        </div>
    ),
    'sleepform'
);

export { SleepForm };
