const humanizeDate = function(date) {
    let startday = date.getDate(), startmonth = date.getMonth(), startyear = date.getFullYear();
    let starthours = date.getHours(), startminutes = date.getMinutes(), startseconds = date.getSeconds();
    if (+startday <  10) {
        startday = '0' + startday;
    }
    if (+startmonth <  10) {
        startmonth = '0' + +startmonth;
    }
    if (+startday <  10) {
        startday = '0' + +startday;
    }
    if (+starthours <  10) {
        starthours = '0' + +starthours;
    }
    if (+startminutes <  10) {
        startminutes = '0' + +startminutes;
    }
    if (+startseconds <  10) {
        startseconds = '0' + +startseconds;
    }
    return startyear + '-' + startmonth + '-' + startday + 'T' + starthours + ':' + startminutes + ':' + startseconds + 'Z';
}

export { humanizeDate };