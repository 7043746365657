import { h } from '@app/utils';
import { User, Themes, Objectives } from '@app/api';
import { addCSRFToken, createAndSaveCSRFToken, isDefined } from '@app/core';

function sleep(milliseconds) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
        if ((new Date().getTime() - start) > milliseconds) {
            break;
        }
    }
}

module.exports = {
    initAction: function() {
        let action = {
            onComponentCreate: (props) => (state, actions) => {
                const user = props.initial
                // let toSend = {}, isPregnancy = false;
                // user.objectives.map((m, i) => toSend[i] = {'id': m.id, 'state': 1});
                // for (let obj in user.objectives) {
                //     if (user.objectives[obj].mainThemes.indexOf(17) > -1) {
                //         isPregnancy = true;
                //     }
                // }
                // console.log('toSend : ', toSend, user.objectives, isPregnancy);
                // if (isPregnancy) {
                //     window.main.location.go('/home/register/pregnancy');
                // }
                // return false;


                // send cgu
                User.updateUser({
                    'cgu': '1'
                }).then(async () => {
                    let browserLanguage = 'fr_FR';
                    if (isDefined(localStorage.getItem('language'))) {
                        browserLanguage = localStorage.getItem('language');
                    }
                    if (browserLanguage.indexOf('_') === -1) {
                        browserLanguage = 'fr_FR';
                    }
                    // send initial profile
                    User.updateUser({
                        'nickname': user.fname,
                        'language': browserLanguage,
                    }).then(async () => {
                        // send all selected objectives
                        let toSend = {};
                        user.objectives.map((m, i) => toSend[i] = {'id': m.id, 'state': 1});

                        Objectives.updateObjective(toSend, false).then(() => {
                            //
                        }).then(() => {
                            actions.goToOnboarding();
                        });
                    })
                })
            },

            setState: ({ ns, key, value }) => (state, actions) => ({
                [ns]: { ...state[ns], ...{ [key]: value } }
            }),

            goToOnboarding: () => (state, actions) => {
                sleep(2000);
                createAndSaveCSRFToken();
                window.main.location.go(addCSRFToken('/onboarding'));
            }
        }
        return action;
    }
}
