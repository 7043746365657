import { t, h, Component } from '@app/utils';
import { Button, Tile, Avatar, Balloon } from '@app/elements';

import logoBackTo from '@app/img/ico/ico-prev-light.png';
import logoBackTox2 from '@app/img/ico/ico-prev-light@2x.png';

import icoEdit from '@app/img/ico/ico-adddatas.png';
import icoEditx2 from '@app/img/ico/ico-adddatas@2x.png';

const StepStart = Component(
    {
        editMealDate: false,
        editMealKind: false,
        today: null,
        calendarDate: {
            day: '',
            month: '',
            year: '',
        }
    },
    {
        onComponentCreate: (props) => (state) => {
            let today = new Date();
            let day = today.getDate();
            let month = (+today.getMonth() + 1);
            let year = today.getFullYear();
            if (+day < 10) {
                day = ('0' + day);
            }
            if (+month < 10) {
                month = ('0' + month);
            }
            state.today = year + month + day;

            if (props.editMealDate) {
                state.editMealDate = props.editMealDate;
            }
            if (props.editMealKind) {
                state.editMealKind = props.editMealKind;
            }
        },
        onComponentUpdate: (props) => (state) => {
            if (props.editMealDate) {
                state.editMealDate = props.editMealDate;
            }
            if (props.editMealKind) {
                state.editMealKind = props.editMealKind;
            }
        },
        getDate: ({el, dat}) => {
            dat = dat.substr(0, 4) + '-' + dat.substr(4, 2) + '-' + dat.substr(6, 2);
            var date = new Date(Date.parse(new Date(dat)));
            var options = { month: 'long', day: 'numeric', weekday: 'long' };
            el.innerHTML = (date.toLocaleDateString('fr-FR', options));
        },
        calendarDate: newState => state => ({
            calendarDate: newState,
        }),
        createCalendar: ({el, props}) => (state, actions) => {
            // eslint-disable-next-line
            let btzCalendar = jsCalendar.new({
                target: el,
                navigator: true,
                navigatorPosition : 'right',
                monthFormat : 'month YYYY',
                language: 'fr'
            });
            btzCalendar.onDateClick(function(event, date){
                let day = date.getDate();
                let month = (+date.getMonth() + 1);
                let year = date.getFullYear();

                if (+day < 10) {
                    day = ('0' + day);
                }
                if (+month < 10) {
                    month = ('0' + month);
                }

                if (state.today < (year + month + day)) {
                    return;
                }

                btzCalendar.set(day + '/' + month + '/' + year);
                actions.calendarDate({day: day, month: month, year: year});
            });
        },
        saveCalendarDate: (props) => (state, actions) => {
            let day = state.calendarDate.day;
            let month = state.calendarDate.month;
            let year = state.calendarDate.year;
            if ((day !== '') && (month !== '') && (year !== '')) {
                props.updateFromCalendar(year + '' + month + '' + day);
                props.setEditMealDate(false);
            }
        },
    },
    (state, actions) => (props, children) => (
        <div key={'editmode-' + state.editMealDate + '-' + state.editMealKind}>
            {state.editMealDate === false ?
                <div key={'editkind-' + state.editMealDate}>
                    <div class='btzData-btzDate'>
                        <div class='btzData-btzDate-btzChoice'>
                            {((props.ymdMealDate !== null) && (props.ymdMealDate !== undefined)) &&
                                <p key={'mealdate-' + props.ymdMealDate} style={{ 'margin': '0px' }} oncreate={(el) => actions.getDate({'el' : el, 'dat' : props.ymdMealDate}) }></p>
                            }
                            <img src={icoEdit} srcset={`${icoEditx2} 2x`} alt='' class='' onclick={() => {props.setEditMealDate(true)}} />
                        </div>
                        {((props.today === props.ymdMealDate) && ((props.editMealKind === true) || (props.editMealDate === true))) &&
                            <p class='btzData-btzDate-btzLabel'>{t('aujourd‘hui', {ns: 'food'})}</p>
                        }
                    </div>

                    {state.editMealKind === true &&
                        <div style={{ 'padding-bottom': '100px' }}>
                            <div class={'btzData-btzMeal-btzMealKind'} style={{ 'border-bottom': 'none' }}>
                                {props.mealKinds.map(item =>
                                    <Button flat active={props.mealKind === item.key} onclick={() => {
                                        props.setMealKind(item.key);
                                        props.setMealKindLabel(item.label);
                                        props.setEditMealKind(false);

                                        if (props.fromEdit) {

                                        } else {
                                            props.resetAlimentsIfNeeded(item.key);
                                        }
                                    }}>
                                        {item.label}
                                    </Button>
                                )}
                            </div>

                            <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap', 'bottom': '0', 'padding': '15px 30px 30px', 'background-color': 'white' }}>
                                <Tile.Icon classes={''}>
                                    <Avatar.Alfred classes={'centered'} />
                                </Tile.Icon>
                                <Tile.Content>
                                    <Balloon kind={'info'} style={{ 'white-space': 'normal' }}>
                                        <span>{t('Sélectionnez le type de repas que vous souhaitez ajouter.', {ns: 'food'})}</span>
                                    </Balloon>
                                </Tile.Content>
                            </Tile>
                        </div>
                    }
                </div>
                :
                <div key={'editdate-' + state.editMealKind}>
                    <img src={logoBackTo} srcset={`${logoBackTox2} 2x`} alt='' class='btzModal-btzPaddingWrapper-btzClose' onclick={() => {props.setEditMealDate(false)}} />
                    <div class={'btzData-btzMeal-btzMealDate'} style={{ 'padding-bottom': '100px' }}>
                        <p class='btzTitle btzXl' style={{ 'margin-top': '50px' }}>{t('Quelle est la date de votre repas ?', {ns: 'food'})}</p>

                        <div id='btzCalendar' oncreate={(el) => actions.createCalendar({el: el, props: props})}></div>
                    </div>

                    <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap', 'bottom': '100px', 'padding': '15px 30px 30px', 'background-color': 'white' }}>
                        <Tile.Icon classes={''}>
                            <Avatar.Alfred classes={'centered'} />
                        </Tile.Icon>
                        <Tile.Content>
                            <Balloon kind={'info'} style={{ 'white-space': 'normal' }}>
                                <span>{t('Sélectionnez une date pour votre repas.', {ns: 'food'})}</span>
                            </Balloon>
                        </Tile.Content>
                    </Tile>

                    <div class='btzFood-btzModal-btzFooter'>
                        <div>
                            <Button flat primary active={(state.calendarDate.day !== '')} onclick={() => {
                                actions.saveCalendarDate(props);
                            }}>
                                <p>{t('Enregistrer', {ns: 'generals'})}</p>
                            </Button>
                        </div>
                    </div>
                </div>
            }
        </div>
    ),
    'addmealstart'
);

export { StepStart };
