import { h, Component } from '@app/utils';
import * as constants from '@app/constants';
import { Grid, Enter } from '@app/elements';

const state = {

}

const actions = {
    onComponentCreate: () => (state, actions) => {

    },
};

const view = (state, actions) => (props, children) => (
    <div key={'empty'}>
        <Enter time={constants.custo.ANIM_DURATION} easing={constants.custo.ANIM_EASING} css={constants.custo.ANIM_CSS}>
            <Grid classes={'btzWrapper btzWrapperFullHeight btzVerticalAlign btzWrapperFullWidth'}>
                <Grid.Row classes={'btzWrapper-verticalAlignContent'}>
                    <Grid.Col x={12} mx={'auto'} class='m-0'>
                        {children}
                    </Grid.Col>
                </Grid.Row>
            </Grid>
        </Enter>
    </div>
);

export default Component(state, actions, view, 'layout');
