import { h } from '@app/utils';
import { User, Themes, Objectives, Payment } from '@app/api';
import { createAndSaveCSRFToken, addCSRFToken, isDefined } from '@app/core';
import { allRoutes } from '@app/settings';

function sleep(milliseconds) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
        if ((new Date().getTime() - start) > milliseconds) {
            break;
        }
    }
}

module.exports = {
    initAction: function() {
        let action = {
            onComponentCreate: (props) => (state, actions) => {
                let forcePayment = false;
                const user = props.initial
                let createUser = null

                let userType = user.type;
                switch (userType) {
                    case 'company':
                        createUser = User.createUserCompanyInvite(
                            user.email,
                            user.password,
                            user.company
                        )

                        break;

                    case 'mutual':
                        createUser = User.createUserMutual(
                            user.email,
                            user.password,
                            user.mutual
                        )

                        break;

                    case 'b2c':
                    default:
                        createUser = User.createUserB2C(
                            user.email,
                            user.password
                        )

                        break;
                }

                // create user
                createUser.then(async ({ data }) => {
                    if (isDefined(data) && !isDefined(data.expiration)) {

                        return false;
                    } else {
                        localStorage.removeItem('password');
                        await localStorage.setItem('apitoken', data.token);
                        await localStorage.setItem('refresh', data.refresh);
                        await localStorage.setItem('expiration', data.expiration.date);
                    }
                    // send cgu
                    User.updateUser({
                        'cgu': '1'
                    }).then(async () => {
                        let browserLanguage = 'fr_FR';
                        if (isDefined(localStorage.getItem('language'))) {
                            browserLanguage = localStorage.getItem('language');
                        }
                        if (browserLanguage.indexOf('_') === -1) {
                            browserLanguage = 'fr_FR';
                        }
                        // send initial profile
                        User.updateUser({
                            'gender': user.gender,
                            'nickname': user.fname,
                            'question': user.question,
                            'answer': user.answer,
                            'language': browserLanguage,
                        }).then(async () => {
                            let toSend = {};
                            user.objectives.map((m, i) => toSend[i] = {'id': m.id, 'state': 1});

                            Objectives.updateObjective(toSend, false).then(() => {
                                //
                            }).then(() => {
                                // send the payment
                                if (userType === 'b2c') {
                                    if (forcePayment) {
                                        Payment.doFakePayment()
                                            .then(async () => {
                                                actions.goToOnboarding()
                                            })
                                    } else {
                                        User.getUserMoney(user.payment).then((res) => {
                                            if (isDefined(res.data) && isDefined(res.data.status)) {
                                                if (res.data.status == 'redirect') {
                                                    window.location.href = res.data.url;
                                                    return;
                                                } else if (res.data.status == 'success') {
                                                    // no 3D secure, user paid
                                                    actions.goToOnboarding();
                                                    return;
                                                }
                                            }
                                            window.location.href = addCSRFToken(allRoutes['public.cb'].pathname);
                                            return;
                                        });
                                    }
                                } else {
                                    actions.goToOnboarding()
                                }
                            })
                        })
                    })
                })
            },

            goToOnboarding: () => (state, actions) => {
                sleep(2000);
                createAndSaveCSRFToken();
                window.main.location.go(addCSRFToken('/onboarding'));
            },
        }
        return action;
    }
}
