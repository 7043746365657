import { allRoutes } from '@app/settings';
import { h, t } from '@app/utils';

import { Modal, Link } from '@app/elements';

import HomeLayout from '@app/layouts/home/layout';

import topFullBg from '@app/img/home/accueil/background-accueil.jpg';

import playButton from '@app/img/home/accueil/btn-play.png';
import playButtonx2 from '@app/img/home/accueil/btn-play@2x.png';

import product1 from '@app/img/home/accueil/products-1.png';
import product1x2 from '@app/img/home/accueil/products-1@2x.png';

import themes from '@app/img/home/accueil/icones-thematiques.png';
import themesx2 from '@app/img/home/accueil/icones-thematiques@2x.png';

import photoMichel from '@app/img/home/accueil/photo-michel.png';
import photoMichelx2 from '@app/img/home/accueil/photo-michel@2x.png';

import guillemetOpen from '@app/img/home/accueil/guillemet-ouvert.png';
import guillemetOpenx2 from '@app/img/home/accueil/guillemet-ouvert@2x.png';

import guillemetClose from '@app/img/home/accueil/guillemet-ferme.png';
import guillemetClosex2 from '@app/img/home/accueil/guillemet-ferme@2x.png';

import product2 from '@app/img/home/accueil/products-2.png';
import product2x2 from '@app/img/home/accueil/products-2@2x.png';

import theyTalk from '@app/img/home/accueil/they-talk-about-us.png';
import theyTalkx2 from '@app/img/home/accueil/they-talk-about-us@2x.png';

import theySupport from '@app/img/home/accueil/they-support-us.png';
import theySupportx2 from '@app/img/home/accueil/they-support-us@2x.png';

import ocWithings from '@app/img/home/accueil/logo-withings.png';
import ocWithingsx2 from '@app/img/home/accueil/logo-withings@2x.png';

import ocMisfit from '@app/img/home/accueil/logo-misfit.png';
import ocMisfitx2 from '@app/img/home/accueil/logo-misfit@2x.png';

import ocFitbit from '@app/img/home/accueil/logo-fitbit.png';
import ocFitbitx2 from '@app/img/home/accueil/logo-fitbit@2x.png';

import ocJawbone from '@app/img/home/accueil/logo-jawbone.png';
import ocJawbonex2 from '@app/img/home/accueil/logo-jawbone@2x.png';

import ocHealthkit from '@app/img/home/accueil/logo-healthkit.png';
import ocHealthkitx2 from '@app/img/home/accueil/logo-healthkit@2x.png';

import ocGooglefit from '@app/img/home/accueil/logo-googlefit.png';
import ocGooglefitx2 from '@app/img/home/accueil/logo-googlefit@2x.png';

import ocRunkeeper from '@app/img/home/accueil/logo-runkeeper.png';
import ocRunkeeperx2 from '@app/img/home/accueil/logo-runkeeper@2x.png';

import ocIHealth from '@app/img/home/accueil/logo-ihealth.png';
import ocIHealthx2 from '@app/img/home/accueil/logo-ihealth@2x.png';

import foncAdvice from '@app/img/home/accueil/icon-conseils.png';
import foncAdvicex2 from '@app/img/home/accueil/icon-conseils@2x.png';
import foncAdviceColored from '@app/img/home/accueil/icon-conseils-color.png';
import foncAdviceColoredx2 from '@app/img/home/accueil/icon-conseils-color@2x.png';

import foncCoaching from '@app/img/home/accueil/icon-coaching.png';
import foncCoachingx2 from '@app/img/home/accueil/icon-coaching@2x.png';
import foncCoachingColored from '@app/img/home/accueil/icon-coaching-color.png';
import foncCoachingColoredx2 from '@app/img/home/accueil/icon-coaching-color@2x.png';

import foncTableau from '@app/img/home/accueil/icon-tableau.png';
import foncTableaux2 from '@app/img/home/accueil/icon-tableau@2x.png';
import foncTableauColored from '@app/img/home/accueil/icon-tableau-color.png';
import foncTableauColoredx2 from '@app/img/home/accueil/icon-tableau-color@2x.png';

import foncRecipe from '@app/img/home/accueil/icon-recettes.png';
import foncRecipex2 from '@app/img/home/accueil/icon-recettes@2x.png';
import foncRecipeColored from '@app/img/home/accueil/icon-recettes-color.png';
import foncRecipeColoredx2 from '@app/img/home/accueil/icon-recettes-color@2x.png';

import foncGamif from '@app/img/home/accueil/icon-gamification.png';
import foncGamifx2 from '@app/img/home/accueil/icon-gamification@2x.png';
import foncGamifColored from '@app/img/home/accueil/icon-gamification-color.png';
import foncGamifColoredx2 from '@app/img/home/accueil/icon-gamification-color@2x.png';

import foncOC from '@app/img/home/accueil/icon-oc.png';
import foncOCx2 from '@app/img/home/accueil/icon-oc@2x.png';
import foncOCColored from '@app/img/home/accueil/icon-oc-color.png';
import foncOCColoredx2 from '@app/img/home/accueil/icon-oc-color@2x.png';

import foncChallenge from '@app/img/home/accueil/icon-defis.png';
import foncChallengex2 from '@app/img/home/accueil/icon-defis@2x.png';
import foncChallengeColored from '@app/img/home/accueil/icon-defis-color.png';
import foncChallengeColoredx2 from '@app/img/home/accueil/icon-defis-color@2x.png';

import foncQuizz from '@app/img/home/accueil/icon-quizz.png';
import foncQuizzx2 from '@app/img/home/accueil/icon-quizz@2x.png';
import foncQuizzColored from '@app/img/home/accueil/icon-quizz-color.png';
import foncQuizzColoredx2 from '@app/img/home/accueil/icon-quizz-color@2x.png';

import foncAlgo from '@app/img/home/accueil/icon-algorithme.png';
import foncAlgox2 from '@app/img/home/accueil/icon-algorithme@2x.png';
import foncAlgoColored from '@app/img/home/accueil/icon-algorithme-color.png';
import foncAlgoColoredx2 from '@app/img/home/accueil/icon-algorithme-color@2x.png';

import bgGeo from '@app/img/home/accueil/background-geometrique.png';
import bgGeox2 from '@app/img/home/accueil/background-geometrique@2x.png';

const homeModalContent = <div id='test'><iframe id='btzModal-btzIframeModal' src='https://player.vimeo.com/video/156669587' frameborder='0' webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe></div>

module.exports = {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <div key='home'>
                <HomeLayout style={{ position: 'relative' }} page='home' customer={props.customer} browserlang={props.browserlang}>
                    <div class='btzHome-btzHomeHeader-btzHomeFullBg' style={{ 'background-image': ('url(' + topFullBg + ')') }}>
                        <div class='btzWrapper-verticalAlignContent'>
                            <div class='btzMaxWidthWrapper'>
                                <div class='col-8 col-md-12'>
                                    <p class='btzTitle btzXl'>{t('Nous vous aidons à vivre mieux et à prendre soin de votre santé.', {ns: 'home'})}</p>
                                    <p class='btzSubTitle'>{t('Betterise est intelligent, simple et ludique.', {ns: 'home'})}</p>

                                    <Modal id='btzHomeVideoModal' content={homeModalContent} kind='vimeo' id='btzModal-btzIframeModal'>
                                        <p class='btzLink btzLink-btzButtonLike' style={{ 'border': 'none' }}>
                                            <img src={playButton} srcset={`${playButtonx2} 2x`} alt='' class='' />
                                            {t('Découvrir notre vidéo', {ns: 'home'})}
                                        </p>
                                    </Modal>

                                    <p style={{ 'color': 'white' }}>{t('ou', {ns: 'home'})}</p>
                                    <Link to={allRoutes['home.register'].pathname} class='btzHeader-btzRegister'>{t('S‘inscrire', {ns: 'home'})}</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class='btzWrapper btzVerticalAlign btzLeftAlign'>
                        <div class='btzMaxWidthWrapper'>
                            <div class='col-7 col-md-12' style={{ 'vertical-align': 'middle' }}>
                                <p class='btzTitle btzXl'>{t('Le majordome de votre santé', {ns: 'home'})}</p>
                                <p class='btzSubTitle btzXl'>{t('Betterise vous propose un accompagnement complet, ultra-personnalisé, au service de votre bien-être et de votre santé.', {ns: 'home'})}</p>
                                <p class='btzTexte'>{t('Parce que Betterise apprend à vous connaître, il sait vous proposer chaque jour une multitude de contenus parfaitement adaptés à votre profil, à vos besoins, à votre comportement et même, au temps qu’il fait là où vous vous trouvez : vous serez bientôt surpris(e) de son niveau de pertinence.', {ns: 'home'})}</p>
                                <p class='btzTexte'>{t('Betterise est le service de santé digitale le plus innovant, le plus complet.', {ns: 'home'})}</p>
                                <p class='btzTexte'>{t('Crédible scientifiquement, il est aussi le plus bienveillant qui existe aujourd’hui pour vous aider à profiter, au maximum, de votre vie en pleine santé.', {ns: 'home'})}</p>
                                <p class='btzTexte'>{t('Betterise est intelligent, et ça, ça change tout.', {ns: 'home'})}</p>
                            </div>
                            <div class='col-5 col-md-12 p-0' style={{ 'vertical-align': 'middle', 'text-align': 'right' }}>
                                <img src={product1} srcset={`${product1x2} 2x`} alt='' class='' style={{ 'max-width': '90vw' }} />
                            </div>
                        </div>
                    </div>

                    <div class='btzWrapper btzVerticalAlign btzLeftAlign' style={{ 'border-top': '1px solid #DEDEDE' }}>
                        <div class='btzMaxWidthWrapper'>
                            <div class='col-7 col-md-12'>
                                <p class='btzTitle btzXl'>{t('Fonctionnalités', {ns: 'home'})}</p>
                                <p class='btzSubTitle btzXl'>{t('Betterise vous propose un grand nombre de fonctionnalités pour vous permettre de prendre soin de votre santé. Toutes, adaptées à vos besoins, jour après jour.', {ns: 'home'})}</p>
                            </div>
                            <div class='btzHome-btzFonctions'>
                                <div class='col-4 col-md-12 btzHome-btzFonction'>
                                    <div class='btzHome-btzFonc-images'>
                                        <img src={foncAdvice} srcset={`${foncAdvicex2} 2x`} alt='' class='btzHome-btzFonc-imagesOff' />
                                        <img src={foncAdviceColored} srcset={`${foncAdviceColoredx2} 2x`} alt='' class='btzHome-btzFonc-imagesOn' />
                                    </div>
                                    <p class='btzTitle'>{t('Conseils personnalisés', {ns: 'home'})}</p>
                                    <p class='btzSubTitle'>{t('Accédez chaque jour à des milliers de conseils bien-être/santé ultra personnalisés, qui vous correspondent précisément.', {ns: 'home'})}</p>
                                </div>
                                <div class='col-4 col-md-12 btzHome-btzFonction'>
                                    <div class='btzHome-btzFonc-images'>
                                        <img src={foncCoaching} srcset={`${foncCoachingx2} 2x`} alt='' class='btzHome-btzFonc-imagesOff' />
                                        <img src={foncCoachingColored} srcset={`${foncCoachingColoredx2} 2x`} alt='' class='btzHome-btzFonc-imagesOn' />
                                    </div>
                                    <p class='btzTitle'>{t('Programmes de coaching', {ns: 'home'})}</p>
                                    <p class='btzSubTitle'>{t('Découvrez nos dizaines de programmes de coaching de très haute qualité, pour vous aider à atteindre vos objectifs.', {ns: 'home'})}</p>
                                </div>
                                <div class='col-4 col-md-12 btzHome-btzFonction'>
                                    <div class='btzHome-btzFonc-images'>
                                        <img src={foncTableau} srcset={`${foncTableaux2} 2x`} alt='' class='btzHome-btzFonc-imagesOff' />
                                        <img src={foncTableauColored} srcset={`${foncTableauColoredx2} 2x`} alt='' class='btzHome-btzFonc-imagesOn' />
                                    </div>
                                    <p class='btzTitle'>{t('Tableau de bord', {ns: 'home'})}</p>
                                    <p class='btzSubTitle'>{t('Prenez du recul avec notre tableau de bord intuitif, analysant et vous aidant à comprendre votre comportement.', {ns: 'home'})}</p>
                                </div>

                                <div class='col-4 col-md-12 btzHome-btzFonction'>
                                    <div class='btzHome-btzFonc-images'>
                                        <img src={foncRecipe} srcset={`${foncRecipex2} 2x`} alt='' class='btzHome-btzFonc-imagesOff' />
                                        <img src={foncRecipeColored} srcset={`${foncRecipeColoredx2} 2x`} alt='' class='btzHome-btzFonc-imagesOn' />
                                    </div>
                                    <p class='btzTitle'>{t('Recettes de cuisine', {ns: 'home'})}</p>
                                    <p class='btzSubTitle'>{t('Dégustez les recettes préparées par nos nutritionnistes, adaptées à votre vie et vos besoins.', {ns: 'home'})}</p>
                                </div>
                                <div class='col-4 col-md-12 btzHome-btzFonction'>
                                    <div class='btzHome-btzFonc-images'>
                                        <img src={foncGamif} srcset={`${foncGamifx2} 2x`} alt='' class='btzHome-btzFonc-imagesOff' />
                                        <img src={foncGamifColored} srcset={`${foncGamifColoredx2} 2x`} alt='' class='btzHome-btzFonc-imagesOn' />
                                    </div>
                                    <p class='btzTitle'>{t('Gamification', {ns: 'home'})}</p>
                                    <p class='btzSubTitle'>{t('Restez en bonne santé, gagnez des points : oui, être en pleine forme peut être un jeu au quotidien.', {ns: 'home'})}</p>
                                </div>
                                <div class='col-4 col-md-12 btzHome-btzFonction'>
                                    <div class='btzHome-btzFonc-images'>
                                        <img src={foncOC} srcset={`${foncOCx2} 2x`} alt='' class='btzHome-btzFonc-imagesOff' />
                                        <img src={foncOCColored} srcset={`${foncOCColoredx2} 2x`} alt='' class='btzHome-btzFonc-imagesOn' />
                                    </div>
                                    <p class='btzTitle'>{t('Objets connectés', {ns: 'home'})}</p>
                                    <p class='btzSubTitle'>{t('Synchronisez l’ensemble de vos objets connectés sur Betterise. Nous agrégeons, et interprétons leurs données.', {ns: 'home'})}</p>
                                </div>

                                <div class='col-4 col-md-12 btzHome-btzFonction'>
                                    <div class='btzHome-btzFonc-images'>
                                        <img src={foncChallenge} srcset={`${foncChallengex2} 2x`} alt='' class='btzHome-btzFonc-imagesOff' />
                                        <img src={foncChallengeColored} srcset={`${foncChallengeColoredx2} 2x`} alt='' class='btzHome-btzFonc-imagesOn' />
                                    </div>
                                    <p class='btzTitle'>{t('Défis', {ns: 'home'})}</p>
                                    <p class='btzSubTitle'>{t('Les petites actions font les grands changements. Accepterez-vous de relever nos défis ?', {ns: 'home'})}</p>
                                </div>
                                <div class='col-4 col-md-12 btzHome-btzFonction'>
                                    <div class='btzHome-btzFonc-images'>
                                        <img src={foncQuizz} srcset={`${foncQuizzx2} 2x`} alt='' class='btzHome-btzFonc-imagesOff' />
                                        <img src={foncQuizzColored} srcset={`${foncQuizzColoredx2} 2x`} alt='' class='btzHome-btzFonc-imagesOn' />
                                    </div>
                                    <p class='btzTitle'>{t('Quizz', {ns: 'home'})}</p>
                                    <p class='btzSubTitle'>{t('Combattez vos idées reçues sur votre santé grâce à nos quizz. Faut-il vraiment marcher 10 000 pas par jour ?', {ns: 'home'})}</p>
                                </div>
                                <div class='col-4 col-md-12 btzHome-btzFonction'>
                                    <div class='btzHome-btzFonc-images'>
                                        <img src={foncAlgo} srcset={`${foncAlgox2} 2x`} alt='' class='btzHome-btzFonc-imagesOff' />
                                        <img src={foncAlgoColored} srcset={`${foncAlgoColoredx2} 2x`} alt='' class='btzHome-btzFonc-imagesOn' />
                                    </div>
                                    <p class='btzTitle'>{t('Algorithme', {ns: 'home'})}</p>
                                    <p class='btzSubTitle'>{t('Toute l’expérience Betterise vous est entièrement personnalisée et adaptée, grâce à notre algorithme comportemental.', {ns: 'home'})}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class='btzBaseBackgroundColored' style={{ 'padding': '35px 0 35px' }}>
                        <div class='btzMaxWidthWrapper'>
                            <div class='col-10 col-md-12' style={{ 'vertical-align': 'middle' }}>
                                <p class='btzSubTitle btzXl' style={{ 'color': 'white', 'margin': '0px' }}>{t('Profitez de Betterise en illimité pour seulement 14,90€ par mois.', {ns: 'home'})}</p>
                            </div>
                            <div class='col-2 col-md-12' style={{ 'vertical-align': 'middle' }}>
                                <Link to={allRoutes['home.register'].pathname} class='btzHeader-btzRegister'>{t('S‘inscrire', {ns: 'home'})}</Link>
                            </div>
                        </div>
                    </div>

                    <div class='btzWrapper btzVerticalAlign' style={{ 'border-top': '1px solid #DEDEDE' }}>
                        <div class='btzMaxWidthWrapper'>
                            <div class='col-8 col-md-12'>
                                <p class='btzTitle btzXl'>{t('Thématiques', {ns: 'home'})}</p>
                                <p class='btzSubTitle btzXl'>{t('Betterise aborde l’ensemble des thématiques qui ont, un vrai impact sur votre santé, et votre bien-être au quotidien.', {ns: 'home'})}</p>
                                <p class='btzTexte'>{t('L’approche scientifique de Betterise, et l’intégralité de son contenu ont été conçus, étudiés et mis en place par un collège d’experts et de médecins réputés, sous la direction du docteur Michel Cymes.', {ns: 'home'})}</p>
                            </div>

                            <img src={themes} srcset={`${themesx2} 2x`} alt='' class='' style={{ 'margin': '35px 0', 'padding': '0 35px' }} />
                        </div>
                    </div>

                    <div class='btzWrapper btzVerticalAlign btzBaseBackgroundColored' style={{ 'padding': '50px 0px 40px' }}>
                        <div class='btzMaxWidthWrapper'>
                            <div class='col-4 col-md-12 vertical-align-middle'>
                                <img src={photoMichel} srcset={`${photoMichelx2} 2x`} alt='' class='' />
                            </div>
                            <div class='col-8 col-md-10 btzMichelWrapper vertical-align-middle'>
                                <img src={guillemetOpen} srcset={`${guillemetOpenx2} 2x`} alt='' class='' style={{ 'left': '-50px' }} />
                                <p class='btzSubTitle btzXl text-left' style={{ 'color': 'white' }}>{t('Betterise couvre, dans une approche globale unique, l’ensemble des 17 thématiques (Nutrition, Activité, Sommeil...) ayant un impact sur votre santé.', {ns: 'home'})}</p>
                                <p class='btzSubTitle btzXl text-left' style={{ 'color': 'white' }}>{t('Et parce que tout ce que vous lirez est prouvé scientifiquement, je peux vous garantir que cela fonctionne, vraiment. Découvrez une nouvelle manière de prendre soin de vous, et de votre santé.', {ns: 'home'})}</p>
                                <p class='btzTitle btzXl text-left' style={{ 'color': 'white', 'opacity': '0.55' }}>{t('Docteur Michel Cymes.', {ns: 'home'})}</p>
                                <p class='btzSubTitle btzXl text-left' style={{ 'color': 'white' }}>{t('Associé fondateur en charge de la recherche médicale', {ns: 'home'})}</p>
                                <img src={guillemetClose} srcset={`${guillemetClosex2} 2x`} alt='' class='' style={{ 'bottom': '0px', 'right': '0px' }} />
                            </div>
                        </div>
                    </div>

                    <div class='btzWrapper btzVerticalAlign' style={{ 'border-top': '1px solid #DEDEDE' }}>
                        <div class='btzMaxWidthWrapper'>
                            <div class='col-8 col-md-12'>
                                <p class='btzTitle btzXl'>{t('Supports', {ns: 'home'})}</p>
                                <p class='btzSubTitle btzXl'>{t('Betterise est accessible sur le web, via des applications iPhone et Android, et même une application Apple Watch.', {ns: 'home'})}</p>
                                <p class='btzTexte'>{t('Aucun service de santé digitale n’est aussi complet à ce jour.', {ns: 'home'})}</p>
                            </div>

                            <img src={product2} srcset={`${product2x2} 2x`} alt='' class='' style={{ 'margin': '35px 0', 'padding': '0 35px' }} />
                        </div>
                    </div>

                    <div class='btzWrapper btzVerticalAlign' style={{ 'border-top': '1px solid #DEDEDE' }}>
                        <div class='btzMaxWidthWrapper'>
                            <div class='col-8 col-md-12'>
                                <p class='btzTitle btzXl'>{t('Objets connectés', {ns: 'home'})}</p>
                                <p class='btzSubTitle btzXl'>{t('Le quantified self devient, enfin, utile.', {ns: 'home'})}</p>
                                <p class='btzTexte'>{t('Vous avez un objet connecté ? Branchez-le sur Betterise, et toutes vos données seront automatiquement synchronisées, agrégées et interprétées au sein d’un tableau de bord facile d’accès. Mais nous allons plus loin : nous leur donnons un sens. Car sans intelligence, la donnée n’est rien : l’ensemble de la plateforme s’adaptera automatiquement à votre comportement, automatiquement.', {ns: 'home'})}</p>
                            </div>
                        </div>
                        <div class='col-12 btzHome-btzOcWrapper' style={{ 'margin': '35px 0' }}>
                            <img src={ocWithings} srcset={`${ocWithingsx2} 2x`} alt='' class='' />
                            <img src={ocMisfit} srcset={`${ocMisfitx2} 2x`} alt='' class='' />
                            <img src={ocFitbit} srcset={`${ocFitbitx2} 2x`} alt='' class='' />
                            <img src={ocJawbone} srcset={`${ocJawbonex2} 2x`} alt='' class='' />
                            <img src={ocHealthkit} srcset={`${ocHealthkitx2} 2x`} alt='' class='' />
                            <img src={ocGooglefit} srcset={`${ocGooglefitx2} 2x`} alt='' class='' />
                            <img src={ocRunkeeper} srcset={`${ocRunkeeperx2} 2x`} alt='' class='' />
                            <img src={ocIHealth} srcset={`${ocIHealthx2} 2x`} alt='' class='' />
                        </div>
                    </div>

                    <div class='btzHome-btzFooter'>
                        <div class='col-10 p-0'>
                            <p class='btzTexte col-6 col-md-10'>{t('Vous voulez en savoir plus sur la technologie derrière Betterise, la sécurité des données', {ns: 'home'})}</p>
                            <Link to={allRoutes['home.technology'].pathname} class='btzLink btzLink-btzButtonLike'>
                                {t('En savoir plus', {ns: 'home'})}
                            </Link>
                            <img src={bgGeo} srcset={`${bgGeox2} 2x`} alt='' class='' />
                        </div>
                    </div>

                    <div class='btzWrapper btzVerticalAlign'>
                        <div class='btzMaxWidthWrapper'>
                            <div class='col-6 col-md-12 vertical-align-top' style={{ 'position': 'relative' }}>
                                <p class='btzSubTitle btzXl btzBaseColored'>{t('Ils parlent de nous', {ns: 'home'})}</p>
                                <img src={theyTalk} srcset={`${theyTalkx2} 2x`} alt='' class='' />
                                <div class='btzVerticalHr' style={{ 'position': 'absolute', 'top': '0', 'right': '0' }}></div>
                            </div>
                            <div class='col-6 col-md-12 vertical-align-top'>
                                <p class='btzSubTitle btzXl btzBaseColored'>{t('Ils nous soutiennent', {ns: 'home'})}</p>
                                <img src={theySupport} srcset={`${theySupportx2} 2x`} alt='' class='' />
                            </div>
                        </div>
                    </div>
                </HomeLayout>
            </div>
        );
        return view;
    }
}