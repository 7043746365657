import { t, h } from '@app/utils';
import * as staticsconstants from '@app/constants/constants-statics';

import decoMaintenance from '@app/img/btz-maintenance.png';
import decoMaintenancex2 from '@app/img/btz-maintenance@2x.png';

export const view = (state, actions) => (props, children) => (
    <div key='publicmaintenance'>
        <div class='col-5 hide-md p-0' style={{ 'vertical-align': 'middle', 'text-align': 'left' }}>
            <img src={decoMaintenance} srcset={`${decoMaintenancex2} 2x`} alt='' />
        </div>
        <div class='col-7 col-md-12' style={{ 'vertical-align': 'middle' }}>
            <div class='btzMaintenance'>
                <div class='btzMaintenance-btzWrapper' style={{ 'text-align': 'left' }}>
                    <p class='btzTitle btzXl'>{staticsconstants.CUSTOMERS[props.customer].title + ' ' + t('est indisponible.', {ns: 'public'})}</p>

                    <p class='btzTitle btzXl' style={{ 'font-size': '20px' }}>
                        {t('Nous revenons très vite. Le plus vite possible.', {ns: 'public'})}<br/>
                        {t('Désolé pour ce contre temps.', {ns: 'public'})}<br/>
                        {t('Nous vous invitons à réessayer plus tard.', {ns: 'public'})}
                    </p>

                    <div class='' style={{ 'border-top': '1px solid white', 'display': 'inline-block', 'padding-top': '10px', 'margin-top': '150px' }}>
                        <p class='btzTitle btzXl' style={{ 'font-size': '20px' }}>
                            {t('Une question ?', {ns: 'public'})}<br/>
                            <a href={'mailto:' + staticsconstants.CUSTOMERS[props.customer].mailto}>{staticsconstants.CUSTOMERS[props.customer].mailto}</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

);