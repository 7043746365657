import { t, h } from '@app/utils';
import { Link } from '@app/elements';

import HomeLayout from '@app/layouts/home/layout';
import { allRoutes } from '@app/settings';

import illuTech from '@app/img/home/technologie/illu-technologie.png';
import illuTechx2 from '@app/img/home/technologie/illu-technologie@2x.png';

import logoAinssi from '@app/img/home/technologie/logo-anssi.png';
import logoAinssix2 from '@app/img/home/technologie/logo-anssi@2x.png';

import bgGeo from '@app/img/home/accueil/background-geometrique.png';
import bgGeox2 from '@app/img/home/accueil/background-geometrique@2x.png';


export const view = (state, actions) => (props, children) => (
    <div key='technology'>
        <HomeLayout style={{ position: 'relative' }} page='technology'>
            <div class='btzHome-btzHeader btzHome-btzHeader-blue'>
                <div class='btzHome-btzHeader-btzContent'>
                    <div class='col-10'>
                        <p class='btzTitle btzXl'>{t('La Technologie', {ns: 'home'})}</p>
                        <p class='btzTexte col-10 col-md-12'>{t('Betterise est une plateforme intelligente, au service de votre bien-être, reposant sur les dernières technologies algorithmiques et de gestion de données en masse, dites « big data ». Ne vous inquiétez pas pour autant : nous utilisons également les dernières technologies en terme de sécurité des données, et de confidentialité. Vos données sont à vous, rien qu’à vous.', {ns: 'home'})}</p>
                    </div>
                </div>
            </div>

            <div class='btzHome-btzContent column'>
                <div class='col-10'>
                    <div class='col-6 col-md-12 btzLeftAnchored' style={{ 'vertical-align': 'top' }}>
                        <p class='btzTitle btzXl'>{t('Un algorithme surpuissant.', {ns: 'home'})}<br />{t('Le BigData, à votre service.', {ns: 'home'})}</p>
                        <p class='btzTexte'>{t('Betterise est basé sur un algorithme comportemental capable de vous adresser des conseils ultra-personnalisés en fonction de votre profil, votre comportement, vos habitudes, vos envies ou même... de la météo.', {ns: 'home'})}</p>
                        <p class='btzTexte'>{t('Betterise gère aujourd’hui plus de 900 indicateurs comportementaux vous concernant, en temps réel, pour vous donner le bon conseil, au bon moment. Sur le web, nos applications mobiles, ou même par mail.', {ns: 'home'})}</p>
                        <p class='btzTexte'>{t('Waow ! Vous n’avez jamais été aussi bien accompagné(e).', {ns: 'home'})}</p>
                    </div>
                    <div class='col-6 col-md-12 btzRightAnchored' style={{ 'padding-top': '35px' }}>
                        <img src={illuTech} srcset={`${illuTechx2} 2x`} alt='' class='' />
                    </div>
                </div>
            </div>

            <div class='btzHr'></div>

            <div class='btzHome-btzContent'>
                <div class='col-10'>
                    <div class='col-6 col-md-12'>
                        <p class='btzTitle btzXl'>{t('Vous êtes bien avec nous.', {ns: 'home'})}<br />{t('Vos données aussi.', {ns: 'home'})}</p>
                        <p class='btzTexte'>{t('Ne vous inquiétez pas : vos données sont à vous, et à personne d’autre. Nous ne revendrons jamais vos données, ni ne les transmettrons à des tiers.  Car ce n’est ni notre modèle économique, ni notre manière de penser la santé.', {ns: 'home'})}</p>
                        <p class='btzTexte'>{t('Cela aussi, fait la différence.', {ns: 'home'})}</p>
                        <p class='btzTexte'>{t('Et nous utilisons en plus les protocoles de chiffrement les plus poussés au monde, pour vous garantir une sécurité, et une confidentialité à toute épreuve.', {ns: 'home'})}</p>
                        <p class='btzTexte'>{t('Et de plus, nous respectons, à la lettre, les consignes de l’ANSSI (Agence Nationale pour la sécurité des systèmes d’information)', {ns: 'home'})}</p>
                    </div>
                    <div class='col-6 col-md-12' style={{ 'padding-top': '35px' }}>
                        <img src={logoAinssi} srcset={`${logoAinssix2} 2x`} alt='' class='' />
                        <p class='btzTexte' style={{ 'text-align': 'center' }}>{t('Logo de l ANSSI', {ns: 'home'})}</p>
                    </div>
                </div>
            </div>

            <div class='btzHome-btzFooter'>
                <div class='col-10'>
                    <p class='btzTexte col-6 col-md-10'>{t('Betterise est issu d‘un long travail de concertation avec des médecins, des chirurgiens, des spécialistes. Pour en savoir plus sur l‘approche médicale de Betterise, cliquez ici.', {ns: 'home'})}</p>
                    <Link to={allRoutes['home.scientific'].pathname} class='btzLink btzLink-btzButtonLike'>
                        {t('En savoir plus', {ns: 'home'})}
                    </Link>
                    <img src={bgGeo} srcset={`${bgGeox2} 2x`} alt='' class='' />
                </div>
            </div>
        </HomeLayout>
    </div>
);
