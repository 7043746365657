import { User, Mail } from '@app/api';
import { t, h, Component } from '@app/utils';
import { Button, Card } from '@app/elements';
import './index.scss';

const state = {
    checked: true,
    verified: true,
    sended: false,
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        actions.onComponentUpdate(props);
    },
    onComponentUpdate: (props) => (state, actions) => {
        if (props.security.emailVerified === true) {
            let isChecked = true;
            console.log('props.settings[setting] : ', props.settings)
            for (var setting in props.settings) {
                if (props.settings[setting].name === 'STORY') {
                    console.log('props.settings[setting]  ', props.settings[setting]);
                    isChecked = ((props.settings[setting].value === '0') || (props.settings[setting].value === 0) || (props.settings[setting].value === false)) ? false : true;
                    break;
                }
            }
            actions.setChecked(isChecked);
        } else {
            actions.setVerified(false);
        }
    },
    switchEmailConfig: (event) => (state, actions) => {
        let settings = [
            {name: 'CHECKUPDAILY', value: (([true, 1].indexOf(event.target.checked) > -1) ? 1 : 0)},
            {name: 'EMAILHEBDO', value: (([true, 1].indexOf(event.target.checked) > -1) ? 1 : 0)},
            {name: 'STORY', value: (([true, 1].indexOf(event.target.checked) > -1) ? 1 : 0)},
            {name: 'BADGEUNLOCKED', value: 0}
        ];

        User.setUserSettings(settings).then(() => {

        });
    },
    sendVerificationMail: () => (state, actions) => {
        if (state.sended) {
            return false;
        }
        actions.setSended(true);
        Mail.verification().then(() => {
            //
        });
    },
    setChecked: newState => state => ({
        checked: newState,
    }),
    setVerified: newState => state => ({
        verified: newState,
    }),
    setSended: newState => state => ({
        sended: newState,
    }),
};

const view = (state, actions) => (props, children) => (
    <Card id={'abonnement'} key={'param-abo'} classes={'btzPageCard btzPageCard-btzPageCardNoPadding'} kind={'param'}>
        <Card.Header classes={'btzCard-btzHeader-btzPage'}>
            <div class='col-12'>
                <p class='btzTimeline-btzCard-btzTitle btzPageContentTitle'>{t('Abonnement aux emails', {ns: 'abonnement'})}</p>
                {state.verified ?
                    <p class='btzTimeline-btzCard-btzSubTitle btzPageContentSubTitle'>{t('Gérez ici l’abonnement aux emails.', {ns: 'abonnement'})}</p>
                    :
                    <p class='btzTimeline-btzCard-btzSubTitle btzPageContentSubTitle'>{t('Vous devez vérifier votre email.', {ns: 'abonnement'})}</p>
                }
            </div>
        </Card.Header>

        <Card.Body>
            {state.verified ?
                <div>
                    <Button.SwitchCheckBox {...state} onclick={(event) => {
                        actions.switchEmailConfig(event);
                    }}>
                        {t('Je veux recevoir les emails', {ns: 'abonnement'})}
                    </Button.SwitchCheckBox>
                </div>
                :
                <div>
                    {!state.sended ?
                        <Button primary active flat style={{ 'color': 'white' }} onclick={() => {
                            actions.sendVerificationMail()
                        }}>
                            {t('Vérifier mon email', {ns: 'abonnement'})}
                        </Button>
                        :
                        <p class='btzTimeline-btzCard-btzSubTitle btzPageContentTexte' style='text-align: center;padding: 0px 0 20px 0;'>{t('Veuillez vérifier vos emails.', {ns: 'abonnement'})}</p>
                    }
                </div>
            }
        </Card.Body>

        <Card.Footer></Card.Footer>
    </Card>
);

export default Component(state, actions, view, 'abonnement');
