import { t, h, Component, isLength, isNumeric } from '@app/utils';
import { debounce } from 'hyperapp-fx';
import { User, Data, Lunch } from '@app/api';
import { convertMetrics, convertMetricsLabels, setUser, getUser, isDefined, dynLoad } from '@app/core';
import * as constants from '@app/constants';
import './index.scss';

const state = {
    computationMode: 'atall',
    computationEnable: null,
    userMetrics: {
        weight: null,
        height: null
    },
    userGender: '1',
    userMedia: null,
    weight: {
        question: 'Combien pesez-vous ? (en kg)',
        i18nQuestion: 'Combien pesez-vous ? (en kg)',
        questionLabel: 'kilogrammes',
        i18nQuestionLabel: t('kilogrammes', {ns: 'food'}),
        placeholder: '65',
        isTouched: true,
        isValid: false,
        value: '',
        kind: 'weight',
        submit: 'weight',
        type: 'counter',
        api: {
            message: 'Saisissez votre poids.',
            i18nMessage: t('Saisissez votre poids.', {ns: 'food'}),
            kind: 'info',
            errors: {
                i18nValid: t('Saisissez votre poids.', {ns: 'food'}),
                valid: 'Saisissez votre poids.',
                i18nEmpty: t('Merci de renseigner votre poids.', {ns: 'food'}),
                empty: 'Merci de renseigner votre poids.',
                i18nInvalid: t('Veuillez renseigner un poids valide.', {ns: 'food'}),
                invalid: 'Veuillez renseigner un poids valide.',
            }
        }
    },
    height: {
        question: 'Combien mesurez-vous ? (en cm)',
        i18nQuestion: t('Combien mesurez-vous ? (en cm)', {ns: 'food'}),
        questionLabel: 'centimètres',
        i18nQuestionLabel: t('centimètres', {ns: 'food'}),
        placeholder: '170',
        isTouched: true,
        isValid: false,
        value: '',
        kind: 'height',
        submit: 'height',
        type: 'counter',
        api: {
            message: 'Saisissez votre taille.',
            i18nMessage: t('Saisissez votre taille.', {ns: 'food'}),
            kind: 'info',
            errors: {
                i18nValid: t('Saisissez votre taille.', {ns: 'food'}),
                valid: 'Saisissez votre taille.',
                i18nEmpty: t('Merci de renseigner votre taille.', {ns: 'food'}),
                empty: 'Merci de renseigner votre taille.',
                i18nInvalid: t('Veuillez renseigner une taille valide.', {ns: 'food'}),
                invalid: 'Veuillez renseigner une taille valide.',
            }
        }
    },
    gender: {
        question: 'Êtes-vous un homme ou une femme ?',
        i18nQuestion: t('Êtes-vous un homme ou une femme ?', {ns: 'food'}),
        placeholder: '',
        isTouched: false,
        isValid: false,
        value: '',
        kind: 'gender',
        submit: 'gender',
        type: 'radio',
        api: {
            message: 'Sélectionnez l’un des deux boutons.',
            i18nMessage: t('Sélectionnez l’un des deux boutons.', {ns: 'food'}),
            kind: 'info',
            errors: {
                valid: 'Sélectionnez l’un des deux boutons.',
                i18nValid: t('Sélectionnez l’un des deux boutons.', {ns: 'food'}),
                empty: '',
                invalid: '',
            }
        }
    },
    birthdate: {
        question: 'Quelle est votre date de naissance ?',
        i18nQuestion: t('Quelle est votre date de naissance ?', {ns: 'food'}),
        placeholder: '',
        isTouched:  {
            year: false,
            month: false,
            day: false
        },
        isValid:  {
            year: false,
            month: false,
            day: false
        },
        value: {
            year: '',
            month: '',
            day: ''
        },
        kind: 'birthdate',
        submit: 'birthdate',
        type: 'date',
        api: {
            message: 'Saisissez votre date de naissance.',
            i18nMessage: t('Saisissez votre date de naissance.', {ns: 'food'}),
            kind: 'info',
            errors: {
                i18nValid: t('Saisissez votre date de naissance.', {ns: 'food'}),
                valid: 'Saisissez votre date de naissance.',
                i18nEmpty: t('Merci de renseigner votre date de naissance.', {ns: 'food'}),
                empty: 'Merci de renseigner votre date de naissance.',
                i18nInvalid: t('Merci de saisir une date de naissance valide.', {ns: 'food'}),
                invalid: 'Merci de saisir une date de naissance valide.',
            }
        }
    },
    activity: {
        question: 'Généralement, quel est le niveau de votre activité physique* ?',
        i18nQuestion: t('Généralement, quel est le niveau de votre activité physique* ?', {ns: 'food'}),
        placeholder: '',
        isTouched: false,
        isValid: false,
        value: '',
        kind: 'activity',
        submit: 'activity',
        type: 'dropdown',
        api: {
            message: '',
            kind: '',
            errors: {
                valid: '',
                empty: '',
                invalid: '',
            }
        }
    },
    current: {
        kind: 'none',
    },
    api: {
        message: '',
        kind: 'info',
    },
    rendering: false,
    activities: ['Inactif', 'Activité modérée', 'Activité importante', 'Activité très importante'],
    i18nActivities: [t('Inactif', {ns: 'food'}), t('Activité modérée', {ns: 'food'}), t('Activité importante', {ns: 'food'}), t('Activité très importante', {ns: 'food'})],
    dayLunchs: null,
    origDayLunchs: [],
    balanceLunchs: null,
    dateLunch: null,
    ymdMealDate: null,
    addSuccess: false,
    editSuccess: false,
    lastAddedId: null,
    transition: false,
    atLeastOneMeal: false,
    user: null,
}

const actions = {
    onComponentCreate: () => (state, actions) => {
        window.addEventListener('addLunchSuccess', () => {
            actions.displayAddSuccessMessage(true);
        }, false);
        window.addEventListener('editLunchSuccess', () => {
            actions.displayEditSuccessMessage(true);
        }, false);
        window.addEventListener('setLastAddedId', (ev) => {
            actions.setLastAddedId(ev.detail.id);
        }, false);

        let userCallback = (sessionUser) => {
            actions.setUser(sessionUser);
            state.userMetrics.weight = sessionUser.metrics;
            state.userMetrics.height = sessionUser.heightMetrics;

            state.weight.question = convertMetricsLabels(true, 'weight', sessionUser.metrics, state.weight.question);
            state.weight.i18nQuestion = t(convertMetricsLabels(true, 'weight', sessionUser.metrics, state.weight.question));
            state.weight.questionLabel = convertMetricsLabels(false, 'weight', sessionUser.metrics, state.weight.questionLabel);
            state.weight.i18nQuestionLabel = t(convertMetricsLabels(false, 'weight', sessionUser.metrics, state.weight.i18nQuestionLabel));

            state.height.question = convertMetricsLabels(true, 'height', sessionUser.heightMetrics, state.height.question);
            state.height.i18nQuestion = t(convertMetricsLabels(true, 'height', sessionUser.heightMetrics, state.height.question));
            state.height.questionLabel = convertMetricsLabels(false, 'height', sessionUser.heightMetrics, state.height.questionLabel);
            state.height.i18nQuestionLabel = t(convertMetricsLabels(false, 'height', sessionUser.heightMetrics, state.height.i18nQuestionLabel));

            state.userGender = (sessionUser.gender ? '1' : '0');
            state.userMedia = sessionUser.media.url;

            let refKPI = null;
            if (sessionUser.profile !== undefined) {
                if (sessionUser.profile.nutrition !== undefined) {
                    if (sessionUser.profile.nutrition.calories !== undefined) {
                        if (sessionUser.profile.nutrition.calories.ideal !== undefined) {
                            refKPI = sessionUser.profile.nutrition.calories.ideal;
                            if (((sessionUser.profile.nutrition.meal !== null) || (sessionUser.profile.nutrition.drink !== null))
                                && ((sessionUser.profile.nutrition.meal !== undefined) || (sessionUser.profile.nutrition.drink !== undefined))) {
                                actions.setAtLeastOneMeal(true);
                            }
                        }
                    }
                }
            }

            var today = new Date();
            var year = today.getUTCFullYear(), month = (today.getUTCMonth() + 1), day = today.getUTCDate();
            if (+month < 10) {
                month = '0' + month;
            }
            if (+day < 10) {
                day = '0' + day;
            }
            today = year + '' + month + '' + day;

            if (refKPI !== null) {
                actions.setComputationState(true);
                // actions.initializeDashboardFood();
            } else {
                actions.setYMDMealDate(today);
                actions.setComputationState(false);
                state['gender'].value = sessionUser['gender'];
                state['gender'].isValid = true;
                for (var userEntry in sessionUser) {
                    for (var stateEntry in state) {
                        if (userEntry === stateEntry) {
                            if (userEntry.indexOf('date') > -1) {
                                let parsedDate = sessionUser[userEntry];
                                if (isDefined(parsedDate) && isDefined(parsedDate.date)) {
                                    parsedDate = parsedDate.date.split(' ')[0].split('-');
                                    if (parsedDate.length === 3) {
                                        state[userEntry].value.year = parsedDate[0];
                                        state[userEntry].value.month = parsedDate[1];
                                        state[userEntry].value.day = parsedDate[2];

                                        state[userEntry].isValid.year = true;
                                        state[userEntry].isValid.month = true;
                                        state[userEntry].isValid.day = true;
                                    }
                                }
                            } else {
                                let userValue = sessionUser[userEntry];
                                // actions.setState({ns: userEntry, key: 'value', value: userValue});
                                state[userEntry].value = userValue;
                                if (sessionUser[userEntry] !== '') {
                                    let isValid = actions.isInputValid({name: stateEntry, value: userValue});
                                    // actions.setState({ns: userEntry, key: 'isValid', value: isValid});
                                    state[userEntry].isValid = isValid;
                                }
                            }
                        } else if (stateEntry === 'weight') {
                            let userValue = sessionUser.profile.personal.weight;
                            if ((userValue !== undefined) && (userValue !== null)) {
                                userValue = userValue.current;
                                // actions.setState({ns: stateEntry, key: 'value', value: userValue});
                                state[stateEntry].value = userValue;
                                let isValid = actions.isInputValid({name: stateEntry, value: userValue});
                                // actions.setState({ns: stateEntry, key: 'isValid', value: isValid});
                                state[stateEntry].isValid = isValid;
                            }
                        } else if (stateEntry === 'height') {
                            let userValue = sessionUser.profile.personal.height;
                            if ((userValue !== undefined) && (userValue !== null)) {
                                // actions.setState({ns: stateEntry, key: 'value', value: userValue});
                                state[stateEntry].value = userValue;
                                let isValid = actions.isInputValid({name: stateEntry, value: userValue});
                                // actions.setState({ns: stateEntry, key: 'isValid', value: isValid});
                                state[stateEntry].isValid = isValid;
                            }
                        } else if (stateEntry === 'activity') {
                            let userValue = sessionUser.profile.activity;
                            if (userValue !== undefined) {
                                userValue = userValue.grade;
                                if (userValue !== undefined) {
                                    // actions.setState({ns: stateEntry, key: 'value', value: userValue});
                                    state[stateEntry].value = userValue;
                                    let isValid = actions.isInputValid({name: stateEntry, value: userValue});
                                    // actions.setState({ns: stateEntry, key: 'isValid', value: isValid});
                                    state[stateEntry].isValid = isValid;
                                }
                            }
                        }
                    }
                }
            }
            actions.rendering();
        };
        getUser(userCallback, true);
    },

    initializeDashboardFood: () => (state, actions) => {
        var today = new Date();
        var year = today.getUTCFullYear(), month = (today.getUTCMonth() + 1), day = today.getUTCDate();
        if (+month < 10) {
            month = '0' + month;
        }
        if (+day < 10) {
            day = '0' + day;
        }
        today = year + '' + month + '' + day;
        actions.setDateLunch(today);
        window.addEventListener('updateLunchListing', () => {
            actions.updateLunchsListing();
            actions.updateLunchsBalance();
            actions.resetAddMealPopup();
        }, false);
        var event = new CustomEvent('updateLunchListing');
        window.dispatchEvent(event);
    },

    updateLunchsListing: () => (state, actions) => {
        actions.setComputationMode('loading');
        actions.setLastAddedId(null);
        Lunch.getLunchsByDate(state.dateLunch).then((res) => {
            if (isDefined(res.data) && (res.data.length > 0)) {
                actions.setAtLeastOneMeal(true);
                actions.setComputationMode('daymeals');
                actions.setDayLunchs(res.data);
                actions.setOrigDayLunchs(JSON.parse(JSON.stringify(res.data)));
            } else {
                if (!state.atLeastOneMeal) {
                    actions.setComputationMode('atall');
                } else {
                    actions.setComputationMode('thisweek');
                }
            }

            actions.setYMDMealDate(state.dateLunch);
        });
    },

    updateLunchsBalance: () => (state, actions) => {
        Lunch.getDashboardInfo(state.dateLunch).then((res) => {
            actions.setBalanceLunchs(res.data);
        });
    },

    resetAddMealPopup: () => (state, actions) => {
        actions.rendering();
    },

    rollbackDayLunchAliment: () => (state, actions) => {
        actions.setDayLunchs(JSON.parse(JSON.stringify(state.origDayLunchs)));
    },

    onUpdate: ({ target: { name, value } }) => (state, actions) => {
        let isValid = actions.isInputValid({ name, value });

        if (name.indexOf('date') > -1) {
            let refPeriod = name.split('-')[1];

            state.current.api.message = t(state.current.api.errors.valid);
            state.current.api.kind = 'info';
            actions.setDateState({ns: 'current', key: 'value', subkey: refPeriod, value: value});
            actions.setDateState({ns: 'current', key: 'isValid', subkey: refPeriod, value: isValid});
            actions.setDateState({ns: 'current', key: 'isTouched', subkey: refPeriod, value: true});
        } else {
            state.current.api.message = t(state.current.api.errors.valid);
            state.current.api.kind = 'info';
            actions.setState({ns: 'current', key: 'value', value: value});
            actions.setState({ns: 'current', key: 'isValid', value: isValid});
            actions.setState({ns: 'current', key: 'isTouched', value: true});
        }
    },
    waitForLastInput: ({ target }) => debounce(
        240,
        'onUpdate',
        { target },
    ),
    onFocus: ({ target: { name } }) => (state, actions) => {
        actions.setTouched({ [name]: true });
    },
    onBlur: ({ target }) => (state, actions) => {
        actions.onUpdate({ target });
    },
    setTouched: newState => state => ({
        isTouched: { ...state.isTouched, ...newState },
    }),

    updateCurrent: (newCurrent) => (state, actions) => {
        actions.setCurrent(state[newCurrent]);
    },

    setCurrent: newState => state => ({
        current: JSON.parse(JSON.stringify(newState)),
    }),

    setApiErrors: newState => state => ({
        api: newState,
    }),

    setAtLeastOneMeal: newState => state => ({
        atLeastOneMeal: newState,
    }),

    setCurrentApiErrorsMessage: newState => state => ({
        current: {...state.current, api: {...state.current.api, message: newState}},
    }),
    setCurrentApiErrorsKind: newState => state => ({
        current: {...state.current, api: {...state.current.api, kind: newState}},
    }),

    setState: ({ ns, key, value }) => state => ({
        [ns]: { ...state[ns], ...{ [key]: value } },
    }),

    setDateState: ({ ns, key, subkey, value }) => state => ({
        [ns]: { ...state[ns], [key] : { ...state[ns][key], [subkey] : value } },
    }),

    setYMDMealDate: newState => state => ({
        ymdMealDate: newState,
    }),

    setComputationState: newState => state => ({
        computationEnable: newState,
    }),

    setComputationMode: newState => state => ({
        computationMode: newState,
    }),

    setDayLunchs: newState => state => ({
        dayLunchs: newState,
    }),

    setBalanceLunchs:  newState => state => ({
        balanceLunchs: newState,
    }),

    rendering: () => state => ({
        rendering: !state.rendering,
    }),

    setDateLunch: newState => state => ({
        dateLunch: newState,
    }),

    setLastAddedId: newState => state => ({
        lastAddedId: newState,
    }),

    setOrigDayLunchs: newState => state => ({
        origDayLunchs: newState,
    }),
    setTransitScreen: newState => state => ({
        transition: newState,
    }),

    setUser: newState => state => ({
        user: newState,
    }),

    isInputValid: ({ name, value }) => (state, actions) => {
        if (name === undefined) {
            return false;
        }

        if ((name === 'height') || (name === 'weight')) {
            value = convertMetrics('metric', name, ((name === 'height') ? state.userMetrics.height : state.userMetrics.weight), value);
        }

        switch (name) {
            case 'height':
                return ((+value > convertMetrics('metric', 'height', state.userMetrics.height, constants.custo.METRICS.height.min)) && (+value < convertMetrics('metric', 'height', state.userMetrics.height, constants.custo.METRICS.height.max)));
            case 'weight':
                return ((+value > convertMetrics('metric', 'weight', state.userMetrics.weight, constants.custo.METRICS.weight.min)) && (+value < convertMetrics('metric', 'weight', state.userMetrics.weight, constants.custo.METRICS.weight.max)));
            case 'activity':
            case 'gender':
                return value !== null;
            case 'counter':
                return isLength(value, { min: 2});
            case 'birthdate-day':
            case 'birthdate-month':
                return (isNumeric(value) && isLength(value, { min: 2, max: 2}));
            case 'birthdate-year':
                return (isNumeric(value) && isLength(value, { min: 4, max: 4}));
            default:
                throw Error(`${name} has no validator assigned`);
        }
    },

    onSubmit: () => (state, actions) => {
        let data = {};
        let field = state.current.submit;
        let value = state.current.value;
        let isValid = state.current.isValid;
        let verifIsValid = true;
        if (field.indexOf('date') > -1) {
            isValid = (state.current.isValid.year && state.current.isValid.month && state.current.isValid.day);
        }
        data[field] = value;

        let day = data[field].day;
        let month = data[field].month;
        let year = data[field].year;
        if (isValid) {
            if (field.indexOf('date') > -1) {
                data[field] = {
                    date: year + '-' + month + '-' + day + ' 00:00:00.000000',
                    timezone: 'UTC',
                    timezone_type: 3
                }
            }
        }

        if ((value === '') || (value === undefined)) {
            actions.setCurrentApiErrorsMessage(t(state.current.api.errors.empty));
            actions.setCurrentApiErrorsKind('error');
        } else if (!isValid) {
            actions.setCurrentApiErrorsMessage(t(state.current.api.errors.invalid));
            actions.setCurrentApiErrorsKind('error');
        } else if (!verifIsValid) {
            actions.setCurrentApiErrorsMessage(t(state.current.api.errors.invalidVerif));
            actions.setCurrentApiErrorsKind('error');
        } else {
            if ((field === 'birthdate') || (field === 'gender')) {
                User.updateUser(data).then((res) => {
                    if (res.data === false) {

                    } else {
                        setUser(res.data);
                        if (field === 'birthdate') {
                            state[field].isValid.year = true;
                            state[field].isValid.month = true;
                            state[field].isValid.day = true;

                            state[field].value.year = year;
                            state[field].value.month = month;
                            state[field].value.day = day;
                        } else {
                            actions.setState({ns: state.current.kind, key: 'value', value: value});
                        }
                        actions.rendering();
                    }
                });
            } else {
                let dateObj = new Date();
                let dateDay = dateObj.getUTCDate();
                let dateMonth = dateObj.getUTCMonth() + 1;
                let dateYear = dateObj.getUTCFullYear();

                if (+dateDay < 10) {
                    dateDay = ('0' + dateDay);
                }
                if (+dateMonth < 10) {
                    dateMonth = ('0' + dateMonth);
                }

                let dataTag = '';
                if (field === 'weight') {
                    dataTag = 'PERSONAL_WEIGHT';
                } else if (field === 'height') {
                    dataTag = 'PERSONAL_HEIGHT';
                } else if (field === 'activity') {
                    dataTag = 'ACTIVITY_GRADE';
                }

                let parsedData = [{
                    tag: dataTag,
                    value: data[field],
                    date: {
                        date: dateYear + '-' + dateMonth + '-' + dateDay + 'T00:00:00.000Z',
                        timezone: 'UTC',
                        timezone_type: 3
                    },
                    source: 'betterise'
                }];
                Data.postData(parsedData).then((res) => {
                    if (res.data === false) {

                    } else {
                        actions.setState({ns: state.current.kind, key: 'isValid', value: true});
                        actions.setState({ns: state.current.kind, key: 'value', value: value});
                        actions.rendering();
                    }
                });
            }
        }
    },

    updateSelector: (date) => (state, actions) => {
        state.ymdMealDate = date;
        state.dateLunch = date;
        // actions.setYMDMealDate(date);
        actions.updateLunchsListing();
        actions.updateLunchsBalance();
        actions.resetAddMealPopup();
    },

    displayAddSuccessMessage: newState => state => ({
        addSuccess: newState,
    }),

    displayEditSuccessMessage: newState => state => ({
        editSuccess: newState,
    })
};

const view = dynLoad('view', 'Food/NoMeal/views');
export default Component(state, actions, view, 'food');
