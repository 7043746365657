import { h } from '@app/utils';

import imgLoader from '@app/img/loader.gif';

export const view = (state, actions) => (props, children) => (
    <div key='Waiting'>
        <div class='btzWaiting'>
            <div class='btzWaiting-btzWrapper'>
                <img src={imgLoader} alt='' class='' />
                {/*<p class='btzTitle btzXl' style={{ 'font-size': '180px' }}>• 302 •</p>
                <p class='btzTitle btzXl'>{t('Veuillez patienter, vous allez être redirigé.', {ns: 'public'})}</p>*/}
            </div>
        </div>
    </div>
);