import { h } from '@app/utils';

export const Header = (props, actions) => (
    <head>
        <meta charset='utf-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1, shrink-to-fit=no' />
        <meta name='theme-color' content='#000000' />
        <link rel='manifest' href='/manifest.json' />
        <link rel='shortcut icon' href='/favicon.ico' />
        <link rel='stylesheet' href='/css/spectre.css' />
        <link rel='stylesheet' href='/css/jsCalendar.css' />
        <link rel='stylesheet' href='/css/cropper.min.css' />
        <script src='https://js.stripe.com/v3/'></script>
        <script src='/js/jsCalendar/jsCalendar.min.js'></script>
        <script src='/js/jsCalendar/jsCalendar.lang.fr.js'></script>
        <script src='/js/cropper.min.js'></script>
        <title>Betterise</title>
    </head>
);