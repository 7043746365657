import { t, h, Component } from '@app/utils';
import { isDefined } from '@app/core';
import { Grid, Navbar, TopSticked, Enter } from '@app/elements';
import * as constants from '@app/constants';

import logoBurger from '@app/img/ico/ico-burger.png';
import logoBurgerx2 from '@app/img/ico/ico-burger@2x.png';

import imgHeaderFullWidth from '@app/img/timeline/img-timeline-header.png';
import imgHeaderFullWidthx2 from '@app/img/timeline/img-timeline-header@2x.png';

import { TopMenuView } from '@app/layouts/topmenu';
import { TopLogoView } from '@app/layouts/toplogo';

const actions = {
    goBack: () => state => {
        window.history.back();
    },
    getDate: ({el, language = 'fr_FR'}) => {
        if (!isDefined(language) || (language === '')) {
            language = 'fr_FR';
        }
        var date = new Date(Date.parse(new Date()));
        var options = { weekday: 'long', month: 'long', day: 'numeric' };
        el.innerHTML = date.toLocaleDateString(language.replace('_', '-'), options);
    }
}

const view = (state, actions) => (props, children) => (
    <div key={props.page} class={props.page} data-theme={props.theme}>
        <Enter time={constants.custo.ANIM_DURATION} easing={constants.custo.ANIM_EASING} css={constants.custo.ANIM_CSS}>
            <Grid classes={'btzHeader'}>
                <Grid.Row classes={'btzHeaderWrapper'}>
                    <Grid.Col x={10} mx={'auto'}>
                        <Navbar>
                            <label for='show-menu' class='btzMenu-btzResponsiveMenu'>
                                <img id='btzBurgerMenu-btzBurgerMenuImg' src={logoBurger} srcset={`${logoBurgerx2} 2x`} alt='' />
                            </label>
                            <input id='show-menu' role='button' type='checkbox' />

                            <Navbar.Section classes='btzHeader-btzHeaderLeftHand'>
                                <TopLogoView {...props} customer={(props.customer || 'betterise')}></TopLogoView>
                            </Navbar.Section>

                            <Navbar.Section classes='btzHeader-btzHeaderRightHand'>
                                <TopMenuView {...props} customer={(props.customer || 'betterise')}></TopMenuView>
                            </Navbar.Section>
                        </Navbar>

                    </Grid.Col>
                </Grid.Row>
            </Grid>

            <Grid.Row classes={'btzHeaderImageFullWidthWrapper'}>
                <TopSticked topdetection={1} classes={'btzStickedWrapperForced'} top={73} progressive={true}>
                    <img src={imgHeaderFullWidth} srcset={`${imgHeaderFullWidthx2} 2x`} alt='' />
                    <Grid.Col x={10} mx={'auto'}>
                        <div class='btzHeaderImageFullWidthWrapper-btzContent'>
                            <div class='btzHeaderImageFullWidthWrapper-btzContent-btzWrapper'>
                                <p class='btzTitle btzXl' oncreate={(el) => {actions.getDate({el: el, language: props.language})}} style={{ 'text-transform': 'capitalize' }}></p>
                                <p class='btzSubTitle'>{t('aujourd‘hui', {ns: 'timeline'})}</p>
                            </div>
                        </div>
                    </Grid.Col>
                </TopSticked>
            </Grid.Row>

            <Grid classes={''} style={{ 'padding-bottom': '100px', 'padding-top': '50px' }}>
                <Grid.Row classes={''}>
                    <Grid.Col x={10} mx={'auto'} style={{ 'position': 'relative' }} classes={'col-xs-12 p-0'}>
                        {children}
                    </Grid.Col>
                </Grid.Row>
            </Grid>
        </Enter>
    </div>
);

export default Component({}, actions, view, 'layout');