import { t, h } from '@app/utils';
import { Button, Modal, Card } from '@app/elements';
import './../index.scss';

import { ModalView } from './../modalView';

module.exports = {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <Card id={'resiliate'} key={'param-resiliate'} classes={'btzPageCard btzPageCard-btzPageCardNoPadding'} kind={'param'}>
                <Card.Header classes={'btzCard-btzHeader-btzPage'}>
                    <div class='col-12'>
                        <p class='btzTimeline-btzCard-btzTitle btzPageContentTitle'>{t('Résiliation', {ns: 'resiliate'})}</p>
                        <p class='btzTimeline-btzCard-btzSubTitle btzPageContentTexte'>
                            {t('Vous souhaitez vraiment nous quitter ? Cela me rend bien triste.', {ns: 'resiliate'})}
                            <br/><br/>
                            {t('Sachez que la résiliation de votre compte entraînera la suppression complète et immédiate de l’ensemble de votre compte. Vous n’aurez plus accès à vos données.', {ns: 'resiliate'})}
                        </p>
                    </div>
                </Card.Header>

                <Card.Body>
                    <div>
                        <Modal content={<ModalView {...state} {...actions} error={state.error} customer={props.customer}></ModalView>}>
                            <Button primary dangerous flat style={{ 'color': 'white' }}>
                                {t('Résilier', {ns: 'resiliate'})}
                            </Button>
                        </Modal>
                    </div>
                </Card.Body>

                <Card.Footer></Card.Footer>
            </Card>
        );
        return view;
    }
}
