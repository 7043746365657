import { t, h } from '@app/utils';
import { Button, Form, Tile, Card, Avatar, Balloon } from '@app/elements';

import decoResiliate from '@app/img/deco/deco-resiliate.png';
import decoResiliatex2 from '@app/img/deco/deco-resiliate@2x.png';

export const ModalView = (props, actions) => (
    <Card classes={'btzInformations-btzResiliationModal'}>
        <Card.Header classes={'btzCard-btzHeader-btzModal'}>
            {props.userIsResiliated === true ?
                <div>
                    <p class='btzTitle btzXl'>{t('Résiliation', {ns: 'resiliate'})}</p>

                    <p class='btzPageContent'>
                        {t('Compte', {ns: 'resiliate'})} <font>{props.email}</font> {t('résilié', {ns: 'resiliate'})}.<br/>
                        {t('Vous allez être redirigé automatiquement.', {ns: 'resiliate'})}
                        <br/><br/>
                        {t('Merci de patienter quelques secondes.', {ns: 'resiliate'})}
                    </p>

                    <img src={decoResiliate} srcset={`${decoResiliatex2} 2x`} alt='' class='' />
                </div>
                :
                <div style={{ 'padding-bottom': '250px' }}>
                    {props.customer === 'feelgood' ?
                        <p class='btzTitle btzXl' style={{ 'padding': '0 15px' }}>{t('Vous souhaitez mettre fin à votre expérience ?', {ns: 'resiliate'})}</p>
                        :
                        <p class='btzTitle btzXl'>{t('Saisissez votre mot de passe pour confirmer votre résiliation :', {ns: 'resiliate'})}</p>
                    }

                    {props.customer === 'feelgood' ?
                        <Button primary flat dangerous onclick={(event) => {
                            props.onSubmit(event);
                        }}>
                            {t('Confirmer la résiliation ?', {ns: 'resiliate'})}
                        </Button>
                        :
                        <Form onsubmit={event => event.preventDefault()}>
                            <div>
                                <Form.Group>
                                    <Form.Input
                                        classes={'btzFullWidth'}
                                        placeholder={t('Votre mot de passe', {ns: 'generals'})}
                                        type={'password'}
                                        id={'password'}
                                        name={'password'}
                                        onfocus={props.onBlur}
                                        oninput={props.waitForLastInput}
                                        error={props.password.isTouched && !props.password.isValid}
                                        classes={(props.password.isTouched) ? (props.password.isValid ? 'is-success' : 'is-error') : ''}
                                    />
                                </Form.Group>

                                <Form.Group classes='btzForm-btzFooter'>
                                    <Button primary flat dangerous={props.password.isTouched && props.password.isValid} onclick={(event) => {
                                        if (props.password.isTouched && props.password.isValid) {
                                            props.onSubmit(event);
                                        }
                                    }}>
                                        {t('Confirmer', {ns: 'generals'})}
                                    </Button>
                                </Form.Group>
                            </div>
                        </Form>
                    }

                    <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap' }}>
                        <Tile.Icon>
                            <Avatar.Alfred classes={'centered'} />
                        </Tile.Icon>
                        <Tile.Content>
                            {props.error === '' ?
                                <Balloon kind={'info'} style={{ 'white-space': 'normal' }}>
                                    <font innerHTML={t('Vous souhaitez vraiment nous quitter ?<br/>Cela me rend bien triste.<br/><br/>Sachez que votre résiliation entraînera la suppression complète et immédiate de l’ensemble de votre compte. Vous n’aurez plus accès à vos données.', {ns: 'resiliate'})}></font>
                                </Balloon>
                                :
                                <Balloon kind={'error'} style={{ 'white-space': 'normal' }}>
                                    <font innerHTML={props.error}></font>
                                </Balloon>
                            }
                        </Tile.Content>
                    </Tile>
                </div>
            }
        </Card.Header>
    </Card>
);