import { t, h, Component } from '@app/utils';
import { Avatar, Button, Modal, Card, Tile, Balloon } from '@app/elements';
import { isDefined } from '@app/core';
import './index.scss';

import { User } from '@app/api';
import { ModalView } from './modalView';
import { StripeID } from './../../../Register/step-payment/stripeWrapper';

const state = {
    error: 'Vous pouvez consulter vos données bancaires en toute sécurité quand vous le souhaitez.',
    i18nError: t('Vous pouvez consulter vos données bancaires en toute sécurité quand vous le souhaitez.', {ns: 'cb'}),
    kind: 'info',
    payment: null,
    api: {
        message: 'Vous pouvez consulter votre carte bancaire.',
        kind: 'info',
    },
    // eslint-disable-next-line
    stripe: Stripe(StripeID),
    stripeElementCardNumber: null,
    stripeElementCardExpiry: null,
    stripeElementCardCvc: null,
    allFieldsAreValid: false,
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        User.getUserPayment().then((res) => {
            actions.setPayment(res.data);
        });
    },
    onComponentUpdate: (props) => (state, actions) => {
        //
    },
    setStripeElement: (elements) => (state, actions) => {
        state.stripeElement = elements;
    },

    setStripeElementCardNumber: (cardNumber) => (state, actions) => {
        state.stripeElementCardNumber = cardNumber;
    },

    setStripeElementCardExpiry: (cardExpiry) => (state, actions) => {
        state.stripeElementCardExpiry = cardExpiry;
    },

    setStripeElementCardCvc: (cardCvc) => (state, actions) => {
        state.stripeElementCardCvc = cardCvc;
    },
    setAllFieldsAreValid: newState => state => ({
        allFieldsAreValid: newState,
    }),
    setPayment: newState => state => ({
        payment: newState,
    })
};

const view = (state, actions) => (props, children) => (
    <Card id={'cb'} key={'param-data'} classes={'btzPageCard btzPageCard-btzPageCardNoPadding'} kind={'param'}>
        <Card.Header classes={'btzCard-btzHeader-btzPage'}>
            <div class='col-12'>
                <p class='btzTimeline-btzCard-btzTitle btzPageContentTitle'>{t('Vos données bancaires', {ns: 'cb'})}</p>
                <p class='btzTimeline-btzCard-btzSubTitle btzPageContentSubTitle'>{t('Vous pouvez consulter ou supprimer vos données bancaires à tout moment.', {ns: 'cb'})}</p>
            </div>
        </Card.Header>

        <Card.Body>
            <div class='col-6 col-sm-12' style={{ 'vertical-align': 'middle' }}>
                {isDefined(state.payment) ?
                    <div>
                        <div class='col-12 p-0 btzFakeForm-btzFakeFormEntry'>
                            <p class='col-5 btzFakeForm-btzFakeFormEntry-btzLabel btzPageContentTexte'>
                                {t('Numéro', {ns: 'cb'})}
                            </p>
                            <div class='col-7 p-0 btzFakeForm-btzFakeFormEntry-btzValues'>
                                <p key={''} class='col-10 p-0 btzFakeForm-btzFakeFormEntry-btzValue btzPageContentTexte btzXl'>{'**** **** **** ' + state.payment.sources.data[0].card.last4}</p>
                            </div>
                        </div>

                        <div class='col-12 p-0 btzFakeForm-btzFakeFormEntry'>
                            <p class='col-5 btzFakeForm-btzFakeFormEntry-btzLabel btzPageContentTexte'>
                                {t('Expire fin', {ns: 'cb'})}
                            </p>
                            <div class='col-7 p-0 btzFakeForm-btzFakeFormEntry-btzValues'>
                                {isDefined(props.payment) &&
                                    <p key={''} class='col-10 p-0 btzFakeForm-btzFakeFormEntry-btzValue btzPageContentTexte btzXl'>{(props.payment.sources.data[0].card.exp_month < 10 ? ('0' + props.payment.sources.data[0].card.exp_month) : props.payment.sources.data[0].card.exp_month) + '/' + state.payment.sources.data[0].card.exp_year}</p>
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <div>
                        <div class='col-12 p-0 btzFakeForm-btzFakeFormEntry'>
                            <p class='btzFakeForm-btzFakeFormEntry-btzLabel btzPageContentTexte btzError'>
                                {t('Une erreur est survenue lors de la récupération de vos informations banquaires.', {ns: 'cb'})}
                            </p>
                        </div>
                    </div>
                }

                <div style={{ 'margin-top': '35px' }}>
                    <Modal content={<ModalView {...state} customActions={actions} error={state.error} payment={state.payment}></ModalView>}>
                        <Button primary flat style={{ 'color': 'white' }}>
                            {t('Détails', {ns: 'cb'})}
                        </Button>
                    </Modal>
                </div>
            </div>

            <div class='col-6 col-sm-12' style={{ 'vertical-align': 'middle' }}>
                <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap' }}>
                    <Tile.Icon classes={''}>
                        <Avatar.Alfred classes={'centered'} />
                    </Tile.Icon>
                    <Tile.Content>
                        <Balloon kind={state.kind} style={{ 'white-space': 'normal' }}>{state.error}</Balloon>
                    </Tile.Content>
                </Tile>
            </div>
        </Card.Body>

        <Card.Footer></Card.Footer>
    </Card>
);

export default Component(state, actions, view, 'cb');
