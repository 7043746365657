import { getQueryVariable, addCSRFToken } from '@app/core';
import { allRoutes } from '@app/settings';

export const actions = {
    onComponentCreate: (props) => (state, actions) => {
        let kind = getQueryVariable('kind', false);
        let type = getQueryVariable('type', false);
        let found = false;
        if (kind === 'sponsor') {
            if (type === 'expired') {
                actions.setKind('sponsorexpire');
                found = true;
            } else if (type === 'resiliated') {
                actions.setKind('sponsorresiliated');
                found = true;
            }
        } else if (kind === 'mutual') {
            if (type === 'expired') {
                actions.setKind('mutualexpire');
                found = true;
            }
        } else if (kind === 'enterprise') {
            if (type === 'expired') {
                actions.setKind('enterpriseexpire');
                found = true;
            }
        }
        // actions.setKind('mutualexpire');

        if (!found) {
            window.location.href = addCSRFToken(allRoutes['public.cb'].pathname);
        }
    },
    setKind: newState => state => ({
        kind: newState,
    }),
    setUserEmail: newState => state => ({
        userEmail: newState,
    }),
    setSponsorEmail: newState => state => ({
        sponsorEmail: newState,
    }),
    setUserXToken: newState => state => ({
        userXToken: newState,
    }),
};
