import { t, h, isLength } from '@app/utils';
import { Modal, Card, MessagePopup, ImageCropper } from '@app/elements';
import { isDefined, logoutAndRedirect } from '@app/core';

import * as staticsconstants from '@app/constants/constants-statics';

import './../index.scss';

import { ModalView } from './modalView-default';

import icoEdit from '@app/img/ico/ico-adddatas.png';
import icoEditx2 from '@app/img/ico/ico-adddatas@2x.png';

module.exports = {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <Card id={'informations'} key={'param-information'} classes={'btzPageCard btzPageCard-btzPageCardNoPadding'} kind={'param'}>
                <Card.Header classes={'btzCard-btzHeader-btzPage'}>
                    <div class='col-12'>
                        <p class='btzTimeline-btzCard-btzTitle btzPageContentTitle'>{t(staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()].menuNames.parameters.title)}</p>
                        <p class='btzTimeline-btzCard-btzSubTitle btzPageContentSubTitle'>{t('Cliquez sur un champ pour le modifier.', {ns: 'informations'})}</p>
                    </div>
                </Card.Header>

                {state.messageActive &&
                    <MessagePopup kind={'success'} active={'active'} duration={3000} durationCallback={() => actions.displaySuccessMessage(false)} message={t('La modification de vos informations a été enregistrée avec succès !', {ns: 'informations'})}></MessagePopup>
                }

                <Card.Body>
                    <div class='col-6 col-md-12 btzFakeForm'>
                        <div class='col-11 p-0 btzFakeForm-btzFakeFormEntry' style={{ 'text-align': 'left' }}>
                            <p class='col-6 col-sm-12 btzFakeForm-btzFakeFormEntry-btzLabel btzPageContentTexte'>
                                {t('Genre', {ns: 'informations'})}
                            </p>
                            <div key={state.gender.value + '-' + state.rendering} class='col-6 col-sm-12 p-0 btzFakeForm-btzFakeFormEntry-btzValues'>
                                <p class='col-10 btzFakeForm-btzFakeFormEntry-btzValue btzPageContentTexte btzXl'>
                                    {((state.gender.value === true) || (state.gender.value === '1')) ? t('Homme', {ns: 'generals'}) : t('Femme', {ns: 'generals'})}
                                </p>
                                <div class='col-2 p-0'>
                                    <Modal content={<ModalView displayed={'gender'} {...state} waitForLastInput={actions.waitForLastInput} updateCurrent={actions.updateCurrent} send={actions.setState} onSubmit={actions.onSubmit} onBlur={actions.onBlur}></ModalView>}>
                                        <div onclick={() => {
                                            actions.setCurrent(state.gender);
                                        }}>
                                            <img src={icoEdit} srcset={`${icoEditx2} 2x`} alt='' class='' />
                                        </div>
                                    </Modal>
                                </div>
                            </div>
                        </div>

                        <div class='col-11 p-0 btzFakeForm-btzFakeFormEntry'>
                            <p class='col-6 col-sm-12 btzFakeForm-btzFakeFormEntry-btzLabel btzPageContentTexte'>
                                {t('Pseudo', {ns: 'informations'})}
                            </p>
                            <div key={state.nick.value + '-' + state.rendering} class='col-6 col-sm-12 p-0 btzFakeForm-btzFakeFormEntry-btzValues'>
                                <p class='col-10 btzFakeForm-btzFakeFormEntry-btzValue btzPageContentTexte btzXl'>{state.nick.value}</p>
                                <div class='col-2 p-0'>
                                    <Modal content={<ModalView displayed={'nick'} {...state} waitForLastInput={actions.waitForLastInput} updateCurrent={actions.updateCurrent} send={actions.setState} onSubmit={actions.onSubmit} onBlur={actions.onBlur}></ModalView>}>
                                        <div onclick={() => {
                                            actions.setCurrent(state.nick);
                                        }}>
                                            <img src={icoEdit} srcset={`${icoEditx2} 2x`} alt='' class='' />
                                        </div>
                                    </Modal>
                                </div>
                            </div>
                        </div>

                        <div class='col-11 p-0 btzFakeForm-btzFakeFormEntry'>
                            <p class='col-6 col-sm-12 btzFakeForm-btzFakeFormEntry-btzLabel btzPageContentTexte'>
                                {t('Ville de résidence', {ns: 'informations'})}
                            </p>
                            <div key={state.city.value + '-' + state.rendering} class='col-6 col-sm-12 p-0 btzFakeForm-btzFakeFormEntry-btzValues'>
                                {state.city.value !== '' ?
                                    <p class='col-10 btzFakeForm-btzFakeFormEntry-btzValue btzPageContentTexte btzXl'>{state.city.value}</p>
                                    :
                                    <p class='col-10 btzFakeForm-btzFakeFormEntry-btzValue btzPageContentTexte' style={{ 'color': '#989898' }}>
                                        {t('À renseigner', {ns: 'informations'})}
                                    </p>
                                }
                                <div class='col-2 p-0'>
                                    <Modal content={<ModalView displayed={'city'} {...state} waitForLastInput={actions.waitForLastInput} updateCurrent={actions.updateCurrent} send={actions.setState} onSubmit={actions.onSubmit} onBlur={actions.onBlur} setCityValue={actions.setCityValue} onCityInputDebouncedUpdate={actions.onCityInputDebouncedUpdate} onCitiesListClick={actions.onCitiesListClick}></ModalView>}>
                                        <div onclick={() => {
                                            actions.setCurrent(state.city);
                                        }}>
                                            <img src={icoEdit} srcset={`${icoEditx2} 2x`} alt='' class='' />
                                        </div>
                                    </Modal>
                                </div>
                            </div>
                        </div>

                        <div class='col-11 p-0 btzFakeForm-btzFakeFormEntry'>
                            <p class='col-6 col-sm-12 btzFakeForm-btzFakeFormEntry-btzLabel btzPageContentTexte'>
                                {t('Date de naissance', {ns: 'informations'})}
                            </p>
                            <div key={state.birthdate.value.day + '/' + state.birthdate.value.month + '/' + state.birthdate.value.year + '-' + state.rendering} class='col-6 col-sm-12 p-0 btzFakeForm-btzFakeFormEntry-btzValues'>
                                {state.birthdate.value.day !== '' ?
                                    <p class='col-10 btzFakeForm-btzFakeFormEntry-btzValue btzPageContentTexte btzXl'>
                                        {state.birthdate.value.day + '/' + state.birthdate.value.month + '/' + state.birthdate.value.year}
                                    </p>
                                    :
                                    <p class='col-10 btzFakeForm-btzFakeFormEntry-btzValue btzPageContentTexte' style={{ 'color': '#989898' }}>
                                        {t('À renseigner', {ns: 'informations'})}
                                    </p>
                                }
                                <div class='col-2 p-0'>
                                    <Modal content={<ModalView displayed={'birthdate'} {...state} waitForLastInput={actions.waitForLastInput} updateCurrent={actions.updateCurrent} send={actions.setState} onSubmit={actions.onSubmit} onBlur={actions.onBlur}></ModalView>}>
                                        <div onclick={() => {
                                            actions.setCurrent(state.birthdate);
                                        }}>
                                            <img src={icoEdit} srcset={`${icoEditx2} 2x`} alt='' class='' />
                                        </div>
                                    </Modal>
                                </div>
                            </div>
                        </div>

                        {(isDefined(state.settings) && isDefined(state.languages)) &&
                            <div class='col-11 p-0 btzFakeForm-btzFakeFormEntry'>
                                <p class='col-6 col-sm-12 btzFakeForm-btzFakeFormEntry-btzLabel btzPageContentTexte'>
                                    {t('Langue préférée', {ns: 'informations'})}
                                </p>
                                <div key={state.language.value + '-' + state.rendering} class='col-6 col-sm-12 p-0 btzFakeForm-btzFakeFormEntry-btzValues'>
                                    <p class='col-10 btzFakeForm-btzFakeFormEntry-btzValue btzPageContentTexte btzXl'>
                                        {t(state.languages[state.language.value])}
                                    </p>
                                    <div class='col-2 p-0'>
                                        <Modal content={<ModalView displayed={'language'} {...state} listing={state.languages} withsearch={false} placeholder={''} waitForLastInput={actions.waitForLastInput} updateCurrent={actions.updateCurrent} send={actions.setState} onSubmit={actions.onSubmit} onBlur={actions.onBlur}></ModalView>}>
                                            <div onclick={() => {
                                                actions.setCurrent(state.language);
                                            }}>
                                                <img src={icoEdit} srcset={`${icoEditx2} 2x`} alt='' class='' />
                                            </div>
                                        </Modal>
                                    </div>
                                </div>
                            </div>
                        }

                        <div class='col-11 p-0 btzFakeForm-btzFakeFormEntry'>
                            <p class='col-6 col-sm-12 btzFakeForm-btzFakeFormEntry-btzLabel btzPageContentTexte'>
                                {t('Fuseau horaire', {ns: 'informations'})}
                            </p>
                            {isDefined(state.timezones) ?
                                <div key={state.timezone.value + '-' + state.rendering} class='col-6 col-sm-12 p-0 btzFakeForm-btzFakeFormEntry-btzValues'>
                                    <p class='col-10 btzFakeForm-btzFakeFormEntry-btzValue btzPageContentTexte btzXl'>{state.timezone.value}</p>
                                    <div class='col-2 p-0'>
                                        <Modal content={<ModalView displayed={'timezone'} {...state} listing={state.timezones} withsearch={true} placeholder={t('Votre fuseau horaire', {ns: 'informations'})} waitForLastInput={actions.waitForLastInput} updateCurrent={actions.updateCurrent} send={actions.setState} onSubmit={actions.onSubmit} onBlur={actions.onBlur}></ModalView>}>
                                            <div onclick={() => {
                                                actions.setCurrent(state.timezone);
                                            }}>
                                                <img src={icoEdit} srcset={`${icoEditx2} 2x`} alt='' class='' />
                                            </div>
                                        </Modal>
                                    </div>
                                </div>
                                :
                                <div class='col-6 col-sm-12 p-0 btzFakeForm-btzFakeFormEntry-btzValues'>
                                    <p class='col-12 p-0' style={{ 'text-align': 'right' }}>{t('récupération en cours...', {ns: 'informations'})}</p>
                                </div>
                            }
                        </div>

                        {(isDefined(state.settings) && isDefined(state.settings.METRICS)) &&
                            <div class='col-11 p-0 btzFakeForm-btzFakeFormEntry'>
                                <p class='col-6 col-sm-12 btzFakeForm-btzFakeFormEntry-btzLabel btzPageContentTexte'>
                                    {t('Unités de mesure', {ns: 'informations'})}
                                </p>
                                <div key={state.metrics.value + '-' + state.rendering} class='col-6 col-sm-12 p-0 btzFakeForm-btzFakeFormEntry-btzValues'>
                                    <p class='col-10 btzFakeForm-btzFakeFormEntry-btzValue btzPageContentTexte btzXl'>
                                        {state.settings.METRICS[state.metrics.value]}
                                    </p>
                                    <div class='col-2 p-0'>
                                        <Modal content={<ModalView displayed={'metrics'} {...state} listing={state.settings.METRICS} withsearch={false} placeholder={''} waitForLastInput={actions.waitForLastInput} updateCurrent={actions.updateCurrent} send={actions.setState} onSubmit={actions.onSubmit} onBlur={actions.onBlur}></ModalView>}>
                                            <div onclick={() => {
                                                actions.setCurrent(state.metrics);
                                            }}>
                                                <img src={icoEdit} srcset={`${icoEditx2} 2x`} alt='' class='' />
                                            </div>
                                        </Modal>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div class='col-1 hide-md'></div>
                    <div class='col-5 col-md-11 btzAvatarForm'>
                        <div class='col-12 p-0 btzFakeForm-btzFakeFormEntry' style={{ 'margin-bottom': '60px', 'position': 'relative', 'cursor': 'pointer', 'height': '200px' }}>
                            <ImageCropper height={200} width={'100%'} media={((state.media && (state.media.value !== null)) ? state.media.value : null)} customer={props.customer}></ImageCropper>
                        </div>

                        <div class='col-11 p-0 btzFakeForm-btzFakeFormEntry'>
                            <p class='col-6 col-sm-12 btzFakeForm-btzFakeFormEntry-btzLabel btzPageContentTexte'>
                                {t('Email', {ns: 'informations'})}
                            </p>
                            <div key={state.email.value + '-' + state.rendering} class='col-6 col-sm-12 p-0 btzFakeForm-btzFakeFormEntry-btzValues'>
                                <p class='col-10 btzFakeForm-btzFakeFormEntry-btzValue btzPageContentTexte btzXl ellipsis'>{state.email.value}</p>
                                <div class='col-2 p-0'>
                                    <Modal content={<ModalView displayed={'email'} {...state} waitForLastInput={actions.waitForLastInput} updateCurrent={actions.updateCurrent} send={actions.setState} onSubmit={actions.onSubmit} onBlur={actions.onBlur}></ModalView>}>
                                        <div onclick={() => {
                                            actions.setCurrent(state.email);
                                        }}>
                                            <img src={icoEdit} srcset={`${icoEditx2} 2x`} alt='' class='' />
                                        </div>
                                    </Modal>
                                </div>
                            </div>
                        </div>

                        <div class='col-11 p-0 btzFakeForm-btzFakeFormEntry'>
                            <p class='col-6 col-sm-12 btzFakeForm-btzFakeFormEntry-btzLabel btzPageContentTexte'>
                                {t('Mot de passe', {ns: 'informations'})}
                            </p>
                            {(staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()].modules.accountEdition.password === true) ?
                                <div key={state.rendering} class='col-6 col-sm-12 p-0 btzFakeForm-btzFakeFormEntry-btzValues btzFakeForm-btzFakeFormEntry-btzPassword'>
                                    <p class='col-10 btzFakeForm-btzFakeFormEntry-btzValue btzPageContentTexte btzXl'></p>
                                    <div class='col-2 p-0'>
                                        <Modal content={<ModalView displayed={'password'} {...state} waitForLastInput={actions.waitForLastInput} updateCurrent={actions.updateCurrent} send={actions.setState} onSubmit={actions.onSubmit} onBlur={actions.onBlur}></ModalView>}>
                                            <div onclick={() => {
                                                actions.setCurrent(state.password);
                                            }}>
                                                <img src={icoEdit} srcset={`${icoEditx2} 2x`} alt='' class='' />
                                            </div>
                                        </Modal>
                                    </div>
                                </div>
                                :
                                <div key={state.rendering} class='col-6 col-sm-12 p-0 btzFakeForm-btzFakeFormEntry-btzValues btzFakeForm-btzFakeFormEntry-btzPassword'>
                                    <p class='col-10 btzFakeForm-btzFakeFormEntry-btzValue btzPageContentTexte btzXl' style={{ 'color': '#989898' }}></p>
                                </div>
                            }
                        </div>
                    </div>
                    <div class='show-xs show-sm show-md show-lg' style={{ 'display': 'none', 'padding': '0 30px' }}>
                        <div class='btzLogout' style={{ 'text-align': 'left' }}><div style={{ 'display': 'inline-block' }} onclick={() => {logoutAndRedirect();}}>{t('Se déconnecter', {ns: 'generals'})}</div></div>
                    </div>
                </Card.Body>

                <Card.Footer></Card.Footer>
            </Card>
        );
        return view;
    }
}
