const deepCopy = function(src) {
    let target = Array.isArray(src) ? [] : {};
    for (let key in src) {
        let v = src[key];
        if (v) {
            if (typeof v === 'object') {
                if (v.lastModified !== undefined) {
                    target[key] = v; // to keep File object intact
                } else {
                    target[key] = deepCopy(v);
                }
            } else {
                target[key] = v;
            }
        } else {
            target[key] = v;
        }
    }
    return target;
};

export { deepCopy };