import { t, h } from '@app/utils';
import { Form, Button, Tile, Avatar, Balloon, MessagePopup } from '@app/elements';
import * as staticsconstants from '@app/constants/constants-statics';

import logoBlocked from '@app/img/public/blocked/you-are-blocked.png';
import logoBlockedx2 from '@app/img/public/blocked/you-are-blocked@2x.png';

import logoBlockedCERBA from '@app/img/public/custo/cerba/blocked/you-are-blocked.png';
import logoBlockedCERBAx2 from '@app/img/public/custo/cerba/blocked/you-are-blocked@2x.png';

import './index.scss';

// export const view = (state, actions) => {
//      dont do this, that not working !!!

export const view = (state, actions) => (props, children) => (
    <div>
        {state.messageActive &&
            <MessagePopup kind={'success'} active={'active'} duration={3000} durationCallback={() => actions.displaySuccessMessage(false)} message={t('Email de réinitialisation envoyé !', {ns: 'login'})}></MessagePopup>
        }

        {state.blockedStep === 0 &&
            <div style={{ position: 'relative' }}>
                <Tile>
                    <div class='btzTitle-btzImage btzTitle-btzImage-absolute col-md-12 col-4'>
                        {{
                            ['betterise']: <img src={logoBlocked} srcset={`${logoBlockedx2} 2x`} alt='' class='' />,
                            ['cerba']: <img src={logoBlockedCERBA} srcset={`${logoBlockedCERBAx2} 2x`} alt='' class='' />
                        }[props.customer]}
                    </div>
                    <Tile.Content classes='btzLeftHrArrowed col-xs-12 col-8'>
                        <p class='btzTitle btzXl'>{t('Compte bloqué', {ns: 'login'})}</p>
                        <p class='btzTexte' style={{ 'text-align': 'left' }}>{t('Vous avez fait 5 erreurs successives dans la saisie du mot de passe lors de votre connexion. Votre compte est maintenant bloqué.', {ns: 'login'})}</p>
                        <p class='btzTexte' style={{ 'margin': '0px', 'text-align': 'left' }}>{t('Veuillez réinitialiser votre mot de passe sur l’email que nous avons envoyé à votre adresse mail pour débloquer votre compte.', {ns: 'login'})}</p>
                        <p class='btzTexte' style={{ 'text-align': 'left', 'margin': '35px 0 15px 0' }}>{t('Vous n’avez pas reçu l’email ?', {ns: 'login'})}</p>

                        {state.emailInputDisplayed &&
                            <Form onsubmit={event => event.preventDefault()}>
                                <Form.Group>
                                    <Form.Input
                                        classes={'btzFullWidth'}
                                        placeholder={t('adresse@mail.com', {ns: 'login'})}
                                        type='email'
                                        id='email'
                                        name='email'
                                        dynResize={true}
                                        onfocus={actions.onBlur}
                                        oninput={actions.waitForLastInput}
                                        error={state.isTouched.email && !state.isValid.email}
                                        classes={(state.isTouched.email) ? (state.isValid.email ? 'is-success' : 'is-error') : ''}
                                        required
                                    />
                                </Form.Group>
                            </Form>
                        }
                        <Button primary flat loading={state.formIsSubmitting} active={!state.formIsSubmitting && (state.userEmail !== null)} onclick={actions.sendEmail}>
                            {t('Renvoyer l‘email', {ns: 'login'})}
                        </Button>
                    </Tile.Content>
                </Tile>
            </div>
        }

        {state.blockedStep === 1 &&
            <div style={{ position: 'relative' }}>
                <Tile>
                    <div class='btzTitle-btzImage btzTitle-btzImage-absolute col-md-12 col-4'>
                        {{
                            ['betterise']: <img src={logoBlocked} srcset={`${logoBlockedx2} 2x`} alt='' class='' />,
                            ['cerba']: <img src={logoBlockedCERBA} srcset={`${logoBlockedCERBAx2} 2x`} alt='' class='' />
                        }[props.customer]}
                    </div>
                    <Tile.Content classes='btzLeftHrArrowed col-xs-12 col-8'>
                        <p class='btzTitle btzXl'>{t('Compte bloqué', {ns: 'login'})}</p>
                        <p class='btzTexte' style={{ 'margin-bottom': '35px', 'text-align': 'left' }}>{t('Pour débloquer votre compte, veuillez réinitialiser votre mot de passe sur l’email que nous avons envoyé à votre adresse mail lors du blocage de votre compte.', {ns: 'login'})}</p>
                        <p class='btzTexte' style={{ 'text-align': 'left' }}>{t('Vous n’avez pas reçu l’email ?', {ns: 'login'})}</p>

                        {state.emailInputDisplayed &&
                            <Form onsubmit={event => event.preventDefault()}>
                                <Form.Group>
                                    <Form.Input
                                        classes={'btzFullWidth'}
                                        placeholder={t('adresse@mail.com', {ns: 'login'})}
                                        type='email'
                                        id='email'
                                        name='email'
                                        dynResize={true}
                                        // value={props.initial.value}
                                        oninput={actions.waitForLastInput}
                                        // onchange={e => console.warn(e.value)}
                                        error={state.isTouched.email && !state.isValid.email}
                                        classes={(state.isTouched.email) ? (state.isValid.email ? 'is-success' : 'is-error') : ''}
                                        required
                                    />
                                </Form.Group>
                            </Form>
                        }
                        <Button primary flat onclick={actions.sendEmail}>
                            {t('Renvoyer l‘email', {ns: 'login'})}
                        </Button>
                    </Tile.Content>
                </Tile>
            </div>
        }

        <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap', 'position': 'fixed', 'bottom': '90px', 'left': '0' }}>
            <Tile.Icon>
                <Avatar.Alfred kind={state.api.kind} classes={'centered'} />
            </Tile.Icon>
            <Tile.Content>
                <Balloon kind={state.api.code} atagTitle={staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()].mailto} atag={'mailto:' + staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()].mailto} style={{ 'white-space': 'normal' }}>
                    {state.api.message}
                </Balloon>
            </Tile.Content>
        </Tile>
    </div>
);
