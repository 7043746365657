import { h, t } from '@app/utils';
import { Card, Balloon, Tile, Avatar, Button } from '@app/elements';

import Onboarding from './../../../Onboarding';

import imgBgSurveyEnd from './cerba/deco-endsurvey@2x.png';

export const ModalViewSurvey = (props, actions) => (
    <Card classes={'btzInformations-btzInformationsModal'} style={{ 'display': (props.surveyQuestionsAnswered === false ? 'table' : 'block') }}>
        {props.surveyQuestionsAnswered === false ?
            <Onboarding displayedon={'timeline'} lastanswercallback={props.endSurveyQuestions} setSurveyQuestions={props.setSurveyQuestions} setNothingToSurvey={props.setNothingToSurvey} customer={props.customer} language={props.language}></Onboarding>
            :
            <div style={{ 'height': 'calc(100vh - 73px)' }}>
                <Card.Body classes='' style={{ 'border-radius': '4px', 'overflow': 'hidden' }}>
                    <div class='btzFullBg' style={{ 'background-image': 'url(' + imgBgSurveyEnd + ')' }}></div>
                    <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap', 'top': 'auto', 'bottom': '150px' }}>
                        <Tile.Icon classes={'col-3 col-xs-12'}>
                            <Avatar.Alfred classes={'centered'} />
                        </Tile.Icon>
                        <Tile.Content classes={'col-9 col-xs-12'}>
                            <Balloon style={{ 'white-space': 'normal' }}>
                                {t('Vous avez répondu à toutes les questions de votre questionnaire personnel.\n Grâce à ces réponses, votre nutritionniste va pouvoir vous contacter dans les plus brefs délais.', {ns: 'timeline'})}
                            </Balloon>
                        </Tile.Content>
                    </Tile>
                </Card.Body>

                <Card.Footer style={{ 'text-align': 'center', 'bottom': '30px' }}>
                    <Button primary block active flat onclick={props.hideSurvey} style={{ 'height': '30px' }}>
                        {t('Enregistrer', {ns: 'generals'})}
                    </Button>
                </Card.Footer>
            </div>
        }
    </Card>
);