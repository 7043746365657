import { t, h } from '@app/utils';
import { Button, Form, Tile } from '@app/elements';
import browser from 'bowser';

import logoStep from '@app/img/register/your-pc.png';
import logoStepx2 from '@app/img/register/your-pc@2x.png';

import './../index.scss';

module.exports = {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <div style={{ position: 'relative' }}>
                <Tile>
                    <div class='btzTitle-btzImage btzTitle-btzImage-absolute col-md-12 col-4'>
                        <img src={logoStep} srcset={`${logoStepx2} 2x`} alt='' class='' />
                    </div>
                    <Tile.Content classes='btzLeftHrArrowed col-xs-12 col-8' style={{ 'padding-bottom': (state.panel.isVisible ? '120px' : '0px') }}>
                        <p class='btzTitle btzXl'>{t('Reconnaissance de', {ns: 'register'})}<br />{t('votre navigateur', {ns: 'register'})}</p>
                        <p class='btzTexte'>{t('A chaque connexion nous vérifions que votre compte est bien utilisé sur ce navigateur.', {ns: 'register'})}</p>
                        <div class='btzDevice'>
                            <p class='btzTitle btzXl'>{t('Votre navigateur', {ns: 'register'})}</p>
                            <p class='btzTexte'>{browser.name} ({browser.osname}, {(browser.mobile || browser.tablet) ? 'Tablet' : 'Desktop'})</p>
                        </div>
                        <div style={{ 'display': 'block', 'margin': '0' }}>
                            <Button link block onclick={() => actions.onPanelClickUpdate()} style={{ 'display': 'block', 'font-family': 'avenir-roman', 'color': '#A6A6A6' }}>
                                {t('Comment ça marche ?', {ns: 'register'})}
                            </Button>
                        </div>

                        {state.panel.isVisible && (
                            <div class=' col-xs-12 col-2 hidden-paragraph' style={{ 'width': '100%' }}>
                                <p>{t('En autorisant la vérification, vous nous autorisez à récupérer l‘identifiant du navigateur que vous utilisez.', {ns: 'register'})}</p>
                                <p>{t('Ensuite, si lors d‘une prochaine connexion, nous nous apercevons que celle-ci se fait sur un autre navigateur, nous vous envoyons un mail pour vous signaler une tentative de connexion suspecte.', {ns: 'register'})}</p>
                                <p>{t('Si c‘est bien vous, l‘identifiant du navigateur sera enregistré sur votre compte.', {ns: 'register'})}</p>
                                <p>{t('Si ce n‘est pas vous par contre, vous aurez la possibilité de bloquer la connexion à ce navigateur.', {ns: 'register'})}</p>
                            </div>
                        )}
                    </Tile.Content>
                </Tile>

                <Form onsubmit={event => event.preventDefault()} style={{ 'min-height': 0 }}>
                    <Form.Group classes='btzForm-btzFooter'>
                        <Button primary active onclick={(event) => {
                            actions.updateParentProps(props)
                            actions.onStepSubmit({event: event, props: props});
                        }} disabled={false}>
                            {t('Autoriser la vérification', {ns: 'register'})}
                        </Button>
                    </Form.Group>
                </Form>

            </div>
        );
        return view;
    }
}
