import { t, h } from '@app/utils';
import { Button, Form, Tile, Modal, Card, DatePicker, Balloon, Avatar, MessagePopup } from '@app/elements';
import { convertMetrics } from '@app/core';

import icoEdit from '@app/img/ico/ico-adddatas.png';
import icoEditx2 from '@app/img/ico/ico-adddatas@2x.png';

import logoFood from '@app/img/food/deco-computation.png';
import logoFoodx2 from '@app/img/food/deco-computation@2x.png';

import './../../index.scss';

import PageLayout from '@app/layouts/page/layout';

import { ModalView } from './../../modalView';

import AtAll from './../atall';
import ThisWeek from './../thisweek';
import Transition from './../transit';
import DayMeals from './../../Meal/daymeals';

module.exports = {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <div class={((state.computationEnable === false) && (state.transition === false) ? 'food-configuration' : '') + ' ' + 'lunch-' + state.ymdMealDate}>
                {state.addSuccess &&
                    <MessagePopup kind={'success'} active={'active'} duration={3000} durationCallback={() => actions.displayAddSuccessMessage(false)} message={t('Votre repas a été enregistré avec succès !', {ns: 'food'})}></MessagePopup>
                }
                {state.editSuccess &&
                    <MessagePopup kind={'success'} active={'active'} duration={3000} durationCallback={() => actions.displayEditSuccessMessage(false)} message={t('La modification a été enregistrée avec succès !', {ns: 'food'})}></MessagePopup>
                }

                {state.computationEnable === true &&
                    <div oncreate={() => { actions.initializeDashboardFood(); }}>
                        <PageLayout page='food' readablePageLabel={t('Alimentation', {ns: 'food'})} readablePageDescription={t('Saisissez et analyser votre alimentation grâce à nos recommandations.', {ns: 'food'})} userMedia={state.userMedia} customer={props.customer}>
                            <Card classes={''} kind={'datas'} style={{ 'border-radius': '70px' }}>
                                <Card.Body data-displayed-kind={state.filter} style={{ 'padding-top': '0px' }}>
                                    {state.ymdMealDate !== null &&
                                        <DatePicker date={state.ymdMealDate} selectorAction={actions.updateSelector} mode={'day'} cols={{left: 4, center: 4, right: 4}}></DatePicker>
                                    }
                                </Card.Body>
                            </Card>
                            {/*state.computationMode === 'loading' &&
                                <Loading customer={props.customer} key={'loading-' + state.ymdMealDate + '-' + state.rendering} ymdMealDate={state.ymdMealDate}></Loading>
                            */}
                            {state.computationMode === 'atall' &&
                                <AtAll customer={props.customer} userGender={state.userGender} key={'atall-' + state.ymdMealDate + '-' + state.rendering} ymdMealDate={state.ymdMealDate}></AtAll>
                            }
                            {state.computationMode === 'thisweek' &&
                                <ThisWeek customer={props.customer} userGender={state.userGender} key={'thisweek-' + state.ymdMealDate + '-' + state.rendering} ymdMealDate={state.ymdMealDate}></ThisWeek>
                            }
                            {state.computationMode === 'daymeals' &&
                                <DayMeals customer={props.customer} user={state.user} userGender={state.userGender} key={'daymeals-' + state.ymdMealDate + '-' + state.rendering} ymdMealDate={state.ymdMealDate} test={'test'} dayLunchs={state.dayLunchs} start={state.dateLunch} end={state.dateLunch} balanceLunchs={state.balanceLunchs} userMetrics={state.userMetrics} userGender={state.userGender} lastAddedId={state.lastAddedId} rollbackDayLunchAliment={actions.rollbackDayLunchAliment}></DayMeals>
                            }
                        </PageLayout>
                    </div>
                }
                {((state.computationEnable === false) && (state.transition === false)) &&
                    <PageLayout page='food' userMedia={state.userMedia} customer={props.customer}>
                        <div key={state.rendering} class='block-food' style={{ position: 'relative' }}>
                            <Tile>
                                <div class='btzTitle-btzImage btzTitle-btzImage-absolute col-md-12 col-4'>
                                    <img src={logoFood} srcset={`${logoFoodx2} 2x`} alt='' class='' />
                                    <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap' }}>
                                        <Tile.Icon>
                                            <Avatar.Alfred classes={'centered'} />
                                        </Tile.Icon>
                                        <Tile.Content>
                                            <Balloon kind={'info'} style={{ 'white-space': 'normal' }}>
                                                {t('Mes recommandations dépendent de chaque profil. Complétez les informations suivantes pour m’aider à analyser votre alimentation de manière pertinente.', {ns: 'food'})}
                                            </Balloon>
                                        </Tile.Content>
                                    </Tile>
                                </div>
                                <Tile.Content classes='btzLeftHrArrowed col-xs-12 col-6'>
                                    <p class='btzTitle btzBlackTitle btzXl' style={{ 'line-height': '1.2' }}>{t('Configurez votre profil alimentaire :', {ns: 'food'})}</p>

                                    <Form key={'rendering-' + state.rendering} onsubmit={event => event.preventDefault()} class={'btzFakeForm formRendering-' + state.rendering}>

                                        <div class='col-12 p-0 btzFakeForm-btzFakeFormEntry'>
                                            <p class='col-4 btzFakeForm-btzFakeFormEntry-btzLabel btzPageContentTexte'>
                                                {t('Poids', {ns: 'food'})}
                                            </p>
                                            <div class='col-8 p-0 btzFakeForm-btzFakeFormEntry-btzValues'>
                                                <p key={state.weight.value} class='col-10 btzFakeForm-btzFakeFormEntry-btzValue btzPageContentTexte btzXl'>
                                                    {state.weight.value === '' ?
                                                        <font style={{ 'white-space': 'nowrap' }}>{t('information manquante', {ns: 'generals'})}</font>
                                                        :
                                                        <span>{convertMetrics('weight', t('kg', {ns: 'food'}), state.userMetrics.weight, state.weight.value)}</span>
                                                    }
                                                </p>
                                                <div class='col-2 p-0'>
                                                    <Modal content={<ModalView displayed={'weight'} unit={t('kg', {ns: 'food'})} metric={state.userMetrics.weight} {...state} waitForLastInput={actions.waitForLastInput} updateCurrent={actions.updateCurrent} send={actions.setState} onSubmit={actions.onSubmit} onBlur={actions.onBlur} onFocus={actions.onFocus}></ModalView>}>
                                                        <div onclick={() => {
                                                            actions.setCurrent(state.weight);
                                                        }}>
                                                            <img src={icoEdit} srcset={`${icoEditx2} 2x`} alt='' class='' />
                                                        </div>
                                                    </Modal>
                                                </div>
                                            </div>
                                        </div>

                                        <div class='col-12 p-0 btzFakeForm-btzFakeFormEntry'>
                                            <p class='col-4 btzFakeForm-btzFakeFormEntry-btzLabel btzPageContentTexte'>
                                                {t('Taille', {ns: 'food'})}
                                            </p>
                                            <div class='col-8 p-0 btzFakeForm-btzFakeFormEntry-btzValues'>
                                                <p key={state.height.value} class='col-10 btzFakeForm-btzFakeFormEntry-btzValue btzPageContentTexte btzXl'>
                                                    {state.height.value === '' ?
                                                        <font style={{ 'white-space': 'nowrap' }}>{t('information manquante', {ns: 'generals'})}</font>
                                                        :
                                                        <span>{convertMetrics('height', t('cm', {ns: 'food'}), state.userMetrics.height, state.height.value)}</span>
                                                    }
                                                </p>
                                                <div class='col-2 p-0'>
                                                    <Modal content={<ModalView displayed={'height'} unit={t('cm', {ns: 'food'})} metric={state.userMetrics.height} {...state} waitForLastInput={actions.waitForLastInput} updateCurrent={actions.updateCurrent} send={actions.setState} onSubmit={actions.onSubmit} onBlur={actions.onBlur} onFocus={actions.onFocus}></ModalView>}>
                                                        <div onclick={() => {
                                                            actions.setCurrent(state.height);
                                                        }}>
                                                            <img src={icoEdit} srcset={`${icoEditx2} 2x`} alt='' class='' />
                                                        </div>
                                                    </Modal>
                                                </div>
                                            </div>
                                        </div>

                                        <div class='col-12 p-0 btzFakeForm-btzFakeFormEntry'>
                                            <p class='col-4 btzFakeForm-btzFakeFormEntry-btzLabel btzPageContentTexte'>
                                                {t('Activité', {ns: 'food'})}
                                            </p>
                                            <div class='col-8 p-0 btzFakeForm-btzFakeFormEntry-btzValues'>
                                                <p key={state.activity.value} class='col-10 btzFakeForm-btzFakeFormEntry-btzValue btzPageContentTexte btzXl'>
                                                    {((state.activity.value === '') || (state.activity.value === null)) ?
                                                        <font style={{ 'white-space': 'nowrap' }}>{t('information manquante', {ns: 'generals'})}</font>
                                                        :
                                                        <span>{t(state.activities[state.activity.value])}</span>
                                                    }
                                                </p>
                                                <div class='col-2 p-0'>
                                                    <Modal content={<ModalView displayed={'activity'} {...state} listing={state.activities.map((m) => t(m))} withsearch={false} placeholder={t('Tapez votre recherche ici ...', {ns: 'food'})} waitForLastInput={actions.waitForLastInput} updateCurrent={actions.updateCurrent} send={actions.setState} onSubmit={actions.onSubmit} onBlur={actions.onBlur} onFocus={actions.onFocus}></ModalView>}>
                                                        <div onclick={() => {
                                                            actions.setCurrent(state.activity);
                                                        }}>
                                                            <img src={icoEdit} srcset={`${icoEditx2} 2x`} alt='' class='' />
                                                        </div>
                                                    </Modal>
                                                </div>
                                            </div>
                                        </div>

                                        <div class='col-12 p-0 btzFakeForm-btzFakeFormEntry'>
                                            <p class='col-4 btzFakeForm-btzFakeFormEntry-btzLabel btzPageContentTexte'>
                                                {t('Âge', {ns: 'food'})}
                                            </p>
                                            <div key={state.birthdate.value.day + '/' + state.birthdate.value.month + '/' + state.birthdate.value.year + '-' + state.rendering} class='col-8 p-0 btzFakeForm-btzFakeFormEntry-btzValues'>
                                                <p class='col-10 btzFakeForm-btzFakeFormEntry-btzValue btzPageContentTexte btzXl'>
                                                    {state.birthdate.value.day === '' && state.birthdate.value.month === '' && state.birthdate.value.year === '' ?
                                                        <font style={{ 'white-space': 'nowrap' }}>{t('information manquante', {ns: 'generals'})}</font>
                                                        :
                                                        <span>{state.birthdate.value.day + '/' + state.birthdate.value.month + '/' + state.birthdate.value.year}</span>
                                                    }
                                                </p>
                                                <div class='col-2 p-0'>
                                                    <Modal content={<ModalView displayed={'birthdate'} {...state} waitForLastInput={actions.waitForLastInput} updateCurrent={actions.updateCurrent} send={actions.setState} onSubmit={actions.onSubmit} onBlur={actions.onBlur} onFocus={actions.onFocus}></ModalView>}>
                                                        <div onclick={() => {
                                                            actions.setCurrent(state.birthdate);
                                                        }}>
                                                            <img src={icoEdit} srcset={`${icoEditx2} 2x`} alt='' class='' />
                                                        </div>
                                                    </Modal>
                                                </div>
                                            </div>
                                        </div>

                                        <div class='col-12 p-0 btzFakeForm-btzFakeFormEntry'>
                                            <p class='col-4 btzFakeForm-btzFakeFormEntry-btzLabel btzPageContentTexte'>
                                                {t('Genre', {ns: 'food'})}
                                            </p>
                                            <div key={state.gender.value + '-' + state.rendering} class='col-8 p-0 btzFakeForm-btzFakeFormEntry-btzValues'>
                                                <p class='col-10 btzFakeForm-btzFakeFormEntry-btzValue btzPageContentTexte btzXl'>
                                                    {state.gender.value === '' ?
                                                        <font style={{ 'white-space': 'nowrap' }}>{t('information manquante', {ns: 'generals'})}</font>
                                                        :
                                                        <span>{(state.gender.value === true) ? t('Homme', {ns: 'generals'}) : t('Femme', {ns: 'generals'})}</span>
                                                    }
                                                </p>
                                                <div class='col-2 p-0'>
                                                    <Modal content={<ModalView displayed={'gender'} {...state} waitForLastInput={actions.waitForLastInput} updateCurrent={actions.updateCurrent} send={actions.setState} onSubmit={actions.onSubmit} onBlur={actions.onBlur} onFocus={actions.onFocus}></ModalView>}>
                                                        <div onclick={() => {
                                                            actions.setCurrent(state.gender);
                                                        }}>
                                                            <img src={icoEdit} srcset={`${icoEditx2} 2x`} alt='' class='' />
                                                        </div>
                                                    </Modal>
                                                </div>
                                            </div>
                                        </div>

                                        <Form onsubmit={event => event.preventDefault()} style={{ 'min-height': 0 }}>
                                            <Form.Group classes='btzForm-btzFooter'>
                                                <Button primary active={state.weight.isValid && state.height.isValid && state.gender.isValid && state.activity.isValid && (state.birthdate.isValid.year && state.birthdate.isValid.month && state.birthdate.isValid.day)} onclick={() => {
                                                    if (state.weight.isValid && state.height.isValid && state.gender.isValid && state.activity.isValid && (state.birthdate.isValid.year && state.birthdate.isValid.month && state.birthdate.isValid.day)) {
                                                        actions.setTransitScreen(true);
                                                    }
                                                }}>{t('Accéder à l’analyse de mes repas', {ns: 'food'})}
                                                </Button>
                                            </Form.Group>
                                        </Form>
                                    </Form>
                                </Tile.Content>
                            </Tile>
                        </div>
                    </PageLayout>
                }

                {((state.transition) && (state.computationEnable === false)) &&
                    <PageLayout userMedia={state.userMedia} page='food' userMedia={state.userMedia} customer={props.customer}>
                        <Transition gotonext={() => {actions.setComputationState(true)}}></Transition>
                    </PageLayout>
                }
            </div>
        );
        return view;
    }
}
