import { t, h } from '@app/utils';
import { Avatar, Balloon, Form, Tile, Button } from '@app/elements';

import logoStepSA from './ico-sa.png';
import logoStepSAx2 from './ico-sa@2x.png';

import logoStepDDR from './ico-ddr.png';
import logoStepDDRx2 from './ico-ddr@2x.png';

module.exports = {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <div style={{ position: 'relative' }}>
                {state.pregnancy.value === 'sa' &&
                    <dummy>
                        <Tile>
                            <Tile.Content style={{ 'border': 'none', 'padding': 0 }}>
                                <img src={logoStepSA} srcset={`${logoStepSAx2} 2x`} alt='' class='' />
                                <p class='btzTitle btzXl'>
                                    {t('Quel est votre nombre de semaines d’aménorrhées (SA) ?', {ns: 'register'})}
                                </p>
                            </Tile.Content>
                        </Tile>

                        <Form onsubmit={event => event.preventDefault()}>
                            <Form.Group style={{ 'white-space': 'nowrap', 'text-align': 'center' }}>
                                <Form.Group>
                                    <Form.Input
                                        placeholder={t('SS', {ns: 'register'})}
                                        type='text'
                                        id='pregnancysa-sa'
                                        name='pregnancysa-sa'
                                        value={state.pregnancysa.value.sa}
                                        oninput={actions.waitForLastInput}
                                        classes={(state.pregnancysa.isTouched.sa) ? (state.pregnancysa.isValid.sa ? '' : 'is-error') : ''}
                                        required
                                    />
                                    <p class=''>{t('SA', {ns: 'register'})}</p>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Input
                                        placeholder={t('JJ', {ns: 'register'})}
                                        type='text'
                                        id='pregnancysa-day'
                                        name='pregnancysa-day'
                                        value={state.pregnancysa.value.day}
                                        oninput={actions.waitForLastInput}
                                        classes={(state.pregnancysa.isTouched.day) ? (state.pregnancysa.isValid.day ? '' : 'is-error') : ''}
                                        required
                                    />
                                    <p class=''>{t('JOURS', {ns: 'register'})}</p>
                                </Form.Group>

                                {(state.api.message !== '') &&
                                    <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap' }}>
                                        <Tile.Icon>
                                            <Avatar.Alfred classes={'centered'} />
                                        </Tile.Icon>
                                        <Tile.Content>
                                            <Balloon kind={'error'} style={{ 'white-space': 'normal' }} actionAfterError={() => { actions.setState({ns: 'api', key: 'message', value: ''}) }}>
                                                <p style={{ 'text-align': 'left' }}>
                                                    <font>
                                                        {state.api.message}
                                                    </font>
                                                </p>
                                            </Balloon>
                                        </Tile.Content>
                                    </Tile>
                                }
                            </Form.Group>

                            <Form.Group classes='btzForm-btzFooter'>
                                <Button primary
                                    active={(state.pregnancysa.isValid.sa && state.pregnancysa.isValid.day)}
                                    onclick={(event) => {
                                        actions.onStepSubmit({ev: event, kind: 'sa'})
                                    }}>
                                    {t('Valider', {ns: 'generals'})}
                                </Button>
                            </Form.Group>
                        </Form>
                    </dummy>
                }
                {state.pregnancy.value === 'ddr' &&
                    <dummy class='pregnancyddrdate'>
                        <Tile>
                            <Tile.Content style={{ 'border': 'none', 'padding': 0 }}>
                                <img src={logoStepDDR} srcset={`${logoStepDDRx2} 2x`} alt='' class='' />
                                <p class='btzTitle btzXl'>
                                    {t('Quelle est la date du premier jour de vos dernières règles ?', {ns: 'register'})}
                                </p>
                            </Tile.Content>
                        </Tile>

                        <Form onsubmit={event => event.preventDefault()}>
                            <Form.Group style={{ 'white-space': 'nowrap', 'text-align': 'center' }}>
                                <Form.Group>
                                    <Form.Input
                                        classes={'btzFullWidth'}
                                        placeholderYear={t('AAAA', {ns: 'register'})}
                                        placeholderMonth={t('MM', {ns: 'register'})}
                                        placeholderDay={t('JJ', {ns: 'register'})}
                                        type={'date'}
                                        id={'pregnancyddrdate'}
                                        name={'pregnancyddrdate'}
                                        value={state.pregnancyddrdate.value}
                                        oninput={actions.waitForLastInput}
                                        error={(state.pregnancyddrdate.isTouched.year && state.pregnancyddrdate.isTouched.month && state.pregnancyddrdate.isTouched.day) && (!state.pregnancyddrdate.isValid.year && !state.pregnancyddrdate.isValid.month && !state.pregnancyddrdate.isValid.day)}
                                        classes={(state.pregnancyddrdate.isTouched.year && state.pregnancyddrdate.isTouched.month && state.pregnancyddrdate.isTouched.day) ? ((state.pregnancyddrdate.isValid.year && state.pregnancyddrdate.isValid.month && state.pregnancyddrdate.isValid.day) ? '' : 'is-error') : ''}
                                    />
                                </Form.Group>

                                {(state.api.message !== '') &&
                                    <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap' }}>
                                        <Tile.Icon>
                                            <Avatar.Alfred kind={'error'} classes={'centered'} />
                                        </Tile.Icon>
                                        <Tile.Content>
                                            <Balloon kind={'error'} style={{ 'white-space': 'normal' }} actionAfterError={() => { actions.setState({ns: 'api', key: 'message', value: ''}) }}>
                                                <p style={{ 'text-align': 'left' }}>
                                                    <font>
                                                        {state.api.message}
                                                    </font>
                                                </p>
                                            </Balloon>
                                        </Tile.Content>
                                    </Tile>
                                }
                            </Form.Group>

                            <Form.Group classes='btzForm-btzFooter'>
                                <Button primary
                                    active={(state.pregnancyddrdate.isValid.year && state.pregnancyddrdate.isValid.month && state.pregnancyddrdate.isValid.day)}
                                    onclick={(event) => {
                                        actions.onStepSubmit({ev: event, kind: 'ddr'})
                                    }}>
                                    {t('Valider', {ns: 'generals'})}
                                </Button>
                            </Form.Group>
                        </Form>
                    </dummy>
                }
            </div>
        );
        return view;
    }
}
