import { t, h, Component } from '@app/utils';
import { Convert } from '@app/elements';
import './index.scss';

const state = {
    program: null,
}

const actions = {
    onComponentCreate: () => (state, actions) => {

    }
};

const view = (state, actions) => (props, children) => (
    <div class='btzProgramsRecommanded-btzProgramRecommanded'>
        <p class='btzPageContentTexte' style={{ 'margin-bottom': '5px' }}>{props.title}</p>
        <p class='btzPageContentSubTexte btzTextColored' data-theme={props.valueTheme} style={{ 'text-align': 'center' }}>{t('exercice', {ns: 'programs'}) + ' ' + (+props.index + 1)}</p>
        {(((props.serieNumber !== '') && (props.serieNumber !== null)) || ((props.duration !== '') && (props.duration !== null)) || ((props.repeat !== '') && (props.repeat !== null))) &&
            <div class='btzPageContentSubTexte btzPageContentSubTexteExercice btzTopCustomDotted'>
                <p class='btzTextColored' data-theme={props.valueTheme}>{(((props.serieNumber === '') || (props.serieNumber === null)) ? '/' : props.serieNumber)}<span>{t('série(s)', {ns: 'programs'})}</span></p>
                <p class='btzTextColored' data-theme={props.valueTheme}>{(((props.duration === '') || (props.duration === null)) ? '/' : props.duration)}<span>{t('durée', {ns: 'programs'})}</span></p>
                <p class='btzTextColored' data-theme={props.valueTheme}>{(((props.repeat === '') || (props.repeat === null)) ? '/' : props.repeat)}<span>{t('répétition(s)', {ns: 'programs'})}</span></p>
            </div>
        }
        <p class='btzPageContentSubTitle btzTopCustomDotted' innerHTML={<Convert is_xhtml={true} type='nl2br'>{props.description}</Convert>}></p>
    </div>
);

export default Component(state, actions, view, 'duration');
