import { h, t } from '@app/utils';
import { Card } from '@app/elements';

import icoInfo from '@app/img/ico/ico-close-black-big.png';
import icoInfox2 from '@app/img/ico/ico-close-black-big@2x.png';

export const ViewMineralUse = (props, actions) => (
    <Card classes={'btzDetail-btzDetailFakeModal btzDetail-btzMainInformationModal'}>
        <Card.Header classes={'btzCard-btzHeader-btzModal'} style={{ 'margin-top': '100px' }}>
            <div class='btzModal-btzPaddingWrapper'>
                <img src={icoInfo} srcset={`${icoInfox2} 2x`} style={{ 'cursor': 'pointer' }} alt='' class='' onclick={props.closeView} style={{ 'margin': '0 0 30px' }} />

                <p class='btzTitle btzXl'>{t('À quoi servent les minéraux dans l’alimentation ?', {ns: 'food'})}</p>

                <div class='btzDetailFake'>
                    <p class='col-12 btzPageContentTitleBlue p-0' style={{ 'color': 'black' }}>
                        {t('Les minéraux sont en quantité très variable dans l’organisme. On distingue les sels minéraux (calcium, magnésium, phosphore...) des oligo-éléments présents à l’état de traces (cuivre, fer, zinc, sélénium…). Ils participent à la constitution des tissus, à l’équilibre hydro-électrique, à l’excitabilité neuromusculaire et à l’élaboration des hormones et enzymes.', {ns: 'food'})}
                    </p>
                </div>
            </div>
        </Card.Header>
    </Card>
);