import { t, h } from '@app/utils';
import { Button, Card } from '@app/elements';

import imgAsk from '@app/img/ico/ico-ask.png';
import imgAskx2 from '@app/img/ico/ico-ask@2x.png';

import icoClose from '@app/img/ico/ico-close-black.png';
import icoClosex2 from '@app/img/ico/ico-close-black@2x.png';

export const view = (state, actions) => (props, children) => (
    <Card kind={'challenge'}>
        <Card.Header>
            <Card.Image classes='btzCard-btzImage-btzAskImage'><img src={imgAsk} srcset={`${imgAskx2} 2x`} alt='' class='' /></Card.Image>

            <Button primary onclick={() => { actions.updateParentPropsWHYfalse(props) }} >
                <Card.Image><img src={icoClose} srcset={`${icoClosex2} 2x`} alt='' class='' /></Card.Image>
            </Button>
        </Card.Header>

        <Card.Body>
            <div>
                <p class='btzTimeline-btzCard-btzDescription btzTitle'>{t('Quels sont les bénéfices de ce défi ?', {ns: 'timeline'})}</p>
                <div class='btzHr btzDottedHr'></div>
                <p class='btzTimeline-btzCard-btzDescription btzTexte'>{props.why}</p>
            </div>
        </Card.Body>
    </Card>
);
