export const actions = {
    onComponentCreate: (props) => (state, actions) => {

    },
    trim: (str) => {
        if (str.length > 250) {
            return (str.substr(0, 250) + '...');
        }
        return str;
    },
    rendering: () => state => ({
        rendering: !state.rendering,
    }),
};
