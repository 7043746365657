const getMinHourFromMinutes = function (min) {
    let hours = Math.floor(min / 60);
    let minutes = Math.round(min % 60);

    if (minutes < 10) {
        minutes = ('0' + minutes);
    }

    return {min: minutes, h: hours};
};

export { getMinHourFromMinutes };