const sortStringNumber = function(arr, key) {
    // eslint-disable-next-line
    var regex = /[A-Za-z\ \-]+([0-9]+)/;

    function map(str) {
        if (key !== null) {
            if (regex.exec(str[key]) === null) {
                return false;
            }
            return Number(regex.exec(str[key])[1]);
        }
        return Number(regex.exec(str)[1]);
    }

    return arr.sort(
        function (a, b) {
            var av = map(a), bv = map(b);
            return av < bv ? -1 : av > bv ? 1 : 0;
        })
}

export { sortStringNumber };