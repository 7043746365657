import { t, h, Component } from '@app/utils';
import * as staticsconstants from '@app/constants/constants-statics';

import { Grid, Link, Navbar, Enter } from '@app/elements';
import { allRoutes } from '@app/settings';
import * as constants from '@app/constants';

import logoBackTo from '@app/img/ico/ico-prev-light.png';
import logoBackTox2 from '@app/img/ico/ico-prev-light@2x.png';

import { TopLogoView } from '@app/layouts/toplogo';

import './index.scss';

const actions = {
    goBack: () => state => {
        window.history.back();
    }
}

const view = (state, actions) => (props, children) => (
    <div key={'register'} class={props.page}>
        <Enter time={constants.custo.ANIM_DURATION} easing={constants.custo.ANIM_EASING} css={constants.custo.ANIM_CSS}>
            {((staticsconstants.CUSTOMERS[props.customer.toLowerCase()] !== undefined) && (staticsconstants.CUSTOMERS[props.customer.toLowerCase()].modules.blanklayout === false)) &&
                <Grid classes={'btzHeader btzHeader-btzNoMediaQuery'}>
                    <Grid.Row classes={'btzHeaderWrapper'}>
                        <Grid.Col x={10} mx={'auto'}>
                            <Navbar>
                                <Navbar.Section classes='btzHeader-btzHeaderLeftHand'>
                                    <TopLogoView {...props} redirectTo={allRoutes['home.index'].pathname} customer={(props.customer || 'betterise')}></TopLogoView>
                                </Navbar.Section>

                                <Navbar.Section classes='btzHeader-btzHeaderRightHand'>
                                    {((staticsconstants.CUSTOMERS[props.customer.toLowerCase()].modules.programs === true) && (props.page == 'login') && (props.customer !== 'feelgood')) && (
                                        <div class='btzHeader-btzCallToRegister'>
                                            <p class='hide-sm' style={{ 'vertical-align': 'middle' }}>{t('Vous n‘avez pas encore de compte ?', {ns: 'register'})}</p>
                                            <Link to={allRoutes['home.register'].pathname}>{t('Inscrivez-vous', {ns: 'register'})}</Link>
                                        </div>
                                    )}
                                    {((props.page == 'register') && (props.customer !== 'feelgood')) && (
                                        <div class='btzHeader-btzCallToLogin'>
                                            <p class='hide-sm' style={{ 'vertical-align': 'middle' }}>{t('Vous avez déjà un compte ?', {ns: 'register'})}</p>
                                            <Link to={allRoutes['home.login'].pathname}>{t('Connectez-vous', {ns: 'register'})}</Link>
                                        </div>
                                    )}
                                </Navbar.Section>
                            </Navbar>
                        </Grid.Col>
                    </Grid.Row>
                </Grid>
            }
            <Grid classes={'btzWrapper btzWrapperFullHeight btzVerticalAlign specificFullHeightMobile'} style={{ 'padding-bottom': '130px', 'padding-top': '105px' }}>
                <Grid.Row classes={'btzWrapper-verticalAlignContent specificFullHeightMobile ' + (props.howthatwork ? ' btzWrapper-verticalAlignContent-minHeight' : '')}>
                    {((props.subpage !== '/home/register/secure') && (props.subpage !== '/home/register/end') && (props.subpage !== '/home/register/pseudo') && (props.subpage !== '/home/register/pregnancy')) &&
                        <div class='btzLink btzLink-btzAbsoluteLink' onclick={actions.goBack}>
                            <img src={logoBackTo} srcset={`${logoBackTox2} 2x`} alt='' />
                        </div>
                    }
                    <Grid.Col x={12} mx={'auto'} style={{ 'position': 'relative' }} classes={'specificFullHeightMobile'}>
                        {children}
                    </Grid.Col>
                </Grid.Row>
            </Grid>
        </Enter>
    </div>
);

export default Component({}, actions, view, 'layout');