import { appCustoSetting } from '@app/settings/settings';
import { queueQueries } from '@app/api/controlleur';
const { API_URL } = appCustoSetting.appsettings;

/**
 * Calls API to retrieve selected relevant cards
 * @see https://betterise-api-doc.betterise.me/#api-Cardline-getCardline
 * @param {String} date The Timeline date to retrieve in query parameter (yyyymmdd, optional)
 * @return {Promise} Object containing data or error
 */
async function getCardline(date = '') {
    var url = `${API_URL}/cardline/${date}/`;
    if (date === null) {
        url = `${API_URL}/cardline/`;
    }
    return queueQueries(url, 'GET')
}

/**
 * Calls API to retrieve selected relevant cards by type
 * @see https://betterise-api-doc.betterise.me/#api-Cardline-getCards
 * @param {Integer} type The cards type to retrieve; Can be an Array of types
 * @return {Object} Object containing data or error
 */
function getCards(type = '', withHeader = true) {
    if (Array.isArray(type)) {
        type = type.join('-');
    }
    return queueQueries(`${API_URL}/cards/${type}/`, 'GET', 'application/json', true, withHeader)
}

function getMedicalCards(timelineId, medicalStudytoken, withHeader = true) {
    return queueQueries(`${API_URL}/medical-study/${timelineId}/${medicalStudytoken}/`, 'GET', 'application/json', true, withHeader)
}
function getMedicalInfo(medicalStudytoken, withHeader = true) {
    return queueQueries(`${API_URL}/medical-study/${medicalStudytoken}/`, 'GET', 'application/json', true, withHeader, true, false)
}

function getArticle(id) {
    return queueQueries(`${API_URL}/article/${id}/`, 'GET')
}

function getRecipe(id) {
    return queueQueries(`${API_URL}/recipe/${id}/`, 'GET')
}

/**
 * Calls API to retrieve the date of last timeline
 * @see https://betterise-api-doc.betterise.me/#api-Cardline-getLastTimeline
 * @param {String} date The date (yyyymmdd, optional)
 * @return {Object} Object containing data or error
 */
function getLastTimeline(date = '') {
    return queueQueries(`${API_URL}/cardline/last/${date}/`, 'GET')
}

function favoriteThisCard(idtimeline, idcard) {
    return queueQueries(`${API_URL}/favorite/${idtimeline}/${idcard}/`, 'POST')
}

function unfavoriteThisCard(idcard) {
    return queueQueries(`${API_URL}/favorite/${idcard}/`, 'DELETE')
}

function getUserChallenges() {
    return queueQueries(`${API_URL}/challenge/user/`, 'GET')
}

function getSharedCard(idtimelineIdcard, lang) {
    return queueQueries(`${API_URL}/shared/?id=${idtimelineIdcard}&lang=${lang}`, 'GET', 'application/json', true, true, true, false)
}

function acceptChallenge(id, datas) {
    return queueQueries(`${API_URL}/challenge/${id}/`, 'POST', 'application/json', true, true, true, true, true, datas)
}

function endChallenge(id, datas) {
    return queueQueries(`${API_URL}/challenge/${id}/`, 'PUT', 'application/json', true, true, true, true, true, datas)
}

function markCardAsRead(id) {
    return queueQueries(`${API_URL}/card/read/${id}/`, 'POST')
}

/**
 * Calls API to send evaluation card answer.
 * @see https://betterise-api-doc.betterise.me/#api-Cardline-postCardline
 * @param {String} id The card id of timeline
 * @param {String} tag The tag of data to be posted
 * @param {String} value The value of data to be posted
 * @param {Array} array Array of id / tag / value (used for QCMs)
 * @return {Object} Object containing data or error
 */
function postCardline(idtimeline, datas, withHeader = true) {
    return queueQueries(`${API_URL}/cardline/${idtimeline}/`, 'POST', 'application/json', true, withHeader, true, true, true, datas)
}

function postMedicalCardline(idtimeline, datas, withHeader = true) {
    return queueQueries(`${API_URL}/medical-study/${idtimeline}/`, 'POST', 'application/json', true, withHeader, true, true, true, datas)
}

function postReadedCardline(id, withHeader = true) {
    return queueQueries(`${API_URL}/card/read/${id}/`, 'POST', 'application/json', true, withHeader)
}

export const Cardline = {
    getCardline,
    getCards,
    getMedicalCards,
    getMedicalInfo,
    getLastTimeline,
    postCardline,
    postMedicalCardline,
    postReadedCardline,
    favoriteThisCard,
    unfavoriteThisCard,
    acceptChallenge,
    getUserChallenges,
    endChallenge,
    markCardAsRead,
    getArticle,
    getRecipe,
    getSharedCard,
};
