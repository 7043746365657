import { h } from '@app/utils';
import { Card, Social, Media, Convert } from '@app/elements';

import '@app/modules/Food/index-addmeal.scss';

export const ModalViewAdvice = (props, actions) => (
    <div class='advice-opened' style={{ 'height': '100%' }}>
        <Card kind={'welcome'} theme={props.valueTheme}>
            <Card.Header classes={'btzCard-btzHeader-btzModal'}>
                <Social kindcard={'welcome'} title={props.title} idfavorite={props.favoriteId} idtimeline={props.timeline} id={props.id} favorite={props.favorite} propagate={props.propagate} displayedon={props.displayedon} cancelAction={props.propagateConfirmClosing}></Social>
                {props.media !== undefined &&
                    <Card.Image kind={props.media}><Media src={props.media}></Media></Card.Image>
                }
                <p class='btzTimeline-btzCard-btzTitle btzTitle'>{props.title}</p>
                <p class='btzTimeline-btzCard-btzSubTitle btzSubTitle btzTextColored' data-theme={props.valueTheme}>{props.labelTheme}</p>
            </Card.Header>

            <Card.Body classes={'btzCard-btzBody-btzModal btzTopCustomDotted'} style={{ 'margin-bottom': '100px' }}>
                <p class='btzTimeline-btzCard-btzDescription btzTexte' innerHTML={<Convert separator={''} is_xhtml={true} type='nl2br'>{props.description}</Convert>}></p>

                <div class='btzTimeline-btzCard-btzTimeline-btzRecipe-btzTimeline-btzTitles'>
                    {((props.tags) && (props.tags.length > 0)) &&
                        <div class='btzTags-btzListing'>
                            {props.tags.map((item) =>
                                <div class='btzTag-btzEntry' data-theme={props.valueTheme} onclick={() => {props.showTagDetail(item)}}><Convert type='capitalize'>{item}</Convert></div>
                            )}
                        </div>
                    }
                </div>
            </Card.Body>
        </Card>
    </div>
);