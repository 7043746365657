import { User } from '@app/api';
import { h, Component } from '@app/utils';
import { getUser, dynLoad } from '@app/core';
import './index.scss';
import './../../Timeline/index.scss';
import * as constants from '@app/constants';

const state = {
    favorites: {
        all: [],
        advice: [],
        article: [],
        recipe: [],
        citation: [],
    },
    filter: 'all',
    initialized: false,
    userMedia: null,
}

const actions = {
    onComponentCreate: () => (state, actions) => {
        let userCallback = (sessionUser) => {
            state.userMedia = sessionUser.media.url;
        };
        getUser(userCallback);

        User.getUserFavorite(0).then((res) => {
            var card = null;
            var recipes = [], articles = [], citations = [], advices = [], alls = [];
            for (var fav in res.data) {
                fav = res.data[fav], card = {};

                var cardTheme = actions.getTheme(fav.card.theme);

                card.valueTheme = cardTheme.value;
                card.labelTheme = cardTheme.label;

                card.id = fav.card.id;
                card.cardId = fav.card.cardId;
                card.favoriteId = fav.id
                card.timeline = fav.timelineId;
                card.cardKind = 'advice';
                card.date = fav.date;

                card.favorite = true;
                card.type = fav.card.type;
                card.title = fav.card.title;
                card.description = fav.card.description;
                card.media = {
                    url: (fav.card.media !== null ? fav.card.media.url : ''),
                };

                if (+fav.card.type === 18) {
                    card.id = fav.card.article.id;
                    card.title = fav.card.article.title;
                    card.description = fav.card.article.description;
                    card.media = {
                        url: (fav.card.article.media !== null ? fav.card.article.media.url : ''),
                    };
                    card.valueTheme = 'reading';
                    card.labelTheme = 'Article';
                    card.cardKind = 'article';
                    card.article = card;
                    articles.push(card);
                } else if (+fav.card.type === 19) {
                    card.id = fav.card.recipe.id;
                    card.title = fav.card.recipe.title;
                    card.description = fav.card.recipe.description;
                    card.media = {
                        url: (fav.card.recipe.media !== null ? fav.card.recipe.media.url : ''),
                    };
                    card.valueTheme = 'cooking';
                    card.labelTheme = 'Recette du jour';
                    card.cardKind = 'recipe';
                    card.recipe = card;
                    recipes.push(card);
                } else if (+fav.card.type === 20) {
                    card.title = fav.card.description;
                    card.description = fav.card.title;
                    card.valueTheme = 'reading';
                    card.labelTheme = 'Citation';
                    card.cardKind = 'citation';
                    citations.push(card);
                } else {
                    advices.push(card);
                }

                alls.push(card);
            }
            actions.setState({ns: 'favorites', key: 'all', value: alls});
            actions.setState({ns: 'favorites', key: 'citation', value: citations});
            actions.setState({ns: 'favorites', key: 'recipe', value: recipes});
            actions.setState({ns: 'favorites', key: 'article', value: articles});
            actions.setState({ns: 'favorites', key: 'advice', value: advices});
            actions.setInitialized(true);
        });
    },
    getTheme: (intTheme) => {
        for (var _t in constants.custo.THEMES) {
            if (constants.custo.THEMES[_t].key === intTheme) {
                return constants.custo.THEMES[_t];
            }
        }
        return {value : '', label : ''};
    },
    setState: ({ ns, key, value }) => state => ({
        [ns]: { ...state[ns], ...{ [key]: value } },
    }),
    setFilter: newState => state => ({
        filter: newState
    }),
    removeThisOne: (idfavorite) => (state, actions) => {
        let favToDelete = document.getElementById('fav-' + idfavorite);
        favToDelete.parentNode.removeChild(favToDelete);
    },
    setInitialized: newState => state => ({
        initialized: newState,
    }),
};

const view = dynLoad('view', 'Account/favorites/views');

export default Component(state, actions, view, 'favorites');
