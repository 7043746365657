function isFloat(n){
    return n === +n && n !== (n|0);
}

function isInteger(n){
    return n === +n && n === (n|0);
}

const castToNumberOfFloat = function(str) {
    if ((str === null) || (str === undefined) || (str === '') || isNaN(str)) {
        return null;
    } else if (isInteger(str) || isFloat(str)) {
        return str;
    } else if ((str.indexOf('.') > -1) || (str.indexOf(',') > -1)) {
        return parseFloat(str);
    } else {
        return parseInt(str);
    }
}

export { isFloat, isInteger, castToNumberOfFloat };