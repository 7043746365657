export const state = {
    card: null,
    sharedFrom: '',
    goToLabel: '',
    goToUrl: '#',
    redirect: {
        ios: '',
        android: '',
    }
};
