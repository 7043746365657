import { handleErrors } from '@app/api/errors';
import { appCustoSetting } from '@app/settings/settings';
import { queueQueries } from '@app/api/controlleur';
const { API_URL } = appCustoSetting.appsettings;

/**
 * Calls API to retrieve mutuals by name.
 * N.B.: in release (& prod), response are in text/plain 
 * @see https://betterise-api-doc.betterise.me/#api-Mutual-getMutuals
 * @param {String} name The one true Mutual to Rule Them All
 * @return {Object} Object containing data or error
 */
async function getMutuals(name = '') {
    // return queueQueries(`${API_URL}/mutual/${name}/`, 'GET', 'application/json', true, true, true, false)
    return await fetch(`${API_URL}/mutual/${name}/`, {
        method: 'GET'
    })
        .then((response) => response.text()) // HACK
        .then(data => JSON.parse(data)) // HACK
        .then(handleErrors)
        .then(data => ({ data }))
        .catch(error => ({ error }))
}

/**
 * Calls API to verify a temporary mutual account
 * @see https://betterise-api-doc.betterise.me/#api-Mutual-mutualVerification
 * @param {String} membership The user mutual membership to verify
 * @return {Object} Object containing data or error
 */
async function mutualVerification(membership) {
    return queueQueries(`${API_URL}/mutual-verification/`, 'POST', 'application/json', true, true, true, true, true, {membership: membership})
}

export const Mutual = {
    getMutuals,
    mutualVerification
};
