import { appCustoSetting } from '@app/settings/settings';
import { queueQueries } from '@app/api/controlleur';
const { API_URL } = appCustoSetting.appsettings;

/**
 * Calls API to create B2C user account
 * @see https://betterise-api-doc.betterise.me/#api-User-createUser
 * @param {any} accountEmail Account email
 * @param {any} accountPassword Account password
 * @return Response object
 */
function shareThisCard(kind, id, type) {
    return queueQueries(`${API_URL}/share/${kind}/${id}/${type}/`, 'GET')
}

export const Share = {
    shareThisCard,
};