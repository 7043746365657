import { t } from '@app/utils';
import { getUser, getTodayYMD, redirectToOnboarding, getQueryVariable } from '@app/core';
import { Objectives, User } from '@app/api';

export const actions = {
    onComponentCreate: (props) => (state, actions) => {
        if (props.language) {
            actions.setLanguage(props.language);
        }
        let customerssoname = getQueryVariable('customerssoname', false);
        if (!customerssoname || (customerssoname === null) || (customerssoname === undefined)) {
            customerssoname = props.customer;
            if (!customerssoname || (customerssoname === null) || (customerssoname === undefined)) {
                customerssoname = 'betterise';
            }
        }

        actions.setCustomerSsoName(customerssoname);
        actions.getUserObjectives();
    },
    getUserObjectives: () => (state, actions) => {
        User.getUserObjectives().then(function(res) {
            var userObjectives = [];
            for (var obj in res.data) {
                obj = res.data[obj];
                obj.idobjective = obj.objective.id;
                obj.title = obj.objective.title;
                obj.media = obj.objective.media;
                obj.mode = 'remove';
                userObjectives.push(obj);
            }
            actions.setState({ns: 'objectives', key: 'user', value: userObjectives});

            actions.getListObjectives();
        });
    },
    getListObjectives: () => (state, actions) => {
        let userCallback = (sessionUser) => {
            let companyId = null;
            if (sessionUser.customer !== undefined) {
                companyId = sessionUser.customer;
            }
            let chars = {' ':'','/':'-','é':'e','-':''};
            if (companyId === null) {
                companyId = 'betteriseentreprise';
            }
            companyId = companyId.replace(/[ /é-]/g, m => chars[m]).toLowerCase();
            actions.getObjectivesListing(companyId);
            actions.setUserId(sessionUser.id);
        };
        getUser(userCallback);
    },
    getObjectivesListing: (companyId) => (state, actions) => {
        Objectives.getObjectives(state.language, companyId).then(function(res) {
            var objectives = [], initials = [];

            for (var obj in res.data) {
                obj = res.data[obj];
                obj.mode = 'add';
                obj.status = 0;
                obj.idobjective = obj.id;

                for (var userObj in state.objectives.user) {
                    userObj = state.objectives.user[userObj];
                    if (userObj.idobjective === obj.id) {
                        obj.mode = 'remove';
                        obj.status = 1;
                        obj.date = userObj.date;
                        obj.objective = userObj.objective;
                    }
                }
                objectives.push(obj);
                initials.push(JSON.parse(JSON.stringify(obj)))
            }
            actions.setState({ns: 'objectives', key: 'all', value: objectives});
            // actions.setInitialState(initials);
        });
    },
    chooseThisObjectif: (props) => (state, actions) => {
        actions.setError('');
        var resampledObjAll = [];
        for (var objAll in state.objectives.all) {
            objAll = state.objectives.all[objAll];
            if (objAll.id === props.id) {
                objAll.mode = (objAll.mode === 'add' ? 'remove' : 'add');
                objAll.status = (objAll.status === 0 ? 1 : 0);
            }
            resampledObjAll.push(objAll);
        }
        actions.setState({ns: 'objectives', key: 'all', value: resampledObjAll});

        actions.parseToProcess(props);
    },
    confirmTheseObjectifRules: ({ev, props}) => (state, actions) => {
        var inUserList = 0;
        for (var obj in state.objectives.all) {
            obj = state.objectives.all[obj];
            if (obj.mode === 'remove') {
                inUserList++;
            }
        }

        if (inUserList < state.minObjectives) {
            actions.setError(t('Vous devez suivre au moins un objectif santé.', {ns: 'public'}));
            ev.stopPropagation();
            ev.cancelBubble = true;
            return;
        } else if (inUserList > state.maxObjectives) {
            actions.setError(t('Vous pouvez suivre 2 objectifs santé au maximum.', {ns: 'public'}));
            ev.stopPropagation();
            ev.cancelBubble = true;
            return;
        }
        actions.setError('');
        actions.confirmTheseObjectif(props);
    },
    confirmTheseObjectif: (props) => (state, actions) => {
        let toSend = {};
        state.objectives.all.map((m, i) => toSend[i] = {'id': m.idobjective, 'state': m.status});

        Objectives.updateObjective(toSend).then(() => {
            redirectToOnboarding(state.userid, getTodayYMD());
            // window.location.href = addCSRFToken(allRoutes['private.onboarding'].pathname);
        });
    },
    parseToProcess: (obj) => (state, actions) => {
        var found = false, foundIdx = null, newToProcess = state.toprocess;
        for (var toProcessObj in newToProcess) {
            if (newToProcess[toProcessObj].id === obj.id) {
                found = true;
                foundIdx = toProcessObj;
            }
        }

        if (!found) {
            // obj not found in obj to process, we can add it to array
            newToProcess.push(obj);
        } else {
            // obj was found, we must update his edition mode
            newToProcess[foundIdx].mode = (newToProcess[foundIdx].mode === 'add' ? 'remove' : 'add');
        }
        actions.setToProcess(newToProcess);
    },
    setCustomerSsoName: newState => state => ({
        customerSsoName: newState,
    }),
    setToProcess: newState => state => ({
        toprocess: newState,
    }),
    setUserId: newState => state => ({
        userid: newState,
    }),
    setState: ({ ns, key, value }) => state => ({
        [ns]: { ...state[ns], ...{ [key]: value } },
    }),
    setModalDisplayed: newState => state => ({
        modalDisplayed: newState,
    }),
    setError: newState => state => ({
        error: newState,
    }),
    setLanguage: newState => state => ({
        language: newState,
    }),
};