import { t, h } from '@app/utils';

export const actions = {
    setErrorMessage: (string) => ({ apiErrors: string }),
    setErrorStatus: (string) => ({ apiErrorsStatus: string }),

    updateParentProps: (props) => (state, actions) => {
        if (props) {
            props.send({
                ns: 'user',
                key: 'payment',
                value: state.payment
            })
        }
    },

    goToNextStep: () => (state, actions) => {
        window.main.location.go('/home/register/end');
    },

    setStripeElementCardNumber: (cardNumber) => (state, actions) => {
        state.stripeElementCardNumber = cardNumber;
    },

    setStripeElementCardExpiry: (cardExpiry) => (state, actions) => {
        state.stripeElementCardExpiry = cardExpiry;
    },

    setStripeElementCardCvc: (cardCvc) => (state, actions) => {
        state.stripeElementCardCvc = cardCvc;
    },

    setAllFieldsAreValid: newState => state => ({
        allFieldsAreValid: newState,
    }),

    setPaymentData: newState => state => ({
        payment: newState,
    }),

    setOnlyOneSubmit: newState => state => ({
        onlyOneSubmit: newState,
    }),

    resetApiError: () => (state, actions) => {
        actions.setErrorMessage(t('Renseignez un moyen de paiement.\nBetterise est satisfait ou remboursé pendant un mois.', {ns: 'cb'}));
        actions.setErrorStatus('info');
    },

    onStepSubmit: ({event, props}) => (state, actions) => {
        event.preventDefault();

        if (state.stripeElementCardNumber._empty) {
            actions.setErrorMessage(t('Merci de saisir votre numéro de carte bancaire.', {ns: 'cb'}));
            actions.setErrorStatus('error');
            return false
        } else if (!state.stripeElementCardNumber._complete || state.stripeElementCardNumber._invalid) {
            actions.setErrorMessage('Veuillez renseigner un numéro de carte bancaire valide.')
            actions.setErrorStatus('error');
            return false
        }

        if (state.stripeElementCardExpiry._empty) {
            actions.setErrorMessage(t('Merci de saisir une date d‘expiration', {ns: 'cb'}));
            actions.setErrorStatus('error');
            return false
        } else if (!state.stripeElementCardExpiry._complete || state.stripeElementCardExpiry._invalid) {
            actions.setErrorMessage('Veuillez renseigner une date d‘expiration valide.')
            actions.setErrorStatus('error');
            return false
        }

        if (state.stripeElementCardCvc._empty) {
            actions.setErrorMessage(t('Merci de saisir une clé de vérification', {ns: 'cb'}));
            actions.setErrorStatus('error');
            return false
        } else if (!state.stripeElementCardCvc._complete || state.stripeElementCardCvc._invalid) {
            actions.setErrorMessage('Veuillez renseigner une clé de vérification valide')
            actions.setErrorStatus('error');
            return false
        }

        actions.resetApiError();

        if (state.onlyOneSubmit) {
            return false;
        }
        actions.setOnlyOneSubmit(true);

        state.stripe.createSource(state.stripeElementCardNumber).then(function(result) {
            if (result.error) {
                actions.setErrorStatus('error')
                switch (event.elementType) {
                    case 'cardNumber':
                        actions.setErrorMessage(t('Veuillez renseigner un numéro de carte bancaire valide.', {ns: 'cb'}));
                        actions.setErrorStatus('error');
                        break;
                    case 'cardExpiry':
                        actions.setErrorMessage(t('Veuillez renseigner une date d\'expiration valide.', {ns: 'cb'}));
                        actions.setErrorStatus('error');
                        break;
                    case 'cardCvc':
                        actions.setErrorMessage(t('Veuillez renseigner une clé de vérification valide.', {ns: 'cb'}));
                        actions.setErrorStatus('error');
                        break;
                }
                return false
            } else {
                stripeSourceHandler(result.source);
            }
        });

        var ensureOneSubmit = false;
        function stripeSourceHandler(source) {
            if (ensureOneSubmit) {
                return false;
            }
            ensureOneSubmit = true;
            var data = {
                redirect_url: window.location.origin + '/home/register/payment/validation',
                stripeSource: source.id,
                three_d_secure: (source.card.three_d_secure || 'none')
            };

            actions.setPaymentData(data);
            actions.updateParentProps(props);
            actions.goToNextStep();
        }
    },
};