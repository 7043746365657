import { h, t } from '@app/utils';
import { Card } from '@app/elements';

import icoInfo from '@app/img/ico/ico-close-black-big.png';
import icoInfox2 from '@app/img/ico/ico-close-black-big@2x.png';

export const ViewMainInformations = (props, actions) => (
    <Card classes={'btzDetail-btzDetailFakeModal btzDetail-btzMainInformationModal'}>
        <Card.Header classes={'btzCard-btzHeader-btzModal'} style={{ 'margin-top': '100px' }}>
            <div class='btzModal-btzPaddingWrapper'>
                <img src={icoInfo} srcset={`${icoInfox2} 2x`} alt='' class='' onclick={props.closeView} style={{ 'margin': '0 0 30px', 'cursor': 'pointer' }} />

                <p class='btzTitle btzXl'>{t('Quelles sont informations nutritionnelles principales ?', {ns: 'food'})}</p>

                <div class='btzDetailFake'>
                    <p class='col-12 btzPageContentTitle p-0'>{t('Énergie', {ns: 'food'}) + ' :'}</p>
                    <p class='col-12 btzPageContentTitleBlue p-0' style={{ 'color': 'black' }}>
                        {t('L’énergie d’un aliment est calculée en kilocalories (kcal) ou en kilo Joules (kJ). Sa valeur dépend de sa composition en protéines, lipides et glucides. Un gramme de protéines apporte 4 kcal (17kJ) d’énergie, un gramme de glucides 4 kcal aussi et un gramme de lipide 9 kcal (38 kJ). L’alcool, quant à lui, apporte 7 kcal par gramme.', {ns: 'food'})}
                    </p>
                </div>

                <div class='btzDetailFake'>
                    <p class='col-12 btzPageContentTitle p-0'>{t('Protéines', {ns: 'food'}) + ' :'}</p>
                    <p class='col-12 btzPageContentTitleBlue p-0' style={{ 'color': 'black' }}>
                        {t('On retrouve les protéines sous différentes formes à tous les niveaux du corps, dans les organes, la peau, les cheveux, les os, les muscles, le sang… Les protéines ingérées par l’alimentation participent au renouvellement des cellules. Elles remplacent les protéines usées et réparent les tissus endommagés. Elles permettent aussi la contraction des muscles et la transmission des messages nerveux. Les protéines peuvent être d’origine animale (viande, poisson, œufs, produits laitiers) ou d’origine végétale (légumineuses, céréales).', {ns: 'food'})}
                    </p>
                </div>

                <div class='btzDetailFake'>
                    <p class='col-12 btzPageContentTitle p-0'>{t('Lipides', {ns: 'food'}) + ' :'}</p>
                    <p class='col-12 btzPageContentTitleBlue p-0' style={{ 'color': 'black' }}>
                        {t('Les lipides correspondent aux matières grasses. Ils constituent la structure des membranes de toutes les cellules du corps et sont utilisés dans la biosynthèse des hormones stéroïdiennes. Ils servent également de transport des vitamines liposolubles (A, D, E, K). Ils peuvent être d’origine animale (beurre, crème, viande…) ou végétale (huile, fruits oléagineux, margarine…)', {ns: 'food'})}
                    </p>
                </div>

                <div class='btzDetailFake'>
                    <p class='col-12 btzPageContentTitle p-0'>{t('Glucides', {ns: 'food'}) + ' :'}</p>
                    <p class='col-12 btzPageContentTitleBlue p-0' style={{ 'color': 'black' }}>
                        {t('Les glucides ont un rôle énergétique, ce sont les principaux nutriments permettant la contraction musculaire et le travail intellectuel. Apportés en quantité suffisante, ils évitent l’utilisation des protéines à des fins énergétiques qui entraînerait une fonte musculaire et une perte de tonus. Les glucides regroupent tous les sucres au sens large. Ils sont classés en deux familles. Les glucides simples, que l’on retrouve dans le sucre blanc, les fruits, le lait, les boissons sucrées, et les glucides complexes dans les céréales, les légumineuses, le pain…', {ns: 'food'})}
                    </p>
                </div>

                <div class='btzDetailFake'>
                    <p class='col-12 btzPageContentTitle p-0'>{t('Fibres', {ns: 'food'}) + ' :'}</p>
                    <p class='col-12 btzPageContentTitleBlue p-0' style={{ 'color': 'black' }}>
                        {t('Les fibres sont des glucides non digestibles. Elles maintiennent une flore bactérienne optimale en stimulant la fermentation colique et participent à la régulation du transit en augmentant le volume du bol alimentaire dans le côlon. Elles ont aussi un effet sur les glucides en diminuant l’Index Glycémique des aliments et entraînant une réponse glycémique et insulinique plus faible après un repas. On les retrouve dans les légumes, les fruits, les légumineuses, les céréales complètes…', {ns: 'food'})}
                    </p>
                </div>

                <div class='btzDetailFake'>
                    <p class='col-12 btzPageContentTitle p-0'>{t('Sel', {ns: 'food'}) + ' :'}</p>
                    <p class='col-12 btzPageContentTitleBlue p-0' style={{ 'color': 'black' }}>
                        {t('Le sel est un élément indispensable à l’organisme, il participe au maintien de l’équilibre hydro-électrique (équilibre de l’eau entre les cellules et les tissus) et de l’équilibre acido-basique (maintien des fonctions cellulaires). Il est également indispensable dans la bonne transmission de l’influx nerveux et musculaire.', {ns: 'food'})}
                    </p>
                </div>
            </div>
        </Card.Header>
    </Card>
);