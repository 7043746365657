import { t, h } from '@app/utils';
import { Tile } from '@app/elements';

import dlIos from './download_apple.png';
import dlIosx2 from './download_apple@2x.png';

import dlAndroid from './download_android.png';
import dlAndroidx2 from './download_android@2x.png';

import logoBigBetterise from '@app/img/logo/logo-btz-big.png';
import logoBigBetterisex2 from '@app/img/logo/logo-btz-big@2x.png';

import logoBigPartena from '@app/img/logo/logo-partena-big.png';
import logoBigPartenax2 from '@app/img/logo/logo-partena-big@2x.png';

import logoGJENSIDIGE from '@app/img/logo/logo-gjensidige-small.png';
import logoGJENSIDIGEx2 from '@app/img/logo/logo-gjensidige-small@2x.png';

import EmptyLayout from '@app/layouts/empty/layout';

export const view = (state, actions) => (props, children) => (
    <div key='publicwebtoapp'>
        <EmptyLayout page='webtoapp' customer={state.customerSsoName}>
            <div class='publicwebtoapp-webtoapp'>
                <div class='btzHeader-btzShadowHeader' style={{ position: 'relative' }}>
                    <Tile>
                        <div>
                            <div class='btzImage-btzAbsoluteImage'>
                                {{
                                    ['betterise']: <img src={logoBigBetterise} srcset={`${logoBigBetterisex2} 2x`} alt='' class='' />,
                                    ['partena']: <img src={logoBigPartena} srcset={`${logoBigPartenax2} 2x`} alt='' class='' />,
                                    ['gjensidige']: <img src={logoGJENSIDIGE} srcset={`${logoGJENSIDIGEx2} 2x`} alt='' />
                                }[state.customerSsoName]}
                            </div>
                            <Tile.Content classes='col-12'>
                                <p class='btzTitle btzXl'>{t('Vous souhaitez accéder à votre application ?', {ns: 'public'})}</p>
                                <p class='btzTexte' style={{ 'margin': '0px' }}>{t('Quelle excellente idée !', {ns: 'public'})}</p>
                                <p class='btzTexte'>{t('Pour cela, ouvrez votre application disponible sur votre téléphone :)', {ns: 'public'})}</p>
                                <p class='btzTexte'>{t('Si vous l’avez supprimée de votre téléphone, téléchargez-la à nouveau en cliquant ci-dessous :', {ns: 'public'})}</p>
                                <div class='btzButtonMarker' onclick={() => {
                                    window.location.href = state.redirect.ios;
                                }}>
                                    <img src={dlIos} srcset={`${dlIosx2} 2x`} alt='' class='' />
                                </div>
                                <div class='btzButtonMarker' onclick={() => {
                                    window.location.href = state.redirect.android;
                                }}>
                                    <img src={dlAndroid} srcset={`${dlAndroidx2} 2x`} alt='' class='' />
                                </div>
                            </Tile.Content>
                        </div>
                    </Tile>
                </div>
                <div class='btzFooter-btzShadowFooter'>
                    <p class=''>{t('Une question ?', {ns: 'public'})}</p>
                    <p class=''><a href={'mailto:' + state.mailto}>{state.mailto}</a></p>
                </div>
            </div>
        </EmptyLayout>
    </div>

);