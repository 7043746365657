import { h } from '@app/utils';
import { Card } from '@app/elements';

export const view = (state, actions) => (props, children) => (
    <Card kind={'prenom'} theme={props.valueTheme}>
        {props.displayedon == 'share' &&
            <div>
                <Card.Body classes='btzVerticalAlign btzCard-btzBody-btzModal btzDottedSpaced'>
                    <div class='btzWrapper-verticalAlignContent'>
                        <p class='btzTimeline-btzCard-btzDescription btzTexte'>{props.description}</p>
                        <Card.Header classes={'btzCard-btzHeader-btzModal'}>
                            <p class='btzTimeline-btzCard-btzTitle btzTitle' style={{ 'margin-top': '0px', 'padding-top': '0px', 'font-size': '22px' }}>{props.title}</p>
                        </Card.Header>
                    </div>
                </Card.Body>
            </div>
        }
    </Card>
);
