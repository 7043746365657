export const state = {
    pregnancy: {
        value: '',
    },
    pregnancysa: {
        isTouched: {
            sa: false,
            day: false
        },
        isValid: {
            sa: false,
            day: false
        },
        value: {
            sa: '',
            day: ''
        },
    },
    pregnancyddrdate: {
        isTouched: {
            year: false,
            month: false,
            day: false
        },
        isValid: {
            year: false,
            month: false,
            day: false
        },
        value: {
            year: '',
            month: '',
            day: ''
        },
    },
    api: {
        message: '',
        code: 200,
    },
    isGuest: false,
    displayed: 'register',
};
