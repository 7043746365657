import { appCustoSetting } from '@app/settings/settings';
import { queueQueries } from '@app/api/controlleur';
const { API_URL } = appCustoSetting.appsettings;

function getHabits(type, usual) {
    return queueQueries(`${API_URL}/lunch/${type}/aliments/${usual}`, 'GET');
}

function getSuggest(type) {
    return queueQueries(`${API_URL}/lunch/${type}/aliments/`, 'GET');
}

function getLunchsByDate(date) {
    return queueQueries(`${API_URL}/lunch/${date}/`, 'GET');
}

function getLunchsByDateType(date, type) {
    return queueQueries(`${API_URL}/lunch/${date}/${type}/`, 'GET');
}

function getUsualLunchsByType(type, usual, page) {
    return queueQueries(`${API_URL}/lunch/${type}/${usual}/${page}`, 'GET');
}

function searchAliments(search) {
    return queueQueries(`${API_URL}/lunch/aliments/${search}/`, 'GET');
}

function searchAlimentsByFamilies(families, search) {
    return queueQueries(`${API_URL}/lunch/aliments/${families}/${search}/`, 'GET');
}

function sendLunch(datas) {
    return queueQueries(`${API_URL}/lunch/`, 'POST', 'application/json', true, true, true, true, true, datas);
}

function deleteLunch(id) {
    return queueQueries(`${API_URL}/lunch/${id}/`, 'DELETE');
}

function editLunch(datas, id) {
    return queueQueries(`${API_URL}/lunch/${id}/`, 'PUT', 'application/json', true, true, true, true, true, datas);
}

function getRepartition(start, end) {
    return queueQueries(`${API_URL}/lunch/repartition/${start}/${end}/`, 'GET');
}

function getRepartitionByDate(day) {
    return queueQueries(`${API_URL}/lunch/${day}/aliments/nutrient`, 'GET');
}

function getDashboardInfo(day) {
    return queueQueries(`${API_URL}/dashboard/nutrition/${day}/day/`, 'GET');
}

function getAliments(id) {
    return queueQueries(`${API_URL}/lunch/aliment/${id}/`, 'GET');
}

function getAlimentByAlias(alias) {
    return queueQueries(`${API_URL}/lunch/aliment/alias/${alias}/`, 'GET');
}



export const Lunch = {
    getHabits,
    getSuggest,
    getLunchsByDate,
    getLunchsByDateType,
    getUsualLunchsByType,
    searchAliments,
    searchAlimentsByFamilies,
    getAliments,
    getAlimentByAlias,
    sendLunch,
    deleteLunch,
    editLunch,
    getRepartition,
    getDashboardInfo,
    getRepartitionByDate,
};
