const getDateObjFromYMD = function (ymd) {
    if (!ymd) {
        return null;
    }

    let dateYear = ymd.substring(0, 4);
    let dateMonth = ymd.substring(4, 6);
    let dateDay = ymd.substring(6, 8);

    var parsedDate = {
        date: new Date(dateYear + '-' + dateMonth + '-' + dateDay),
        timezone: 'UTC',
        timezone_type: 3
    }

    return parsedDate;
}

export { getDateObjFromYMD };