import { t, h } from '@app/utils';

export const actions = {
    onComponentCreate: (props) => (state, actions) => {

    },
    getDate: ({el, dat}) => {
        var date = new Date(Date.parse(new Date(dat.replace(' ', 'T'))));
        var options = { month: 'long', day: 'numeric', year: 'numeric' };
        el.innerHTML = (t('Depuis le', {ns: 'generals'}) + ' ' + date.toLocaleDateString('fr-FR', options));
    }
};
