import { t } from '@app/utils';
import { redirectToOnboarding, getTodayYMD, createUTCDate, displayErrors } from '@app/core';
import { allRoutes } from '@app/settings';
import { User, Cardline } from '@app/api';
import * as staticsconstants from '@app/constants/constants-statics';

import { handleErrors } from '@app/api/errors';
import { handleHeaders } from '@app/api/headers';
import { Loader } from '@app/api/loader';

let attemps = 0;
let attempsNumber = 3;
//
let fetchSignal = null;
let POSTcontroller = null;
let GETcontroller = null;
let FETCHS_CONTROLLERS = {'post': [], 'get': []}
if ('AbortController' in window) {
    POSTcontroller = new window.AbortController();
    FETCHS_CONTROLLERS = {'post': [POSTcontroller], 'get': []};
}

let onceRefreshAtOnce = false;
const TIMER = 1000;
const sleeping = (ms = null) => {
    if (ms === null) {
        ms = TIMER;
    }
    var promise = new Promise(function(resolve) {
        window.setTimeout(function() {
            resolve('sleep done.');
        }, ms);
    });
    return promise;
}

const executeQuery = async (url, method, ctype, withLoader, withHeader, withErrors, withToken, mustBeQueued, datas, mustBeAborted, withVersion) => {
    console.log('executeQuery ::: !!!!!!!!!!!! ', url, method, withErrors);
    let token = localStorage.getItem('apitoken');
    let oHeader = {};
    if (withToken) {
        if (ctype === null) {
            oHeader = {
                'X-Token': token,
            }
        } else {
            oHeader = {
                'X-Token': token,
                'Content-Type': ctype,
            }
        }
    } else if (ctype !== null) {
        oHeader = {
            'Content-Type': ctype,
        }
    }
    if (withVersion) {
        oHeader['X-Version'] = (process.env.APP_VERSION || 'N.A');
    }
    console.log('oHeader : ', process.env, withVersion, oHeader);

    let oBody = null;
    let totalSize = 0;
    if (['get', 'head'].indexOf(method.toLowerCase()) === -1) {
        if (datas instanceof FormData) {
            oBody = datas;
            for (var bod of oBody.entries()) {
                for (var entry in bod) {
                    if (bod[entry].size !== undefined) {
                        totalSize += bod[entry].size;
                    }
                }
            }
        } else if (datas !== null) {
            oBody = JSON.stringify(datas);
        }
    } else {
        oBody = void(0);
    }
    if (totalSize > 2000000) {
        displayErrors(t('Le contenue de la requête est supérieur à 2M, veuillez envoyer moins de donnée ou une image moins grande!', {ns: 'generals'}), 5000);
        return false;
    }

    if ('AbortController' in window) {
        GETcontroller = new window.AbortController();
        // only GET controller are pushed, because only GET query are aborted when changing page
        //  POST must complete to send data before changing page
        FETCHS_CONTROLLERS.get.push(GETcontroller);
        // fetchSignal = (method === 'POST' ? POSTcontroller.signal : GETcontroller.signal);
        fetchSignal = (((method === 'GET') || (mustBeAborted === true)) ? GETcontroller.signal : POSTcontroller.signal);
        console.log('ABORTING signal ==? ' + url, (((method === 'GET') || (mustBeAborted === true)) ? 'GETcontroller.signal (to abort)' : 'POSTcontroller.signal (to not abort)'))
    }

    let options = {
        method: method,
        headers: new Headers(oHeader),
        body: oBody,
        signal: fetchSignal,
        oWith: {
            wLoader: withLoader,
            wHeader: withHeader,
            wErrors: withErrors
        }
    };

    attemps = 0;
    return await fetch_retry(url, options, attempsNumber);
}

const fetch_retry = async (url, options, n) => {
    if (options.oWith.wLoader) {
        Loader.addQuery();
    }
    return await fetch(url, options)
        .then((response) => {
            console.log('executeQuery ::: !!!!!!!!!!!! withLoader ', options.oWith.wLoader);
            if (options.oWith.wLoader) {
                Loader.removeQuery();
            }
            if (response) {
                return response;
            } else {
                console.error('ERROR when fetching');
            }
        })
        .then((response) => {
            console.log('executeQuery ::: !!!!!!!!!!!! withHeader ', options.oWith.wHeader);
            if (options.oWith.wHeader) {
                return handleHeaders(response)
            } else {
                if (response) {
                    return response;
                } else {
                    console.error('ERROR when handling header');
                }
                return response;
            }
        })
        .then((response) => {
            if (response.status === 204) {
                return {};
            } else {
                if (response) {
                    return response.json();
                } else {
                    console.error('ERROR when checking 204 status');
                }
            }
        })
        .then((response) => {
            console.log('executeQuery ::: !!!!!!!!!!!! withErrors ', options.oWith.wErrors);
            if (options.oWith.wErrors) {
                let errors = handleErrors(response);
                if (errors === 'expired') {
                    return errors;
                } else {
                    return errors;
                }
            } else {
                if (response) {
                    return response;
                } else {
                    console.error('ERROR when handling error');
                }
            }
        })
        .then((data) => {
            return {data: data};
        })
        .catch((error) => {
            console.log('CATCH : executeQuery :: queueQueries !!!!!!!!!!!! ', error, typeof error, JSON.stringify(error));
            var aborterror = String(error);
            if ((typeof aborterror === 'string') && ((aborterror.indexOf('DOMException') > -1) || (aborterror.indexOf('AbortError') > -1))) {
                return Promise.reject()
            }
            Loader.removeQuery();
            if (String(error).indexOf('fetch') > -1) {
                if (n === 1) {
                    error = t('Service indisponible.', {ns: 'generals'});
                    displayErrors(error, 2000);
                    sleeping(2000).then(() => {
                        window.location.href = allRoutes['home.index'].pathname;
                    });
                    return Promise.reject()
                } else {
                    let sleepingTime = 1000;
                    if (attemps === 1) {
                        sleepingTime = 3000;
                    } else if (attemps === 2) {
                        sleepingTime = 10000;
                    }
                    attemps += 1;
                    return sleeping(sleepingTime).then(() => {
                        return fetch_retry(url, options, (n - 1));
                    });
                }
            }
            displayErrors(error, 2000);
            return {error: error};
        })
}

const queueQueries = async (url, method, ctype = 'application/json', withLoader = true, withHeader = true, withErrors = true, withToken = true, mustBeQueued = true, datas = null, mustBeAborted = false, withVersion = false) => {
    let expiration = localStorage.getItem('expiration');
    console.log('executeQuery ::: queueQueries !!!!!!!!!!!! ', url, method, withErrors);
    //
    // IE VM dont calculate expiration correctly
    // return executeQuery(url, method, ctype, withLoader, withHeader, withErrors, withToken, mustBeQueued, datas, mustBeAborted);
    //
    if ((expiration !== false) && (expiration !== null)) {
        expiration = decodeURI(expiration);
        if (onceRefreshAtOnce) {
            return sleeping().then(() => {
                return queueQueries(url, method, ctype, withLoader, withHeader, withErrors, withToken, mustBeQueued, datas, mustBeAborted, withVersion);
            });
        } else if (expiration !== 'null') {
            let dateExpiration = createUTCDate(expiration, false);
            let now = createUTCDate(null, false);

            console.log('EXPIRE : ', dateExpiration +' < ' + now, dateExpiration.getTime() < now.getTime());
            if (dateExpiration.getTime() < now.getTime()) {
                let cb = function() {
                    return executeQuery(url, method, ctype, withLoader, withHeader, withErrors, withToken, mustBeQueued, datas, mustBeAborted, withVersion);
                }
                let sendtoOnboarding = false;
                if (dateExpiration.getDate() !== now.getDate()) {
                    sendtoOnboarding = true;
                }
                return refreshSession(cb, sendtoOnboarding);
            } else {
                return executeQuery(url, method, ctype, withLoader, withHeader, withErrors, withToken, mustBeQueued, datas, mustBeAborted, withVersion);
            }
        } else {
            return executeQuery(url, method, ctype, withLoader, withHeader, withErrors, withToken, mustBeQueued, datas, mustBeAborted, withVersion);
        }
    } else {
        return executeQuery(url, method, ctype, withLoader, withHeader, withErrors, withToken, mustBeQueued, datas, mustBeAborted, withVersion);
    }
}

const refreshSession = (callback, sendtoOnboarding) => {
    let refreshToken = localStorage.getItem('refresh');
    onceRefreshAtOnce = true;

    let customer = localStorage.getItem('readablecustomer');
    if (refreshToken) {
        return User.refreshUser({refresh: refreshToken}, customer).then((res) => {
            onceRefreshAtOnce = false;
            if ((res.data !== undefined) && (res.data.token && res.data.refresh)) {
                localStorage.setItem('apitoken', res.data.token);
                localStorage.setItem('refresh', res.data.refresh);
                localStorage.setItem('expiration', res.data.expiration.date);
                if (sendtoOnboarding) {
                    return Cardline.getCards([1, 22]).then((res) => {
                        if (res.data.length > 0) {
                            redirectToOnboarding(null, getTodayYMD());
                            // window.location.href = allRoutes['private.onboarding'].pathname;
                        } else {
                            return callback('success');
                        }
                    });
                } else {
                    return callback('success');
                }
            } else {
                localStorage.removeItem('apitoken');
                localStorage.removeItem('refresh');
                localStorage.removeItem('expiration');
                if ((staticsconstants.CUSTOMERS[customer] !== undefined) && (staticsconstants.CUSTOMERS[customer].logout !== undefined)) {
                    window.location.href = staticsconstants.CUSTOMERS[customer].logout;
                } else {
                    if (callback !== null) {
                        return callback(res);
                    } else {
                        window.location.href = allRoutes['home.login'].pathname;
                    }
                }
                return false;
            }
        });
    } else {
        onceRefreshAtOnce = false;
        localStorage.removeItem('apitoken');
        localStorage.removeItem('refresh');
        localStorage.removeItem('expiration');
        if ((staticsconstants.CUSTOMERS[customer] !== undefined) && (staticsconstants.CUSTOMERS[customer].logout !== undefined)) {
            window.location.href = staticsconstants.CUSTOMERS[customer].logout;
        } else {
            if (callback !== null) {
                return callback('error');
            } else {
                window.location.href = allRoutes['home.login'].pathname;
            }
        }
        return false
    }
}

export { FETCHS_CONTROLLERS };
export { queueQueries };
export { executeQuery };
export { refreshSession };