import { t, h, Component } from '@app/utils';
import { isDefined, getCustomerInfos } from '@app/core';
import { allRoutes } from '@app/settings';
import { Link } from '@app/elements';
import * as staticsconstants from '@app/constants/constants-statics';
import './index.scss';


const BotFooterView = Component(
    {
        customer: 'betterise',
        displayed: true,
        urlPdfCgu: allRoutes['pdf.cgu'].pathname,
        urlPdfCga: allRoutes['pdf.cga'].pathname,
        contactMail: 'alfred@betterise.me',
        allRightReserved: 'Betterise Health Tech',
        todayYear: 'Ø',
        startYear: '2013',
        loaded: false,
    },
    {
        onComponentUpdate: (props) => (state, actions) => {
            if (props.customer) {
                if (staticsconstants.CUSTOMERS[props.customer] !== undefined) {
                    let customerInformations = getCustomerInfos(props.customer);
                    if (customerInformations.mailto !== undefined) {
                        actions.setContactMail(customerInformations.mailto);
                    }
                    if (customerInformations.reserved !== undefined) {
                        actions.setAllRightReserved(customerInformations.reserved);
                    }
                    if (customerInformations.pdf !== undefined) {
                        if (customerInformations.pdf.cgu !== undefined) {
                            let cguUrl = allRoutes[customerInformations.pdf.cgu];
                            if (cguUrl && (cguUrl !== undefined) && (cguUrl.pathname !== undefined)) {
                                actions.setUrlPdfCgu(cguUrl.pathname);
                            }
                        }
                    }
                }
                actions.setCustomer(props.customer);
            }

            let d = new Date();
            actions.setTodayYear(d.getFullYear());
            actions.setLoaded(true);
        },
        removeDisclamer: () => state => ({
            displayed: false
        }),
        setUrlPdfCgu: newState => state => ({
            urlPdfCgu: newState
        }),
        setUrlPdfCga: newState => state => ({
            urlPdfCga: newState
        }),
        setContactMail: newState => state => ({
            contactMail: newState
        }),
        setCustomer: newState => state => ({
            customer: newState
        }),
        setAllRightReserved: newState => state => ({
            allRightReserved: newState
        }),
        setTodayYear: newState => state => ({
            todayYear: newState
        }),
        setStartYear: newState => state => ({
            startYear: newState
        }),
        setLoaded: newState => state => ({
            loaded: newState
        }),
    },
    (state, actions) => (props, children) => (
        <div class='btzFooter-btzFooters'>
            {state.loaded &&
                <dummy>
                    <p class='btzFooter-btzFooter'>{t('Tous droits réservés', {ns: 'generals'}) + ' ' + state.allRightReserved} {state.startYear} - {state.todayYear}</p>
                    <p class='btzFooter-btzFooter btzFooter-btzFooter-btzLinks'>
                        {(isDefined(staticsconstants.CUSTOMERS[state.customer].pdf) && isDefined(staticsconstants.CUSTOMERS[state.customer].pdf.cga)) &&
                            <Link to={state.urlPdfCga} target={'_blank'}>{t('CGA', {ns: 'generals'}) + ' - '}</Link>
                        }
                        <Link to={state.urlPdfCgu} target={'_blank'}>{t('CGU', {ns: 'generals'}) + ' - '}</Link>
                        <Link to={'mailto:' + state.contactMail}>{t('Contact', {ns: 'generals'})}</Link>
                    </p>
                </dummy>
            }
        </div>
    ),
    'botfooterview'
);

export { BotFooterView };