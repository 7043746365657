import { getUser, createUTCDate, getTodayYMD, isDefined, addCSRFToken, uuidv4, $ } from '@app/core';
import { Cardline, User } from '@app/api';
import { h } from '@app/utils';
import { allRoutes } from '@app/settings';
import * as constants from '@app/constants';

module.exports = {
    initAction: function() {
        const action = {
            onComponentCreate: () => (state, actions) => {
                state.tmdate = getTodayYMD();
                state.uniqId = uuidv4();
                actions.resetPreviousTimelines();

                loadSides();

                function loadSides() {
                    function getTMUser() {
                        let userCallback = (sessionUser) => {
                            let onboardingViewed = localStorage.getItem('onboarding-viewed-' + sessionUser.id + '-' + getTodayYMD());
                            if (isDefined(onboardingViewed) && ((onboardingViewed === true) || (onboardingViewed === 'true'))) {
                                state.user.profil = sessionUser;
                                actions.setState({ns: 'user', key: 'registrationDay', value: sessionUser.creation.date.split(' ')[0].replace(/-/g, '')});
                                localStorage.setItem('language', sessionUser.language);
                                getUserAlfred();
                            } else {
                                window.location.href = addCSRFToken(allRoutes['private.onboarding'].pathname);
                                return false;
                            }
                        };
                        getUser(userCallback, true);

                        function getUserAlfred() {
                            Cardline.getCards(13).then((res) => {
                                if (isDefined(res.data)) {
                                    actions.setState({ns: 'user', key: 'alfred', value: (res.data.length > 0 ? res.data[0] : null)});
                                }
                                getUserChallenge();
                            }).catch(() => {
                                getUserChallenge();
                            });
                        }

                        // get user running challenge
                        function getUserChallenge() {
                            Cardline.getUserChallenges().then((res) => {
                                if ((res.data !== undefined) && (res.data.length > 0)) {
                                    let running = [];
                                    for (var card in res.data) {
                                        card = res.data[card];
                                        var runningTheme = actions.getTheme(card.theme);
                                        var runningDuration = actions.getRemainingTime(card);

                                        card.valueTheme = runningTheme.value;
                                        card.labelTheme = runningTheme.label;
                                        card.labelDuration = runningDuration;
                                        card.percentDuration = (runningDuration === 0 ? 100 : actions.getRemainingPercent(card));
                                        card.accepted = true;

                                        running.push(card);
                                    }
                                    state.user.challenges.running = running;

                                    if (running.length < 3) {
                                        actions.getRecommandedChallenge();
                                    } else {
                                        actions.loadTimeline();
                                    }
                                } else {
                                    actions.getRecommandedChallenge();
                                }
                                actions.displayNotifications(true);
                            }).catch(() => {
                                actions.loadTimeline();
                            });
                        }
                    }

                    getTMUser();
                }
            },
            onComponentDestroy: () => (state, actions) => {
                document.removeEventListener('scroll', actions.onTimelineScroll, true);
            },
            loadPreviousTimelineOnScroll: () => (state, actions) => {
                document.addEventListener('scroll', actions.onTimelineScroll, true);
            },
            desableLoadOnScroll: () => (state, actions) => {
                document.removeEventListener('scroll', actions.onTimelineScroll, true);
                actions.noPreviousTimeline(true);
            },
            onTimelineScroll: () => (state, actions) => {
                // because onComponentDestroy is sometimes not called
                let areWeOnTheGoodPage = $('#btzTimelineRoot');
                if (!areWeOnTheGoodPage || (areWeOnTheGoodPage.length === 0)) {
                    actions.desableLoadOnScroll();
                    return false;
                }
                if (!state.mainTimelineLoaded) {
                    return false;
                }
                const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop)
                if ((scrollTop + window.innerHeight) >= document.body.scrollHeight) {
                    if (!state.isLoading) {
                        actions.getPreviousTimeline();
                    }
                }
            },
            getRecommandedChallenge: () => (state, actions) => {
                User.getChallenges().then((res) => {
                    if (res.data && (res.data !== undefined) && (res.data.length > 0)) {
                        var recommandeds = res.data;
                        var parsedRecommanded = [];
                        var maxRecommanded = 2;
                        for (var i = 0, len = recommandeds.length; i < len; i++) {
                            if (maxRecommanded-- === 0) {
                                break;
                            }
                            var recommanded = recommandeds[i];
                            var recommandedTheme = actions.getTheme(recommanded.theme);
                            var recommandedDuration = actions.getDuration(recommanded.duration);

                            recommanded.valueTheme = recommandedTheme.value;
                            recommanded.labelTheme = recommandedTheme.label;
                            recommanded.labelDuration = recommandedDuration;
                            recommanded.display = {};
                            recommanded.display.accepted = false;
                            recommanded.display.why = false;

                            parsedRecommanded.push(recommanded);
                        }
                        state.user.challenges.recommanded = parsedRecommanded;
                    }
                    // actions.setMainTimelineLoaded(true);
                    actions.loadTimeline();
                }).catch(() => {
                    actions.loadTimeline();
                });
            },
            loadTimeline: () => (state, actions) => {
                Cardline.getCards(2).then((res) => {
                    let advices = [];
                    if (res.data && (res.data !== undefined) && (res.data.length > 0)) {
                        for (var card in res.data) {
                            card = res.data[card];
                            var cardTheme = actions.getTheme(card.theme);

                            card.valueTheme = cardTheme.value;
                            card.labelTheme = cardTheme.label;
                            advices.push(card);
                        }
                    }
                    actions.setState({ns: 'cards', key: 'advices', value: advices});
                    console.log('ABORTING not abort, getting citation', res)
                    getCitation();
                }).catch(() => {
                    console.log('ABORTING CATCH ERROR, getting citation')
                    getCitation();
                });

                function getCitation() {
                    let citations = [];
                    Cardline.getCards(20).then((res) => {
                        if (res.data && (res.data !== undefined) && (res.data.length > 0)) {
                            for (var card in res.data) {
                                card = res.data[card];
                                citations.push(card);
                            }
                        }
                        actions.setState({ns: 'cards', key: 'citations', value: citations});
                        console.log('ABORTING not abort, getting article', res)
                        getArticle();
                    }).catch(() => {
                        console.log('ABORTING CATCH ERROR, getting article')
                        getArticle();
                    });
                }

                function getArticle() {
                    let articles = [];
                    Cardline.getCards(18).then((res) => {
                        if (res.data && (res.data !== undefined) && (res.data.length > 0)) {
                            for (var card in res.data) {
                                card = res.data[card];
                                articles.push(card);
                            }
                        }
                        actions.setState({ns: 'cards', key: 'articles', value: articles});
                        console.log('ABORTING not abort, getting recipe', res)
                        getRecipe();
                    }).catch(() => {
                        console.log('ABORTING CATCH ERROR, getting recipe')
                        getRecipe();
                    });
                }

                function getRecipe() {
                    let recipes = [];
                    Cardline.getCards(19).then((res) => {
                        if (res.data && (res.data !== undefined) && (res.data.length > 0)) {
                            for (var card in res.data) {
                                card = res.data[card];
                                recipes.push(card);
                            }
                        }
                        actions.setState({ns: 'cards', key: 'recipes', value: recipes});
                        console.log('ABORTING not abort, getting quizz', res)
                        getQuizz();
                    }).catch(() => {
                        console.log('ABORTING CATCH ERROR, getting quizz')
                        getQuizz();
                    });
                }

                function getQuizz() {
                    let quizzs = [];
                    Cardline.getCards(12).then((res) => {
                        if (res.data && (res.data !== undefined) && (res.data.length > 0)) {
                            for (var card in res.data) {
                                card = res.data[card];
                                var cardTheme = actions.getTheme(card.theme);
                                for (var form in card.forms) {
                                    if (card.forms[form].goodResponse === null) {
                                        card.forms[form].goodResponse = 0;
                                    }
                                }

                                card.valueTheme = cardTheme.value;
                                card.labelTheme = cardTheme.label;
                                quizzs.push(card);
                            }
                        }
                        actions.setState({ns: 'cards', key: 'quizzs', value: quizzs});
                        actions.setMainTimelineLoaded(true);
                        actions.loadPreviousTimelineOnScroll();
                    }).catch(() => {
                        actions.setMainTimelineLoaded(true);
                        actions.loadPreviousTimelineOnScroll();
                    });
                }
            },
            getPreviousTimeline: () => (state, actions) => {
                if (state.isLoading) {
                    return false;
                }
                //
                actions.loading();
                Cardline.getLastTimeline(state.tmdate).then((res) => {
                    actions.loading();
                    //
                    let cards = null;
                    let nextDate = null;
                    let foundTM = true;
                    //
                    if (isDefined(res.data)) {
                        cards = res.data.cards;
                        nextDate = res.data.date;

                        if (!isDefined(cards) || (cards.length === 0)) {
                            foundTM = false;
                        }
                        if (isDefined(nextDate)) {
                            actions.setTmdate(nextDate);
                        }
                    } else {
                        foundTM = false;
                    }
                    //
                    if (foundTM) {
                        actions.treatTimelineResult(cards);
                    } else {
                        if (isDefined(nextDate)) {
                            //
                            actions.getPreviousTimeline();
                        }
                    }
                    if (nextDate <= state.user.registrationDay) {
                        actions.desableLoadOnScroll();
                        return false;
                    }
                });
            },
            treatTimelineResult: (cards) => (state, actions) => {
                let parsedTm = {};
                let cardthemes = [2, 12, 19, 18, 20];
                for (var crd in cards) {
                    crd = cards[crd];
                    var cardTheme = actions.getTheme(crd.theme);

                    if (cardTheme !== undefined) {
                        crd.valueTheme = cardTheme.value;
                        crd.labelTheme = cardTheme.label;
                    }
                    if (parsedTm.cards === undefined) {
                        parsedTm.cards = [];
                    }
                    if (cardthemes.indexOf(+crd.type) > -1) {
                        parsedTm.cards.push(crd);
                    }
                }
                console.log('state.tmdate : ', state.tmdate);
                parsedTm.date = actions.getReadableFromYMD(state.tmdate);
                parsedTm.labelDate = 'Ø';
                state.timelines.push(parsedTm);
                actions.rendering();
            },
            getTheme: (intTheme) => {
                for (var _t in constants.custo.THEMES) {
                    if (constants.custo.THEMES[_t].key === intTheme) {
                        return constants.custo.THEMES[_t];
                    }
                }
            },
            getDuration: (duration) => {
                var str = duration.replace('P', '');
                var factor = str.slice(0, -1);
                var kind = str.slice(-1), nkind = 0;

                if (kind === 'D') {
                    nkind = 1;
                } else if (kind === 'W') {
                    nkind = 7;
                }

                return (+factor * nkind);
            },
            getRemainingTime: (card) => {
                var startDate = createUTCDate(null, false);
                var startTime = card.start.date.split(' ')[1];

                var endDate = createUTCDate(card.end.date.split(' ')[0] + 'T' + startTime.split('.')[0] + 'Z', false);
                if (endDate.getTime() < startDate.getTime()) {
                    return 0;
                }

                // get total seconds between the times
                var delta = Math.abs(endDate.getTime() - startDate.getTime()) / 1000;
                // calculate (and subtract) whole days
                var days = Math.floor(delta / 86400);
                delta -= days * 86400;
                if (days === 0) {
                    days = '';
                } else {
                    days = days + 'j';
                }

                // calculate (and subtract) whole hours
                var hours = Math.floor(delta / 3600) % 24;
                delta -= hours * 3600;
                if (hours === 0) {
                    hours = '00';
                }
                hours = hours + 'h';

                // calculate (and subtract) whole minutes
                var minutes = Math.floor(delta / 60) % 60;
                delta -= minutes * 60;
                if (minutes === 0) {
                    '00';
                }
                minutes = minutes + 'min';
                return (days + hours + minutes);
            },
            getRemainingPercent: (card) => {
                var now = new Date();
                var start = new Date(card.start.date);
                var end = new Date(card.end.date);

                var totalDuration = Math.abs(end.getTime() - start.getTime());
                var difDuration = Math.abs(end.getTime() - now.getTime());
                var onTen = (difDuration * 100) / totalDuration;

                return (100 - onTen);
            },
            getReadableFromYMD: (ymd) => (state, actions) => {
                var date = new Date(Date.parse(new Date(ymd.substr(0, 4) + '-' + ymd.substr(4, 2) + '-' + ymd.substr(6, 2))));
                var options = { weekday: 'long', month: 'long', day: 'numeric' };
                var readableDate = date.toLocaleDateString('fr-FR', options);
                return readableDate;
            },
            subOneDayToDateYMD: (ymd) => (state, actions) => {
                let date = null;
                if (ymd === null) {
                    date = new Date();
                } else {
                    date = new Date(ymd.substr(0, 4) + '-' + ymd.substr(4, 2) + '-' + ymd.substr(6, 2));
                }
                date.setDate(date.getDate() - 1);

                var month = date.getUTCMonth() + 1; //months from 1-12
                if (+month < 10) {
                    month = ('0' + month);
                }
                var day = date.getUTCDate();
                if (+day < 10) {
                    day = ('0' + day);
                }
                var year = date.getUTCFullYear();
                state.tmdate = (year + '' + month + '' + day);
            },
            displayNotifications: newState => state => ({
                displayedNotifications: newState,
            }),
            setState: ({ ns, key, value }) => state => ({
                [ns]: { ...state[ns], ...{ [key]: value } },
            }),
            resetPreviousTimelines: () => state => ({
                timelines: [],
            }),
            propagateFavoriteStatus: (props) => (state, actions) => {
                actions.rendering();
            },
            removeEndedChallenge: () => (state, actions) => {
                state.user.challenges.running.shift();
                actions.rendering();
            },
            recommandedRefused: () => (state, actions) => {
                state.user.challenges.recommanded.shift();
                actions.rendering();
            },
            removeRecommandedEndedChallenge: () => (state, actions) => {
                actions.recommandedRefused(); // just to remove actual recommanded, ended.
            },
            loading: () => state => ({
                isLoading: !state.isLoading,
            }),
            setTmdate: newState => state => ({
                tmdate: newState,
            }),
            noPreviousTimeline: () => state => ({
                isRegisterDay: !state.isRegisterDay,
            }),
            setMainTimelineLoaded: newState => state => ({
                mainTimelineLoaded: newState,
            }),
            rendering: () => state => ({
                rendering: !state.rendering,
            }),
        }
        return action;
    }
}
