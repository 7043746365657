import { h } from '@app/utils';
import './../index.scss';

import RegisterLayout from '@app/layouts/register/layout';

import StepWelcome from './../step-welcome';
import StepDevice from './../step-device';
import StepCgu from './../step-cgu';
import StepIds from './../step-ids';
import StepSecure from './../step-secure';

import StepPseudo from './../step-pseudo';
import StepSex from './../step-sex';
import StepWhopay from './../step-whopay';
import StepMutualHealth from './../step-mutualhealth';
import StepEntreprise from './../step-entreprise';
import StepPayment from './../step-payment';
import StepPaymentValidation from './../step-payment-validation';
import StepObjectives from './../step-objectif';
import StepEnd from './../step-end';

module.exports = {
    initView: function() {
        let view = (state, actions) => ({ location, match, language }, children) => (
        //let view = (state, actions) => (props, children) => (
            <div key='register'>
                <RegisterLayout page='register' subpage={location.pathname} customer={state.customer} howthatwork={state.howthatwork}>

                    {/* Default view */}
                    {match.isExact && (
                        <StepWelcome initial={state.user} send={actions.setState} customer={state.customer} />
                    )}

                    {/* STEP 1 */}
                    {location.pathname === '/home/register/welcome' && (
                        <StepWelcome initial={state.user} send={actions.setState} customer={state.customer} />
                    )}

                    {/* STEP 2 */}
                    {location.pathname === '/home/register/device' && (
                        <StepDevice initial={state.user} send={actions.setState} customer={state.customer} switchHowThatWork={actions.switchHowThatWork} />
                    )}

                    {/* STEP 3 */}
                    {location.pathname === '/home/register/cgu' && (
                        <StepCgu initial={state.user} send={actions.setState} customer={state.customer} />
                    )}

                    {/* STEP 4 */}
                    {location.pathname === '/home/register/ids' && (
                        <StepIds initial={state.user} send={actions.setState} customer={state.customer} />
                    )}

                    {/* STEP 5 */}
                    {location.pathname === '/home/register/secure' && (
                        <StepSecure initial={state.user} send={actions.setState} customer={state.customer} />
                    )}

                    {/* STEP 6 */}
                    {location.pathname === '/home/register/pseudo' && (
                        <StepPseudo initial={state.user} send={actions.setState} customer={state.customer} />
                    )}

                    {/* STEP 7 */}
                    {location.pathname === '/home/register/sex' && (
                        <StepSex initial={state.user} send={actions.setState} customer={state.customer} />
                    )}

                    {/* STEP 8 */}
                    {location.pathname === '/home/register/whopay' && (
                        <StepWhopay initial={state.user} send={actions.setState} customer={state.customer} />
                    )}

                    {/* STEP 9 */}
                    {location.pathname === '/home/register/mutualhealth' && (
                        <StepMutualHealth initial={state.user} send={actions.setState} customer={state.customer} />
                    )}

                    {/* STEP 10 */}
                    {location.pathname === '/home/register/entreprise' && (
                        <StepEntreprise initial={state.user} send={actions.setState} customer={state.customer} />
                    )}

                    {/* STEP 11 */}
                    {location.pathname === '/home/register/objectives' && (
                        <StepObjectives initial={state.user} send={actions.setState} customer={state.customer} language={language} />
                    )}

                    {/* STEP 12 */}
                    {location.pathname === '/home/register/payment' && (
                        <StepPayment initial={state.user} send={actions.setState} customer={state.customer} />
                    )}

                    {/* STEP 13 */}
                    {location.pathname === '/home/register/payment/validation' && (
                        <StepPaymentValidation initial={state.user} customer={state.customer} />
                    )}

                    {/* STEP 14 */}
                    {location.pathname === '/home/register/end' && (
                        <StepEnd initial={state.user} send={actions.setState} customer={state.customer} />
                    )}

                </RegisterLayout>
            </div>
        );
        return view;
    }
}

export { StepWelcome };
export { StepDevice };
export { StepCgu };
export { StepIds };
export { StepSecure };
export { StepPseudo };
export { StepSex };
export { StepMutualHealth };
export { StepEntreprise };
export { StepPayment };
export { StepObjectives };
export { StepEnd };
