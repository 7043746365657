import { t } from '@app/utils'
export const state = {
    email: 'en cours de vérification ...',
    i18nEmail: t('en cours de vérification ...', {ns: 'public'}),
    emailConfirmed: false,
    customerSsoName: 'betterise',
    sharedFrom: '',
    goToLabel: '',
    goToUrl: '',
};
