import { t, h, Component } from '@app/utils';
import './index.scss';

import AccountLayout from '@app/layouts/account/layout';

const state = {

}

const actions = {
    onComponentCreate: () => (state, actions) => {

    },
};

const view = (state, actions) => (props, children) => (
    <AccountLayout page='account' readablePageLabel={t('Votre compte', {ns: 'account'})} readablePageDescription={t('Pour gérer au mieux votre accompagnement santé', {ns: 'account'})}>
        ACCOUNT
    </AccountLayout>
);

export default Component(state, actions, view, 'account');
