import * as staticsconstants from '@app/constants/constants-statics';

const getCustomerInfos = function(customer) {
    if ((customer === undefined) || (customer === null) || (customer === '') || (staticsconstants.CUSTOMERS[customer] === undefined)) {
        customer = 'betterise';
    }
    let customerInfos = staticsconstants.CUSTOMERS[customer];
    return customerInfos;
}

export { getCustomerInfos };