import { h, Component } from '@app/utils';
import { Button, Card } from '@app/elements';
import * as constants from '@app/constants';

import icoEdit from '@app/img/ico/ico-adddatas.png';
import icoEditx2 from '@app/img/ico/ico-adddatas@2x.png';

import { StepStart } from './step-start';
import { StepListings } from './step-listings';
import { StepEdit } from './step-edit';

const AddMeal = Component(
    {
        ymdMealDate: null,
        editMealDate: false,
        editMealKind: true,
        mealKind: null,
        mealContFeel: [],
        mealContEat: [],
        mealContKind: [],
        mealKindLabel: '',
        mealContFeelLabel: '',
        mealContEatLabel: '',
        mealContKindLabel: '',
        mealDate: null,
        mealKinds: constants.custo.LUNCH,
        mealContFeels: constants.custo.LUNCH_CONTEXT_FEEL,
        mealContEats: constants.custo.LUNCH_CONTEXT_EAT,
        mealContKinds: constants.custo.LUNCH_CONTEXT_KIND,
        displayedListing: 'suggest',
        lastStep: false,
        existingLunch: null,
        today: null,
        resetLunchAliments: false,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.existingLunch && (props.existingLunch.id !== undefined)) {
                for (var meal in state.mealKinds) {
                    if (state.mealKinds[meal].key === props.existingLunch.type) {
                        state.mealKindLabel = state.mealKinds[meal].label;
                        break;
                    }
                }
                state.editMealDate = false;
                state.editMealKind = false;
                state.mealKind = props.existingLunch.type;
                state.mealName = props.existingLunch.name;
                state.existingLunch = props.existingLunch;
            }

            state.ymdMealDate = props.ymdMealDate;
            actions.setMealDate(props.ymdMealDate);

            let today = new Date();
            let day = today.getDate();
            let month = (+today.getMonth() + 1);
            let year = today.getFullYear();

            if (+day < 10) {
                day = ('0' + day);
            }
            if (+month < 10) {
                month = ('0' + month);
            }
            actions.setToday(year + '' + month + '' + day);

            if ((props.resetLunchAliments) && (props.resetLunchAliments === true)) {
                actions.resetLunch();
            }
            actions.rendering();
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (props.existingLunch && (props.existingLunch.id !== undefined)) {
                for (var meal in state.mealKinds) {
                    if (state.mealKinds[meal].key === props.existingLunch.type) {
                        state.mealKindLabel = state.mealKinds[meal].label;
                        break;
                    }
                }
                state.editMealDate = false;
                state.editMealKind = false;
                state.mealKind = props.existingLunch.type;
                state.mealName = props.existingLunch.name;
                state.existingLunch = props.existingLunch;
            }

            state.ymdMealDate = props.ymdMealDate;
            actions.setMealDate(props.ymdMealDate);
            actions.rendering();

            if ((props.resetLunchAliments) && (props.resetLunchAliments === true)) {
                actions.resetLunch();
            }
        },
        updateFromCalendar: (ymdDate) => (state, actions) => {
            actions.setResetLunchAliments(false);
            actions.setYMDMealDate(ymdDate);
            actions.setMealDate(ymdDate);
        },
        resetAlimentsIfNeeded: (mealKind) => (state, actions) => {
            if (mealKind === 4) {
                if ((state.mealKind !== 4) || (state.mealKind === null)) {
                    actions.setExistingLunch([]);
                    actions.setResetLunchAliments(true);
                } else {
                    actions.setResetLunchAliments(false);
                }
            } else {
                if ((state.mealKind === 4) || (state.mealKind === null)) {
                    // actions.setExistingLunch([]);
                    // actions.setResetLunchAliments(true);
                    actions.setResetLunchAliments(false);
                } else {
                    // actions.setResetLunchAliments(false);
                    actions.setExistingLunch([]);
                    actions.setResetLunchAliments(true);
                }
            }
        },
        resetLunch: () => (state, actions) => {
            actions.setExistingLunch([]);
            actions.setResetLunchAliments(true);
            actions.setMealContFeel([]);
            actions.setMealContEat([]);
            actions.setMealContKind([]);
        },
        setMealContEat: newState => state => ({
            mealContEat: newState,
        }),
        setMealContFeel: newState => state => ({
            mealContFeel: newState,
        }),
        setMealContKind: newState => state => ({
            mealContKind: newState,
        }),
        setExistingLunch: newState => state => ({
            existingLunch: newState,
        }),
        setEditMealDate: newState => state => ({
            editMealDate: newState,
        }),
        setEditMealKind: newState => state => ({
            editMealKind: newState,
        }),
        setMealKind: newState => state => ({
            mealKind: newState,
        }),
        setMealDate: newState => state => ({
            mealDate: newState,
        }),
        setYMDMealDate: newState => state => ({
            ymdMealDate: newState,
        }),
        setMealKindLabel: newState => state => ({
            mealKindLabel: newState,
        }),
        setDisplayed: newState => state => ({
            displayedListing: newState,
        }),
        displayLastStep: newState => state => ({
            lastStep: newState,
        }),
        setToday: newState => state => ({
            today: newState,
        }),
        setResetLunchAliments: newState => state => ({
            resetLunchAliments: newState,
        }),
        rendering: () => state => ({
            rendering: !state.rendering,
        }),
    },
    (state, actions) => (props, children) => (
        <Card classes={'btzInformations-btzInformationsModal ' + ((state.editMealKind != false || state.editMealDate != false) ? 'btzInformations-btzInformationsModal-btzStartStep' : '') + (((props.dayLunchsId !== null) && (props.dayLunchsId !== '') && (props.dayLunchsId !== undefined)) ? 'btzInformations-btzInformationsModal-btzEditStep' : '')}>
            <Card.Header classes={'btzCard-btzHeader-btzModal'} style={{ 'vertical-align': (state.editMealKind || state.editMealDate) ? 'middle' : 'top' }}>
                <div class='btzModal-btzPaddingWrapper'>
                    <StepStart updateFromCalendar={actions.updateFromCalendar} today={state.today} ymdMealDate={state.ymdMealDate} existingLunch={state.existingLunch} key={'edit-' + state.editMealDate + '-' + state.editMealKind} editMealDate={state.editMealDate} editMealKind={state.editMealKind} setEditMealDate={actions.setEditMealDate} setEditMealKind={actions.setEditMealKind} setMealKind={actions.setMealKind} mealDate={state.mealDate} setMealKindLabel={actions.setMealKindLabel} resetAlimentsIfNeeded={actions.resetAlimentsIfNeeded} {...state} fromEdit={((props.dayLunchsId !== null) && (props.dayLunchsId !== '') && (props.dayLunchsId !== undefined)) ? true : false} ></StepStart>
                </div>
                {(state.mealKind !== null && state.editMealKind === false && state.editMealDate === false) &&
                    <div>
                        <div class={'btzData-btzMeal-btzMealKind btzData-btzMeal-btzMealKind-btzSelected btzModal-btzPaddingWrapper'} style={{ 'margin-top': '0px' }}>
                            <Button flat active style={{ 'cursor': 'default' }}>
                                <p key={'meal-' + state.mealKind}>{state.mealKindLabel}</p>
                            </Button>
                            <img src={icoEdit} srcset={`${icoEditx2} 2x`} alt='' class='' onclick={() => {
                                actions.setResetLunchAliments(false);
                                actions.setEditMealKind(true);
                            }}/>
                        </div>
                        {((props.dayLunchsId !== null) && (props.dayLunchsId !== '') && (props.dayLunchsId !== undefined)) ?
                            <div class={'mealedit-' + props.customer + (state.existingLunch ? state.existingLunch.id : '')} key={(state.existingLunch ? state.existingLunch.id : state.resetLunchAliments)}>
                                <StepEdit customer={props.customer} userGender={props.userGender} existingLunch={state.existingLunch} lastStep={state.lastStep} displayLastStep={actions.displayLastStep} displayed={state.displayedListing} mealKind={state.mealKind} mealDate={state.ymdMealDate} mealName={state.mealName} setDisplayed={actions.setDisplayed} resetLunchAliments={false} mealContFeels={state.mealContFeels} mealContEats={state.mealContEats} mealContKinds={state.mealContKinds} mealContexts={state.existingLunch.contexts} ></StepEdit>
                            </div>
                            :
                            <div class={'meallisting-' + props.customer + (state.existingLunch ? state.existingLunch.id : '')} key={(state.existingLunch ? state.existingLunch.id : state.resetLunchAliments)}>
                                <StepListings customer={props.customer} userGender={props.userGender} existingLunch={state.existingLunch} lastStep={state.lastStep} displayLastStep={actions.displayLastStep} displayed={state.displayedListing} mealKind={state.mealKind} mealDate={state.ymdMealDate} mealName={state.mealName} setDisplayed={actions.setDisplayed} resetLunchAliments={false} mealContFeels={state.mealContFeels} mealContEats={state.mealContEats} mealContKinds={state.mealContKinds} ></StepListings>
                            </div>
                        }
                    </div>
                }
            </Card.Header>
        </Card>
    ),
    'addmeal'
);

export { AddMeal };
