import { dynLoad, scrollTo, getClosest, getTodayYMD, addCSRFToken, getUser, getQueryVariable, createAndSaveCSRFToken, convertMetrics, convertMetricsLabels, isDefined } from '@app/core';
import { Cardline } from '@app/api';
import { allRoutes } from '@app/settings';
import { t, h, Component } from '@app/utils';
import * as constants from '@app/constants';
import * as staticsconstants from '@app/constants/constants-statics';

export const helps = dynLoad('help-mssg', 'Onboarding/custo');
const HELPS_MESSAGES = helps.HELPS_MESSAGES;

const HELP_DEFAULT = HELPS_MESSAGES.DEFAULT.label;
const HELP_SLIDER = HELPS_MESSAGES.SLIDER.label;
const HELP_MORAL = HELPS_MESSAGES.MORAL.label;
const HELP_STRESS = HELPS_MESSAGES.STRESS.label;
const HELP_TIREDNESS = HELPS_MESSAGES.TIREDNESS.label;
const HELP_BUTTON = HELPS_MESSAGES.BUTTON.label;
const HELP_QCM = HELPS_MESSAGES.QCM.label;
const LABEL_ALCOOL = HELPS_MESSAGES.ALCOOL.label;
const LABEL_TOBACCO = HELPS_MESSAGES.TOBACCO.label;

const FIRST_CARD_NAME = 'EVAL-MORA-01';

const state = {
    idx: 0,
    activeValidation: false,
    steps: {},
    current: {},
    values: [],
    readed: [],
    formIsSubmitting: false,
    error: '',
    customer: 'betterise',
    endBoard: false,
    activeEndSubmit: false,
    targetCards: [1, 22, 58],
    lastanswercallback: null,
    maxCards: 10,
    totalCards: 0,
    user: null,
    uniformButtonHeight: 0,
    cerbaSportCoachDisplay: 'EVAL-CERBA-032',
    specialInitialCount: 0,
    language: 'fr_FR',
    timelineId: null,
    alpha: 'abcdefghijklmnopqrstuvwxyz',
    mobileOnboarding: false,
    showPregnancyInputs: false,
    showPregnancyInputsStep: 'pregnancy',
    tempCounter: {
      "id": "6023e5d401ebfc411c1b3f87",
      "name": "EVAL-TEST-COUNTER",
      "title": "Test eval counter",
      "description": null,
      "shortTitle": null,
      "extra": null,
      "theme": 3,
      "type": 1,
      "children": [],
      "category": null,
      "redirectUrl": null,
      "dataDate": "P0D",
      "media": null,
      "article": null,
      "recipe": null,
      "program": null,
      "reward": null,
      "challenge": null,
      "forms": [{
        "id": "7ef58d4ad80355811488b0205fe278af",
        "tag": "MEASURE_CHESTPAIN",
        "type": "Counter",
        "responses": [],
        "goodResponse": 0,
        "datas": [],
        "url": null,
        "media": null,
        "gameAction": null,
        "order": 0,
        "step": 1,
        "min": 0,
        "max": 10,
        "default": 5
      }],
      "coach": null,
      "medicalStudyMoment": null,
      "medicalStudySurvey": null,
      "medicalStudyEvalMeasureKpi": null,
      "skippableEval": true,
      "shareTwitter": 0,
      "shareFacebook": 0,
      "shareEmail": 0,
      "shareMessenger": 0,
      "like": 0,
      "favoriteCount": 0,
      "favorite": false,
      "favoriteId": null,
      "timeline": "6023e5e44f73ca0b1574bcb2",
      "idCard": "6023e5d401ebfc411c1b3f87",
      "cardId": "6023e5d401ebfc411c1b3f87",
      "state": 0
    },
}

const actions = {
    onComponentUnload: (props) => (state, actions) => {
        console.log('component!! UNLOAD')
    },
    onComponentRemove: (props) => (state, actions) => {
        console.log('component!! REMOVE')
    },
    onComponentBeforeRemove: (props) => (state, actions) => {
        console.log('component!! BEFORE REMOVE')
    },
    onComponentCreate: (props) => (state, actions) => {
        // actions.setShowPregnancyInputs(true);
        let isMobile = false;
        if (isDefined(staticsconstants.CUSTOMERS[props.customer.toLowerCase()])) {
            isMobile = staticsconstants.CUSTOMERS[props.customer.toLowerCase()].modules.mobileOnboarding;
            actions.setIsMobileOnboarding(isMobile);
        }
        console.log('TRACK : onboarding create : ', props.customer, staticsconstants.CUSTOMERS[props.customer.toLowerCase()]);
        //
        if (staticsconstants.CUSTOMERS[props.customer.toLowerCase()].customerRedirect.withLoginToken === true) {
            let token = getQueryVariable('token', false);
            let refresh = getQueryVariable('refresh', false);
            let expire = getQueryVariable('expire', false);
            console.log('TRACK : onboarding create : ', token, refresh, expire);

            if (token && (token !== false)) {
                localStorage.setItem('apitoken', token);
            }
            if (refresh && (refresh !== false)) {
                localStorage.setItem('refresh', refresh);
            }
            if (expire && (expire !== false)) {
                localStorage.setItem('expiration', expire);
            }
            createAndSaveCSRFToken(true);
        }
        let timelineId = getQueryVariable('timelineid', false);
        if (timelineId && (timelineId !== false)) {
            actions.setTimelineId(timelineId);
        } else {
            timelineId = null;
        }

        if (isMobile) {
            actions.setConfirmQuitEvent();
        }

        let onboardLanguage = state.language;
        if (props.language) {
            onboardLanguage = props.language;
            actions.setLanguage(onboardLanguage);
        }

        let userCallback = (sessionUser) => {
            console.log('TRACK : onboarding create : userCallback ', sessionUser);
            if (sessionUser) {
                actions.setUser(sessionUser);
            }
            //
            let targetCards = [1, 22];
            if (props.displayedon && (props.displayedon === 'timeline')) {
                targetCards = 58;
                actions.setTargetCards(targetCards);
            } else if (props.customer) {
                actions.setCustomer(props.customer);
                if (isDefined(staticsconstants.CUSTOMERS[state.customer.toLowerCase()].customOnboardingCards)) {
                    if (staticsconstants.CUSTOMERS[state.customer.toLowerCase()].customOnboardingCards.length > 0) {
                        targetCards = staticsconstants.CUSTOMERS[state.customer.toLowerCase()].customOnboardingCards;
                    }
                }
            }
            let getCardsCallback = (res) => {
                if (res.error) {
                    window.location.href = addCSRFToken(allRoutes['home.login'].pathname);
                    return false;
                } else {
                    let atLeastOneCardNotAnswered = false;
                    if (res.data.length > 0) {
                        var cards = res.data;
                        cards = cards.sort((x, y) => { return x.name == FIRST_CARD_NAME ? -1 : y.name == FIRST_CARD_NAME ? 1 : 0; });

                        //
                        let customeFilter = false;
                        if (isDefined(staticsconstants.CUSTOMERS[state.customer.toLowerCase()].customOnboardingCards)) {
                            if (staticsconstants.CUSTOMERS[state.customer.toLowerCase()].customOnboardingCards.length > 0) {
                                customeFilter = true;
                            }
                        }
                        if (props.displayedon && (props.displayedon !== 'timeline') && (customeFilter === true) && (cards.length > 10)) {
                            var cardsEvalClassic = cards.filter((f) => ((+f.type === 1) || (+f.type === 8)));
                            var cardsEvalBehaviour = cards.filter((f) => (+f.type === 22));
                            var cardsEvalNutritional = cards.filter((f) => (+f.type === 58));
                            if (cardsEvalNutritional.length > 0) {
                                cards = cardsEvalNutritional.slice(0, state.maxCards); // get max of nutri card
                                if (cards.length < state.maxCards) { // if there is less than maxCard
                                    let remainingCards = state.maxCards - cards.length; // calculate remaining space
                                    cards = [...cards, ...cardsEvalClassic.slice(0, remainingCards)];
                                }
                                cards = [...cards, ...cardsEvalBehaviour];
                            }
                        }
                        //
                        let returnedData = actions.parseAndPushCards(cards);
                        let stateSteps = returnedData.stateSteps;
                        atLeastOneCardNotAnswered = returnedData.atLeastOneCardNotAnswered;
                        onboardLanguage = returnedData.onboardLanguage;
                        actions.setLanguage(onboardLanguage);
                        actions.setSteps(stateSteps);
                    }
                    if (atLeastOneCardNotAnswered) {
                        actions.updateCurrentStep();
                    } else {
                        if (props.displayedon !== 'timeline') {
                            if (!isDefined(timelineId)) {
                                localStorage.setItem('onboarding-viewed-' + sessionUser.id + '-' + getTodayYMD(), true);
                                actions.redirectToTimeline();
                            } else {
                                window.location.href = addCSRFToken(allRoutes['private.endonboarding'].pathname);
                            }
                            return false;
                        } else {
                            if (props.setNothingToSurvey && (props.setNothingToSurvey !== null)) {
                                props.setNothingToSurvey(true);
                            }
                        }
                    }
                }
            };

            if (isDefined(timelineId)) {
                let medicalStudytoken = localStorage.getItem('medicalStudytoken');
                Cardline.getMedicalCards(timelineId, medicalStudytoken, false).then((res) => {
                    if (isDefined(res.data)) {
                        if (isDefined(res.data.code) && (+res.data.code === 2304)) {
                            window.location.href = addCSRFToken(allRoutes['private.doneonboarding'].pathname);
                            return false;
                        }
                        //
                        let totalCards = 0;
                        for (var card in res.data) {
                            if (res.data[card] && isDefined(res.data[card].forms) && (res.data[card].forms.length > 0)) {
                                totalCards++;
                            }
                        }
                        actions.setTotalCards(totalCards);
                        getCardsCallback(res);
                    }
                });
            } else {
                Cardline.getCards(targetCards, false).then((res) => {
                    getCardsCallback(res);
                });
            }

            window.onkeyup = function(e) {
                var key = e.keyCode ? e.keyCode : e.which;
                if (key === 13) {
                    if (props.displayedon === 'timeline') {
                        actions.sendTimelineAnswer();
                    } else {
                        actions.nextCard(false);
                    }
                }
            }
        };
        console.log('TRACK : onboarding create : gettingUser()');
        getUser(userCallback, true, false);
    },
    setConfirmQuitEvent: () => (state, actions) => {
        var myEvent = window.attachEvent || window.addEventListener;
        var chkevent = window.attachEvent ? 'onbeforeunload' : 'beforeunload';
        // var isOnIOS = navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i);
        // chkevent = isOnIOS ? 'pagehide' : chkevent;
        console.log('QUIT EVENT : ', chkevent)
        myEvent(chkevent, actions.triggerConfirmQuit);
        myEvent('pagehide', actions.triggerConfirmQuit);
        myEvent('visibilitychange', actions.triggerConfirmQuitVisibilty);
    },
    removeConfirmQuitEvent: () => (state, actions) => {
        var myEvent = window.detachEvent || window.removeEventListener;
        var chkevent = window.detachEvent ? 'onbeforeunload' : 'beforeunload';
        // var isOnIOS = navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i);
        // chkevent = isOnIOS ? 'pagehide' : chkevent;
        myEvent(chkevent, actions.triggerConfirmQuit);
        myEvent('pagehide', actions.triggerConfirmQuit);
        myEvent('visibilitychange', actions.triggerConfirmQuitVisibilty);
    },
    triggerConfirmQuitVisibilty: (e) => (state, actions) => {
        if (document.visibilityState === 'visible') {
            //
        } else {
            actions.triggerConfirmQuit(e)
        }
    },
    triggerConfirmQuit: (e) => (state, actions) => {
        console.log('QUIT EVENT : triggered ! ', state.activeEndSubmit);
        if (!state.activeEndSubmit) { // if its not final submit
            var confirmationMessage = t('Cette page demande de confirmer sa fermeture ; des données saisies pourraient ne pas être enregistrées.', {ns: 'onboarding'});
            (e || window.event).returnValue = confirmationMessage;
            return confirmationMessage;
        }
    },
    parseAndPushCards: (cards) => (state, actions) => {
        let stateSteps = [];
        let cardIdx = 0;
        let onboardLanguage = state.language;
        let atLeastOneCardNotAnswered = false;
        let enGBCardsPossible = true;
        //
        // cards.unshift(state.tempCounter);
        for (let card in cards) {
            if (cards[card].forms[0] === undefined) {
                continue;
            }
            // TEMP
            // cards[card].type = 'bloodpressure';
            // cards[card].forms[0].type = 'bloodpressure';
            //
            let stepType = cards[card].forms[0].type.toLowerCase(),
                stepValues = cards[card].forms[0].datas.length,
                stepDatas = cards[card].forms[0].datas,
                stepTag = cards[card].forms[0].tag,
                stepRanges = cards[card].forms,
                stepHelp = HELP_DEFAULT,
                stepTheme = actions.getTheme(cards[card].theme),
                stepLabel = '',
                skippableEval = (isDefined(cards[card].skippableEval) ? cards[card].skippableEval : true),
                missingTag = cards[card].forms.filter((f) => ((f.tag === null) || (f.tag === '') || (f.tag === undefined)));

            if (missingTag.length > 0) {
                continue;
            }

            if ((stepType === 'slider') || (stepType === 'counter')) {
                if ((stepValues > 11) || (stepType === 'counter')) { // here change 11
                    if (stepType !== 'counter') {
                        stepType = 'number';
                    }
                    stepHelp = HELP_SLIDER;
                } else {
                    if (stepTheme === 'stress') {
                        stepHelp = HELP_STRESS;
                    } else if (stepTheme === 'tiredness') {
                        stepHelp = HELP_TIREDNESS;
                    } else if ((stepTheme === 'sport') || (stepTheme === 'sleep') || (stepTheme === 'activity')) {
                        stepHelp = HELP_BUTTON;
                    } else if (stepTheme === 'shape') {
                        stepHelp = HELP_MORAL;
                    }
                }
            } else if (stepType === 'button') {
                stepDatas = cards[card].forms;
                stepHelp = HELP_BUTTON;
            } else if (stepType === 'qcm') {
                stepDatas = cards[card].forms;
                stepHelp = HELP_QCM;
            }

            if (stepTheme === 'alcool') {
                stepLabel = LABEL_ALCOOL;
            } else if (stepTheme === 'tobacco') {
                stepLabel = LABEL_TOBACCO;
            }
            if (cards[card].extra) {
                stepHelp = cards[card].extra;
            }

            let linkPatt = /(?=\[\[)(.*?)(\]\])/g;
            if (isDefined(stepHelp)) {
                stepHelp = stepHelp.replace(linkPatt, (patt) => {
                    patt = patt.replace(/\[\[|\]\]/g, '').split('|');
                    if ((patt.length === 3) && (patt[0] === 'link')) {
                        return '<a target="_blank" href="' + patt[1] + '">' + patt[2] + '</a>';
                    }
                    return patt;
                });
            }
            if (!isDefined(cards[card].state)) {
                cards[card].state = 0; // temp fixe for onco question 58
            }
            if (cards[card].state === 0) {
                stateSteps[cardIdx] = {
                    theme: stepTheme,
                    name: cards[card].name,
                    question: (cards[card].title !== null) ? cards[card].title : cards[card].name,
                    help: stepHelp,
                    type: cards[card].type,
                    numCard: (cardIdx + 1),
                    form: {
                        id: cards[card].id,
                        type: stepType,
                        values: stepValues,
                        datas: stepDatas,
                        tag: stepTag,
                        label: stepLabel
                    },
                    original: cards[card],
                    idtimeline: cards[card].timeline,
                    stepRanges: stepRanges,
                    skippableEval: skippableEval,
                };
                cardIdx++;

                atLeastOneCardNotAnswered = true;
                let refStepDatas = null;
                for (var stepDat in stepDatas) {
                    if (isDefined(stepDatas[stepDat]) && isDefined(stepDatas[0].datas)) {
                        refStepDatas = stepDatas[stepDat];
                    }
                }
                if (isDefined(refStepDatas) && isDefined(refStepDatas.datas) && enGBCardsPossible) {
                    let refCard = refStepDatas.datas.filter((f) => f.lang === onboardLanguage)[0];
                    if (isDefined(refCard) && (refCard.name === '')) {
                        console.log('stateSteps : switching here ', card, refCard);
                        onboardLanguage = 'en_GB';
                        //
                        refCard = refStepDatas.datas.filter((f) => f.lang === onboardLanguage)[0];
                        if ((isDefined(refCard) && (refCard.name === '')) || !isDefined(refCard)) {
                            onboardLanguage = 'fr_FR';
                            enGBCardsPossible = false;
                        }
                    } else {
                        refCard = refStepDatas.datas.filter((f) => f.lang === 'en_GB')[0];
                        if ((isDefined(refCard) && (refCard.name === '')) || !isDefined(refCard)) {
                            enGBCardsPossible = false;
                        }
                    }
                }
            }
        }
        console.log('stateSteps : ', stateSteps, onboardLanguage);

        return {stateSteps: stateSteps, atLeastOneCardNotAnswered: atLeastOneCardNotAnswered, onboardLanguage: onboardLanguage};
    },
    onComponentUpdate: (props) => (state, actions) => {
        if (props.lastanswercallback) {
            actions.setLastAnswerCallback(props.lastanswercallback);
        }
    },
    setTargetCards: newState => state => ({
        targetCards: newState,
    }),
    setCustomer: newState => state => ({
        customer: newState,
    }),
    setTimelineId: newState => state => ({
        timelineId: newState,
    }),
    setIsMobileOnboarding: newState => state => ({
        mobileOnboarding: newState,
    }),
    setUser: newState => state => ({
        user: newState,
    }),
    setError: newState => state => ({
        error: newState,
    }),
    setTotalCards: newState => state => ({
        totalCards: newState,
    }),
    nextCard: (force) => (state, actions) => {
        if (!force && !state.activeValidation) {
            actions.setError(t('Merci de renseigner une réponse.', {ns: 'onboarding'}));
            return false;
        } else if (state.formIsSubmitting) {
            actions.setError(t('Le formulaire est en cours de traitement.', {ns: 'onboarding'}));
            return false;
        }

        if (force) {
            // readedTypes
        }

        var els = document.querySelectorAll('.btzCurrentEvaluationMarker');
        if (!force) {
            // when we press "passer", the data must not be added, even if user select an answer
            let isValid = actions.pushValues(els);
            if (!isValid) {
                return false;
            }
        } else {
            if (+state.current.type !== 58) {
                state.readed.push(state.current.form.id);
            }
        }

        let thenClear = () => {
            for (var elem in els) {
                if (els[elem].classList !== undefined) {
                    els[elem].classList.remove('active');
                    els[elem].classList.remove('btzCurrentEvaluationMarker');
                }
            }
            let targetEl = document.querySelector('div.container.btzWrapper.btzWrapperFullHeight.btzVerticalAlign');
            if (targetEl) {
                // targetEl.scrollTop = 0;
                // window.scroll(0, 0);
                scrollTo(targetEl, 0, 360);
            }
        }

        if ((+state.idx + 1) >= Object.keys(state.steps).length) {
            let thenPostCardLine = () => {
                actions.sendCardline(state.current.idtimeline);
            }
            if ((isDefined(state.timelineId)) && !force) {
                actions.sendMedicalCardline({idtimeline: state.timelineId, thenClear: thenClear, thenPost: thenPostCardLine});
            } else {
                thenPostCardLine();
            }
        } else {
            if ((isDefined(state.timelineId)) && !force) {
                actions.sendMedicalCardline({idtimeline: state.timelineId, thenClear: thenClear, thenPost: null});
            } else {
                thenClear();
                actions.setUniformButtonHeight(0);
                actions.setIdx(state.idx + 1);
                actions.switchValidationActivation(false);
                actions.updateCurrentStep();
            }
        }

        actions.setError('');
    },
    sendCardline: (idtimeline) => (state, actions) => {
        state.activeEndSubmit = true;
        actions.setFormIsSubmitting(true);
        thenPostCardLine();

        function thenPostCardLine() {
            if (staticsconstants.CUSTOMERS[state.customer.toLowerCase()].customOnboardingEnd === true) {
                if (isDefined(state.user.profile) && (state.user.profile.seniority == 1)) {
                    actions.displayEndBoard(true);
                    thenRealyPostThisCardLine(true);
                } else {
                    thenRealyPostThisCardLine(false);
                }
            } else {
                thenRealyPostThisCardLine(false);
            }
        }

        function thenRealyPostThisCardLine(endboard) {
            if (state.mobileOnboarding) {
                actions.removeConfirmQuitEvent();
            }
            Cardline.postCardline(idtimeline, state.values, false).then((res) => {
                if (endboard === false) {
                    if ((res.data !== undefined) && (res.data.message && (res.data.message === 'ok'))) {
                        if (staticsconstants.CUSTOMERS[state.customer.toLowerCase()].modules.timeline === true) {
                            localStorage.setItem('onboarding-viewed-' + state.user.id + '-' + getTodayYMD(), true);
                            actions.redirectToTimeline();
                        } else {
                            state.activeEndSubmit = true;
                            window.location.href = addCSRFToken(allRoutes['private.endonboarding'].pathname);
                        }
                        return false;
                    } else {
                        actions.setFormIsSubmitting(false);
                    }
                }
            });
        }
    },
    sendMedicalCardline: ({idtimeline, thenClear, thenPost}) => (state, actions) => {
        actions.setFormIsSubmitting(true);
        let lastPushedValues = state.values[state.values.length - 1];
        Cardline.postMedicalCardline(idtimeline, lastPushedValues, false).then((res) => {
            if (isDefined(thenClear)) {
                thenClear();
            }
            actions.setFormIsSubmitting(false);
            //
            let optionalChildrenCards = res.data;
            // if we found child cards, we insert them at current index
            if (isDefined(optionalChildrenCards) && (optionalChildrenCards.length > 0)) {
                let childParsedData = actions.parseAndPushCards(optionalChildrenCards);
                let childCards = childParsedData.stateSteps;
                let numCardPrefix = state.current.numCard;
                for (let childCard in childCards) {
                    childCards[childCard].numCard = numCardPrefix + '.' + (isDefined(state.alpha[+childCard]) ? state.alpha[+childCard] : (+childCard + 1));
                }
                if (isDefined(childCards) && (childCards.length > 0)) {
                    let existingCards = state.steps;
                    const insert = (arr, index, ...newItems) => [
                        ...arr.slice(0, index),
                        ...newItems,
                        ...arr.slice(index)
                    ];
                    let newCardsList = insert(existingCards, (state.idx + 1), ...childCards);
                    actions.setSteps(newCardsList);
                }
            } else if (isDefined(thenPost)) {
                // for last medical card only,
                //  we must post it to verify if there is a child
                //   then, if there is no child, post the Cardline
                thenPost();
                return false;
            }
            //
            actions.setUniformButtonHeight(0);
            actions.setIdx(state.idx + 1);
            actions.switchValidationActivation(false);
            actions.updateCurrentStep();
        });
    },
    sendTimelineAnswer: () => (state, actions) => {
        if (!state.activeValidation) {
            actions.setError(t('Merci de renseigner une réponse.', {ns: 'onboarding'}));
            return false;
        } else if (state.formIsSubmitting) {
            actions.setError(t('Le formulaire est en cours de traitement.', {ns: 'onboarding'}));
            return false;
        }

        var els = document.querySelectorAll('.btzCurrentEvaluationMarker');
        state.values = [];
        els.forEach(function (el) {
            if (!isDefined(el.getAttribute('data-id')) && isDefined(el.lastChild)) {
                el = el.lastChild;
            }
            var id = el.getAttribute('data-id'),
                tag = el.getAttribute('data-tag'),
                val = el.getAttribute('data-value');
            if (!isDefined(val) && isDefined(el.value)) {
                val = el.value;
            }
            state.values.push({ tag: tag, value: val, id: id });
        });

        // send data
        setTimeout(() => {
            Cardline.postCardline(state.current.idtimeline, state.values, false).then(() => {
                for (var elem in els) {
                    if (els[elem].classList !== undefined) {
                        els[elem].classList.remove('active');
                        els[elem].classList.remove('btzCurrentEvaluationMarker');
                    }
                }
                actions.nextTimelineAnswer();
            });
        }, 200);
    },
    nextTimelineAnswer: () => (state, actions) => {
        if ((+state.idx + 1) >= Object.keys(state.steps).length) {
            if (state.lastanswercallback && (state.lastanswercallback !== null)) {
                state.lastanswercallback();
            }
        } else {
            state.values = [];
            actions.setIdx(state.idx + 1);
            actions.updateCurrentStep();
            actions.setFormIsSubmitting(false);
        }
    },
    selectThisAnswer: (el) => (state, actions) => {
        actions.setError('');
        if (el.target.getAttribute('data-kind') === 'qcm') {
            if (el.target.classList.contains('active')) {
                el.target.classList.remove('active');
                el.target.classList.remove('btzCurrentEvaluationMarker');
            } else {
                el.target.classList.add('active');
                el.target.classList.add('btzCurrentEvaluationMarker');
            }
        } else {
            var els = getClosest(el.target, '.btzOnboarding-btzButtonsWrapper').querySelectorAll('.btzOnBoarding-btzButton');
            for (var elem in els) {
                if (els[elem] && (els[elem].classList !== undefined)) {
                    els[elem].classList.remove('active');
                    els[elem].classList.remove('btzCurrentEvaluationMarker');
                }
            }
            if (!el.target.classList.contains('btzOnBoarding-btzButton')) {
                el = getClosest(el.target, '.btzOnBoarding-btzButton');
                el.classList.add('active');
                el.classList.add('btzCurrentEvaluationMarker');
            } else {
                el.target.classList.add('active');
                el.target.classList.add('btzCurrentEvaluationMarker');
            }
        }

        if (state.mobileOnboarding) {
            let targetEl = document.querySelector('div.container.btzWrapper.btzWrapperFullHeight.btzVerticalAlign');
            if (targetEl) {
                // targetEl.scrollTop = 1000;
                // targetEl.scroll(0, 1000);
                scrollTo(targetEl, targetEl.offsetHeight, 360);
            }
        }

        if (!state.activeValidation) {
            actions.switchValidationActivation(true);
        }
    },
    pushValues: (els) => (state, actions) => {
        let everythingOk = true;
        console.log('>>>> pushValues els : ', els);
        els.forEach(function (el) {
            if (!isDefined(el.getAttribute('data-id')) && isDefined(el.lastChild)) {
                el = el.lastChild;
            }
            var tag = el.getAttribute('data-tag'),
                val = el.getAttribute('data-value'),
                min = el.getAttribute('data-min'),
                max = el.getAttribute('data-max');
            //
            if (!isDefined(val) && isDefined(el.value)) {
                val = el.value;
            }
            if ((tag == '-1') && (val == '-1')) {
                // nothing, empty answer
            } else {
                let numericValuesToTest = false;
                console.log('>> numericValuesToTest : ', numericValuesToTest, tag);
                if ((tag === 'PERSONAL_WEIGHT') || (tag === 'CRYPTEDWEIGHT_VALUE')) {
                    numericValuesToTest = true;
                    let userMetrics = state.user.metrics;
                    let isValid = ((parseFloat(val) >= convertMetrics('metric', 'weight', userMetrics, constants.custo.METRICS.weight.min)) && (parseFloat(val) <= convertMetrics('metric', 'weight', userMetrics, constants.custo.METRICS.weight.max)));
                    if (!isValid || (val === '') || (val === null)) {
                        actions.setError(t('Veuillez renseigner un poids compris entre', {ns: 'onboarding'}) + ' ' + convertMetrics('metric', 'weight', userMetrics, constants.custo.METRICS.weight.min) + convertMetricsLabels(true, 'weight', userMetrics, 'kg') + ' ' + t('et', {ns: 'onboarding'}) + ' ' + convertMetrics('metric', 'weight', userMetrics, constants.custo.METRICS.weight.max) + convertMetricsLabels(true, 'weight', userMetrics, 'kg'))
                        everythingOk = false;
                    }
                } else if (tag === 'PERSONAL_HEIGHT') {
                    numericValuesToTest = true;
                    let userMetrics = (state.user.heightMetrics || state.user.metrics);
                    let isValid = ((parseFloat(val) >= convertMetrics('metric', 'height', userMetrics, constants.custo.METRICS.height.min)) && (parseFloat(val) <= convertMetrics('metric', 'height', userMetrics, constants.custo.METRICS.height.max)));
                    if (!isValid || (val === '') || (val === null)) {
                        actions.setError(t('Veuillez renseigner une taille comprise entre', {ns: 'onboarding'}) + ' ' + convertMetrics('metric', 'height', userMetrics, constants.custo.METRICS.height.min) + convertMetricsLabels(true, 'height', userMetrics, 'cm') + ' ' + t('et', {ns: 'onboarding'}) + ' ' + convertMetrics('metric', 'height', userMetrics, constants.custo.METRICS.height.max) + convertMetricsLabels(true, 'height', userMetrics, 'cm'))
                        everythingOk = false;
                    }
                } else if ((tag === 'PERSONAL_ABDOMINALPERIMETER') || (tag === 'PERSONAL_WAISTSIZE')) {
                    numericValuesToTest = true;
                    let userMetrics = (state.user.heightMetrics || state.user.metrics);
                    let isValid = ((parseFloat(val) >= convertMetrics('metric', 'abdo', userMetrics, constants.custo.METRICS.abdo.min)) && (parseFloat(val) <= convertMetrics('metric', 'abdo', userMetrics, constants.custo.METRICS.abdo.max)));
                    if (!isValid || (val === '') || (val === null)) {
                        actions.setError(t('Veuillez renseigner un tour de taille compris entre', {ns: 'onboarding'}) + ' ' + convertMetrics('metric', 'abdo', userMetrics, constants.custo.METRICS.abdo.min) + convertMetricsLabels(true, 'abdo', userMetrics, 'cm') + ' ' + t('et', {ns: 'onboarding'}) + ' ' + convertMetrics('metric', 'abdo', userMetrics, constants.custo.METRICS.abdo.max) + convertMetricsLabels(true, 'abdo', userMetrics, 'cm'))
                        everythingOk = false;
                    }
                }
                //
                if (isDefined(min) && isDefined(max)) {
                    numericValuesToTest = true;
                }
                //
                let valuePrecision = '';
                if (state.current.form.type === 'bloodpressure') {
                    if (tag === 'BLOODPRESSURE_SYSTOLIC') {
                        valuePrecision = t('pression systolique', {ns: 'onboarding'});
                    } else if (tag === 'BLOODRESSURE_DIASTOLIC') {
                        valuePrecision = t('pression diastolique', {ns: 'onboarding'});
                    }
                }
                if (isDefined(min) && (parseFloat(val) < parseFloat(min))) {
                    if (valuePrecision !== '') {
                        actions.setError(t('Veuillez renseigner une valeur', {ns: 'onboarding'}) + ' (' + valuePrecision + ') ' + t('supérieure à', {ns: 'onboarding'}) + ' ' + min)
                    } else {
                        actions.setError(t('Veuillez renseigner une valeur', {ns: 'onboarding'}) + ' ' + t('supérieure à', {ns: 'onboarding'}) + ' ' + min)
                    }
                    everythingOk = false;
                } else if (isDefined(max) && (parseFloat(val) > parseFloat(max))) {
                    if (valuePrecision !== '') {
                        actions.setError(t('Veuillez renseigner une valeur', {ns: 'onboarding'}) + ' (' + valuePrecision + ') ' + t('inférieure à', {ns: 'onboarding'}) + ' ' + max)
                    } else {
                        actions.setError(t('Veuillez renseigner une valeur', {ns: 'onboarding'}) + ' ' + t('inférieure à', {ns: 'onboarding'}) + ' ' + max)
                    }
                    everythingOk = false;
                }
                if (numericValuesToTest) { // ^\d+([.]\d)?$
                    console.log('TESTING : ', val.match(/\d+(\.\d+)?$/));
                    let isValidNumber = val.match(/^\d+([.]\d)?$/);
                    console.log('isValidNumber : ', isValidNumber);
                    if (isValidNumber === null) {
                        everythingOk = false;
                        actions.setError(t('Veuillez renseigner une valeur valide.', {ns: 'onboarding'}));
                    }
                }
            }
        });
        //
        if (everythingOk) {
            if (state.current.form && (state.current.form.type === 'qcm')) {
                // push all (others) key to false, mandatory for qcm
                actions.pushDefaultValues(state.current.original);
            }
            let dualValues = [];
            els.forEach(function (el) {
                if (!isDefined(el.getAttribute('data-id')) && isDefined(el.lastChild)) {
                    el = el.lastChild;
                }
                var id = el.getAttribute('data-id'),
                    tag = el.getAttribute('data-tag'),
                    val = el.getAttribute('data-value');
                console.log('pushValues el : ', el, id, tag, val);
                if (!isDefined(val) && isDefined(el.value)) {
                    val = el.value;
                }
                if ((tag == '-1') && (val == '-1')) {
                    // nothing, empty answer
                } else {
                    if (state.current.form.type === 'qcm') {
                        // find value by tag then replace it
                        for (var i = 0, len = state.values.length; i < len; i++) {
                            if (state.values[i] !== undefined) {
                                if (state.values[i].tag === tag) {
                                    state.values[i] = {tag: tag, value: val, id: id};
                                }
                            }
                        }
                    } else {
                        if (state.current.form.type === 'bloodpressure') {
                            dualValues.push({ tag: tag, value: parseFloat(val), id: id });
                        } else {
                            state.values.push({ tag: tag, value: val, id: id });
                        }
                    }
                }
            });
            if (dualValues.length > 0) {
                state.values.push(dualValues);
            }
        }
        return everythingOk;
    },
    redirectToTimeline: (force = false) => (state, actions) => {
        let followPregnancyTheme = (state.user.themes.indexOf(17) > -1), showPregnancyInputs = true;
        //
        if (!force && followPregnancyTheme) {
            if (isDefined(state.user.profile) && isDefined(state.user.profile.pregnancy) && isDefined(state.user.profile.pregnancy.delivery)) {
                showPregnancyInputs = false;
            }
        } else{
            showPregnancyInputs = false;
        }
        //
        // if (!force) {
        //     showPregnancyInputs = true; //
        // }
        //
        if (showPregnancyInputs) {
            actions.setShowPregnancyInputs(true);
        } else {
            window.location.href = addCSRFToken(allRoutes['private.timeline'].pathname);
        }
    },
    updatePregnancyKind: newState => state => ({
        showPregnancyInputsStep: newState,
    }),
    pushDefaultValues: (datas) => (state, actions) => {
        for (var dat in datas.forms) {
            state.values.push({ tag: datas.forms[dat].tag, value: false, id: datas.id });
        }
    },
    setValues: newState => state => ({
        values: { ...state.values, ...newState },
    }),
    updateButtonHeight: (el) => (state, actions) => {
        let newButtonHeight = el.clientHeight;
        if (+newButtonHeight > state.uniformButtonHeight) {
            actions.setUniformButtonHeight(newButtonHeight);
        }
    },
    setActiveEndSubmit: newState => state => ({
        activeEndSubmit: newState,
    }),
    setUniformButtonHeight: newState => state => ({
        uniformButtonHeight: newState,
    }),
    switchValidationActivation: (bool) => state => ({
        activeValidation: bool,
    }),
    updateCurrentStep: () => state => ({
        current: state.steps[state.idx],
        activeValidation: (isDefined(state.steps[state.idx]) ? ((['number', 'counter', 'qcm', 'cryptedweight', 'cryptedtemperature', 'bloodpressure', 'heartbeat'].indexOf(state.steps[state.idx].form.type) > -1) ? true : false) : false),
    }),
    setFormIsSubmitting: (active) => (state, actions) => ({
        formIsSubmitting: active
    }),
    setLastAnswerCallback: newState => state => ({
        lastanswercallback: newState,
    }),
    setSteps: newState => state => ({
        steps: newState,
    }),
    setIdx: newState => state => ({
        idx: newState,
    }),
    setSpecialInitialCount: newState => state => ({
        specialInitialCount: newState,
    }),
    displayEndBoard: newState => state => ({
        endBoard: newState,
    }),
    setLanguage: newState => state => ({
        language: newState,
    }),
    setShowPregnancyInputs: newState => state => ({
        showPregnancyInputs: newState,
    }),
    getTheme: (intTheme) => {
        for (var _t in constants.custo.THEMES) {
            if (constants.custo.THEMES[_t].key === intTheme) {
                return constants.custo.THEMES[_t].value;
            }
        }
    },
};

const view = dynLoad('view', 'Onboarding/views');

export default Component(state, actions, view, 'onboarding');
