import { t, isLength, debounce } from '@app/utils'
import { Session } from '@app/api';
import { isEmail } from '@app/core';

import * as staticsconstants from '@app/constants/constants-statics';

export const actions = {

    onComponentCreate: (props) => (state, actions) => {
        if (props.initial) {
            actions.getInitialStateAndUpdate(props.initial);
            if (props.initial.isGuest === true) {
                actions.userIsGuest(true);
            } else {
                actions.userIsGuest(false);
            }
        }
        if (props.send) {
            actions.setSendAsSendToParent(props.send);
        }
        if (props.customer) {
            actions.setCustomer(props.customer);
        }
    },

    getInitialStateAndUpdate: (initial) => (state, actions) => {
        for (var ntl in initial) {
            let found = false;
            for (var stt in state) {
                if (stt === ntl)
                    found = true;
            }
            if (!found)
                continue;

            actions.setState({
                ns: ntl,
                key: 'value',
                value: initial[ntl]
            });

            if ((initial[ntl] !== null) && (initial[ntl].length > 0)) {
                if (ntl === 'email') {
                    actions.setEmailValidity(isEmail(initial[ntl]))
                    actions.setEmailTouched(true);
                } else if (ntl === 'password') {
                    actions.setPasswordValidity(isLength(initial[ntl], { min: 8 }));
                    actions.setPasswordTouched(true);
                }
            }
        }
    },

    setApiErrors: newState => state => ({
        api: newState,
    }),

    setState: ({ ns, key, value }) => (state, actions) => ({
        [ns]: { ...state[ns], ...{ [key]: value } }
    }),

    setCustomer: newState => state => ({
        customer: newState,
    }),

    setSendAsSendToParent: newState => state => ({
        sendToParent: newState,
    }),

    setEmailValue: (string) => (state, actions) => actions.setState({
        ns: 'email',
        key: 'value',
        value: string
    }),

    setEmailTouched: (boolean) => (state, actions) => actions.setState({
        ns: 'email',
        key: 'isTouched',
        value: boolean
    }),

    setEmailValidity: (boolean) => (state, actions) => actions.setState({
        ns: 'email',
        key: 'isValid',
        value: boolean
    }),

    setEmailApproval: (boolean) => (state, actions) => actions.setState({
        ns: 'email',
        key: 'isApproved',
        value: boolean
    }),

    setPasswordValue: (string) => (state, actions) => actions.setState({
        ns: 'password',
        key: 'value',
        value: string
    }),

    setPasswordTouched: (boolean) => (state, actions) => actions.setState({
        ns: 'password',
        key: 'isTouched',
        value: boolean
    }),

    setPasswordValidity: (boolean) => (state, actions) => actions.setState({
        ns: 'password',
        key: 'isValid',
        value: boolean
    }),

    setPasswordApproved: (boolean) => (state, actions) => actions.setState({
        ns: 'password',
        key: 'isApproved',
        value: boolean
    }),

    setPasswordVisibility: (boolean) => (state, actions) => actions.setState({
        ns: 'password',
        key: 'isVisible',
        value: boolean
    }),

    setApiStatus: (number) => (state, actions) => actions.setState({
        ns: 'api',
        key: 'status',
        value: number
    }),

    setApiMessage: (string) => (state, actions) => actions.setState({
        ns: 'api',
        key: 'message',
        value: string
    }),

    setFormIsSubmitting: (active) => (state, actions) => ({
        formIsSubmitting: active
    }),

    userIsGuest: newState => (state) => ({
        isGuest: newState,
    }),

    onEmailInputUpdate: string => (state, actions) => {
        actions.setEmailValue(string)
        actions.setEmailValidity(isEmail(string))
    },

    onEmailInputDebouncedUpdate: (string) => (state, actions) => debounce(
        600,
        'onEmailInputUpdate',
        string
    ),

    onEmailInputTouched: () => (state, actions) => {
        actions.setApiErrors({
            'message': '',
            'kind': 'info',
        });
        if (!state.email.isTouched) {
            actions.setEmailTouched(true)
        }
    },

    onPasswordInputUpdate: string => (state, actions) => {
        actions.setPasswordValue(string)
        actions.setPasswordValidity(isLength(string, { min: 8 }))
    },

    onPasswordInputDebouncedUpdate: string => (state, actions) => debounce(
        600,
        'onPasswordInputUpdate',
        string
    ),

    onPasswordInputTouched: () => (state, actions) => {
        actions.setApiErrors({
            'message': '',
            'kind': 'info',
        });
        if (!state.password.isTouched) {
            actions.setPasswordTouched(true)
        }
    },

    onPasswordClickUpdate: () => (state, actions) => {
        actions.setPasswordVisibility(!state.password.isVisible);
    },

    isInputValid: ({ name, value }) => {
        switch (name) {
            case 'email':
                return isEmail(value);
            case 'password':
                return isLength(value, { min: 8 });
            default:
                throw Error(`${name} has no validator assigned`);
        }
    },

    onUpdate: ({ target: { name, value } }) => (state, actions) => {
        actions.setValues({ [name]: value });
        actions.setValidity({
            [name]: actions.isInputValid({ name, value }),
        });
    },

    onBlur: ({ target: { name } }) => (state, actions) => {
        actions.setApiErrors({
            'message': '',
            'kind': 'info',
        });
        actions.setTouched({ [name]: true });
    },

    goToNextStep: () => (state, actions) => {
        if (!staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()].modules.registerWorkflow.secure) {
        // if (['cerba', 'coronavirus', 'klesia_netflix'].indexOf(state.customer) > -1) {
            window.main.location.go('/home/register/pseudo');
        } else {
            window.main.location.go('/home/register/secure');
        }
    },

    updateParentProps: (props) => (state, actions) => {
        if (props) {
            props.send({
                ns: 'user',
                key: 'email',
                value: state.email.value
            })

            props.send({
                ns: 'user',
                key: 'password',
                value: state.password.value
            })
        }
    },

    onStepSubmit: (event) => (state, actions) => {
        event.preventDefault();

        if ((state.email.value === '') || (state.email.value === undefined)) {
            actions.setApiErrors({
                'message': t('Merci de saisir une adresse email.', {ns: 'register'}),
                'kind': 'error',
            });
        } else if (!state.email.isValid) {
            actions.setApiErrors({
                'message': t('Merci de renseigner une adresse email valide.', {ns: 'register'}),
                'kind': 'error',
            });
        } else if (state.password.value === '') {
            actions.setApiErrors({
                'message': t('Merci de saisir le mot de passe de votre choix.', {ns: 'register'}),
                'kind': 'error',
            });
        } else if (!state.password.isValid) {
            actions.setApiErrors({
                'message': t('Veuillez renseigner un mot de passe composé de 8 caractères minimum.', {ns: 'register'}),
                'kind': 'error',
            });
        }

        if (state.email.isValid && state.password.isValid) {
            actions.setFormIsSubmitting(true);
            Session.checkEmail(state.email.value)
                .then(({ data }) => {
                    actions.setFormIsSubmitting(false);
                    if ((data.message === 'ok') && (staticsconstants.CUSTOMERS_COMPANY_PROVISIONNING.indexOf(state.customer) === -1)) {
                        if (data.sponsor && (data.sponsor !== null) && (data.sponsor !== '') && (data.customer && (data.customer !== null))) {
                            state.sendToParent({
                                ns: 'user',
                                key: 'isGuest',
                                value: true
                            });
                            state.sendToParent({
                                ns: 'user',
                                key: 'type',
                                value: (data.customer.mutual === true) ? 'mutual' : 'company',
                            });
                            state.sendToParent({
                                ns: 'user',
                                key: 'email',
                                value: state.email.value
                            });
                            state.sendToParent({
                                ns: 'user',
                                key: (data.customer.mutual === true) ? 'mutual' : 'company',
                                value: (data.customer.mutual === true) ? data.customer.name : data.customer.inviteCode,
                            });
                            state.sendToParent({
                                ns: 'user',
                                key: (data.customer.mutual === true) ? 'mutualId' : 'companyId',
                                value: data.customer.id
                            });
                            actions.setEmailApproval(true);
                            actions.goToNextStep();
                        } else {
                            actions.setApiErrors({
                                'message': t('Cette adresse email est déjà enregistrée. Connectez-vous pour accéder au service.', {ns: 'register'}),
                                'kind': 'error',
                            });
                            actions.setEmailApproval(false);
                            return;
                        }
                    } else if (data.redirect) {
                        // send to parent 'account type is company'
                        window.location.href = data.redirect;
                    } else {
                        if (staticsconstants.CUSTOMERS_COMPANY_PROVISIONNING.indexOf(state.customer) > -1) {
                            if (data.message === 'ok') {
                                actions.setEmailApproval(true);
                                actions.goToNextStep();
                            } else {
                                actions.setApiErrors({
                                    'message': t('Veuillez renseigner une adresse email provisionnée.', {ns: 'register'}),
                                    'kind': 'error',
                                });
                                actions.setEmailApproval(false);
                                return;
                            }
                        } else {
                            actions.setEmailApproval(true);
                            actions.goToNextStep();
                        }
                    }
                })
        }

    },

};
