import { getQueryVariable, isEmail } from '@app/core';
import { allRoutes } from '@app/settings';
import { t, debounce } from '@app/utils'
import { Session } from '@app/api'

export const actions = {

    onComponentCreate: (props) => (state, actions) => {
        if (props.initial.value) {
            actions.getInitialStateAndUpdate(props.initial.value)
        }
        if (props.send) {
            state.sendToParent = props.send;
        }

        let email = getQueryVariable('email', false);
        if ((email !== '') && (email !== null) && (email !== undefined) && (email !== false)) {
            actions.getInitialStateAndUpdate(email);
        }
    },

    onComponentUpdate: (props) => (state, actions) => {
        actions.setApiErrors({
            'message' : t('Renseignez l’adresse email liée à votre', {ns: 'login'}) + '\n' + t('compte, vous recevrez un email pour', {ns: 'login'}) + '\n' + t('réinitialiser votre mot de passe.', {ns: 'login'}),
            'kind' : 'info',
        });
    },

    getInitialStateAndUpdate: (string) => (state, actions) => {
        actions.onEmailInputUpdate(string)
        actions.onEmailInputTouched(true);
    },

    setApiErrors: newState => state => ({
        api: newState,
    }),

    setState: ({ ns, key, value }) => (state, actions) => ({
        [ns]: { ...state[ns], ...{ [key]: value } }
    }),

    setEmailValue: (string) => (state, actions) => actions.setState({
        ns: 'email',
        key: 'value',
        value: string
    }),

    setEmailTouched: (boolean) => (state, actions) => actions.setState({
        ns: 'email',
        key: 'isTouched',
        value: boolean
    }),

    setEmailValidity: (boolean) => (state, actions) => actions.setState({
        ns: 'email',
        key: 'isValid',
        value: boolean
    }),

    setEmailApproval: (boolean) => (state, actions) => actions.setState({
        ns: 'email',
        key: 'isApproved',
        value: boolean
    }),

    setApiStatus: (string) => (state, actions) => actions.setState({
        ns: 'api',
        key: 'status',
        value: string
    }),

    setFormIsSubmitting: (active) => (state, actions) => ({
        formIsSubmitting: active
    }),

    onEmailInputUpdate: (string) => (state, actions) => {
        actions.setEmailValue(string)
        actions.setEmailValidity(isEmail(string))
    },

    onEmailInputDebouncedUpdate: (string) => (state, actions) => debounce(
        600,
        'onEmailInputUpdate',
        string
    ),

    onEmailInputTouched: () => (state, actions) => {
        actions.setApiErrors({
            message: t('Renseignez l’adresse email liée à votre', {ns: 'login'}) + '\n' + t('compte, vous recevrez un email pour', {ns: 'login'}) + '\n' + t('réinitialiser votre mot de passe.', {ns: 'login'}),
            kind: 'info'
        });
        if (!state.email.isTouched) {
            actions.setEmailTouched(true)
        }
    },

    callApiToResetEmail: () => (state, actions) => {
        if (state.email.value === '') {
            actions.setApiErrors({
                message: t('Merci de renseigner une adresse email.', {ns: 'login'}),
                kind : 'error',
            });
            return false;
        }

        if (!state.email.isValid) {
            actions.setApiErrors({
                message: t('Votre adresse email n‘est pas reconnue', {ns: 'login'}),
                kind : 'error',
            });
            return false;
        }

        actions.setApiErrors({
            'message' : t('Renseignez l’adresse email liée à votre', {ns: 'login'}) + '\n' + t('compte, vous recevrez un email pour', {ns: 'login'}) + '\n' + t('réinitialiser votre mot de passe.', {ns: 'login'}),
            'kind' : 'info',
        });

        if (state.email.isValid) {
            actions.setFormIsSubmitting(true);
            Session.resetSession(state.email.value)
                .then(({ data }) => {
                    if (data.message === 'ok') {
                        actions.displaySuccessMessage(true);
                        setTimeout(function() {
                            window.location.href = allRoutes['home.login'].pathname;
                        }, 2500);
                    } else {
                        actions.setApiErrors({
                            message: t('Utilisateur introuvable.', {ns: 'login'}),
                            kind : 'error',
                        });
                        actions.setFormIsSubmitting(false);
                    }
                    return;
                })
                .catch(() => {

                })
        }
    },

    onStepSubmit: () => (state, actions) => {
        actions.callApiToResetEmail();
    },

    displaySuccessMessage: newState => state => ({
        messageActive: newState,
    }),
}
