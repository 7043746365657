import { t, h, Component } from '@app/utils';
import { Data } from '@app/api';
import { Tile, Balloon, Form, Button, Avatar, Counter } from '@app/elements';
import './index.scss';

// import ImgShowMore from './social/soc-more.png';
// import ImgShowMorex2 from './social/soc-more@2x.png';

const DeniveleForm = Component(
    {
        question: 'Combien d’étages avez-vous gravis ?',
        i18nquestion: t('Combien d’étages avez-vous gravis ?', {ns: 'datas'}),
        form: {
            type: 'number',
            tag: 'ACTIVITY_FLOORS',
            values: null,
        },
        source: 'betterise',
        date: null,
        readableDate: null,
        help: 'Saisissez le nombre d‘étages.',
        i18nhelp: t('Saisissez le nombre d‘étages.', {ns: 'datas'}),
        error: '',
        activeValidation: false,
        formIsSubmitting: false,
        values: [],
        defaultValue: null,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.date) {
                actions.setReadableDate(actions.getDate(props.date));
                actions.setDate(actions.createDateObj(props.date));
                // state.form.date
            }
            if (props.defaultValue) {
                actions.setDefaultValue(props.defaultValue);
            }
        },
        onComponentUpdate: (props) => (state) => {

        },
        switchValidationActivation: () => state => ({
            activeValidation: !state.activeValidation,
        }),
        updateValidity: () => (state, actions) => {
            if (!state.activeValidation) {
                actions.switchValidationActivation(true);
            }
        },
        sendData: (props) => (state, actions) => {
            var parent = document.querySelectorAll('.btzDashboards-btzDashboardWrapper#' + props.theme)[0];
            var els = parent.querySelectorAll('.btzCurrentEvaluationMarker');
            actions.pushValues(els);

            if (state.values.length === 0) {
                actions.setError(t('Veuillez remplir le formulaire.', {ns: 'generals'}));
            } else {
                actions.setFormIsSubmitting(true);
                if (+state.values[0].value >= 0) {
                    //
                } else {
                    state.values[0].value = 0;
                }
                Data.postData(state.values).then(function() {
                    props.updateDataViz(props);
                    props.cancelAction();
                    actions.setFormIsSubmitting(false);
                });
            }
        },
        setError: newState => state => ({
            error: newState,
        }),
        setFormIsSubmitting: (active) => (state, actions) => ({
            formIsSubmitting: active
        }),
        setReadableDate: newState => state => ({
            readableDate: newState
        }),
        setDate: newState => state => ({
            date: newState
        }),
        setDefaultValue: newState => state => ({
            defaultValue: newState
        }),
        createDateObj: (date) => (state, actions) => {
            var parsedDate = {
                date: new Date(date.substr(0, 4) + '-' + date.substr(4, 2) + '-' + date.substr(6, 2)),
                timezone: 'UTC',
                timezone_type: 3
            }

            return parsedDate;
        },
        pushValues: (els) => (state, actions) => {
            state.values = [];
            els.forEach(function (el) {
                if (el.getAttribute('data-id') === null) {
                    el = el.lastChild;
                }
                var tag = el.getAttribute('data-tag'),
                    val = el.getAttribute('data-value'),
                    date = state.date,
                    source = state.source;

                // find value by id then replace it
                for (var i = 0, len = state.values.length; i < len; i++) {
                    if (state.values[i] !== undefined) {
                        if (state.values[i].tag === tag) {
                            state.values.splice((i + 0), 1);
                        }
                    }
                }
                state.values.push({ tag: tag, value: val, date: date, source: source });
            });
        },
        getDate: (dat) => {
            var date = new Date(Date.parse(new Date(dat.substr(0, 4) + '-' + dat.substr(4, 2) + '-' + dat.substr(6, 2))));
            var options = { month: 'long', day: 'numeric', year: 'numeric' };
            return date.toLocaleDateString('fr-FR', options);
        }
    },
    (state, actions) => (props, children) => (
        <div class='btzDashboard-btzDashboardForm' id={props.id} idtimeline={props.idtimeline} idfavorite={props.idfavorite} data-id={props.did} data-tag={props.tag} data-value={state.count} data-kind={state.kind}>
            <div style={{ 'position': 'relative', 'height': '100%', 'display': 'table', 'width': '100%' }}>
                <Tile>
                    <div class='btzTitle-btzImage btzTitle-btzImage-absolute'>
                        <p class='btzSubTitle'>{state.readableDate}</p>
                        <p class='btzTitle btzXl'>{t(state.question)}</p>
                    </div>
                    <Tile.Content classes='btzLeftHrArrowedOnboarding' style={{ 'text-align': 'center' }}>
                        {state.form && (
                            <div>
                                {state.form.type == 'number' && (
                                    <div class='btzOnboarding-btzButtonsWrapper btzOnboarding-btzButtonsWrapper-number'>
                                        <Counter class='btzCurrentEvaluationMarker' min={0} max={1000} count={state.defaultValue} did={'single'} kind='number' tag={state.form.tag} label={t('étage(s)', {ns: 'datas'})} oninput={actions.updateValidity}></Counter>
                                    </div>
                                )}
                            </div>
                        )}
                    </Tile.Content>
                </Tile>

                <Form onsubmit={() => null} style={{ 'min-height': 0 }}>
                    <Form.Group classes='btzForm-btzFooter'>
                        <Button primary small loading={(state.formIsSubmitting)} onclick={() => { if (!state.formIsSubmitting) { props.cancelAction(); } }} >
                            {t('Annuler', {ns: 'generals'})}
                        </Button>

                        <Button primary active loading={(state.formIsSubmitting)} onclick={() => {
                            if (!state.formIsSubmitting) {
                                actions.sendData(props);
                            }
                        }} >
                            {t('Valider', {ns: 'generals'})}
                        </Button>
                    </Form.Group>
                </Form>

                <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap', 'margin-top': '50px' }}>
                    <Tile.Icon classes={''}>
                        <Avatar.Alfred classes={'centered'} />
                    </Tile.Icon>
                    <Tile.Content>
                        <Balloon kind={state.error !== '' ? 'error' : 'info'} title={t('QUESTION', {ns: 'datas'})} style={{ 'white-space': 'normal' }}>{(state.error !== '' ? state.error : t(state.help))}</Balloon>
                    </Tile.Content>
                </Tile>
            </div>
        </div>
    ),
    'deniveleform'
);

export { DeniveleForm };
