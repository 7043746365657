import { h, Component } from '@app/utils';

import About from './about';
import Developer from './developer';
import Enterprise from './enterprise';
import Home from './home';
import Scientific from './scientific';
import Technology from './technology';

import './index.scss';

const view = (state, actions) => (props, children) => (
    <div>
        {children}
    </div>
);

export {
    About,
    Developer,
    Enterprise,
    Home,
    Scientific,
    Technology
};

// used for lazy loading
export default Component({}, {}, view, 'home');