import { addCSRFToken } from '@app/core';
import { allRoutes } from '@app/settings';
import { Programs } from '@app/api';
import { t, h, Component } from '@app/utils';
import { Tile, Avatar, Balloon, Button, Link } from '@app/elements';
import './index.scss';

const state = {
    noSubmit: false,
    values: null,
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        if (props.noSubmit) {
            state.noSubmit = props.noSubmit;
        }
        if (props.values) {
            state.values = props.values;
        }
    },
    onComponentUpdate: (props) => (state, actions) => {
        if (props.noSubmit) {
            state.noSubmit = props.noSubmit;
        }
        if (props.values) {
            state.values = props.values;
        }
    },
    endTheseSession: (programid) => (state, actions) => {
        if (state.noSubmit) {
            window.location.reload(true);
        } else {
            Programs.finishProgramSession(programid, state.values).then(function(res) {
                if (res.data.nextSessionDate !== undefined) {
                    window.location.href = addCSRFToken(allRoutes['private.programs'].pathname);
                } else {
                    //
                }
            });
        }
    },
    notEndedTheseSession: () => (state, actions) => {
        window.location.href = addCSRFToken(allRoutes['private.programs'].pathname);
    },
};

const view = (state, actions) => (props, children) => (
    <div>
        {props.displayedon == 'session' &&
            <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap' }} id={'ended'} displayedon='session'>
                <Tile.Icon>
                    <Avatar.Alfred classes={'centered'} />
                </Tile.Icon>
                <Tile.Content>
                    <Balloon title={t('Séance', {ns: 'programs'}) + ' ' + props.seanceNumber + ' ' + t('terminée', {ns: 'programs'})} style={{ 'white-space': 'normal' }}>{t('Avez-vous terminé votre séance ?', {ns: 'programs'})}</Balloon>
                </Tile.Content>

                <div class='' style={{ 'display': 'block', 'text-align': 'right', 'margin': '15px 0' }}>
                    <Button primary block onclick={() => { actions.notEndedTheseSession() }}>
                        {t('Non', {ns: 'generals'})}
                    </Button>
                    <Button primary block active onclick={() => { actions.endTheseSession(props.programid) }}>
                        {t('Oui j‘ai terminé', {ns: 'programs'})}
                    </Button>
                </div>
            </Tile>
        }

        {props.displayedon == 'summaryLikeSession' &&
            <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap' }} id={'ended'} displayedon='session'>
                <Tile.Icon>
                    <Avatar.Alfred classes={'centered'} />
                </Tile.Icon>
                <Tile.Content>
                    <Balloon title={t('Séance', {ns: 'programs'}) + ' ' + props.seanceNumber + ' ' + t('terminée', {ns: 'programs'})} style={{ 'white-space': 'normal' }}>{t('Avez-vous terminé votre séance ?', {ns: 'programs'})}</Balloon>
                </Tile.Content>

                <div class='' style={{ 'display': 'block', 'text-align': 'right', 'margin': '15px 0' }}>
                    <Button primary block onclick={() => { actions.notEndedTheseSession() }}>
                        {t('Non', {ns: 'generals'})}
                    </Button>
                    <Button primary block active onclick={() => { actions.endTheseSession(props.programid) }}>
                        {t('Oui', {ns: 'generals'})}
                    </Button>
                </div>
            </Tile>
        }

        {props.displayedon == 'summary' &&
            <div class='btzProgram-btzLeftMenu-btzEntry'><Link scrollto={'ended'} offsetHeader={100}>{t('Séance terminée', {ns: 'programs'})}</Link></div>
        }
    </div>
);

export default Component(state, actions, view, 'ended');
