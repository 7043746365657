import { t, h } from '@app/utils';
import { Button, Form, Tile, Avatar, Balloon } from '@app/elements';

import logoResiliate from '@app/img/public/resiliate/you-are-resiliate.png';
import logoResiliatex2 from '@app/img/public/resiliate/you-are-resiliate@2x.png';

import PublicLayout from '@app/layouts/public/layout';

export const view = (state, actions) => (props, children) => (
    <div key='publicresiliate'>
        <PublicLayout page='resiliate' customer={props.customer}>
            <div class='publicresiliate-resiliate'>
                <div style={{ position: 'relative' }}>
                    <Tile>
                        <div class='btzTitle-btzImage btzTitle-btzImage-absolute col-md-12 col-4'>
                            <img src={logoResiliate} srcset={`${logoResiliatex2} 2x`} alt='' class='' />
                        </div>
                        <Tile.Content classes='btzLeftHrArrowed col-xs-12 col-8'>
                            <p class='btzTitle btzXl'>{t('Vous êtes sur le point de résilier votre compte', {ns: 'public'})}</p>
                            <p class='btzTexte'>{t('Vous souhaitez vraiment nous quitter ? Cela nous rend bien tristes.', {ns: 'public'})}</p>

                            <p class='btzTexte'>{t('Sachez que la résiliation de votre compte entraînera la suppression complète et immédiate de l’ensemble de votre compte - vous n’aurez plus accès à vos données.', {ns: 'public'})}</p>

                            {state.resiliateFormDisplay === false ?
                                <Button flat dangerous onclick={() => {actions.displayResiliateForm()}}>{t('Résilier', {ns: 'public'})}</Button>
                                :
                                <div>
                                    <p class='btzTexte'>{t('Confirmez votre identité en renseignant votre mot de passe :', {ns: 'public'})}</p>
                                    <Form>
                                        <Form.Group>
                                            <Form.Input
                                                placeholder={t('votre mot de passe', {ns: 'public'})}
                                                type='password'
                                                id='password'
                                                name='password'
                                                oninput={actions.waitForLastInput}
                                                onfocus={actions.onBlur}
                                                classes={(state.password.isTouched) ? (state.password.isValid ? 'is-success' : 'is-error') : ''}
                                            />
                                        </Form.Group>

                                        <Button flat dangerous onclick={() => {actions.sendResiliation()}}>{t('Valider', {ns: 'generals'})}</Button>
                                    </Form>
                                </div>
                            }

                            <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap' }}>
                                <Tile.Icon>
                                    <Avatar.Alfred classes={'centered'} />
                                </Tile.Icon>
                                <Tile.Content>
                                    <Balloon kind={state.api.kind} style={{ 'white-space': 'normal' }}>
                                        {state.api.message}
                                    </Balloon>
                                </Tile.Content>
                            </Tile>
                        </Tile.Content>
                    </Tile>
                </div>
            </div>
        </PublicLayout>
    </div>

);