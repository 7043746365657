import { h, Component } from '@app/utils';
import { Card } from '@app/elements';
import './index.scss';

const state = {

}

const actions = {
    onComponentCreate: () => (state, actions) => {

    }
};

const view = (state, actions) => (props, children) => (
    <Card.Image class='btzCard-btzImageColored' data-theme={props.valueTheme} style={{ 'height': (props.firstofblock && (props.firstofblock === true)) ? '410px' : '270px' }} >
        <div class='btzCard-btzImageColored-btzContentWrapper'>
            <p class='btzCard-btzImageColored-btzContent'>{props.title}</p>
            <p class='btzCard-btzImageColored-btzContent' style={{ 'opacity': '0.8' }}>Coaching</p>
            <p class='btzCard-btzImageColored-btzContent' style={{ 'opacity': '0.6' }}>演习</p>
            <p class='btzCard-btzImageColored-btzContent' style={{ 'opacity': '0.4' }}>осуществление</p>
        </div>
    </Card.Image>
);

export default Component(state, actions, view, 'default');
