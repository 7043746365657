import { fetch } from 'node-fetch';
import { Session } from '.';

beforeEach(() => {
    global.fetch = fetch;
    global.Headers = fetch.Headers;
    global.Request = fetch.Request;
    global.Response = fetch.Response;
});

describe('checkEmail', () => {
    it('should should return ok as a response', () => {
        const account = {
            email: 'admin@betterise.me',
        };

        Session.checkEmail(account.email).then((response) => {
            expect(response.message).toBe('ok');
        });
    });
});

describe('createSession', () => {
    it('should contain tokens, refresh and expiration properties', () => {
        const account = {
            email: 'admin@betterise.me',
            password: 'mangemamerde!',
        };

        Session.createSession(account.email, account.password).then((response) => {
            expect(response)
                .toHaveProperty('token')
                .toHaveProperty('refresh')
                .toHaveProperty('expiration');
        });
    });
});

describe('refreshSession', () => {
    it('should contain tokens, refresh and expiration properties', () => {
        const account = {
            email: 'admin@betterise.me',
            password: 'mangemamerde!',
        };

        let savedResponse = null;

        Session.createSession(account.email, account.password).then((response) => {
            savedResponse = response;
        });

        Session.refreshSession(savedResponse.token, savedResponse.refresh).then((response) => {
            expect(response)
                .toHaveProperty('token')
                .toHaveProperty('refresh')
                .toHaveProperty('expiration');
        });
    });
});
