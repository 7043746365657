export const state = {
    email: {
        value: '',
        isTouched: false,
        isValid: false,
        isApproved: null,
    },
    password: {
        value: '',
        isTouched: false,
        isValid: false,
        isApproved: null,
        isVisible: false,
    },
    api: {
        message: '',
        kind: 'info',
    },
    formIsSubmitting: false,
    sendToParent: null,
    isGuest: false,
    customer: null,
};
