import { t, h, Component } from '@app/utils';
import { Card, Link, Convert } from '@app/elements';
import './index.scss';

const state = {
    program: null,
}

const actions = {
    onComponentCreate: () => (state, actions) => {

    }
};

const view = (state, actions) => (props, children) => (
    <div>
        {props.displayedon == 'session' &&
            <Card kind={'objectif'} id={'objectif-' + props.id}>
                <div>
                    <Card.Header>
                        {((props.mediaUrl !== undefined) && (props.mediaUrl !== null)) &&
                            <Card.Image class='btzCard-btzImageMedia'>
                                <Card.Media src={props.mediaUrl}></Card.Media>
                            </Card.Image>
                        }
                        <div class='' style={{ 'margin': '22px' }}>
                            {((props.coach !== undefined) && (props.coach.media !== null) && (props.coach.media.url !== undefined)) &&
                                <img style={{ 'display': 'inline-block', 'vertical-align': 'middle', 'width': '65px' }} src={props.coach.media.url} alt='' class='btzCoachWord' />
                            }
                            <div class='' style={{ 'display': 'inline-block', 'vertical-align': 'middle' }}>
                                <p class='btzTimeline-btzCard-btzTitle btzTitle btzXl' style={{ 'margin-top': '0px' }}>{props.title}</p>
                                <p class='btzTimeline-btzCard-btzSubTitle btzSubTitle btzTextColored' data-theme={props.theme}>{t('Mot du coach', {ns: 'programs'})}</p>
                            </div>
                        </div>
                    </Card.Header>

                    <Card.Body classes={'btzTopCustomDotted'}>
                        <p class='btzTimeline-btzCard-btzDescription btzTexte' innerHTML={<Convert separator={''} is_xhtml={true} type='nl2br'>{props.description}</Convert>}></p>
                    </Card.Body>
                </div>
            </Card>
        }

        {props.displayedon == 'summary' &&
            <div class='btzProgram-btzLeftMenu-btzEntry' data-theme={props.theme}><Link scrollto={'objectif-' + props.id} offsetHeader={100}>{t('Mot du coach', {ns: 'programs'})}</Link></div>
        }
    </div>
);

export default Component(state, actions, view, 'objectif');
