import { allRoutes } from '@app/settings';
import { addCSRFToken } from './csrf';
import { tryToGetUser } from './getUser';

const tryToLoginAndRedirect = function() {
    var cb = function(res) {
        if (res && (res.id !== undefined)) {
            window.location.href = addCSRFToken(allRoutes['private.timeline'].pathname);
        }
    };
    tryToGetUser(cb, true);
}

export { tryToLoginAndRedirect };