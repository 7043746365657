import { t } from '@app/utils'
import { Cardline } from '@app/api';

export const actions = {
    onComponentCreate: (props) => (state, actions) => {
        state.savedItem = props;
        state.favorite = (props.favorite || false);
    },
    setDetailAliment: newState => state => ({
        detailAliment: newState,
    }),
    displayAlimentDetail: displayed => state => ({
        alimentDetailDisplayed: displayed,
    }),
    setTag: newState => state => ({
        tagaliment: newState,
    }),
    setModalDisplayed: newState => state => ({
        modalDisplayed: newState,
    }),
    setKindOfInfos: newState => state => ({
        kindOfInfos: newState,
    }),
    getDetailArticle: (event) => (state, actions) => {
        event.preventDefault();
        if ((state.detail === null) || (state.detail === undefined)) {
            Cardline.getArticle(state.savedItem.article.id).then((res) => {
                var card = res.data;

                card.favoriteId = state.savedItem.favoriteId;
                card.timeline = state.savedItem.timeline;
                card.favorite = state.savedItem.favorite;

                actions.setDetail(card);
                actions.setModalDisplayed(true);
                actions.rendering();
            });
        }
    },
    getDate: ({el, dat}) => {
        if (dat === null) {
            el.innerHTML = 'Ø';
            return false;
        }
        dat = dat.replace(/-/g, '/');
        dat = dat.split(' ')[0];
        var date = new Date(Date.parse(new Date(dat)));
        var options = { month: 'long', day: 'numeric', year: 'numeric' };
        el.innerHTML = (t('Ajouté le', {ns: 'generals'}) + ' : ' + date.toLocaleDateString('fr-FR', options));
    },
    updateParentProps: (favoriteid) => (state, actions) => {
        state.savedItem.favoriteId = favoriteid;
        actions.setFavorisStatus();
        state.savedItem.favorite = state.favorite;
    },
    setFavorisStatus: () => state => ({
        favorite: !state.favorite
    }),
    setDetail: newState => state => ({
        detail: newState,
    }),
    rendering: () => state => ({
        rendering: !state.rendering,
    }),
};
