const isDefined = function(variable, isLength = false, isEmpty = false) {
    if ((variable !== null) && (variable !== 'null') && (variable !== undefined) && (variable !== 'undefined') && (!isLength || ((isLength) && ((Array.isArray(variable)) && (variable.length > 0)) || !Array.isArray(variable)))) {
        if (!isEmpty || (isEmpty && (variable !== ''))) {
            return true;
        }
        return false
    }
    return false
}

export { isDefined };