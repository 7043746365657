import { h, isLength } from '@app/utils';

export const actions = {
    onComponentCreate: (props) => (state, actions) => {
        console.log('REGISTER > sex : is guest > ', props.initial);
        if (props.initial) {
            if (props.initial.isGuest !== null) {
                actions.setIsGuest(props.initial.isGuest);
            }
        }
        if (props.customer) {
            actions.setCustomer(props.customer);
        }
        if (props.displayed) {
            actions.setDisplayed(props.displayed);
        }
        if (props.updateKind) {
            actions.setUpdateKind(props.updateKind);
        }
    },

    setState: ({ ns, key, value }) => (state, actions) => ({
        [ns]: { ...state[ns], ...{ [key]: value } }
    }),

    setPregnancyValue: string => (state, actions) => actions.setState({
        ns: 'pregnancy',
        key: 'value',
        value: string
    }),

    setIsGuest: newState => (state) => ({
        isGuest: newState,
    }),
    setCustomer: newState => (state) => ({
        customer: newState,
    }),
    setDisplayed: newState => (state) => ({
        displayed: newState,
    }),
    setUpdateKind: newState => (state) => ({
        updateKind: newState,
    }),

    onSAClick: () => (state, actions) => {
        actions.setPregnancyValue('sa');
        actions.goToNextStep('sa');
    },

    onDDRClick: () => (state, actions) => {
        actions.setPregnancyValue('ddr');
        actions.goToNextStep('ddr');
    },

    goToNextStep: (kind) => (state, actions) => {
        if (state.displayed === 'register') {
            window.main.location.go('/home/register/pregnancy/kind?kind=' + kind);
        } else if (state.displayed === 'onboarding') {
            state.updateKind(kind);
        }
    },
};
