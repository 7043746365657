import { addCSRFToken, isDefined, getUser } from '@app/core';
import { allRoutes } from '@app/settings';
import { Programs } from '@app/api';
import { t, h, Component } from '@app/utils';
import { Button, Convert } from '@app/elements';
import './index.scss';
import * as constants from '@app/constants';

import PageLayout from '@app/layouts/page/splitted/program/session/layout';

import icoFrequency from '@app/img/ico/ico-frequency.png';
import icoFrequencyx2 from '@app/img/ico/ico-frequency@2x.png';

import icoMaterial from '@app/img/ico/ico-material.png';
import icoMaterialx2 from '@app/img/ico/ico-material@2x.png';

const state = {
    program: null,
    userMedia: null,
    programAlreadyStarted: false,
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        if (performance.navigation.type && (performance.navigation.type === 2)) {
            location.reload(true);
            return false;
        }

        if (isDefined(props.displayedon) && (props.displayedon === 'preview')) {
            /*var cardTheme = actions.getTheme(card.theme);
            card.valueTheme = cardTheme.value;
            card.labelTheme = cardTheme.label;*/
            //
            actions.setProgram({...props});
            return  false;
        }

        let programid = props.match.params.programid;
        if (!programid) {
            return false;
        }

        Programs.getRunning().then(function(programs) {
            for (var prog in programs.data) {
                if (programs.data[prog].program.id === programid) {
                    actions.setProgramAlreadyStarted(true);
                    // window.location.href = addCSRFToken(allRoutes['private.program.session'].pathname.replace(':programid', programid));
                    // return false;
                }
            }

            let userCallback = (sessionUser) => {
                if (isDefined(sessionUser.media)) {
                    actions.setUserMedia(sessionUser.media.url);
                }
            };
            getUser(userCallback);

            Programs.getProgram(programid).then(function(res) {
                var card =  null;
                card = res.data;
                var cardTheme = actions.getTheme(card.theme);

                card.valueTheme = cardTheme.value;
                card.labelTheme = cardTheme.label;

                actions.setProgram(card);
            });
        });
    },
    setUserMedia: newState => state => ({
        userMedia: newState,
    }),
    beginTheseProgram: (programid) => (state, actions) => {
        Programs.finishProgramSession(programid, {}).then(function() {
            window.location.href = addCSRFToken(allRoutes['private.program.session'].pathname.replace(':programid', programid));
        });
    },
    goToFirstSession: (programid) => (state, actions) => {
        window.location.href = addCSRFToken(allRoutes['private.program.session'].pathname.replace(':programid', programid));
    },
    setProgram: newState => state => ({
        program: newState
    }),
    setProgramAlreadyStarted: newState => state => ({
        programAlreadyStarted: newState
    }),
    getTheme: (intTheme) => {
        for (var _t in constants.custo.THEMES) {
            if (constants.custo.THEMES[_t].key === intTheme) {
                return constants.custo.THEMES[_t];
            }
        }
        return {value : '', label : ''};
    },
};

const view = (state, actions) => (props, children) => (
    <PageLayout page='programs' readablePageLabel={t('Vos programmes de coaching', {ns: 'programs'})} readablePageDescription={t('Pour vous aider à atteindre vos objectifs, jour après jour', {ns: 'programs'})} userMedia={state.userMedia} customer={props.customer}>
        {state.program !== null &&
            <div class='btzSimplePageBlock'>
                <div class='col-7 col-sm-12' style={{ 'vertical-align': 'top' }}>
                    <p class='btzTitle btzXl'>{state.program.title}</p>
                    <div class=''>
                        <p class='btzPageContentSubTitle btzTextColored' data-theme={state.program.valueTheme}>{state.program.labelTheme}</p>
                        <p class='btzPageContentSubTitle'>| {t('Programme de', {ns: 'programs'})} {state.program.duration}</p>
                    </div>

                    <div class='btzProgramStart-btzProgramDescriptionWrapper'>
                        <p class='btzPageContentTexte btzXl'>{t('Description', {ns: 'programs'})}</p>
                        <p class='btzPageContentTexte' innerHTML={<Convert separator={''} is_xhtml={true} type='nl2br'>{state.program.description}</Convert>}></p>
                    </div>

                    <div class='btzProgramStart-btzProgramDescriptionWrapper-btzInfos'>
                        <img src={icoFrequency} srcset={`${icoFrequencyx2} 2x`} alt='' class='' />
                        <div class=''>
                            <p class='btzPageContentTexte btzXl'>{t('Fréquence', {ns: 'programs'})}</p>
                            <p class='btzPageContentTexte'>
                                {state.program.duration} - {state.program.sessionNumber} {t('séances', {ns: 'programs'})}
                            </p>
                        </div>
                    </div>

                    {state.program.equipment &&
                        <div class='btzProgramStart-btzProgramDescriptionWrapper-btzInfos'>
                            <img src={icoMaterial} srcset={`${icoMaterialx2} 2x`} alt='' class='' />
                            <div class=''>
                                <p class='btzPageContentTexte btzXl'>{t('Matériel nécessaire', {ns: 'programs'})}</p>
                                <p class='btzPageContentTexte' innerHTML={<Convert separator={' '} is_xhtml={true} type='nl2br'>{state.program.equipment}</Convert>}></p>
                            </div>
                        </div>
                    }

                    {isDefined(state.program.coach) &&
                        <div class='btzProgramStart-btzProgramCoachDescriptionWrapper'>
                            <div style={{ 'margin-top': '-65px' }}>
                                <div class='btzBackgroundCover' style={{ 'background-image': 'url(' + (isDefined(state.program.coach.media) ? state.program.coach.media.url : '') + ')' }}></div>
                            </div>
                            <p class='btzPageContentSubTexte'>{t('programme proposé par :', {ns: 'programs'})}</p>
                            <p class='btzPageContentTexte btzXl'>{state.program.coach.name}</p>
                            <p class='btzPageContentTexte'>
                                {state.program.coach.presentation}
                            </p>
                        </div>
                    }
                </div>

                <div class='col-5 col-sm-12 btzProgramStartFooter' style={{ 'vertical-align': 'top' }}>
                    <div class='btzBackgroundCover' style={{ 'background-image': 'url(' + (isDefined(state.program.media) ? state.program.media.url : '') + ')', 'height': '470px', 'border-radius': '4px' }}></div>
                    {state.programAlreadyStarted === false ?
                        <Button primary active onclick={() => { if (isDefined(props.displayedon) && (props.displayedon === 'preview')) { return false; } else { actions.beginTheseProgram(state.program.id); } }} >
                            {props.customer === 'cerba' ?
                                t('Commencer l‘activité', {ns: 'programs'})
                                :
                                t('Commencer le programme', {ns: 'programs'})
                            }
                        </Button>
                        :
                        <dummy>
                            <p class='btzPageContentSubTitle' style={{ 'margin': '15px auto 0', 'display': 'block', 'text-align': 'center' }}>{t('Vous suivez déjà ce programme.', {ns: 'programs'})}</p>
                            <Button primary active onclick={() => { actions.goToFirstSession(state.program.id) }} >
                                {t('Accéder à la première séance', {ns: 'programs'})}
                            </Button>
                        </dummy>
                    }
                </div>
            </div>
        }
    </PageLayout>
);

export default Component(state, actions, view, 'programStart');
