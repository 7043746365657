import { t, h } from '@app/utils';
import { Button, Form, Tile } from '@app/elements';

import logoStep from '@app/img/register/your-data.png';
import logoStepx2 from '@app/img/register/your-data@2x.png';

module.exports = {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <div style={{ position: 'relative' }}>
                <Tile>
                    <div class='btzTitle-btzImage btzTitle-btzImage-absolute col-md-12 col-4'>
                        <img src={logoStep} srcset={`${logoStepx2} 2x`} alt='' class='' />
                    </div>
                    <Tile.Content classes='btzLeftHrArrowed col-xs-12 col-8'>
                        <p class='btzTitle btzXl'>{t('Vos données sont sécurisées', {ns: 'register'})}</p>
                        <p class='btzTexte'>{t('Nous prenons des mesures uniques pour garantir l‘intégrité de vos données en 3 étapes.', {ns: 'register'})}</p>
                        <p class='btzTexte'>{t('Commencez la configuration', {ns: 'register'})}</p>
                    </Tile.Content>
                </Tile>

                <Form onsubmit={event => event.preventDefault()} style={{ 'min-height': 0 }}>
                    <Form.Group classes='btzForm-btzFooter'>
                        <Button primary active onclick={(event) => {
                            actions.updateParentProps(props)
                            actions.onStepSubmit(event)
                        }}>
                            {t('Commencer', {ns: 'generals'})}
                        </Button>
                    </Form.Group>
                </Form>
            </div>
        );
        return view;
    }
}