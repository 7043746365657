import { t, h, Component } from '@app/utils';
import { Card, Link, Convert } from '@app/elements';
import './index.scss';

const state = {
    program: null,
}

const actions = {
    onComponentCreate: () => (state, actions) => {

    }
};

const view = (state, actions) => (props, children) => (
    <div>
        {props.displayedon == 'session' &&
            <Card kind={'advice'} id={'advice-' + props.id}>
                <div>
                    <Card.Header>
                        {props.mediaUrl !== undefined &&
                            <Card.Image class='btzCard-btzImageMedia'>
                                <Card.Media src={props.mediaUrl}></Card.Media>
                            </Card.Image>
                        }
                        <p class='btzTimeline-btzCard-btzTitle btzTitle btzXl'>{props.title}</p>
                        <p class='btzTimeline-btzCard-btzSubTitle btzSubTitle btzTextColored' data-theme={props.theme}>{t('Conseil', {ns: 'programs'})}</p>
                    </Card.Header>

                    <Card.Body classes={'btzTopCustomDotted'}>
                        <p class='btzTimeline-btzCard-btzDescription btzTexte' innerHTML={<Convert separator={''} is_xhtml={true} type='nl2br'>{props.description}</Convert>}></p>
                    </Card.Body>
                </div>
            </Card>
        }

        {props.displayedon == 'summary' &&
            <div class='btzProgram-btzLeftMenu-btzEntry' data-theme={props.theme}><Link scrollto={'seance'} offsetHeader={100}>{t('Séance', {ns: 'programs'})}</Link></div>
        }
    </div>
);

export default Component(state, actions, view, 'advice');
