import { t } from '@app/utils'
import { Objectives } from '@app/api';

import * as staticsconstants from '@app/constants/constants-statics';

export const actions = {
    onComponentCreate: (props) => (state, actions) => {
        let targetId = (props.initial.type === 'mutual' ? props.initial.mutualId : (props.initial.type === 'company' ? props.initial.companyId : null));

        // let chars = {' ':'','/':'-','é':'e','-':''};
        if (targetId === null) {
            targetId = 'betteriseentreprise';
            state.isB2c = true;
        }
        if (props.customer === 'feelgood') {
            targetId = 'ageo';
            state.isB2c = false;
        }
        if (props.customer === 'klesia') {
            targetId = 'klesia';
            state.isB2c = false;
        }
        if (props.customer === 'klesia_netflix') {
            targetId = 'klesianetflix';
            state.isB2c = false;
        }
        if (props.customer === 'coronavirus') {
            targetId = 'coronavirus';
            state.isB2c = false;
        }
        //targetId = targetId.replace(/[ /é-]/g, m => chars[m]).toLowerCase();
        let language = props.language;
        if (language.indexOf('_') === -1) {
            language = 'fr_FR';
        } else {
            language = language.split('_')[0] + '_' + language.split('_')[1].toUpperCase();
        }
        Objectives.getObjectives(language, targetId)
            .then(({ data }) => actions.setObjectivesValues(data))
    },

    setState: ({ ns, key, value }) => state => ({
        [ns]: { ...state[ns], ...{ [key]: value } },
    }),

    setObjectivesValues: (array) => (state, actions) => actions.setState({
        ns: 'objectives',
        key: 'values',
        value: array
    }),

    setObjectivesChoosen: (array) => (state, actions) => actions.setState({
        ns: 'objectives',
        key: 'choosen',
        value: array
    }),

    onObjectiveClick: (string) => (state, actions) => {
        const isObjectiveAlreadyPresent = !!state.objectives.choosen.find(obj => obj.name === string)
        let selectedObjectives = null

        if (isObjectiveAlreadyPresent) {
            selectedObjectives =
                [...state.objectives.choosen.filter(objective => objective.name !== string)]
        } else {
            selectedObjectives =
                [   // add new choosen objective to choosen objectives,
                    // merge them in a single array
                    ...state.objectives.choosen,
                    ...state.objectives.values.filter(objective => {
                        if (isObjectiveAlreadyPresent) return
                        return objective.name === string
                    })
                ]  // clean redundant objectives to keep one reference
                    .filter((elem, pos, arr) => arr.indexOf(elem) === pos)
        }

        actions.setObjectivesChoosen(selectedObjectives)
    },

    updateParentProps: (props) => (state, actions) => {
        if (props) {
            props.send({
                ns: 'user',
                key: 'objectives',
                value: state.objectives.choosen
            })
        }
    },

    setApiMessage: (string) => (state, actions) => actions.setState({
        ns: 'api',
        key: 'message',
        value: string
    }),

    goToNextStep: (props) => (state, actions) => {
        if (!staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()].modules.registerWorkflow.payment) {
        // if (props.customer && ((props.customer === 'feelgood') || (props.customer === 'klesia'))) {
            window.main.location.go('/home/register/device');
        } else {
            if (state.isB2c === true) {
                window.main.location.go('/home/register/payment');
            } else {
                window.main.location.go('/home/register/end');
            }
        }
    },

    onStepSubmit: ({event, props}) => (state, actions) => {
        event.preventDefault();
        if (state.objectives.choosen.length === 0) {
            actions.setApiMessage(t('Merci de sélectionner au moins un objectif santé.', {ns: 'register'}));
        } else if ((props.customer === 'klesia_netflix') && (state.objectives.choosen.length > 1)) {
            actions.setApiMessage(t('Veuillez sélectionner 1 objectif santé au maximum.', {ns: 'register'}));
        } else if (state.objectives.choosen.length > 2) {
            actions.setApiMessage(t('Veuillez sélectionner 2 objectifs santé au maximum.', {ns: 'register'}));
        } else {
            actions.goToNextStep(props)
        }
    }
};
