import { t, h, Component } from '@app/utils';
import { Card, Link, Convert } from '@app/elements';
import './index.scss';

const state = {
    program: null,
}

const actions = {
    onComponentCreate: () => (state, actions) => {

    }
};

const view = (state, actions) => (props, children) => (
    <div>
        {props.displayedon == 'session' &&
            <Card kind={'exercice'} id={'exercice-' + props.id}>
                <div>
                    <Card.Header>
                        <Card.Image class='btzCard-btzImageColored' data-theme={props.theme}>
                            <div class='btzCard-btzImageColored-btzContentWrapper'>
                                <p class='btzCard-btzImageColored-btzContent'>Exercice</p>
                                <p class='btzCard-btzImageColored-btzContent' style={{ 'opacity': '0.8' }}>Coaching</p>
                                <p class='btzCard-btzImageColored-btzContent' style={{ 'opacity': '0.6' }}>演习</p>
                                <p class='btzCard-btzImageColored-btzContent' style={{ 'opacity': '0.4' }}>осуществление</p>
                            </div>
                        </Card.Image>
                        <p class='btzTimeline-btzCard-btzTitle btzTitle btzXl'>{props.title}</p>
                        <p class='btzTimeline-btzCard-btzSubTitle btzSubTitle btzTextColored' data-theme={props.theme}>{t('Exercice du jour', {ns: 'programs'})}</p>
                    </Card.Header>

                    <Card.Body classes={'btzTopCustomDotted'}>
                        <p class='btzTimeline-btzCard-btzDescription btzTexte' innerHTML={<Convert separator={''} is_xhtml={true} type='nl2br'>{props.description}</Convert>}></p>
                    </Card.Body>
                </div>
            </Card>
        }

        {props.displayedon == 'summary' &&
            <div class='btzProgram-btzLeftMenu-btzEntry' data-theme={props.theme}><Link scrollto={'exercice-' + props.id} offsetHeader={100}>{t('Exercice', {ns: 'programs'})}</Link></div>
        }
    </div>
);

export default Component(state, actions, view, 'exercice');
