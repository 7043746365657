import { appCustoSetting } from '@app/settings/settings';
import { queueQueries } from '@app/api/controlleur';
const { API_URL } = appCustoSetting.appsettings;

function getTrackersList() {
    return queueQueries(`${API_URL}/trackers/`, 'GET')
}

function connectTracker(brand, name, scheme) {
    return queueQueries(`${API_URL}/tracker/add/${brand}/${name}/?scheme=${scheme}`, 'POST', 'application/json', true, true, true, true, true, {brand: brand, name: name})
}

function connectConflictedTracker(brand, name, scheme, data) {
    return queueQueries(`${API_URL}/tracker/add/${brand}/${name}/?scheme=${scheme}`, 'POST', 'application/json', true, true, true, true, true, data)
}

function removeTracker(brand, name) {
    return queueQueries(`${API_URL}/tracker/delete/${brand}/${name}/`, 'DELETE')
}

export const Trackers = {
    getTrackersList,
    connectTracker,
    connectConflictedTracker,
    removeTracker,
};
