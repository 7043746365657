import { t } from '@app/utils';

export const state = {
    rendering: false,
    values: {
        adh: '',
    },
    isTouched: {
        adh: false,
    },
    isValid: {
        adh: false,
    },
    api: {
        message: 'Le numéro d‘adhérent est composé de 10 chiffres.',
        i18nMessage: t('Le numéro d‘adhérent est composé de 10 chiffres.', {ns: 'timeline'}),
        code: 200,
        kind: 'info'
    },
    kind: 'adh',
    messageActive: false,
    modalDisplayed: null,
    cookieName: '',
};
