let msgTimeout = null;
const displayErrors = function (message, time) {
    if (document.getElementById('btzMaintextContent') !== null) {
        document.getElementById('btzMaintextContent').innerHTML = message;
        document.getElementById('btzMainMessage').classList.add('active');
    } else {
        return false;
    }

    msgTimeout = setTimeout(function () {
        clearTimeout(msgTimeout);
        msgTimeout = null;
        document.getElementById('btzMainMessage').classList.remove('active');
    }, time);
};

export { displayErrors };
