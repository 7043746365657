import { getQueryVariable } from '@app/core';
import * as staticsconstants from '@app/constants/constants-statics';

export const actions = {
    onComponentCreate: (props) => (state, actions) => {
        let customerssoname = getQueryVariable('customerssoname', false);
        if (!customerssoname || (customerssoname === null) || (customerssoname === undefined)) {
            customerssoname = props.customer;
            if (!customerssoname || (customerssoname === null) || (customerssoname === undefined)) {
                customerssoname = 'betterise';
            }
        }
        if (staticsconstants.CUSTOMERS[customerssoname] !== undefined) {
            state.redirect.ios = staticsconstants.CUSTOMERS[customerssoname].appurl.ios;
            state.redirect.android = staticsconstants.CUSTOMERS[customerssoname].appurl.android;
            actions.setMailto(staticsconstants.CUSTOMERS[customerssoname].mailto);
        }
        actions.setCustomerSsoName(customerssoname);
    },
    setCustomerSsoName: newState => state => ({
        customerSsoName: newState,
    }),
    setMailto: newState => state => ({
        mailto: newState,
    }),
};