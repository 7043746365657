import { t, h } from '@app/utils';
import { Button, Card, Avatar, Tile, Balloon, Modal } from '@app/elements';

import imgBgSurvey from './cerba/bg-survey.png';

import { ModalViewSurvey } from './modalView';

export const view = (state, actions) => (props, children) => (
    <div>
        {((props.displayedon == 'timeline') && (state.nothingToSurvey === false)) &&
            <Card kind={'offer'} theme={props.valueTheme}>
                <div>
                    <Card.Body classes='' style={{ 'border-radius': '4px', 'overflow': 'hidden' }}>
                        <div class='btzFullBg' style={{ 'background-image': 'url(' + imgBgSurvey + ')' }}></div>
                        <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap' }}>
                            <Tile.Icon classes={'col-2 col-xs-12'}>
                                <Avatar.Alfred classes={'centered'} />
                            </Tile.Icon>
                            <Tile.Content classes={'col-9 col-xs-12'}>
                                <Balloon style={{ 'white-space': 'normal' }}>
                                    {t('Afin de préparer votre rendez-vous téléphonique avec votre nutritionniste, veuillez remplir votre questionnaire personnel.', {ns: 'timeline'})}
                                </Balloon>
                            </Tile.Content>
                        </Tile>
                    </Card.Body>

                    <Card.Footer>
                        <div class='col-3 col-xs-12'></div>
                        <div class='col-9 col-xs-12 p-0'>
                            <Modal modalDisplayed={state.modalDisplayed} content={<ModalViewSurvey {...state} endSurveyQuestions={actions.endSurveyQuestions} setSurveyQuestions={actions.setSurveyQuestions} setNothingToSurvey={actions.setNothingToSurvey} hideSurvey={actions.hideSurvey} propagate={actions.updateParentProps} customer={props.customer} language={props.language}></ModalViewSurvey>}>
                                <Button primary block active flat onclick={actions.getSurveyQuestions} style={{ 'height': '40px' }}>
                                    {t('Je remplis mon questionnaire', {ns: 'timeline'})}
                                </Button>
                            </Modal>
                        </div>
                    </Card.Footer>
                </div>
            </Card>
        }
    </div>
);
