import { appCustoSetting } from '@app/settings/settings';
import { queueQueries } from '@app/api/controlleur';
const { API_URL } = appCustoSetting.appsettings;

function getRunning() {
    return queueQueries(`${API_URL}/program/user/`, 'GET');
}

function getRecommanded() {
    return queueQueries(`${API_URL}/program/`, 'GET');
}

function getRecommandedFollowingObjectives() {
    return queueQueries(`${API_URL}/program/objectives/`, 'GET');
}

function getAll() {
    return queueQueries(`${API_URL}/themes/`, 'GET');
}

function getProgram(idprogram) {
    return queueQueries(`${API_URL}/program/${idprogram}/`, 'GET');
}

function getUserProgram(idprogram) {
    return queueQueries(`${API_URL}/program/${idprogram}/user/`, 'GET');
}

function getProgramSession(idprogram) {
    return queueQueries(`${API_URL}/program/${idprogram}/session/`, 'GET');
}

function getPreviousProgramSession(idprogram, interval) {
    return queueQueries(`${API_URL}/program/${idprogram}/session/${interval}/`, 'GET');
}

function finishProgramSession(idprogram, datas) {
    return queueQueries(`${API_URL}/program/${idprogram}/session/`, 'POST', 'application/json', true, true, true, true, true, datas);
}

function cancelProgram(idprogram) {
    return queueQueries(`${API_URL}/program/${idprogram}/`, 'DELETE');
}


export const Programs = {
    getRunning,
    getRecommanded,
    getRecommandedFollowingObjectives,
    getAll,
    getProgram,
    getUserProgram,
    getProgramSession,
    getPreviousProgramSession,
    finishProgramSession,
    cancelProgram,
};
