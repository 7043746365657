import { getQueryVariable, scrollTo, getUser, isDefined, dynLoad } from '@app/core';
import { Data, Cardline, User } from '@app/api';
import { h, Component } from '@app/utils';
import * as sports from '@app/sports';
import './index.scss';
import * as constants from '@app/constants';

import Dashboard from './dashboard';

const state = {
    today: null,
    refDate: null,
    data: {
        interval: null,
    },
    mode: 'week',
    userTracker: null,
    desabledDash: [],
    dataRising: [],
    particularHeight: 0,
    metrics: {
        height: 0,
        weight: 0,
    },
    userMedia: null,
    userSettings: null,
    locales: null,
    informationDisplayed: true,
    // dynamicSportsTag: ['ACTIVITY_SWIMMINGSTATS', 'ACTIVITY_BIKINGSTATS', 'ACTIVITY_RUNNINGSTATS', 'ACTIVITY_WORKOUTSTATS', 'ACTIVITY_FOOTBALLSTATS', 'ACTIVITY_TENNISSTATS', 'ACTIVITY_YOGASTATS', 'ACTIVITY_MARTIALSTATS', 'ACTIVITY_RUGBYSTATS', 'ACTIVITY_GOLFSTATS'],
    dynamicSportsTag: constants.custo.DASH_SPORTS.filter((f) => f.kpi !== 'OTHER').map((m) => m.kpi),
}

const actions = {
    onComponentCreate: () => (state, actions) => {
        let temp = sports.OTHERS_SPORTS.map((sport) => sport.datatag);
        actions.setDynamicSportsTag(state.dynamicSportsTag.concat(temp));

        Cardline.getCards(17).then(function(res) {
            let dataRising = [];
            for (var dRise in res.data) {
                dRise = res.data[dRise];
                dataRising.push({
                    theme: '' + dRise.theme,
                    data: dRise,
                });
            }
            actions.setDataRising(dataRising);
        });

        let removeDataInformation = localStorage.getItem('removeDataInformation');
        if ((removeDataInformation !== null) && (removeDataInformation !== undefined) && (removeDataInformation !== '')) {
            if (removeDataInformation == 'true') {
                actions.removeInformation();
            }
        }

        var refDate = new Date();
        var year = refDate.getUTCFullYear(), month = (refDate.getUTCMonth() + 1), day = refDate.getUTCDate();
        if (+month < 10) {
            month = '0' + month;
        }
        if (+day < 10) {
            day = '0' + day;
        }
        refDate = year + '' + month + '' + day;

        let userCallback = (sessionUser) => {
            const getLocalesVariables = async () => {
                let userLang = sessionUser.language;
                userLang = userLang.replace('_', '-');
                const data = await import(`locales/d3/${userLang}.json`);
                actions.setLocales(data);
            }
            getLocalesVariables();

            actions.setUserMedia((sessionUser.media !== null) ? sessionUser.media.url : '');
            actions.setUserTracker(sessionUser.trackers);

            state.metrics = {
                height: sessionUser.heightMetrics,
                weight: sessionUser.metrics,
            }
            actions.setRefDate(refDate);
            actions.setToday(refDate);
            actions.updateAllDataViz();
            if (isDefined(sessionUser.profile.personal) && isDefined(sessionUser.profile.personal.height)) {
                actions.setParticularHeight(sessionUser.profile.personal.height);
            }
            let desabledDash = [];
            for (var sett in sessionUser.settings) {
                sett = sessionUser.settings[sett];
                if ((sett.name.toLowerCase().indexOf('theme') > -1) && (+sett.value === 0)) {
                    desabledDash.push('' + sett.name.split('_')[1]);
                }
            }
            actions.setDesabledDash(desabledDash);
            actions.setUserSettings(sessionUser.settings);
        };
        getUser(userCallback, true);
    },
    setUserMedia: newState => state => ({
        userMedia: newState,
    }),
    setUserTracker: newState => state => ({
        userTracker: newState,
    }),
    updateAllDataViz: () => (state, actions) => {
        var refDate = state.refDate;
        Data.initialQuery(refDate, state.mode).then(function(res) {
            actions.setState({ns: 'data', key: 'interval', value: res.data.interval });

            let tmt = setTimeout(() => {
                clearTimeout(tmt);
                tmt = null;
                let trg = getQueryVariable('theme');
                if ((trg !== null) && (trg !== false)) {
                    if (document.getElementById(trg) === null) {
                        return false;
                    }
                    let target = document.getElementById(trg)
                    let offset = target.getBoundingClientRect().top + window.scrollY;
                    scrollTo(null, (offset - 160), 240);
                }
            }, 1000);
        });
    },
    onToggleDash: ({dash, mode}) => (state, actions) => {
        if (dash === 0) {
            return false;
        }
        let newDashs = state.desabledDash;
        if (mode === 0) {
            newDashs.push(dash);
        } else {
            newDashs.splice(newDashs.indexOf(dash), 1);
        }
        let data = [
            {'name' : 'THEME_' + dash, 'value' : mode}
        ];

        User.setUserSettings(data).then(() => {});
        actions.setDesabledDash(newDashs);
    },
    updatePeriod: (mode) => (state, actions) => {
        state.mode = mode,
        actions.updateAllDataViz();
    },
    updateSelector: (date) => (state, actions) => {
        state.refDate = date,
        actions.updateAllDataViz();
    },
    setState: ({ ns, key, value }) => state => ({
        [ns]: { ...state[ns], ...{ [key]: value } },
    }),
    setRefDate: newState => state => ({
        refDate: newState,
    }),
    setToday: newState => state => ({
        today: newState,
    }),
    setMode: newState => state => ({
        mode: newState,
    }),
    setDesabledDash: newState => state => ({
        desabledDash: newState,
    }),
    setDataRising: newState => state => ({
        dataRising: newState,
    }),
    setParticularHeight: newState => state => ({
        particularHeight: newState,
    }),
    setLocales: newState => state => ({
        locales: newState,
    }),
    setDynamicSportsTag: newState => state => ({
        dynamicSportsTag: newState,
    }),
    setUserSettings: newState => state => ({
        userSettings: newState,
    }),
    removeInformation: () => state => ({
        informationDisplayed: false,
    }),
    noMoreInformation: () => (state, actions) => {
        let newbool = true;
        let existingbool = localStorage.getItem('removeDataInformation');
        if ((existingbool !== null) && (existingbool !== undefined) && (existingbool !== '')) {
            newbool = (existingbool === 'true' ? false : true);
        }
        localStorage.setItem('removeDataInformation', newbool);
    },
};

const view = dynLoad('view', 'Datas/views');

export default Component(state, actions, view, 'datas');
