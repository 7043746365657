import { t, h } from '@app/utils';
import { redirectToWebOrApp } from '@app/core';
import { Button, Tile, TextTransform } from '@app/elements';

import logoAuthorize from '@app/img/public/authorize/you-are-authorize.png';
import logoAuthorizex2 from '@app/img/public/authorize/you-are-authorize@2x.png';

import logoAuthorizeCERBA from '@app/img/public/custo/cerba/authorize/you-are-authorize.png';
import logoAuthorizeCERBAx2 from '@app/img/public/custo/cerba/authorize/you-are-authorize@2x.png';

import PublicLayout from '@app/layouts/public/layout';

export const view = (state, actions) => (props, children) => (
    <div key='publicblocked'>
        <PublicLayout page='blocked' customer={props.customer}>
            <div class='publicblocked-blocked'>
                <div style={{ position: 'relative' }}>
                    <Tile>
                        <div>
                            <div class='btzTitle-btzImage btzTitle-btzImage-absolute col-md-12 col-4'>
                                {{
                                    ['betterise']: <img src={logoAuthorize} srcset={`${logoAuthorizex2} 2x`} alt='' class='' />,
                                    ['partena']: <img src={logoAuthorize} srcset={`${logoAuthorizex2} 2x`} alt='' class='' />,
                                    ['gjensidige']: <img src={logoAuthorize} srcset={`${logoAuthorizex2} 2x`} alt='' class='' />,
                                    ['cerba']: <img src={logoAuthorizeCERBA} srcset={`${logoAuthorizeCERBAx2} 2x`} alt='' class='' />
                                }[props.customer]}
                            </div>
                            <Tile.Content classes='btzLeftHrArrowed col-xs-12 col-8'>
                                <p class='btzTitle btzXl'>{t('L’appareil a bien été autorisé', {ns: 'public'})}</p>
                                <p class='btzTexte' style={{ 'max-width': '320px' }}>{t('Vous pouvez maintenant vous connecter.', {ns: 'public'})}</p>
                                <Button flat active onclick={() => { redirectToWebOrApp(props.customer)}}>{t('Accéder à', {ns: 'public'}) + ' '}<TextTransform mode={'capitalize'}>{(props.customer === 'gjensidige' ? 'iver' : props.customer)}</TextTransform></Button>
                            </Tile.Content>
                        </div>
                    </Tile>
                </div>
            </div>
        </PublicLayout>
    </div>

);