import { Mutual } from '@app/api';
import { isDefined } from '@app/core';
import { debounce, isEmpty } from '@app/utils';

import * as staticsconstants from '@app/constants/constants-statics';

export const actions = {

    onComponentCreate: ({ initial: { mutual = '' }, customer }) => (state, actions) => {
        if (customer) {
            actions.setCustomer(customer);
        }
        if (mutual) {
            actions.getInitialStateAndUpdate(mutual)
        }
    },

    getInitialStateAndUpdate: string => (state, actions) => {

        Mutual.getMutuals(string)
            .then((data) => {
                actions.setMutualChoosen(data.data[0])
                actions.setMutualValue(data.data[0].name)
                actions.setMutualApproval(data.data[0].partner)
                actions.setMutualValidity(true)
                actions.setMutualsValues([])
            })
    },

    setCustomer: newState => state => ({
        customer: newState,
    }),

    setState: ({ ns, key, value }) => (state, actions) => ({
        [ns]: { ...state[ns], ...{ [key]: value } }
    }),

    setMutualValue: string => (state, actions) => actions.setState({
        ns: 'mutual',
        key: 'value',
        value: string
    }),

    setMutualValidity: boolean => (state, actions) => actions.setState({
        ns: 'mutual',
        key: 'isValid',
        value: boolean
    }),

    setMutualTouched: boolean => (state, actions) => actions.setState({
        ns: 'mutual',
        key: 'isTouched',
        value: boolean
    }),

    setMutualApproval: boolean => (state, actions) => actions.setState({
        ns: 'mutual',
        key: 'isApproved',
        value: boolean
    }),

    setMutualChoosen: object => (state, actions) => actions.setState({
        ns: 'mutual',
        key: 'choosen',
        value: object
    }),

    setMutualsValues: array => (state, actions) => actions.setState({
        ns: 'mutuals',
        key: 'values',
        value: array
    }),

    setApiStatus: number => (state, actions) => actions.setState({
        ns: 'api',
        key: 'status',
        value: number
    }),

    setApiMessage: string => (state, actions) => actions.setState({
        ns: 'api',
        key: 'message',
        value: string
    }),

    setFormSubmission: boolean => (sttae, actions) => actions.setState({
        ns: 'form',
        key: 'isSubmitting',
        value: boolean
    }),

    onMutualInputUpdate: string => (state, actions) => {
        actions.setApiMessage(null);
        if (isEmpty(string)) return false

        Mutual.getMutuals(string)
            .then(({ data }) => {
                let results = data;
                let particularMutualsSelected = staticsconstants.CUSTOMERS[state.customer.toLowerCase()].particularMutuals.register.selected;
                let particularMutualsIgnored = staticsconstants.CUSTOMERS[state.customer.toLowerCase()].particularMutuals.register.ignored;
                //
                if (isDefined(particularMutualsSelected) && (particularMutualsSelected.length > 0)) {
                    results = results.filter((f) => particularMutualsSelected.indexOf(f.name.toLowerCase()) > -1);
                } else if (isDefined(particularMutualsIgnored) && (particularMutualsIgnored.length > 0)) {
                    results = results.filter((f) => particularMutualsIgnored.indexOf(f.name.toLowerCase()) === -1);
                }
                actions.setMutualsValues(results)
            })
    },

    onMutualInputDebouncedUpdate: (string) => (state, actions) => debounce(
        600,
        'onMutualInputUpdate',
        string
    ),

    onMutualsListClick: string => (state, actions) => {
        const choosenMutual = state.mutuals.values.filter(({ name }) => {
            return name.toLowerCase() === string.toLowerCase()
        }) || {}

        actions.setMutualChoosen(choosenMutual[0])
        actions.setMutualValue(choosenMutual[0].name)
        actions.setMutualApproval(choosenMutual[0].partner)

        const mutualExistenceInList = !!state.mutuals.values.find(mutual => mutual.name.toLowerCase() === string.toLowerCase());

        if (mutualExistenceInList) {
            actions.setMutualValidity(true)
            actions.setMutualsValues([])
        } else {
            actions.setMutualValidity(false)
        }
    },

    onMutualInputTouched: () => (state, actions) => {
        actions.setApiMessage(null);
        if (!state.mutual.isTouched) {
            actions.setMutualTouched(true)
        }
    },
    goToNextStep: () => {
        window.main.location.go('/home/register/objectives');
    },

    onStepSubmit: (event) => (state, actions) => {
        event.preventDefault()

        if (state.form.isSubmitting) {
            return false
        }

        if (state.mutual.isTouched && state.mutual.value.length === 0) {
            actions.setApiMessage('MutualNoEntry')
            return false
        }

        if (state.mutual.isTouched && state.mutuals.values.length > 0) {
            actions.setApiMessage('MutualNotSelected')
            return false;
        }

        if (state.mutual.isTouched && !state.mutual.isValid) {
            actions.setApiMessage('MutualNoExists')
            return false
        }

        if (state.mutual.isTouched && state.mutual.isValid && !state.mutual.isApproved) {
            actions.setApiMessage('MutualNotPartner')
            return false
        }

        if (!state.mutual.isValid) return

        if (state.mutual.isValid && state.mutual.isApproved) actions.setFormSubmission(true)
        actions.goToNextStep()
    },

    updateParentProps: (props) => (state, actions) => {
        props.send({
            ns: 'user',
            key: 'mutual',
            value: state.mutual.choosen.name
        })
        props.send({
            ns: 'user',
            key: 'mutualId',
            value: state.mutual.choosen.id
        })
    }
};
