import { t, h } from '@app/utils';
import { Card } from '@app/elements';
import { dynMod } from '@app/core';
import * as staticsconstants from '@app/constants/constants-statics';

import UNotification from './notification';

export const view = (state, actions) => (props, children) => (
    <Card kind={'notification'}>
        <Card.Header>
            <p class='btzSubTitle'>{t('Notifications', {ns: 'timeline'})}</p>
            {(((state.notifications !== null) && (state.notifications.length > 0)) || ((state.programs !== null) && (state.programs.length > 0))) ?
                <p class='btzLightTexte' style={{ 'cursor': 'pointer' }} onclick={() => { actions.removeAllNotifications() }}>{t('tout effacer', {ns: 'timeline'})}</p>
                :
                <p class='btzLightTexte'></p>
            }
        </Card.Header>
        <Card.Body>
            {((state.adh !== null) && (props.customer === 'vyv')) &&
                <div key={state.adh.id}><UNotification kind='adh' {...state.adh} removeNotification={actions.removeNotification} customer={props.customer}></UNotification></div>
            }
            {state.notifications.map((item, idx) =>
                <div key={item.id}><UNotification kind='knowit' {...item} idx={idx} removeNotification={actions.removeNotification} customer={props.customer}></UNotification></div>
            )}
            {(state.gamification !== null) &&
                <div key={'gamification'}><UNotification kind='gamification' {...state.gamification} removeNotification={actions.removeNotification} customer={props.customer}></UNotification></div>
            }
            {((staticsconstants.CUSTOMERS[props.customer.toLowerCase()].modules.programs === true) && (staticsconstants.CUSTOMERS[props.customer.toLowerCase()].modules.programsSeparateNotification === false)) &&
                <dummy>
                    {state.programs === null ?
                        <div>
                            {(Array.apply(null, { length: 3 }).map(Number.call, Number)).map(() =>
                                <div class='btzLoadingContainer-btzWrapper'>
                                    <div class='btzLoadingContainer'></div>
                                </div>
                            )}
                        </div>
                        :
                        <div>
                            {state.programs.map((item, idx) =>
                                <div key={item.id}><UNotification kind='program' {...item} idx={idx} removeNotification={actions.removeNotification} customer={props.customer}></UNotification></div>
                            )}
                        </div>
                    }
                </dummy>
            }
        </Card.Body>
    </Card>
);
