import { t, h } from '@app/utils';
import { Tile } from '@app/elements';

import PublicLayout from '@app/layouts/public/layout';

export const view = (state, actions) => (props, children) => (
    <div key='publicemailshow'>
        <PublicLayout page='emailshow' customer={props.customer} goToLabel={state.goToLabel} goToUrl={state.goToUrl}>
            <div class='publicemailshow-emailshow'>
                <div style={{ position: 'relative' }}>
                    {state.email === false ?
                        <p class=''>{t('Email non trouvé!', {ns: 'public'})}</p>
                        :
                        <Tile>
                            <div innerHTML={state.email}></div>
                        </Tile>
                    }
                </div>
            </div>
        </PublicLayout>
    </div>

);