import { t, h } from '@app/utils';
import { allRoutes } from '@app/settings';
import { Button } from '@app/elements';
import * as staticsconstants from '@app/constants/constants-statics';

import ShareLayout from '@app/layouts/share/layout';

import Advice from './../../Timeline/cards/advice';
import Article from './../../Timeline/cards/article';
import Recipe from './../../Timeline/cards/recipe';
import Citation from './../../Timeline/cards/citation';
import Prenom from './../../Timeline/cards/prenom';

export const view = (state, actions) => (props, children) => (
    <div key='publicshare'>
        <ShareLayout page='share' customer={(props.customer || 'betterise')} goToUrl={state.goToUrl} goToLabel={state.goToLabel} redirectTo={((['betterise', 'cerba'].indexOf(props.customer) === -1) ? (allRoutes['public.transit.webtoapp'].pathname + '?customerssoname=' + props.customer) : null)}>
            <div class='publicshare-share'>
                <div style={{ position: 'relative', 'max-width': '560px', 'margin': 'auto', 'padding-bottom': '80px' }}>
                    {state.card !== null &&
                        <div>
                            {state.card.type === 2 &&
                                <Advice {...state.card} displayedon='share' customer={props.customer}></Advice>
                            }
                            {state.card.type === 20 &&
                                <Citation {...state.card} displayedon='share' customer={props.customer}></Citation>
                            }
                            {state.card.type === 8 &&
                                <Prenom {...state.card} displayedon='share' customer={props.customer}></Prenom>
                            }
                            {state.card.type === 18 &&
                                <Article {...state.card} displayedon='share' customer={props.customer}></Article>
                            }
                            {state.card.type === 19 &&
                                <Recipe {...state.card} displayedon='share' customer={props.customer}></Recipe>
                            }
                        </div>
                    }

                    <div class='btzTimeline-btzCard-btzFooter' style={{ 'margin-top': '10px', 'line-height': '22px' }}>
                        <p class='btzTimeline-btzCard-btzDescription'>
                            {t('Ce contenu a été partagé depuis', {ns: 'public'}) + ' ' + state.sharedFrom + '.'}<br/>
                            {t('Cliquez sur le bouton ci-dessous pour en savoir plus.', {ns: 'public'})}
                        </p>
                    </div>
                    {((staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()].appredirectFromPublic == false) || ((state.redirect.android === '') && (state.redirect.ios === ''))) ?
                        <div style={{ 'display': 'inline-block', 'margin': 'auto' }}>
                            <Button flat active onclick={() => { window.location.href = state.goToUrl}}>{t('Visiter', {ns: 'public'}) + ' ' + state.goToLabel}</Button>
                        </div>
                        :
                        <div class=''>
                            <Button flat active onclick={() => { window.location.href = state.redirect.android}}>{t('Télécharger depuis Google Play', {ns: 'public'})}</Button>
                            <Button flat active onclick={() => { window.location.href = state.redirect.ios}}>{t('Télécharger depuis l‘App Store', {ns: 'public'})}</Button>
                        </div>
                    }
                </div>
            </div>
        </ShareLayout>
    </div>
);

/*
<a class="btz-button-gjensidige" href="https://itunes.apple.com/fr/app/iver/id1298542367?mt=8">
        {{ "Télécharger depuis l'App Store" | trans | upper }}
    </a>
</div>
<div class="col-xs-6">
    <a class="btz-button-gjensidige" href="https://play.google.com/store/apps/details?id=com.betterise.gjensidige&hl=fr ">
        {{ "Télécharger depuis Google Play" | trans | upper }}
    </a>
 */