import { dynMod, getClosest, getQueryVariable, checkForValidCSRFToken, isDefined, idleLogout, displayErrors, redirectToWebOrApp } from '@app/core';
import { app, t, h, location, withTranslation, withStorage, withSecurity, withRouting } from '@app/utils';
import { Route, Switch, ProgressBar, GamifNotifications, Meta } from '@app/elements';
import { allRoutes } from '@app/settings';
import { appCustoSetting } from '@app/settings';

console.log('>>> appCustoSetting <<< : ', appCustoSetting);

const { CUSTO_SSO } = appCustoSetting.appsettings;

import * as staticsconstants from '@app/constants/constants-statics';

import * as serviceWorker from './registerServiceWorker';

import { BotFooterView } from '@app/layouts/botfooter';

import icoCloseWhite from '@app/img/ico/ico-close-white-big.png';
import icoCloseWhitex2 from '@app/img/ico/ico-close-white-big@2x.png';

import {
    Login,
    Register,
    About,
    Developer,
    Enterprise,
    Home,
    Scientific,
    Technology,
    Onboarding,
    OnboardingEnd,
    OnboardingDone,
    Timeline,
    Food,
    Datas,
    Follow,
    Programs,
    ProgramStart,
    ProgramSession,
    Account,
    Favorites,
    Objectives,
    Oc,
    Waiting,
    Parameter,
    Rewards,
    PublicSurvey,
    PublicSurveyObjective,
    PublicSurveyResiliate,
    PublicMaintenance,
    PublicExpire,
    PublicExpireSponsor,
    PublicSubscription,
    PublicBlocked,
    PublicAuthorize,
    PublicEmailConfirm,
    PublicEmailShow,
    PublicPasswordReset,
    PublicSecret,
    PublicShare,
    PublicResiliate,
    PublicCb,
    QuatreCentQuatre,
    TransitWebToApp,
    PublicObjectifs,
    PublicUnregisterSW,
    AdminPreview,
    SpecificPage,
} from '@app/modules';


const state = {
    location: location.state,
    rendering: false,
    loaded: 'nope', // this set overflow hidden on body until complete load
    langloaded: 'nope',
    customerssoname: CUSTO_SSO.toLowerCase(), // reference ssoname for custo
    meta: null, // meta are raw setted in public/index.html, then we override them if needed
    refLanguage: 'fr_FR',
    browserlang: '', // reference language
    language: '',
};

// https://github.com/stripe/stripe-node/issues/265
// to defer and load only for specific customer (that need payment)

const actions = {
    onUpdate: () => (state, actions) => {
        checkForValidCSRFToken();
    },
    location: location.actions,
    pendingAjaxQuery: () => (state, actions) => {
        if (isDefined(localStorage.getItem('language')) && localStorage.getItem('language').length === 5) {
            state.language = localStorage.getItem('language').replace(/-/g, '_'); // ensure lang is xy_XY pattern
        } else {
            localStorage.removeItem('language');
        }
        //
        let error = getQueryVariable('err', false);
        if (error && (error !== false) && (error !== '')) {
            error = staticsconstants.ERRORS[error];
            if (error && (error !== undefined) && (error !== '') && (error.kind === 'error')) {
                displayErrors(error.message, error.time);
            }
        }
        //
        let scheme = getQueryVariable('scheme', false); // redirect to mobile app
        if ((typeof scheme !== 'undefined') && (scheme !== '') && (scheme !== null) && (scheme !== false)) {
            window.location.href = scheme;
            return false;
        }
        //
        let customerssoname = null;
        let forcedLanguage = staticsconstants.CUSTOMERS[state.customerssoname].forcedLanguage;
        if ((appCustoSetting.DEBUG === 'true') || (appCustoSetting.DEBUG === true)) {
            // get customer by query variable only if dev
            customerssoname = getQueryVariable('customerssoname', false);
            let customersso = getQueryVariable('customersso', false);
            customerssoname = (customerssoname === false) ? customersso : customerssoname; // look at query parameter
            customerssoname = (customerssoname === false) ? state.customerssoname : customerssoname; // if nothing, take CUSTO_SSO constant
            if (staticsconstants.CUSTOMERS_WHYTE_BRAND.indexOf(state.customer) > -1) {
                // no dynamique custo using query parameter for white brand
                customerssoname = CUSTO_SSO.toLowerCase();
            }
        } else {
            // else we use the .env customer, no dyn, each customer has his instance
            customerssoname = state.customerssoname;
        }
        let ccustomer = staticsconstants.CUSTOMERS[customerssoname];
        if (ccustomer === undefined) {
            customerssoname = state.customerssoname;
        }
        actions.setCustomer(customerssoname);
        //
        let isIdleLogout = staticsconstants.CUSTOMERS[state.customerssoname].idleLogout;
        if (isIdleLogout === true) {
            idleLogout();
        }
        //
        localStorage.setItem('readablecustomer', staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()].readablecustomer.toLowerCase());
        //
        actions.loadCSS(customerssoname);
        actions.overrideMeta(customerssoname);
        actions.defineLanguage(forcedLanguage);

        var intervalID = window.setInterval(
            function () {
                if (document.readyState === 'complete') {
                    actions.setLoaded('loaded');
                    window.clearInterval(intervalID);
                }
            }, 500);
    },
    loadCSS: (customerssoname) => (state, actions) => {
        console.log('customerssoname  ', customerssoname);
        require('@app/custo/_index.scss');
        // try {
        //     require('custo/' + customerssoname + '/index.scss');
        // } catch (error) {
        //     require('custo/betterise/index.scss');
        //     actions.setCustomer('betterise');
        // }
    },
    overrideMeta: (customerssoname) => (state, actions) => {
        let ccustomer = staticsconstants.CUSTOMERS[customerssoname];
        let meta = {
            url: process.env.APP_WEB_URL,
            customer: customerssoname,
            title: (ccustomer === undefined ? 'default title' : ccustomer.title),
            description: (ccustomer === undefined ? 'default description' : t(ccustomer.description)),
            favicon: window.location.origin + '/favicon/' + ((customerssoname !== false) ? customerssoname : 'betterise') + '/favicon.ico',
            iosbanner: (((ccustomer !== undefined) && (ccustomer.appurl !== undefined) && (ccustomer.appurl.iosbanner !== undefined)) ? ccustomer.appurl.iosbanner : null),
            androidbanner: (((ccustomer !== undefined) && (ccustomer.appurl !== undefined) && (ccustomer.appurl.androidbanner !== undefined)) ? ccustomer.appurl.androidbanner : null),
        }
        window.dispatchEvent(new Event('resize'));
        actions.setMeta(meta);
    },
    defineLanguage: (forcedLanguage) => (state, actions) => {
        let storedUser = localStorage.getItem('user');
        let user = null;
        if (isDefined(storedUser)) {
            user = JSON.parse(storedUser);
        }
        let userLang = null;
        if (isDefined(user) && isDefined(user.language)) {
            userLang = user.language.replace(/-/g, '_'); // ensure lang is xy_XY pattern
            actions.setLang(userLang);
        }

        let browserLang = window.navigator.userLanguage || window.navigator.language;
        if (browserLang.length === 2) {
            browserLang = browserLang + '_' + browserLang.toUpperCase();
        } else {
            browserLang = browserLang.replace(/-/g, '_'); // ensure lang is xy_XY pattern
        }

        let systemLang = state.language;
        console.log('LANGUAGE #1 ', forcedLanguage, user, systemLang, browserLang, userLang);
        if (isDefined(forcedLanguage)) {
            browserLang = forcedLanguage;
            systemLang = forcedLanguage;
        } else if (isDefined(userLang)) {
            browserLang = userLang;
            systemLang = userLang;
        }

        console.log('LANGUAGE #2 ', systemLang, browserLang, userLang, window.navigator);
        let selectedLang = null;
        if ((systemLang.length === 5) || (browserLang.length === 5)) {
            if ((systemLang.length === 5) && (systemLang !== state.refLanguage)) {
                selectedLang = systemLang;
            } else if ((browserLang.length === 5) && (browserLang !== state.refLanguage)) {
                selectedLang = browserLang;
            }
        }
        if (!isDefined(selectedLang)) {
            selectedLang = state.refLanguage;
        }
        selectedLang = selectedLang.replace(/-/g, '_'); // ensure lang is xy_XY pattern
        window.main.changeLanguage({lang: selectedLang, updateStorage: false});
        actions.setBrowserLang(selectedLang);
        actions.setLangLoaded('loaded');
    },
    redirectToLogin: () => (state, actions) => {
        let customerRedirect = staticsconstants.CUSTOMERS[state.customerssoname].customerRedirect;
        if (isDefined(customerRedirect) && (customerRedirect.redirect === true)) {
            if (customerRedirect.withAccessToken) {
                let access_token = getQueryVariable('access_token', false);
                window.location.href = customerRedirect.url + '?access_token=' + access_token;
            } else {
                window.location.href = customerRedirect.url;
            }
        } else {
            if (staticsconstants.CUSTOMERS[state.customerssoname].modules.medicalLogin === true) {
                let medicalRedirect = allRoutes[staticsconstants.CUSTOMERS[state.customerssoname].modules.medicalLoginURL].pathname;
                medicalRedirect = medicalRedirect.replace(/:/g, '');
                window.location.href = medicalRedirect;
            } else if (staticsconstants.CUSTOMERS[state.customerssoname].modules.login === false) {
                redirectToWebOrApp(state.customerssoname);
            } else {
                let side = getQueryVariable('side', false);
                if (side === 'register') {
                    window.location.href = allRoutes['home.register'].pathname;
                } else {
                    window.location.href = allRoutes['home.login'].pathname;
                }
            }
        }
    },
    setCustomer: newState => state => ({
        customerssoname: newState,
    }),
    setLoaded: newState => state => ({
        loaded: newState,
    }),
    setLangLoaded: newState => state => ({
        langloaded: newState,
    }),
    setMeta: newState => state => ({
        meta: newState,
    }),
    setBrowserLang: newState => state => ({
        browserlang: newState,
    }),
    setLang: newState => state => ({
        lang: newState,
    }),
    rendering: () => state => ({
        rendering: !state.rendering,
    }),
};

const view = (state, actions) => (
    <div key={state.language} id='btzRoot' class={state.customerssoname + ' ' + state.language + ' ' + state.langloaded} onupdate={actions.onUpdate} oncreate={actions.pendingAjaxQuery} data-loading={'loaded'} key={state.customerssoname}>
        {state.meta !== null &&
            <div class='root-meta' key={state.meta.customer}><Meta {...state.meta}></Meta></div>
        }
        {state.langloaded === 'loaded' &&
            <dummy class='root-dummy'>
                <ProgressBar customer={state.customerssoname}></ProgressBar>
                {dynMod('gamifications', 'isactive') &&
                    <GamifNotifications location={location} customer={state.customerssoname}></GamifNotifications>
                }
                <Switch>
                    {state.customerssoname === 'cerba' &&
                        <Route path='/'>
                            {() => {
                                window.location.href = '/landing/';
                                return <Waiting />
                            }}
                        </Route>
                    }

                    {dynMod('landing', 'isactive') ?
                        <Route path='/'>
                            {() => <Home customer={state.customerssoname} browserlang={state.browserlang} />}
                        </Route>
                        :
                        <Route path='/'>
                            {() => {
                                actions.redirectToLogin();
                                return <Waiting />
                            }}
                        </Route>
                    }

                    {dynMod('landing', 'isactive') ?
                        <Route path={allRoutes['home.index'].pathname}>
                            {() => <Home customer={state.customerssoname} browserlang={state.browserlang} />}
                        </Route>
                        :
                        <Route path={allRoutes['home.index'].pathname}>
                            {() => {
                                actions.redirectToLogin();
                                return <Waiting />
                            }}
                        </Route>
                    }

                    {dynMod('landing', 'isactive') ?
                        <Route path={allRoutes['home.technology'].pathname}>
                            {() => <Technology customer={state.customerssoname} browserlang={state.browserlang} />}
                        </Route>
                        :
                        <Route path={allRoutes['home.technology'].pathname}>
                            {() => {
                                actions.redirectToLogin();
                                return <Waiting />
                            }}
                        </Route>
                    }
                    {dynMod('landing', 'isactive') ?
                        <Route path={allRoutes['home.scientific'].pathname}>
                            {() => <Scientific customer={state.customerssoname} browserlang={state.browserlang} />}
                        </Route>
                        :
                        <Route path={allRoutes['home.scientific'].pathname}>
                            {() => {
                                actions.redirectToLogin();
                                return <Waiting />
                            }}
                        </Route>
                    }
                    {dynMod('landing', 'isactive') ?
                        <Route path={allRoutes['home.enterprise'].pathname}>
                            {() => <Enterprise customer={state.customerssoname} browserlang={state.browserlang} />}
                        </Route>
                        :
                        <Route path={allRoutes['home.enterprise'].pathname}>
                            {() => {
                                actions.redirectToLogin();
                                return <Waiting />
                            }}
                        </Route>
                    }
                    {dynMod('landing', 'isactive') ?
                        <Route path={allRoutes['home.about'].pathname}>
                            {() => <About customer={state.customerssoname} browserlang={state.browserlang} />}
                        </Route>
                        :
                        <Route path={allRoutes['home.about'].pathname}>
                            {() => {
                                actions.redirectToLogin();
                                return <Waiting />
                            }}
                        </Route>
                    }
                    {dynMod('landing', 'isactive') ?
                        <Route path={allRoutes['home.developer'].pathname}>
                            {() => <Developer customer={state.customerssoname} browserlang={state.browserlang} />}
                        </Route>
                        :
                        <Route path={allRoutes['home.developer'].pathname}>
                            {() => {
                                actions.redirectToLogin();
                                return <Waiting />
                            }}
                        </Route>
                    }

                    {dynMod('login', 'isactive') ?
                        <Route path={allRoutes['home.login'].pathname} parent>
                            {({ location, match }) => <Login match={match} location={location} customer={state.customerssoname} language={state.browserlang} />}
                        </Route>
                        :
                        <Route path='/'>
                            {() => {
                                actions.redirectToLogin();
                                return <Waiting />
                            }}
                        </Route>
                    }
                    {dynMod('medicalLogin', 'isactive') ?
                        <Route path={allRoutes[staticsconstants.CUSTOMERS[state.customerssoname].modules.medicalLoginURL].pathname} parent>
                            {({ location, match }) => <Login match={match} location={location} customer={state.customerssoname} language={state.browserlang} />}
                        </Route>
                        :
                        <Route path='/'>
                            {() => {
                                actions.redirectToLogin();
                                return <Waiting />
                            }}
                        </Route>
                    }

                    {dynMod('register', 'isactive') &&
                        <Route path={allRoutes['home.register'].pathname} parent>
                            {({ location, match }) => <Register match={match} location={location} customer={state.customerssoname} language={state.browserlang} />}
                        </Route>
                    }

                    <Route path={allRoutes['private.onboarding'].pathname}>
                        {() => <Onboarding customer={state.customerssoname} displayedon={'regular'} language={state.browserlang} />}
                    </Route>

                    <Route path={allRoutes['private.endonboarding'].pathname}>
                        {() => <OnboardingEnd customer={state.customerssoname} displayedon={'regular'} language={state.browserlang} />}
                    </Route>

                    <Route path={allRoutes['private.doneonboarding'].pathname}>
                        {() => <OnboardingDone customer={state.customerssoname} displayedon={'regular'} language={state.browserlang} />}
                    </Route>

                    {dynMod('timeline', 'isactive') &&
                        <Route path={allRoutes['private.timeline'].pathname}>
                            {() => <Timeline customer={state.customerssoname} language={state.browserlang} />}
                        </Route>
                    }

                    {dynMod('nutrition', 'isactive') &&
                        <Route path={allRoutes['private.food'].pathname}>
                            {() => <Food customer={state.customerssoname} />}
                        </Route>
                    }

                    {dynMod('dashboard', 'isactive') &&
                        <Route path={allRoutes['private.datas'].pathname}>
                            {() => <Datas customer={state.customerssoname} />}
                        </Route>
                    }

                    {dynMod('follow', 'isactive') &&
                        <Route path={allRoutes['private.follow'].pathname}>
                            {() => <Follow customer={state.customerssoname} />}
                        </Route>
                    }

                    {dynMod('programs', 'isactive') &&
                        <Route path={allRoutes['private.programs'].pathname}>
                            {() => <Programs customer={state.customerssoname} />}
                        </Route>
                    }

                    {dynMod('programs', 'isactive') &&
                        <Route path={allRoutes['private.program.start'].pathname}>
                            {({ location, match }) => <ProgramStart match={match} location={location} customer={state.customerssoname} />}
                        </Route>
                    }

                    {dynMod('programs', 'isactive') &&
                        <Route path={allRoutes['private.program.session'].pathname}>
                            {({ location, match }) => <ProgramSession match={match} location={location} customer={state.customerssoname} />}
                        </Route>
                    }

                    {dynMod('account', 'isactive') &&
                        <Route path={allRoutes['private.account'].pathname}>
                            {() => <Account customer={state.customerssoname} />}
                        </Route>
                    }

                    {dynMod('accountMod.favorites', 'isactive') &&
                        <Route path={allRoutes['account.favorites'].pathname}>
                            {() => <Favorites customer={state.customerssoname} />}
                        </Route>
                    }

                    {dynMod('accountMod.objectives', 'isactive') &&
                        <Route path={allRoutes['account.objectives'].pathname}>
                            {() => <Objectives customer={state.customerssoname} language={state.browserlang} />}
                        </Route>
                    }

                    {dynMod('accountMod.oc', 'isactive') &&
                        <Route path={allRoutes['account.oc'].pathname}>
                            {({ location, match }) => <Oc location={location} customer={state.customerssoname} />}
                        </Route>
                    }

                    {dynMod('accountMod.parameters', 'isactive') &&
                        <Route path={allRoutes['account.parameters'].pathname}>
                            {() => <Parameter customer={state.customerssoname} />}
                        </Route>
                    }

                    {dynMod('accountMod.badges', 'isactive') &&
                        <Route path={allRoutes['account.rewards'].pathname}>
                            {() => <Rewards customer={state.customerssoname} />}
                        </Route>
                    }

                    <Route path={allRoutes['private.what.is.klesia_netflix'].pathname}>
                        {() => <SpecificPage customer={state.customerssoname} specific={'what_is_klesia_netflix'} />}
                    </Route>

                    <Route path={allRoutes['public.survey'].pathname}>
                        {() => <PublicSurvey customer={state.customerssoname} />}
                    </Route>

                    <Route path={allRoutes['public.survey.objective'].pathname}>
                        {() => <PublicSurveyObjective customer={state.customerssoname} />}
                    </Route>

                    <Route path={allRoutes['public.survey.resiliate'].pathname}>
                        {() => <PublicSurveyResiliate customer={state.customerssoname} />}
                    </Route>
                    <Route path='/satisfaction'>{/* OLD ROUTE */}
                        {() => <PublicSurveyResiliate customer={state.customerssoname} />}
                    </Route>

                    <Route path={allRoutes['public.expire'].pathname}>
                        {() => <PublicExpire customer={state.customerssoname} />}
                    </Route>

                    <Route path={allRoutes['public.expire.sponsor'].pathname}>
                        {() => <PublicExpireSponsor customer={state.customerssoname} />}
                    </Route>

                    <Route path={allRoutes['public.subscription'].pathname}>
                        {() => <PublicSubscription customer={state.customerssoname} />}
                    </Route>
                    <Route path='/unsubscribeChoice'>{/* OLD ROUTE */}
                        {() => <PublicSubscription customer={state.customerssoname} />}
                    </Route>

                    <Route path={allRoutes['public.blocked'].pathname}>
                        {() => <PublicBlocked customer={state.customerssoname} />}
                    </Route>
                    <Route path='/device/acknowledge'>{/* OLD ROUTE */}
                        {() => <PublicBlocked customer={state.customerssoname} />}
                    </Route>

                    <Route path={allRoutes['public.authorize'].pathname}>
                        {() => <PublicAuthorize  customer={state.customerssoname} />}
                    </Route>
                    <Route path='/device/authorize'>{/* OLD ROUTE */}
                        {() => <PublicAuthorize customer={state.customerssoname} />}
                    </Route>

                    <Route path={allRoutes['public.email.confirm'].pathname}>
                        {() => <PublicEmailConfirm customer={state.customerssoname} />}
                    </Route>
                    <Route path='/email-confirm'>{/* OLD ROUTE */}
                        {() => <PublicEmailConfirm customer={state.customerssoname} />}
                    </Route>

                    <Route path={allRoutes['public.password.reset'].pathname}>
                        {() => <PublicPasswordReset customer={state.customerssoname} />}
                    </Route>
                    <Route path='/session/reset'>{/* OLD ROUTE */}
                        {() => <PublicPasswordReset customer={state.customerssoname} />}
                    </Route>

                    <Route path={allRoutes['public.secret'].pathname}>
                        {() => <PublicSecret customer={state.customerssoname} />}
                    </Route>

                    <Route path={allRoutes['public.resiliate'].pathname}>
                        {({ location, match }) => <PublicResiliate match={match} location={location} customer={state.customerssoname} />}
                    </Route>

                    <Route path={allRoutes['public.cb'].pathname}>
                        {() => <PublicCb customer={state.customerssoname} />}
                    </Route>

                    <Route path={allRoutes['public.maintenance'].pathname}>
                        {() => <PublicMaintenance customer={state.customerssoname} />}
                    </Route>

                    <Route path={allRoutes['public.email.show'].pathname}>
                        {({ location, match }) => <PublicEmailShow match={match} location={location} customer={state.customerssoname} />}
                    </Route>
                    <Route path='/mail'>{/* OLD ROUTE */}
                        {({ location, match }) => <PublicEmailShow match={match} location={location} customer={state.customerssoname} />}
                    </Route>

                    <Route path={allRoutes['public.share'].pathname}>
                        {({ location, match }) => <PublicShare match={match} location={location} customer={state.customerssoname} />}
                    </Route>
                    <Route path='/public'>{/* OLD ROUTE */}
                        {({ location, match }) => <PublicShare match={match} location={location} customer={state.customerssoname} />}
                    </Route>

                    <Route path='/adminpreview'>
                        {({ location, match }) => <AdminPreview match={match} location={location} />}
                    </Route>

                    <Route path={allRoutes['public.transit.webtoapp'].pathname}>
                        {() => <TransitWebToApp customer={state.customerssoname} />}
                    </Route>
                    <Route path={allRoutes['public.transit.objectives'].pathname}>
                        {() => <PublicObjectifs customer={state.customerssoname} language={state.browserlang} />}
                    </Route>

                    <Route path='/waiting/'>
                        {() => <Waiting />}
                    </Route>

                    <Route path='/withings/'>
                        {() => {
                            window.location.href = window.location.href.replace(appCustoSetting.WEB_URL, appCustoSetting.API_URL);
                            return <Waiting />
                        }}
                    </Route>
                    <Route path='/fitbit/'>
                        {() => {
                            window.location.href = window.location.href.replace(appCustoSetting.WEB_URL, appCustoSetting.API_URL);
                            return <Waiting />
                        }}
                    </Route>
                    <Route path='/polar/'>
                        {() => {
                            window.location.href = window.location.href.replace(appCustoSetting.WEB_URL, appCustoSetting.API_URL);
                            return <Waiting />
                        }}
                    </Route>
                    <Route path='/garmin/'>
                        {() => {
                            window.location.href = window.location.href.replace(appCustoSetting.WEB_URL, appCustoSetting.API_URL);
                            return <Waiting />
                        }}
                    </Route>
                    <Route path='/runkeeper/'>
                        {() => {
                            window.location.href = window.location.href.replace(appCustoSetting.WEB_URL, appCustoSetting.API_URL);
                            return <Waiting />
                        }}
                    </Route>
                    <Route path='/misfit/'>
                        {() => {
                            window.location.href = window.location.href.replace(appCustoSetting.WEB_URL, appCustoSetting.API_URL);
                            return <Waiting />
                        }}
                    </Route>
                    <Route path='/ihealth/'>
                        {() => {
                            window.location.href = window.location.href.replace(appCustoSetting.WEB_URL, appCustoSetting.API_URL);
                            return <Waiting />
                        }}
                    </Route>
                    <Route path='/android/'>
                        {() => {
                            window.location.href = window.location.href.replace(appCustoSetting.WEB_URL, appCustoSetting.API_URL);
                            return <Waiting />
                        }}
                    </Route>
                    <Route path='/apple/'>
                        {() => {
                            window.location.href = window.location.href.replace(appCustoSetting.WEB_URL, appCustoSetting.API_URL);
                            return <Waiting />
                        }}
                    </Route>

                    {(isDefined(staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()].modules.noSWRoutes) && (staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()].modules.noSWRoutes.length > 0)) &&
                        <Route path={(staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()].modules.noSWRoutes)}>
                            {() => <PublicUnregisterSW />}
                        </Route>
                    }

                    <Route>
                        {() => <QuatreCentQuatre customer={state.customerssoname} />}
                    </Route>
                </Switch>
                <BotFooterView customer={state.customerssoname}></BotFooterView>

                <span id='btzRuler'></span>
                <div id='btzMainOverlay'></div>
                <div id='btzMainMessage' data-kind='error'>
                    <div id='btzMainMessageCross' onclick={(e) => {
                        getClosest(e.target, '#btzMainMessage').classList.remove('active');
                    }}>
                        <img src={icoCloseWhite} srcset={`${icoCloseWhitex2} 2x`} alt='' />
                    </div>
                    <div id='btzMaintextContent'></div>
                </div>
            </dummy>
        }
    </div>
);

/*app = persist(app, {
    storage: 'my-app/v1',
    include: [ 'router', 'player' ]
});*/

let appOnSteroids = app;
appOnSteroids = withTranslation({})(appOnSteroids) // grabs all locales, set one as default
// appOnSteroids = withStorage({})(appOnSteroids) // syncs state objects in localstorage
appOnSteroids = withSecurity({})(appOnSteroids) // keeps an eye on tokens and triggers actions
appOnSteroids = withRouting({})(appOnSteroids) // grabs all locales, set one as default

if (appCustoSetting.DEBUG) {
    const { withLogger } = require('@hyperapp/logger')
    appOnSteroids = withLogger({})(appOnSteroids)
}


// const instance = persist(appOnSteroids(state, actions, view, document.body), {});
const instance = appOnSteroids(state, actions, view, document.body)
// eslint-disable-next-line
const unsubscribe = location.subscribe(instance.location);

window.main = instance;

// instance.initStorage()

serviceWorker.unregister();