import { h, Component } from '@app/utils';
import { getUser, isDefined, dynLoad } from '@app/core';
import './index.scss';

const state = {
    user: null,
    payment: null,
}

const actions = {
    onComponentCreate: () => (state, actions) => {
        let userCallback = (sessionUser) => {
            if (isDefined(sessionUser)) {
                actions.setUser(sessionUser);
            }
        };
        getUser(userCallback, true);
    },
    setUser: newState => state => ({
        user: newState,
    }),
    setPayment: newState => state => ({
        payment: newState,
    }),
};

const view = dynLoad('view', 'Account/parameter/views');

export default Component(state, actions, view, 'parameter');
