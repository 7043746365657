import { t, h } from '@app/utils';
import { Button, Card, Modal } from '@app/elements';

import icoDeco from '@app/img/food/deco-weekly-blank.png';
import icoDecox2 from '@app/img/food/deco-weekly-blank@2x.png';

import { AddMeal } from '@app/modules/Food/AddMeal';

export const view = (state, actions) => (props, children) => (
    <Card kind={'nomeal-thisweek'}>
        <Card.Body>
            <div class='col-4 btzData-btzDatasWrapper'>
                <Card.Image classes='btzCard-btzImage-btzAskImage'><img data-customer={props.customer} src={icoDeco} srcset={`${icoDecox2} 2x`} alt='' class='' /></Card.Image>
            </div>
            <div class='col-8 btzData-btzDatasWrapper'>
                <p class='btzPageContentTexte'>{t('Aucun repas renseigné pour ce jour.', {ns: 'food'})}</p>
                <p class='btzPageContentSubTexte' style={{ 'margin-bottom': '30px' }}>{t('Cliquez sur le bouton ci-dessous pour ajouter un repas.', {ns: 'food'})}</p>
                <Modal content={<AddMeal customer={props.customer} userGender={props.userGender} ymdMealDate={props.ymdMealDate}></AddMeal>} styme={{ 'display': 'inline-block' }}>
                    <Button primary flat active>
                        {t('Ajouter un repas', {ns: 'food'})}
                    </Button>
                </Modal>
            </div>
        </Card.Body>
    </Card>
);
