import { debounce } from 'hyperapp-fx';
import { t, isLength } from '@app/utils';
import { Session, User } from '@app/api';
import { enableTheseButton, desableTheseButton, isEmail, getUser, getTodayYMD, redirectToOnboarding } from '@app/core';
import { createAndSaveCSRFToken, addCSRFToken, isDefined, isTestMode } from '@app/core';
import { allRoutes } from '@app/settings';
import * as staticsconstants from '@app/constants/constants-statics';

module.exports = {
    initAction: function() {
        let action = {
            onComponentCreate: (props) => (state, actions) => {
                if (props.send) {
                    state.sendToParent = props.send;
                }
                if (props.customer) {
                    actions.setCustomer(props.customer);
                }
                if (props.isMedical) {
                    actions.setIsMedical(props.isMedical);
                }
                localStorage.removeItem('refresh');

                window.onkeyup = function(e) {
                    var key = e.keyCode ? e.keyCode : e.which;
                    if (key === 13) {
                        actions.onSubmit(e);
                    }
                }

                actions.resetApiError();
            },

            onComponentUpdate: (props) => (state, actions) => {
                actions.resetApiError();
            },

            setValues: newState => state => ({
                values: { ...state.values, ...newState },
            }),

            setTouched: newState => state => ({
                isTouched: { ...state.isTouched, ...newState },
            }),

            setValidity: newState => state => ({
                isValid: { ...state.isValid, ...newState },
            }),

            setApiErrors: newState => state => ({
                api: newState,
            }),

            setPasswordState: newState => state => ({
                passwordHidden: newState,
            }),

            setCustomer: newState => state => ({
                customer: newState,
            }),

            setIsMedical: newState => state => ({
                isMedical: newState,
            }),

            isInputValid: ({ name, value }) => (state, actions) => {
                if (name === undefined) {
                    return false;
                }

                state.sendToParent({
                    ns: name,
                    key: 'value',
                    value: value
                });

                switch (name) {
                    case 'email':
                        return isEmail(value);
                    case 'password':
                        return isLength(value, { min: 1 });
                    default:
                        throw Error(`${name} has no validator assigned`);
                }
            },

            contactApiToCheckEmail: async email => await Session.checkEmail(email),

            onCreate: () => (state, actions) => {
                setTimeout(function () {
                    actions.forceHydratation();
                }, 200);
            },

            onUpdate: ({ target: { name, value } }) => (state, actions) => {
                actions.setValues({ [name]: value });
                actions.setValidity({
                    [name]: actions.isInputValid({ name, value }),
                });

                actions.forceHydratation();
            },

            forceHydratation: () => (state, actions) => {
                let nodeList = document.querySelectorAll('.btzForm-btzFormInput');
                for (var i = 0, len = nodeList.length; i < len; i++) {
                    var el = nodeList[i];
                    const { name, value } = el;
                    if (name && value && (name !== undefined)) {
                        actions.setTouched({ [name]: true });
                        actions.setValues({ [name]: value });
                        actions.setValidity({
                            [name]: actions.isInputValid({ name, value }),
                        });
                    }
                }
            },

            waitForLastInput: ({ target }) => debounce(
                120,
                'onUpdate',
                { target },
            ),

            togglePasswordInput: () => (state, actions) => {
                actions.setPasswordState(!state.passwordHidden);
            },

            onBlur: ({ target: { name } }) => (state, actions) => {
                actions.resetApiError();
                actions.setTouched({ [name]: true });
            },

            setFormIsSubmitting: (active) => (state, actions) => ({
                formIsSubmitting: active
            }),

            goToNextStep: () => (state, actions) => {
                window.location.href = allRoutes['home.login.recovery'].pathname + '?email=' + state.values.email;
            },

            resetApiError: () => (state, actions) => {
                actions.setApiErrors({
                    'message' : t('Vous avez une question ?', {ns: 'login'}) + '\n' + t('Contactez-moi :', {ns: 'login'}),
                    'kind' : 'info',
                });
            },

            onSubmit: event => (state, actions) => {
                event.preventDefault();

                if (state.formIsSubmitting) {
                    return false;
                }

                actions.resetApiError();

                const { email } = state.values;

                if ((state.values.email === '') || (state.values.email === undefined)) {
                    actions.setApiErrors({
                        'message' : t('Merci de renseigner une adresse email.', {ns: 'login'}),
                        'kind' : 'error',
                    });
                } else if (!state.isValid.email) {
                    actions.setApiErrors({
                        'message' : t('Veuillez renseigner une adresse email valide.', {ns: 'login'}),
                        'kind' : 'error',
                    });
                } else if (state.values.password === '') {
                    actions.setApiErrors({
                        'message' : t('Merci de renseigner votre mot de passe', {ns: 'login'}),
                        'kind' : 'error',
                    });
                } else if (!state.isValid.password) {
                    actions.setApiErrors({
                        'message' : t('Votre mot de passe n\'est pas reconnu.', {ns: 'login'}),
                        'kind' : 'error',
                    });
                }

                if (state.isValid.email && state.isValid.password) {
                    actions.setFormIsSubmitting(true);
                    Session.checkEmail(email).then((res) => {
                        if (res.data && (res.data.code === 'sponsored')) {
                            actions.setFormIsSubmitting(false);
                            actions.setApiErrors({
                                'message' : t('Veuillez d‘abord vous inscrire pour profiter de votre parrainage au service.', {ns: 'login'}),
                                'kind' : 'error',
                            });
                        } else {
                            if (res.data && (+res.data.stepRegistration < 8)) {
                                actions.setFormIsSubmitting(false);
                                actions.setApiErrors({
                                    'message' : t('Pour pouvoir vous connecter, veuillez d’abord vous inscrire en cliquant sur le bouton en haut à droite de cette page.', {ns: 'login'}),
                                    'kind' : 'error',
                                });
                            } else {
                                actions.createSession();
                            }
                        }
                    });
                }
            },

            createSession: () => (state, actions) => {
                localStorage.removeItem('apitoken');
                localStorage.removeItem('user');
                localStorage.removeItem('expiration');
                localStorage.removeItem('refresh');
                const { email, password } = state.values;
                Session.createSession(email, password, state.customer).then((res) => {
                    actions.setFormIsSubmitting(false);
                    if (res.data && res.data.code) {
                        actions.setApiErrors(res.data);
                    } else {
                        if (res.data === false) {
                            actions.setApiErrors({
                                'message' : t('La combinaison email / mot de passe n‘est pas bonne.', {ns: 'login'}),
                                'kind' : 'error',
                            });
                        } else if (res.data !== undefined) {
                            let sessionsData = res.data;
                            localStorage.setItem('apitoken', sessionsData.token);
                            let mustSeeObjectivesTransitionV6toV7 = false;
                            let userCustomer = '';
                            let userSsos = null;

                            let userCallback = (sessionUser) => {
                                let userIsOnGoodPlateform = true;
                                let mutCstm = null;
                                let platformErrorMssg = '';
                                let knownCustomer = false;
                                userCustomer = sessionUser.customer;
                                userSsos = sessionUser.ssos;
                                if (isDefined(userCustomer) || isDefined(userSsos)) {
                                    let existingCustomer = Object.keys(staticsconstants.CUSTOMERS);
                                    let userIs = state.rootCustomer;
                                    let customerIs = state.customer;
                                    let refMutuellesCustomer = [customerIs];
                                    let particularMutuals = staticsconstants.CUSTOMERS[state.customer.toLowerCase()].particularMutuals.login;
                                    if (isDefined(particularMutuals) && (particularMutuals.length > 0)) {
                                        refMutuellesCustomer = particularMutuals;
                                    }

                                    if (isDefined(userCustomer)) {
                                        knownCustomer = true;
                                        userIs = userCustomer.toLowerCase();
                                        if (userIs.toLowerCase().indexOf(customerIs) > -1) { // to handle customer-temp
                                            userIs = customerIs;
                                        } else {
                                            for (mutCstm in refMutuellesCustomer) {
                                                if (userCustomer.toLowerCase().indexOf(refMutuellesCustomer[mutCstm]) > -1) {
                                                    userIs = customerIs;
                                                }
                                            }
                                        }
                                    }
                                    //
                                    if (isDefined(userSsos)) {
                                        for (var sso in userSsos) {
                                            if (isDefined(userSsos[sso].name)) {
                                                knownCustomer = true;
                                                if (userSsos[sso].name.toLowerCase().indexOf(customerIs) > -1) {
                                                    userIs = customerIs;
                                                    break;
                                                } else {
                                                    for (mutCstm in refMutuellesCustomer) {
                                                        if (userSsos[sso].name.toLowerCase().indexOf(refMutuellesCustomer[mutCstm]) > -1) {
                                                            userIs = customerIs;
                                                            break;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    if (!isDefined(userIs) || userIs === '') {
                                        userIs = state.rootCustomer;
                                    }
                                    if (existingCustomer.indexOf(userIs) === -1) {
                                        // user is not attached to a known customer (cerba, vyv, maternite, etc...)
                                        // assuming user is attached to betterise -> [state.rootCustomer]
                                        userIs = state.rootCustomer;
                                    }
                                    if ((userIs !== customerIs) || (!knownCustomer && state.customer !== state.rootCustomer)) {
                                        userIsOnGoodPlateform = false;
                                    }
                                    console.log('userIs : ', userIs, customerIs);
                                }
                                //
                                if (!userIsOnGoodPlateform) {
                                    if (isDefined(state.customer)) {
                                        platformErrorMssg = t(staticsconstants.CUSTOMERS[state.customer.toLowerCase()].wrongPlateFormMessage);
                                    }

                                    if (platformErrorMssg === '') {
                                        platformErrorMssg = t(staticsconstants.CUSTOMERS[state.rootCustomer].wrongPlateFormMessage);
                                    }
                                }
                                //
                                if (isDefined(sessionUser.profile) && !isDefined(sessionUser.profile.objective, true, true)) {
                                    mustSeeObjectivesTransitionV6toV7 = staticsconstants.CUSTOMERS[state.customer.toLowerCase()].modules.V6toV7transit;
                                }
                                if (isTestMode) {
                                    userIsOnGoodPlateform = true;
                                    console.log('>>>>>>>>>>>> TESTMODE, test mode set userIsOnGoodPlateform to [true], bypassing plateform check');
                                }
                                if (userIsOnGoodPlateform) {
                                    let storedLanguage = localStorage.getItem('language');
                                    if (!isDefined(storedLanguage) || (storedLanguage === '') || (storedLanguage.length !== 5) || (storedLanguage.indexOf('_') === -1)) {
                                        storedLanguage = 'fr_FR';
                                    }
                                    if (sessionUser.language !== storedLanguage) {
                                        User.updateUser({
                                            'language': storedLanguage,
                                        }).then(() => {
                                            actions.redirectUser({sessionsData: sessionsData, sessionUser: sessionUser, mustSeeObjectivesTransitionV6toV7: mustSeeObjectivesTransitionV6toV7});
                                        });
                                    } else {
                                        actions.redirectUser({sessionsData: sessionsData, sessionUser: sessionUser, mustSeeObjectivesTransitionV6toV7: mustSeeObjectivesTransitionV6toV7});
                                    }
                                } else {
                                    localStorage.setItem('apitoken', null);
                                    actions.setApiErrors({
                                        'message' : platformErrorMssg,
                                        'kind' : 'error',
                                    });
                                }
                            };
                            getUser(userCallback, true);
                        } else {
                            actions.setApiErrors({
                                'message' : t('Une erreur critique est survenue, veuillez essayer à nouveaux.', {ns: 'login'}),
                                'kind' : 'error',
                            });
                        }
                    }
                });
            },
            redirectUser: ({sessionsData, sessionUser,  mustSeeObjectivesTransitionV6toV7}) => (state, actions) => {
                localStorage.setItem('refresh', sessionsData.refresh);
                localStorage.setItem('expiration', sessionsData.expiration.date);
                createAndSaveCSRFToken();

                if (mustSeeObjectivesTransitionV6toV7) {
                    window.location.href = addCSRFToken(allRoutes['public.transit.objectives'].pathname);
                } else {
                    redirectToOnboarding(sessionUser.id, getTodayYMD());
                }
            },
        }
        return action;
    }
}
