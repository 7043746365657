import { h, Component } from '@app/utils';
import { addCSRFToken } from '@app/core';
import { allRoutes } from '@app/settings';
import { Link } from '@app/elements';

const TopLogoView = Component(
    {
        customer: 'betterise',
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.customer) {
                actions.setCustomer(props.customer);
            } else {
                actions.setCustomer(process.env.APP_CUSTOMISATION.toLowerCase());
            }
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (props.customer) {
                actions.setCustomer(props.customer);
            } else {
                actions.setCustomer(process.env.APP_CUSTOMISATION.toLowerCase());
            }
        },
        setCustomer: newState => state => ({
            customer: newState
        }),
    },
    (state, actions) => (props, children) => (
        <ul id='btzMainTopLogo'>
            <li style={{ 'display': 'block' }}>
                <Link class={'btn btn-link'} to={((props.redirectTo !== undefined) && (props.redirectTo !== null)) ? props.redirectTo : addCSRFToken(allRoutes['private.timeline'].pathname)} style={{ 'opacity': 1 }}>
                    <img src={`${process.env.APP_WEB_URL}/__custo/${state.customer}/logo-small.png`} srcset={`${process.env.APP_WEB_URL}/__custo/${state.customer}/logo-small@2x.png`} alt='' />
                </Link>
            </li>
        </ul>
    ),
    'toplogoview'
);

export { TopLogoView };