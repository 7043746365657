import { t, h } from '@app/utils';
import { Button, Card, Modal, Social, Trim, Media, Convert } from '@app/elements';
import { isDefined } from '@app/core';

import { ModalViewArticle } from './modalView';

export const view = (state, actions) => (props, children) => (
    <Card kind={'article'}>
        {props.displayedon == 'timeline' &&
            <div>
                <Card.Header>
                    <Social kindcard={'article'} displayedon={'timeline'} title={props.article.title} idfavorite={props.favoriteId} idtimeline={props.timeline} id={props.article.id} favorite={state.favorite} optfavid={props.id} render={actions.rendering} propagate={actions.updateParentProps}></Social>
                    {props.article.media !== undefined &&
                        <Card.Image kind={props.media}><Media src={props.media}></Media></Card.Image>
                    }
                    <p class='btzTimeline-btzCard-btzTitle btzTitle'>{props.article.title}</p>
                    <p class='btzTimeline-btzCard-btzSubTitle btzSubTitle btzTextColored' data-theme={'reading'}>{t('Article', {ns: 'timeline'})}</p>
                </Card.Header>

                <Card.Body classes='btzTopCustomDotted'>
                    <p class='btzTimeline-btzCard-btzDescription btzTexte' innerHTML={<Convert separator={''} is_xhtml={true} type='nl2br'><Trim char={250}>{props.article.description}</Trim></Convert>}></p>
                </Card.Body>

                <Card.Footer>
                    <Modal content={<ModalViewArticle {...state.detail} id={props.article.id} optfavid={props.id} setTag={actions.setTag} alimentDetailDisplayed={state.alimentDetailDisplayed} favorite={state.favorite} propagate={actions.updateParentProps} tagaliment={state.tagaliment} displayedon={'timeline'} showTagDetail={actions.showTagDetail} detailAliment={state.detailAliment} setDetailAliment={actions.setDetailAliment} kindOfInfos={state.kindOfInfos} setKindOfInfos={actions.setKindOfInfos} displayAlimentDetail={actions.displayAlimentDetail}></ModalViewArticle>}>
                        <Button primary data-id={props.id} data-articleid={props.article.id} onclick={actions.getDetailArticle} >
                            <p class='btzTextColored' data-theme={'reading'}>{t('LIRE', {ns: 'timeline'})}</p>
                        </Button>
                    </Modal>
                </Card.Footer>
            </div>
        }

        {props.displayedon == 'favorite' &&
            <Modal modalDisplayed={state.modalDisplayed} content={<ModalViewArticle {...state.detail} setTag={actions.setTag} alimentDetailDisplayed={state.alimentDetailDisplayed} favorite={state.favorite} propagate={props.propagate} tagaliment={state.tagaliment} displayedon={'favorite'} showTagDetail={actions.showTagDetail} detailAliment={state.detailAliment} setDetailAliment={actions.setDetailAliment} kindOfInfos={state.kindOfInfos} setKindOfInfos={actions.setKindOfInfos} displayAlimentDetail={actions.displayAlimentDetail} propagateConfirmClosing={() => actions.setModalDisplayed(false)}></ModalViewArticle>}>
                <Button primary data-id={props.id} data-articleid={props.article.id} onclick={actions.getDetailArticle} >
                    <div>
                        <Card.Header>
                            {props.article.media !== undefined &&
                                <Card.Image kind={props.media}><Media src={props.media}></Media></Card.Image>
                            }
                            <p class='btzTimeline-btzCard-btzTitle btzTitle'>{props.article.title}</p>
                            <p class='btzTimeline-btzCard-btzSubTitle btzSubTitle btzTextColored' data-theme={'reading'}>{t('Article', {ns: 'timeline'})}</p>
                            <p class='btzLightTexte' oncreate={(el) => actions.getDate({'el' : el, 'dat' : (isDefined(props.date) ? props.date.date : null)}) }></p>
                        </Card.Header>
                    </div>
                </Button>
            </Modal>
        }

        {props.displayedon == 'share' &&
            <div>
                <Card.Header classes={'btzCard-btzHeader-btzModal'}>
                    {props.article.media !== undefined &&
                        <Card.Image kind={props.media}><Media src={props.article.media}></Media></Card.Image>
                    }
                    <p class='btzTimeline-btzCard-btzTitle btzTitle'>{props.article.title}</p>
                    <p class='btzTimeline-btzCard-btzSubTitle btzSubTitle btzTextColored' data-theme={'reading'}>{t('Articles', {ns: 'timeline'})}</p>
                </Card.Header>

                <Card.Body classes={'btzCard-btzBody-btzModal btzTopCustomDotted'} style={{ 'margin-bottom': '100px' }}>
                    <p class='btzTimeline-btzCard-btzDescription btzTexte' innerHTML={<Convert separator={''} is_xhtml={true} type='nl2br'>{props.article.description}</Convert>}></p>
                    <p class='btzTimeline-btzCard-btzDescription btzTimeline-btzCard-btzContent btzTexte btzTopCustomDotted' innerHTML={<Convert separator={''} is_xhtml={true} type='nl2br'>{props.article.content}</Convert>}></p>

                    <div class='btzTimeline-btzCard-btzTimeline-btzRecipe-btzTimeline-btzTitles'>
                        {((props.article.tags) && (props.article.tags.length > 0)) &&
                            <div class='btzTags-btzListing'>
                                {props.article.tags.map((item) =>
                                    <div class='btzTag-btzEntry' data-theme={'reading'}>{item}</div>
                                )}
                            </div>
                        }
                    </div>
                </Card.Body>
            </div>
        }
    </Card>
);
