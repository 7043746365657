import { t, h } from '@app/utils';
import { Button, Card } from '@app/elements';

import icoInfo from '@app/img/ico/ico-ask-black.png';
import icoInfox2 from '@app/img/ico/ico-ask-black@2x.png';

import decoStars from '@app/img/deco/deco-stars.png';
import decoStarsx2 from '@app/img/deco/deco-stars@2x.png';

export const view = (state, actions) => (props, children) => (
    <div>
        <Card kind={'challenge'} data-theme={props.valueTheme}>
            {((props.why != '') && (props.why != null)) &&
                <div class='btzInformationsBalance-btzImage' onclick={() => { actions.updateParentPropsWHYtrue(props) }}><img src={icoInfo} srcset={`${icoInfox2} 2x`} alt='' /></div>
            }
            <Card.Header>
                <Card.Image classes={'btzTimelineChallengeImg'}><img src={'/img/timeline/challenge/' + props.valueTheme + '.png'} srcset={`${'/img/timeline/challenge/' + props.valueTheme + '@2x.png'} 2x`} alt='' class='' /></Card.Image>
                <Card.Image><img src={decoStars} srcset={`${decoStarsx2} 2x`} alt='' class='' /></Card.Image>
            </Card.Header>

            <Card.Body>
                <div>
                    <p class='btzTimeline-btzCard-btzDescription btzTitle'>{props.title}</p>
                    <div class='btzHr btzDottedHr'></div>
                    <p class='btzTimeline-btzCard-btzDescription btzSubTitle'>{t('Défi', {ns: 'timeline'})} {props.labelTheme}</p>
                    <p class='btzTimeline-btzCard-btzDescription btzTexte'>{t('Durée', {ns: 'timeline'})} : {props.labelDuration + ' ' + t('jour', {ns: 'timeline'}) + (+props.labelDuration > 1 ? 's' : '')}</p>
                    <div class='btzHr btzDottedHr'></div>
                </div>
            </Card.Body>

            <Card.Footer>
                <div class='col-6' style={{ 'padding-right': '0px' }}>
                    <Button primary active loading={(state.isLoading)} onclick={() => { actions.updateParentPropsREFUSED(props) }} >
                        <p class='btzTextColored'>{t('Non, merci', {ns: 'timeline'})}</p>
                    </Button>
                </div>
                <div class='col-6' style={{ 'padding-left': '0px' }}>
                    <Button primary active loading={(state.isLoading)} onclick={() => { actions.updateParentPropsACCEPTED(props) }} >
                        <p class='btzTextColored'>{t('J‘accepte', {ns: 'timeline'})}</p>
                    </Button>
                </div>
            </Card.Footer>
        </Card>
    </div>
);
