import { t, h } from '@app/utils';
import { allRoutes } from '@app/settings';
import { Link } from '@app/elements';

export const view = (state, actions) => (props, children) => (
    <div key='QuatreCentQuatre'>
        <div class='btzQuatreCentQuatre'>
            <div class='btzQuatreCentQuatre-btzWrapper'>
                <p class='btzTitle btzXl' style={{ 'font-size': '180px' }}>• 404 •</p>
                <p class='btzTitle btzXl'>{t('La page que vous demandez n‘existe pas.', {ns: 'public'})}</p>

                <Link to={allRoutes['index'].pathname}>{t('> Revenir au site <', {ns: 'public'})}</Link>
            </div>
        </div>
    </div>
);