import { h, t } from '@app/utils';
import { Card } from '@app/elements';

import icoInfo from '@app/img/ico/ico-close-black-big.png';
import icoInfox2 from '@app/img/ico/ico-close-black-big@2x.png';

export const ViewVitamineUse = (props, actions) => (
    <Card classes={'btzDetail-btzDetailFakeModal btzDetail-btzMainInformationModal'}>
        <Card.Header classes={'btzCard-btzHeader-btzModal'} style={{ 'margin-top': '100px' }}>
            <div class='btzModal-btzPaddingWrapper'>
                <img src={icoInfo} srcset={`${icoInfox2} 2x`} style={{ 'cursor': 'pointer' }} alt='' class='' onclick={props.closeView} style={{ 'margin': '0 0 30px' }} />

                <p class='btzTitle btzXl'>{t('À quoi servent les vitamines dans l’alimentation ?', {ns: 'food'})}</p>

                <div class='btzDetailFake'>
                    <p class='col-12 btzPageContentTitleBlue p-0' style={{ 'color': 'black' }}>
                        {t('Les vitamines sont des substances organiques indispensables, sans valeur énergétique, que le corps ne synthétise pas ou en quantité insuffisante. Une carence en vitamines peut entraîner des symptômes et des pathologies plus ou moins graves. Les vitamines ont un rôle de régulation en agissant sur les métabolismes énergétiques, glucidiques, protéiques et lipidiques et en luttant contre le vieillissement des cellules.', {ns: 'food'})}
                        <br/><br/>
                        {t('On retrouve les vitamines liposolubles (solubles dans les graisses) A, D, E, K dans les huiles, les poissons gras, le jaune d’œuf, les abats… Les vitamines du groupe B et la vitamine C font partie des vitamines hydrosolubles, qui peuvent se dissoudre dans l’eau. On les trouve principalement dans les fruits, les légumes et les céréales.', {ns: 'food'})}
                    </p>
                </div>
            </div>
        </Card.Header>
    </Card>
);