import { t, h } from '@app/utils';
import { Avatar, Button, Form, Tile, Balloon } from '@app/elements';

import logoStepMemory from '@app/img/register/obj-memory.png';

import './index.scss'

export const view = (state, actions) => (props, children) => (
    <div style={{ 'position': 'relative' }}>
        <Tile>
            <Tile.Content>
                {props.customer === 'vyv' ?
                    <p class='btzTitle btzXl' style={{ 'margin-bottom': '0', 'height': '115px' }}>{t('Quel(s) objectif(s) bien-être', {ns: 'register'})}<br />{t('souhaitez-vous suivre ?', {ns: 'register'})}</p>
                    :
                    <p class='btzTitle btzXl' style={{ 'margin-bottom': '0', 'height': '115px' }}>{t('Quels objectifs santé', {ns: 'register'})}<br />{t('souhaitez-vous suivre ?', {ns: 'register'})}</p>
                }
            </Tile.Content>
        </Tile>

        <Form onsubmit={event => event.preventDefault()}>

            {state.objectives.values && (
                <Form.Group style={{ 'text-align': state.objectives.values.length >= 7 ? 'left' : 'center' }}>
                    {state.objectives.values.map(objective => (
                        <Form.CheckBox
                            placeholder={objective.title}
                            name='objectif[]'
                            image={objective.media ? objective.media.url : logoStepMemory}
                            required
                            checked={!!state.objectives.choosen.find(obj => obj.name === objective.name)}
                            onclick={() => actions.onObjectiveClick(objective.name)}
                        />
                    ))}
                </Form.Group>
            )}

            {state.api.message != '' && (
                <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap', 'padding-right': '105px' }}>
                    <Tile.Icon>
                        <Avatar.Alfred kind={'error'} classes={'centered'} />
                    </Tile.Icon>
                    <Tile.Content>
                        <Balloon kind={'error'} style={{ 'white-space': 'normal' }} actionAfterError={() => {actions.setApiMessage('');}}>
                            {state.api.message}
                        </Balloon>
                    </Tile.Content>
                </Tile>
            )}

            <Form.Group classes='btzForm-btzFooter'>
                <Button primary
                    active={(state.objectives.choosen.length > 0 && state.objectives.choosen.length <= 2)}
                    onclick={(event) => {
                        actions.updateParentProps(props)
                        actions.onStepSubmit({event: event, props: props})
                    }}>
                    {t('Valider', {ns: 'generals'})}
                </Button>
            </Form.Group>

        </Form>
    </div >
);