//async
const dynLoad = function(type, baseurl, root = 'modules') {
    let _v = null;
    if (baseurl !== '') {
        baseurl += '/'
    }
    if ((root !== '') || (root === 'modules')) {
        root += '/'
    }
    try {
        let _custo = process.env.APP_CUSTOMISATION.toLowerCase();
        if (_custo === 'betterise') {
            _custo = 'default';
        }
        _v = require('./../' + root + baseurl + type + '-' + _custo + '.js');
    } catch (error) {
        _v = require('./../' + root + baseurl + type + '-default.js');

        /*
        _v = require(`@app/${root}${baseurl}${type}-${_custo}.js`);
    } catch (error) {
        _v = require(`@app/${root}${baseurl}${type}-default.js`);
        */

        /*_v = await import(`${root}${baseurl}${type}-${_custo}.js`);
    } catch (error) {
        _v = await import(`${root}${baseurl}${type}-default.js`);*/
    }

    if (type === 'view') {
        return _v.initView();
    } else if (type === 'action') {
        return _v.initAction();
    }
    return _v;
}

export { dynLoad };