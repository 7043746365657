import { h, Component, classMerge } from '@app/utils';
import './index.scss';

/**
 * Alfred' speech balloon
 * @param {any} props All properties to element
 * @param {any} children All children to be nested inside
 * @return vNode
 * @example
 * <Balloon {...props}>
 *   {children}
 * </Balloon>
 */
const Avatar = Component(
    {
        classes: null,
        allClasses: '',
        kind: 'info',
        tmout: null,
        oldKind: 'info',
        actionAfterError: null,
        alt: '',
        src: '',
        srcset: '',
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.kind) {
                actions.setKind(props.kind);
                state.allClasses = classMerge([
                    props.kind,
                ]);
            }
            if (props.classes) {
                state.allClasses = classMerge([
                    props.classes,
                    state.allClasses
                ]);
            }
            if (props.actionAfterError) {
                actions.setActionAfterError(props.actionAfterError);
            }

            if (props.kind !== 'error') {
                state.oldKind = props.kind;
                clearTimeout(state.tmout);
                state.tmout = null;
            } else {
                actions.timeBeforeRemovingError();
            }

            if (props.alt) {
                actions.setAlt(props.alt);
            }
            if (props.src) {
                actions.setSrc(props.src);
            }
            if (props.srcset) {
                actions.setSrcset(props.srcset);
            }
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (props.kind) {
                actions.setKind(props.kind);
            }
            if (props.actionAfterError) {
                actions.setActionAfterError(props.actionAfterError);
            }

            if (props.kind !== 'error') {
                state.oldKind = props.kind;
                clearTimeout(state.tmout);
                state.tmout = null;
            } else {
                actions.timeBeforeRemovingError();
            }

            if (props.alt) {
                actions.setAlt(props.alt);
            }
            if (props.src) {
                actions.setSrc(props.src);
            }
            if (props.srcset) {
                actions.setSrcset(props.srcset);
            }
        },
        timeBeforeRemovingError: () => (state, actions) => {
            clearTimeout(state.tmout);
            state.tmout = setTimeout(() => {
                clearTimeout(state.tmout);
                state.tmout = null;
                actions.setKind(state.oldKind);

                if ((state.actionAfterError !== null) && (typeof state.actionAfterError === 'function')) {
                    state.actionAfterError();
                }
            }, 3500);
        },
        setKind: newState => state => ({
            kind: newState,
        }),
        setAlt: newState => state => ({
            alt: newState,
        }),
        setSrc: newState => state => ({
            src: newState,
        }),
        setSrcset: newState => state => ({
            srcset: newState,
        }),
        setActionAfterError: newState => state => ({
            actionAfterError: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <figure class={'btzAvatar centered'} kind={state.kind}>
            <img src={state.src} srcset={state.srcset} alt={state.alt} />
            {children}
        </figure>
    ),
    'avatar'
);

/**
 * Alfred' Avatar
 * @return vNode with Alfred image in
 */
const Alfred = props => <Avatar {...props} src={`${process.env.APP_WEB_URL}/__custo/${(props.customer ? props.customer.toLowerCase() : process.env.APP_CUSTOMISATION.toLowerCase())}/avatar/my-major.png`} srcset={`${process.env.APP_WEB_URL}/__custo/${(props.customer ? props.customer.toLowerCase() : process.env.APP_CUSTOMISATION.toLowerCase())}/avatar/my-major@2x.png 2x`} size={'xl'} alt='Alfred' />;
// const Alfred = props => <Avatar {...props} src={ImgAlfred} srcset={ImgAlfredRetina} size={'xl'} alt='Alfred' />;

Avatar.Alfred = Alfred;

export { Avatar };
