import { allRoutes } from '@app/settings';
import { t, h, Component } from '@app/utils';
import { Grid, Navbar, Button, Enter } from '@app/elements';
import * as constants from '@app/constants';

import './index.scss';

import { TopLogoView } from '@app/layouts/toplogo';

const actions = {
    getDate: (el) => {
        var date = new Date(Date.parse(new Date()));
        var options = { weekday: 'long', month: 'long', day: 'numeric' };
        el.innerHTML = date.toLocaleDateString('fr-FR', options);
    }
}

const view = (state, actions) => (props, children) => (
    <div key={props.page} class={props.page} data-theme={props.theme}>
        <Enter time={constants.custo.ANIM_DURATION} easing={constants.custo.ANIM_EASING} css={constants.custo.ANIM_CSS}>
            <Grid classes={'btzHeader btzHeader-btzNoMediaQuery'}>
                <Grid.Row classes={'btzHeaderWrapper'}>
                    <Grid.Col x={10} mx={'auto'}>
                        <Navbar>
                            <Navbar.Section classes='btzHeader-btzHeaderLeftHand'>
                                <TopLogoView {...props} customer={(props.customer || 'betterise')}></TopLogoView>
                            </Navbar.Section>

                            {props.customer !== 'gjensidige' &&
                                <Navbar.Section classes='btzHeader-btzHeaderRightHand'>
                                    <Button flat active onclick={() => { window.location.href = (props.goToUrl || allRoutes['index'].pathname)}}>{t('Visiter', {ns: 'public'})+ ' ' + (props.goToLabel || 'Betterise')}</Button>
                                </Navbar.Section>
                            }
                        </Navbar>

                    </Grid.Col>
                </Grid.Row>
            </Grid>

            <Grid classes={'btzWrapper btzWrapperFullHeight btzVerticalAlign'} style={{ 'padding-bottom': '100px', 'padding-top': '105px' }}>
                <Grid.Row classes={'btzWrapper-verticalAlignContent'}>
                    <Grid.Col x={12} mx={'auto'} style={{ 'position': 'relative' }}>
                        {children}
                    </Grid.Col>
                </Grid.Row>
            </Grid>
        </Enter>
    </div>
);

export default Component({}, actions, view, 'layout');