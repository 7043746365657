import { debounce } from 'hyperapp-fx';
import { t, h, Component, isLength } from '@app/utils';
import { Form, Button, Tile, Avatar, Balloon, Modal } from '@app/elements';

import { ListingEntry } from './../step-listing-entry';

import icoEdit from '@app/img/ico/ico-adddatas.png';
import icoEditx2 from '@app/img/ico/ico-adddatas@2x.png';

import icoPrevious from '@app/img/ico/ico-prev-light.png';
import icoPreviousx2 from '@app/img/ico/ico-prev-light@2x.png';

const StepEnd = Component(
    {
        lunch: [],
        endStep: 'recap',
        mealContEat: null,
        mealContFeel: null,
        mealContKind: null,
        lunchDataName: {
            value: '',
            isTouched: false,
            isValid: ''
        }
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.lunchAliments) {
                actions.parseLunch(props);
            }

            if (props.mealName) {
                actions.setState({ns : 'lunchDataName', key : 'value', value : props.mealName});
                actions.setState({ns : 'lunchDataName', key : 'isValid', value : true});
            }
            if (props.mealContEat) {
                actions.setMealContEat(props.mealContEat);
            }
            if (props.mealContFeel) {
                actions.setMealContFeel(props.mealContFeel);
            }
            if (props.mealContKind) {
                actions.setMealContKind(props.mealContKind);
            }
        },

        onComponentUpdate: (props) => (state, actions) => {
            if (props.lunchAliments) {
                actions.parseLunch(props);
            }
            if (props.mealContEat) {
                actions.setMealContEat(props.mealContEat);
            }
            if (props.mealContFeel) {
                actions.setMealContFeel(props.mealContFeel);
            }
            if (props.mealContKind) {
                actions.setMealContKind(props.mealContKind);
            }
        },

        parseLunch: (props) => (state, actions) => {
            let uniqFilter = props.lunchAliments;
            uniqFilter = uniqFilter.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.id === thing.id
                ))
            );

            actions.setLunch(uniqFilter);
        },

        setState: ({ ns, key, value }) => state => ({
            [ns]: { ...state[ns], ...{ [key]: value } },
        }),

        setMealContEat: newState => state => ({
            mealContEat: newState,
        }),

        setMealContFeel: newState => state => ({
            mealContFeel: newState,
        }),

        setMealContKind: newState => state => ({
            mealContKind: newState,
        }),

        setLunch: newState => state => ({
            lunch: newState,
        }),

        setEndStep: newState => state => ({
            endStep: newState,
        }),

        setValues: newState => state => ({
            values: { ...state.values, ...newState },
        }),

        setTouched: newState => state => ({
            isTouched: { ...state.isTouched, ...newState },
        }),

        updateLunchName: (props) => (state, actions) => {
            if (props) {
                props.setMealName(state.lunchDataName.value);
            }
        },

        onUpdate: ({ target: { name, value } }) => (state, actions) => {
            let isValid = actions.isInputValid({ name, value });
            actions.setState({ns : 'lunchDataName', key : 'value', value : value});
            actions.setState({ns : 'lunchDataName', key : 'isValid', value : isValid});
        },

        waitForLastInput: ({ target }) => debounce(
            500,
            'onUpdate',
            { target },
        ),

        onBlur: () => (state, actions) => {
            actions.setState({ns : 'lunchDataName', key : 'isTouched', value : true});
        },

        isInputValid: ({ name, value }) => (state, actions) => {
            if (name === undefined) {
                return false;
            }

            switch (name) {
                case 'name':
                    return isLength(value, { min: 1 });
                default:
                    throw Error(`${name} has no validator assigned`);
            }
        },
    },
    (state, actions) => (props, children) => (
        <div key={'listingmode-end'}>
            {state.endStep === 'recap' ?
                <div class='btzData-btzFood-btzListing'>
                    <img src={icoPrevious} srcset={`${icoPreviousx2} 2x`} alt='' class='btzModal-btzPaddingWrapper-btzClose' onclick={() => {
                        if ((props.editing === false) || (props.editing === undefined)) {
                            props.displayLastStep(false);
                        } else {
                            //
                        }
                    }} />

                    <p class='btzPageContentTexte btzPageContentTexteEndLabel btzXl'>{t('Votre repas', {ns: 'food'})}</p>
                    {state.lunch.length > 0 &&
                        <dummy>
                            <div class='btzModal-btzPaddingWrapper' style={{ 'height': 'calc(100vh - 360px)', 'overflow-y': 'auto', 'padding-bottom': '60px', 'padding-right': '0px' }}>
                                {Array.isArray(state.lunch) &&
                                    <div key={'listing-' + state.lunch.filter((f) => f.count > 0).length} style={{ 'padding-bottom': '60px' }}>
                                        {state.lunch.map(item =>
                                            <ListingEntry key={'item-' + item.alias} {...item} lunchAliment={props.lunchAliments.filter((lunch) => lunch.id === item.id)} addOneAliment={props.addOneAliment} removeOneAliment={props.removeOneAliment}></ListingEntry>
                                        )}
                                    </div>
                                }
                            </div>
                        </dummy>
                    }

                    {props.editing === true &&
                        <dummy>
                            <div class='btzFood-btzModal-btzContext'>
                                <p class='btzPageContentTexte btzPageContentTexteEndLabel btzXl'>{t('Contexte du repas', {ns: 'food'})}</p>
                                <div onclick={() => {actions.setEndStep('lunchcontext');}}><img src={icoEdit} srcset={`${icoEditx2} 2x`} alt='' class='' /></div>
                            </div>
                            <div style={{ 'text-align': 'left', 'padding-left': '30px' }}>
                                {[...props.mealContFeel, ...props.mealContKind, ...props.mealContEat].map(item =>
                                    <dummy>
                                        {[...props.mealContFeels, ...props.mealContKinds, ...props.mealContEats].filter((f) => f.key === item).map(meal =>
                                            <Button flat active>
                                                {meal.label[('gender-' + props.gender)]}
                                            </Button>
                                        )}
                                    </dummy>
                                )}
                            </div>
                        </dummy>
                    }

                    <div class='btzFood-btzModal-btzFooter'>
                        {((props.editing === false) || (props.editing === undefined)) ?
                            <dummy>
                                <div class='btzFood-btzModal-btzFooter-btzUsualSwitch'>
                                    <Button.SwitchCheckBox onclick={() => {props.updateUsualState(props.mealUsual === true ? false : true)}} checked={props.mealUsual}>
                                        {t('Enregistrer comme repas habituel', {ns: 'food'})}
                                    </Button.SwitchCheckBox>
                                </div>
                                <div style={{ 'float': 'left' }}>
                                    <Button primary flat active={state.lunch.filter((f) => f.count > 0)[0] !== undefined} onclick={() => {
                                        // props.sendLunch();
                                        actions.setEndStep('lunchcontext');
                                    }}>
                                        <p>{t('Ajouter un contexte', {ns: 'food'})}</p>
                                    </Button>
                                </div>
                            </dummy>
                            :
                            <dummy>
                                <div style={{ 'float': 'left' }}>
                                    <Button primary flat cancel>
                                        <Modal content={''} confirm={true} confirmConfirm={() => {
                                            props.deleteLunch(props.existingLunchId);
                                        }} title={t('Suppression du repas', {ns: 'food'})} description={t('Êtes-vous sûr(e) de vouloir supprimer ce repas', {ns: 'food'})+ ' ?'}>
                                            <p>{t('Supprimer', {ns: 'generals'})}</p>
                                        </Modal>
                                    </Button>
                                </div>
                            </dummy>
                        }
                        <div style={{ 'float': 'right' }}>
                            <Button primary flat active={state.lunch.filter((f) => f.count > 0)[0] !== undefined} onclick={() => {
                                // props.sendLunch();
                                if (state.lunch.filter((f) => f.count > 0)[0] === undefined) {
                                    return false;
                                }
                                if ((props.mealUsual) && ((props.editing === undefined) || (props.editing === false))) {
                                    actions.setEndStep('lunchname');
                                } else {
                                    // actions.setEndStep('lunchcontext');
                                    props.sendLunch();
                                }
                            }}>
                                <p>
                                    {props.editing === true ?
                                        <dummy>{t('Enregistrer', {ns: 'generals'})}</dummy>
                                        :
                                        <dummy>{t('Valider', {ns: 'generals'})}</dummy>
                                    }
                                </p>
                            </Button>
                        </div>
                    </div>
                </div>
                :
                <div>
                    {state.endStep === 'lunchname' ?
                        <div class='btzModal-btzPaddingWrapper' style={{ 'padding-top': '100px' }}>
                            <img src={icoPrevious} srcset={`${icoPreviousx2} 2x`} alt='' class='btzModal-btzPaddingWrapper-btzClose' onclick={() => {
                                actions.setEndStep('recap')
                            }} />

                            {props.mealUsual &&
                                <div>
                                    <div class=''>
                                        <p class='btzTitle btzXl' style={{ 'margin-bottom': '0' }}>{t('Choisissez un nom pour ce repas habituel', {ns: 'food'})}</p>
                                        <Form onsubmit={event => event.preventDefault()}>
                                            <Form.Group style={{ 'white-space': 'nowrap' }}>
                                                <Form.Input
                                                    placeholder={t('Nom du repas ...', {ns: 'food'})}
                                                    type='text'
                                                    name='name'
                                                    value={state.lunchDataName.value}
                                                    oninput={actions.waitForLastInput}
                                                    onfocus={actions.onBlur}
                                                />
                                            </Form.Group>
                                        </Form>
                                    </div>

                                    <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap', 'bottom': '0', 'padding': '15px 30px 90px', 'background-color': 'white' }}>
                                        <Tile.Icon classes={''}>
                                            <Avatar.Alfred classes={'centered'} />
                                        </Tile.Icon>
                                        <Tile.Content>
                                            <Balloon kind={'info'} style={{ 'white-space': 'normal' }}>
                                                <span>{t('Vous pourrez ensuite retrouver ce repas dans vos repas habituels.', {ns: 'food'})}</span>
                                            </Balloon>
                                        </Tile.Content>
                                    </Tile>
                                </div>
                            }

                            <div class='btzFood-btzModal-btzFooter'>
                                <div>
                                    <Button flat primary active={state.lunchDataName.value !== ''} onclick={() => {
                                        if (state.lunchDataName.value === '') {
                                            return false;
                                        }
                                        actions.updateLunchName(props);
                                        props.sendLunch();
                                    }}>
                                        <p>{t('Enregistrer', {ns: 'generals'})}</p>
                                    </Button>
                                </div>
                            </div>
                        </div>
                        :
                        <div class='btzModal-btzPaddingWrapper' style={{ 'height': 'calc(100vh - 360px)', 'overflow-y': 'auto', 'padding-bottom': '60px', 'padding-right': '0px', 'padding-top': '30px' }}>
                            <img src={icoPrevious} srcset={`${icoPreviousx2} 2x`} alt='' class='btzModal-btzPaddingWrapper-btzClose' onclick={() => {
                                if ((props.mealUsual) && ((props.editing === undefined) || (props.editing === false))) {
                                    actions.setEndStep('lunchname');
                                } else {
                                    actions.setEndStep('recap');
                                    // props.sendLunch();
                                }
                            }} />
                            <div class={'btzData-btzMeal-btzMealKind'}>
                                <p class='btzPageContentTexte btzXl' style={{ 'margin-bottom': '20px', 'text-align': 'left', 'display': 'block' }}>{t('Avant, comment vous sentiez-vous ?', {ns: 'food'})}</p>
                                {props.mealContFeels.map(item =>
                                    <Button flat active={(state.mealContFeel.indexOf(item.key) > -1)} onclick={() => {
                                        props.addMealContFeel(item.key);
                                    }}>
                                        {item.label[('gender-' + props.gender)]}
                                    </Button>
                                )}
                            </div>
                            <div class={'btzData-btzMeal-btzMealKind'}>
                                <p class='btzPageContentTexte btzXl' style={{ 'margin-bottom': '20px', 'text-align': 'left', 'display': 'block' }}>{t('Avez-vous mangé :', {ns: 'food'})}</p>
                                {props.mealContEats.map(item =>
                                    <Button flat active={(state.mealContEat.indexOf(item.key) > -1)} onclick={() => {
                                        props.addMealContEat(item.key);
                                    }}>
                                        {item.label[('gender-' + props.gender)]}
                                    </Button>
                                )}
                            </div>
                            <div class={'btzData-btzMeal-btzMealKind'}>
                                <p class='btzPageContentTexte btzXl' style={{ 'margin-bottom': '20px', 'text-align': 'left', 'display': 'block' }}>{t('Quel de type de plat ?', {ns: 'food'})}</p>
                                {props.mealContKinds.map(item =>
                                    <Button flat active={(state.mealContKind.indexOf(item.key) > -1)} onclick={() => {
                                        props.addMealContKind(item.key);
                                    }}>
                                        {item.label[('gender-' + props.gender)]}
                                    </Button>
                                )}
                            </div>

                            <div class='btzFood-btzModal-btzFooter'>
                                <div style={{ 'float': 'right' }}>
                                    <Button flat primary active={state.lunch.filter((f) => f.count > 0)[0] !== undefined} onclick={() => {
                                        // actions.updateLunchContext(props);
                                        // actions.setEndStep('lunchcontext');
                                        if (state.lunch.filter((f) => f.count > 0)[0] !== undefined) {
                                            actions.setEndStep('recap');
                                        }
                                    }}>
                                        <p>{t('Valider', {ns: 'generals'})}</p>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    ),
    'addmealend'
);

export { StepEnd };
