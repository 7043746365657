import * as staticsconstants from '@app/constants/constants-statics';

const isPublicRoute = function() {
    var currentUrl = window.location.href;
    var publicRoutes = (staticsconstants.PUBLIC_ROUTES || []);
    var notPublicRoutes = (staticsconstants.PUBLIC_ROUTES_NOT || []);
    var isPublic = false;
    if ((window.location.pathname === '/') || (window.location.pathname.indexOf('pdf') > -1)) {
        isPublic = true;
    } else {
        for (var route in publicRoutes) {
            if (currentUrl.indexOf(publicRoutes[route]) > -1) {
                isPublic =  true;
            }
        }
        for (var notroute in notPublicRoutes) {
            if (currentUrl.indexOf(notPublicRoutes[notroute]) > -1) {
                isPublic =  false;
            }
        }
    }
    return isPublic;
}

export { isPublicRoute };