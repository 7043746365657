let notificationSandBox = false;

const handleHeaders = (response) => {
    let header_X_NOTIFICATIONS = response.headers.get('X-Notifications');
    if ((header_X_NOTIFICATIONS !== null) && (+header_X_NOTIFICATIONS > 0)) {
        if (notificationSandBox) {
            // return response;
        }
        notificationSandBox = true;
        var event = new CustomEvent('displayGamifNotification');
        window.dispatchEvent(event);
    }
    return response;
}

export { handleHeaders };