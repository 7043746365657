import { StripeID } from './../../Register/step-payment/stripeWrapper';

export const state = {
    // eslint-disable-next-line
    stripe: Stripe(StripeID),
    stripeElementCardNumber: null,
    stripeElementCardExpiry: null,
    stripeElementCardCvc: null,
    cbFormDisplay: false,
    user: null,
    apiErrors: '',
    apiErrorsStatus: '',
    submitting: false,
    stripeElement: null,
    allFieldsAreValid: false,
    ensureOneSubmit: false,
};
