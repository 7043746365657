import { t, h } from '@app/utils';
import { Avatar, Balloon, Button, Form, Tile } from '@app/elements';
import * as staticsconstants from '@app/constants/constants-statics';

module.exports = {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <div style={{ position: 'relative' }}>
                <Tile classes={'btzAlfred btzAlfredHeader'} style={{ 'white-space': 'nowrap' }}>
                    <Tile.Icon>
                        <Avatar.Alfred kind={'info'} classes={'centered'} />
                    </Tile.Icon>
                    <Tile.Content>
                        <Balloon kind={'info'} style={{ 'white-space': 'normal', 'margin': '0 0 0 15px' }}>
                            <p class='btzBaloon-btzTitle'>
                                {t('Bonjour', {ns: 'register'})}
                                <img src='https://emojipedia-us.s3.amazonaws.com/thumbs/120/apple/129/waving-hand-sign_1f44b.png' style={{ 'display': 'inline', 'width': '18px', 'height': '18px', 'margin': '0 7px' }} />
                                <font>
                                    <br />{t('Je suis', {ns: 'register'}) + ' ' + staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()].helper + ' .'}<br />{t('C’est moi qui vais vous accompagner.', {ns: 'register'})}
                                </font>
                            </p>
                        </Balloon>
                    </Tile.Content>
                </Tile>

                <Tile style={{ 'text-align': 'left' }}>
                    <Tile.Content>
                        <p class='btzTitle btzXl' style={{ 'margin': '25px 0' }}>{t('Comment dois-je vous', {ns: 'register'})}<br />{t('appeler ?', {ns: 'register'})}</p>
                    </Tile.Content>
                </Tile>

                <Form onsubmit={event => event.preventDefault()}>

                    <Form.Group>
                        <Form.Input
                            placeholder={t('Votre prénom', {ns: 'register'})}
                            type='text'
                            id='fname'
                            name='fname'
                            value={state.fname.value}
                            oninput={actions.onUpdate}
                            onfocus={() => actions.onFNameInputTouched()}
                            classes={(state.fname.isTouched) ? (state.fname.isValid ? 'is-success' : 'is-error') : ''}
                            required
                        />
                    </Form.Group>

                    <Form.Group classes='btzForm-btzFooter'>
                        <Button primary block onclick={event => {
                            actions.updateParentProps(props);
                            actions.onStepSubmit({event: event, props: props});
                        }}
                        active={(state.fname.isValid)
                        }>
                            {t('Valider', {ns: 'generals'})}
                        </Button>
                    </Form.Group>
                </Form>

                {state.api.message != '' && (
                    <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap' }}>
                        <Tile.Icon>
                            <Avatar.Alfred classes={'centered'} />
                        </Tile.Icon>
                        <Tile.Content>
                            <Balloon kind={'error'} style={{ 'white-space': 'normal' }} actionAfterError={() => {actions.setApiErrors({'message': '','kind': 'info'});}}>
                                {state.api.message}
                            </Balloon>
                        </Tile.Content>
                    </Tile>
                )}
            </div>
        );
        return view;
    }
}
