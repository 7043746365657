import { h, t } from '@app/utils';
import { Card } from '@app/elements';

export const ModalViewSurvey = (props, actions) => (
    <Card classes={'btzInformations-btzInformationsModal btzInformations-btzSurveyModal'}>
        <Card.Header classes={'btzCard-btzHeader-btzModal'}>
            <div class='btzModal-btzPaddingWrapper'>
                <p class='btzTitle btzXl'>{t('Pourquoi doit-on surveiller ces aliments ?', {ns: 'food'})}</p>
                <p class='col-12 btzLightTexte btzXl' style={{ 'font-size': '16px', 'text-align': 'left', 'color': 'black', 'padding': '0 0 30px 0' }}>
                    <font>{t('Gras', {ns: 'food'})}</font>
                    {t('Les lipides peuvent être utilisés à des fins énergétique mais, comme ils ne sont pas prioritaires sur les glucides, ils vont d’abord être mis en réserve. En excès, ils sont stockés dans les cellules lipidiques, ce qui augmente la masse graisseuse et entraîne une prise de poids.', {ns: 'food'})}
                    <br/><br/>
                    {t('Parmi les lipides, on retrouve également les acides gras saturés dont la consommation est à limiter. En effet, ils sont en partie responsables de l’hypercholestérolémie, des maladies cardiovasculaires et du diabète. Leurs apports ne devraient pas dépasser 12% des apports énergétiques de la journée. On les retrouve principalement dans les viandes, les charcuteries, les fromages, la crème…', {ns: 'food'})}
                    <br/><br/><br/>
                    <font>{t('Sucres', {ns: 'food'})}</font>
                    {t('La consommation excessive de produits sucrés est responsable du surpoids, de l’obésité, du diabète de type 2, des maladies cardiovasculaires et des caries. Leur consommation ne devrait pas dépasser 10% des apport énergétique totaux, sans compter les fruits (5% d’après l’OMS).', {ns: 'food'})}
                    <br/><br/><br/>
                    <font>{t('Sel', {ns: 'food'})}</font>
                    {t('Le sel est indispensable au bon fonctionnement du corps mais il est important de ne pas le consommer en excès. En excès, il entraîne de l’hypertension, des maladies cardiovasculaires, une fonte osseuse et un déséquilibre acido-basique. La consommation de sel ne devrait pas dépasser 8 g par jour pour les hommes et 6,5 g pour les femmes.', {ns: 'food'})}
                    <br/><br/><br/>
                    <font>{t('Alcool', {ns: 'food'})}</font>
                    {t('Les recommandations en matière d’alcool sont de l’ordre de 2 verres maximum par jour. En excès, en plus de provoquer une dépendance, l’alcool est responsable de l’apparition de cancers.', {ns: 'food'})}
                </p>
            </div>
        </Card.Header>
    </Card>
);