import { t } from '@app/utils'

export const actions = {
    onComponentCreate: (props) => (state, actions) => {
        state.favorite = (props.favorite || false);
    },
    onComponentUpdate: (props) => (state, actions) => {
        // actions.rendering();
        //props.update();
    },
    setDetailAliment: newState => state => ({
        detailAliment: newState,
    }),
    displayAlimentDetail: displayed => state => ({
        alimentDetailDisplayed: displayed,
    }),
    setTag: newState => state => ({
        tagaliment: newState,
    }),
    setModalDisplayed: newState => state => ({
        modalDisplayed: newState,
    }),
    setKindOfInfos: newState => state => ({
        kindOfInfos: newState,
    }),
    trim: (str) => {
        if (str.length > 250) {
            return (str.substr(0, 245) + '...');
        }
        return str;
    },
    updateParentProps: (props) => (state, actions) => {
        actions.setFavoriteState();
    },
    setFavoriteState: () => state => ({
        favorite: !state.favorite,
    }),
    rendering: () => state => ({
        rendering: !state.rendering,
    }),
    getDate: ({el, dat}) => {
        if (dat === null) {
            el.innerHTML = 'Ø';
            return false;
        }
        dat = dat.replace(/-/g, '/');
        dat = dat.split(' ')[0];
        var date = new Date(Date.parse(new Date(dat)));
        var options = { month: 'long', day: 'numeric', year: 'numeric' };
        el.innerHTML = (t('Ajouté le', {ns: 'generals'}) + ' : ' + date.toLocaleDateString('fr-FR', options));
    }
};
