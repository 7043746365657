export { default as Root, About, Developer, Enterprise, Home, Scientific, Technology } from './Home';
export { default as Login } from './Login';
export { default as Register } from './Register';
export { default as Onboarding } from './Onboarding';
export { default as OnboardingEnd } from './OnboardingEnd';
export { default as OnboardingDone } from './OnboardingDone';
export { default as Timeline } from './Timeline';
export { default as Datas } from './Datas';
export { default as Follow } from './Follow';
export { default as Programs } from './Programs';
export { default as ProgramStart } from './ProgramStart';
export { default as ProgramSession } from './ProgramSession';
export { default as Account } from './Account';
export { default as SpecificPage } from './SpecificPage';

export { default as Food } from './Food';
export { default as AddMeal } from './Food/AddMeal';
export { default as DayMeal } from './Food/Meal/daymeals';
export { default as NoMealAtAll } from './Food/NoMeal/atall';
export { default as NoMealLoading } from './Food/NoMeal/loading';
export { default as NoMealThisWeek } from './Food/NoMeal/thisweek';
export { default as NoMealTransit } from './Food/NoMeal/transit';

export { default as Favorites } from './Account/favorites';
export { default as Objectives } from './Account/objectives';
export { default as Oc } from './Account/oc';
export { default as Parameter } from './Account/parameter';
export { default as Rewards } from './Account/rewards';

export { default as Abonnement } from './Account/parameter/abonnement';
export { default as Cb } from './Account/parameter/cb';
export { default as Health } from './Account/parameter/health';
export { default as Informations } from './Account/parameter/informations';
export { default as Resiliate } from './Account/parameter/resiliate';
export { default as Sponsor } from './Account/parameter/sponsor';

export { default as PublicSurvey } from './Public/survey/survey';
export { default as PublicSurveyObjective } from './Public/survey/surveyObjective';
export { default as PublicSurveyResiliate } from './Public/survey/surveyResiliate';
export { default as PublicMaintenance } from './Public/maintenance';
export { default as PublicExpire } from './Public/expire/expire';
export { default as PublicExpireSponsor } from './Public/expire/expiresponsor';
export { default as PublicSubscription } from './Public/subscription';
export { default as PublicBlocked } from './Public/blocked';
export { default as PublicAuthorize } from './Public/authorize';
export { default as PublicEmailConfirm } from './Public/email/confirm';
export { default as PublicEmailShow } from './Public/email/show';
export { default as PublicPasswordReset } from './Public/password/reset';
export { default as PublicSecret } from './Public/secret';
export { default as PublicShare } from './Public/share';
export { default as PublicResiliate } from './Public/resiliate';
export { default as PublicCb } from './Public/cb';
export { default as PublicUnregisterSW } from './Public/unregistersw';
export { default as AdminPreview } from './Public/adminpreview';

export { default as QuatreCentQuatre } from './Public/quatrecentquatre';
export { default as Waiting } from './Public/waiting';
export { default as TransitWebToApp } from './Public/transit/webtoapp';
export { default as PublicObjectifs } from './Public/transit/objectifs';