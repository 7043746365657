import { addCSRFToken, displayErrors } from '@app/core';
import { allRoutes } from '@app/settings';
import * as staticsconstants from '@app/constants/constants-statics';

const handleErrors = (response) => {
    let customer = localStorage.getItem('readablecustomer');
    /*
    125     La licence de votre parrain est expirée (page d'expiration spécifique)
    126     Votre parrain a résilié sa licence (page d'expiration spécifique)
    129     Votre mutuelle n‘est plus partenaire (page d'expiration spécifique)
    130     Votre entreprise n‘est plus partenaire (page d'expiration spécifique)
    122     Votre licence est expirée (redirection vers le paiement, sans passer par une page d'expiration spécifique)
     */
    console.log('CONTROLLER >>>> error: ', response);
    if (response.code && (response.code !== null) && (response.code !== undefined)) {
        switch (+response.code) {
            case 102: // user not found, see #BL-5515
            case 200:
            case 201:
            case 202:
            case 203: // wrong email/pwd
            case 303: // no timeline for this day
            case 433: // invalid birthdate (onco login)
            case 510: // tracker conflict
            case 812: // invalid invitation code, see #BL-5516
            case 907: // no debit was done with this credit card (this call is made from user profil)
                // OK, no error to display
                break;

            case 208: // account blocked
                localStorage.removeItem('apitoken');
                if ((staticsconstants.CUSTOMERS[customer] !== undefined) && (staticsconstants.CUSTOMERS[customer].logout !== undefined)) {
                    window.location.href = staticsconstants.CUSTOMERS[customer].logout + '?blocked=true';
                } else {
                    window.location.href = allRoutes['home.login'].pathname + '/blocked';
                }
                return; // dont remove it or redirect is not done
                break;
            case 2304: // medical cards already answered today
                window.location.href = addCSRFToken(allRoutes['private.doneonboarding'].pathname);
                return; // dont remove it or redirect is not done
                break;
            case 125: // sponsor licence expired
                window.location.href = allRoutes['public.expire'].pathname + '?kind=sponsor&type=expired';
                return; // dont remove it or redirect is not done
                break;
            case 126: // sponsor licence resiliated
                window.location.href = allRoutes['public.expire'].pathname + '?kind=sponsor&type=resiliated';
                return; // dont remove it or redirect is not done
                break;
            case 117: // expired account, customer license is expired
            case 129: // mutual not sponsor anymore
            case 805: // customer license is expired
                window.location.href = allRoutes['public.expire'].pathname + '?kind=mutual&type=expired';
                return; // dont remove it or redirect is not done
                break;
            case 130: // enterprise not sponsor anymore
                window.location.href = allRoutes['public.expire'].pathname + '?kind=enterprise&type=expired';
                return; // dont remove it or redirect is not done
                break;
            case 122: // no valid payment method
                window.location.href = allRoutes['public.expire'].pathname;
                return; // dont remove it or redirect is not done
                break;
            case 900: // an error occur
                window.location.href = addCSRFToken(allRoutes['public.cb'].pathname);
                return; // dont remove it or redirect is not done
                break;

            case 115:
                localStorage.removeItem('apitoken');
                window.location.href = allRoutes['home.register'].pathname + '?register=incomplete';
                break;

            case 103: // disabled account
            case 210:
                localStorage.removeItem('apitoken');
                if ((staticsconstants.CUSTOMERS[customer] !== undefined) && (staticsconstants.CUSTOMERS[customer].logout !== undefined)) {
                    window.location.href = staticsconstants.CUSTOMERS[customer].logout + '?deactivated=true';
                } else {
                    window.location.href = allRoutes['home.login'].pathname + '?err=deactivated';
                }
                return; // dont remove it or redirect is not done
                break;

            case 205:
            case 204: // expired session
                localStorage.removeItem('apitoken');
                if ((staticsconstants.CUSTOMERS[customer] !== undefined) && (staticsconstants.CUSTOMERS[customer].logout !== undefined)) {
                    window.location.href = staticsconstants.CUSTOMERS[customer].logout + '?expired=true';
                } else {
                    window.location.href = allRoutes['home.login'].pathname + '?err=expired';
                }
                return; // dont remove it or redirect is not done
                break;

            default:
                displayErrors((response.message !== null ? response.message : response.code), 2000);
        }
        console.log('CONTROLLER >>>> error: returning false...');
        return false;
    } else {
        // everything is ok
    }
    console.log('CONTROLLER >>>> error: returning ', response);
    return response;
}

export { handleErrors };
