import * as staticsconstants from '@app/constants/constants-statics';

export const actions = {
    onComponentCreate: (props) => (state, actions) => {
        if (props.initial) {

        }
        if (props.customer) {
            if (['cerba', 'coronavirus'].indexOf(props.customer) > -1) {
                actions.goToNextStep(props);
            }
        }
    },
    onComponentUpdate: (props) => (state, actions) => {
        if (props.initial) {

        }
        if (props.customer) {
            if (['cerba', 'coronavirus'].indexOf(props.customer) > -1) {
                actions.goToNextStep(props);
            }
        }
    },

    setState: ({ ns, key, value }) => (state, actions) => ({
        [ns]: { ...state[ns], ...{ [key]: value } }
    }),

    goToNextStep: (props) => (state, actions) => {
        if (!staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()].modules.registerWorkflow.device) {
        // if (props.customer && (props.customer === 'klesia_netflix')) {
            window.main.location.go('/home/register/cgu');
        } else {
            window.main.location.go('/home/register/device');
        }
    },

    onStepSubmit: (props) => (state, actions) => {
        actions.goToNextStep(props)
    },

    updateParentProps: (props) => (state, actions) => {
        // props.send({
        //     ns: 'welcome',
        //     key: 'passed',
        //     value: true
        // })
    }
};
