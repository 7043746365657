import { User } from '@app/api';
import { getUser, isDefined } from '@app/core';
import { t, h, Component } from '@app/utils';
import { Button, Card, Tile, Avatar, Balloon } from '@app/elements';

import * as staticsconstants from '@app/constants/constants-statics';
import * as constants from '@app/constants';

import './index.scss';

import bgStarsx2 from '@app/img/deco/deco-stars-quizz@2x.png';

import bgFlowerLevelx2 from '@app/elements/Gamif/deco-notif-lvlup@2x.png';

import AccountLayout from '@app/layouts/account/layout';
import Reward from './reward';

const state = {
    rewards: {},
    gamifications: null,
    filter: ['all'],
    userMedia: null,
}

const actions = {
    onComponentCreate: () => (state, actions) => {
        let userCallback = (sessionUser) => {
            state.userMedia = sessionUser.media.url;
        };
        getUser(userCallback);

        let rewards = {};
        constants.custo.GAMIF_THEMES.map((item, idx) => {
            item.badges = [];
            rewards[idx] = item;
        });
        rewards.all = [];
        rewards.done = [];
        rewards.last = null;
        actions.setRewards(rewards);

        User.getUserGamification().then((res) => {
            var alls = [], dones = [], last = null;
            for (var rew in res.data) {
                rew = res.data[rew];

                if (rew.done === true) {
                    dones.push(rew);
                    if (rew.completion.date !== undefined) {
                        if ((last === null) || (new Date(last.completion.date).getTime() < new Date(rew.completion.date).getTime())) {
                            last = rew;
                        }
                    }
                }

                alls.push(rew);
            }
            actions.setState({ns: 'rewards', key: 'all', value: alls});
            actions.setState({ns: 'rewards', key: 'done', value: dones});
            actions.setState({ns: 'rewards', key: 'last', value: last});
        });
        //
        /*let tmpGamif = {
            "next" : {
                "media" :
                    {"name" : "Niveau 2.png", "pathname" : null, "hash" : "52b9c845487f746191efcb04c57204d9", "kind" : null, "length" : "29248", "mediatype" : "0", "url" : "https://cdn-release.betterise.me/medias/5baba7b26617a372588b45cf", "id" : "5baba7b26617a372588b45cf", "type" : "image/png"}
                ,
                "value" : 2,
                "points" : 10,
                "name" : "LEVEL2",
                "id" : "5baba7b26617a305618b4572"
            },
            "points" : 39555,
            "current" : {
                "media" :
                    { "name" : "Niveau 1.png", "pathname" : null, "hash" : "a3e980efcd81e8a356ef7bdb374cae20", "kind" : null, "length" : "28186", "mediatype" : "0", "url" : "https://cdn-release.betterise.me/medias/5baba7c96617a32f5a8b45b6", "id" : "5baba7c96617a32f5a8b45b6", "type" : "image/png" }
                ,
                "value" : 1,
                "points" : 0,
                "name" : "LEVEL1",
                "id" : "5baa42cd6617a37d7f8b456c"
            }
        }
        actions.setGamification(tmpGamif);
        return;*/
        User.getUserGamificationStatus().then((res) => {
            let gamif = res.data;
            actions.setGamification(gamif);
        });
    },
    setRewards: newState => state => ({
        rewards: newState,
    }),
    setFilter: newState => state => ({
        filter: newState
    }),
    addFilter: (id) => (state, actions) => {
        let filter = JSON.parse(JSON.stringify(state.filter));
        let allIdx = filter.indexOf('all');
        filter = (allIdx > -1 ? [] : filter);

        if (filter.indexOf(id) > -1) {
            filter.splice(filter.indexOf(id), 1);
        } else {
            filter.push(id);
        }
        if (filter.length === 0) {
            filter = ['all'];
        }
        actions.setFilter(filter);
    },
    setGamification: newState => state => ({
        gamifications: newState
    }),
    setState: ({ ns, key, value }) => state => ({
        [ns]: { ...state[ns], ...{ [key]: value } },
    }),
};

const view = (state, actions) => (props, children) => (
    <AccountLayout page='rewards' readablePageLabel={t(staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()].menuNames.account.title)} readablePageDescription={t('Pour gérer au mieux votre accompagnement santé', {ns: 'account'})} userMedia={state.userMedia} customer={props.customer}>
        {((state.gamifications !== undefined) && (state.gamifications !== null)) &&
            <Card classes={'btzPageCard'} kind={'gamification'}>
                <Card.Header classes={'btzCard-btzHeader-btzPage'}>
                    <div class='col-12'>
                        <p class='btzTimeline-btzCard-btzTitle btzPageContentTitle'>{t('Le statut', {ns: 'rewards'})}</p>
                        <p class='btzTimeline-btzCard-btzSubTitle btzPageContentSubTitle'>{t('Retrouvez votre nombre de points total et le niveau actuel que vous avez atteint.', {ns: 'rewards'})}</p>
                    </div>
                </Card.Header>

                <Card.Body style={{ 'padding-top': '0px' }}>
                    {state.gamifications.current !== null &&
                        <div class='col-5 col-xs-12' style={{ 'vertical-align': 'top' }}>
                            <div class='btzLastBadges btzLastGamification' style={{ 'background-image': 'url(' + bgStarsx2 + ')' }}>
                                <p class='btzTimeline-btzCard-btzTitle btzPageContentTitle'>{t('Niveau', {ns: 'rewards'})}</p>
                                {((state.gamifications.current.media) && (state.gamifications.current.media !== null) && (state.gamifications.current.media.url !== undefined)) ?
                                    <div class='col-12 btzCard-btzGamification' style={{ 'background-image': 'url(' + state.gamifications.current.media.url + ')' }}></div>
                                    :
                                    <div class='col-12 btzCard-btzGamification' style={{ 'background-image': 'url(' + bgFlowerLevelx2 + ')' }}>
                                        {state.gamifications.current.value}
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    <div class='col-1 hide-xs'></div>
                    {state.gamifications.next !== null &&
                        <div class='col-6 col-xs-12' style={{ 'vertical-align': 'top' }}>
                            <div style={{ 'padding-left': '60px' }}>
                                <p class='btzTimeline-btzCard-btzTitle btzPageContentTitle'>{t('Points', {ns: 'rewards'})}</p>
                                <div class='btzJauge-btzSimpleJauge-btzWrapper'>
                                    <div class='btzJauge-btzSimpleJauge' style={{ 'width': (Math.round((+state.gamifications.points * 100) / +state.gamifications.next.points) + '%') }} data-percent={Math.round((+state.gamifications.points * 100) / +state.gamifications.next.points)}>

                                    </div>
                                </div>
                                {(isDefined(state.gamifications.next.points) && isDefined(state.gamifications.next.value)) ?
                                    <p class='btzTexte'><b>{state.gamifications.points}</b>{' ' + t('points sur', {ns: 'rewards'}) + ' ' + state.gamifications.next.points + ' ' + t('jusqu’au niveau', {ns: 'rewards'}) + ' ' + (isNaN(+state.gamifications.next.value) ? 'Ø' : +state.gamifications.next.value)}</p>
                                    :
                                    <p class='btzTexte'>{t('Vous avez atteint le niveau maximum avec', {ns: 'rewards'}) + ' '}<b>{state.gamifications.points}</b>{' ' + t('points', {ns: 'rewards'})}</p>
                                }
                            </div>
                            <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap', 'margin-top': '60px' }}>
                                <Tile.Icon classes={''}>
                                    <Avatar.Alfred classes={'centered'} />
                                </Tile.Icon>
                                <Tile.Content>
                                    <Balloon kind={'info'} style={{ 'white-space': 'normal' }}>{t('Gagnez des points et des badges pour monter de niveau en effectuant des actions quotidiennes sur l’application !', {ns: 'rewards'})}</Balloon>
                                </Tile.Content>
                            </Tile>
                        </div>
                    }
                </Card.Body>

                <Card.Footer></Card.Footer>
            </Card>
        }

        <Card classes={'btzPageCard'} kind={'reward'}>
            <Card.Header classes={'btzCard-btzHeader-btzPage'}>
                <div class='col-12'>
                    <p class='btzTimeline-btzCard-btzTitle btzPageContentTitle'>{t('Tous les badges', {ns: 'rewards'})} ({state.rewards.done.length} {state.rewards.done.length > 1 ? t('gagnés', {ns: 'rewards'}) : t('gagné', {ns: 'rewards'})} {t('sur', {ns: 'rewards'})} {state.rewards.all.length})</p>
                    <p class='btzTimeline-btzCard-btzSubTitle btzPageContentSubTitle'>{t('Cliquez sur un badge pour voir comment le gagner', {ns: 'rewards'})}</p>
                </div>
            </Card.Header>

            <Card.Body data-displayed-kind={state.filter} style={{ 'padding-top': '0px' }}>
                <div class='col-12 btzBadges-btzMenu p-0'>
                    <Button primary flat active={(state.filter.indexOf('all') > -1)} onclick={() => actions.setFilter(['all'])} >{t('Tous', {ns: 'rewards'})} ({state.rewards.all.length})</Button>
                    {constants.custo.GAMIF_THEMES.map(item =>
                        <Button primary flat active={(state.filter.indexOf(item.key) > -1)} onclick={() => actions.addFilter(item.key)} >{t(item.readable)} ({state.rewards.all.filter((filt) => filt.category.toLowerCase() === item.key.toLowerCase()).length})</Button>
                    )}
                </div>

                {state.rewards.last !== null &&
                    <div class='btzLastBadges' style={{ 'background-image': 'url(' + bgStarsx2 + ')' }}>
                        <p class='btzAboveTitle'>{t('Dernier badge gagné :', {ns: 'rewards'})}</p>
                        <div id={'rew-last-' + state.rewards.last.id} key={state.rewards.last.id} class='col-12 btzCard-btzReward' style={{ 'margin': '20px 0 0' }}>
                            <Reward {...state.rewards.last}></Reward>
                        </div>
                        <p class='btzPageContentTexte' style={{ 'line-height': '1.2', 'margin-top': '20px' }}>{state.rewards.last.title}</p>
                        <p class='btzAboveSubTitle' style={{ 'margin-top': '15px' }}>{state.rewards.last.category.toUpperCase()}</p>
                    </div>
                }

                <div class='btzCard-btzRewards' key={state.filter.length} style={{ 'text-align': 'left' }}>
                    {state.rewards.all.map(item =>
                        <div id={'rew-' + item.id} key={item.id} class={'col-2 col-md-6 col-xs-12 btzCard-btzReward ' + (((state.filter.indexOf(item.category) > -1) || (state.filter.indexOf('all') > -1)) ? 'active': '')}>
                            <Reward {...item}></Reward>
                        </div>
                    )}
                </div>
            </Card.Body>

            <Card.Footer></Card.Footer>
        </Card>
    </AccountLayout>
);

export default Component(state, actions, view, 'rewards');
