const convertObjectToArray = function(obj) {
    let arr = []
    if (typeof obj === 'object') {
        for (var item in obj) {
            arr.push(obj[item]);
        }
    } else {
        arr = obj;
    }
    return arr;
};

export { convertObjectToArray };