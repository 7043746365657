import { t } from '@app/utils';

export const state = {
    rendering: false,
    objectives: [],
    constQualitySleep: {
        1 : 'très mal dormi',
        4 : 'mal dormi',
        7 : 'bien dormi',
        10 : 'très bien dormi'
    },
    ie18nConstQualitySleep: {
        1 : t('très mal dormi', {ns: 'timeline'}),
        4 : t('mal dormi', {ns: 'timeline'}),
        7 : t('bien dormi', {ns: 'timeline'}),
        10 : t('très bien dormi', {ns: 'timeline'})
    },
    drink: 0,
};