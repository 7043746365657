import { debounce } from 'hyperapp-fx';
import { t, isLength, isNumeric } from '@app/utils';
import { Session, Cardline } from '@app/api';
import { getUser, getTodayYMD, redirectToOnboarding, createAndSaveCSRFToken } from '@app/core';
import { allRoutes } from '@app/settings';

module.exports = {
    initAction: function() {
        let action = {
            onComponentCreate: (props) => (state, actions) => {
                console.log('props : ', props);
                if (props.send) {
                    state.sendToParent = props.send;
                }
                if (props.customer) {
                    actions.setCustomer(props.customer);
                }
                if (props.isMedical) {
                    actions.setIsMedical(props.isMedical);
                }
                if (props.match && props.match.params) {
                    if (props.match.params.medicalStudytoken) {
                        let medicalStudytoken = props.match.params.medicalStudytoken;
                        localStorage.setItem('medicalStudytoken', medicalStudytoken);
                        actions.setMedicalStudyToken(medicalStudytoken);

                        if (medicalStudytoken !== 'medicalStudytoken') {
                            Cardline.getMedicalInfo(medicalStudytoken).then((res) => {
                                actions.setMedicalInfos(res.data);
                            });
                        }
                    }
                    if (props.match.params.timelineId) {
                        actions.setTimelineId(props.match.params.timelineId);
                    }
                }
                localStorage.removeItem('refresh');

                window.onkeyup = function(e) {
                    var key = e.keyCode ? e.keyCode : e.which;
                    if (key === 13) {
                        actions.onSubmit(e);
                    }
                }

                actions.resetApiError();
            },

            onComponentUpdate: (props) => (state, actions) => {
                actions.resetApiError();
            },

            setValues: newState => state => ({
                values: { ...state.values, ...newState },
            }),

            setTouched: newState => state => ({
                isTouched: { ...state.isTouched, ...newState },
            }),

            setValidity: newState => state => ({
                isValid: { ...state.isValid, ...newState },
            }),

            setApiErrors: newState => state => ({
                api: newState,
            }),

            setPasswordState: newState => state => ({
                passwordHidden: newState,
            }),

            setCustomer: newState => state => ({
                customer: newState,
            }),

            setIsMedical: newState => state => ({
                isMedical: newState,
            }),

            setMedicalStudyToken: newState => state => ({
                medicalStudytoken: newState,
            }),

            setTimelineId: newState => state => ({
                timelineId: newState,
            }),

            setMedicalInfos: newState => state => ({
                medicalInfos: newState,
            }),

            isInputValid: ({ name, value }) => (state, actions) => {
                if (name === undefined) {
                    return false;
                }

                state.sendToParent({
                    ns: name,
                    key: 'value',
                    value: value
                });
                switch (name) {
                    case 'day':
                        return (isNumeric(value) && isLength(value, { min: 1, max: 2}) && (+value > 0) && (+value < 32));
                    case 'month':
                        return (isNumeric(value) && isLength(value, { min: 1, max: 2}) && (+value > 0) && (+value < 13));
                    case 'year':
                        return (isNumeric(value) && isLength(value, { min: 4, max: 4}) && (+value > 1900) && (+value <= 2021));
                    default:
                        throw Error(`${name} has no validator assigned`);
                }
            },

            contactApiToCheckEmail: async email => await Session.checkEmail(email),

            onUpdate: ({ target: { name, value } }) => (state, actions) => {
                if (name.indexOf('date') > -1) {
                    name = name.split('-')[1];
                    let birthdate = state.birthdate;
                    birthdate[name] = value;
                    actions.setBirthDate(birthdate);
                } else {
                    actions.setValues({ [name]: value });
                }
                actions.setValidity({
                    [name]: actions.isInputValid({ name, value }),
                });
            },

            waitForLastInput: ({ target }) => debounce(
                120,
                'onUpdate',
                { target },
            ),

            togglePasswordInput: () => (state, actions) => {
                actions.setPasswordState(!state.passwordHidden);
            },

            nextStep: () => (state, actions) => {
                let newStep = 1;
                state.sendToParent({
                    ns: 'specialClass',
                    key: 'value',
                    value: ('onco-step-' + newStep)
                });
                actions.updateStep(newStep);
            },

            updateStep: (newStep) => (state, actions) => ({
                loginStep: newStep
            }),

            onBlur: ({ target: { name } }) => (state, actions) => {
                actions.resetApiError();
                if (name.indexOf('date') > -1) {
                    name = name.split('-')[1];
                }
                actions.setTouched({ [name]: true });
                console.log('ISVALID : ', state.isValid, state.isTouched);
            },

            setFormIsSubmitting: (active) => (state, actions) => ({
                formIsSubmitting: active
            }),

            setBirthDate: (active) => (state, actions) => ({
                birthdate: active
            }),

            goToNextStep: () => (state, actions) => {
                window.location.href = allRoutes['home.login.recovery'].pathname + '?email=' + state.values.email;
            },

            resetApiError: () => (state, actions) => {
                actions.setApiErrors({
                    'message' : '',
                    'kind' : 'info',
                });
            },

            onSubmit: event => (state, actions) => {
                event.preventDefault();

                if (state.formIsSubmitting) {
                    return false;
                }

                actions.resetApiError();

                if ((state.birthdate.year === '') || (state.birthdate.month === '') || (state.birthdate.day === '')) {
                    actions.setApiErrors({
                        'message' : t('Renseignez votre date de naissance pour continuer.', {ns: 'login'}),
                        'kind' : 'error',
                    });
                    return false;
                } else if ((!state.isValid.year || !state.isValid.month || !state.isValid.day)) {
                    actions.setApiErrors({
                        'message' : t('La date de naissance saisie semble erronée.', {ns: 'login'}),
                        'kind' : 'error',
                    });
                    return false;
                }

                actions.createSession();
            },

            createSession: () => (state, actions) => {
                if (state.formIsSubmitting) {
                    return false;
                }
                actions.setFormIsSubmitting(true);
                localStorage.removeItem('apitoken');
                localStorage.removeItem('user');
                localStorage.removeItem('expiration');
                localStorage.removeItem('refresh');
                //
                let birthdate = state.birthdate;
                let parsedBirthdate = '';
                parsedBirthdate += '' + birthdate.year;
                if (birthdate.month.length < 2) {
                    parsedBirthdate += '0' + birthdate.month;
                } else {
                    parsedBirthdate += birthdate.month;
                }
                if (birthdate.day.length < 2) {
                    parsedBirthdate += '0' + birthdate.day;
                } else {
                    parsedBirthdate += birthdate.day;
                }
                //
                Session.createMedicalSession(parsedBirthdate, state.medicalStudytoken).then((res) => {
                    if (res && res.data) {
                        let sessionsData = res.data;
                        localStorage.setItem('apitoken', sessionsData.token);
                        let userCallback = (sessionUser) => {
                            actions.redirectUser({sessionsData: sessionsData, sessionUser: sessionUser});
                        };
                        getUser(userCallback, true);
                    } else {
                        actions.setFormIsSubmitting(false);
                        actions.setApiErrors({
                            'message' : t('La date de naissance saisie semble erronée.', {ns: 'login'}),
                            'kind' : 'error',
                        });
                    }
                });
            },
            redirectUser: ({sessionsData, sessionUser}) => (state, actions) => {
                localStorage.setItem('refresh', sessionsData.refresh);
                localStorage.setItem('expiration', sessionsData.expiration.date);
                createAndSaveCSRFToken();

                redirectToOnboarding(sessionUser.id, getTodayYMD(), state.timelineId);
            },
        }
        return action;
    }
}
