import { Cardline } from '@app/api';
import { t } from '@app/utils';

export const actions = {
    onComponentCreate: (props) => (state, actions) => {
        state.favorite = (props.favorite || false);
        actions.setSavedItem(props);
        let card = props.recipe;
        if (card !== undefined) {
            actions.parseRecipe(card);
        }
    },
    getDetailRecipe: (event) => (state, actions) => {
        event.preventDefault();
        if (state.detailGetted === false) {
            Cardline.getRecipe(state.savedItem.recipe.id).then((res) => {
                actions.parseRecipe(res.data);
                actions.setModalDisplayed(true);
                actions.setDetailGetted(true);
            });
        }
    },
    parseRecipe: (card) => (state, actions) => {
        var making = card.making, makingLabel = '',
            baking = card.baking, bakingLabel = '',
            rest = card.rest, restLabel = '';
        if ((rest === null) || (rest === undefined)) {
            rest = '';
        }
        if ((baking === null) || (baking === undefined)) {
            baking = '';
        }
        if ((making === null) || (making === undefined)) {
            making = '';
        }
        card.making = {'value' : making, 'unit' : makingLabel, 'values': [making, baking, rest]};
        card.baking = {'value' : baking, 'unit' : bakingLabel, 'values': [making, baking, rest]};
        card.rest = {'value' : rest, 'unit' : restLabel, 'values': [making, baking, rest]};

        card.favoriteId = state.savedItem.favoriteId;
        card.timeline = state.savedItem.timeline;
        card.favorite = state.savedItem.favorite;

        actions.setDetail(card);
    },
    setDetailAliment: newState => state => ({
        detailAliment: newState,
    }),
    displayAlimentDetail: displayed => state => ({
        alimentDetailDisplayed: displayed,
    }),
    setTag: newState => state => ({
        tagaliment: newState,
    }),
    setModalDisplayed: newState => state => ({
        modalDisplayed: newState,
    }),
    scrollTop: (el) => (state, actions) => {
        el.scrollTop = 0;
    },
    setKindOfInfos: newState => state => ({
        kindOfInfos: newState,
    }),
    setDetailGetted: newState => state => ({
        detailGetted: newState,
    }),
    setSavedItem: newState => state => ({
        savedItem: newState,
    }),
    getDate: ({el, dat}) => {
        if (dat === null) {
            el.innerHTML = 'Ø';
            return false;
        }
        dat = dat.replace(/-/g, '/');
        dat = dat.split(' ')[0];
        var date = new Date(Date.parse(new Date(dat)));
        var options = { month: 'long', day: 'numeric', year: 'numeric' };
        el.innerHTML = (t('Ajouté le', {ns: 'generals'}) + ' : ' + date.toLocaleDateString('fr-FR', options));
    },
    updateParentProps: (favoriteid) => (state, actions) => {
        state.savedItem.favoriteId = favoriteid;
        actions.setFavorisStatus();
        state.savedItem.favorite = state.favorite;
    },
    setFavorisStatus: () => state => ({
        favorite: !state.favorite
    }),
    setDetail: newState => state => ({
        detail: newState,
    }),
    rendering: () => state => ({
        rendering: !state.rendering,
    }),
};
