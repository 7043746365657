import { t, h } from '@app/utils';
import { Enter, Tile } from '@app/elements';

import logoStep from '@app/img/food/your-lunch.png';
import logoStepx2 from '@app/img/food/your-lunch@2x.png';

import logoStep1 from '@app/img/food/your-lunchs.png';
import logoStep1x2 from '@app/img/food/your-lunchs@2x.png';

module.exports = {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <div style={{ 'display': 'table-cell', 'vertical-align': 'middle' }}>
                <div key='page-container'>
                    <Enter time={800} easing='ease-in-out' css={{ opacity: '0', transform: 'translateY(-100px)' }}>
                        <Tile key='home'>
                            <div class='btzTitle-btzImage col-xs-12'>
                                <img src={logoStep} srcset={`${logoStepx2} 2x`} alt='' class='' />
                            </div>
                            <div class='btzTitle-btzImage col-xs-12' style={{ 'height': '115px' }}>
                                <img src={logoStep1} srcset={`${logoStep1x2} 2x`} alt='' class='' />
                            </div>
                            <div class='btzTitle col-xs-12'>
                                <p class='btzTexte' style={{ 'margin': '0' }}>{t('Grâce à ces informations, les recommandations', {ns: 'food'})}</p>
                                <p class='btzTexte' style={{ 'margin': '0' }}>{t('seront adaptées à votre profil. Merci !', {ns: 'food'})}</p>
                            </div>
                        </Tile>
                    </Enter>
                </div>
            </div>
        );
        return view;
    }
}
