import { isLength } from '@app/utils'
import { Objectives } from '@app/api';
import { enableTheseButton, desableTheseButton } from '@app/core';

export const actions = {
    onComponentCreate: (props) => (state, actions) => {
        Objectives.getObjectives().then(function(res) {
            actions.setObjectives(res.data);
        });
    },

    setObjectives: newState => state => ({
        objectives: newState,
    }),

    onSubmit: () => (state, actions) => {

    },
};
