import { t, h } from '@app/utils';

import HomeLayout from '@app/layouts/home/layout';

import illuScience from '@app/img/home/science/illu-science.png';
import illuSciencex2 from '@app/img/home/science/illu-science@2x.png';

export const view = (state, actions) => (props, children) => (
    <div key='scientific'>
        <HomeLayout style={{ position: 'relative' }} page='scientific'>
            <div class='btzHome-btzHeader btzHome-btzHeader-blue'>
                <div class='btzHome-btzHeader-btzContent'>
                    <div class='col-10'>
                        <p class='btzTitle btzXl'>{t('La Science', {ns: 'home'})}</p>
                        <p class='btzTexte col-10 col-md-12'>{t('Betterise est une plateforme unique, entre le meilleur des technologies d’aujourd’hui, et le meilleur de la recherche médicale. L’intégralité du contenu proposé par Betterise est issu de recherches scientifiques, menées par des médecins ou des spécialistes, pour ne vous proposer que des informations et des outils fiables, vérifiés scientifiquement.', {ns: 'home'})}</p>
                    </div>
                </div>
            </div>

            <div class='btzHome-btzContent column'>
                <div class='col-10'>
                    <div class='col-6 col-md-12 btzLeftAnchored' style={{ 'vertical-align': 'top' }}>
                        <p class='btzTitle btzXl'>{t('Le constat.', {ns: 'home'})}</p>
                        <p class='btzTexte'>{t('Selon l’OMS, les maladies non transmissibles (maladies cardio-vasculaires, cancers, diabètes de type 2 notamment) sont aujourd’hui la principale cause de mortalité dans le monde : elles représentent près de 87% des décès dans les pays aux revenus élevés.', {ns: 'home'})}</p>
                        <p class='btzTexte'>{t('Sur les 10 dernières années, le nombre de décès liés à ces maladies du style de vie a augmenté de près de… 60 %, représentant 36 millions de personnes dans le monde – dont 450 000 en France.', {ns: 'home'})}</p>
                        <p class='btzTexte'>{t('En modifiant légèrement les comportements, et en luttant contre les principaux facteurs de risque, nous pourrions éviter 75 % des AVC, des maladies du cœur ou des diabètes – et près de 40 % des cancers.', {ns: 'home'})}</p>
                        <p class='btzTexte'>{t('.. soit sauver, chaque année en France, près de 135 000 personnes.', {ns: 'home'})}</p>
                        <p class='btzTexte'>{t('L’équivalent d’une ville comme Besançon, ou Brest.', {ns: 'home'})}</p>
                        <p class='btzTitle btzXl' style={{ 'margin-top': '80px' }}>{t('Notre réponse.', {ns: 'home'})}</p>
                        <p class='btzTexte'>{t('Nous pensons que la médecine préventive personnalisée, grâce à la prise de recul sur ses habitudes et à la modification des comportements qu’elle entraîne, est l’avenir de la santé.', {ns: 'home'})}</p>
                        <p class='btzTexte'>{t('Nous sommes convaincus que grâce aux nouvelles technologies, cette prévention est beaucoup plus facile à mettre en œuvre.', {ns: 'home'})}</p>
                        <p class='btzTexte'>{t('Nous avons conçu Betterise comme un service unique, adapté aux usages d’aujourd’hui, non seulement pour aider nos utilisateurs à vivre mieux ; nous croyons en la véritable capacité de Betterise, à sauver des vies. Et c’est, la plus belle des missions.', {ns: 'home'})}</p>
                    </div>
                    <div class='col-6 col-md-12 btzRightAnchored' style={{ 'padding-top': '35px' }}>
                        <img src={illuScience} srcset={`${illuSciencex2} 2x`} alt='' class='' />
                    </div>
                </div>
            </div>
        </HomeLayout>
    </div>
);
