import { h, Component } from '@app/utils';
import './index.scss';

import ImgSuccess from './ico-success-white.png';
import ImgSuccessx2 from './ico-success-white@2x.png';

const MessagePopup = Component(
    {
        duration: 2000,
    },
    {
        onComponentCreate: (props) => (state) => {
            if (props.duration) {
                state.duration = props.duration;
            }
            if (!props.durationCallback) {
                console.error('message popup CANT be used without a duration callback, callback that will be called when display duration is over.');
            }

            setTimeout(function() {
                props.durationCallback();
            }, state.duration);
        },
    },
    (state, actions) => (props, children) => (
        <div class={'col-12 btzMessagePopup ' + props.kind + ' ' + props.active} data-percent={state.percent}>
            <div class='col-2 btzMessagePopup-MessagePopupImage'>
                <img src={ImgSuccess} srcset={`${ImgSuccessx2} 2x`} alt='' class='' />
            </div>
            <div class='col-10 btzMessagePopup-MessagePopupMessage'>
                {props.message &&
                    <p>{props.message}</p>
                }
            </div>
        </div>
    ),
    'messagepopup'
);

export { MessagePopup };
