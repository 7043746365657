import { debounce } from 'hyperapp-fx';
import { isDefined } from '@app/core';
import { h, Component, isLength } from '@app/utils';

import { StepListings } from './../step-listings';

const StepEdit = Component(
    {
        lunch: [],
        endStep: 'recap',
        mealContexts: [],
        lunchDataName: {
            value: '',
            isTouched: false,
            isValid: ''
        }
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.existingLunch.aliments) {
                actions.parseLunch(props.existingLunch.aliments);
            }

            if (props.mealName) {
                actions.setState({ns : 'lunchDataName', key : 'value', value : props.mealName});
                actions.setState({ns : 'lunchDataName', key : 'isValid', value : true});
            }
            let mealContexts = (isDefined(props.mealContexts) ? props.mealContexts : []);
            actions.setMealContexts(mealContexts);
        },

        onComponentUpdate: (props) => (state, actions) => {
            if (props.existingLunch.aliments) {
                actions.parseLunch(props.existingLunch.aliments);
            }
            let mealContexts = (isDefined(props.mealContexts) ? props.mealContexts : []);
            actions.setMealContexts(mealContexts);
        },
        resetLunch: () => (state, actions) => {
            actions.setLunch([]);
        },
        parseLunch: (aliments) => (state, actions) => {
            let uniqFilter = aliments;
            uniqFilter = uniqFilter.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.id === thing.id
                ))
            );

            actions.setLunch(uniqFilter);
        },

        setState: ({ ns, key, value }) => state => ({
            [ns]: { ...state[ns], ...{ [key]: value } },
        }),

        setMealContFeel: newState => state => ({
            mealContFeel: newState,
        }),

        setMealContKind: newState => state => ({
            mealContKind: newState,
        }),
        setLunch: newState => state => ({
            lunch: newState,
        }),

        setEndStep: newState => state => ({
            endStep: newState,
        }),

        setValues: newState => state => ({
            values: { ...state.values, ...newState },
        }),

        setTouched: newState => state => ({
            isTouched: { ...state.isTouched, ...newState },
        }),

        setMealContexts: newState => state => ({
            mealContexts: newState,
        }),

        updateLunchName: (props) => (state, actions) => {
            if (props) {
                props.setMealName(state.lunchDataName.value);
            }
        },

        onUpdate: ({ target: { name, value } }) => (state, actions) => {
            let isValid = actions.isInputValid({ name, value });
            actions.setState({ns : 'lunchDataName', key : 'value', value : value});
            actions.setState({ns : 'lunchDataName', key : 'isValid', value : isValid});
        },

        waitForLastInput: ({ target }) => debounce(
            500,
            'onUpdate',
            { target },
        ),

        onBlur: () => (state, actions) => {
            actions.setState({ns : 'lunchDataName', key : 'isTouched', value : true});
        },

        isInputValid: ({ name, value }) => (state, actions) => {
            if (name === undefined) {
                return false;
            }

            switch (name) {
                case 'name':
                    return isLength(value, { min: 1 });
                default:
                    throw Error(`${name} has no validator assigned`);
            }
        },
    },
    (state, actions) => (props, children) => (
        <div key={'listingmode-edit'} class={'listingmode-edit'}>
            <div class='btzData-btzFood-btzListing'>
                {state.lunch.length > 0 &&
                    <div class='btzModal-btzPaddingWrapper' style={{ 'height': 'calc(100vh - 360px)', 'overflow-y': 'auto', 'padding-bottom': '60px', 'padding-right': '0px' }}>
                        <StepListings customer={props.customer} userGender={props.userGender} existingLunch={props.existingLunch} lastStep={true} editing={true} displayLastStep={null} displayed={true} mealKind={props.mealKind} mealDate={props.mealDate} mealName={props.mealName} setDisplayed={actions.setDisplayed} resetLunchAliments={false} mealContFeels={props.mealContFeels} mealContFeel={props.mealContFeels.filter((f) => state.mealContexts.indexOf(f.key) > -1).map((m) => m.key)} mealContEats={props.mealContEats} mealContEat={props.mealContEats.filter((f) => state.mealContexts.indexOf(f.key) > -1).map((m) => m.key)} mealContKinds={props.mealContKinds} mealContKind={props.mealContKinds.filter((f) => state.mealContexts.indexOf(f.key) > -1).map((m) => m.key)} ></StepListings>
                    </div>
                }
            </div>
        </div>
    ),
    'addmealedit'
);

export { StepEdit };
