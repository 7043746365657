import { h, Component } from '@app/utils';
import './index.scss';

import QuestionLayout from '@app/layouts/datas/question/layout';

import { MoralForm } from './forms/moral';
import { ActivityForm } from './forms/activity';
import { DeniveleForm } from './forms/denivele';
import { StressForm } from './forms/stress';
import { TirednessForm } from './forms/tiredness';
import { SleepForm } from './forms/sleep';
import { AlcoolForm } from './forms/alcool';
import { TobaccoForm } from './forms/tobacco';
import { FollowedWeightForm } from './forms/followed-weight';
import { SportForm } from './forms/sport';
import { WaistsizeForm } from './forms/waistsize';

const state = {
    idx: 0,
    activeValidation: false,
    steps: {},
    current: {},
    values: [],
    idtimeline: null,
    formIsSubmitting: false,
    error: '',
}

const actions = {
    onComponentCreate: () => (state, actions) => {

    },
};

const view = (state, actions) => (props, children) => (
    <QuestionLayout page='question' theme={state.current.theme}>
        {props.theme === 'shape' &&
            <MoralForm {...props}></MoralForm>
        }
        {props.theme === 'activity' &&
            <ActivityForm {...props}></ActivityForm>
        }
        {props.theme === 'denivele' &&
            <DeniveleForm {...props}></DeniveleForm>
        }
        {props.theme === 'tiredness' &&
            <TirednessForm {...props}></TirednessForm>
        }
        {props.theme === 'sleep' &&
            <SleepForm {...props}></SleepForm>
        }
        {props.theme === 'stress' &&
            <StressForm {...props}></StressForm>
        }
        {props.theme === 'alcool' &&
            <AlcoolForm {...props}></AlcoolForm>
        }
        {props.theme === 'tobacco' &&
            <TobaccoForm {...props}></TobaccoForm>
        }
        {props.theme === 'followed-weight' &&
            <FollowedWeightForm {...props}></FollowedWeightForm>
        }
        {props.theme === 'sport' &&
            <SportForm {...props}></SportForm>
        }
        {props.theme === 'waistsize' &&
            <WaistsizeForm {...props}></WaistsizeForm>
        }
    </QuestionLayout>
);

export default Component(state, actions, view, 'question');
