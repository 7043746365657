import { t, h } from '@app/utils';
import { Link } from '@app/elements';

import HomeLayout from '@app/layouts/home/layout';

import illuAbout from '@app/img/home/apropos/illu-apropos.png';
import illuAboutx2 from '@app/img/home/apropos/illu-apropos@2x.png';

import bgGeo from '@app/img/home/accueil/background-geometrique.png';
import bgGeox2 from '@app/img/home/accueil/background-geometrique@2x.png';

export const view = (state, actions) => (props, children) => (
    <div key='about'>
        <HomeLayout style={{ position: 'relative' }} page='about'>
            <div class='btzHome-btzHeader btzHome-btzHeader-blue'>
                <div class='btzHome-btzHeader-btzContent'>
                    <div class='col-10'>
                        <p class='btzTitle btzXl'>{t('À propos', {ns: 'home'})}</p>
                        <p class='btzTexte col-10 col-md-12'>{t('Betterise est une aventure incroyable, commencée en France, en 2013. Nous pensions alors qu’en maîtrisant les nouvelles technologies, mais aussi (et surtout) les nouveaux usages digitaux, il nous était possible de créer un service unique, permettant d’améliorer concrètement la vie, et la santé, de ses utilisateurs.', {ns: 'home'})}</p>
                    </div>
                </div>
            </div>

            <div class='btzHome-btzContent column'>
                <div class='col-10'>
                    <div class='col-6 col-md-12 btzLeftAnchored' style={{ 'vertical-align': 'top' }}>
                        <p class='btzTexte'>{t('Depuis, notre équipe formidable travaille dur pour concrétiser cette vision, de concert avec les plus grands médecins et spécialistes qui nous accompagnent jour après jour dans la conception de ce service, pas comme les autres.', {ns: 'home'})}</p>
                        <p class='btzTexte'>{t('En Octobre 2014, la 1ère version de Betterise est mise en ligne. Au début du Printemps 2015, Betterise est accessible sur le web, sur une application iPhone, une application Android, et l’application Apple Watch est déjà en cours de test.', {ns: 'home'})}</p>
                        <p class='btzTexte'>{t('Nous couvrons 17 thématiques santé, au travers d’un nombre incroyable de fonctionnalités, toutes très diverses - et nous sommes compatibles avec les plus grands objets connectés. Notre plateforme technique est plus ouverte au monde que jamais, avec des APIs robustes, complètes, pouvant délivrer notre contenu et notre intelligence au monde entier.', {ns: 'home'})}</p>
                        <p class='btzTexte'>{t('Nous avons gagné des prix, récompensant notre implication. Plus important pour nous, Betterise est à ce jour proposé à des centaines de milliers de personnes en Europe, grâce à des accords passés avec des clients innovants. Nos applications iPhone ont été téléchargées plus de 200 000 fois.', {ns: 'home'})}</p>
                        <p class='btzTexte'>{t('Et le futur est déjà là : nous améliorons sans cesse Betterise. Plus de contenu, plus de programmes, plus de fonctionnalités … et plus de domotique, aussi. Car oui, votre maison est un lieu propice à l’épanouissement de votre santé. Nous franchissons également les frontières : nous sommes présents à Bruxelles, et à Londres.', {ns: 'home'})}</p>
                        <p class='btzTexte'>{t('Notre mission est claire : utiliser le meilleur des nouvelles technologies et des nouveaux usages, afin d’améliorer la vie et la santé de nos utilisateurs. Notre société,', {ns: 'home'})} <Link class='btzLink btzLink-btzUltraBasic' to={'http://www.betterise-healthtech.com/'} target={'_blank'}>Betterise Health Tech</Link>{t(', a été créée pour cela, et développe déjà d’autres plateformes, plus spécifiques que Betterise.', {ns: 'home'})}</p>
                        <p class='btzTexte'>{t('Waow ! Que de chemins parcourus. Nous sommes à la fois fiers, et reconnaissants envers tous ceux, qui nous ont soutenus.', {ns: 'home'})}</p>
                        <p class='btzTexte'>{t('Et merci et bravo à toi, Alex. Tu es avec nous, tous les jours.', {ns: 'home'})}</p>
                    </div>
                    <div class='col-6 col-md-12 btzHome-btzContent-btzParticularContent btzRightAnchored' style={{ 'padding-top': '35px' }}>
                        <img src={illuAbout} srcset={`${illuAboutx2} 2x`} alt='' class='' />
                        <p class='btzTexte col-6 col-xs-12' style={{ 'margin-top': '-200px' }}>{t('Pour nous contacter, envoyez-nous un mail : ', {ns: 'home'})}<Link class='btzLink btzLink-btzUltraBasic' to={'mailto:hello@betterise.me'}>hello@betterise.me</Link></p>
                        <p class='btzTexte col-7 col-xs-12'>{t('Pour toute demande concernant l’utilisation d’un de nos produits, une demande d’assistance, ou une question sur votre compte notre service client est à votre disposition au mail : ', {ns: 'home'})}<Link class='btzLink btzLink-btzUltraBasic' to={'mailto:alfred@betterise.me'}>alfred@betterise.me</Link></p>
                    </div>
                </div>
            </div>

            <div class='btzHome-btzFooter'>
                <div class='col-10 p-0'>
                    <p class='btzTexte col-6 col-md-10'>{t('Découvrez l’équipe derrière Betterise.', {ns: 'home'})}</p>
                    <Link to={'http://betterise-healthtech.com/fr/equipe'} class='btzLink btzLink-btzButtonLike'>
                        {t('Betterise Health Tech', {ns: 'home'})}
                    </Link>
                    <img src={bgGeo} srcset={`${bgGeox2} 2x`} alt='' class='' />
                </div>
            </div>
        </HomeLayout>
    </div>
);
