import { t, h } from '@app/utils';
import { Card, Button, Modal, MessagePopup } from '@app/elements';
import AccountLayout from '@app/layouts/account/layout';

import * as staticsconstants from '@app/constants/constants-statics';

import { ModalView } from './../modalView';

import Objectif from './../objectif';

module.exports = {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <AccountLayout page='objectives' readablePageLabel={t(staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()].menuNames.account.title)} readablePageDescription={t('Pour gérer au mieux votre accompagnement santé', {ns: 'account'})} userMedia={state.userMedia} customer={props.customer}>

                {state.messageActive &&
                    <MessagePopup kind={'success'} active={'active'} duration={3000} durationCallback={() => actions.displaySuccessMessage(false)} message={t('La modification de vos objectifs a été enregistrée avec succès !', {ns: 'objectives'})}></MessagePopup>
                }

                <div class='col-6 col-md-12' style={{ 'vertical-align': 'top' }}>
                    <Card classes={'btzPageCard'} kind={'objectif'}>
                        <Card.Header classes={'btzCard-btzHeader-btzPage'}>
                            <div class='col-12'>
                                <p class='btzTimeline-btzCard-btzTitle btzPageContentTitle'>{t('Objectifs en cours', {ns: 'objectives'})} ({state.objectives.user.length})</p>
                                <p class='btzTimeline-btzCard-btzSubTitle btzPageContentSubTitle'>{t('Voici les objectifs que vous suivez actuellement.', {ns: 'objectives'})}</p>
                            </div>
                        </Card.Header>

                        <Card.Body>
                            {state.objectives.user.map(item =>
                                <div id={'obj-' + item.id} key={item.id} class='col-12 btzCard-btzObjectives p-0' data-displaying={item.cardKind}>
                                    <Objectif {...item} displayedon={'user'}></Objectif>
                                </div>
                            )}
                        </Card.Body>

                        <Card.Footer></Card.Footer>
                    </Card>
                </div>

                <div class='col-6 col-md-12' style={{ 'vertical-align': 'top' }}>
                    <Card classes={'btzPageCard'} kind={'objectif'}>
                        <Card.Header classes={'btzCard-btzHeader-btzPage'}>
                            <div class='col-12'>
                                <p class='btzTimeline-btzCard-btzTitle btzPageContentTitle'>{t('Changement d’objectifs', {ns: 'objectives'})}</p>
                                <p class='btzTimeline-btzCard-btzSubTitle btzPageContentSubTitle'></p>
                            </div>
                        </Card.Header>

                        <Card.Body style={{ 'margin-top': '10px' }}>
                            <p class='btzTimeline-btzCard-btzSubTitle btzPageContentTitleBlue' style={{ 'color': 'black' }}>
                                {t('Vous estimez avoir réussi un de vos objectifs ? Vous souhaitez suivre un objectif en plus ? Ou vous avez tout simplement une envie de changement ?', {ns: 'objectives'})}<br/><br/>
                                {t('Vous pouvez modifier vos objectifs quand vous le souhaitez :', {ns: 'objectives'})}
                            </p>

                            <Modal modalDisplayed={state.modalDisplayed} cancelAction={actions.cancelObjectivesModifications} content={<ModalView {...props} objectives={state.objectives.all} error={state.error} chooseObjectif={actions.chooseThisObjectif} confirmObjectif={actions.confirmTheseObjectif} confirmObjectifRules={actions.confirmTheseObjectifRules} updateError={() => {actions.setError('');}}></ModalView>}>
                                <Button colored primary style={{ 'color': 'white' }} onclick={() => {
                                    actions.setModalDisplayed(true);
                                }}>
                                    {props.customer === 'vyv' ?
                                        <dummy>{t('Modifiez vos objectifs', {ns: 'objectives'})}</dummy>
                                        :
                                        <dummy>{t('Modifier vos objectifs', {ns: 'objectives'})}</dummy>
                                    }
                                </Button>
                            </Modal>
                        </Card.Body>

                        <Card.Footer></Card.Footer>
                    </Card>
                </div>
            </AccountLayout>
        );
        return view;
    }
}
