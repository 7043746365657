export const state = {
    email: {
        value: '',
        isTouched: false,
        isValid: false,
        isApproved: null,
    },
    api: {
        message: '',
        kind: 'info'
    },
    formIsSubmitting: false,
    sendToParent: null,
    messageActive: false,
};
