const trimSpecialChar = function(str) {
    let dict = {'á':'a', 'à':'a', 'ç':'c', 'é':'e', 'è':'e', 'ê':'e', 'ë':'e', 'ù':'u', 'û':'u', 'ô':'o'};
    if (Array.isArray(str)) {
        for (var entry in str) {
            str[entry] = str[entry].replace(/[^\w ]/g, function(char) {
                return dict[char] || char;
            });
        }
    } else {
        str = str.replace(/[^\w ]/g, function(char) {
            return dict[char] || char;
        });
    }
    return str;
}

export { trimSpecialChar };