import { h } from '@app/utils';
import { allRoutes } from '@app/settings';
import { Payment } from '@app/api';
import { addCSRFToken, createAndSaveCSRFToken, getQueryVariable } from '@app/core';

export const actions = {
    onComponentCreate: (props) => (state, actions) => {

    },
    redirectToPayment: () => (state, actions) => {
        let qSource = getQueryVariable('source', false);
        let qClientSecret = getQueryVariable('client_secret', false);
        let data = {
            source: qSource,
            client_secret: qClientSecret
        };

        Payment.redirectPaymentCard(data).then(() => {
            createAndSaveCSRFToken();
            window.location.href = addCSRFToken(allRoutes['private.onboarding'].pathname);
        });
    },
};