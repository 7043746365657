import { t, h } from '@app/utils';
import { Card } from '@app/elements';

import imgNoTimeline from '@app/img/timeline/img-no-previous.png';
import imgNoTimelinex2 from '@app/img/timeline/img-no-previous@2x.png';

export const view = (state, actions) => (props, children) => (
    <Card kind={'notimeline'} theme={props.valueTheme}>
        <Card.Image kind={'image'}><img src={imgNoTimeline} srcset={`${imgNoTimelinex2} 2x`} alt='' class='' /></Card.Image>
        <Card.Body classes='btzVerticalAlign btzDottedSpaced'>
            <div class='btzWrapper-verticalAlignContent'>
                <p class='btzTimeline-btzCard-btzDescription btzTitle'>{t('PAS DE CONTENU', {ns: 'timeline'})}</p>
                <p class='btzTimeline-btzCard-btzDescription btzTexte'>{t('Vous ne vous êtes pas connecté.', {ns: 'timeline'})}</p>
            </div>
        </Card.Body>
    </Card>
);
