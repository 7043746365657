import { getClosest, isDefined } from '@app/core';
import { t, h, Component } from '@app/utils';
import { Data } from '@app/api';
import { Tile, Balloon, Form, Button, Avatar, CustomInput } from '@app/elements';
import './index.scss';

// import ImgShowMore from './social/soc-more.png';
// import ImgShowMorex2 from './social/soc-more@2x.png';

const ActivityForm = Component(
    {
        question: 'Combien de temps avez-vous marché ?',
        i18nquestion: t('Combien de temps avez-vous marché ?', {ns: 'datas'}),
        form: {
            type: 'button',
            tag: 'ACTIVITY_WALK',
            values: [
                {key: 'moins de 15 minutes', value: '1500'},
                {key: 'entre 15 et 30min', value: '2250'},
                {key: 'entre 30 et 45min', value: '3750'},
                {key: 'entre 45min et 1h', value: '5250'},
                {key: 'entre 1h et 1h30min', value: '7500'},
                {key: 'plus d‘1h30min', value: '10000'},
            ],
            i18nvalues: [
                {key: t('moins de 15 minutes', {ns: 'datas'}), value: '1500'},
                {key: t('entre 15 et 30min', {ns: 'datas'}), value: '2250'},
                {key: t('entre 30 et 45min', {ns: 'datas'}), value: '3750'},
                {key: t('entre 45min et 1h', {ns: 'datas'}), value: '5250'},
                {key: t('entre 1h et 1h30min', {ns: 'datas'}), value: '7500'},
                {key: t('plus d‘1h30min', {ns: 'datas'}), value: '10000'},
            ],
        },
        source: 'betterise',
        date: null,
        readableDate: null,
        help: 'Une seule réponse possible.',
        i18nhelp: t('Une seule réponse possible.', {ns: 'datas'}),
        error: '',
        activeValidation: false,
        formIsSubmitting: false,
        moreThan10kDisplayed: false,
        values: [],
        defaultValue: null,
    }, // 1min = 111pas // 90 => 10000pas
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.date) {
                actions.setReadableDate(actions.getDate(props.date));
                actions.setDate(actions.createDateObj(props.date));
                // state.form.date
            }
            if (props.defaultValue) {
                if (props.defaultValue >= 10000) {
                    actions.setHelp(t('Saisissez le temps de marche.', {ns: 'datas'}))
                    actions.setDefaultValue([{'h': Math.floor((props.defaultValue / 111) / 60),'min': Math.floor((props.defaultValue / 111) % 60)}]);
                    // actions.setMoreThan10kDisplayed(true);
                } else {
                    actions.setDefaultValue(props.defaultValue);
                }
            }
        },
        onComponentUpdate: (props) => (state) => {

        },
        switchValidationActivation: () => state => ({
            activeValidation: !state.activeValidation,
        }),
        selectThisAnswer: (el) => (state, actions) => {
            actions.setError('');

            var els = getClosest(el.target, '.btzOnboarding-btzButtonsWrapper').querySelectorAll('.btzOnBoarding-btzButton');
            for (var elem in els) {
                if (els[elem] && (els[elem].classList !== undefined)) {
                    els[elem].classList.remove('active');
                    els[elem].classList.remove('btzCurrentEvaluationMarker');
                }
            }

            el.target.classList.add('active');
            el.target.classList.add('btzCurrentEvaluationMarker');

            if (!state.activeValidation) {
                actions.switchValidationActivation();
            }
        },
        sendData: (props) => (state, actions) => {
            var parent = document.querySelectorAll('.btzDashboards-btzDashboardWrapper#' + props.theme)[0];
            var els = parent.querySelectorAll('.btzCurrentEvaluationMarker');
            if (els.length === 0) {
                actions.setError(t('Merci de renseigner une réponse.', {ns: 'datas'}));
                return false;
            }
            actions.setError('');
            //
            let addedValues = [];
            let valuesAdded = false;
            els.forEach(function (el) {
                if ((el.getAttribute('data-id') === null) && (el.lastChild !== null)) {
                    el = el.lastChild;
                }
                var tag = state.form.tag,
                    val = el.getAttribute('data-value'),
                    date = state.date,
                    source = state.source;

                if (val === 'morethan10k') {

                    valuesAdded = false;
                } else {
                    // find value by id then replace it
                    for (var i = 0, len = addedValues.length; i < len; i++) {
                        if (addedValues[i] !== undefined) {
                            if (addedValues[i].tag === tag) {
                                addedValues.splice((i + 0), 1);
                            }
                        }
                    }
                    if (state.moreThan10kDisplayed) {
                        if (isDefined(state.defaultValue) && (state.defaultValue.length > 0) && (state.defaultValue[0].h !== undefined)) {
                            if (((+state.defaultValue[0].h * 60) + +state.defaultValue[0].min) <= 90) {
                                actions.setError(t('Veuillez saisir une valeur supérieure à 1h30.', {ns: 'datas'}));
                                return false;
                            }
                            val = (+state.defaultValue[0].h * 111 * 60) + (+state.defaultValue[0].min * 111);
                        }
                    } else {
                        //
                    }
                    addedValues.push({ tag: tag, value: val, date: date, source: source });
                    valuesAdded = true;
                }
            });
            actions.setValues(addedValues);
            //
            if (!valuesAdded) {
                if (isDefined(state.defaultValue) && ((+state.defaultValue.h <= 1) && (+state.defaultValue.min < 30)) || (+state.defaultValue < 10000)) {
                    actions.setDefaultValue([{'h': 1, 'min': 30}]);
                }
                actions.setMoreThan10kDisplayed(true);
                actions.setHelp(t('Saisissez le temps de marche.', {ns: 'datas'}));
            } else {
                if (state.moreThan10kDisplayed) {
                    //
                }
                //
                if (addedValues.length === 0) {
                    actions.setError(t('Veuillez remplir le formulaire.', {ns: 'generals'}));
                } else {
                    actions.setFormIsSubmitting(true);
                    Data.postData(addedValues).then(function() {
                        props.updateDataViz(props);
                        props.cancelAction();
                        actions.setFormIsSubmitting(false);
                    });
                }
            }
        },
        setError: newState => state => ({
            error: newState,
        }),
        setValues: newState => state => ({
            values: newState,
        }),
        setHelp: newState => state => ({
            help: newState,
        }),
        setFormIsSubmitting: (active) => (state, actions) => ({
            formIsSubmitting: active
        }),
        setReadableDate: newState => state => ({
            readableDate: newState
        }),
        setDate: newState => state => ({
            date: newState
        }),
        setDefaultValue: newState => state => ({
            defaultValue: newState
        }),
        setMoreThan10kDisplayed: newState => state => ({
            moreThan10kDisplayed: newState
        }),
        updateDefaultValue: (newValue) => (state, actions) => {
            if (!Array.isArray(state.defaultValue)) {
                state.defaultValue = [{'h': 0, 'min': 0}];
            }
            if (newValue[0].h === null) {
                newValue[0].h = state.defaultValue[0].h;
            } else if (newValue[0].min === null) {
                newValue[0].min = state.defaultValue[0].min;
            }
            if (newValue[0].h === '') {
                newValue[0].h = '';
            } else if (newValue[0].min === '') {
                newValue[0].min = '';
            }
            actions.setDefaultValue(newValue);
        },
        createDateObj: (date) => (state, actions) => {
            var parsedDate = {
                date: new Date(date.substr(0, 4) + '-' + date.substr(4, 2) + '-' + date.substr(6, 2)),
                timezone: 'UTC',
                timezone_type: 3
            }

            return parsedDate;
        },
        getDate: (dat) => {
            var date = new Date(Date.parse(new Date(dat.substr(0, 4) + '-' + dat.substr(4, 2) + '-' + dat.substr(6, 2))));
            var options = { month: 'long', day: 'numeric', year: 'numeric' };
            return date.toLocaleDateString('fr-FR', options);
        }
    },
    (state, actions) => (props, children) => (
        <div class='btzDashboard-btzDashboardForm' id={props.id} idtimeline={props.idtimeline} idfavorite={props.idfavorite} data-id={props.did} data-tag={props.tag} data-value={state.count} data-kind={state.kind}>
            <div style={{ 'position': 'relative', 'height': '100%', 'display': 'table', 'width': '100%' }}>
                <Tile>
                    <div class='btzTitle-btzImage btzTitle-btzImage-absolute'>
                        <p class='btzSubTitle'>{state.readableDate}</p>
                        <p class='btzTitle btzXl'>{t(state.question)}</p>
                    </div>
                    <Tile.Content classes='btzLeftHrArrowedOnboarding' style={{ 'text-align': 'center' }}>
                        {state.form && (
                            <div>
                                {(state.moreThan10kDisplayed) ?
                                    <Tile.Content classes='btzLeftHrArrowedOnboarding' style={{ 'text-align': 'center' }}>
                                        {state.form && (
                                            <div>
                                                <div class='col-6 col-xs-6' style={{ 'max-width': '120px' }}>
                                                    <CustomInput key={'activity-hours'} maxValue={23} data-id={'multi'} uniqName={'activity-hours'} data-value={0} uniqLabel={t('h', {ns: 'datas'})} uniqMaxLength={2} uniqDefaultValue={((isDefined(state.defaultValue)) && (state.defaultValue.length > 0)) ? state.defaultValue[0].h : ''} oninput={(el) => {actions.updateDefaultValue([{h: +el.target.value, min: null}])}} onclick={(el) => {actions.updateDefaultValue([{h: (+el.target.value === 0 ? '' : +el.target.value), min: null}])}} uniqClass={'btzCurrentEvaluationMarker'}></CustomInput>
                                                </div>
                                                <div class='col-6 col-xs-6' style={{ 'max-width': '120px' }}>
                                                    <CustomInput key={'activity-minutes'} maxValue={59} data-id={'multi'} uniqName={'activity-minutes'} data-value={0} uniqLabel={t('min', {ns: 'datas'})} uniqMaxLength={2} uniqDefaultValue={((isDefined(state.defaultValue)) && (state.defaultValue.length > 0)) ? state.defaultValue[0].min : ''} oninput={(el) => {actions.updateDefaultValue([{h: null, min: +el.target.value}])}} onclick={(el) => {actions.updateDefaultValue([{h: null, min: (+el.target.value === 0 ? '' : +el.target.value)}])}} uniqClass={'btzCurrentEvaluationMarker'}></CustomInput>
                                                </div>
                                            </div>
                                        )}
                                    </Tile.Content>
                                    :
                                    <div class='btzOnboarding-btzButtonsWrapper btzOnboarding-btzButtonsWrapper-button' data-length={state.form.values.length}>
                                        {state.form.values.map(item =>
                                            <div class='col-4 col-md-12'>
                                                <div onclick={actions.selectThisAnswer} data-id={'single'} data-kind='button' data-value={(+item.value === 10000 ? 'morethan10k' : item.value)} class={'btzOnBoarding-btzButton btzOnBoarding-btzSlider-btzLabel ' + (+state.defaultValue === +item.value ? 'active btzCurrentEvaluationMarker' : '')}>
                                                    <p>{t(item.key)}</p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                }
                            </div>
                        )}
                    </Tile.Content>
                </Tile>

                <Form onsubmit={() => null} style={{ 'min-height': 0 }}>
                    <Form.Group classes='btzForm-btzFooter'>
                        <Button primary small loading={(state.formIsSubmitting)} onclick={() => { if (!state.formIsSubmitting) { props.cancelAction(); } }} >
                            {t('Annuler', {ns: 'generals'})}
                        </Button>

                        <Button primary active={state.activeValidation} loading={(state.formIsSubmitting)} onclick={() => {
                            if (!state.formIsSubmitting) {
                                actions.sendData(props);
                            }
                        }} >
                            {t('Valider', {ns: 'generals'})}
                        </Button>
                    </Form.Group>
                </Form>

                <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap', 'margin-top': '50px' }}>
                    <Tile.Icon classes={''}>
                        <Avatar.Alfred classes={'centered'} />
                    </Tile.Icon>
                    <Tile.Content>
                        <Balloon kind={state.error !== '' ? 'error' : 'info'} title={t('QUESTION', {ns: 'datas'})} style={{ 'white-space': 'normal' }}>{(state.error !== '' ? state.error : t(state.help))}</Balloon>
                    </Tile.Content>
                </Tile>
            </div>
        </div>
    ),
    'activityform'
);

export { ActivityForm };
