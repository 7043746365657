import { debounce } from 'hyperapp-fx';
import { t, h, isLength } from '@app/utils'
import { User } from '@app/api';
import { getQueryVariable, redirectToWebOrApp } from '@app/core';

module.exports = {
    initAction: function() {
        const action = {
            onComponentCreate: (props) => (state, actions) => {
                let token = getQueryVariable('xtoken', false);
                localStorage.setItem('apitoken', token);
            },

            onComponentUpdate: (props) => (state, actions) => {
                if (props.customer) {
                    actions.setCustomer(props.customer);
                }
            },

            onUpdate: ({ target: { name, value } }) => (state, actions) => {
                let isValid = actions.isInputValid({ name, value });

                state.api.message = '';
                state.api.kind = '';
                actions.setState({ns: name, key: 'value', value: value});
                actions.setState({ns: name, key: 'isValid', value: isValid});
            },

            waitForLastInput: ({ target }) => debounce(
                500,
                'onUpdate',
                { target },
            ),

            isInputValid: ({ name, value }) => (state, actions) => {
                if (name === undefined) {
                    return false;
                }

                switch (name) {
                    case 'passwordconfirm':
                    case 'password':
                        return isLength(value, { min: 8});
                    case 'secret':
                        return isLength(value, { min: 1});
                    default:
                        throw Error(`${name} has no validator assigned`);
                }
            },

            setState: ({ ns, key, value }) => state => ({
                [ns]: { ...state[ns], ...{ [key]: value } },
            }),

            setCustomer: newState => state => ({
                customer: newState,
            }),

            onBlur: ({ target: { name } }) => (state, actions) => {
                state.api.message = '';
                state.api.kind = '';
                actions.setState({ns: name, key: 'isTouched', value: true});
            },

            onSubmit: (event) => (state, actions) => {
                event.preventDefault();

                let newpwd = state.password.value;
                let newpwdconfirm = state.passwordconfirm.value;


                if (newpwd === '') {
                    state.api.message = t('Merci de renseigner un nouveau mot de passe.', {ns: 'public'});
                    state.api.kind = 'error';
                    actions.rendering();
                } else if (newpwd.length < 8) {
                    state.api.message = t('Votre mot de passe doit être composé de 8 caractères minimum.', {ns: 'public'});
                    state.api.kind = 'error';
                    actions.rendering();
                } else if (newpwd !== newpwdconfirm) {
                    state.api.message = t('Les mots de passe renseignés ne sont pas similaires.', {ns: 'public'});
                    state.api.kind = 'error';
                    actions.rendering();
                } else {
                    let data = {
                        newpassword: newpwd,
                    };
                    User.newPassword(data).then((res) => {
                        if (res.data && (res.data !== false)) {
                            state.api.message = t('Votre nouveau mot de passe a bien été enregistré.', {ns: 'public'});
                            state.api.kind = 'success';
                            actions.rendering();

                            let resetScheme = getQueryVariable('resetscheme', true);
                            let schemeLink = getQueryVariable('schemelink', true);
                            if (resetScheme && (resetScheme !== null) && (resetScheme !== undefined)) {
                                window.location.href = resetScheme;
                            } else {
                                setTimeout(function() {
                                    redirectToWebOrApp(state.customer);
                                }, 1500);
                            }
                        }
                    });
                }
            },

            rendering: () => state => ({
                rendering: !state.rendering
            }),
        }
        return action;
    }
}