import { h, Component } from '@app/utils';
import { isDefined } from '@app/core';
import './index.scss';

import ImgLess from './ico-less.png';
import ImgLessx2 from './ico-less@2x.png';

import ImgPlus from './ico-plus.png';
import ImgPlusx2 from './ico-plus@2x.png';

const Counter = Component(
    {
        count: 0,
        max: 5,
        min: 0,
        tmout: null,
        onfocus: null,
        oninput: null,
        name: 'counter',
        floating: false,
        isMobile: false,
        placeholder: '0',
        initialCount: '',
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            console.log('PROPS : uppp / then ', props)
            state.max = (props.max || state.max);
            state.min = (props.min || state.min);
            state.count = (props.count || state.count);
            if (props.onfocus) {
                state.onfocus = props.onfocus;
            }
            if (props.oninput) {
                state.oninput = props.oninput;
            }
            if (props.name) {
                state.name = props.name;
            }
            if (props.floating) {
                state.floating = props.floating;
            }
            if (props.isMobile) {
                state.isMobile = props.isMobile;
            }
            if (props.initialCount) {
                state.initialCount = props.initialCount;
                actions.setPlaceHolder(props.initialCount);
            } else {
                if (props.placeholder) {
                    actions.setPlaceHolder(props.placeholder);
                }
            }
            if (props.tag) {
                if (props.tag === 'PERSONAL_WEIGHT') {
                    state.initialCount = 70;
                    actions.setPlaceHolder(70);
                } else if (props.tag === 'PERSONAL_HEIGHT') {
                    state.initialCount = 180;
                    actions.setPlaceHolder(180);
                } else if (props.tag === 'PERSONAL_WAISTSIZE') {
                    state.initialCount = 60;
                    actions.setPlaceHolder(60);
                }
            }
        },
        onComponentUpdate: (props) => (state, actions) => {
            console.log('PROPS : uppp ', props)
            if (props.initialCount) {
                state.initialCount = props.initialCount;
                actions.setPlaceHolder(props.initialCount);
            } else {
                if (props.placeholder) {
                    actions.setPlaceHolder(props.placeholder);
                }
            }
        },
        down: () => state => ({ count: (((+state.count - 1) < state.min) ? state.min : (+state.count - 1)) }),
        up: () => state => ({ count: (((+state.count + 1) > state.max) ? state.max : (+state.count + 1)) }),
        setCount: newState => state => ({
            count: newState,
        }),
        setPlaceHolder: newState => state => ({
            placeholder: newState,
        }),
        isNumberKey: (evt) => (state, actions) => {
            let charCode = (evt.which) ? evt.which : evt.keyCode;
            if (charCode === 38) {
                actions.up();
            } else if (charCode === 40) {
                actions.down();
            } else {
                let pattern = /^\d+(\.?\d?)?$/;
                if (!state.floating) {
                    pattern = /^[0-9]+$/;
                }
                if (evt.target.value === '') {
                    actions.setCount('');
                } else if (!pattern.test(evt.target.value)) {
                    return true;
                }

                state.tmout = setTimeout(function() {
                    clearTimeout(state.tmout);
                    state.tmout = null;
                    if (state.floating) {
                        actions.setCount(evt.target.value.replace(',', '.'));
                    } else if (!isNaN(+evt.target.value)) {
                        actions.setCount(evt.target.value.replace(',', '.'));
                    }
                }, 100);
            }
            console.log('evt.target.value : return false');
            return false;
        }
    },
    (state, actions) => (props, children) => (
        <div class='btzCounter' id={props.id} data-id={props.did} data-tag={props.tag} data-value={state.count} data-min={(props.min || '')} data-max={(props.max || '')} data-default={(props.default || '')} data-kind={state.kind}>
            <input id={'input-' + props.id} class='btzCounter-btzCounterValue' inputmode={(state.isMobile ? 'numeric' : '')} type={(state.isMobile ? 'tel' : 'text')} pattern={(state.isMobile ? '[0-9]*' : '')} name={props.name} value={((state.count === 0) || (state.count === '') || (state.count === 'null')) ? '' : state.count} placeholder={state.placeholder} onkeyup={(evt) => {
                actions.isNumberKey(evt);
            }} onfocus={state.onfocus} oninput={state.oninput} onclick={() => {
                if ((state.count === 0) || (state.count === '') || (state.count === 'null')) {
                    actions.setCount(state.initialCount);
                    //
                    setTimeout(() => {
                        if (state.oninput !== null) {
                            state.oninput({target : document.getElementById('input-' + props.id)});
                        }
                    }, 100);
                } else {
                    state.count;
                }
            }} oncreate={(el) => { if (props.mobileOnboarding && el) { el.focus(); } if (isDefined(props.default) && (props.default !== '')) { actions.setCount(props.default); } }} />
            {props.label &&
                <div class='btzCounter-btzCounterLabel'>{props.label}</div>
            }
            <button class='btzCounter-btzCounterButtonLeft' onclick={() => {
                if (((state.count === 0) || (state.count === 'null')) && (state.initialCount !== '')) {
                    actions.setCount(+state.initialCount - 1);
                } else {
                    actions.down();
                }
                setTimeout(() => {
                    if (state.oninput !== null) {
                        state.oninput({target : document.getElementById('input-' + props.id)});
                    }
                }, 100);
            }}>
                <img src={ImgLess} srcset={`${ImgLessx2} 2x`} alt='' class='' />
            </button>
            <button class='btzCounter-btzCounterButtonRight' onclick={() => {
                if (((state.count === 0) || (state.count === 'null')) && (state.initialCount !== '')) {
                    actions.setCount(+state.initialCount + 1);
                } else {
                    actions.up();
                }
                if (state.oninput !== null) {
                    setTimeout(() => {
                        state.oninput({target : document.getElementById('input-' + props.id)});
                    }, 100);
                }
            }}>
                <img src={ImgPlus} srcset={`${ImgPlusx2} 2x`} alt='' class='' />
            </button>
        </div>
    ),
    'counter'
);

export { Counter };
