import { h, Component } from '@app/utils';
import { Switch } from '@app/elements';
import { getQueryVariable, getUser, dynLoad } from '@app/core';
import './index.scss';

const state = {
    howthatwork: false,
    customer: null,
    user: {
        id: null,
        objectives: [],
        cgu: null, // boolean
        email: null, // string
        password: null, // string
        device: null, // string
        fname: null, // string
        lname: null, // string
        gender: null, // boolean
        type: null, // company, mutual or b2c,
        company: null, // company membership
        companyId: null, // company id
        mutual: null, // mutual object
        mutualId: null, // mutual id
        individual: null, // something.
        payment: [], // stripe payment related thing,
        question: null, // string
        answer: null, // string
        isGuest: false
    }
};
/*
'security'     => 2,
'registration' => 3,
'themes'       => 4,
'trackers'     => 5,
'magic'        => 6,
'payment'      => 7,
'finish'       => 8

sponsor url is like: http://localhost:8080/home/register?guest=true&type=2-B2C&email=adrien%2Bspon%40betterise.me
*/
const actions = {
    onComponentCreate: (props) => (state, actions) => {
        for (var entry in state.user) {
            let stored = localStorage.getItem(entry);
            if (stored && (stored !== undefined) && (stored.length > 0) && (stored !== null) && (stored !== 'undefined')) {
                stored = JSON.parse(stored);
                actions.setStateOverided({ns : 'user', key : entry, value : stored})
            }
        }

        let guest = getQueryVariable('guest', false);
        let type = getQueryVariable('type', false);
        let email = getQueryVariable('email', false);
        console.log('REGISTER : is guest > ', guest);
        if (guest === true || guest === 'true') {
            actions.setStateOverided({ns : 'user', key : 'isGuest', value : true});
            if (type && (type !== undefined) && (type !== null)) {
                actions.setState({ns : 'user', key : 'type', value : type.split('-')[1].toLowerCase()});
            }
            if (email && (email !== undefined) && (email !== null)) {
                actions.setState({ns : 'user', key : 'email', value : decodeURIComponent(email)})
            }
        }

        let token = getQueryVariable('token', false);
        let refresh = getQueryVariable('refresh', false);
        let expire = getQueryVariable('expire', false);
        if (token && (token !== false)) {
            localStorage.setItem('apitoken', token);
            if (refresh && (refresh !== false)) {
                localStorage.setItem('refresh', refresh);
            }
            if (expire && (expire !== false)) {
                localStorage.setItem('expiration', expire);
            }

            let userCallback = (sessionUser) => {
                if (sessionUser) {
                    let userName = '';
                    if ((sessionUser.fname !== undefined) && (sessionUser.fname !== null)) {
                        userName = sessionUser.fname;
                    } else if ((sessionUser.nickName !== undefined) && (sessionUser.nickName !== null)) {
                        userName = sessionUser.nickName;
                    }
                    actions.setState({ns : 'user', key : 'fname', value : userName})
                }
            };
            getUser(userCallback, true);
        }

        if (props.customer) {
            actions.setCustomer(props.customer);
        }
    },
    onComponentUpdate: (props) => (state, actions) => {
        if (props.customer) {
            actions.setCustomer(props.customer);
        }
    },

    setCustomer: newState => state => ({
        customer: newState
    }),
    switchHowThatWork: () => state => ({
        howthatwork: !state.howthatwork,
    }),
    setState: ({ ns, key, value }) => (state, actions) => {
        localStorage.setItem(key, JSON.stringify(value));
        actions.setStateOverided({ns : ns, key : key, value : value})
    },
    setStateOverided: ({ ns, key, value }) => state => ({
        [ns]: { ...state[ns], ...{ [key]: value } },
    }),
};

const view = dynLoad('view', 'Register/views');

// used for lazy loading
export default Component(state, actions, view, 'register');
