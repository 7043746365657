export const state = {
    pregnancy: {
        value: '',
    },
    api: {
        message: '',
        code: 200,
    },
    isGuest: false,
    displayed: 'register',
    updateKind: null,
};
