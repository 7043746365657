import { t, h } from '@app/utils';
import { Button, Tile, Card, Avatar, Balloon } from '@app/elements';

export const ModalView = (props, actions) => (
    <Card kind={'resiliate'}>
        <Card.Header classes={'btzCard-btzHeader-btzModal'}>
            <p class='btzTitle btzXl'>Saisissez votre mot de passe pour confirmer votre résiliation :</p>

            {props.error !== '' &&
                <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap', 'margin-top': '50px' }}>
                    <Tile.Icon classes={''}>
                        <Avatar.Alfred classes={'centered'} />
                    </Tile.Icon>
                    <Tile.Content>
                        <Balloon kind={'error'} style={{ 'white-space': 'normal' }} actionAfterError={props.updateError}>{props.error}</Balloon>
                    </Tile.Content>
                </Tile>
            }

            <Button primary active>
                {t('Confirmer', {ns: 'health'})}
            </Button>
        </Card.Header>
    </Card>
);