import { h, Component } from '@app/utils';
import { dynLoad } from '@app/core';
import './index.scss';

const state = {
    userMedia: null,
    messageActive: false,
    appointments: [],
    biological: [],
    nutritional: [],
    tmpDate: new Date(),
    contactType: '',
    contactDateDay: '',
    contactDateMonth: '',
    contactDateYear: '',
    suspendSubmit: false,
}

const actions = dynLoad('action', 'Follow/actions');
const view = dynLoad('view', 'Follow/views');

export default Component(state, actions, view, 'follow');
