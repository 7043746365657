import { t, h } from '@app/utils';
import { Card, Button } from '@app/elements';
import AccountLayout from '@app/layouts/account/layout';

import * as staticsconstants from '@app/constants/constants-statics';

import Advice from './../../../Timeline/cards/advice';
import Article from './../../../Timeline/cards/article';
import Citation from './../../../Timeline/cards/citation';
import Recipe from './../../../Timeline/cards/recipe';
import NoFavorite from './../nofavorite';

module.exports = {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <AccountLayout page='favorites' readablePageLabel={t(staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()].menuNames.account.title)} readablePageDescription={t('Pour gérer au mieux votre accompagnement santé', {ns: 'account'})} userMedia={state.userMedia} customer={props.customer}>
                {state.initialized === true &&
                    <Card classes={'btzPageCard'} kind={'favorite'}>
                        <Card.Header classes={'btzCard-btzHeader-btzPage'}>
                            <div class='col-12'>
                                <p class='btzTimeline-btzCard-btzTitle btzPageContentTitle'>{t(staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()].menuNames.favorites.title)}</p>
                                <p class='btzTimeline-btzCard-btzSubTitle btzPageContentSubTitle'>{t('Retrouvez ci-dessous vos contenus favoris.', {ns: 'favorites'})}</p>
                            </div>
                        </Card.Header>

                        <Card.Body data-displayed-kind={state.filter}>
                            <div class='col-12'>
                                <Button flat active={(state.filter === 'all')} onclick={() => actions.setFilter('all')} >{t('Tous', {ns: 'favorites'})} ({state.favorites.all.length})</Button>
                                <Button flat active={(state.filter === 'article')} onclick={() => actions.setFilter('article')} >{t('Articles', {ns: 'favorites'})} ({state.favorites.article.length})</Button>
                                <Button flat active={(state.filter === 'citation')} onclick={() => actions.setFilter('citation')} >{t('Citations', {ns: 'favorites'})} ({state.favorites.citation.length})</Button>
                                <Button flat active={(state.filter === 'advice')} onclick={() => actions.setFilter('advice')} >{t('Conseils', {ns: 'favorites'})} ({state.favorites.advice.length})</Button>
                                <Button flat active={(state.filter === 'recipe')} onclick={() => actions.setFilter('recipe')} >{t('Recettes', {ns: 'favorites'})} ({state.favorites.recipe.length})</Button>
                            </div>
                            <div style={{ 'text-align': 'left' }}>
                                {state.favorites.all.map(item =>
                                    <div id={'fav-' + item.favoriteId} key={item.id} class='col-4 col-md-12 btzCard-btzFavorite' data-displaying={item.cardKind}>
                                        {item.type == 2 &&
                                            <Advice {...item} displayedon='favorite' propagate={actions.removeThisOne}></Advice>
                                        }
                                        {item.type == 18 &&
                                            <Article {...item} displayedon='favorite' propagate={actions.removeThisOne}></Article>
                                        }
                                        {item.type == 19 &&
                                            <Recipe {...item} displayedon='favorite' propagate={actions.removeThisOne}></Recipe>
                                        }
                                        {item.type == 20 &&
                                            <Citation {...item} displayedon='favorite' propagate={actions.removeThisOne}></Citation>
                                        }
                                    </div>
                                )}

                                {state.favorites.all.length == 0 &&
                                    <div class='col-12 btzCard-btzFavorite btzCard-btzFavorite-not' data-displaying={'noAll'}>
                                        <NoFavorite customer={props.customer} kind={t('de contenu', {ns: 'favorites'})}></NoFavorite>
                                    </div>
                                }

                                {state.favorites.advice.length == 0 &&
                                    <div class='col-12 btzCard-btzFavorite btzCard-btzFavorite-not' data-displaying={'noAdvice'}>
                                        <NoFavorite customer={props.customer} kind={t('de conseil', {ns: 'favorites'})}></NoFavorite>
                                    </div>
                                }

                                {state.favorites.article.length == 0 &&
                                    <div class='col-12 btzCard-btzFavorite btzCard-btzFavorite-not' data-displaying={'noArticle'}>
                                        <NoFavorite customer={props.customer} kind={t('d‘article', {ns: 'favorites'})}></NoFavorite>
                                    </div>
                                }

                                {state.favorites.recipe.length == 0 &&
                                    <div class='col-12 btzCard-btzFavorite btzCard-btzFavorite-not' data-displaying={'noRecipe'}>
                                        <NoFavorite customer={props.customer} kind={t('de recette', {ns: 'favorites'})}></NoFavorite>
                                    </div>
                                }

                                {state.favorites.citation.length == 0 &&
                                    <div class='col-12 btzCard-btzFavorite btzCard-btzFavorite-not' data-displaying={'noCitation'}>
                                        <NoFavorite customer={props.customer} kind={t('de citation', {ns: 'favorites'})}></NoFavorite>
                                    </div>
                                }
                            </div>
                        </Card.Body>

                        <Card.Footer></Card.Footer>
                    </Card>
                }
            </AccountLayout>
        );
        return view;
    }
}
