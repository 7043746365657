import { h, t } from '@app/utils';
import { Tile, Enter } from '@app/elements';

import logoStep from '@app/img/register/end-register.png';
import logoStepx2 from '@app/img/register/end-register@2x.png';

import logoStep1 from '@app/img/register/end-registers.png';
import logoStep1x2 from '@app/img/register/end-registers@2x.png';

module.exports = {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <div style={{ 'display': 'table-cell', 'vertical-align': 'middle' }}>
                <Enter time={800} easing='ease-in-out' css={{ transform: 'translateY(-100%)', opacity: '0', }}
                    style={{ 'position': 'relative', 'display': 'table-cell', 'vertical-align': 'middle' }}>
                    <Tile>
                        <div class='btzTitle-btzImage col-xs-12'>
                            <img src={logoStep} srcset={`${logoStepx2} 2x`} alt='' class='' style={{ 'padding': '61px 0' }} />
                        </div>
                        <div class='btzTitle-btzImage col-xs-12' style={{ 'height': '115px' }}>
                            <img src={logoStep1} srcset={`${logoStep1x2} 2x`} alt='' class='' />
                        </div>
                        <div class='btzTitle col-xs-12'>
                            <p class='btzTexte' style={{ 'margin': '0' }}>{t('Données enregistrées,', {ns: 'register'})}</p>
                            <p class='btzTexte' style={{ 'margin': '0' }}>{t('profil créé.', {ns: 'register'})}</p>
                        </div>
                    </Tile>
                </Enter>
            </div>
        );
        return view;
    }
}
