import { t } from '@app/utils';

export const state = {
    sex: {
        value: '',
    },
    api: {
        message: t('Sélectionnez l’un des deux boutons.', {ns: 'register'}),
        code: 200,
    },
    isGuest: false,
};
