export const state = {
    rootCustomer: 'betterise',
    isMedical: false,
    medicalStudytoken: null,
    timelineId: null,
    values: {
        email: '',
        password: '',
    },
    birthdate: {
        year: '',
        month: '',
        day: ''
    },
    isTouched: {
        email: false,
        password: false,
        year: false,
        month: false,
        day: false
    },
    isValid: {
        email: false,
        password: false,
        year: false,
        month: false,
        day: false
    },
    api: {
        message: '',
        code: 200,
        kind: 'info'
    },
    isPasswordContentVisible: true,
    message: [],
    formIsSubmitting: false,
    formSubmittedAtLeastOneTime: false,
    sendToParent: null,
    rendering: false,
    customer: '',
    loginStep: 0,
    medicalInfos: null,
};
