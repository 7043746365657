import { h } from '@app/utils';
import { Card } from '@app/elements';

import { StepListings } from './../../AddMeal/step-listings';

export const ModalViewAliments = (props, actions) => (
// export const ModalViewAliments = (state, actions) => (props, children) => (
    <Card classes={'btzInformations-btzInformationsModal btzInformations-btzBalanceModal'}>
        <Card.Header classes={'btzCard-btzHeader-btzModal'}>
            <div>
                <StepListings customer={props.customer} readableMealDate={props.readableMealDate} compositionsAliments={props.compositionsAliments} surveyLunchs={props.surveyLunchs} userMetrics={props.userMetrics} userGender={props.userGender}></StepListings>
            </div>
        </Card.Header>
    </Card>
);