import { t, h, Component, isLength, isNumeric } from '@app/utils';
import { debounce } from 'hyperapp-fx';
import { User, City, Session } from '@app/api';
import { setUser, isEmail, isDefined, convertDateToUTC, humanizeDate, dynLoad } from '@app/core';
import { appCustoSetting } from '@app/settings/settings';

import './index.scss';

const state = {
    submitting: false,
    gender: {
        question: 'Êtes-vous un homme ou une femme ?',
        i18nQuestion: t('Êtes-vous un homme ou une femme ?', {ns: 'informations'}),
        placeholder: '',
        isTouched: false,
        isValid: true,
        value: null,
        kind: 'gender',
        submit: 'gender',
        type: 'radio',
        api: {
            message: 'Sélectionnez l’un des deux boutons.',
            i18nMessage: t('Sélectionnez l’un des deux boutons.', {ns: 'informations'}),
            kind: 'info',
            errors: {
                valid: 'Sélectionnez l’un des deux boutons.',
                i18nValid: t('Sélectionnez l’un des deux boutons.', {ns: 'informations'}),
                empty: '',
                invalid: '',
            }
        }
    },
    nick: {
        question: 'Comment dois-je vous appeler ?',
        i18nQuestion: t('Comment dois-je vous appeler ?', {ns: 'informations'}),
        placeholder: 'Votre prénom',
        i18nPlaceholder: t('Votre prénom', {ns: 'informations'}),
        isTouched: true,
        isValid: true,
        value: '',
        kind: 'nick',
        submit: 'nickname',
        type: 'text',
        api: {
            message: 'Renseignez votre prénom ou un pseudo.',
            i18nMessage: t('Renseignez votre prénom ou un pseudo.', {ns: 'informations'}),
            kind: 'info',
            errors: {
                i18nValid: t('Renseignez votre prénom ou un pseudo.', {ns: 'informations'}),
                valid: 'Renseignez votre prénom ou un pseudo.',
                i18nEmpty: t('Merci de renseigner votre prénom ou un pseudo.', {ns: 'informations'}),
                empty: 'Merci de renseigner votre prénom ou un pseudo.',
                i18nInvalid: t('Veuillez renseigner un pseudo valide.', {ns: 'informations'}),
                invalid: 'Veuillez renseigner un pseudo valide.',
            }
        }
    },
    city: {
        question: 'Dans quelle ville résidez-vous ?',
        i18nQuestion: t('Dans quelle ville résidez-vous ?', {ns: 'informations'}),
        placeholder: 'Votre ville',
        i18nPlaceholder: t('Votre ville', {ns: 'informations'}),
        isTouched: false,
        isValid: false,
        value: '',
        values: [],
        kind: 'city',
        submit: 'city',
        type: 'city',
        api: {
            message: 'Tapez les premières lettres de votre ville puis sélectionnez-la dans la liste.',
            i18nMessage: t('Tapez les premières lettres de votre ville puis sélectionnez-la dans la liste.', {ns: 'informations'}),
            kind: 'info',
            errors: {
                i18nValid: t('Tapez les premières lettres de votre ville puis sélectionnez-la dans la liste.', {ns: 'informations'}),
                valid: 'Tapez les premières lettres de votre ville puis sélectionnez-la dans la liste.',
                i18nEmpty: t('Merci de renseigner votre ville de résidence.', {ns: 'informations'}),
                empty: 'Merci de renseigner votre ville de résidence.',
                i18nInvalid: t('Le nom de la ville semble invalide. Tapez les premières lettres de votre ville puis sélectionnez-la dans la liste.', {ns: 'informations'}),
                invalid: 'Le nom de la ville semble invalide. Tapez les premières lettres de votre ville puis sélectionnez-la dans la liste.',
            }
        }
    },
    birthdate: {
        question: 'Quelle est votre date de naissance ?',
        i18nQuestion: t('Quelle est votre date de naissance ?', {ns: 'informations'}),
        placeholder:  {
            year: 'AAAA',
            month: 'MM',
            day: 'JJ'
        },
        isTouched: {
            year: true,
            month: true,
            day: true
        },
        isValid: {
            year: false,
            month: false,
            day: false
        },
        value: {
            year: '',
            month: '',
            day: ''
        },
        kind: 'birthdate',
        submit: 'birthdate',
        type: 'date',
        api: {
            message: 'Renseignez votre date de naissance.',
            i18nMessage: t('Renseignez votre date de naissance.', {ns: 'informations'}),
            kind: 'info',
            errors: {
                i18nValid: t('Renseignez votre date de naissance.', {ns: 'informations'}),
                valid: 'Renseignez votre date de naissance.',
                i18nEmpty: t('Merci de renseigner votre date de naissance.', {ns: 'informations'}),
                empty: 'Merci de renseigner votre date de naissance.',
                i18nInvalid: t('Merci de saisir une date de naissance valide.', {ns: 'informations'}),
                invalid: 'Merci de saisir une date de naissance valide.',
            }
        }
    },
    email: {
        question: 'Modification de votre adresse email',
        i18nQuestion: t('Modification de votre adresse email', {ns: 'informations'}),
        placeholder: 'Votre nouvelle adresse email',
        i18nPlaceholder: t('Votre nouvelle adresse email', {ns: 'informations'}),
        isTouched: true,
        isValid: true,
        value: '',
        kind: 'email',
        submit: 'email',
        type: 'email',
        api: {
            message: 'Renseignez votre mot de passe pour pouvoir modifier votre adresse email.',
            i18nMessage: t('Renseignez votre mot de passe pour pouvoir modifier votre adresse email.', {ns: 'informations'}),
            kind: 'info',
            errors: {
                i18nValid: t('Renseignez votre mot de passe pour pouvoir modifier votre adresse email.', {ns: 'informations'}),
                valid: 'Renseignez votre mot de passe pour pouvoir modifier votre adresse email.',
                i18nEmpty: t('Merci de renseigner votre nouvelle adresse email.', {ns: 'informations'}),
                empty: 'Merci de renseigner votre nouvelle adresse email.',
                i18Iinvalid: t('Votre nouvelle adresse email semble invalide.', {ns: 'informations'}),
                invalid: 'Votre nouvelle adresse email semble invalide.',
                i18nInvalidVerif: t('Votre mot de passe semble invalide.', {ns: 'informations'}),
                invalidVerif: 'Votre mot de passe semble invalide.'
            }
        }
    },
    passwordemail: {
        question: '',
        placeholder: '',
        isTouched: true,
        isValid: false,
        value: '',
        kind: 'password',
        submit: 'password',
        type: 'password',
        api: {
            message: '',
            kind: '',
            errors: {
                valid: '',
                empty: '',
                invalid: '',
            }
        }
    },
    secret: {
        question: '',
        placeholder: '',
        isTouched: true,
        isValid: false,
        value: '',
        kind: 'text',
        submit: 'secret',
        type: 'secret',
        api: {
            message: '',
            kind: '',
            errors: {
                valid: '',
                empty: '',
                invalid: '',
            }
        }
    },
    password: {
        question: 'Modification de votre mot de passe',
        i18nQuestion: t('Modification de votre mot de passe', {ns: 'informations'}),
        placeholder: 'Votre nouveau mot de passe',
        i18nPlaceholder: t('Votre nouveau mot de passe', {ns: 'informations'}),
        isTouched: true,
        isValid: false,
        value: '',
        kind: 'password',
        submit: 'password',
        type: 'password',
        api: {
            message: 'Renseignez votre nouveau mot de passe.',
            i18nmessage: t('Renseignez votre nouveau mot de passe.', {ns: 'informations'}),
            kind: 'info',
            errors: {
                valid: 'Renseignez votre nouveau mot de passe.',
                empty: '',
                invalid: '',
            }
        }
    },
    oldpassword: {
        question: '',
        placeholder: 'Votre mot de passe actuel',
        i18nPlaceholder: t('Votre mot de passe actuel', {ns: 'informations'}),
        isTouched: true,
        isValid: false,
        value: '',
        kind: 'oldpassword',
        submit: 'oldpassword',
        type: 'password',
        api: {
            message: '',
            kind: '',
            errors: {
                valid: '',
                empty: '',
                invalid: '',
            }
        }
    },
    languages: null,
    language: {
        question: 'Sélectionnez votre langue préférée :',
        i18nQuestion: t('Sélectionnez votre langue préférée :', {ns: 'informations'}),
        placeholder: '',
        isTouched: false,
        isValid: true,
        value: '',
        kind: 'language',
        submit: 'language',
        type: 'dropdown',
        api: {
            message: '',
            kind: '',
            errors: {
                valid: '',
                empty: '',
                invalid: '',
            }
        }
    },
    timezone: {
        question: 'Sélectionnez votre fuseau horaire :',
        i18nQuestion: t('Sélectionnez votre fuseau horaire :', {ns: 'informations'}),
        placeholder: '',
        isTouched: false,
        isValid: true,
        value: '',
        kind: 'timezone',
        submit: 'timezone',
        type: 'dropdown',
        api: {
            message: '',
            kind: '',
            errors: {
                valid: '',
                empty: '',
                invalid: '',
            }
        }
    },
    metrics: {
        question: 'Sélectionnez vos unités de mesure :',
        i18nQuestion: t('Sélectionnez vos unités de mesure :', {ns: 'informations'}),
        placeholder: '',
        isTouched: false,
        isValid: true,
        value: '',
        kind: 'metrics',
        submit: 'metrics',
        type: 'dropdown',
        api: {
            message: '',
            kind: '',
            errors: {
                valid: '',
                empty: '',
                invalid: '',
            }
        }
    },
    media: {
        value: null,
    },
    current: {
        kind: 'none',
    },
    api: {
        message: '',
        kind: 'info',
    },
    settings: null,
    timezones: null,
    rendering: false,
    messageActive: false,
    thisYear: null,
    customer: null,
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        state.thisYear = +(new Date()).getFullYear();
        state.settings = appCustoSetting.appsettings;

        let tradLanguages = {};
        for (var lang in state.settings.LANGUAGES) {
            tradLanguages[lang] = t(state.settings.LANGUAGES[lang]);
        }
        actions.setLanguages(tradLanguages);

        if (props.customer) {
            state.customer = props.customer;
        }

        User.getTimezone().then((res) => {
            let parsedTimez = [];
            for (var timez in res.data) {
                parsedTimez[res.data[timez]] = res.data[timez];
            }
            actions.setTimezones(parsedTimez);
        });

        actions.onComponentUpdate(props);
    },

    onComponentUpdate: (props) => (state, actions) => {
        for (var userEntry in props) {
            for (var stateEntry in state) {
                if ((userEntry === stateEntry) || (userEntry == 'cityName')) {
                    if (userEntry.indexOf('date') > -1) {
                        let parsedDate = props[userEntry];
                        if (isDefined(parsedDate) && parsedDate.date) {
                            parsedDate = parsedDate.date.split(' ')[0].split('-');
                            if (isDefined(parsedDate) && (parsedDate.length === 3)) {
                                state[userEntry].value.year = parsedDate[0];
                                state[userEntry].value.month = parsedDate[1];
                                state[userEntry].value.day = parsedDate[2];

                                state[userEntry].isValid.year = true;
                                state[userEntry].isValid.month = true;
                                state[userEntry].isValid.day = true;
                            }
                        }
                    } else if (userEntry === 'metrics') {
                        state[userEntry].value = props[userEntry] + '-' + props.heightMetrics;
                    } else if (userEntry === 'cityName') {
                        // if (isDefined(props.profile[userEntry]) && (props.profile[userEntry].name !== '')) {
                        //     actions.setState({ns: userEntry, key: 'value', value: props.profile[userEntry].name});
                        // }
                        if ((props.cityName) && (props.cityName !== '')) {
                            actions.setState({ns: 'city', key: 'value', value: props.cityName});
                        }
                    } else if (userEntry === 'media') {
                        if (isDefined(props[userEntry]) && (props[userEntry].default === false)) {
                            actions.setState({ns: userEntry, key: 'value', value: props[userEntry].url});
                        }
                    } else {
                        actions.setState({ns: userEntry, key: 'value', value: props[userEntry]});
                    }
                }
            }
        }
        actions.rendering();
    },

    onUpdate: ({ target: { name, value } }) => (state, actions) => {
        let isValid = actions.isInputValid({ name, value });
        if (name.indexOf('date') > -1) {
            let refPeriod = name.split('-')[1];

            state.current.api.message = t(state.current.api.errors.valid);
            state.current.api.kind = 'info';
            actions.setDateState({ns: 'current', key: 'value', subkey: refPeriod, value: value});
            actions.setDateState({ns: 'current', key: 'isValid', subkey: refPeriod, value: isValid});
        } else if ((name.indexOf('password') > -1) || (name === 'secret')) {
            state[name].api.message = t(state[name].api.errors.valid);
            state[name].api.kind = 'info';
            actions.setState({ns: name, key: 'value', value: value});
            actions.setState({ns: name, key: 'isValid', value: isValid});

            if (name === 'password') {
                actions.setState({ns: 'current', key: 'value', value: value});
                actions.setState({ns: 'current', key: 'isValid', value: isValid});
            } else if (name === 'oldpassword') {
                actions.setState({ns: 'oldpassword', key: 'isValid', value: isValid});
            }
        } else {
            state.current.api.message = t(state.current.api.errors.valid);
            state.current.api.kind = 'info';
            actions.setState({ns: 'current', key: 'value', value: value});
            actions.setState({ns: 'current', key: 'isValid', value: isValid});
        }
    },

    onCitiesListClick: city => (state, actions) => {
        actions.setCityValue(city);
        actions.setCityValues([]);
        actions.setState({ns: 'current', key: 'isValid', value: (state.city.values.map((f) => f).indexOf(city) > -1)});
        actions.setState({ns: 'current', key: 'isTouched', value: true});
    },

    onCityInputUpdate: city => (state, actions) => {
        if (city === '') return false

        City.getCitiesByName(state.language.value.split('_')[0], city)
            .then(({ data }) => {
                actions.setCityValues(data);
            })
    },

    onCityInputDebouncedUpdate: (city) => (state, actions) => debounce(
        600,
        'onCityInputUpdate',
        city
    ),

    setCityValue: city => (state, actions) => actions.setState({
        ns: 'current',
        key: 'value',
        value: city
    }),

    setCityValues: values => (state, actions) => actions.setState({
        ns: 'city',
        key: 'values',
        value: values
    }),

    waitForLastInput: ({ target }) => debounce(
        500,
        'onUpdate',
        { target },
    ),

    updateCurrent: (newCurrent) => (state, actions) => {
        actions.setCurrent(state[newCurrent]);
    },

    setCurrent: newState => state => ({
        current: JSON.parse(JSON.stringify(newState)),
    }),

    setLanguages: newState => state => ({
        languages: newState,
    }),

    setTimezones: newState => state => ({
        timezones: newState,
    }),

    toggleSubmitting: newState => state => ({
        submitting: newState,
    }),

    setState: ({ ns, key, value }) => state => ({
        [ns]: { ...state[ns], ...{ [key]: value } },
    }),

    setDateState: ({ ns, key, subkey, value }) => state => ({
        [ns]: { ...state[ns], [key] : { ...state[ns][key], [subkey] : value } },
    }),

    rendering: () => state => ({
        rendering: !state.rendering,
    }),

    displaySuccessMessage: newState => state => ({
        messageActive: newState,
    }),

    setCurrentApiErrorsMessage: newState => state => ({
        current: {...state.current, api: {...state.current.api, message: newState}},
    }),
    setCurrentApiErrorsKind: newState => state => ({
        current: {...state.current, api: {...state.current.api, kind: newState}},
    }),

    isInputValid: ({ name, value }) => (state, actions) => {
        if (name === undefined) {
            return false;
        }

        switch (name) {
            case 'email':
                return isEmail(value);
            case 'password':
                return isLength(value, { min: 8 });
            case 'oldpassword':
            case 'passwordemail':
            case 'secret':
            case 'nick':
                return isLength(value, { min: 1});
            case 'birthdate-day':
                return (isNumeric(value) && isLength(value, { min: 2, max: 2}) && (value > 0) && (value < 32));
            case 'birthdate-month':
                return (isNumeric(value) && isLength(value, { min: 2, max: 2}) && (value > 0) && (value < 13));
            case 'birthdate-year':
                return (isNumeric(value) && isLength(value, { min: 4, max: 4}) && (value > 1900) && (value < 2018));
            default:
                throw Error(`${name} has no validator assigned`);
        }
    },

    onBlur: ({ target: { name } }) => (state, actions) => {
        actions.setState({ns: name, key: 'isTouched', value: true});
        actions.setCurrentApiErrorsKind('info');
        actions.setCurrentApiErrorsMessage(t(state.current.api.errors.valid));
    },

    onSubmit: () => (state, actions) => {
        let data = {};
        let field = state.current.submit;
        let value = state.current.value;
        let isValid = state.current.isValid;
        let verifIsValid = true;
        if (field.indexOf('date') > -1) {
            isValid = (state.current.isValid.year && state.current.isValid.month && state.current.isValid.day);
        }
        data[field] = value;
        if (state.submitting) {
            return false;
        }
        actions.toggleSubmitting(true);

        if (isValid) {
            if (field === 'email') {
                let verifValue = state.passwordemail.value;
                verifIsValid = state.passwordemail.isValid;

                data = {};
                data['email'] = value;
                data['password'] = verifValue;
            } else if (field === 'password') {

            } else if (field === 'metrics') {
                let datas = value.split('-');

                data = {};
                data['metrics'] = datas[0];
                data['heightMetrics'] = datas[1];
            } else if (field.indexOf('date') > -1) {
                data = {};
                let birthDate = humanizeDate(convertDateToUTC(new Date(value.year, value.month, value.day)));
                data[field] = {
                    date: value.year + '-' + value.month + '-' + value.day + ' 12:00:00.000000',
                    timezone: 'UTC',
                    timezone_type: 3
                }
            }
        }

        if (field === 'password') {
            let pwdError = '';
            if ((state.oldpassword.value === undefined) || (state.oldpassword.value === '')) {
                pwdError = t('Merci de renseigner votre mot de passe actuel.', {ns: 'generals'});
            } else if ((state.password.value === undefined) || (state.password.value === '')) {
                pwdError = t('Merci de renseigner un nouveau mot de passe.', {ns: 'informations'});
            } else if (!isLength(state.password.value, { min: 8 })) {
                pwdError = t('Veuillez renseigner un mot de passe composé de 8 caractères minimum.', {ns: 'informations'});
            }
            if (pwdError !== '') {
                actions.setCurrentApiErrorsMessage(pwdError);
                actions.setCurrentApiErrorsKind('error');
                actions.toggleSubmitting(false);
                return false;
            }
            //
            Session.createSession(state.email.value, state.oldpassword.value, state.customer).then((res) => {
                if (res.data && (res.data.token !== undefined)) {
                    Session.newPassword(state.password.value).then((res) => {
                        if (res.data === false) {
                            actions.toggleSubmitting(false);
                        } else {
                            actions.displaySuccessMessage(true);
                            actions.setState({ns: state.current.kind, key: 'value', value: value});
                            actions.rendering();
                            document.body.classList.remove('btzOverflowHidden');
                        }
                    });
                } else {
                    actions.toggleSubmitting(false);
                    actions.setCurrentApiErrorsMessage(t('Votre mot de passe semble incorrect.', {ns: 'generals'}));
                    actions.setCurrentApiErrorsKind('error');
                }
            })
        } else {
            if ((value === '') || (value === undefined)) {
                actions.setCurrentApiErrorsMessage(t(state.current.api.errors.empty));
                actions.setCurrentApiErrorsKind('error');
                actions.toggleSubmitting(false);
            } else if (!isValid) {
                actions.setCurrentApiErrorsMessage(t(state.current.api.errors.invalid));
                actions.setCurrentApiErrorsKind('error');
                actions.toggleSubmitting(false);
            } else if (!verifIsValid) {
                actions.setCurrentApiErrorsMessage(t(state.current.api.errors.invalidVerif));
                actions.setCurrentApiErrorsKind('error');
                actions.toggleSubmitting(false);
            } else {
                User.updateUser(data).then((res) => {
                    if (res.data === false) {
                        actions.toggleSubmitting(false);
                    } else {
                        actions.displaySuccessMessage(true);
                        setUser(res.data);
                        if (field === 'language') {
                            localStorage.setItem(field, value);
                            setTimeout(() => {
                                window.location.reload();
                            }, 50);
                        } else {
                            actions.setState({ns: state.current.kind, key: 'value', value: value});
                            actions.rendering();
                            actions.toggleSubmitting(false);
                            localStorage.setItem('user', JSON.stringify(res.data));
                            document.body.classList.remove('btzOverflowHidden');
                        }
                    }
                });
            }
        }
    },
};

const view = dynLoad('view', 'Account/parameter/informations/views');

export default Component(state, actions, view, 'informations');
