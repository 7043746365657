export const state = {
    form: {
        isSubmitting: false
    },
    mutual: {
        value: '',
        isValid: false,
        isTouched: true,
        isApproved: null,
        choosen: {}
    },
    mutuals: {
        values: []
    },
    api: {
        status: '',
        message: ''
    },
    customer: '',
};
