import { t, h, Component } from '@app/utils';
import { getUser } from '@app/core';
import './index.scss';

import SpecificPageLayout from '@app/layouts/page/layout';
import WhatIsKlesiaNetflix from './what_is_klesia_netflix';

const state = {
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        let userCallback = (sessionUser) => {
            actions.setUserMedia((sessionUser.media !== null) ? sessionUser.media.url : '');
        };
        getUser(userCallback);
    },
    setUserMedia: newState => state => ({
        userMedia: newState,
    }),
};

const view = (state, actions) => (props, children) => (
    <dummy>
        {props.specific == 'what_is_klesia_netflix' &&
            <SpecificPageLayout page='specificpage' customer={props.customer} readablePageLabel={t('Klesia, c’est quoi ?', {ns: 'specificpage'})} readablePageDescription={t('Pour comprendre le rôle de Klesia', {ns: 'specificpage'})} userMedia={state.userMedia}>
                <WhatIsKlesiaNetflix customer={props.customer}></WhatIsKlesiaNetflix>
            </SpecificPageLayout>
        }
    </dummy>
);

export default Component(state, actions, view, 'specificpage');
