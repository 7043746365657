import { t, h } from '@app/utils';
import { Button, Form, Tile, Card, Avatar, Balloon } from '@app/elements';

export const ModalViewADH = (props, actions) => (
    <Card classes={'btzNotificationCard-btzADH'}>
        <Card.Header classes={'btzCard-btzHeader-btzModal'}>
            <p class='btzTitle btzXl'>{t('Quel est le numéro d’adhérent à votre mutuelle', {ns: 'timeline'}) + ' ?'}</p>

            <Form onsubmit={event => event.preventDefault()}>

                <Form.Group>
                    <Form.Input
                        classes={'btzFullWidth'}
                        placeholder={t('Votre numéro d‘adhérent', {ns: 'timeline'})}
                        type='text'
                        id='adh'
                        name='adh'
                        onfocus={props.onBlur}
                        oninput={props.waitForLastInput}
                        error={props.isTouched.adh && !props.isValid.adh}
                        classes={(props.isTouched.adh) ? (props.isValid.adh ? 'is-success' : 'is-error') : ''}
                    />
                </Form.Group>

                <Form.Group classes='btzForm-btzFooter'>
                    <Button primary flat active={props.isValid.adh} onclick={(e) => props.onSubmit({event: e, props: props})}>
                        {t('Enregistrer', {ns: 'generals'})}
                    </Button>
                </Form.Group>
            </Form>

            <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap' }}>
                <Tile.Icon>
                    <Avatar.Alfred classes={'centered'} />
                </Tile.Icon>
                <Tile.Content>
                    <Balloon kind={props.api.kind} style={{ 'white-space': 'normal' }}>
                        {t(props.api.message)}
                    </Balloon>
                </Tile.Content>
            </Tile>
        </Card.Header>
    </Card>
);