import { handleErrors } from '@app/api/errors';
import { appCustoSetting } from '@app/settings/settings';
import { queueQueries } from '@app/api/controlleur';
const { API_URL } = appCustoSetting.appsettings;

function initialQuery(date, period) {
    return queueQueries(`${API_URL}/dashboard/init/${date}/${period}/`, 'GET')
}

function themeQuery(theme, date, period) {
    return queueQueries(`${API_URL}/dashboard/${theme}/${date}/${period}/`, 'GET')
}

function postData(datas) {
    return queueQueries(`${API_URL}/data/`, 'POST', 'application/json', true, true, true, true, true, datas)
}

function getSports() {
    return queueQueries(`${API_URL}/sports/`, 'GET', 'application/json')
}

export const Data = {
    initialQuery,
    themeQuery,
    postData,
    getSports,
};
