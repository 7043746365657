import { t, isAlphanumeric } from '@app/utils';

import * as staticsconstants from '@app/constants/constants-statics';

export const actions = {
    onComponentCreate: (props) => (state, actions) => {
        if (props.initial) {
            actions.getInitialStateAndUpdate(props.initial);
        }

    },

    getInitialStateAndUpdate: (initial) => (state, actions) => {
        for (var ntl in initial) {
            let found = false;
            for (var stt in state) {
                if (stt === ntl)
                    found = true;
            }
            if (!found)
                continue;

            if (initial[ntl] != 'null') {
                actions.setState({
                    ns: ntl,
                    key: 'value',
                    value: initial[ntl]
                });
                if ((initial[ntl] !== null) && (initial[ntl].length > 0)) {
                    actions.setPseudoValidity(isAlphanumeric(initial[ntl]));
                    actions.setFNameTouched(true);
                }
            }
        }
    },

    setApiErrors: newState => state => ({
        api: newState,
    }),

    setPseudoValidity: (boolean) => (state, actions) => actions.setState({
        ns: 'fname',
        key: 'isValid',
        value: boolean
    }),

    setPseudoValue: (string) => (state, actions) => actions.setState({
        ns: 'fname',
        key: 'value',
        value: string
    }),

    onUpdate: ({ target: { value } }) => (state, actions) => {
        actions.setPseudoValue(value)
        actions.setPseudoValidity(value !== '');
    },

    goToNextStep: (props) => (state, actions) => {
        if (!staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()].modules.registerWorkflow.sexAfterPseudo) {
        // if (props.customer && ((props.customer === 'feelgood') || (props.customer === 'klesia'))) {
            window.main.location.go('/home/register/objectives');
        } else {
            window.main.location.go('/home/register/sex');
        }
    },

    onFNameInputTouched: () => (state, actions) => {
        actions.setApiErrors({
            'message': '',
            'kind': 'info',
        });
        if (!state.fname.isTouched) {
            actions.setFNameTouched(true)
        }
    },

    setFNameTouched: (boolean) => (state, actions) => actions.setState({
        ns: 'fname',
        key: 'isTouched',
        value: boolean
    }),

    setState: ({ ns, key, value }) => (state, actions) => ({
        [ns]: { ...state[ns], ...{ [key]: value } }
    }),

    updateParentProps: (props) => (state, actions) => {
        if (props) {
            // props.send({
            //     ns: 'pseudo',
            //     key: 'passed',
            //     value: true
            // })

            props.send({
                ns: 'user',
                key: 'fname',
                value: state.fname.value
            })
        }
    },

    onStepSubmit: ({event, props}) => (state, actions) => {
        event.preventDefault();

        if (!state.fname.isValid) {
            actions.setApiErrors({
                'message': t('Merci de saisir un pseudo.', {ns: 'register'}),
                'kind': 'error',
            });

            let stmt = setTimeout(() => {
                clearTimeout(stmt);
                stmt = null;
                actions.setApiErrors({
                    'message': '',
                    'kind': 'info',
                });
            }, 3500);
        } else {
            actions.goToNextStep(props);
        }
    }
};
