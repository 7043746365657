import { t } from '@app/utils';
import { Mail } from '@app/api';
import { getQueryVariable } from '@app/core';
import { allRoutes } from '@app/settings';

export const actions = {
    onComponentCreate: (props) => (state, actions) => {
        let emailid = null;
        if ((props.match.params === undefined) || (props.match.params.emailid === undefined)) {
            emailid = getQueryVariable('id', false);
        } else {
            emailid = props.match.params.emailid;
        }

        actions.setGoToUrl(allRoutes['index'].pathname);
        if (props.customer) {
            actions.setGoToUrl(allRoutes['index'].pathname + '?customerssoname=' + props.customer);
            if (props.customer === 'partena') {
                actions.setGoToLabel(t('Visiter', {ns: 'public'}) + ' ' + 'Partenamut');
                actions.setGoToUrl('http://www.partenamut.be/vitalite?utm_medium=email&utm_source=app_vitalite&utm_campaign=visit_partenamut-be');
            } else if (props.customer === 'gjensidige') {
                actions.setGoToLabel(t('Visiter', {ns: 'public'}) + ' ' + 'Iver');
                // actions.setGoToUrl('');
            } else if (props.customer === 'cerba') {
                actions.setGoToLabel(t('Visiter', {ns: 'public'}) + ' ' + 'Eliott');
                // actions.setGoToUrl('');
            } else if (props.customer === 'vyv') {
                actions.setGoToLabel(t('Visiter', {ns: 'public'}) + ' ' + 'B.E by Vivoptim');
                // actions.setGoToUrl('');
            }
        }

        if (emailid !== null) {
            Mail.show(emailid).then((res) => {
                if (res.data && (res.data.content !== undefined)) {
                    actions.setEmail(res.data.content);
                } else {
                    actions.setEmail(false);
                }
            });
        }
    },
    setGoToLabel: newState => state => ({
        goToLabel: newState,
    }),
    setGoToUrl: newState => state => ({
        goToUrl: newState,
    }),
    setEmail: newState => state => ({
        email: newState,
    })
};
