import { t, h } from '@app/utils';
import { Button, Form, Tile, Avatar, Balloon } from '@app/elements';

import logoResetPwd from '@app/img/public/password/you-are-pwdreset.png';
import logoResetPwdx2 from '@app/img/public/password/you-are-pwdreset@2x.png';

import PublicLayout from '@app/layouts/public/layout';

module.exports = {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <div key='publicsecret'>
                <PublicLayout page='secret' customer={props.customer}>
                    <div class='publicsecret-secret'>
                        <div style={{ position: 'relative' }}>
                            <Tile>
                                <div>
                                    <div class='btzTitle-btzImage btzTitle-btzImage-absolute col-md-12 col-4'>
                                        <img src={logoResetPwd} srcset={`${logoResetPwdx2} 2x`} alt='' class='' />
                                    </div>
                                    <Tile.Content classes='btzLeftHrArrowed col-xs-12 col-8'>
                                        <p class='btzTitle btzXl' style={{ 'max-width': '320px' }}>{t('Votre question secrète', {ns: 'public'})}</p>
                                        <p class='btzTitle btzXl' style={{ 'max-width': '320px' }}>{state.question}</p>

                                        <Form onsubmit={event => event.preventDefault()} style={{ 'max-width': '450px' }}>
                                            <Form.Group>
                                                <Form.Input
                                                    placeholder={t('Votre réponse', {ns: 'public'})}
                                                    type='text'
                                                    id='secret'
                                                    name='secret'
                                                    onfocus={actions.onBlur}
                                                    oninput={actions.waitForLastInput}
                                                    classes={(state.secret.isTouched) ? (state.secret.isValid ? 'is-success' : 'is-error') : ''}
                                                    required
                                                />
                                            </Form.Group>

                                            <Form.Group classes='btzForm-btzFooter'>
                                                <Button primary block active={state.secret.isValid}
                                                    onclick={(event) => {
                                                        actions.onSubmit(event)
                                                    }}>
                                                    {t('Valider', {ns: 'generals'})}
                                                </Button>
                                            </Form.Group>
                                        </Form>

                                        {state.api.message !== '' &&
                                            <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap' }}>
                                                <Tile.Icon>
                                                    <Avatar.Alfred classes={'centered'} />
                                                </Tile.Icon>
                                                <Tile.Content>
                                                    <Balloon kind={state.api.kind} style={{ 'white-space': 'normal' }}>
                                                        {state.api.message}
                                                    </Balloon>
                                                </Tile.Content>
                                            </Tile>
                                        }
                                    </Tile.Content>
                                </div>
                            </Tile>
                        </div>
                    </div>
                </PublicLayout>
            </div>
        );
        return view;
    }
}
