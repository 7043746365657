export const state = {
    objectives: {
        values: [],
        choosen: []
    },
    api: {
        message: '',
        code: 200,
    },
    isB2c: false,
};
