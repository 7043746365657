import { t } from '@app/utils';
import * as constants from '@app/constants';
import * as sports from '@app/sports';

export const state = {
    customer: '',
    type: '',
    today: null,
    keyprimary: '',
    rendering: false,
    data: null,
    mode: '',
    modeLabel: '',
    modeLabelPrefix: '',
    kind: '',
    queryKind: '',
    x: null,
    subx: null,
    xAxis: null,
    y: null,
    yAxis: null,
    yAxisUnit: '',
    ydomain: 10,
    ydomainMin: 0,
    ydomainMax: 10,
    ydomainOffset: 10,
    dynYdomainMax: false,
    dynYdomainMin: false,
    width: 500,
    height: 235,
    rootContainer: null,
    rootContainerJS: null,
    pathLine: null,
    valueLine: null,
    defaultSelected: null,
    displayQuestion: false,
    xLabelHeight: 50,
    nShowMore: 1,
    ticksNumber: {
        x: {
            week: 7,
            month: null,
            year: 12,
        },
        y: {
            week: 3,
            month: 3,
            year: 3,
        },
        format: {
            week: '%a %d/%m',
            month: '%a',
            year: '%B',
        },
        substring: {
            week: null,
            month: 1,
            year: 3,
        }
    },
    padding: {
        left: 50,
        bottom: 20,
    },
    margin: {
        left: 55,
        top: 30,
    },
    variations: null,
    extremAndMean: null,
    extremAndMeanConfig: {
        min: true,
        max: true,
        mean: true,
        total: false,
        weight: false,
        imc: false
    },
    qualitySleep: null,
    yearQualitySleep: null,
    theme: {
        label: '',
        unit: '',
    },
    valueUnit: '',
    readableDate: '',
    variationsInverted: false,
    rectBg: null,
    rect: {
        week: {
            width: 38,
            height: 38,
            fontsize: 24,
            dy: 9,
        },
        month: {
            width: 22,
            height: 22,
            fontsize: 14,
            dy: 5,
        },
        year: {
            width: 38,
            height: 38,
            fontsize: 24,
            dy: 9,
        },
    },
    updating: false,
    defaultValue: null,
    showMore: false,
    particularHeight: 0,
    convertTo: null,
    constQualitySleep: {
        1 : 'très mal dormi',
        4 : 'mal dormi',
        7 : 'bien dormi',
        10 : 'très bien dormi'
    },
    ie18nConstQualitySleep: {
        1 : t('très mal dormi', {ns: 'datas'}),
        4 : t('mal dormi', {ns: 'datas'}),
        7 : t('bien dormi', {ns: 'datas'}),
        10 : t('très bien dormi', {ns: 'datas'})
    },
    constSports: constants.custo.DASH_SPORTS.concat(sports.OTHERS_SPORTS.map((sport) => sport)),
    themeTracker: null,
    risedData: '',
    metrics: null,
    dynamicSportsSetting: null,
    toDisplayAsMean: ['shape', 'tiredness', 'sleep', 'stress', 'sport', 'followed-weight', 'waistsize'],
    toDisplayAsTotal: ['tobacco', 'alcool', 'activity', 'denivele'],
    defaultAxis: [],
};
