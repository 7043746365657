import { Cardline } from '@app/api';
import { isDefined, getQueryVariable } from '@app/core';
import { allRoutes } from '@app/settings';

import * as constants from '@app/constants';
import * as staticsconstants from '@app/constants/constants-statics';

export const actions = {
    onComponentCreate: (props) => (state, actions) => {
        let idtimelineIdCard = null;
        if ((props.match.params === undefined) || (props.match.params.shareid === undefined)) {
            idtimelineIdCard = getQueryVariable('id', false);
        } else {
            idtimelineIdCard = props.match.params.shareid;
        }
        console.log('idtimelineIdCard : ', idtimelineIdCard);
        if (!isDefined(idtimelineIdCard)) {
            console.log('idtimelineIdCard : RENDER FROM PREVIEW');
        } else {
            let lang = getQueryVariable('lang', false);
            if (lang === false) {
                lang = 'fr_FR';
            }
            // let previewDoc = getQueryVariable('doc', false);
            // if (isDefined(previewDoc)) {
            //     console.log('decodedDocument : ', previewDoc);
            //     let decodedDocument = JSON.parse(atob(previewDoc));
            //     console.log('decodedDocument : ', decodedDocument);
            //     actions.parseCardAndDisplayIt({card: decodedDocument, props: null});
            // } else {
            if (staticsconstants.CUSTOMERS[props.customer] !== undefined) {
                state.redirect.ios = staticsconstants.CUSTOMERS[props.customer].appurl.ios;
                state.redirect.android = staticsconstants.CUSTOMERS[props.customer].appurl.android;
            }

            Cardline.getSharedCard(idtimelineIdCard, lang).then((res) => {
                var card = res.data.card;
                actions.parseCardAndDisplayIt({card: card, props: props});
            });
            // }
        }
    },

    parseCardAndDisplayIt: ({card, props}) => (state, actions) => {
        if (!isDefined(card)) {
            return false;
        }
        var cardTheme = actions.getTheme(card.theme);

        if (cardTheme !== undefined) {
            card.valueTheme = cardTheme.value;
            card.labelTheme = cardTheme.label;
        }

        if (card.recipe) {
            var making = card.recipe.making, makingLabel = '',
                baking = card.recipe.baking, bakingLabel = '',
                rest = card.recipe.rest, restLabel = '';
            if (making) {
                makingLabel = '‘';
                if (making.indexOf('h') > -1) {
                    makingLabel = 'h';
                } else if (props.customer === 'maternite') {
                    makingLabel = 'min';
                }
                making = making.match(/\d/g).join('');
            }
            if (baking) {
                bakingLabel = '‘';
                if (baking.indexOf('h') > -1) {
                    bakingLabel = 'h';
                } else if (props.customer === 'maternite') {
                    bakingLabel = 'min';
                }
                baking = baking.match(/\d/g).join('');
            }
            if (rest) {
                restLabel = '‘';
                if (rest.indexOf('h') > -1) {
                    restLabel = 'h';
                } else if (props.customer === 'maternite') {
                    restLabel = 'min';
                }
                rest = rest.match(/\d/g).join('');
            }
            if ((rest === null) || (rest === undefined)) {
                rest = '';
            }
            if ((baking === null) || (baking === undefined)) {
                baking = '';
            }
            if ((making === null) || (making === undefined)) {
                making = '';
            }
            card.recipe.making = {'value' : making, 'unit' : makingLabel};
            card.recipe.baking = {'value' : baking, 'unit' : bakingLabel};
            card.recipe.rest = {'value' : rest, 'unit' : restLabel};
        }

        console.log('card : ', card);
        actions.setCard(card);
    },

    onComponentUpdate: (props) => (state, actions) => {
        actions.setGoToUrl(allRoutes['index'].pathname);
        if (props.customer) {
            actions.setGoToUrl(allRoutes['index'].pathname + '?customerssoname=' + props.customer);
            if (isDefined(staticsconstants.CUSTOMERS[props.customer])) {
                let redirectObj = staticsconstants.CUSTOMERS[props.customer].redirect;
                if (isDefined(redirectObj)) {
                    let sharedFrom = redirectObj.sharedFrom;
                    let gotoLabel = redirectObj.gotoLabel;
                    let gotoUrl = redirectObj.gotoUrl;
                    if (isDefined(sharedFrom) && (sharedFrom !== '')) {
                        actions.setSharedFrom(sharedFrom);
                    }
                    if (isDefined(gotoLabel) && (gotoLabel !== '')) {
                        actions.setGoToLabel(gotoLabel);
                    }
                    if (isDefined(gotoUrl) && (gotoUrl !== '')) {
                        actions.setGoToUrl(gotoUrl);
                    }
                }
            }
            // if (props.customer === 'partena') {
            //     actions.setSharedFrom('Partenamut Vitalité');
            //     actions.setGoToLabel('Partenamut');
            //     actions.setGoToUrl('http://www.partenamut.be/vitalite?utm_medium=email&utm_source=app_vitalite&utm_campaign=visit_partenamut-be');
            // } else if (props.customer === 'gjensidige') {
            //     actions.setSharedFrom('Iver');
            //     actions.setGoToLabel('Iver');
            // } else if (props.customer === 'cerba') {
            //     actions.setSharedFrom('Eliott');
            //     actions.setGoToLabel('Eliott');
            // } else if (props.customer === 'vyv') {
            //     actions.setSharedFrom('B.E by Vivoptim');
            //     actions.setGoToLabel('B.E by Vivoptim');
            // } else if (props.customer === 'maternite') {
            //     actions.setSharedFrom('l’application « Moi, Bientôt Maman »');
            // } else if (props.customer === 'klesia') {
            //     actions.setGoToLabel('TVB');
            //     actions.setSharedFrom('TVB');
            //     actions.setGoToUrl('https://www.carcept-prev.fr/le-programme-transportez-vous-bien');
            // }
        }
    },

    getTheme: (intTheme) => {
        for (var _t in constants.custo.THEMES) {
            if (constants.custo.THEMES[_t].key === intTheme) {
                return constants.custo.THEMES[_t];
            }
        }
    },

    setCard: newState => state => ({
        card: newState,
    }),

    setSharedFrom: newState => state => ({
        sharedFrom: newState,
    }),
    setGoToLabel: newState => state => ({
        goToLabel: newState,
    }),
    setGoToUrl: newState => state => ({
        goToUrl: newState,
    }),
};
