import { h } from '@app/utils';
import { getQueryVariable, getUser } from '@app/core';

module.exports = {
    initAction: function() {
        let action = {
            onComponentCreate: (props) => (state, actions) => {
                if (props.initial) {

                }
                let token = getQueryVariable('token', false);
                let refresh = getQueryVariable('refresh', false);
                if (!token) {
                    actions.setCguInitialized(true);
                } else {
                    localStorage.setItem('apitoken', token);
                    localStorage.setItem('refresh', refresh);

                    let cb = function() {
                        actions.setCguInitialized(true);
                    };
                    getUser(cb);
                }
            },

            setState: ({ ns, key, value }) => (state, actions) => ({
                [ns]: { ...state[ns], ...{ [key]: value } }
            }),

            setCguInitialized: newState => state => ({
                cguInitialized: newState,
            }),

            setAlfredVisibility: boolean => (state, actions) => actions.setState({
                ns: 'alfred',
                key: 'isVisible',
                value: boolean
            }),

            setCguAcceptability: boolean => (state, actions) => actions.setState({
                ns: 'cgu',
                key: 'isAccepted',
                value: boolean
            }),

            onRefusedTerms: () => (state, actions) => {
                actions.setAlfredVisibility(true);
                actions.setCguAcceptability(false);

                let tmtout = setTimeout(() => {
                    clearTimeout(tmtout);
                    tmtout = null;
                    actions.setAlfredVisibility(false);
                }, 3500);
            },

            onAcceptedTerms: () => (state, actions) => {
                actions.setAlfredVisibility(false);
                actions.setCguAcceptability(true);
            },

            goToNextStep: (props) => (state, actions) => {
                window.main.location.go('/home/register/pseudo');
            },

            displayErrorMessage: newState => state => ({
                messageActive: newState,
            }),

            updateParentProps: (props) => (state, actions) => {
                if (props) {
                    // props.send({
                    //     ns: 'cgu',
                    //     key: 'passed',
                    //     value: true
                    // })

                    props.send({
                        ns: 'user',
                        key: 'cgu',
                        value: state.cgu.isAccepted
                    })
                }
            },

            onStepSubmit: ({event, props}) => (state, actions) => {
                event.preventDefault();
                let token = localStorage.getItem('apitoken');
                if (!token || (token === 'false') || (token === null) || (token === undefined)) {
                    actions.displayErrorMessage(true);
                } else {
                    actions.goToNextStep(props);
                }
            },
        }
        return action;
    }
}
