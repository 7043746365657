import { Component } from '@app/utils';
import { state } from './state';
// import { actions } from './actions';
// import { view } from './view';

import './index.scss';

import { dynLoad } from '@app/core';
const actions = dynLoad('action', 'Public/password/reset/actions');
const view = dynLoad('view', 'Public/password/reset/views');

export { state, actions, view };

// used for lazy loading
export default Component(state, actions, view, 'publicresetpassword');
