import { appCustoSetting } from '@app/settings/settings';
import { queueQueries } from '@app/api/controlleur';
const { API_URL } = appCustoSetting.appsettings;

/**
 * Calls API to add a payment card of user
 * @see https://betterise-api-doc.betterise.me/#api-Payment-addPaymentCard
 * @return Response object
 */
async function addPaymentCard() {
    return queueQueries(`${API_URL}/payment/card/`, 'POST', 'application/json', true, true, true, false)
}

/**
 * Calls API to delete a payment card of user
 * @see https://betterise-api-doc.betterise.me/#api-Payment-deletePaymentCard
 * @return Response object
 */
async function deletePaymentCard(id) {
    return queueQueries(`${API_URL}/payment/${id}/card`, 'DELETE', 'application/json', true, true, true, false)
}

/**
 * Calls API to delete a payment card of user
 * @see https://betterise-api-doc.betterise.me/#api-Payment-deletePaymentCard
 * @return Response object
 */
async function redirectPaymentCard(data) {
    return queueQueries(`${API_URL}/stripe/redirect/`, 'POST', 'application/json', true, true, true, true, true, data)
}
/**
 * Calls API to retrieve payment informations of user
 * @see https://betterise-api-doc.betterise.me/#api-Payment-getPayment
 * @return Response object
 */
async function getPayment() {
    return queueQueries(`${API_URL}/payment/`, 'GET', 'application/json', true, true, true, false)
}

/**
 * Calls API to retrieve payment sources informations of user
 * @see https://betterise-api-doc.betterise.me/#api-Payment-getPaymentCards
 * @return Response object
 */
async function getPaymentCards() {
    return queueQueries(`${API_URL}/payment/cards/`, 'GET', 'application/json', true, true, true, false)
}

async function doFakePayment() {
    return queueQueries(`${API_URL}/hack-step-register/`, 'PUT')
}

export const Payment = {
    addPaymentCard,
    deletePaymentCard,
    getPayment,
    getPaymentCards,
    doFakePayment,
    redirectPaymentCard,
};
