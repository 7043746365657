import { t, h, Component } from '@app/utils';
import { Card, Modal } from '@app/elements';

import imgDefault from '@app/img/food/deco-blank.png';
import imgDefaultx2 from '@app/img/food/deco-blank@2x.png';

import imgTrash from '@app/img/ico/ico-trash.png';
import imgTrashx2 from '@app/img/ico/ico-trash@2x.png';

import icoFiche from '@app/img/ico/ico-fiche.png';
import icoFichex2 from '@app/img/ico/ico-fiche@2x.png';

import { ViewAlimentDetail } from './viewAlimentDetail';

const ListingEntry = Component(
    {
        idaliment: null,
        lunchAliment: null,
        listingKind: 'search',
        detailAliment: null,
        kindOfInfos: null,
        modeDisplayed: 'portion',
        alimentDetailDisplayed: false,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.lunchAliment) {
                actions.parseLunchAliment(props);
            } else {
                actions.setLunchAliment([]);
            }
            if (props.listingKind) {
                actions.setListingKind(props.listingKind);
            }
            if (props.id) {
                actions.setLunchId(props.id);
            }

            if (props.aliments) {
                props.aliments = props.aliments.filter((thing, index, self) =>
                    index === self.findIndex((t) => (
                        t.id === thing.id
                    ))
                );
            }
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (props.lunchAliment) {
                actions.parseLunchAliment(props);
            } else {
                actions.setLunchAliment([]);
            }
            if (props.listingKind) {
                actions.setListingKind(props.listingKind);
            }
            if (props.id) {
                actions.setLunchId(props.id);
            }

            if (props.aliments) {
                props.aliments = props.aliments.filter((thing, index, self) =>
                    index === self.findIndex((t) => (
                        t.id === thing.id
                    ))
                );
            }
        },
        parseLunchAliment: (props) => (state, actions) => {
            let lunchAliment = props.lunchAliment;
            if (lunchAliment) {
                // lunchAliment.alimentsCount = +props.lunchAliment.length;
                // lunchAliment.alimentsCount = (isNaN(+props.lunchAliment.count) ? 0 : +props.lunchAliment.count);
                actions.setLunchAliment(lunchAliment);
            }
        },
        setLunchId: newState => state => ({
            idaliment: newState,
        }),
        setLunchAliment: newState => state => ({
            lunchAliment: newState,
        }),
        setListingKind: newState => state => ({
            listingKind: newState,
        }),
        setDetailAliment: newState => state => ({
            detailAliment: newState,
        }),
        setKindOfInfos: newState => state => ({
            kindOfInfos: newState,
        }),
        setDisplayedMode: newState => state => ({
            modeDisplayed: newState,
        }),
        displayAlimentDetail: newState => state => ({
            alimentDetailDisplayed: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <div key={'aliment-' + props.alias} class='btzListing-btzListingEntry' data-list-kind={state.listingKind}>
            {state.listingKind === 'search' ?
                <div style={{ 'white-space': 'nowrap' }}>
                    <div class='btzListing-btzListingEntry-btzImage'>
                        <Card.Image classes='btzCard-btzImage-btzAskImage' onclick={() => { actions.displayAlimentDetail(true); }}>
                            {((props.media === null) || (props.media === undefined)) ?
                                <img src={imgDefault} srcset={`${imgDefaultx2} 2x`} alt='' class='' />
                                :
                                <img src={props.media.url} srcset={`${props.media.url} 2x`} alt='' class='' />
                            }
                            <img src={icoFiche} srcset={`${icoFichex2} 2x`} alt='' class='btzCard-btzImage-btzAskImage-btzFiche' />
                        </Card.Image>
                    </div>
                    <div class='btzListing-btzListingEntry-btzContent'>
                        <p class='btzPageContentTexte' style={{ 'font-size': '14px', 'line-height': '1' }}>{props.name}</p>
                        <p class='btzPageContentSubTexte'>{t('portion', {ns: 'food'}) + ' (' + props.portionLabel + ')'}</p>
                    </div>
                    <div class='btzListing-btzListingEntry-btzCounter' style={{ 'display': 'inline-block' }}>
                        <div class='btzListing-btzListingEntry-btzContent-btzLess btzUnselectable' onclick={() => props.removeOneAliment(props)}>-</div>
                        <div class='btzListing-btzListingEntry-btzContent-btzValue'>{(state.lunchAliment[0] !== undefined) ? state.lunchAliment[0].count : 0}</div>
                        <div class='btzListing-btzListingEntry-btzContent-btzMore btzUnselectable' onclick={() => props.addOneAliment(props)}>+</div>
                    </div>
                </div>
                :
                <div>
                    {state.listingKind === 'habits' ?
                        <div style={{ 'margin-right': '20px', 'white-space': 'nowrap' }}>
                            <div class='btzListing-btzListingEntry-btzContent btzHover-btzGreyRight' style={{ 'width': '85%' }} onclick={() => props.setHabitsToAddInLunch()}>
                                <p class='btzPageContentTexte' style={{ 'font-size': '14px', 'padding': '0px', 'line-height': '1' }}>{props.name}</p>
                                <div class='btzPageContentSubTexte' style={{ 'overflow': 'hidden visible', 'text-overflow': 'ellipsis', 'line-height': '0.8', 'white-space': 'nowrap', 'margin-top': '5px' }}>
                                    {props.aliments.map((aliment, idx) => {
                                        return aliment.name + ((idx + 1) < props.aliments.length ? ', ' : '');
                                    })}
                                </div>
                            </div>
                            <div class='btzDumbMarker' style={{ 'padding': '28px 0px 28px 40px', 'display': 'inline-block' }}>
                                <Modal content={''} confirm={true} confirmConfirm={() => {
                                    props.removeLunchFromHabit(props.id);
                                }} title={t('Suppression du repas habituel', {ns: 'food'})} description={t('Êtes-vous sûr(e) de vouloir supprimer le repas enregistré', {ns: 'food'}) + ' « ' + props.name + ' » ?'}>
                                    <img style={{ 'vertical-align': 'middle' }} src={imgTrash} srcset={`${imgTrashx2} 2x`} alt='' class='' />
                                </Modal>
                            </div>
                        </div>
                        :
                        <div style={{ 'white-space': 'nowrap' }}>
                            <div class='btzListing-btzListingEntry-btzImage'>
                                <Card.Image classes='btzCard-btzImage-btzAskImage' onclick={() => { actions.displayAlimentDetail(true); }}>
                                    {((props.media === null) || (props.media === undefined)) ?
                                        <img src={imgDefault} srcset={`${imgDefaultx2} 2x`} alt='' class='' />
                                        :
                                        <img src={props.media.url} srcset={`${props.media.url} 2x`} alt='' class='' />
                                    }
                                    <img src={icoFiche} srcset={`${icoFichex2} 2x`} alt='' class='btzCard-btzImage-btzAskImage-btzFiche' />
                                </Card.Image>
                            </div>
                            <div class='btzListing-btzListingEntry-btzContent'>
                                <p class='btzPageContentTexte' style={{ 'font-size': '14px', 'line-height': '1' }}>{props.name}</p>
                                {props.composition === 'fat' &&
                                    <p class='btzPageContentSubTexte'>{props.Lipides + '% ' + t('de lipides et', {ns: 'food'}) + ' ' + props.AGSatures + '% ' + t('d‘AG saturés', {ns: 'food'})}</p>
                                }
                                {props.composition === 'sugar' &&
                                    <p class='btzPageContentSubTexte'>{props.Sucres + 'g ' + t('de sucre pour 100g', {ns: 'food'})}</p>
                                }
                                {props.composition === 'salt' &&
                                    <p class='btzPageContentSubTexte'>{props.Sel + 'g ' + t('de sel pour 100g', {ns: 'food'})}</p>
                                }
                                {props.composition === 'alcool' &&
                                    <p class='btzPageContentSubTexte'>{props.Alcool + 'g ' + t('d‘alcool pour 100g', {ns: 'food'})}</p>
                                }
                            </div>
                        </div>
                    }
                </div>
            }
            {state.alimentDetailDisplayed === true &&
                <div class='btzLunch-btzAliment-btzDetail-btzWrapper'>
                    <ViewAlimentDetail {...state} displayAlimentDetail={actions.displayAlimentDetail} setKindOfInfos={actions.setKindOfInfos} setDetailAliment={actions.setDetailAliment} lunchAliment={state.lunchAliment[0]} addOneAliment={() => {props.addOneAliment(props)}} setDisplayedMode={actions.setDisplayedMode} modeDisplayed={state.modeDisplayed} showAddButton={props.showAddButton || false}></ViewAlimentDetail>
                </div>
            }
        </div>
    ),
    'addmeallistingentry'
);

export { ListingEntry };
