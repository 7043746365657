import { t, h } from '@app/utils';
import { Form } from '@app/elements';

import logoCvc from '@app/img/register/areyou-cb-crypto.png';
import logoCvcx2 from '@app/img/register/areyou-cb-crypto@2x.png';

export const StripeID = (process.env.APP_STRIPE_KEY || 'pk_test_0OWZHmcnp8lbNu5SvdBb5ril');
export const StripeWrapper = (props, actions) => (state, actions) => {
// export const StripeWrapper = (props, children) => (state, actions) => {
    // eslint-disable-next-line

    let elements = null;
    if (state.stripe === undefined) {
        elements = props.stripe.elements();
    } else {
        elements = state.stripe.elements();
    }

    if (props.customActions) {
        actions = props.customActions;
    }
    // existingdata

    const style = {
        base: {
            // fontFamily: ''avenir-black'',
            fontSize: '30px',
            fontWeight: '700',
            color: '#989898',
            '::placeholder': {
                color: '#c2c2c1'
            },
        },
        invalid: {
            color: '#989898',
            iconColor: '#989898',
        }
    }

    var cardNumber = null, cardExpiry = null, cardCvc = null;
    const onElementCardNumberCreate = element => {
        cardNumber = elements.create('cardNumber', {
            style,
            placeholder: t('Votre numéro de carte', {ns: 'cb'})
        });
        cardNumber.mount('#' + element.id);
        cardNumber.addEventListener('change', (event) => onChange(event));
        actions.setStripeElementCardNumber(cardNumber);
    }
    const onElementCardNumberDestroy = () => {
        if (cardNumber !== null) {
            cardNumber.destroy();
        }
    }
    const onElementCardNumberBlur = () => {
        actions.resetApiError();
    }

    const onElementCardExpiryCreate = element => {
        cardExpiry = elements.create('cardExpiry', {
            style,
            placeholder: t('Date d‘expiration (MM/AA)', {ns: 'cb'})
        });
        cardExpiry.mount('#' + element.id);
        cardExpiry.addEventListener('change', (event) => onChange(event));
        actions.setStripeElementCardExpiry(cardExpiry);
    }
    const onElementCardExpiryDestroy = () => {
        if (cardExpiry !== null) {
            cardExpiry.destroy();
        }
    }

    const onElementCardCvcCreate = element => {
        cardCvc = elements.create('cardCvc', {
            style,
            placeholder: t('Clé', {ns: 'cb'})
        });
        cardCvc.mount('#' + element.id);
        cardCvc.addEventListener('change', (event) => onChange(event));
        actions.setStripeElementCardCvc(cardCvc);
    }
    const onElementCardCvcDestroy = () => {
        if (cardCvc !== null) {
            cardCvc.destroy();
        }
    }

    let stupideTimeout = null;

    const onChange = (event) => {
        if (actions.resetApiError) {
            actions.resetApiError();
        }
        if (event.complete && event.token) {
            alert(event.token);
        }

        if (state.allFieldsAreValid || props.allFieldsAreValid) {
            actions.setAllFieldsAreValid(false);
        }

        stupideTimeout = setTimeout(function() {
            clearTimeout(stupideTimeout);
            stupideTimeout = null;
            if (cardNumber._complete && cardExpiry._complete && cardCvc._complete) {
                actions.setAllFieldsAreValid(true);
            }
        }, 500);
    }

    return (
        <Form.Group id='stripe-payment-form'>
            <div id='cardnumber' onfocus={(el) => onElementCardNumberBlur(el)} oncreate={(el) => onElementCardNumberCreate(el)} ondestroy={(el) => onElementCardNumberDestroy(el)} />
            <hr />
            <div id='cardexpiry' onfocus={(el) => onElementCardNumberBlur(el)} oncreate={(el) => onElementCardExpiryCreate(el)} ondestroy={(el) => onElementCardExpiryDestroy(el)} />
            <hr />
            <div id='cardcvc' onfocus={(el) => onElementCardNumberBlur(el)} oncreate={(el) => onElementCardCvcCreate(el)} ondestroy={(el) => onElementCardCvcDestroy(el)} />
            <img src={logoCvc} srcset={logoCvcx2} class='cvc' />
        </Form.Group>
    )
}