import { isDefined } from './isDefined';

const isTestMode = function() {
    var currentUrl = window.location.href;
    var publicRoute = /localhost:80/;
    if (isDefined(currentUrl.match(publicRoute))) {
        return true;
    }
    return false;
};

export { isTestMode };