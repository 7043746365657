import { getClosest, getUser, isDefined } from '@app/core';
import { t, h, Component, debounce, isEmpty } from '@app/utils';
import { Data, User } from '@app/api';
import { Tile, Balloon, Form, Button, Avatar, CustomInput } from '@app/elements';
import * as constants from '@app/constants';
import * as sports from '@app/sports';
import './index.scss';

import icoValidQCM from '@app/img/ico/ico-valid-white.png';
import icoValidQCMx2 from '@app/img/ico/ico-valid-white@2x.png';

const SportForm = Component(
    {
        questionStep: 0,
        question: ['Quel sport avez-vous pratiqué ?', 'Combien de temps avez-vous pratiqué l’activité' + ' « [activity] » ?'],
        i18nquestion: [t('Quel sport avez-vous pratiqué ?', {ns: 'datas'}), t('Combien de temps avez-vous pratiqué l’activité', {ns: 'datas'}) + ' « [activity] » ?'],
        form: {
            type: 'qcm',
            tag: 'ACTIVITY_',
            values: constants.custo.DASH_SPORTS,
            label: ['', '']
        },
        source: 'betterise',
        date: null,
        readableDate: null,
        help: ['Plusieurs réponses possibles.', 'Saisissez la durée de l’activité.', 'Renseignez les premiers caractères  et choisissez dans la liste.'],
        i18nhelp: [t('Plusieurs réponses possibles.', {ns: 'datas'}), t('Saisissez la durée de l’activité.', {ns: 'datas'}), t('Renseignez les premiers caractères  et choisissez dans la liste.', {ns: 'datas'})],
        error: '',
        activeValidation: false,
        formIsSubmitting: false,
        values: [],
        defaultValue: [{h: 0, min: 0}],
        selectedTag: null,
        selectedLabel: null,
        dynSport: false,
        sports: sports.OTHERS_SPORTS,
        filteredSports: [],
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.date) {
                actions.setReadableDate(actions.getDate(props.date));
                actions.setDate(actions.createDateObj(props.date));
            }
            if (props.defaultValue) {
                let newValues = [...state.defaultValue, ...props.defaultValue];
                actions.setDefaultValue(newValues);
            }
        },
        onComponentUpdate: (props) => (state) => {

        },
        switchValidationActivation: () => state => ({
            activeValidation: !state.activeValidation,
        }),
        selectThisAnswer: (el) => (state, actions) => {
            actions.setError('');

            let dataTag = '';
            if (el.datatag && (el.datatag !== undefined) && (el.datatag !== null)) {
                actions.setSelectedTag(el.datatag);
                actions.setSelectedLabel(el.readable);
                dataTag = el.datatag;
            } else {
                var els = getClosest(el.target, '.btzOnboarding-btzButtonsWrapper').querySelectorAll('.btzOnBoarding-btzButton');
                for (var elem in els) {
                    if (els[elem] && (els[elem].classList !== undefined)) {
                        els[elem].classList.remove('active');
                        els[elem].classList.remove('btzCurrentEvaluationMarker');
                    }
                }
                dataTag = el.target.getAttribute('data-tag');

                el.target.classList.add('active');
                el.target.classList.add('btzCurrentEvaluationMarker');

                actions.setSelectedTag(el.target.getAttribute('data-tag'));
                actions.setSelectedLabel(el.target.getAttribute('data-label'));
            }

            // return if trying to add 6th sport, and that sport is not an edition of already added sport
            if ((state.defaultValue.length >= 6) && (state.defaultValue.filter((f) => f.kpi === dataTag)[0] === undefined)) {
                actions.setError(t('Le nombre de sport par jour est limité à', {ns: 'datas'}) + ' 5.');
                return;
            }

            for (var dvalue in state.defaultValue) {
                if (isDefined(state.defaultValue[dvalue]) && (state.defaultValue[dvalue].kpi === dataTag)) {
                    var sportValue = state.defaultValue[dvalue].value;
                    actions.setDefaultValue([{
                        'h' : Math.floor(sportValue / 60),
                        'min' : Math.round(sportValue % 60)
                    }]);
                }
            }

            if (!state.activeValidation) {
                actions.switchValidationActivation();
            }
            if (dataTag.toLowerCase() === 'other') {
                actions.setDynSport(true);
            }
        },
        sendData: (props) => (state, actions) => {
            let callback = function(res) {
                if (state.formIsSubmitting) {
                    return false;
                }

                let sportHours = (document.querySelectorAll('input[name="sport-hours-custom"]')[0].value || 0);
                let sportMinutes = (document.querySelectorAll('input[name="sport-minutes-custom"]')[0].value || 0);

                let sportValue = (+sportHours * 60) + +sportMinutes;
                if (+sportValue === 0) {
                    actions.setError(t('Veuillez renseigner la durée de votre séance de sport.', {ns: 'datas'}));
                    return false;
                }

                let totalTime = +sportValue;
                for (var sprt in state.defaultValue) {
                    if (state.defaultValue[sprt].kpi !== state.selectedTag) {
                        if (state.defaultValue[sprt].value !== null) {
                            totalTime += +state.defaultValue[sprt].value;
                        }
                    }
                }

                state.values = [{tag: state.selectedTag, value: sportValue, date: state.date, source: state.source}];

                let settingFound = false;
                let tag = '';
                let name = 0;
                if (state.dynSport) {
                    tag = state.values[0].tag;
                    for (var setting in res.settings) {
                        if (res.settings[setting].name.indexOf('SPORT_') > -1) {
                            name++;
                        }
                        if (res.settings[setting].value === tag) {
                            settingFound = true;
                        }
                    }
                    if (!settingFound && (name >= 15)) {
                        actions.setError(t('Le nombre maximum de sports est atteint.', {ns: 'datas'}));
                        return;
                    }
                    name = 'SPORT_' + name;
                }

                if (totalTime >= 720) {
                    actions.setError(t('Le nombre d‘heure de sport par jour est limité à 12H.', {ns: 'datas'}));
                    return;
                }

                actions.setFormIsSubmitting(true);
                Data.postData(state.values).then(function() {
                    if (state.dynSport) {
                        if (!settingFound) {// avoid duplicate in user settings
                            let settings = [
                                {name: name, value: tag}
                            ];
                            User.setUserSettings(settings).then(() => {
                                let eventbis = new CustomEvent('updateUserSettings', {detail: {newSettings: {name: name, value: tag}}});
                                window.dispatchEvent(eventbis);
                                actions.updateDataVizEvent(props);
                            });
                        } else {
                            actions.updateDataVizEvent(props);
                        }
                    } else {
                        actions.updateDataVizEvent(props);
                    }
                });
            }
            if (state.dynSport) {
                getUser(callback, true);
            } else {
                callback(null);
            }
        },
        updateDataVizEvent: (props) => (state, actions) => {
            actions.setDynSport(false);
            actions.setFormIsSubmitting(false);
            props.cancelAction();
            props.updateDataViz(props);
        },
        setReadableDate: newState => state => ({
            readableDate: newState
        }),
        setDate: newState => state => ({
            date: newState
        }),
        updateDefaultValue: (newValue) => (state, actions) => {
            if (state.error !== '') {
                actions.setError('');
            }
            if (newValue[0].h === null) {
                newValue[0].h = state.defaultValue[0].h;
            } else if (newValue[0].min === null) {
                newValue[0].min = state.defaultValue[0].min;
            }
            actions.setDefaultValue(newValue);
        },
        setDefaultValue: newState => state => ({
            defaultValue: newState
        }),
        createDateObj: (date) => (state, actions) => {
            var parsedDate = {
                date: new Date(date.substr(0, 4) + '-' + date.substr(4, 2) + '-' + date.substr(6, 2)),
                timezone: 'UTC',
                timezone_type: 3
            }

            return parsedDate;
        },
        getDate: (dat) => {
            var date = new Date(Date.parse(new Date(dat.substr(0, 4) + '-' + dat.substr(4, 2) + '-' + dat.substr(6, 2))));
            var options = { month: 'long', day: 'numeric', year: 'numeric' };
            return date.toLocaleDateString('fr-FR', options);
        },
        updateQuestionStep: newState => state => ({
            questionStep: newState,
        }),
        setDynSport: newState => state => ({
            dynSport: newState,
        }),
        setSports: newState => state => ({
            sports: newState,
        }),
        setFilteredSports: newState => state => ({
            filteredSports: newState,
        }),
        setSelectedTag: newState => state => ({
            selectedTag: newState,
        }),
        setSelectedLabel: newState => state => ({
            selectedLabel: newState,
        }),
        setFormIsSubmitting: newState => state => ({
            formIsSubmitting: newState,
        }),
        setError: newState => state => ({
            error: newState,
        }),

        onOtherSportInputUpdate: string => (state, actions) => {
            if (isEmpty(string)) return false

            let sports = state.sports;
            let filteredSports = sports.filter((sport) => sport.readable.toLowerCase().indexOf(string.toLowerCase()) > -1);

            actions.setFilteredSports(filteredSports);
        },

        onOtherSportInputDebouncedUpdate: (string) => (state, actions) => debounce(
            600,
            'onOtherSportInputUpdate',
            string
        ),

        onOtherSportListClick: sport => (state, actions) => {
            actions.selectThisAnswer(sport);
            actions.updateQuestionStep(1);
        },
    },
    (state, actions) => (props, children) => (
        <div class='btzDashboard-btzDashboardForm' id={props.id} idtimeline={props.idtimeline} idfavorite={props.idfavorite} data-id={props.did} data-tag={props.tag} data-value={state.count} data-kind={state.kind}>
            <div style={{ 'position': 'relative', 'height': '100%', 'display': 'table', 'width': '100%' }}>
                <Tile style={{ 'padding-bottom': '100px', 'overflow': 'hidden' }}>
                    <div class='btzTitle-btzImage btzTitle-btzImage-absolute'>
                        <p class='btzSubTitle'>{state.readableDate}</p>
                        <p class='btzTitle btzXl'>{t(state.question[state.questionStep]).replace('[activity]', state.selectedLabel)}</p>
                    </div>
                    {state.questionStep === 0 &&
                        <div>
                            {state.dynSport === false ?
                                <Tile.Content classes='btzLeftHrArrowedOnboarding' style={{ 'text-align': 'center' }}>
                                    {state.form && (
                                        <div>
                                            {state.form.type == 'qcm' && (
                                                <div class='btzOnboarding-btzButtonsWrapper btzOnboarding-btzButtonsWrapper-qcm'>
                                                    {state.form.values.map(item =>
                                                        <div class='col-4 col-md-12'>
                                                            <div onclick={actions.selectThisAnswer} data-id={'single'} data-label={t(item.readable)} data-tag={item.kpi} data-kind='qcm' data-value={item.kpi} class='btzOnBoarding-btzButton btzOnBoarding-btzQcm-btzLabel'>
                                                                <div class='btzOnboarding-btzButtonsWrapper-QcmPuce' data-theme={'sport'}>
                                                                    <img src={icoValidQCM} srcset={`${icoValidQCMx2} 2x`} alt='' />
                                                                </div>
                                                                <p>{t(item.readable)}</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </Tile.Content>
                                :
                                <Form>
                                    <Form.Group style={{ 'white-space': 'nowrap' }}>
                                        <Form.Input
                                            placeholder={t('Votre sport', {ns: 'datas'})}
                                            type='text'
                                            id='other'
                                            name='other'
                                            // value={state.mutual.value}
                                            oninput={(event) => {
                                                // actions.setOtherSport(event.target.value);
                                                actions.onOtherSportInputDebouncedUpdate(event.target.value);
                                            }}
                                        />
                                        {state.filteredSports && (
                                            <ul name='othersport'>
                                                {state.filteredSports.map(sport => (
                                                    <button key={sport.datatag} data-tag={sport.datatag}
                                                        onclick={() => actions.onOtherSportListClick(sport)}>{sport.readable}</button>
                                                ))}
                                            </ul>
                                        )}
                                    </Form.Group>
                                </Form>
                            }
                        </div>
                    }

                    {state.questionStep === 1 &&
                        <Tile.Content classes='btzLeftHrArrowedOnboarding' style={{ 'text-align': 'center' }}>
                            {state.form && (
                                <div>
                                    <div class='col-6 col-xs-6' style={{ 'max-width': '120px' }}>
                                        <CustomInput key={'sport-hours'} uniqName={'sport-hours'} uniqLabel={t('h', {ns: 'datas'})} uniqMaxLength={2} uniqDefaultValue={state.defaultValue[0].h} oninput={(el) => {actions.updateDefaultValue([{h: +el.target.value, min: null}])}} onclick={(el) => {actions.updateDefaultValue([{h: (+el.target.value === 0 ? '' : +el.target.value), min: null}])}} uniqClass={'btzCurrentEvaluationMarker'}></CustomInput>
                                    </div>
                                    <div class='col-6 col-xs-6' style={{ 'max-width': '120px' }}>
                                        <CustomInput key={'sport-minutes'} uniqName={'sport-minutes'} uniqLabel={t('min', {ns: 'datas'})} uniqMaxLength={2} uniqDefaultValue={state.defaultValue[0].min} oninput={(el) => {actions.updateDefaultValue([{h: null, min: +el.target.value}])}} onclick={(el) => {actions.updateDefaultValue([{h: null, min: (+el.target.value === 0 ? '' : +el.target.value)}])}} uniqClass={'btzCurrentEvaluationMarker'}></CustomInput>
                                    </div>
                                </div>
                            )}
                        </Tile.Content>
                    }
                </Tile>

                <Form onsubmit={() => null} style={{ 'min-height': 0 }}>
                    <Form.Group classes='btzForm-btzFooter'>
                        <Button primary small loading={(state.formIsSubmitting)} onclick={() => {
                            if (!state.formIsSubmitting) {
                                actions.setDynSport(false);
                                props.cancelAction();
                            }
                        }} >
                            {t('Annuler', {ns: 'generals'})}
                        </Button>

                        <Button primary active={(state.activeValidation && !state.formIsSubmitting)} loading={(state.formIsSubmitting)} onclick={() => {
                            if (state.activeValidation) {
                                if (state.questionStep === 0) {
                                    if (!state.dynSport) {
                                        actions.updateQuestionStep(1);
                                    }
                                } else {
                                    actions.sendData(props);
                                }
                            } else {
                                actions.setError(t('Veuillez remplir le formulaire.', {ns: 'generals'}));
                            }
                        }} >
                            {t('Valider', {ns: 'generals'})}
                        </Button>
                    </Form.Group>
                </Form>

                <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap', 'margin-top': '50px' }}>
                    <Tile.Icon classes={''}>
                        <Avatar.Alfred classes={'centered'} />
                    </Tile.Icon>
                    <Tile.Content>
                        <Balloon kind={state.error !== '' ? 'error' : 'info'} title={t('QUESTION', {ns: 'datas'}) + ' - ' + (state.questionStep + 1) + '/' + state.question.length} style={{ 'white-space': 'normal' }}>{(state.error !== '' ? state.error : t(state.help[state.questionStep]))}</Balloon>
                    </Tile.Content>
                </Tile>
            </div>
        </div>
    ),
    'sportform'
);

export { SportForm };
