import { t, h } from '@app/utils';
import { Button, Tile, Card, Avatar, Balloon } from '@app/elements';

import Objectif from './objectif';

export const ModalView = (props, actions) => (
    <Card kind={'objectif'}>
        <Card.Header classes={'btzCard-btzHeader-btzModal'}>
            <p class='btzTitle btzXl'>{t('Quels objectifs santé souhaitez-vous suivre ?', {ns: 'public'})}</p>
            <div class='btzObjectives-btzObjectivesModalWrapper'>
                {props.objectives.map(item =>
                    <Objectif key={'obj-' + item.id} {...item} chooseObjectif={props.chooseObjectif} displayedon={'modal'}></Objectif>
                )}
            </div>

            <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap', 'margin-top': '50px' }}>
                <Tile.Icon classes={''}>
                    <Avatar.Alfred classes={'centered'} />
                </Tile.Icon>
                <Tile.Content>
                    <Balloon kind={(props.error !== '' ? 'error' : 'info')} style={{ 'white-space': 'normal' }} actionAfterError={props.updateError}>{(props.error !== '' ? props.error : t(props.info))}</Balloon>
                </Tile.Content>
            </Tile>

            <Button primary active flat onclick={(event) => {
                props.confirmObjectifRules({ev: event, props: props})
            }}>
                {t('Valider', {ns: 'generals'})}
            </Button>
        </Card.Header>
    </Card>
);