module.exports = {
    initAction: function() {
        let action = {
            setState: ({ ns, key, value }) => state => ({
                [ns]: { ...state[ns], ...{ [key]: value } },
            })
        }
        return action;
    }
}
