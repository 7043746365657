import { t, h } from '@app/utils';
import { Card } from '@app/elements';

import icoAskWhite from '@app/img/ico/ico-ask-white.png';
import icoAskWhitex2 from '@app/img/ico/ico-ask-white@2x.png';

import bgStarsx2 from '@app/img/deco/deco-stars-quizz@2x.png';

export const ModalView = (props, actions) => (
    <Card kind={'reward'}>
        <Card.Header classes={'btzCard-btzHeader-btzModal'}>
            {props.media !== undefined &&
                <div>
                    {props.done &&
                        <div class='btzLastBadges' style={{ 'background-image': 'url(' + bgStarsx2 + ')' }}>
                            <Card.Image><img src={props.media.url} alt='' class='' /></Card.Image>
                        </div>
                    }
                    {(props.done === false) &&
                        <dummy>
                            {(props.mediaOff === null) ?
                                <Card.Image style={{ 'margin': '0 auto 25px' }}><img src={props.media.url} alt='' class='' />
                                    <Card.Image classes={'btzRewards-btzReward-btzNotDone'}><img src={icoAskWhite} srcset={`${icoAskWhitex2} 2x`} alt='' class='' /></Card.Image>
                                </Card.Image>
                                :
                                <Card.Image tyle={{ 'margin': '0 auto 25px' }} classes={'no-radius'}>{props.mediaOff !== null && <img src={props.mediaOff.url} alt='' class='' />}</Card.Image>
                            }
                        </dummy>
                    }
                </div>
            }
            <p class='btzTimeline-btzCard-btzTitle btzAboveTitle'>
                {props.done === false ? t('Badge à gagner :', {ns: 'rewards'}) : t('Badge gagné :', {ns: 'rewards'})}
            </p>
            <p class='btzTimeline-btzCard-btzTitle btzTitle btzXl' style={{ 'font-size': '16px', 'margin': '5px 0 15px' }}>{props.title}</p>
            <p class='btzTimeline-btzCard-btzDescription btzPageContentTitleBlue' style={{ 'color': 'black' }}>
                {props.done ?
                    <span>{props.successMessage}</span>
                    :
                    <span>{props.description}</span>
                }
            </p>

            <p class='btzAboveSubTitle' style={{ 'margin-top': '25px' }}>{props.category.toUpperCase()}</p>
        </Card.Header>
    </Card>
);