import { h, t } from '@app/utils';
import { Card } from '@app/elements';

import icoInfo from '@app/img/ico/ico-close-black-big.png';
import icoInfox2 from '@app/img/ico/ico-close-black-big@2x.png';

import decoGlyLoad from '@app/img/food/deco-glycemic-load.png';
import decoGlyLoadx2 from '@app/img/food/deco-glycemic-load@2x.png';

export const ViewGlycemicLoad = (props, actions) => (
    <Card classes={'btzDetail-btzDetailFakeModal btzDetail-btzMainInformationModal'}>
        <Card.Header classes={'btzCard-btzHeader-btzModal'} style={{ 'margin-top': '100px' }}>
            <div class='btzModal-btzPaddingWrapper'>
                <img src={icoInfo} srcset={`${icoInfox2} 2x`} alt='' style={{ 'cursor': 'pointer' }} class='' onclick={props.closeView} style={{ 'margin': '0 0 30px' }} />

                <p class='btzTitle btzXl'>{t('Qu’est-ce que la charge glycémique ?', {ns: 'food'})}</p>

                <div class='btzDetailFake' style={{ 'padding-bottom': '50px' }}>
                    <p class='col-12 btzPageContentTitleBlue p-0' style={{ 'color': 'black' }}>
                        {t('La charge glycémique (CG) ne doit pas être confondue avec l’index glycémique (IG). L’IG indique la vitesse à laquelle le glucose d’un aliment se retrouve dans le sang une fois consommé, mais ne tient pas compte de la quantité de glucose de cet aliment.', {ns: 'food'})}
                        <br/><br/>
                        {t('La CG permet d’établir un rapport entre l’IG d’un aliment et la quantité ingérée. On l’obtient par le calcul suivant :', {ns: 'food'})}
                        <br/>
                        {t('CG = [IG x quantité de glucides d’une portion d’aliment (g)]/100 ', {ns: 'food'})}
                    </p>
                </div>
                <img src={decoGlyLoad} srcset={`${decoGlyLoadx2} 2x`} alt='' class='' style={{ 'margin': '0' }} />
                <div class='btzDetailFake' style={{ 'padding-top': '50px' }}>
                    <p class='col-12 btzPageContentTitleBlue p-0' style={{ 'color': 'black' }}>
                        {t('Betterise vous indique si la CG de la recette est faible (jauge verte), modérée (jauge orange) ou élevée (jauge rouge).', {ns: 'food'})}
                    </p>
                </div>
            </div>
        </Card.Header>
    </Card>
);