import { t, h } from '@app/utils';
import { Avatar, Balloon, Button, Form, Tile, Switch } from '@app/elements';

import logoStep from '@app/img/register/areyou-cb.png';
import logoStepx2 from '@app/img/register/areyou-cb@2x.png';

import './index.scss';
import { StripeWrapper } from './stripeWrapper';

export const view = (state, actions) => (props, children) => (
    <div style={{ position: 'relative' }}>
        <Tile>
            <div class='btzTitle-btzImage col-xs-12 col-4'>
                <img src={logoStep} srcset={`${logoStepx2} 2x`} alt='' class='' />
            </div>
            <Tile.Content classes='btzLeftHrArrowed col-xs-12 col-8'>
                <p class='btzTitle btzXl'>{t('Renseignez vos', {ns: 'cb'})}<br />{t('coordonnées bancaires.', {ns: 'cb'})}</p>
                <Form onsubmit={event => event.preventDefault()}>

                    <div key={'nochange'}>
                        <StripeWrapper />
                    </div>

                    <Form.Group classes='btzForm-btzFooter'>
                        <Button primary block active={state.allFieldsAreValid === true} onclick={(event) => {
                            actions.onStepSubmit({event: event, props: props});
                        }}>
                            {t('Valider', {ns: 'generals'})}
                        </Button>
                    </Form.Group>
                </Form>

                <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap' }}>
                    <Tile.Icon>
                        <Avatar.Alfred kind={state.apiErrorsStatus} classes={'centered'} />
                    </Tile.Icon>
                    <Tile.Content>
                        {
                            state.apiErrorsStatus === 'info' ? (
                                <Balloon kind={state.apiErrorsStatus} style={{ 'white-space': 'normal' }}>
                                    <p style={{ 'text-align': 'left' }}>
                                        <span class='btzBaloon-btzTitle'>{t('INSCRIPTION', {ns: 'register'})}</span>
                                        <span> - 4/4</span>
                                        <font>
                                            <br />{state.apiErrors}
                                        </font>
                                    </p>
                                </Balloon>
                            ) : (
                                <Balloon kind={state.apiErrorsStatus} style={{ 'white-space': 'normal' }}>
                                    {state.apiErrors}
                                </Balloon>
                            )
                        }
                    </Tile.Content>
                </Tile>
            </Tile.Content>
        </Tile>
    </div>
);
