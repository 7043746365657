import { addCSRFToken, isDefined } from '@app/core';
import { allRoutes } from '@app/settings';
import { t, h } from '@app/utils';
import { Button, Card, DatePicker } from '@app/elements';

import * as staticsconstants from '@app/constants/constants-statics';

import './../index.scss';

import PageLayout from '@app/layouts/datas/layout';
import Dashboard from './../dashboard';

import bgNoTrackersx2 from '@app/img/trackers/bg-no-trackers@2x.png';

import icoClose from '@app/img/ico/ico-close-black.png';
import icoClosex2 from '@app/img/ico/ico-close-black@2x.png';

module.exports = {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <PageLayout page='datas' readablePageLabel={t(staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()].menuNames.dashboard.title)} readablePageDescription={t('Toutes vos données sont analysées ici, pour mieux suivre et ajuster vos comportements.', {ns: 'datas'})} desabledDash={state.desabledDash} toggleDash={actions.onToggleDash} userMedia={state.userMedia} customer={props.customer}>
                <Card classes={''} kind={'datas'}>
                    <Card.Body data-displayed-kind={state.filter} style={{ 'padding-top': '0px' }}>
                        {state.refDate !== null &&
                            <DatePicker date={state.refDate} periodAction={actions.updatePeriod} selectorAction={actions.updateSelector} activeTopSticked={true}></DatePicker>
                        }
                    </Card.Body>
                </Card>

                {(isDefined(state.userTracker) && (state.userTracker.length == 0) && (state.informationDisplayed === true)) &&
                    <Card classes={'btzPageCard btzBackgroundCover'} kind={'datas'} style={{ 'background-image': 'url(' + bgNoTrackersx2 + ')' }}>
                        <Card.Header classes={'btzCard-btzHeader-btzPage'}>
                            <img class='btzImg-btzClose' src={icoClose} srcset={`${icoClosex2} 2x`} alt='' onclick={actions.removeInformation} />
                            <div class='col-12'>
                                <p class='btzTimeline-btzCard-btzTitle btzPageContentTitle'>{t('Le saviez-vous ?', {ns: 'datas'})}</p>
                                <div class='col-12 p-0'>
                                    <p class='col-6 col-sm-12 btzTimeline-btzCard-btzSubTitle btzPageContentSubTitle p-0'>{t('Vous pouvez relier un objet connecté à votre compte pour remonter certaines données automatiquement.', {ns: 'datas'})}</p>
                                    <p class='col-6 col-sm-12'></p>
                                </div>
                                <Button primary active small style={{ 'margin': '30px 0' }} onclick={() => {
                                    window.location.href = addCSRFToken(allRoutes['account.oc'].pathname);
                                }}>
                                    {t('Connecter un objet', {ns: 'datas'})}
                                </Button>
                                <Button.SwitchCheckBox onclick={(event) => { actions.noMoreInformation(event); }} label={{on: t('Ok !', {ns: 'datas'}), off: t('ne plus m’afficher ce message', {ns: 'datas'})}}></Button.SwitchCheckBox>
                            </div>
                        </Card.Header>
                    </Card>
                }

                {state.data.interval !== null &&
                    <div class={'dashboard-' + state.mode}>
                        <Dashboard customer={props.customer} locales={state.locales} datarised={state.dataRising.filter(drise => drise.theme === '5')} displayed={state.desabledDash.indexOf('5') > -1 ? false : true} nShowMore={1} userTracker={state.userTracker} key='dash-moral' kind='shape' queryKind='moral' readableKind='moral' keyprimary={['MORAL_LEVEL']} mode={state.mode} data={state.data.interval} today={state.today} defaultSelected={state.refDate} ydomain={10} convertTo={'points'} extrmConvertTo={'/10'} convertToMetric={'osef'} convertFrom={'osef'} type={'linechart'} defaultAxis={[0, 5, 10]}></Dashboard>
                        <Dashboard customer={props.customer} locales={state.locales} datarised={state.dataRising.filter(drise => drise.theme === '2')} displayed={state.desabledDash.indexOf('2') > -1 ? false : true} nShowMore={2} userTracker={state.userTracker} key='dash-activity' kind='activity' queryKind='activity' readableKind='marche' keyprimary={['ACTIVITY_WALK']} mode={state.mode} data={state.data.interval} today={state.today} defaultSelected={state.refDate} ydomain={10000} convertTo={'pas'} extrmConvertTo={'pas'} convertToMetric={'osef'} convertFrom={'osef'} type={'linechart'} ydomain={'dyn'} ydomainMax={'dyn'} extremAndMeanConfig={{min: true, max: true, mean: false, total: true, weight: false, imc: false}} defaultAxis={[0, 3000, 6000]}></Dashboard>
                        <Dashboard customer={props.customer} locales={state.locales} datarised={state.dataRising.filter(drise => drise.theme === '19')} displayed={state.desabledDash.indexOf('19') > -1 ? false : true} nShowMore={2} userTracker={state.userTracker} key='dash-sport' kind='sport' queryKind='sport' readableKind='sport' keyprimary={state.dynamicSportsTag} convertTo={'minuteAndHour'} extrmConvertTo={'minuteAndHour'} mode={state.mode} data={state.data.interval} today={state.today} defaultSelected={state.refDate} ydomain={'dyn'} ydomainMax={'dyn'} type={'groupedbarchart'} yAxisUnit={'h'} ydomainOffset={120} extremAndMeanConfig={{min: true, max: true, mean: false, total: true, weight: false, imc: false}} userSettings={state.userSettings} defaultAxis={[0, 60, 120]}></Dashboard>
                        <Dashboard customer={props.customer} locales={state.locales} datarised={state.dataRising.filter(drise => drise.theme === '20')} displayed={state.desabledDash.indexOf('20') > -1 ? false : true} nShowMore={2} userTracker={state.userTracker} key='dash-denivele' kind='denivele' queryKind='activity' readableKind='dénivelé' keyprimary={['ACTIVITY_FLOORS']} mode={state.mode} data={state.data.interval} today={state.today} defaultSelected={state.refDate} ydomain={'dyn'} ydomainMax={'dyn'} convertTo={'étages'} extrmConvertTo={'étages'} convertToMetric={'osef'} convertFrom={'osef'} type={'linechart'} extremAndMeanConfig={{min: true, max: true, mean: false, total: true, weight: false, imc: false}} defaultAxis={[0, 3, 6]}></Dashboard>
                        <Dashboard customer={props.customer} locales={state.locales} datarised={state.dataRising.filter(drise => drise.theme === '3')} displayed={state.desabledDash.indexOf('3') > -1 ? false : true} nShowMore={3} userTracker={state.userTracker} key='dash-sleep' kind='sleep' queryKind='sleep' readableKind='sommeil' keyprimary={['SLEEP_DURATION', 'SLEEP_QUALITY']} mode={state.mode} data={state.data.interval} today={state.today} defaultSelected={state.refDate} type={'barchart'} ydomain={'dyn'} ydomainMax={'dyn'} convertTo={'minuteAndHour'} extrmConvertTo={'minuteAndHour'} convertToMetric={'osef'} convertFrom={'osef'} yAxisUnit={'h'} defaultAxis={[0, 300, 720]}></Dashboard>
                        <Dashboard customer={props.customer} locales={state.locales} datarised={state.dataRising.filter(drise => drise.theme === '18')} displayed={state.desabledDash.indexOf('18') > -1 ? false : true} nShowMore={1} userTracker={state.userTracker} key='dash-tiredness' kind='tiredness' queryKind='tiredness' readableKind='fatigue' keyprimary={['TIREDNESS_LEVEL']} mode={state.mode} data={state.data.interval} today={state.today} defaultSelected={state.refDate} ydomain={10} convertTo={'points'} extrmConvertTo={'/10'} convertToMetric={'osef'} convertFrom={'osef'} type={'linechart'} variationsInverted={true} defaultAxis={[0, 5, 10]}></Dashboard>
                        <Dashboard customer={props.customer} locales={state.locales} datarised={state.dataRising.filter(drise => drise.theme === '4')} displayed={state.desabledDash.indexOf('4') > -1 ? false : true} nShowMore={1} userTracker={state.userTracker} key='dash-stress' kind='stress' queryKind='stress' readableKind='stress' keyprimary={['STRESS_LEVEL']} mode={state.mode} data={state.data.interval} today={state.today} defaultSelected={state.refDate} ydomain={10} convertTo={'points'} extrmConvertTo={'/10'} convertToMetric={'osef'} convertFrom={'osef'} type={'linechart'} variationsInverted={true} defaultAxis={[0, 5, 10]}></Dashboard>
                        <Dashboard customer={props.customer} locales={state.locales} datarised={state.dataRising.filter(drise => drise.theme === '6')} displayed={state.desabledDash.indexOf('6') > -1 ? false : true} nShowMore={1} userTracker={state.userTracker} key='dash-tobacco' kind='tobacco' queryKind='smoke' readableKind='tabac' keyprimary={['SMOKE_QUANTITY']} mode={state.mode} data={state.data.interval} today={state.today} defaultSelected={state.refDate} ydomain={'dyn'} convertTo={'cigarettes'} extrmConvertTo={'cigarettes'} convertToMetric={'osef'} convertFrom={'osef'} ydomainMax={'dyn'} type={'linechart'} variationsInverted={true} extremAndMeanConfig={{min: true, max: true, mean: false, total: true, weight: false, imc: false}} defaultAxis={[0, 5, 10]}></Dashboard>
                        <Dashboard customer={props.customer} locales={state.locales} datarised={state.dataRising.filter(drise => drise.theme === '8')} displayed={state.desabledDash.indexOf('8') > -1 ? false : true} nShowMore={1} userTracker={state.userTracker} key='dash-alcool' kind='alcool' queryKind='drink' readableKind='alcool' keyprimary={['DRINK_QUANTITY']} mode={state.mode} data={state.data.interval} today={state.today} defaultSelected={state.refDate} ydomain={'dyn'} convertTo={'verres'} extrmConvertTo={'verres'} convertToMetric={'osef'} convertFrom={'osef'} ydomainMax={'dyn'} type={'linechart'} variationsInverted={true} extremAndMeanConfig={{min: true, max: true, mean: false, total: true, weight: false, imc: false}} defaultAxis={[0, 3, 6]}></Dashboard>
                        <Dashboard customer={props.customer} locales={state.locales} datarised={state.dataRising.filter(drise => drise.theme === '16')} displayed={state.desabledDash.indexOf('16') > -1 ? false : true} nShowMore={1} userTracker={state.userTracker} key='dash-followed-weight' kind='followed-weight' queryKind='followed-weight' readableKind='poids' keyprimary={['PERSONAL_WEIGHT']} mode={state.mode} data={state.data.interval} today={state.today} defaultSelected={state.refDate} ydomain={'dyn'} ydomainMin={'dyn'} ydomainMax={'dyn'} convertTo={'kg'} extrmConvertTo={'kg'} convertToMetric={(state.metrics !== undefined) ? state.metrics.weight : 0} convertFrom={'weight'} type={'linechart'} extremAndMeanConfig={{min: false, max: false, mean: false, total: false, weight: true, imc: true}} particularHeight={state.particularHeight} metrics={state.metrics} defaultAxis={[65, 70, 75]}></Dashboard>
                    </div>
                }
            </PageLayout>
        );
        return view;
    }
}