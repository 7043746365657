import { t, h } from '@app/utils';
import { Button, Form } from '@app/elements';

import PublicLayout from '@app/layouts/public/layout';

export const view = (state, actions) => (props, children) => (
    <div key={'publicsurvey-' + state.objectives.length}>
        <PublicLayout page='survey' customer={props.customer}>
            <div class='btzSurvey-btzSurveyHeader'>
                <p class='btzSurvey-btzSurveyHeader-btzLabel'>
                    {t('Merci de nous accorder quelques secondes de votre temps pour laisser votre impression. Vos réponses à ces questions aideront à améliorer le service.', {ns: 'public'})}
                </p>
            </div>

            <Form onsubmit={event => event.preventDefault()}>

                <div class='btzSurvey-btzSurveyBlock'>
                    <div class='btzSurvey-btzSurveyBlock-btzSurveyTitle'>
                        <p class='btzSurvey-btzSurveyBlock-btzSurveyTitle-btzPuce'>1</p>
                        <p class='btzSurvey-btzSurveyBlock-btzSurveyTitle-btzLabel'>
                            {t('Quel(s) objectif(s) avez-vous suivi(s) jusqu’à maintenant ?', {ns: 'public'})}

                            <font class='btzSurvey-btzSurveyBlock-btzSurveyTitle-btzSubLabel'>
                                {t('Plusieurs réponses possibles', {ns: 'public'})}
                            </font>
                        </p>
                    </div>

                    {state.objectives &&
                        <div>
                            {state.objectives.map(item =>
                                <div class='btzSurvey-btzSurveyBlock-btzSurveyEntry'>
                                    <Form.CheckBox
                                        placeholder={item.title}
                                        id={item.id}
                                        name='objReasons'
                                        custom='btzSurvey'
                                        value={'obj_' + item.title + '-' + item.id}
                                    />
                                </div>
                            )}
                        </div>
                    }
                </div>

                <div class='btzSurvey-btzSurveyBlock'>
                    <div class='btzSurvey-btzSurveyBlock-btzSurveyTitle'>
                        <p class='btzSurvey-btzSurveyBlock-btzSurveyTitle-btzPuce'>2</p>
                        <p class='btzSurvey-btzSurveyBlock-btzSurveyTitle-btzLabel'>
                            {t('Ces objectifs vous ont-ils aidé à changer quelques mauvaises habitudes ?', {ns: 'public'})}
                        </p>
                    </div>

                    <div class='btzSurvey-btzSurveyBlock-btzSurveyEntry'>
                        <Form.Radio
                            placeholder={t('Oui', {ns: 'generals'})}
                            id='changehabits'
                            name='objHelpToChangeHabits'
                            custom='btzSurvey'
                            value='obj_help_me'
                        />
                    </div>

                    <div class='btzSurvey-btzSurveyBlock-btzSurveyEntry'>
                        <Form.Radio
                            placeholder={t('Non', {ns: 'generals'})}
                            id='changehabits'
                            name='objHelpToChangeHabits'
                            custom='btzSurvey'
                            value='obj_dont_help_me'
                        />
                    </div>
                </div>

                <div class='btzSurvey-btzSurveyBlock'>
                    <div class='btzSurvey-btzSurveyBlock-btzSurveyTitle'>
                        <p class='btzSurvey-btzSurveyBlock-btzSurveyTitle-btzPuce'>3</p>
                        <p class='btzSurvey-btzSurveyBlock-btzSurveyTitle-btzLabel'>
                            {t('Pourquoi nous avoir quittés ?', {ns: 'public'})}

                            <font class='btzSurvey-btzSurveyBlock-btzSurveyTitle-btzSubLabel'>
                                {t('Plusieurs réponses possibles', {ns: 'public'})}
                            </font>
                        </p>
                    </div>

                    <div class='btzSurvey-btzSurveyBlock-btzSurveyEntry'>
                        <Form.CheckBox
                            placeholder={t('Le contenu des conseils n‘est pas intéressant', {ns: 'public'})}
                            name='quitReasons'
                            custom='btzSurvey'
                            value={t('Le contenu des conseils n‘est pas intéressant', {ns: 'public'})}
                        />
                    </div>
                    <div class='btzSurvey-btzSurveyBlock-btzSurveyEntry'>
                        <Form.CheckBox
                            placeholder={t('La plateforme n‘est pas assez interactive', {ns: 'public'})}
                            name='quitReasons'
                            custom='btzSurvey'
                            value={t('La plateforme n‘est pas assez interactive', {ns: 'public'})}
                        />
                    </div>
                    <div class='btzSurvey-btzSurveyBlock-btzSurveyEntry'>
                        <Form.CheckBox
                            placeholder={t('J‘ai l‘impression d‘avoir fait le tour', {ns: 'public'})}
                            name='quitReasons'
                            custom='btzSurvey'
                            value={t('J‘ai l‘impression d‘avoir fait le tour', {ns: 'public'})}
                        />
                    </div>
                    <div class='btzSurvey-btzSurveyBlock-btzSurveyEntry'>
                        <Form.CheckBox
                            placeholder={t('Les objectifs ne me convenaient pas', {ns: 'public'})}
                            name='quitReasons'
                            custom='btzSurvey'
                            value={t('Les objectifs ne me convenaient pas', {ns: 'public'})}
                        />
                    </div>
                    <div class='btzSurvey-btzSurveyBlock-btzSurveyEntry'>
                        <Form.CheckBox
                            placeholder={t('La gamification (score, statuts, récompenses) ne me plaisait pas', {ns: 'public'})}
                            name='quitReasons'
                            custom='btzSurvey'
                            value={t('La gamification (score, statuts, récompenses) ne me plaisait pas', {ns: 'public'})}
                        />
                    </div>
                    <div class='btzSurvey-btzSurveyBlock-btzSurveyEntry'>
                        <Form.CheckBox
                            placeholder={t('Autre :', {ns: 'public'})}
                            name='quitReasons'
                            custom='btzSurvey'
                            value={t('Autre :', {ns: 'public'})}
                        />
                    </div>

                    <div class='btzSurvey-btzSurveyBlock-btzSurveyEntry'>
                        <Form.Group>
                            <Form.Input
                                classes={'btzFullWidth'}
                                placeholder={t('Écrivez votre réponse ici', {ns: 'public'})}
                                type='text'
                                name='quitReasons'
                                oninput={actions.waitForLastInput}
                            />
                        </Form.Group>
                    </div>
                </div>


                <Form.Group classes='btzForm-btzFooter'>
                    <Button primary flat onclick={actions.onSubmit}>
                        {t('Envoyer', {ns: 'generals'})}
                    </Button>
                </Form.Group>
            </Form>



        </PublicLayout>
    </div>

);