import { t, h } from '@app/utils';
import { Button, Tile, Avatar, Balloon } from '@app/elements';
import { allRoutes } from '@app/settings';
import * as staticsconstants from '@app/constants/constants-statics';

import logoExpire from '@app/img/public/expire/you-are-expire.png';
import logoExpirex2 from '@app/img/public/expire/you-are-expire@2x.png';

import PublicLayout from '@app/layouts/public/layout';

module.exports = {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <div key='publicexpire'>
                <PublicLayout page='expire' customer={props.customer}>
                    <div class='publicexpire-expire'>
                        <div style={{ position: 'relative' }}>
                            {state.kind === 'sponsorexpire' &&
                                <Tile>
                                    <div class='btzTitle-btzImage btzTitle-btzImage-absolute col-md-12 col-4'>
                                        <img src={logoExpire} srcset={`${logoExpirex2} 2x`} alt='' class='' />
                                    </div>
                                    <Tile.Content classes='btzLeftHrArrowed col-xs-12 col-8'>
                                        <p class='btzTitle btzXl'>{t('La licence de votre parrain est expirée', {ns: 'public'})}</p>
                                        <p class='btzTexte'>{t('Vous bénéficiez d‘un accès gratuit à la plateforme. Ceci grâce à votre parrain.', {ns: 'public'})}</p>

                                        <div class='' style={{ 'margin': '30px 0' }}>
                                            <p class=''>
                                                {t('Mais cela ne vous empêche pas de poursuivre l’aventure !', {ns: 'public'})}<br/>
                                                {t('Pour continuer à profiter de nos conseils, nos programmes, et préserver votre santé, vous pouvez souscrire à l’abonnement pour 14,90 euros par mois.', {ns: 'public'})}
                                            </p>
                                        </div>

                                        <div class='' style={{ 'margin': '30px 0' }}>
                                            <p class=''>
                                                {t('Choisissez, ci-dessous, l‘option que vous souhaitez et laissez vous guider.', {ns: 'public'})}
                                            </p>
                                        </div>

                                        <Button flat active onclick={() => {document.location.href = allRoutes['public.cb'].pathname}}>{t('Souscrire', {ns: 'public'})}</Button>

                                        <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap' }}>
                                            <Tile.Icon>
                                                <Avatar.Alfred classes={'centered'} />
                                            </Tile.Icon>
                                            <Tile.Content>
                                                <Balloon kind={'info'} style={{ 'white-space': 'normal' }}>
                                                    {t('Pour toute question, n’hésitez pas à me contacter', {ns: 'public'}) + ' : ' + ((staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()] !== undefined) ? staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()].mailto : '#')}
                                                    {t('Je me ferai un plaisir de vous aider.', {ns: 'public'})}
                                                </Balloon>
                                            </Tile.Content>
                                        </Tile>
                                    </Tile.Content>
                                </Tile>
                            }
                            {state.kind === 'parentexpire' &&
                                <Tile>
                                    <div class='btzTitle-btzImage btzTitle-btzImage-absolute col-md-12 col-4'>
                                        <img src={logoExpire} srcset={`${logoExpirex2} 2x`} alt='' class='' />
                                    </div>
                                    <Tile.Content classes='btzLeftHrArrowed col-xs-12 col-8'>
                                        <p class='btzTitle btzXl'>{t('Votre parrain a résilié sa licence', {ns: 'public'})}</p>
                                        <p class='btzTexte'>{t('Vous bénéficiez d‘un accès gratuit à la plateforme. Ceci grâce à votre parrain', {ns: 'public'})}</p>

                                        <p class='btzTexte' style={{ 'margin': '0' }}>{t('Celui-ci a résilié son compte.', {ns: 'public'})}</p>

                                        <div class='' style={{ 'margin': '30px 0' }}>
                                            <p class=''>
                                                {t('Mais cela ne vous empêche pas de poursuivre l’aventure !', {ns: 'public'})}<br/>
                                                {t('Pour continuer à profiter de nos conseils, nos programmes, et préserver votre santé, vous pouvez souscrire à l’abonnement pour 14,90 euros par mois.', {ns: 'public'})}
                                            </p>
                                        </div>

                                        <Button flat active onclick={() => {document.location.href = allRoutes['public.cb'].pathname}}>{t('Souscrire', {ns: 'public'})}</Button>

                                        <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap' }}>
                                            <Tile.Icon>
                                                <Avatar.Alfred classes={'centered'} />
                                            </Tile.Icon>
                                            <Tile.Content>
                                                <Balloon kind={'info'} style={{ 'white-space': 'normal' }}>
                                                    {t('Pour toute question, n’hésitez pas à me contacter', {ns: 'public'}) + ' : ' + ((staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()] !== undefined) ? staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()].mailto : '#')}
                                                    {t('Je me ferai un plaisir de vous aider.', {ns: 'public'})}
                                                </Balloon>
                                            </Tile.Content>
                                        </Tile>
                                    </Tile.Content>
                                </Tile>
                            }
                            {((state.kind === 'mutualexpire') || (state.kind === 'enterpriseexpire')) &&
                                <Tile>
                                    <div class='btzTitle-btzImage btzTitle-btzImage-absolute col-md-12 col-4'>
                                        <img src={logoExpire} srcset={`${logoExpirex2} 2x`} alt='' class='' />
                                    </div>
                                    <Tile.Content classes='btzLeftHrArrowed col-xs-12 col-8'>
                                        <p class='btzTitle btzXl'>{t('Votre licence est expirée', {ns: 'public'})}</p>
                                        <p class='btzTexte'>{t('Il peut il y avoir plusieurs raisons à cela :', {ns: 'public'})}</p>

                                        <div class='btzCustomUl-btzUl'>
                                            {state.kind === 'mutualexpire' ?
                                                <div>
                                                    <div class='btzCustomUl-btzLi'>{t('votre mutuelle n‘est plus partenaire', {ns: 'public'})}</div>
                                                    <div class='btzCustomUl-btzLi'>{t('vous avez quitté votre mutuelle', {ns: 'public'})}</div>
                                                    <div class='btzCustomUl-btzLi'>{t('un problème technique - contactez', {ns: 'public'}) + ' ' + ((staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()] !== undefined) ? staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()].mailto : '#')}</div>
                                                </div>
                                                :
                                                <div>
                                                    <div class='btzCustomUl-btzLi'>{t('votre entreprise n‘est plus partenaire', {ns: 'public'})}</div>
                                                    <div class='btzCustomUl-btzLi'>{t('vous avez quitté votre entreprise', {ns: 'public'})}</div>
                                                    <div class='btzCustomUl-btzLi'>{t('un problème technique - contactez', {ns: 'public'}) + ' ' + ((staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()] !== undefined) ? staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()].mailto : '#')}</div>
                                                </div>
                                            }
                                        </div>

                                        <div class='' style={{ 'margin': '30px 0' }}>
                                            <p class=''>
                                                {t('Si vous faites partie des deux premiers cas de figure, cela ne vous empêche pas de poursuivre l’aventure !', {ns: 'public'})}<br/>
                                                {t('Pour continuer à profiter de nos conseils, nos programmes, et préserver votre santé, vous pouvez souscrire à l’abonnement pour 14,90 euros par mois.', {ns: 'public'})}
                                            </p>
                                        </div>

                                        <Button flat active onclick={() => {document.location.href = allRoutes['public.cb'].pathname}}>{t('Je continue l‘aventure', {ns: 'public'})}</Button>

                                        <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap' }}>
                                            <Tile.Icon>
                                                <Avatar.Alfred classes={'centered'} />
                                            </Tile.Icon>
                                            <Tile.Content>
                                                <Balloon kind={'info'} style={{ 'white-space': 'normal' }}>
                                                    {t('Pour toute question, n’hésitez pas à me contacter', {ns: 'public'}) + ' : ' + ((staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()] !== undefined) ? staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()].mailto : '#')}
                                                    {t('Je me ferai un plaisir de vous aider.', {ns: 'public'})}
                                                </Balloon>
                                            </Tile.Content>
                                        </Tile>
                                    </Tile.Content>
                                </Tile>
                            }
                        </div>
                    </div>
                </PublicLayout>
            </div>
        );
        return view;
    }
}