import { addCSRFToken, compareByKey } from '@app/core';
import { allRoutes } from '@app/settings';
import { Programs } from '@app/api';
import { t, h, Component } from '@app/utils';
import { VerticalScroll } from '@app/elements';
import './index.scss';

import * as staticsconstants from '@app/constants/constants-statics';
import * as constants from '@app/constants';

const state = {
    recommanded: [],
    initialize: false,
}

const actions = {
    onComponentCreate: () => (state, actions) => {
        Programs.getRecommandedFollowingObjectives().then(function(res) {
            var recommanded = [], card =  null;
            for (var reco in res.data) {
                card = res.data[reco];
                var cardTheme = actions.getTheme(card.theme);

                card.valueTheme = cardTheme.value;
                card.labelTheme = cardTheme.label;
                card.order = cardTheme.order;

                recommanded.push(card);
            }
            recommanded.sort(compareByKey('order'));
            actions.setRecommandedPrograms(recommanded);
            actions.setInitialize(true);
        });
    },
    setRecommandedPrograms: newState => state => ({
        recommanded: newState
    }),
    setInitialize: newState => state => ({
        initialize: newState
    }),
    beginTheseProgram: (programid) => (state, actions) => {
        window.location.href = addCSRFToken(allRoutes['private.program.start'].pathname.replace(':programid', programid));
    },
    getTheme: (intTheme) => {
        for (var _t in constants.custo.THEMES) {
            if (constants.custo.THEMES[_t].key === intTheme) {
                return constants.custo.THEMES[_t];
            }
        }
        return {value : '', label : '', order: ''};
    },
};

const view = (state, actions) => (props, children) => (
    <div>
        {((state.initialize === true) && (state.recommanded.length == 0)) ?
            <div></div>
            :
            <div id={'recommandedPrograms'} class='btzSimplePageBlock btzSimplePageBlock-btzSimplePageBlockNoPadding' style={{ 'padding-bottom': '25px' }}>
                <p class='btzTitle btzXl'>{t(staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()].menuNames.programs.recommanded)}</p>
                <p class='btzSubTitle'>{t('Vous trouverez ci-dessous une liste de programmes correspondant aux objectifs que vous souhaitez atteindre.', {ns: 'programs'})}</p>

                <div key={state.initialize + '-' + state.recommanded.length}>
                    <VerticalScroll>
                        {((state.recommanded.length == 0) && (state.initialized === false)) &&
                            <div>
                                <div class='col-4 col-md-6 col-sm-12'>
                                    <div class='btzLoadingContainer-btzWrapper'>
                                        <div class='btzLoadingContainer'></div>
                                    </div>
                                </div>
                                <div class='col-4 col-md-6 col-sm-12'>
                                    <div class='btzLoadingContainer-btzWrapper'>
                                        <div class='btzLoadingContainer'></div>
                                    </div>
                                </div>
                                <div class='col-4 col-md-6 col-sm-12'>
                                    <div class='btzLoadingContainer-btzWrapper'>
                                        <div class='btzLoadingContainer'></div>
                                    </div>
                                </div>
                                <div class='col-4 col-md-6 col-sm-12'>
                                    <div class='btzLoadingContainer-btzWrapper'>
                                        <div class='btzLoadingContainer'></div>
                                    </div>
                                </div>
                            </div>
                        }

                        {state.recommanded.map(item =>
                            <div class='col-4 col-md-6 col-sm-12'>
                                <div key={item.id} class='btzProgram-btzProgramRecommanded-btzProgramRecommandedWrapper' onclick={() => { if (item.program.inProgress || item.program.alreadyDone) { return false; } else { actions.beginTheseProgram(item.program.id); } }}>
                                    <div class={'btzBackgroundCover ' + (item.program.new ? 'btzProgramRecommanded-btzNewProgram' : (item.program.alreadyDone ? 'btzProgramRecommanded-btzAlreadyDoneProgram' : (item.program.inProgress ? 'btzProgramAll-btzRunningProgram' : '')))} data-new={t('nouveau', {ns: 'programs'})} data-alreadydone={t('déjà fait', {ns: 'programs'})} data-running={t('en cours', {ns: 'programs'})} style={{ 'background-image': 'url(' + (((item.media !== null) && (item.media !== undefined) && (item.media.url !== undefined)) ? item.media.url : '') + ')' }}></div>

                                    <div class='btzProgramsRecommanded-btzProgramRecommanded'>
                                        <p class='btzPageContentTexte'>{item.program.title}</p>
                                        <p class='btzPageContentSubTitle btzTextColored' data-theme={item.valueTheme}>{t(item.labelTheme)}</p>
                                        <p class='btzPageContentSubTexte'>{t('Durée', {ns: 'programs'})} : {item.program.duration}</p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </VerticalScroll>
                </div>
            </div>
        }
    </div>
);

export default Component(state, actions, view, 'recommandedPrograms');
