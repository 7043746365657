import { isDefined } from '@app/core';

import * as staticsconstants from '@app/constants/constants-statics';

export const actions = {
    onComponentCreate: (props) => (state, actions) => {
        console.log('REGISTER > sex : is guest > ', props.initial);
        if (props.initial) {
            if (props.initial.gender !== null) {
                actions.setSexValue(+props.initial.gender);
            }
            if (props.initial.isGuest !== null) {
                actions.setIsGuest(props.initial.isGuest);
            }
            if (props.customer) {
                actions.setCustomer(props.customer);
            }
        }
    },

    setState: ({ ns, key, value }) => (state, actions) => ({
        [ns]: { ...state[ns], ...{ [key]: value } }
    }),

    setSexValue: string => (state, actions) => actions.setState({
        ns: 'sex',
        key: 'value',
        value: string
    }),

    setIsGuest: newState => (state) => ({
        isGuest: newState,
    }),
    setCustomer: newState => (state) => ({
        customer: newState,
    }),

    onWomanClick: () => (state, actions) => {
        actions.setSexValue('woman')
    },

    onManClick: () => (state, actions) => {
        actions.setSexValue('man')
    },

    goToNextStep: () => (state, actions) => {
        if (!staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()].modules.registerWorkflow.whopay) {
        //if ((state.isGuest === true) || (state.customer === 'coronavirus') || (state.customer === 'klesia_netflix')) {
            window.main.location.go('/home/register/objectives');
        } else {
            if (isDefined(staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()].modules.registerWorkflow.directpay)) {
            // if (state.customer === 'vyv') {
                window.main.location.go('/home/register/' + staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()].modules.registerWorkflow.directpay);
            } else {
                window.main.location.go('/home/register/whopay');
            }
        }
    },

    onStepSubmit: ({ev, props}) => (state, actions) => {
        ev.preventDefault();
        if (['cerba'].indexOf(state.customer) > -1) {
            window.main.location.go('/home/register/end');
        } else {
            if (state.customer === 'vyv') {
                props.send({
                    ns: 'user',
                    key: 'type',
                    value: 'mutual'
                });
            }
            actions.goToNextStep();
        }
    },
};
