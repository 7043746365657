export const actions = {
    /*onComponentCreate: ({ initial: { type = '' } }) => (state, actions) => {
        actions.setWhoPayValue(type);
    },*/

    onComponentUpdate: ({ initial: { type = '' } }) => (state, actions) => {
        actions.setWhoPayValue(type);
    },

    setState: ({ ns, key, value }) => (state, actions) => ({
        [ns]: { ...state[ns], ...{ [key]: value } }
    }),

    setWhoPayValue: type => (state, actions) => actions.setState({
        ns: 'whopay',
        key: 'value',
        value: type
    }),

    onCompanyClick: () => (state, actions) => actions.setWhoPayValue('company'),

    onMutualClick: () => (state, actions) => actions.setWhoPayValue('mutual'),

    onIndividualClick: () => (state, actions) => actions.setWhoPayValue('b2c'),

    updateParentProps: (props) => (state, actions) => {
        if (props) {
            props.send({
                ns: 'user',
                key: 'type',
                value: state.whopay.value
            });
            actions.redirectDependingOnWhoPay(state.whopay.value)
        }
    },

    redirectDependingOnWhoPay: (string) => {
        switch (string) {
            case 'company':
                window.main.location.go('/home/register/entreprise');
                break;
            case 'mutual':
                window.main.location.go('/home/register/mutualhealth');
                break;
            case 'b2c':
                window.main.location.go('/home/register/objectives');
                break;
        }
    },
};
