import { User } from '@app/api';
import { debounce } from 'hyperapp-fx';
import { t, h, Component } from '@app/utils';
import { isEmail, getCustomerInfos, isDefined } from '@app/core';
import { Avatar, Button, Form, Card, Tile, Balloon } from '@app/elements';
import './index.scss';

const state = {
    remainingChildren: 0,
    totalChildren: 0,
    error: '',
    kind: 'info',
    customerName: 'Betterise',
    sponsors: [],
    sponsored: [],
    rendering: false,
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        let customerInfos = getCustomerInfos(props.customer);
        actions.setCustomerName(customerInfos.name)
        actions.setTotalChildren(props.maxChildren);
        if (isDefined(props.sponsored)) {
            actions.setRemainingChildren(props.maxChildren - props.sponsored.length);

            for (var i = 0; i < (props.maxChildren - props.sponsored.length); i++) {
                state.sponsors.push({
                    isValid: false,
                    isTouched: false,
                    value: '',
                });
            }

            let sponsored = [];
            for (var j = 0; j < props.sponsored.length; j++) {
                sponsored.push(props.sponsored[j]);
            }
            actions.setSponsored(sponsored);
        }
    },
    setErrorState: newState => state => ({
        error: newState,
    }),
    setRemainingChildren:  newState => state => ({
        remainingChildren: newState,
    }),
    setTotalChildren:  newState => state => ({
        totalChildren: newState,
    }),
    setCustomerName:  newState => state => ({
        customerName: newState,
    }),
    setSponsored:  newState => state => ({
        sponsored: newState,
    }),

    onUpdate: ({ target: { name, value } }) => (state, actions) => {
        let sponsor = name.split('-')[0];
        let sponsorKey = name.split('-')[1];
        let isValid = actions.isInputValid({ sponsor, value });

        state.sponsors[sponsorKey].sponsorKey = sponsorKey;
        state.sponsors[sponsorKey].isValid = isValid;
        state.sponsors[sponsorKey].value = value;
        actions.rendering();
    },

    waitForLastInput: ({ target }) => debounce(
        500,
        'onUpdate',
        { target },
    ),

    setState: ({ ns, key, value }) => state => ({
        [ns]: { ...state[ns], ...{ [key]: value } },
    }),

    isInputValid: ({ sponsor, value }) => (state, actions) => {
        if (sponsor === undefined) {
            return false;
        }

        switch (sponsor) {
            case 'sponsor':
                return isEmail(value);
            default:
                throw Error(`${sponsor} has no validator assigned`);
        }
    },

    onBlur: ({ target: { name } }) => (state, actions) => {
        actions.setErrorState('');
        let sponsorKey = name.split('-')[1];
        state.sponsors[sponsorKey].isTouched = true;
    },
    onSubmit: (sponsor) => (state, actions) => {
        if (sponsor.isValid) {
            let data = {email : sponsor.value};

            User.setUserSponsor(data).then((res) => {
                if (res.data === false) {

                } else {
                    state.sponsors[sponsor.sponsorKey] = [];
                    state.sponsored.push(sponsor.value);
                    actions.setRemainingChildren(state.remainingChildren - 1);
                    actions.rendering();
                }
            });
        } else {
            actions.setErrorState('L’adresse email saisie semble invalide.');
        }
    },
    rendering: () => state => ({
        rendering: !state.rendering,
    }),
};


const view = (state, actions) => (props, children) => (
    <Card id={'sponsor'} key={'param-sponsor'} classes={'btzPageCard btzPageCard-btzPageCardNoPadding'} kind={'param'}>
        <Card.Header classes={'btzCard-btzHeader-btzPage'}>
            <div class='col-12'>
                <p class='btzTimeline-btzCard-btzTitle btzPageContentTitle'>{t('Parrainage', {ns: 'sponsor'})}</p>
                <p class='btzTimeline-btzCard-btzSubTitle btzPageContentSubTitle'>{t('Faites bénéficier gratuitement de', {ns: 'sponsor'}) + ' ' + state.customerName + ' ' + t('à', {ns: 'generals'}) + ' ' + state.totalChildren + ' ' + t('de vos proches, pour que chacun prenne soin de sa santé.', {ns: 'sponsor'})}</p>
            </div>
        </Card.Header>

        <Card.Body>
            <div class='col-6 col-sm-12' style={{ 'overflow': 'hidden', 'vertical-align': 'middle', 'padding-right': '0px' }}>
                {state.sponsored.map(item =>
                    <Form style={{ 'position': 'relative', 'padding-top': '5px' }}>
                        <div key={item}>
                            <Form.Group>
                                <Form.Input
                                    classes={'btzFullWidth'}
                                    type={'email'}
                                    readonly={'readonly'}
                                    value={item}
                                    classes={'is-success'}
                                />
                            </Form.Group>

                            <Form.Group classes='btzForm-btzFooter btzForm-btzFooter-btzSuccess' style={{ 'position': 'absolute', 'right': '0', 'margin-top': '-65px', 'font-size': '16px', 'width': '75px' }}>
                                {t('Invitation envoyée !', {ns: 'sponsor'})}
                            </Form.Group>
                        </div>
                    </Form>
                )}

                {Array.from({length: state.remainingChildren}, (_, i) => (
                    <Form onsubmit={event => event.preventDefault()} style={{ 'position': 'relative', 'padding-top': '5px' }}>
                        <div>
                            <Form.Group>
                                <Form.Input
                                    style={{ 'padding-bottom': '6px', 'color': '#989898' }}
                                    classes={'btzFullWidth'}
                                    placeholder={t('Email à parrainer', {ns: 'sponsor'})}
                                    type={'email'}
                                    id={'sponsor-' + i}
                                    name={'sponsor-' + i}
                                    onfocus={actions.onBlur}
                                    oninput={actions.waitForLastInput}
                                    error={state.sponsors[i].isTouched && !state.sponsors[i].isValid}
                                    classes={(state.sponsors[i].isTouched) ? (state.sponsors[i].isValid ? 'is-success' : 'is-error') : ''}
                                />
                            </Form.Group>

                            <Form.Group classes='btzForm-btzFooter' style={{ 'position': 'absolute', 'right': '0', 'margin-top': '-68px' }}>
                                <Button primary active={state.sponsors[i].isTouched && state.sponsors[i].isValid} flat onclick={() => {
                                    actions.onSubmit(state.sponsors[i])
                                }}>
                                    {t('Inviter', {ns: 'sponsor'})}
                                </Button>
                            </Form.Group>
                        </div>
                    </Form>
                ))}
            </div>
            <div class='col-6 col-sm-12' style={{ 'vertical-align': 'middle' }}>
                <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap' }}>
                    <Tile.Icon classes={''}>
                        <Avatar.Alfred classes={'centered'} />
                    </Tile.Icon>
                    <Tile.Content>
                        {state.error === '' ?
                            <Balloon kind={'info'} style={{ 'white-space': 'normal' }}>
                                {state.remainingChildren === state.totalChildren ?
                                    <p>{t('Qui seront les', {ns: 'sponsor'}) + ' ' + props.maxChildren + ' ' + t('petits chanceux ?', {ns: 'sponsor'}) + ' ' + t('Choisissez bien !', {ns: 'sponsor'})}</p>
                                    :
                                    <div>
                                        {state.remainingChildren === 0 ?
                                            <p>{t('Vous n‘avez plus d‘invitation disponible.', {ns: 'sponsor'})}</p>
                                            :
                                            <p>{(state.totalChildren - state.remainingChildren) + ' ' + t('sur', {ns: 'generals'}) + ' ' + state.totalChildren + ' ! ' + t('Et il vous reste encore', {ns: 'sponsor'}) + ' ' + state.remainingChildren + ' ' + t('invitation', {ns: 'sponsor'}) + '… ' + t('C’est pas beau ça ?', {ns: 'sponsor'})}</p>
                                        }
                                    </div>
                                }

                            </Balloon>
                            :
                            <Balloon kind={'error'} style={{ 'white-space': 'normal' }}>
                                <p>{state.error}</p>
                            </Balloon>
                        }
                    </Tile.Content>
                </Tile>
            </div>
        </Card.Body>

        <Card.Footer></Card.Footer>
    </Card>
);

export default Component(state, actions, view, 'sponsor');
