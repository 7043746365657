import { t, h } from '@app/utils';
import { Form, Tile, Card, Avatar, Balloon } from '@app/elements';

import logoCvc from '@app/img/register/areyou-cb-crypto.png';
import logoCvcx2 from '@app/img/register/areyou-cb-crypto@2x.png';

export const ModalView = (props, actions) => (
    <Card classes={'btzInformations-btzResiliationModal'}>
        <Card.Header classes={'btzCard-btzHeader-btzModal'}>
            <Tile>
                <Tile.Content classes='btzLeftHrArrowed col-12'>
                    <p class='btzTitle btzXl' style={{ 'margin-bottom': '45px' }}>{t('Détails des données bancaires', {ns: 'cb'})}</p>
                    {props.payment !== null &&
                        <Form onsubmit={event => event.preventDefault()}>
                            <div key={'nochange'}>
                                <div class='ElementsApp'>
                                    <div class='InputElement'>{'**** **** **** ' + props.payment.sources.data[0].card.last4}</div>
                                </div>
                                <div class='ElementsApp'>
                                    <div class='InputElement'>{(props.payment.sources.data[0].card.exp_month < 10 ? ('0' + props.payment.sources.data[0].card.exp_month) : props.payment.sources.data[0].card.exp_month) + ' / ' + props.payment.sources.data[0].card.exp_year}</div>
                                </div>
                                <div class='ElementsApp'>
                                    <div class='InputElement'>
                                        <p style={{ 'height': '30px', 'display': 'inline-block', 'vertical-align': 'middle' }}>{'***'}</p>
                                        <img style={{ 'height': '20px', 'display': 'inline-block', 'vertical-align': 'middle', 'margin': '0 0 0 10px' }} src={logoCvc} srcset={logoCvcx2} class='cvc' />
                                    </div>
                                </div>
                            </div>
                        </Form>
                    }

                    <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap' }}>
                        <Tile.Icon>
                            <Avatar.Alfred classes={'centered'} />
                        </Tile.Icon>
                        <Tile.Content>
                            {
                                props.api.kind === 'info' ? (
                                    <Balloon kind={props.api.kind} style={{ 'white-space': 'normal' }}>
                                        <p style={{ 'text-align': 'left' }}>
                                            {t(props.api.message)}
                                        </p>
                                    </Balloon>
                                ) : (
                                    <Balloon kind={props.api.kind} style={{ 'white-space': 'normal' }}>
                                        {t(props.api.message)}
                                    </Balloon>
                                )
                            }
                        </Tile.Content>
                    </Tile>
                </Tile.Content>
            </Tile>
        </Card.Header>
    </Card>
);