import { t } from '@app/utils'

export const actions = {
    onComponentCreate: (props) => (state, actions) => {
        state.favorite = (props.favorite || false);
    },
    updateParentProps: (props) => (state, actions) => {
        actions.setFavoriteState();
    },
    setFavoriteState: () => state => ({
        favorite: !state.favorite
    }),
    setModalDisplayed: newState => state => ({
        modalDisplayed: newState,
    }),
    rendering: () => state => ({
        rendering: !state.rendering,
    }),
    getDate: ({el, dat}) => {
        if (dat === null) {
            el.innerHTML = 'Ø';
            return;
        }
        dat = dat.replace(/-/g, '/');
        dat = dat.split(' ')[0];
        var date = new Date(Date.parse(new Date(dat)));
        var options = { month: 'long', day: 'numeric', year: 'numeric' };
        el.innerHTML = (t('Ajouté le', {ns: 'generals'}) + ' : ' + date.toLocaleDateString('fr-FR', options));
    }
};
