export const actions = {
    getSurveyQuestions: () => (state, actions) => {

    },
    endSurveyQuestions: () => (state, actions) => {
        actions.setSurveyQuestionsAnswered(true);
    },
    hideSurvey: () => (state, actions) => {
        actions.hideModal();
        setTimeout(() => {
            actions.setNothingToSurvey(true);
        }, 100);
    },
    hideModal: () => state => ({
        modalDisplayed: false,
    }),
    setSurveyQuestions: newState => state => ({
        surveyQuestions: newState,
    }),
    setSurveyQuestionsAnswered: newState => state => ({
        surveyQuestionsAnswered: newState,
    }),
    setNothingToSurvey: newState => state => ({
        nothingToSurvey: newState,
    })
};
