import { h, Component } from '@app/utils';
import { uuidv4 } from '@app/core';

const TopSticked = Component(
    {
        distanceToTop: 0,
        topdetection: 50,
        uniqId: null,
        classes: '',
        mode: 'fixed',
        fixed: false,
        progressive: false,
        absolute: false,
        parentWidth: 0,
        fixedTop: 0,
        offsetTop: '',
        originalHeight: 0,
        minHeight: null,
        oldScroll: 0,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.topdetection) {
                state.topdetection = props.topdetection;
            }
            if (props.classes) {
                state.classes = props.classes;
            }
            if (props.mode) {
                state.mode = props.mode;
            }
            if (props.top) {
                state.fixedTop = props.top;
            }
            if (props.progressive) {
                state.progressive = props.progressive;
            }

            state.uniqId = uuidv4();
            actions.activeTopSticked();
        },
        activeTopSticked: () => (state, actions) => {
            window.addEventListener('scroll', actions.handleTopStickedEvent);
            window.addEventListener('resize', actions.handleTopStickedEvent);
        },
        handleTopStickedEvent: () => (state, actions) => {
            let obj = document.querySelectorAll('#' + 'btzUniq-' + state.uniqId)[0];
            if (obj === undefined) {
                return false;
            }
            let distanceToTop = obj.getBoundingClientRect().top;
            actions.setOldScroll(distanceToTop);

            actions.setParentWidth(obj.offsetWidth);

            let top = (window.pageYOffset || document.documentElement.scrollTop)  - (document.documentElement.clientTop || 0);
            actions.setOffsetTop(top - state.topdetection);

            if ((state.progressive) && (state.originalHeight !== null) && (!isNaN(+state.originalHeight - top))) {
                actions.setMinHeight(+state.originalHeight - top);
            }

            if (state.topdetection === 1 && (top > 0)) {
                actions.setFixed(true);
                actions.setAbsolute(true);
            } else if ((distanceToTop - state.topdetection) < 0) {
                if (!state.fixed) {
                    actions.setFixed(true);
                    actions.setAbsolute(true);
                }
            } else if (state.fixed) {
                actions.setFixed(false);
                actions.setAbsolute(false);
            }
        },
        setFixed: newState => state => ({
            fixed: newState,
        }),
        setAbsolute: newState => state => ({
            absolute: newState,
        }),
        setOffsetTop: newState => state => ({
            offsetTop: newState,
        }),
        setParentWidth: newState => state => ({
            parentWidth: newState,
        }),
        defineMinHeight: (el) => (state, actions) => {
            actions.setOriginalHeight(el.offsetHeight);
            if (!state.progressive) {
                return false;
            }
            actions.setMinHeight(el.offsetHeight);
        },
        setOriginalHeight: newState => state => ({
            originalHeight: newState,
        }),
        setMinHeight: newState => state => ({
            minHeight: newState,
        }),
        setOldScroll: newState => state => ({
            oldScroll: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <div id={'btzUniq-' + state.uniqId} class={'btzStickedObj ' + (state.fixed ? (state.mode === 'fixed' ? 'fixed' : 'absolute') : '')}>
            <div oncreate={(el) => { actions.defineMinHeight(el) }} style={{ 'min-height': (state.minHeight !== null ? (state.minHeight + 'px') : ''), 'top' : (state.mode === 'fixed' ? ((+state.fixedTop > 0) ? (state.fixedTop + 'px') : '') : (state.offsetTop + 'px')), 'width': (state.parentWidth > 0 ? (state.parentWidth + 'px') : '') }} class={'btzStickedWrapper ' + state.classes + ' ' + (state.fixed ? (state.mode === 'fixed' ? 'fixed' : 'absolute') : '')}>{children}</div>
        </div>
    ),
    'topsticked'
);

export { TopSticked };
