import { t, h } from '@app/utils';
import { Card } from '@app/elements';

import logoNotFound from '@app/img/food/deco-no-aliment-tosuggest.png';
import logoNotFoundx2 from '@app/img/food/deco-no-aliment-tosuggest@2x.png';

export const view = (state, actions) => (props, children) => (
    <Card kind={'nomeal-thisweek'}>
        <Card.Body>
            <div class='col-4 btzData-btzDatasWrapper'>
                <Card.Image classes='btzCard-btzImage-btzAskImage'><img data-customer={props.customer} src={logoNotFound} srcset={`${logoNotFoundx2} 2x`} alt='' class='' /></Card.Image>
            </div>
            <div class='col-8 btzData-btzDatasWrapper'>
                <p class='btzPageContentTexte'>{t('Nous cherchons vos repas.', {ns: 'food'})}</p>
                <p class='btzPageContentSubTexte'>{t('Veuillez patienter ...', {ns: 'food'})}</p>
            </div>
        </Card.Body>
    </Card>
);
