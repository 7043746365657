import { getQueryVariable, tryToGetUser, isDefined } from '@app/core';
import { User, Mail } from '@app/api';

export const actions = {
    onComponentCreate: (props) => (state, actions) => {
        let token = getQueryVariable('xtoken', false);
        localStorage.setItem('apitoken', token);
        //
        let email = getQueryVariable('email', false);
        if (email && (email !== null)) {
            actions.setEmail(decodeURIComponent(email));
        }
        //
        let userCallback = (res) => {
            if (isDefined(res.security) && (res.security.emailVerified === true)) {
                let isChecked = res.settings.filter((f) => f.name === 'CHECKUPDAILY')[0];
                if (isDefined(isChecked)) {
                    actions.setChecked(+isChecked.value);
                }
            } else {
                actions.setVerified(false);
            }
        };
        tryToGetUser(userCallback, true, false);
    },

    setEmail: newState => state => ({
        email: newState,
    }),

    setChecked: newState => state => ({
        checked: newState,
    }),

    setKind: newState => state => ({
        kind: newState,
    }),

    setSubmitting: newState => state => ({
        submitting: newState,
    }),
    setVerified: newState => state => ({
        verified: newState,
    }),

    sendVerificationMail: () => (state, actions) => {
        if (state.sended) {
            return false;
        }
        actions.setSended(true);
        Mail.verification().then(() => {

        });
    },

    setSended: newState => state => ({
        sended: newState,
    }),

    onSubmit: (event) => (state, actions) => {
        event.preventDefault();
        if (state.submitting) {
            return false;
        }
        actions.setSubmitting(true);

        let settings = [
            {name: 'CHECKUPDAILY', value: (([true, 1].indexOf(state.checked) > -1) ? 1 : 0)},
            {name: 'EMAILHEBDO', value: (([true, 1].indexOf(state.checked) > -1) ? 1 : 0)},
            {name: 'STORY', value: (([true, 1].indexOf(state.checked) > -1) ? 1 : 0)},
            {name: 'BADGEUNLOCKED', value: 0}
        ];
        User.setUserSettings(settings).then(() => {
            actions.setKind('confirmation');
            actions.setSubmitting(false);
        });
    },
};