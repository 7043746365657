import { t, h } from '@app/utils';
import { Button, Card, Modal, Tile, Avatar, Balloon } from '@app/elements';

import icoEdit from '@app/img/ico/ico-adddatas.png';
import icoEditx2 from '@app/img/ico/ico-adddatas@2x.png';

import icoInfo from '@app/img/ico/ico-ask-white.png';
import icoInfox2 from '@app/img/ico/ico-ask-white@2x.png';

import icoAlcoolOK from '@app/img/food/ico-alcool-ok.png';
import icoAlcoolOKx2 from '@app/img/food/ico-alcool-ok.png';
import icoAlcoolKO from '@app/img/food/ico-alcool-ko.png';
import icoAlcoolKOx2 from '@app/img/food/ico-alcool-ko.png';

import icoFatOK from '@app/img/food/ico-gras-ok.png';
import icoFatOKx2 from '@app/img/food/ico-gras-ok.png';
import icoFatKO from '@app/img/food/ico-gras-ko.png';
import icoFatKOx2 from '@app/img/food/ico-gras-ko.png';

import icoSaltOK from '@app/img/food/ico-salt-ok.png';
import icoSaltOKx2 from '@app/img/food/ico-salt-ok.png';
import icoSaltKO from '@app/img/food/ico-salt-ko.png';
import icoSaltKOx2 from '@app/img/food/ico-salt-ko.png';

import icoSugarOK from '@app/img/food/ico-sugar-ok.png';
import icoSugarOKx2 from '@app/img/food/ico-sugar-ok.png';
import icoSugarKO from '@app/img/food/ico-sugar-ko.png';
import icoSugarKOx2 from '@app/img/food/ico-sugar-ko.png';

import { AddMeal } from '@app/modules/Food/AddMeal';

import { ModalViewBalance } from './modalViewBalance';
import { ModalViewSurvey } from './modalViewSurvey';
import { ModalViewAliments } from './modalViewAliments';

export const view = (state, actions) => (props, children) => (
    <div>
        <div class='col-4 col-sm-12' style={{ 'vertical-align': 'top' }}>
            {state.dayLunchs.map(item => {
                return <Card kind={'meal-daymeals'} classes={'btzPageCard btzPageCard-btzPageCardNoPadding ' + (props.lastAddedId === item.id ? 'btzGreenToBlack' : '')} style={{ 'margin-bottom': '10px' }}>
                    <Card.Body>
                        <div key={'mealrecap-' + state.dayLunchs.length}>
                            <div key={'meal-' + item.id} class='btzFood-btzMeal-btzRecap'>
                                <div class='col-8 p-0' style={{ 'vertical-align': 'middle' }}>
                                    <div class='btzFood-btzMeal-btzRecap-btzContent'>
                                        <p class=''>
                                            {state.mealKinds.map(meal => {
                                                if (meal.key === item.type) {
                                                    return <font class='btzPageContentTexte' style={{ 'font-size': '14px' }}>{meal.label}</font>
                                                }
                                            })}
                                        </p>
                                        <div class='btzPageContentSubTexte' style={{ 'line-height': '1', 'white-space': 'normal', 'padding': '5px 0' }}>
                                            {item.parsedaliments.map((aliment, idx) => {
                                                return aliment.name + ((idx + 1) < item.parsedaliments.length ? ', ' : '');
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div class='col-3' style={{ 'vertical-align': 'middle', 'padding-right': '0px' }}>
                                    <div class='btzFood-btzMeal-btzRecap-btzEdit'>
                                        <p class='btzPageContentTexte btzGreenToBlackMarker'>{item.aliments.length + ' ' + (item.aliments.length > 1 ? t('items', {ns: 'food'}) : t('item', {ns: 'food'}))}</p>
                                        <Modal cancelAction={props.rollbackDayLunchAliment} content={state.dayLunchsId === item.id ? <AddMeal customer={props.customer} ymdMealDate={props.ymdMealDate} existingLunch={item} userGender={props.userGender} dayLunchsId={state.dayLunchsId}></AddMeal> : ''}>
                                            <div onclick={() => actions.setDayLunchId(item.id)}><img src={icoEdit} srcset={`${icoEditx2} 2x`} alt='' class='' /></div>
                                        </Modal>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>;
            })}

            <div style={{ 'margin-top': '30px' }}>
                <Modal content={<div key={'reseting-toggle-' + state.resetingModal}><AddMeal customer={props.customer} userGender={props.userGender} ymdMealDate={props.ymdMealDate} resetLunchAliments={true} existingLunch={[]}></AddMeal></div>} style={{ 'display': 'inline-block' }}>
                    <Button primary flat active onclick={actions.resetAddMealPopup}>
                        {t('Ajouter un repas', {ns: 'food'})}
                    </Button>
                </Modal>
            </div>

            {(state.dataRising !== null) && (state.dataRising.length > 0) &&
                <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap', 'margin-top': '50px' }}>
                    <Tile.Icon>
                        <Avatar.Alfred classes={'centered'} />
                    </Tile.Icon>
                    <Tile.Content>
                        <Balloon kind={'info'} style={{ 'white-space': 'normal' }}>
                            {state.dataRising[0].data.description}
                        </Balloon>
                    </Tile.Content>
                </Tile>
            }
        </div>

        <div class='col-4 col-sm-12' style={{ 'vertical-align': 'top' }}>
            <Card kind={'meal-daymeals'} classes={'btzPageCard btzPageCard-btzPageCardNoPadding'}>
                <Card.Body>
                    <div class='col-12 btzInformationsSurvey' style={{ 'white-space': 'nowrap', 'padding-left': '0px' }}>
                        <div class='col-10' style={{ 'vertical-align': 'middle', 'padding-left': '0px' }}>
                            <p class='btzPageContentTitle'>{t('Équilibre alimentaire', {ns: 'food'})}</p>
                            <p class='btzPageContentSubTitle'>{t('À comparer aux recommandations', {ns: 'food'})}</p>
                        </div>
                        <div class='col-2 p-0' style={{ 'vertical-align': 'middle' }}>
                            <Modal style={{ 'display': 'inline-block' }} content={<ModalViewBalance></ModalViewBalance>}>
                                <div class='btzInformationsBalance-btzImage'><img src={icoInfo} srcset={`${icoInfox2} 2x`} alt='' /></div>
                            </Modal>
                        </div>
                    </div>

                    {((state.balanceLunchs !== null) && (((state.balanceLunchs.glucides !== null) && (state.balanceLunchs.glucides !== undefined)) || ((state.balanceLunchs.lipides !== null) && (state.balanceLunchs.lipides !== undefined)) || ((state.balanceLunchs.proteines !== null) && (state.balanceLunchs.proteines !== undefined)))) ?
                        <div class='btzFoodCompare'>
                            <div class='col-12 btzFoodSurvey-btzJaugeContent p-0' style={{ 'position': 'relative', 'vertical-align': 'middle' }}>
                                <p class='btzPageContentTexte'>{t('Glucides', {ns: 'food'})}</p>
                                <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzTick'></div>
                                <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzPercent-btzArrow' style={{ 'left': state.balanceLunchs.glucides.percent + '%' }}></div>
                                <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzPercent-btzLeftLimit' style={{ 'left': state.balanceLunchs.glucides.values.low + '%' }}></div>
                                <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzPercent-btzRightLimit' style={{ 'left': state.balanceLunchs.glucides.values.high + '%' }}></div>
                                <div class='btzFoodSurvey-btzJaugeContent-btzJauge'>
                                    {(state.balanceLunchs.glucides.percent < state.balanceLunchs.glucides.values.low) &&
                                        <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzPercent-btzLowest' style={{ 'left': state.balanceLunchs.glucides.percent + '%', 'width': (state.balanceLunchs.glucides.values.low - state.balanceLunchs.glucides.percent) + '%' }}></div>
                                    }
                                    {(state.balanceLunchs.glucides.percent > state.balanceLunchs.glucides.values.high) &&
                                        <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzPercent-btzHighest' style={{ 'left': state.balanceLunchs.glucides.values.high + '%', 'width': (state.balanceLunchs.glucides.percent - state.balanceLunchs.glucides.values.high) + '%' }}></div>
                                    }
                                    {((state.balanceLunchs.glucides.percent > state.balanceLunchs.glucides.values.low) && (state.balanceLunchs.glucides.percent < state.balanceLunchs.glucides.values.high)) &&
                                        <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzPercent-btzMiddle' style={{ 'left': state.balanceLunchs.glucides.values.low + '%', 'width': (state.balanceLunchs.glucides.percent - state.balanceLunchs.glucides.values.low) + '%' }}></div>
                                    }
                                    <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzPercent-btzMask'>
                                        <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzPercent' style={{ 'left': state.balanceLunchs.glucides.percent + '%' }}></div>
                                    </div>
                                </div>
                            </div>
                            <div class='col-12 btzFoodSurvey-btzJaugeContent p-0' style={{ 'position': 'relative', 'vertical-align': 'middle' }}>
                                <p class='btzPageContentTexte'>{t('Lipides', {ns: 'food'})}</p>
                                <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzTick'></div>
                                <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzPercent-btzArrow' style={{ 'left': state.balanceLunchs.lipides.percent + '%' }}></div>
                                <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzPercent-btzLeftLimit' style={{ 'left': state.balanceLunchs.lipides.values.low + '%' }}></div>
                                <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzPercent-btzRightLimit' style={{ 'left': state.balanceLunchs.lipides.values.high + '%' }}></div>
                                <div class='btzFoodSurvey-btzJaugeContent-btzJauge'>
                                    {(state.balanceLunchs.lipides.percent < state.balanceLunchs.lipides.values.low) &&
                                        <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzPercent-btzLowest' style={{ 'left': state.balanceLunchs.lipides.percent + '%', 'width': (state.balanceLunchs.lipides.values.low - state.balanceLunchs.lipides.percent) + '%' }}></div>
                                    }
                                    {(state.balanceLunchs.lipides.percent > state.balanceLunchs.lipides.values.high) &&
                                        <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzPercent-btzHighest' style={{ 'left': state.balanceLunchs.lipides.values.high + '%', 'width': (state.balanceLunchs.lipides.percent - state.balanceLunchs.lipides.values.high) + '%' }}></div>
                                    }
                                    {((state.balanceLunchs.lipides.percent > state.balanceLunchs.lipides.values.low) && (state.balanceLunchs.lipides.percent < state.balanceLunchs.lipides.values.high)) &&
                                        <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzPercent-btzMiddle' style={{ 'left': state.balanceLunchs.lipides.values.low + '%', 'width': (state.balanceLunchs.lipides.percent - state.balanceLunchs.lipides.values.low) + '%' }}></div>
                                    }
                                    <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzPercent-btzMask'>
                                        <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzPercent' style={{ 'left': state.balanceLunchs.lipides.percent + '%' }}></div>
                                    </div>
                                </div>
                            </div>
                            <div class='col-12 btzFoodSurvey-btzJaugeContent p-0' style={{ 'position': 'relative', 'vertical-align': 'middle' }}>
                                <p class='btzPageContentTexte'>{t('Protéines', {ns: 'food'})}</p>
                                <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzTick'></div>
                                <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzPercent-btzArrow' style={{ 'left': state.balanceLunchs.proteines.percent + '%' }}></div>
                                <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzPercent-btzLeftLimit' style={{ 'left': state.balanceLunchs.proteines.values.low + '%' }}></div>
                                <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzPercent-btzRightLimit' style={{ 'left': state.balanceLunchs.proteines.values.high + '%' }}></div>
                                <div class='btzFoodSurvey-btzJaugeContent-btzJauge'>
                                    {(state.balanceLunchs.proteines.percent < state.balanceLunchs.proteines.values.low) &&
                                        <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzPercent-btzLowest' style={{ 'left': state.balanceLunchs.proteines.percent + '%', 'width': (state.balanceLunchs.proteines.values.low - state.balanceLunchs.proteines.percent) + '%' }}></div>
                                    }
                                    {(state.balanceLunchs.proteines.percent > state.balanceLunchs.proteines.values.high) &&
                                        <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzPercent-btzHighest' style={{ 'left': state.balanceLunchs.proteines.values.high + '%', 'width': (state.balanceLunchs.proteines.percent - state.balanceLunchs.proteines.values.high) + '%' }}></div>
                                    }
                                    {((state.balanceLunchs.proteines.percent > state.balanceLunchs.proteines.values.low) && (state.balanceLunchs.proteines.percent < state.balanceLunchs.proteines.values.high)) &&
                                        <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzPercent-btzMiddle' style={{ 'left': state.balanceLunchs.proteines.values.low + '%', 'width': (state.balanceLunchs.proteines.percent - state.balanceLunchs.proteines.values.low) + '%' }}></div>
                                    }
                                    <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzPercent-btzMask'>
                                        <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzPercent' style={{ 'left': state.balanceLunchs.proteines.percent + '%' }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        /*<div style={{ 'margin-top': '30px' }}>
                            <p class='btzPageContentTexte'>{t('Vous', {ns: 'food'})}</p>
                            <div class='btzFoodBalance' style={{ 'height': '30px' }}>
                                <div class='btzFoodBalance-btzDataviz' data-num={state.balanceLunchs.glucides} style={{ 'width': state.balanceLunchs.glucides.percent + '%' }}><p>{state.balanceLunchs.glucides.percent}%</p></div>
                                <div class='btzFoodBalance-btzDataviz' data-num={state.balanceLunchs.lipides} style={{ 'width': state.balanceLunchs.lipides.percent + '%' }}><p>{state.balanceLunchs.lipides.percent}%</p></div>
                                <div class='btzFoodBalance-btzDataviz' data-num={state.balanceLunchs.proteines} style={{ 'width': state.balanceLunchs.proteines.percent + '%' }}><p>{state.balanceLunchs.proteines.percent}%</p></div>
                            </div>

                            <div class='btzFoodBalance-btzFoodBalanceLink' style={{ 'text-align': 'left', 'white-space': 'nowrap' }}>
                                <svg x='0' y='0' height='40px' width='100%' viewBox={'0 0 100 50'} preserveAspectRatio='none'>
                                    <path class='btzFoodBalance-btzDataviz-btzTrapezoid' d={'M0,0 L' + state.balanceLunchs.glucides.percent + ',0 L50,50 L0,50z'} />
                                    <path class='btzFoodBalance-btzDataviz-btzTrapezoid' d={'M' + state.balanceLunchs.glucides.percent + ',0 L' + (state.balanceLunchs.glucides.percent + state.balanceLunchs.lipides.percent) + ',0 L85,50 L50,50z'} />
                                    <path class='btzFoodBalance-btzDataviz-btzTrapezoid' d={'M' + (state.balanceLunchs.glucides.percent + state.balanceLunchs.lipides.percent) + ',0 L' + (state.balanceLunchs.glucides.percent + state.balanceLunchs.lipides.percent + state.balanceLunchs.proteines.percent) + ',0 L100,50 L85,50z'} />
                                </svg>
                            </div>

                            <div class='btzFoodBalance'>
                                <div class='btzFoodBalance-btzDataviz btzFoodBalance-btzDataviz-btzLegend' style={{ 'width': '50%' }}><p>50%</p></div>
                                <div class='btzFoodBalance-btzDataviz btzFoodBalance-btzDataviz-btzLegend' style={{ 'width': '35%' }}><p>35%</p></div>
                                <div class='btzFoodBalance-btzDataviz btzFoodBalance-btzDataviz-btzLegend' style={{ 'width': '15%' }}><p>15%</p></div>
                            </div>
                            <p class='btzPageContentTexte' style={{ 'font-size': '13px' }}>{t('Recommandé', {ns: 'food'})}</p>
                        </div>*/
                        :
                        <p class='' style={{ 'margin-top': '25px' }}>
                            {t('Nous n‘avons pas réussis à récupérer votre équilibre alimentaire.', {ns: 'food'})}
                        </p>
                    }

                    {/*<div class='btzFoodBalance-btzLegendes'>
                        <div class='col-4' style={{ 'padding-left': '0px' }}>
                            <div class='btzFoodBalance-btzLegende'>
                                <div class='btzFoodBalance-btzLegende-btzPuce' data-kind='glucides'></div>
                                <p class='btzAboveTitle'>{t('Glucides', {ns: 'food'})}</p>
                            </div>
                        </div>
                        <div class='col-4'>
                            <div class='btzFoodBalance-btzLegende'>
                                <div class='btzFoodBalance-btzLegende-btzPuce' data-kind='lipides'></div>
                                <p class='btzAboveTitle'>{t('Lipides', {ns: 'food'})}</p>
                            </div>
                        </div>
                        <div class='col-4' style={{ 'padding-left': '0px' }}>
                            <div class='btzFoodBalance-btzLegende'>
                                <div class='btzFoodBalance-btzLegende-btzPuce' data-kind='proteines'></div>
                                <p class='btzAboveTitle'>{t('Protéines', {ns: 'food'})}</p>
                            </div>
                        </div>
                    </div>*/}
                </Card.Body>
            </Card>
        </div>

        <div class='col-4 col-sm-12' style={{ 'vertical-align': 'top' }}>
            <Card kind={'meal-daymeals'} classes={'btzPageCard btzPageCard-btzPageCardNoPadding'}>
                <Card.Body>
                    <div class='col-12 btzInformationsSurvey' style={{ 'white-space': 'nowrap', 'padding-left': '0px' }}>
                        <div class='col-10' style={{ 'vertical-align': 'middle', 'padding-left': '0px' }}>
                            <p class='btzPageContentTitle'>{t('Aliments à surveiller', {ns: 'food'})}</p>
                            <p class='btzPageContentSubTitle'>{t('Consommation sur la journée', {ns: 'food'})}</p>
                        </div>
                        <div class='col-2 p-0' style={{ 'vertical-align': 'middle' }}>
                            <Modal style={{ 'display': 'inline-block' }} content={<ModalViewSurvey></ModalViewSurvey>}>
                                <div class='btzInformationsSurvey-btzImage'><img src={icoInfo} srcset={`${icoInfox2} 2x`} alt='' /></div>
                            </Modal>
                        </div>
                    </div>

                    {state.surveyLunchs !== null &&
                        <div style={{ 'margin-top': '15px' }}>
                            <div class='btzFoodSurvey'>
                                <div class='col-8 btzFoodSurvey-btzJaugeContent p-0' style={{ 'position': 'relative', 'vertical-align': 'middle' }}>
                                    <p class='btzPageContentTexte'>{t('Aliments gras', {ns: 'food'})}</p>
                                    <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzTick'></div>
                                    <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzPercent-btzArrow' style={{ 'left': state.surveyLunchs.saturated.percent + '%' }}></div>
                                    <div class='btzFoodSurvey-btzJaugeContent-btzJauge'>
                                        <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzPercent-btzMask'>
                                            <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzPercent' style={{ 'left': state.surveyLunchs.saturated.percent + '%' }}></div>
                                        </div>
                                    </div>
                                </div>
                                <div class='col-4 btzFoodSurvey-btzJaugeImages' style={{ 'padding-right': '0px', 'vertical-align': 'middle' }}>
                                    {state.surveyLunchs.saturated.percent >= state.surveyLunchs.percentToNotReach ?
                                        <img src={icoFatKO} srcset={`${icoFatKOx2} 2x`} alt='' class='' />
                                        :
                                        <img src={icoFatOK} srcset={`${icoFatOKx2} 2x`} alt='' class='' />
                                    }
                                </div>
                            </div>

                            <div class='btzFoodSurvey'>
                                <div class='col-8 btzFoodSurvey-btzJaugeContent p-0' style={{ 'position': 'relative', 'vertical-align': 'middle' }}>
                                    <p class='btzPageContentTexte'>{t('Aliments sucrés', {ns: 'food'})}</p>
                                    <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzTick'></div>
                                    <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzPercent-btzArrow' style={{ 'left': state.surveyLunchs.sugar.percent + '%' }}></div>
                                    <div class='btzFoodSurvey-btzJaugeContent-btzJauge'>
                                        <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzPercent-btzMask'>
                                            <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzPercent' style={{ 'left': state.surveyLunchs.sugar.percent + '%' }}></div>
                                        </div>
                                    </div>
                                </div>
                                <div class='col-4 btzFoodSurvey-btzJaugeImages' style={{ 'padding-right': '0px', 'vertical-align': 'middle' }}>
                                    {state.surveyLunchs.sugar.percent >= state.surveyLunchs.percentToNotReach ?
                                        <img src={icoSugarKO} srcset={`${icoSugarKOx2} 2x`} alt='' class='' />
                                        :
                                        <img src={icoSugarOK} srcset={`${icoSugarOKx2} 2x`} alt='' class='' />
                                    }
                                </div>
                            </div>

                            <div class='btzFoodSurvey'>
                                <div class='col-8 btzFoodSurvey-btzJaugeContent p-0' style={{ 'position': 'relative', 'vertical-align': 'middle' }}>
                                    <p class='btzPageContentTexte'>{t('Aliments salés', {ns: 'food'})}</p>
                                    <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzTick'></div>
                                    <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzPercent-btzArrow' style={{ 'left': state.surveyLunchs.salt.percent + '%' }}></div>
                                    <div class='btzFoodSurvey-btzJaugeContent-btzJauge'>
                                        <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzPercent-btzMask'>
                                            <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzPercent' style={{ 'left': state.surveyLunchs.salt.percent + '%' }}></div>
                                        </div>
                                    </div>
                                </div>
                                <div class='col-4 btzFoodSurvey-btzJaugeImages' style={{ 'padding-right': '0px', 'vertical-align': 'middle' }}>
                                    {state.surveyLunchs.salt.percent >= state.surveyLunchs.percentToNotReach ?
                                        <img src={icoSaltKO} srcset={`${icoSaltKOx2} 2x`} alt='' class='' />
                                        :
                                        <img src={icoSaltOK} srcset={`${icoSaltOKx2} 2x`} alt='' class='' />
                                    }
                                </div>
                            </div>

                            <div class='btzFoodSurvey'>
                                <div class='col-8 btzFoodSurvey-btzJaugeContent p-0' style={{ 'position': 'relative', 'vertical-align': 'middle' }}>
                                    <p class='btzPageContentTexte'>{t('Alcool', {ns: 'food'})}</p>
                                    <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzTick'></div>
                                    <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzPercent-btzArrow' style={{ 'left': state.surveyLunchs.alcool.percent + '%' }}></div>
                                    <div class='btzFoodSurvey-btzJaugeContent-btzJauge'>
                                        <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzPercent-btzMask'>
                                            <div class='btzFoodSurvey-btzJaugeContent-btzJauge-btzPercent' style={{ 'left': state.surveyLunchs.alcool.percent + '%' }}></div>
                                        </div>
                                    </div>
                                </div>
                                <div class='col-4 btzFoodSurvey-btzJaugeImages' style={{ 'padding-right': '0px', 'vertical-align': 'middle' }}>
                                    {state.surveyLunchs.alcool.percent >= state.surveyLunchs.percentToNotReach ?
                                        <img src={icoAlcoolKO} srcset={`${icoAlcoolKOx2} 2x`} alt='' class='' />
                                        :
                                        <img src={icoAlcoolOK} srcset={`${icoAlcoolOKx2} 2x`} alt='' class='' />
                                    }
                                </div>
                            </div>
                        </div>
                    }

                    <Modal style={{ 'display': 'inline-block' }} content={<ModalViewAliments customer={props.customer} key={'showaliement-' + state.readableMealDate} readableMealDate={state.readableMealDate} compositionsAliments={state.parsedCompositionDayLunchs} surveyLunchs={state.surveyLunchs} userMetrics={props.userMetrics} userGender={props.userGender}></ModalViewAliments>}>
                        <Button primary flat colored onclick={() => actions.getRepartitionByDate(props.ymdMealDate)}>
                            {t('Voir les aliments', {ns: 'food'})}
                        </Button>
                    </Modal>
                </Card.Body>
            </Card>
        </div>
    </div>
);