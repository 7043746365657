/*
Settings that are responsible for application' configuration,
and can be overriden per item basis by using the corresponding
variable environment, e.g.:
`API_URL="https://domain.tld" yarn start`
*/

export { dynLoad } from './dyn-load';
export { dynMod } from './dyn-mod';
//
export { $ } from './tools/$';
export { getClosest } from './tools/getClosest';
export { getMinHourFromMinutes } from './tools/getMinHourFromMinutes';
export { getQueryVariable } from './tools/getQueryVariable';
export { tryToLoginAndRedirect } from './tools/tryToLoginAndRedirect';
export { convertDateToUTC } from './tools/convertDateToUTC';
export { humanizeDate } from './tools/humanizeDate';
export { createCookie, readCookie, eraseCookie } from './tools/cookie';
export { scrollTo } from './tools/scrollTo';
export { isDefined } from './tools/isDefined';
export { guid } from './tools/guid';
export { uuidv4 } from './tools/uuidv4';
export { isEmail } from './tools/isEmail';
export { deepCopy } from './tools/deepCopy';
export { convertMetrics, convertMetricsLabels } from './tools/convertMetrics';
export { getUser, tryToGetUser } from './tools/getUser';
export { setUser } from './tools/setUser';
export { parseToPercent } from './tools/parseToPercent';
export { capitalize } from './tools/capitalize';
export { compareByKey } from './tools/compareByKey';
export { trimSpecialChar } from './tools/trimSpecialChar';
export { isFloat, isInteger, castToNumberOfFloat } from './tools/number';
export { isPublicRoute } from './tools/isPublicRoute';
export { isMobile } from './tools/isMobile';
export { isMobileOrTablet } from './tools/isMobileOrTablet';
export { isTestMode } from './tools/isTestMode';
export { redirectToWebOrApp } from './tools/redirectToWebOrApp';
export { sortStringNumber } from './tools/sortStringNumber';
export { addCSRFToken, checkForValidCSRFToken, createAndSaveCSRFToken } from './tools/csrf';
export { getDateObjFromYMD } from './tools/getDateObjFromYMD';
export { getYMDfromDateObj } from './tools/getYMDfromDateObj';
export { getTodayYMD } from './tools/getTodayYMD';
export { getCustomerInfos } from './tools/getCustomerInfos';
export { logoutAndRedirect, idleLogout } from './tools/logoutAndRedirect';
export { createUTCDate } from './tools/createUTCDate';
export { convertObjectToArray } from './tools/convertObjectToArray';
export { displayErrors } from './tools/displayErrors';
//
export { redirectToOnboarding } from './special_tools';
//
// export { allRoutes } from './routes';