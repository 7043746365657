import { h, classMerge } from '@app/utils';

/**
 * Navigation bar
 * @see https://picturepan2.github.io/spectre/layout.html#navbar
 * @param {any} props All properties passed to element
 * @param {any} props.classes All CSS classes extending the element
 * @param {any} children All children elements to be nested inside
 * @return vNode
 */
const Navbar = (props, children) => {
    const {
        classes,
        ...otherProps
    } = props;

    const allClasses = classMerge([
        'navbar',
        classes,
    ]);

    return (
        <header class={allClasses} {...otherProps}>
            {children}
        </header>
    );
};

/**
 * Section of navigation bar, can be centered
 * @see https://picturepan2.github.io/spectre/layout.html#navbar
 * @param {any} props All properties passed to element
 * @param {boolean} props.center Center section, false by default
 * @param {any} props.classes All CSS classes extending the element
 * @param {any} children All children elements to be nested inside
 * @return vNode
 */
const Section = (props, children) => {
    const {
        center = false,
        classes,
        ...otherProps
    } = props;

    const allClasses = classMerge([
        center ? 'navbar-center' : 'navbar-section',
        classes,
    ]);

    return (
        <section class={allClasses} {...otherProps}>
            {children}
        </section>
    );
};

Navbar.Section = Section;

export { Navbar };
