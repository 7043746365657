import { t } from '@app/utils';
import { Data } from '@app/api';
import { isDefined, getQueryVariable, getMinHourFromMinutes, convertMetricsLabels, convertMetrics, capitalize, compareByKey, scrollTo, castToNumberOfFloat, dynLoad } from '@app/core';
import * as d3 from 'd3';
import * as constants from '@app/constants';


export const ico = dynLoad('ico', 'Datas/dashboard/custo');
const icoAddData = ico.ICO.ADD_DATAS.normal;

Date.prototype.yyyymmdd = function() {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [this.getFullYear(),
        (mm>9 ? '' : '0') + mm,
        (dd>9 ? '' : '0') + dd
    ].join('');
};

export const actions = {
    onComponentCreate: (props) => (state, actions) => {
        if (props.customer) {
            state.customer = props.customer;
        }
        if (props.metrics) {
            state.metrics = props.metrics;
        }
        if (props.data) {
            state.data = props.data;
        }
        if (props.mode) {
            state.mode = props.mode;
        }
        if (props.kind) {
            state.kind = props.kind;
        }
        if (props.queryKind) {
            state.queryKind = props.queryKind;
        }
        if (props.defaultSelected) {
            state.defaultSelected = props.defaultSelected;
        }
        if (props.today) {
            state.today = props.today;
        }
        if (props.keyprimary) {
            state.keyprimary = props.keyprimary;
        }
        if (props.convertTo) {
            state.convertTo = props.convertTo;
        }
        if (props.yAxisUnit) {
            state.yAxisUnit = props.yAxisUnit;
        }
        if (props.variationsInverted) {
            state.variationsInverted = props.variationsInverted;
        }
        if (props.defaultAxis) {
            state.defaultAxis = props.defaultAxis;
        }
        if (props.extremAndMeanConfig) {
            state.extremAndMeanConfig = props.extremAndMeanConfig;
        }
        if (props.nShowMore) {
            state.nShowMore = props.nShowMore;
        }
        if (props.ydomainOffset) {
            state.ydomainOffset = props.ydomainOffset;
        }
        if (props.userSettings) {
            actions.setUserSettings(props.userSettings);
        }
        if (props.datarised) {
            if (props.datarised[0] !== undefined) {
                state.risedData = props.datarised[0].data.description;
            }
        }
        if (props.particularHeight) {
            actions.updateParticularHeight(props.particularHeight);
        }

        if (props.userTracker) {
            actions.parseTrackers(props.userTracker);
        }

        let trg = getQueryVariable('theme', false);
        if (trg === props.kind) {
            let todayValue = '';
            if (isDefined(props.data[props.defaultSelected]) && isDefined(props.data[props.defaultSelected][props.keyprimary[0]])) {
                todayValue = (props.data[props.defaultSelected][props.keyprimary[0]].current || props.data[props.defaultSelected][props.keyprimary[0]].total);
            }
            actions.displayQuestion(todayValue);
        }

        if (props.ydomain) {
            if (props.ydomain === 'dyn') {
                if (props.ydomainMin === 'dyn') {
                    actions.setDynYDomainMin(true);
                }
                if (props.ydomainMax === 'dyn') {
                    actions.setDynYDomainMax(true);
                }
            } else {
                actions.setYDomainMax(props.ydomain);
            }
        }
        if (props.type) {
            actions.setType(props.type);
        }
        if (props.mode) {
            actions.setModeLabel(props.mode);
            actions.setModeLabelPrefixed(props.mode);
        }

        if (props.locales && (props.locales !== null)) {
            d3.timeFormatDefaultLocale(props.locales);
        }
        actions.setXAxis(d3.scaleTime());
        actions.setYAxis(d3.scaleLinear());

        if (props.mode) {
            actions.setThemeInformationsFromTheme(props.kind);
        }

        if (props.kind == 'sport') {
            window.addEventListener('updateUserSettings', (ev) => {
                actions.updateUserSettings(ev.detail.newSettings);
            }, false);
        }
    },
    onComponentUpdate: (props) => (state, actions) => {
        if (props.data) {
            state.data = props.data;
        }
        if (props.mode) {
            state.mode = props.mode;
        }
        if (props.kind) {
            state.kind = props.kind;
        }
        if (props.defaultSelected) {
            state.defaultSelected = props.defaultSelected;
        }
        if (props.particularHeight) {
            actions.updateParticularHeight(props.particularHeight);
        }

        if (!state.updating) {
            actions.setUpdating(true);
            actions.updateDataViz();
        }
        actions.setModeLabel(state.mode);
        actions.setModeLabelPrefixed(state.mode);

        if (props.mode) {
            actions.setThemeInformationsFromTheme(props.kind);
        }

    },
    setUserSettings: (userSettings) => (state, actions) => {
        let dynamicSportsSetting = [];
        for (var sett in userSettings) {
            sett = userSettings[sett];
            if ((sett.name.indexOf('SPORT_') > -1) && (sett.value.indexOf('ACTIVITY_') > -1)) {
                dynamicSportsSetting.push({name: sett.name, value: sett.value});
            }
        }
        actions.setDynamicSportsSetting(dynamicSportsSetting);
    },
    updateUserSettings: (newSettings) => (state, actions) => {
        let dynamicSportsSetting = state.dynamicSportsSetting;
        dynamicSportsSetting.push(newSettings)
        actions.setDynamicSportsSetting(dynamicSportsSetting);
    },
    sendUniqThemeQueryAndUpdateDataViz: (props) => (state, actions) => {
        Data.themeQuery(props.queryTheme, props.date, state.mode).then(function(res) {
            state.data = res.data.interval;
            actions.setData(res.data.interval);
            actions.updateDataViz();
        });
    },
    createDataViz: (props) => (state, actions) => {
        var rootContainer = null, svg = null;
        actions.setRootContainerJS(props);
        svg = d3.select(props);
        var root = svg.append('g');
        root.attr('class', 'root-container')
            .attr('transform', 'translate(0, 0)');

        var availableWidth = props.parentNode.offsetWidth;
        var availableHeight = props.parentNode.offsetHeight;

        if (availableWidth <= 414) {
            state.padding.left = 40;
            state.margin.left = 25;
        }

        actions.setDataVizWidth(availableWidth);
        actions.setDataVizHeight(availableHeight);

        let maxXRange = (availableWidth - state.padding.left - (2 * state.margin.left));
        if (availableWidth <= 414) {
            maxXRange = (availableWidth - (state.padding.left * 1));
        }
        state.xAxis.range([state.padding.left, maxXRange]);
        state.yAxis.range([(availableHeight - state.padding.bottom - state.xLabelHeight), 0]);

        var rectBg = root.append('g').attr('class', 'rectBg');
        actions.setRootContainer(root);
        actions.setBgContainer(rectBg);
        rootContainer = root;

        var g = rootContainer.append('g')
            .attr('transform', 'translate(0, 0)');

        var x = g.append('g')
            .attr('class', 'axis axis--x')
            .attr('transform', 'translate(0, ' + (availableHeight - state.xLabelHeight) +')');
        actions.setX(x);

        var subx = g.append('g')
            .attr('class', 'axis sub-axis sub-axis--x')
            .attr('transform', 'translate(0, ' + (availableHeight - state.xLabelHeight + 10) +')');
        actions.setSubX(subx);

        var y = g.append('g')
            .attr('class', 'axis axis--y');
        actions.setY(y);

        var valueLine = d3.line()
            .defined(function(d) {
                return ((d[0].value !== null) && (d[0].value != 'null'));
            })
            .x(function(d) { return state.xAxis(d[0].date); })
            .y(function(d) { return state.yAxis(d[0].value); });

        actions.setPathLine(rootContainer.append('path'));
        actions.setValueLine(valueLine);

        if (!state.updating) {
            actions.setUpdating(true);
            actions.updateDataViz();
        }
    },
    updateParticularHeight: newState => state => ({
        particularHeight: newState,
    }),
    updateDataViz: () => (state, actions) => {
        var parseTime = d3.timeParse('%Y%m%d');
        if ((state.data === null) || (state.data == 'null')) {
            return false;
        }

        var data = {}, dashData = [], tempDayData = {};
        var yearQualitySleep = [];
        for (var day in state.data) {
            for (var kpi in state.data[day]) {
                if (state.keyprimary.indexOf(kpi) > -1) {
                    if (tempDayData[day] === undefined) {
                        tempDayData[day] = [];
                    }
                    data = {};
                    data.kpi = kpi;
                    data.label = kpi.replace('ACTIVITY_', '');
                    data.date = day;
                    data.min = castToNumberOfFloat(state.data[day][kpi].min);
                    data.max = castToNumberOfFloat(state.data[day][kpi].max);
                    data.mean = castToNumberOfFloat(state.data[day][kpi].mean);
                    data.total = castToNumberOfFloat(state.data[day][kpi].total);

                    if (state.data[day][kpi].mean === null) {
                        data.value = null;
                    } else if (state.toDisplayAsMean.indexOf(state.kind) > -1) {
                        if ((data.mean !== undefined) && (data.mean !== null)) {
                            data.value = Math.round(data.mean * 10) / 10;
                        } else {
                            data.value = null;
                        }
                    } else if (state.toDisplayAsTotal.indexOf(state.kind) > -1) {
                        if ((data.total !== undefined) && (data.total !== null)) {
                            data.value = Math.round(data.total * 10) / 10;
                        } else {
                            data.value = null;
                        }
                    } else {
                        data.value = null;
                    }

                    if ((data.value === undefined) || (data.value === null)) {
                        data.value = data.total;
                    }

                    if (state.data[day][kpi].diffYesterday === undefined) {
                        data.diffYesterday = {
                            date: day,
                            diff: null,
                        };
                    } else {
                        data.diffYesterday = state.data[day][kpi].diffYesterday;
                    }
                    if (state.data[day][kpi].diffWeekAgo === undefined) {
                        data.diffWeekAgo = {
                            date: day,
                            diff: null,
                        };
                    } else {
                        data.diffWeekAgo = state.data[day][kpi].diffWeekAgo;
                    }

                    tempDayData[day].push(data);
                } else if ((state.kind === 'sleep') && (state.mode === 'year') && (kpi.toLowerCase().indexOf('quality') > -1)) {
                    if (state.data[day][kpi].count > 0) {
                        for (var i = 0; i < state.data[day][kpi].count; i++ ) {
                            yearQualitySleep.push(+kpi.replace('SLEEP_QUALITY_', ''));
                        }
                    }
                    state.yearQualitySleep = yearQualitySleep;
                }
            }
        }
        for (var dday in tempDayData) {
            dashData.push(tempDayData[dday]);
        }
        if (state.kind == 'sport') {
            for (var dashDat in dashData) {
                dashData[dashDat].sort(compareByKey('origvalue'));
            }
        }
        state.data = dashData;

        // parse data date and value
        var tmpDate = null;
        dashData.forEach(function(d) {
            d.forEach(function(dd) {
                tmpDate = dd.date;
                if (tmpDate.indexOf('MONTH') > -1) {
                    tmpDate = (tmpDate.replace('MONTH', '') + '01');
                }
                dd.key = tmpDate;
                dd.date = parseTime(tmpDate);
                dd.origvalue = dd.value;
                if (state.kind === 'followed-weight') {
                    dd.value = convertMetrics('weight', '', (state.metrics !== null ? state.metrics.weight : 0), dd.value);
                } else if (state.kind === 'waistsize') {
                    dd.value = convertMetrics('height', '', (state.metrics !== null ? state.metrics.height : 0), dd.value);
                }
            })
        });

        if (state.kind === 'sleep') {
            actions.updateQualitySleep();
        }
        actions.updateVariations();
        actions.updateAxis();

        if (state.type === 'linechart') {
            actions.updateLineChart();
            actions.updateAddDataButtons('g.circles');
        } else if (state.type === 'groupedbarchart') {
            actions.updateGroupedBarChart();
            actions.updateAddDataButtons('g.circles');
        } else if (state.type === 'barchart') {
            actions.updateBarChart();
            actions.updateAddDataButtons('g.circles');
        }

        let obj = null;
        if (state.mode === 'year') {
            obj = state.rootContainerJS.querySelectorAll('.bgrectangles.bgrectangles-' + +state.defaultSelected.substring(0, 6) + '01');
        } else {
            obj = state.rootContainerJS.querySelectorAll('.bgrectangles.bgrectangles-' + +state.defaultSelected);
        }
        if (obj && (obj !== undefined)) {
            if (obj.length > 0) {
                obj = obj[0];
            }
            obj = obj.parentNode;
            if (obj && (obj !== undefined)) {
                actions.highLightThisDay({data: {key: +state.defaultSelected}, obj: obj});
            }
        }

        actions.setReadableDate(actions.getPeriodLabel(dashData));
        setTimeout(function() {
            actions.setUpdating(false);
        }, 1000);
    },
    updateLineChart: () => (state, actions) => {
        var rootContainer = state.rootContainer;
        // circle group
        var grp = rootContainer.selectAll('g.circles')
            .data(state.data);
        grp.exit().remove();
        grp.enter()
            .append('g')
            .attr('class', 'circles')
            .merge(grp)
            .transition()
            .duration(400)
            .attr('transform', function(d) {
                if ((d[0].value === null) || (d[0].value === 'null')) {
                    return 'translate(' + state.xAxis(d[0].date) + ', ' + (state.height / 2) + ')'
                }
                return 'translate(' + state.xAxis(d[0].date) + ', ' + state.yAxis(d[0].value) + ')'
            });
        grp = rootContainer.selectAll('g.circles')
        grp.attr('class', function(d) {
            if (+d[0].key > +state.today) {
                return 'circles noadddata';
            } else if ((d[0].value === null) || (d[0].value === 'null')) {
                return 'circles adddata';
            }
            return 'circles';
        })
            .on('mousedown', function(d) {
                d3.event.stopPropagation();
                if (+d[0].key > +state.today) {
                    return false;
                }
                if (state.kind === 'followed-weight') {
                    actions.displayQuestion([d[0].value, state.particularHeight]);
                } else if (state.kind === 'sleep') {
                    let duration = getMinHourFromMinutes(d[0].value), quality = (d[1] !== undefined ? d[1].value : null);
                    actions.displayQuestion([duration, quality]);
                } else if (state.kind === 'sport') {
                    actions.displayQuestion(d);
                } else {
                    actions.displayQuestion(d[0].value);
                }
            });

        actions.updateBgRect();
        // line path
        state.pathLine
            .data([state.data])
            .attr('class', 'line')
            .attr('d', state.valueLine)
            .attr('opacity', '0')
            .transition()
            .duration(400)
            .ease(d3.easeLinear)
            .attr('opacity', '1');
    },
    updateGroupedBarChart: () => (state, actions) => {
        var tmpData = state.data, parseDataDay = [], parseDataKpi = [], valueFound = false;
        for (var day in tmpData) {
            parseDataKpi = [], valueFound = false;
            for (var kpi in tmpData[day]) {
                if (tmpData[day][kpi].value !== null) {
                    valueFound = true;
                    break;
                }
            }

            for (kpi in tmpData[day]) {
                if (tmpData[day][kpi].value !== null) {
                    parseDataKpi.push(tmpData[day][kpi]);
                } else if ((!valueFound) && (parseDataKpi.length < 1)) {
                    parseDataKpi.push({
                        date: tmpData[day][kpi].date,
                        key: tmpData[day][kpi].key,
                    });
                }
            }
            parseDataDay.push(parseDataKpi);
        }
        state.data = parseDataDay;
        var rootContainer = state.rootContainer;

        actions.updateBgRect();

        // circle group
        var grp = rootContainer.selectAll('g.circles')
            .data(state.data);
        grp.exit().remove();
        grp.enter()
            .append('g')
            .attr('class', 'circles')
            .merge(grp)
            .transition()
            .duration(400)
            .attr('transform', function(d) {
                if ((d[0].value === null) || (d[0].value === undefined) || (d[0].value == 'null')) {
                    return 'translate(' + state.xAxis(d[0].date) + ', ' + (state.height / 2) + ')'
                }
                return 'translate(' + state.xAxis(d[0].date) + ', ' + state.yAxis(d[0].value) + ')'
            });
        grp = rootContainer.selectAll('g.circles')
        grp.attr('class', function(d) {
            if (+d[0].key > +state.today) {
                return 'circles noadddata';
            } else if ((d[0].value === null) || (d[0].value === undefined) || (d[0].value == 'null')) {
                return 'circles adddata';
            }
            return 'circles';
        });

        var grpBgRect = rootContainer.selectAll('g.grpbgrectangles');

        var groups = grpBgRect.selectAll('g.objgroup')
            .data(function(d) {
                return d;
            })
            .enter()
            .append('g')
            .attr('class', function() {
                return 'objgroup';
            })
            .attr('transform', function(d, i, j) {
                if (state.mode === 'month') {
                    return 'translate(0, 0)'
                } else if (state.mode === 'year') {
                    return 'translate(0, 0)'
                }
                let bgWidth = ((state.width - state.padding.left - state.margin.left) / state.data.length);
                // return 'translate(' + (bgWidth / j.length) * i + ', 0)';
                let offsetleft = i;
                if (j.length === 1) {
                    offsetleft = 1;
                } else if (j.length === 2) {
                    if (offsetleft === 0) {
                        offsetleft = 0.5;
                    } else if (offsetleft === 1) {
                        offsetleft = 1.5;
                    }
                }
                let dividelength = 3;
                for (var day in state.data) {
                    if ((state.data[day].length > 3) && (state.data[day][0].key === d.key)) {
                        dividelength = state.data[day].length;
                    }
                }
                return 'translate(' + (bgWidth / dividelength) * offsetleft + ', 0)';
            });

        let _y = function(d) {
            if (state.kind === 'sport') {
                return state.yAxis(d.value / 60); // 60 is for minutes
            }
            return state.yAxis(d.value);
        };
        let _w = function(d) {
            let bgWidth = ((state.width - state.padding.left - state.margin.left) / state.data.length) - 4; // 4 is the stroke
            if ((state.mode === 'month') || (state.mode === 'year')) {
                return bgWidth;
            }
            let dividelength = 3;
            for (var day in state.data) {
                if ((state.data[day].length > 3) && (state.data[day][0].key === d.key)) {
                    dividelength = state.data[day].length;
                }
            }
            return (bgWidth / dividelength);
        };
        let _h = function(d) {
            if (state.kind === 'sport') {
                return state.height - state.xLabelHeight - state.yAxis(d.value / 60); // 60 is for minutes
            }
            return state.height - state.xLabelHeight - state.yAxis(d.value);
        };

        groups.append('path')
            .attr('class', function(d) {
                for (var dynamicSportSetting in state.dynamicSportsSetting) {
                    if (state.dynamicSportsSetting[dynamicSportSetting].value === d.kpi) {
                        return 'objgcharts ' + state.dynamicSportsSetting[dynamicSportSetting].name;
                    }
                }
                return 'objgcharts ';
            })
            .style('fill', function(d) {
                let sportData = constants.custo.DASH_SPORTS.filter((dash) => dash.kpi == d.kpi);
                if (sportData && (sportData.length > 0)) {
                    return sportData[0].color;
                }
                return 'grey';
            })
            .attr('d', function(d, i) {
                if (isNaN(_y(d)) || isNaN(_w(d, i)) || isNaN(_h(d))) {
                    return '';
                }
                return actions.rounded_rect({x: 0, y: _y(d), w: _w(d, i), h: _h(d), r: 8, tl: true, tr: true, bl: false, br: false});
            })

        groups.append('text')
            .attr('y', function(d) {
                if (state.mode === 'month') {
                    return 8;
                } else if (state.mode === 'year') {
                    return 17;
                }
                let dividelength = 1;
                for (var day in state.data) {
                    if ((state.data[day].length > 3) && (state.data[day][0].key === d.key)) {
                        dividelength = (state.data[day].length - 2);
                    }
                }
                return 12 / dividelength;
            })
            .attr('dy', '0')
            .attr('dx', '0')
            .attr('x', function() {
                return (state.height - state.xLabelHeight - 10) * -1;
            })
            .attr('transform', 'rotate(-90)')
            .attr('class', function(d) {
                return 'objtcharts ' + d.kpi;
            })
            .text(function(d) {
                if ((d.value === 0) || (d.value === undefined) || (d.value === null)) {
                    return '';
                } else if (state.kind === 'sport') {
                    for (var constSport in state.constSports) {
                        if ((state.constSports[constSport].kpi === d.kpi)
                            || (state.constSports[constSport].datatag === d.kpi)) {
                            return state.constSports[constSport].readable;
                        }
                    }
                }
                return d.label;
            });
    },
    updateBarChart: () => (state, actions) => {
        var tmpData = state.data, parseDataDay = [], parseDataKpi = [], valueFound = false;
        for (var day in tmpData) {
            parseDataKpi = [], valueFound = false;
            for (var kpi in tmpData[day]) {
                if (tmpData[day][kpi].value !== null) {
                    valueFound = true;
                    break;
                }
            }

            for (kpi in tmpData[day]) {
                if (tmpData[day][kpi].value !== null) {
                    tmpData[day][kpi].displayingkey = (tmpData[day][kpi].value / 60);
                    parseDataKpi.push(tmpData[day][kpi]);
                } else if ((!valueFound) && (parseDataKpi.length < 1)) {
                    parseDataKpi.push({
                        date: tmpData[day][kpi].date,
                        key: tmpData[day][kpi].key,
                    });
                }
            }
            parseDataDay.push(parseDataKpi);
        }
        state.data = parseDataDay;
        var rootContainer = state.rootContainer;

        actions.updateBgRect();

        // circle group
        var grp = rootContainer.selectAll('g.circles')
            .data(state.data);
        grp.exit().remove();
        grp.enter()
            .append('g')
            .attr('class', 'circles')
            .merge(grp)
            .transition()
            .duration(400)
            .attr('transform', function(d) {
                if ((d[0].value === null) || (d[0].value === undefined) || (d[0].value == 'null')) {
                    return 'translate(' + state.xAxis(d[0].date) + ', ' + (state.height / 2) + ')';
                }
                return 'translate(' + state.xAxis(d[0].date) + ', ' + state.yAxis(d[0].value) + ')';
            });
        grp = rootContainer.selectAll('g.circles');
        grp.attr('class', function(d) {
            if (+d[0].key > +state.today) {
                return 'circles noadddata';
            } else {
                for (var entry in d) {
                    if ((d[entry].value === null) || (d[entry].value === undefined) || (d[entry].value == 'null')) {
                        return 'circles adddata';
                    }
                }
                return 'circles';
            }
            return 'circles';
        });


        let _x = function() {
            if (state.mode === 'month') {
                return 0;
            }
            return ((refBoundingBox.width / 2) - 16);
        };
        let _y = function(d) {
            return state.yAxis(d.displayingkey);
        };
        let _h = function(d) {
            if (d.displayingkey === undefined) {
                return 0;
            }
            return state.height - state.yAxis(d.displayingkey) - state.xLabelHeight;
        };
        let _w = function() {
            let bgWidth = ((state.width - state.padding.left - state.margin.left) / state.data.length) - 4; // 4 is the stroke
            if (state.mode === 'month') {
                return bgWidth;
            } else if (state.mode === 'year') {
                return (bgWidth / 2);
            }
            return (bgWidth / 3);
        };
        var grpBgRect = rootContainer.selectAll('g.grpbgrectangles');
        var refBoundingBox = grpBgRect.node().getBoundingClientRect();
        grpBgRect.selectAll('rect.objgcharts')
            .data(function(d) {
                let returning = d[0];
                if (d[1] !== undefined) {
                    returning.quality = d[1].value;
                    let tmpValue = d[1].value;
                    if (tmpValue < 10) {
                        if (tmpValue < 7) {
                            if (tmpValue < 4) {
                                tmpValue = 1;
                            } else {
                                tmpValue = 4;
                            }
                        } else {
                            tmpValue = 7;
                        }
                    }
                    returning.qualityLabel = [...state.constQualitySleep[tmpValue]];
                }
                return [returning];
            })
            .enter()
            .append('path')
            .attr('class', function(d) {
                return d.kpi + d.quality + ' objgcharts';
            })
            .attr('d', function(d) {
                if (isNaN(_y(d)) || isNaN(_x(d)) || isNaN(_h(d)) || (d.kpi === undefined) || (d.quality === undefined)) {
                    return '';
                }
                return actions.rounded_rect({x: _x(d), y: _y(d), w: _w(d), h: _h(d), r: 8, tl: true, tr: true, bl: false, br: false});
            });

        grpBgRect.selectAll('text.objtcharts')
            .data(function(d) {
                let returning = d[0];
                if (d[1] !== undefined) {
                    returning.quality = d[1].value;
                }
                return [returning];
            })
            .enter()
            .append('text')
            .attr('y', function() {
                let bgWidth = ((state.width - state.padding.left - state.margin.left) / state.data.length);
                return (bgWidth / 2.2);
            })
            .attr('dy', '0')
            .attr('dx', '0')
            .attr('x', (state.height - state.xLabelHeight - 10) * -1)
            .attr('transform', 'rotate(-90)')
            .attr('class', function() {
                return 'objtcharts';
            })
            .text(function(d) {
                return d.qualityLabel;
            });
    },
    selectThisDay: ({data, obj}) => (state, actions) => {
        if (data.key > state.today) {
            return false;
        }
        actions.highLightThisDay({data: data, obj: obj});
        if (state.mode === 'year') {
            return false;
        }

        Data.themeQuery(state.queryKind, data.key, state.mode).then(function(res) {
            var parsedData = res.data.interval;
            // var parseTime = d3.timeParse('%Y%m%dT%H:%M:%S.000Z'), tmpDate = null, tmpData = [];
            var parseTime = d3.timeParse('%Y%m%d'), tmpDate = null, tmpData = [];

            var kpiData = [];
            for (var day in parsedData) {
                kpiData = [];
                for (var kpi in parsedData[day]) {
                    tmpDate = day;
                    kpi = parsedData[day][kpi];
                    kpi.key = tmpDate;
                    kpi.date = parseTime(tmpDate);
                    kpiData.push(kpi);
                }
                tmpData.push(kpiData);
            }
            state.data = tmpData;
            actions.updateVariations();
            actions.updateAxis();
        });
    },
    highLightThisDay: ({data, obj}) => (state, actions) => {
        state.rootContainer.selectAll('g.grpbgrectangles')
            .attr('class', 'grpbgrectangles');
        let subx = state.rootContainer.select('text.subx-' + +data.key);
        let subxs = state.rootContainer.selectAll('text.subx');
        if (subxs) {
            subxs.style('display', 'none');
        }
        if (subx) {
            subx.style('display', 'block');
        }
        state.defaultSelected = data.key;
        obj.setAttribute('class', 'grpbgrectangles active');
    },
    displayQuestion: (value) => (state, actions) => {
        actions.setShowMore(false);
        actions.setDefaultValue(value);
        actions.setDisplayQuestion(true);

        let trg = document.querySelectorAll('.btzDashboards-btzDashboard-btzHeader-btzKind[data-theme="' + state.kind + '"]');
        if (trg && (trg.length > 0)) {
            trg = trg[0];
            if (trg && (trg !== undefined)) {
                let trgOffset = trg.getBoundingClientRect().top + window.scrollY;
                if ((trgOffset !== null) && (trgOffset !== undefined)) {
                    scrollTo(null, (trgOffset - 200), 240);
                }
            }
        }
    },
    cancelQuestion: () => (state, actions) => {
        actions.setDisplayQuestion(false);
    },
    updateAddDataButtons: (root) => (state, actions) => {
        var rootContainer = state.rootContainer;
        var grpRect = null, grpText = null;

        // circle itself
        var grpCircle = rootContainer.selectAll(root);
        grpCircle.selectAll('*').remove(); // can be do better way
        grpRect = grpCircle.append('rect')
            .attr('class', 'objrectangles');
        grpText = grpCircle.append('text')
            .text(function(d) {
                if ((d[0].value === null) || (d[0].value === undefined) || (d[0].value == 'null')) {
                    return '+';
                }
                return d[0].value;
            })


        grpRect = rootContainer.selectAll('rect.objrectangles');
        grpRect.attr('fill', function() { return 'red'; })
            .attr('width', function(d) {
                var factor = 1, dvalue = d[0].value;
                if ((dvalue !== null) && (dvalue !== undefined) && (dvalue !== 'null')) {
                    dvalue = String(dvalue).replace('.', '');
                    if (dvalue.length > 2) {
                        factor = (String(d[0].value).length / 2);
                    }
                }
                return ((state.rect[state.mode].width * factor) + 'px')
            })
            .attr('height', (state.rect[state.mode].height + 'px'))
            .attr('x', function(d) {
                var factor = 1, dvalue = d[0].value;
                if ((dvalue !== null) && (dvalue !== undefined) && (dvalue !== 'null')) {
                    dvalue = String(dvalue).replace('.', '');
                    if (dvalue.length > 2) {
                        factor = (String(d[0].value).length / 2);
                    }
                }
                return ((state.rect[state.mode].width * -0.5 * factor) + 'px')
            })
            .attr('y', ((state.rect[state.mode].height * -0.5) + 'px'))
            .attr('rx', (state.rect[state.mode].height / 2))
            .attr('ry', (state.rect[state.mode].height / 2));
        // circle value
        grpText = rootContainer.selectAll('text');
        grpText.attr('font-size', (state.rect[state.mode].fontsize + 'px'))
            .attr('dx', 0)
            .attr('dy', state.rect[state.mode].dy);
    },
    updateVariations: () => (state, actions) => {
        var min = null, max = null, mean = null, numbOfValue = 0;
        var total = 0, weight = null, imc = null;

        let primaryKey = null;
        if (!Array.isArray(state.data)) {
            return false;
        }
        state.data.forEach(function(d) {
            primaryKey = d;

            if ((state.kind === 'sleep') || (state.kind === 'activity') || (state.kind === 'followed-weight')) {
                primaryKey = [d[0]];
            } else if ((state.kind === 'denivele') && (d[1] !== undefined)) {
                primaryKey = [d[1]];
            }

            primaryKey.forEach(function(dd) {
                if (dd.min !== null) {
                    if ((min === null) || (+dd.min < min)) {
                        if (min === null) {
                            min = 0;
                        }
                        min = +dd.min;
                    }
                }
                if ((max === null) || (+dd.max > max)) {
                    if (max === null) {
                        max = 0;
                    }
                    max = +dd.max;
                }
                if (dd.mean !== null) {
                    if (mean === null) {
                        mean = 0;
                    }
                    mean += +dd.mean;
                }
                if (dd.value && (dd.value !== null) && (dd.value !== undefined) && !isNaN(+dd.value)) {
                    if (weight === null) {
                        weight = 0;
                        total = 0;
                        imc = 0;
                    }
                    numbOfValue++;
                    weight += +dd.origvalue;
                    total += +dd.origvalue;

                    if ((state.kind === 'followed-weight') || (state.kind === 'waistsize')) {
                        imc += (+dd.origvalue / ((state.particularHeight / 100) * (state.particularHeight / 100)));
                    }
                }
            });
        });

        mean = Math.round((mean / numbOfValue) * 10) / 10;
        if (min === null) {
            min = max;
        }

        var variations = null, statevariation = null;
        for (var variation in state.data) {
            statevariation = state.data[variation];
            if ((state.kind === 'sleep') || (state.kind === 'activity') || (state.kind === 'followed-weight')) {
                statevariation = [state.data[variation][0]];
            } else if ((state.kind === 'denivele') && (state.data[variation][1] !== undefined)) {
                statevariation = [state.data[variation][1]];
            }
            for (var vvariation in statevariation) {
                vvariation = statevariation[vvariation];
                if (+vvariation.key === +state.defaultSelected) {
                    variations = vvariation;
                }
            }
        }
        actions.setVariations(variations);

        var tmpUnit = state.theme.unit;
        if (tmpUnit !== '') {
            tmpUnit = '/' + tmpUnit;
        }

        if (weight !== null) {
            weight = Math.round((weight / numbOfValue) * 10) / 10;
        } else {
            weight = 'Ø';
            tmpUnit = '';
        }
        if (imc !== null) {
            imc = Math.round((imc  / numbOfValue) * 10) / 10;
        } else {
            imc = 'Ø';
            tmpUnit = '';
        }

        actions.setExtremAndMean({
            min: (min + tmpUnit),
            max: (max + tmpUnit),
            mean: (mean + tmpUnit),
            total: (total + tmpUnit),
            weight: (weight + tmpUnit),
            imc: (imc + tmpUnit)
        });
    },
    updateQualitySleep: () => (state, actions) => {
        let qualitySleep = null, qualityStat = [], qualityTotal = 0;
        for (var quality in state.data) {
            let qualityCase = state.data[quality][1];
            if (isDefined(qualityCase) && (qualityCase.value !== null)) {
                qualityStat.push(qualityCase.value);
                qualityTotal++;
            }
        }
        if ((state.kind === 'sleep') && (state.mode === 'year')) {
            qualityStat = state.yearQualitySleep;
            qualityTotal = qualityStat.length;
        }
        qualitySleep = qualityStat.reduce(function(acc, curr) {
            acc[curr] ? acc[curr]++ : acc[curr] = 1;
            return acc;
        }, {});

        let qualitySleepPercents = [];
        for (var qsleep in qualitySleep) {
            let percentQsleep = (qualitySleep[qsleep] * 100) / qualityTotal;
            qualitySleepPercents.push({'percent': Math.round(percentQsleep), 'quality': qsleep})
        }
        actions.setQualitySleep(qualitySleepPercents);
    },
    updateQualitySleepDataViz: (props) => (state, actions) => {
        // test resolution
        let qualitySleep = state.qualitySleep;
        var svg = d3.select(props);
        var root = svg.append('g');
        root.attr('class', 'root-container-sleepquality')
            .attr('transform', 'translate(0, 0)');

        var availableWidth = 78;
        var availableHeight = 78;
        var radius = Math.min(availableWidth, availableHeight) / 2;

        var arc = d3.arc()
            .outerRadius(radius)
            .innerRadius(radius - 14);

        var pie = d3.pie()
            .sort(null)
            .value(function(d) {
                return Math.round(+d.percent);
            });

        root.append('g').attr('class', 'arc')
            .attr('transform', 'translate(' + availableWidth / 2 + ',' + availableHeight / 2 + ')');
        root.selectAll('.arc')
            .datum(qualitySleep).selectAll('path')
            .data(pie)
            .enter().append('path')
            .attr('d', arc)
            .style('fill', function(d) {
                if (constants.custo.DASH_SLEEP.filter((dash) => +dash.quality === +d.data.quality)[0] !== undefined) {
                    return constants.custo.DASH_SLEEP.filter((dash) => +dash.quality === +d.data.quality)[0].color;
                }
                return 'grey';
            });
    },
    updateAxis: () => (state, actions) => {
        var tmpMin = null, tmpMax = null, tmpTotal = 0;
        var statedata = state.data;
        if (state.kind === 'sport') {
            //
        }

        // ugly !!!
        statedata.forEach(function(d) {
            // sport need to iterate in each day to find the hightest sport time (can be multiple sports in one day)
            if (state.kind === 'sport') {
                if (Array.isArray(d)) {
                    d.forEach(function(dd) {
                        if (tmpMin === null) {
                            tmpMin = dd.min;
                        } else if ((dd.min !== null) && (+dd.min < tmpMin)) {
                            tmpMin = +dd.min;
                        }
                        if ((dd.max !== null) && (tmpMax === null) || (+dd.max > tmpMax)) {
                            tmpMax = +dd.max;
                        }
                        if (dd.value && (dd.value !== null) && (dd.value > tmpTotal)) {
                            tmpTotal += +dd.value;
                        }
                    });
                }
            } else {
                if (tmpMin === null) {
                    tmpMin = d[0].min;
                } else if ((d[0].min !== null) && (+d[0].min < tmpMin)) {
                    tmpMin = +d[0].min;
                }
                if ((d[0].max !== null) && (tmpMax === null) || (+d[0].max > tmpMax)) {
                    tmpMax = +d[0].max;
                }
                if (d[0].value && (d[0].value !== null) && (d[0].value > tmpTotal)) {
                    tmpTotal += +d[0].value;
                }
            }
        });
        if (tmpMin === null) {
            tmpMin = tmpMax;
        }

        // axis update
        state.xAxis.domain(d3.extent(state.data, function(d) { return d[0].date; }));
        var tmpYMax = state.ydomainMax, tmpYMin = state.ydomainMin;
        if (state.defaultAxis && (state.defaultAxis.length > 1)) {
            tmpYMax = Math.max.apply(Math, state.defaultAxis);
            tmpYMin = Math.min.apply(Math, state.defaultAxis);
        }

        if ((state.dynYdomainMax === true) && (tmpMax !== null)) {
            tmpYMax = tmpMax + state.ydomainOffset;
        }
        if ((state.dynYdomainMin === true) && (tmpMin !== null)) {
            tmpYMin = tmpMin - state.ydomainOffset;
        }
        if ((state.toDisplayAsTotal.indexOf(state.kind) > -1) && (state.mode === 'year')) {
            tmpYMax = tmpTotal;
        }

        if ((state.kind === 'sleep') || (state.kind === 'sport')) {
            if (tmpMax !== null) {
                tmpYMax = tmpMax + 60;
            }
            tmpYMax = Math.floor(tmpYMax / 60);
            tmpYMin = Math.floor(tmpYMin / 60);
        }

        if (state.metrics !== undefined) {
            if (state.kind === 'followed-weight') {
                tmpYMax = convertMetrics('weight', '', (state.metrics !== null ? state.metrics.weight : 0), tmpYMax);
                tmpYMin = convertMetrics('weight', '', (state.metrics !== null ? state.metrics.weight : 0), tmpYMin);
            } else if (state.kind === 'waistsize') {
                tmpYMax = convertMetrics('abdo', '', (state.metrics !== null ? state.metrics.height : 0), tmpYMax);
                tmpYMin = convertMetrics('abdo', '', (state.metrics !== null ? state.metrics.height : 0), tmpYMin);
            }
        }
        var rootContainer = state.rootContainer;
        if (isDefined(tmpYMax) && (tmpYMax !== '') && (!isNaN(tmpYMax))) {
            actions.setYDomainMax(tmpYMax);
        } else {
            tmpYMax = state.ydomainMax;
        }
        if (isDefined(tmpYMin) && (tmpYMin !== '') && (!isNaN(tmpYMin))) {
            actions.setYDomainMin(tmpYMin);
        } else {
            tmpYMin = state.ydomainMin;
        }
        state.yAxis.domain([tmpYMin, tmpYMax]);
        state.x.call(d3.axisBottom(state.xAxis)
            .tickFormat(function(d) {
                if (state.ticksNumber.substring[state.mode] !== null) {
                    return d3.timeFormat(state.ticksNumber.format[state.mode])(d).substring(0, state.ticksNumber.substring[state.mode]);
                }
                return d3.timeFormat(state.ticksNumber.format[state.mode])(d);
            })
            .ticks(state.data.length));
        var ticks = state.x.selectAll('.tick');
        ticks.attr('class', function(d) {
            if (d instanceof Date) {
                if (+state.defaultSelected == +d.yyyymmdd()) { return 'tick active' }
                else { return 'tick' }
            }
        });
        //
        let yAxisTicks = [];
        let nTick = state.ticksNumber.y[state.mode];
        for (var i = 0; i < nTick; i++ ) {
            // yAxisTicks.push(Math.round((+[tmpYMin, tmpYMax][1] - +[tmpYMin, tmpYMax][0]) / (nTick - 1)* i + +[tmpYMin, tmpYMax][0]));
            yAxisTicks.push(Math.round((+tmpYMax - +tmpYMin) / (nTick - 1)* i + +tmpYMin));
        }
        //
        if ((tmpMax === null) && (tmpMin === null)) {
            state.y.call(d3.axisLeft(state.yAxis)
                .tickFormat(function(d) {
                    return (d + state.yAxisUnit);
                })
                .tickPadding(2)
                .tickValues(state.defaultAxis.map(function(d) {
                    if (['sleep', 'sport'].indexOf(state.kind) > -1) {
                        return Math.floor(d / 60);
                        // return d;
                    } else if (['followed-weight'].indexOf(state.kind) > -1) {
                        return convertMetrics('weight', '', (state.metrics !== null ? state.metrics.weight : 0), d);
                    } else if (['waistsize'].indexOf(state.kind) > -1) {
                        return convertMetrics('abdo', '', (state.metrics !== null ? state.metrics.height : 0), d);
                    }
                    return d;
                }))
                .ticks(state.ticksNumber.y[state.mode]));
        } else {
            state.y.call(d3.axisLeft(state.yAxis)
                .tickFormat(function(d) {
                    return (d + state.yAxisUnit);
                })
                .tickPadding(2)
                .tickValues(yAxisTicks.map(function(d) {
                    if (['sleep', 'sport'].indexOf(state.kind) > -1) {
                        // return Math.round(d / 60);
                        return d;
                    } else if (['followed-weight'].indexOf(state.kind) > -1) {
                        return convertMetrics('weight', '', (state.metrics !== null ? state.metrics.weight : 0), d);
                    } else if (['waistsize'].indexOf(state.kind) > -1) {
                        return convertMetrics('abdo', '', (state.metrics !== null ? state.metrics.height : 0), d);
                    }
                    return d;
                }))
                .ticks(state.ticksNumber.y[state.mode]));
        }
        //
        state.subx.call(d3.axisBottom(state.xAxis)
            .tickFormat(function(d) {
                if (state.mode === 'month') {
                    return d3.timeFormat('%d/%m')(d);
                }
                return '';
            })
            .ticks(state.data.length));
        rootContainer.select('.sub-axis--x').selectAll('text')
            .attr('class', function(d) {
                // ugly fixe, dont understand why there is a day difference
                //  between this class, and the [d3.timeFormat('%d/%m')(d);] above
                let date = new Date(d);
                date.setDate(date.getDate() + 1);
                date = date.toISOString().split('T')[0].split('-').join('');
                //
                return 'subx subx-' + date;
            })
    },
    updateBgRect: () => (state, actions) => {
        var rect = state.rectBg.selectAll('g.grpbgrectangles')
            .data(state.data);
        rect.exit().remove();

        var grpBgRect = rect.enter()
            .append('g')
            .attr('class', function(d) {
                return 'grpbgrectangles grpbgrectangles-' + +d[0].key;
            })
            .merge(rect);

        grpBgRect.selectAll('*').remove();
        grpBgRect.exit().remove();
        grpBgRect.append('rect')
            .attr('class', function(d) {
                return 'bgrectangles bgrectangles-' + +d[0].key;
            })
            .attr('height', function() {
                return state.height - state.xLabelHeight;
            })
            .merge(grpBgRect)
            .attr('width', (((state.width - state.padding.left - state.margin.left) / state.data.length) + 'px'))

        grpBgRect.append('svg:image')
            .attr('xlink:href', icoAddData)
            .attr('class', function(d) {
                if (+d[0].key > +state.today) {
                    return 'btnnoedit';
                }
                return 'btnedit';
            })
            .attr('width', function() {
                if (state.customer === 'vyv') {
                    return '22px';
                }
                return '15px';
            })
            .attr('height', function() {
                if (state.customer === 'vyv') {
                    return '22px';
                }
                return '15px';
            })
            .on('mousedown', function(d) {
                d3.event.stopPropagation();
                if (state.kind === 'followed-weight') {
                    actions.displayQuestion([d[0].value, state.particularHeight]);
                } else if (state.kind === 'sleep') {
                    let duration = getMinHourFromMinutes(d[0].value), quality = (d[1] !== undefined ? d[1].value : null);
                    actions.displayQuestion([duration, quality]);
                } else if (state.kind === 'sport') {
                    actions.displayQuestion(d);
                } else {
                    actions.displayQuestion(d[0].value);
                }
            })
            .merge(grpBgRect)
            .attr('x', function() {
                if (state.customer === 'vyv') {
                    return (((state.width - state.padding.left - state.margin.left) / state.data.length) / 2) - 11;
                }
                return (((state.width - state.padding.left - state.margin.left) / state.data.length) / 2) - 7;
            })
            .attr('y', (state.height - state.xLabelHeight + 30));

        state.rectBg.selectAll('g.grpbgrectangles')
            .on('mousedown', function(d) {
                actions.selectThisDay({data: d[0], obj: this});
            })
            .merge(rect)
            .transition()
            .duration(400)
            .attr('transform', function(d) {
                return 'translate(' + (state.xAxis(d[0].date) - (((state.width - state.padding.left - state.margin.left) / state.data.length) / 2)) + ', 0)'
            });
    },
    parseTrackers: (trackers) => (state, actions) => {
        var found = false, trackerObj = null, trackerImg = null;
        var chars = {' ':'','/':'-','é':'e'};
        for (var tracker in trackers) {
            tracker = trackers[tracker];
            switch (state.kind) {
                case 'activity':
                case 'denivele':
                    if (tracker.activity === true) {
                        found = true;
                        trackerObj = tracker;
                    }

                    break;
                case 'sleep':
                    if (tracker.sleep === true) {
                        trackerObj = tracker;
                        found = true;
                    }

                    break;
                case 'sport':
                    if (tracker.body === true) {
                        trackerObj = tracker;
                        found = true;
                    }

                    break;
                default:

            }
            if (found) {
                trackerImg = trackerObj.name.replace(/[ /é]/g, m => chars[m]);
                trackerImg = `${process.env.PUBLIC_URL}/img/trackers/tracker/` + trackerObj.brand.toLowerCase() + `_` + trackerImg.toLowerCase() + `@2x.png`;
                trackerObj.img = trackerImg;

                actions.setThemeTracker(trackerObj);
                return;
            }
        }
    },
    setData: newState => state => ({
        data: newState,
    }),
    setThemeTracker: newState => state => ({
        themeTracker: newState,
    }),
    setBgContainer: newState => state => ({
        rectBg: newState,
    }),
    setRootContainer: newState => state => ({
        rootContainer: newState,
    }),
    setRootContainerJS: newState => state => ({
        rootContainerJS: newState,
    }),
    setPathLine: newState => state => ({
        pathLine: newState,
    }),
    setValueLine: newState => state => ({
        valueLine: newState,
    }),
    setDataVizWidth: newState => state => ({
        width: newState,
    }),
    setDataVizHeight: newState => state => ({
        height: newState,
    }),
    setX: newState => state => ({
        x: newState,
    }),
    setSubX: newState => state => ({
        subx: newState,
    }),
    setXAxis: newState => state => ({
        xAxis: newState,
    }),
    setY: newState => state => ({
        y: newState,
    }),
    setYAxis: newState => state => ({
        yAxis: newState,
    }),
    setVariations: newState => state => ({
        variations: newState,
    }),
    setQualitySleep: newState => state => ({
        qualitySleep: newState,
    }),
    setExtremAndMean: newState => state => ({
        extremAndMean: newState,
    }),
    setThemeInformations: newState => state => ({
        theme: newState,
    }),
    setReadableDate: newState => state => ({
        readableDate: newState,
    }),
    setDisplayQuestion: newState => state => ({
        displayQuestion: newState,
    }),
    setUpdating: newState => state => ({
        updating: newState
    }),
    setDefaultValue: newState => state => ({
        defaultValue: newState
    }),
    setShowMore: newState => state => ({
        showMore: newState
    }),
    setYDomain: newState => state => ({
        ydomain: newState
    }),
    setDynYDomainMax: newState => state => ({
        dynYdomainMax: newState
    }),
    setYDomainMax: newState => state => ({
        ydomainMax: newState
    }),
    setDynYDomainMin: newState => state => ({
        dynYdomainMin: newState
    }),
    setYDomainMin: newState => state => ({
        ydomainMin: newState
    }),
    setType: newState => state => ({
        type: newState
    }),
    setYAxisUnit: newState => state => ({
        yAxisUnit: newState
    }),
    setModeLabel: newState => state => ({
        modeLabel: (newState === 'year' ? t('année', {ns: 'datas'}) : (newState === 'month' ? t('mois', {ns: 'datas'}) : t('semaine', {ns: 'datas'})))
    }),
    setModeLabelPrefixed: newState => state => ({
        modeLabelPrefixed: (newState === 'year' ? t('de l‘année', {ns: 'datas'}) : (newState === 'month' ? t('du mois', {ns: 'datas'}) : t('de la semaine', {ns: 'datas'})))
    }),
    setDynamicSportsSetting: newState => state => ({
        dynamicSportsSetting: newState,
    }),
    getDate: ({el, dat}) => {
        dat = dat.substr(0, 4) + '-' + dat.substr(4, 2) + '-' + dat.substr(6, 2);
        var date = new Date(Date.parse(new Date(dat)));
        var options = { month: 'long', day: 'numeric', weekday: 'long' };
        el.innerHTML = (t('par rapport à', {ns: 'datas'}) + ' ' + capitalize(date.toLocaleDateString('fr-FR', options)));
    },
    getPeriodLabel: (dashData) => (state, actions) => {
        var start = '', end = '', datObjStart = dashData[0], datObjEnd = dashData[dashData.length - 1];
        var date = '', options = '';

        if ((datObjStart !== undefined) && (datObjStart !== null) && (datObjStart[0].key !== undefined)) {
            if (state.mode === 'week') {
                start = datObjStart[0].key, end = datObjEnd[0].key;
                if (typeof start === 'string') {
                    start = start.substr(6, 2) + '/' + start.substr(4, 2);
                    end = end.substr(6, 2) + '/' + end.substr(4, 2);
                    return (t('semaine du', {ns: 'datas'}) + ' ' + start + ' ' + t('au', {ns: 'datas'}) + ' ' + end);
                }
                return '';
            } else if (state.mode === 'month') {
                start = datObjStart[0].key;
                start = start.substr(0, 4) + '-' + start.substr(4, 2) + '-' + start.substr(6, 2);
                if (typeof start === 'string') {
                    date = new Date(Date.parse(new Date(start)));
                    options = { month: 'long', year: 'numeric' };
                    return date.toLocaleDateString('fr-FR', options);
                }
                return '';
            } else if (state.mode === 'year') {
                start = datObjStart[0].key;
                start = start.substr(0, 4) + '-' + start.substr(4, 2) + '-' + start.substr(6, 2);
                if (typeof start === 'string') {
                    date = new Date(Date.parse(new Date(start)));
                    options = { year: 'numeric' };
                    return date.toLocaleDateString('fr-FR', options);
                }
                return '';
            }
        }
        return '';
    },
    setThemeInformationsFromTheme: (kind) => (state, actions) => {
        var infos = {}
        if (kind === 'shape') {
            if (state.mode === 'year') {
                infos = {
                    label: t('note moyenne sur 10', {ns: 'datas'}),
                    unit: '',
                }
            } else {
                infos = {
                    label: t('note sur 10', {ns: 'datas'}),
                    unit: '',
                }
            }
        } else if (kind === 'activity') {
            if (state.mode === 'year') {
                infos = {
                    label: t('nombre total de pas', {ns: 'datas'}),
                    unit: '',
                }
            } else {
                infos = {
                    label: t('nombre de pas', {ns: 'datas'}),
                    unit: '',
                }
            }
        } else if (kind === 'sport') {
            if (state.mode === 'year') {
                infos = {
                    label: t('durée totale', {ns: 'datas'}),
                    unit: '',
                }
            } else {
                infos = {
                    label: t('durée', {ns: 'datas'}),
                    unit: '',
                }
            }
        } else if (kind === 'denivele') {
            if (state.mode === 'year') {
                infos = {
                    label: t('nombre total d‘étages', {ns: 'datas'}),
                    unit: '',
                }
            } else {
                infos = {
                    label: t('nombre d’étages', {ns: 'datas'}),
                    unit: '',
                }
            }
        } else if (kind === 'tiredness') {
            if (state.mode === 'year') {
                infos = {
                    label: t('note moyenne sur 10', {ns: 'datas'}),
                    unit: '',
                }
            } else {
                infos = {
                    label: t('note sur 10', {ns: 'datas'}),
                    unit: '',
                }
            }
        } else if (kind === 'stress') {
            if (state.mode === 'year') {
                infos = {
                    label: t('note moyenne sur 10', {ns: 'datas'}),
                    unit: '',
                }
            } else {
                infos = {
                    label: t('note sur 10', {ns: 'datas'}),
                    unit: '',
                }
            }
        } else if (kind === 'sleep') {
            if (state.mode === 'year') {
                infos = {
                    label: t('durée et qualité moyenne', {ns: 'datas'}),
                    unit: '',
                }
            } else {
                infos = {
                    label: t('durée et qualité', {ns: 'datas'}),
                    unit: '',
                }
            }
        } else if (kind === 'tobacco') {
            if (state.mode === 'year') {
                infos = {
                    label: t('nombre total de cigarettes', {ns: 'datas'}),
                    unit: '',
                }
            } else {
                infos = {
                    label: t('nombre de cigarettes', {ns: 'datas'}),
                    unit: '',
                }
            }
        } else if (kind === 'alcool') {
            if (state.mode === 'year') {
                infos = {
                    label: t('nombre total de verres', {ns: 'datas'}),
                    unit: '',
                }
            } else {
                infos = {
                    label: t('nombre de verres', {ns: 'datas'}),
                    unit: '',
                }
            }
        } else if (kind === 'followed-weight') {
            if (state.mode === 'year') {
                infos = {
                    label: t('moyenne', {ns: 'datas'}) + ' ' + convertMetricsLabels(true, 'weight', (state.metrics !== null ? state.metrics.weight : 0), t('en kg', {ns: 'datas'})),
                    unit: '',
                }
            } else {
                infos = {
                    label: convertMetricsLabels(true, 'weight', (state.metrics !== null ? state.metrics.weight : 0), t('en kg', {ns: 'datas'})),
                    unit: '',
                }
            }
        } else if (kind === 'waistsize') {
            if (state.mode === 'year') {
                infos = {
                    label: t('moyenne', {ns: 'datas'}) + ' ' + convertMetricsLabels(true, 'abdo', (state.metrics !== null ? state.metrics.height : 0), t('en cm', {ns: 'datas'})),
                    unit: '',
                }
            } else {
                infos = {
                    label: convertMetricsLabels(true, 'height', (state.metrics !== null ? state.metrics.height : 0), t('en cm', {ns: 'datas'})),
                    unit: '',
                }
            }
        }

        actions.setThemeInformations(infos);
    },
    /*
    x: x-coordinate
    y: y-coordinate
    w: width
    h: height
    r: corner radius
    tl: top_left rounded?
    tr: top_right rounded?
    bl: bottom_left rounded?
    br: bottom_right rounded?
    {x, y, w, h, r, tl, tr, bl, br}
    https://bost.ocks.org/mike/chart/
     */
    rounded_rect: ({x, y, w, h, r, tl, tr, bl, br}) => (state, actions) => {
        var retval;
        retval  = 'M' + (x + r) + ',' + y;
        retval += 'h' + (w - 2*r);
        if (tr) { retval += 'a' + r + ',' + r + ' 0 0 1 ' + r + ',' + r; }
        else { retval += 'h' + r; retval += 'v' + r; }
        retval += 'v' + (h - 2*r);
        if (br) { retval += 'a' + r + ',' + r + ' 0 0 1 ' + -r + ',' + r; }
        else { retval += 'v' + r; retval += 'h' + -r; }
        retval += 'h' + (2*r - w);
        if (bl) { retval += 'a' + r + ',' + r + ' 0 0 1 ' + -r + ',' + -r; }
        else { retval += 'h' + -r; retval += 'v' + -r; }
        retval += 'v' + (2*r - h);
        if (tl) { retval += 'a' + r + ',' + r + ' 0 0 1 ' + r + ',' + -r; }
        else { retval += 'v' + -r; retval += 'h' + r; }
        retval += 'z';
        return retval;
    }
};