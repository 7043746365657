import { t, h } from '@app/utils';
import { User, Data, Lunch } from '@app/api';
import { isDefined } from '@app/core';
import * as constants from '@app/constants';

module.exports = {
    initAction: function() {
        let action = {
            onComponentCreate: (props) => (state, actions) => {
                var refDate = new Date();
                var year = refDate.getUTCFullYear(), month = (refDate.getUTCMonth() + 1), day = refDate.getUTCDate();
                if (+month < 10) {
                    month = '0' + month;
                }
                if (+day < 10) {
                    day = '0' + day;
                }
                refDate = year + '' + month + '' + day;

                Data.initialQuery(refDate, 'week').then(function(data) {
                    if (data.data === undefined) {
                        return false;
                    }
                    let curData = data.data.interval[refDate];

                    User.getUserObjectives().then(function(res) {
                        var userObjectives = [];
                        let specificThmPregnancy = {detect: [1, 2, 3, 4, 8, 6, 14, 17], rise: [5, 18]};
                        let specificThmDiabetes = {detect: [1, 2, 3, 4, 8, 6, 14], rise: [2, 4]};
                        for (var obj in res.data) {
                            obj = res.data[obj];

                            let theme = obj.objective.mainThemes;
                            let arrtheme = constants.custo.ARRTHEMES;
                            let allActivitiesExcept = ['ACTIVITY_WALK', 'ACTIVITY_FLOORS', 'ACTIVITY_DURATION', 'ACTIVITY_DISTANCE', 'ACTIVITY_ALTITUDE', 'ACTIVITY_SPORTSTATS', 'ACTIVITY_OTHERSTATS'];
                            obj.data = [];
                            //
                            if (Array.isArray(theme)) {
                                if (theme.length === specificThmPregnancy.detect.length && theme.sort().every(function(value, index) { return value === specificThmPregnancy.detect.sort()[index]})) {
                                    theme = specificThmPregnancy.rise;
                                } else if (theme.length === specificThmDiabetes.detect.length && theme.sort().every(function(value, index) { return value === specificThmDiabetes.detect.sort()[index]})) {
                                    theme = specificThmDiabetes.rise;
                                }
                            }
                            //
                            for (var thm in theme) {
                                let tags = arrtheme.filter((arrthm) => {
                                    return +arrthm.key === +theme[thm]
                                }) || {};

                                for (var tag in tags[0].tags) {
                                    tag = tags[0].tags[tag];
                                    for (var themeTag in curData) {
                                        if ((tag === themeTag) && (tag !== 'SLEEP_QUALITY')) {
                                            let objData = curData[themeTag];
                                            let cardTheme = actions.getTheme(theme[thm]);

                                            objData.valueTheme = cardTheme.value;
                                            objData.labelTheme = cardTheme.label;
                                            objData.labelLabel = 'note sur 10';

                                            if (tag === 'ACTIVITY_WALK') {
                                                objData.labelTheme = 'Marche';
                                                objData.i18nLabelTheme = t('Marche', {ns: 'timeline'});
                                                objData.labelLabel = 'nombre de pas';
                                                objData.i18nLabelLabel = t('nombre de pas', {ns: 'timeline'});
                                            } else if (tag === 'ACTIVITY_FLOORS') {
                                                objData.labelTheme = 'Dénivelé';
                                                objData.i18nLabelTheme = t('Dénivelé', {ns: 'timeline'});
                                                objData.valueTheme = 'denivele';
                                                objData.labelLabel = 'nombre d‘étages';
                                                objData.i18nLabelLabel = t('nombre d‘étages', {ns: 'timeline'});
                                            } else if (tag === 'SLEEP_DURATION') {
                                                objData.labelTheme = 'Sommeil';
                                                objData.i18nLabelTheme = t('Sommeil', {ns: 'timeline'});
                                                objData.valueTheme = 'sleep';
                                                objData.labelLabel = state.constQualitySleep[+curData['SLEEP_QUALITY'].total];
                                                if (+curData['SLEEP_QUALITY'].total === 0) {
                                                    objData.labelLabel = 'qualité du sommeil';
                                                    objData.i18nLabelLabel = t('qualité du sommeil', {ns: 'timeline'});
                                                }
                                                objData.convertto = 'minuteAndHour';
                                            }

                                            obj.data.push(objData);
                                        } else if (tag === 'NUTRITION') {
                                            let objData = {};
                                            objData.labelTheme = 'Alimentation';
                                            objData.i18nLabelTheme = t('Alimentation', {ns: 'timeline'});
                                            objData.valueTheme = 'food';
                                            objData.labelLabel = 'ajouter un repas';
                                            objData.i18nLabelLabel = t('ajouter un repas', {ns: 'timeline'});

                                            obj.data.push(objData);

                                            // objData = {};
                                            // objData.labelTheme = 'Verre d‘eau';
                                            // objData.i18nLabelTheme = t('Verre d‘eau', {ns: 'timeline'});
                                            // objData.valueTheme = 'drink';
                                            // objData.labelLabel = 'nombre de verres';
                                            // objData.i18nLabelLabel = t('nombre de verres', {ns: 'timeline'});
                                            // objData.onclick = () => {
                                            //     let today = new Date();
                                            //     let year = today.getUTCFullYear(), month = (today.getUTCMonth() + 1), day = today.getUTCDate();
                                            //     if (+month < 10) {
                                            //         month = '0' + month;
                                            //     }
                                            //     if (+day < 10) {
                                            //         day = '0' + day;
                                            //     }
                                            //     let data = {
                                            //         type: 4,
                                            //         name: '',
                                            //         comment: 'none',
                                            //         aliments: [18066],
                                            //         usual: false,
                                            //         date: actions.createDateObj(year + '' + month + '' + day),
                                            //         contexts: null,
                                            //     }
                                            //     Lunch.sendLunch(data).then((res) => {
                                            //         if ((res.data !== undefined) && (res.data.aliments !== null)) {
                                            //             actions.setDrinkNumber(res.data.aliments.length);
                                            //         }
                                            //     });
                                            // };

                                            // obj.data.push(objData);
                                            break;
                                        } else if (tag === 'SPORT') {
                                            let objData = {};
                                            for (var cdat in curData) {
                                                if ((cdat.indexOf('ACTIVITY_') > -1) && (allActivitiesExcept.indexOf(cdat) === -1)) {
                                                    if (objData.current === undefined) {
                                                        objData = curData[cdat];
                                                    } else {
                                                        objData.current += curData[cdat].current;
                                                    }
                                                }
                                            }
                                            if (isDefined(objData.current)) {
                                                objData.total = objData.current;
                                            }
                                            objData.labelTheme = 'Sport';
                                            objData.i18nLabelTheme = t('Sport', {ns: 'timeline'});
                                            objData.valueTheme = 'sport';
                                            objData.labelLabel = 'ajouter une activité';
                                            objData.i18nLabelLabel = t('ajouter une activité', {ns: 'timeline'});
                                            objData.convertto = 'minuteAndHour';

                                            obj.data.push(objData);
                                            break;
                                        }
                                    }
                                }
                            }

                            obj.idobjective = obj.objective.id;
                            obj.title = obj.objective.title;
                            obj.media = obj.objective.media;
                            userObjectives.push(obj);
                        }
                        actions.setObjectives(userObjectives);
                        Lunch.getLunchsByDateType(refDate, 4).then((res) => {
                            // 18066 is the water aliment id
                            let drinkNumber = 0;
                            if (isDefined(res.data) && (res.data[0] && (res.data[0].aliments !== undefined))) {
                                drinkNumber = res.data[0].aliments.filter((f) => +f.id === 18066).length;
                            }
                            actions.setDrinkNumber(drinkNumber);
                        });
                    });
                });
            },
            getTheme: (intTheme) => {
                for (let _t in constants.custo.THEMES) {
                    if (+constants.custo.THEMES[_t].key === +intTheme) {
                        return constants.custo.THEMES[_t];
                    }
                }
                return [];
            },
            createDateObj: (date) => (state, actions) => {
                let parsedDate = {
                    date: new Date(date.substring(0, 4) + '-' + date.substring(4, 6) + '-' + date.substring(6, 8)),
                    timezone: 'UTC',
                    timezone_type: 3
                }
                return parsedDate;
            },
            trim: (str) => {
                if (str.length > 250) {
                    return (str.substring(0, 250) + '...');
                }
                return str;
            },
            setObjectives: newState => state => ({
                objectives: newState,
            }),
            setDrinkNumber: newState => state => ({
                drink: newState,
            }),
            rendering: () => state => ({
                rendering: !state.rendering,
            }),
            setState: ({ ns, key, value }) => state => ({
                [ns]: { ...state[ns], ...{ [key]: value } },
            })
        }
        return action;
    }
}