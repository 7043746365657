import { t, h } from '@app/utils';

import icoAppBio from '@app/img/follow/appointment-biological.png';
import icoAppBiox2 from '@app/img/follow/appointment-biological@2x.png';

import icoAppNutr from '@app/img/follow/appointment-nutritional.png';
import icoAppNutrx2 from '@app/img/follow/appointment-nutritional@2x.png';

import icoValid from '@app/img/ico/ico-valid-white.png';
import icoValidx2 from '@app/img/ico/ico-valid-white@2x.png';

export const view = (state, actions) => (props, children) => (
    <div>
        <div class='btzFollow-btzFollowEntry btzFollow-btzFollowEntryAppointment'>
            <div class='col-3' style={{ 'vertical-align': 'middle', 'padding-left': '0px' }}>
                <div class='btzRoundedImage'>
                    {props.type === 'BIO' ?
                        <img src={icoAppBio} srcset={`${icoAppBiox2} 2x`} alt='' class='' />
                        :
                        <img src={icoAppNutr} srcset={`${icoAppNutrx2} 2x`} alt='' class='' />
                    }
                    <div class={'btzRoundedImage btzRoundedImageDone ' + (props.status === 'COMPLETED' ? 'active' : '')}>
                        <img src={icoValid} srcset={`${icoValidx2} 2x`} alt='' class='' />
                    </div>
                </div>
            </div>
            <div class='col-9 p-0' style={{ 'vertical-align': 'middle', 'text-align': 'left', 'white-space': 'normal' }}>
                <p class='btzPageContentSubTexte btzXl' oncreate={(el) => actions.getDate({'el' : el, 'dat' : props.date}) }></p>
                <p class='btzPageContentTexte' oncreate={(el) => actions.getTime({'el' : el, 'dat' : props.date}) }></p>
                <p class='btzTimeline-btzCard-btzSubTitle btzPageContentSubTitle'>
                    {(props.type === 'BIO') ?
                        <font>{t('bilan de biologie médicale', {ns: 'follow'})}</font>
                        :
                        <font>{t('consultation avec mon nutritionniste', {ns: 'follow'})}</font>
                    }
                </p>
            </div>
        </div>
    </div>
);