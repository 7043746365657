import { t, h } from '@app/utils';
import { Tile, Switch } from '@app/elements';

import logoStep from '@app/img/register/areyou-cb.png';
import logoStepx2 from '@app/img/register/areyou-cb@2x.png';

import './index.scss';

export const view = (state, actions) => (props, children) => (
    <div style={{ position: 'relative' }} oncreate={actions.redirectToPayment}>
        <Tile style={{ 'text-align': 'left' }}>
            <div class='btzTitle-btzImage col-xs-12 col-4'>
                <img src={logoStep} srcset={`${logoStepx2} 2x`} alt='' class='' />
            </div>
            <Tile.Content classes='btzLeftHrArrowed col-xs-12 col-8'>
                <p class='btzTitle btzXl'>{t('Nous validons', {ns: 'cb'})}<br />{t('votre paiement ...', {ns: 'cb'})}</p>
            </Tile.Content>
        </Tile>
    </div>
);
