import { Cardline } from '@app/api';

export const actions = {
    onComponentCreate: (props) => (state, actions) => {
        //
    },
    rendering: () => state => ({
        rendering: !state.rendering,
    }),
    updateParentPropsSUCCESStrue: (props) => (state, actions) => {
        if (state.isLoading) {
            return false;
        }

        actions.loading();
        Cardline.endChallenge(props.id, {success: 1}).then(() => {
            actions.loading();
            props.removeEndedChallenge(props);
        });
    },
    updateParentPropsSUCCESSfalse: (props) => (state, actions) => {
        if (state.isLoading) {
            return false;
        }

        actions.loading();
        Cardline.endChallenge(props.id, {success: 0}).then(() => {
            actions.loading();
            props.removeEndedChallenge(props);
        });
    },
    loading: () => state => ({
        isLoading: !state.isLoading,
    }),
};
