import { Lunch, Cardline } from '@app/api';
import { parseToPercent, getUser } from '@app/core';

export const actions = {
    onComponentCreate: (props) => (state, actions) => {
        if (props.dayLunchs) {
            actions.parseDayLunch(props.dayLunchs);
            actions.setDayLunchId(props.dayLunchs[0].id);
        }

        if (props.user) {
            actions.setUser(props.user);
        }

        if (props.balanceLunchs) {
            actions.parseBalanceLunchs(props.balanceLunchs);
        }

        if (props.ymdMealDate) {
            actions.parseReadableMealDate(props.ymdMealDate);
        }

        Cardline.getCards(17).then(function(res) {
            let dataRising = [];
            for (var dRise in res.data) {
                dRise = res.data[dRise];
                if (+dRise.theme === 1) {
                    dataRising.push({
                        theme: '' + dRise.theme,
                        data: dRise,
                    });
                    break;
                }
            }
            actions.setDataRising(dataRising);
        });
    },
    onComponentUpdate: (props) => (state, actions) => {
        if (props.dayLunchs) {
            actions.parseDayLunch(props.dayLunchs);
            actions.setDayLunchId(props.dayLunchs[0].id);
        }

        if (props.user) {
            actions.setUser(props.user);
        }

        if (props.balanceLunchs) {
            actions.parseBalanceLunchs(props.balanceLunchs);
        }

        if (props.ymdMealDate) {
            actions.parseReadableMealDate(props.ymdMealDate);
        }
    },

    parseDayLunch: (lunchs) => (state, actions) => {
        let parsedDayLunch = [];
        for (var lunch in lunchs) {
            lunchs[lunch].parsedaliments = lunchs[lunch].aliments.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.id === thing.id
                ))
            );
            parsedDayLunch.push(lunchs[lunch]);
        }
        actions.setDayLunch(parsedDayLunch);
    },

    parseCompositionDayLunchs: (lunchs) => (state, actions) => {

        // let uniqFilter = lunchs[0].aliments;
        // uniqFilter = uniqFilter.filter((thing, index, self) =>
        //     index === self.findIndex((t) => (
        //         t.id === thing.id
        //     ))
        // );

        // actions.setParsedCompositionDayLunchs({
        //     fat: uniqFilter,
        //     sugar: uniqFilter,
        //     salt: uniqFilter,
        //     alcool: uniqFilter
        // });
    },

    setParsedCompositionDayLunchs: newState => state => ({
        parsedCompositionDayLunchs: newState,
    }),

    setDayLunch: newState => state => ({
        dayLunchs: newState,
    }),

    setDayLunchId: newState => state => ({
        dayLunchsId: newState,
    }),

    setUser: newState => state => ({
        user: newState,
    }),

    parseBalanceLunchs: (balanceLunch) => (state, actions) => {
        if (state.user === null) {
            return false;
        }
        if ((state.user.profile === undefined) || (state.user.profile.nutrition === undefined)) {
            // first meal added, need to get user again, to retrieve his profile
            getUser((sessionUser => {
                actions.setUser(sessionUser);
                actions.onParseBalanceLunchs(balanceLunch);
                actions.parseToSurveyLunchs(balanceLunch);
            }), true);
        } else {
            actions.onParseBalanceLunchs(balanceLunch);
            actions.parseToSurveyLunchs(balanceLunch);
        }
    },

    onParseBalanceLunchs: (balanceLunch) => (state, actions) => {
        let parsedBalanceLunchs = [];
        let toParses = balanceLunch.interval[Object.keys(balanceLunch.interval)[0]];
        state.idealAETQ = state.user.profile.nutrition.calories.ideal;
        //
        let carbohydrates = {
            0: {
                low: 40,
                high: 55
            },
            1: {
                low: 50,
                high: 60
            }
        };
        let lipids = {
            0: {
                low: 35,
                high: 45
            },
            1: {
                low: 35,
                high: 40
            },
            2: {
                low: 30,
                high: 35
            }
        };
        let proteins = {
            0: {
                low: 10,
                high: 20
            },
            1: {
                low: 15,
                high: 20
            },
            2: {
                low: 12,
                high: 20
            },
            3: {
                low: 10,
                high: 20
            }
        };
        let carbohydrate = carbohydrates[state.user.profile.activity.grade == 3 ? 1 : 0];
        let proteinIndex = state.user.profile.activity.grade == 3 ? 3 : null;
        if (proteinIndex == null) {
            proteinIndex = (state.user.profile.activity.grade === 0) && (state.user.age > 50 && state.user.age <= 70 ) && state.user.gender == 0 ? 2 : null;
        }
        if (proteinIndex == null) {
            proteinIndex = (state.user.profile.activity.grade === 0) && (state.user.age > 60 && state.user.age <= 70 ) && state.user.gender == 1 ? 2 : null;
        }
        if (proteinIndex == null) {
            proteinIndex = state.user.age > 70 ? 1 : 0;
        }
        let protein = proteins[proteinIndex];
        let lipidIndex = state.user.profile.activity.grade == 3 ? 2 : null;
        if (lipidIndex == null) {
            lipidIndex = state.user.age > 18 ? 1 : 0;
        }
        let lipid = lipids[lipidIndex];
        //
        let glucides = 0, lipides = 0, proteines = 0;
        let gluTag = 'NUTRITION_NUTRIENTS_CARBOHYDRATES';
        let lipTag = 'NUTRITION_NUTRIENTS_LIPIDS';
        let proTag = 'NUTRITION_NUTRIENTS_PROTEINS';
        let glucidesFactor = (50 / ((carbohydrate.low + carbohydrate.high) / 2));
        let lipidesFactor = (50 / ((lipid.low + lipid.high) / 2));
        let proteinesFactor = (50 / ((protein.low + protein.high) / 2));

        for (var toParse in toParses) {
            if (toParse === gluTag) {
                glucides = toParses[toParse].total;
            } else if (toParse === lipTag) {
                lipides = toParses[toParse].total;
            } else if (toParse === proTag) {
                proteines = toParses[toParse].total;
            }
        }

        parsedBalanceLunchs = {
            'base': {
                'AETQ': state.idealAETQ,
                'glucides': {
                    'value': glucides,
                    'percent': parseToPercent(glucides, state.idealAETQ),
                    'values': {
                        'low': carbohydrate.low,
                        'high': carbohydrate.high,
                    }
                },
                'lipides': {
                    'value': lipides,
                    'percent': parseToPercent(lipides, state.idealAETQ),
                    'values': {
                        'low': lipid.low,
                        'high': lipid.high,
                    }
                },
                'proteines': {
                    'value': proteines,
                    'percent': parseToPercent(proteines, state.idealAETQ),
                    'values': {
                        'low': protein.low,
                        'high': protein.high,
                    }
                }
            },
            'glucides': {
                'percent': parseToPercent(glucides, state.idealAETQ) * glucidesFactor,
                'values': {
                    'low': carbohydrate.low * glucidesFactor,
                    'high': carbohydrate.high * glucidesFactor,
                }
            },
            'lipides': {
                'percent': parseToPercent(lipides, state.idealAETQ) * lipidesFactor,
                'values': {
                    'low': lipid.low * lipidesFactor,
                    'high': lipid.high * lipidesFactor,
                }
            },
            'proteines': {
                'percent': parseToPercent(proteines, state.idealAETQ) * proteinesFactor,
                'values': {
                    'low': protein.low * proteinesFactor,
                    'high': protein.high * proteinesFactor,
                }
            }
        };
        console.log('FOOD : parsedBalanceLunchs : ', parsedBalanceLunchs);
        actions.setBalanceLunchs(parsedBalanceLunchs);
    },

    parseToSurveyLunchs: (surveyLunch) => (state, actions) => {
        let parsedSurveyLunchs = [];
        let toParses = surveyLunch.interval[Object.keys(surveyLunch.interval)[0]];

        let alcool = 0, salt = 0, saturated = 0, sugarShare = 0, sugarWeight = 0;
        let alcoolPercent = 0, saltPercent = 0, saturatedPercent = 0, sugarPercentShare = 0, sugarPercentWeight = 0;
        let alcoolMax = 2, saltMaxH = 8, saltMaxF = 6.5, saturatedMax = 12, sugarMaxShare = 20, sugarMaxWeight = 100;
        let alcTag = 'NUTRITION_WATCH_ALCOHOL'; // must be less then 2 drink
        // let satTag = 'NUTRITION_WATCH_SATURATEDFATSHARE';
        let satTag = 'NUTRITION_WATCH_SATURATEDFAT'; // kcal to compare with AETQ, must be < 12% of AETQ
        // let sugTagShare = 'NUTRITION_WATCH_SUGARSHARE';
        let sugTagShare = 'NUTRITION_WATCH_SUGAR'; // kcal to compare with AETQ, must be < 20% of AETQ
        let sugTagWeight = 'NUTRITION_WATCH_SUGARWEIGHT'; // must be less than 100gr
        let salTag = 'NUTRITION_WATCH_SALTWEIGHT'; // must be less than 8gr

        let minValue = 0, maxValue = 100, percentToNotReach = 50;
        for (var toParse in toParses) {
            if (toParse === alcTag) {
                alcool = toParses[toParse].total; // % of total eated aliments
                alcoolPercent = (alcool * percentToNotReach) / alcoolMax;
                if (alcoolPercent < minValue) alcoolPercent = minValue;
                else if (alcoolPercent > maxValue) alcoolPercent = maxValue;
            } else if (toParse === satTag) {
                saturated = toParses[toParse].total;
                saturatedPercent = parseToPercent(saturated, state.idealAETQ);
                saturatedPercent = (saturatedPercent * percentToNotReach) / saturatedMax;
                if (saturatedPercent < minValue) saturatedPercent = minValue;
                else if (saturatedPercent > maxValue) saturatedPercent = maxValue;
            } else if (toParse === sugTagShare) {
                sugarShare = toParses[toParse].total;
                sugarPercent = parseToPercent(sugarShare, state.idealAETQ);
                sugarPercent = (sugarPercent * percentToNotReach) / sugarMaxShare;
                if (sugarPercentShare < minValue) sugarPercentShare = minValue;
                else if (sugarPercentShare > maxValue) sugarPercentShare = maxValue;
            } else if (toParse === sugTagWeight) {
                sugarWeight = toParses[toParse].total; // % of total eated aliments
                sugarPercentWeight = (sugarWeight * percentToNotReach) / sugarMaxWeight;
                if (sugarPercentWeight < minValue) sugarPercentWeight = minValue;
                else if (sugarPercentWeight > maxValue) sugarPercentWeight = maxValue;
            } else if (toParse === salTag) {
                salt = toParses[toParse].total;
                saltPercent = (salt * percentToNotReach) / (((state.user.gender === 0) || (state.user.gender === false)) ? saltMaxF : saltMaxH);
                if (saltPercent < minValue) saltPercent = minValue;
                else if (saltPercent > maxValue) saltPercent = maxValue;
            }
        }

        let sugar = (sugarShare > sugarWeight) ? sugarShare : sugarWeight;
        let sugarPercent = (sugarPercentShare > sugarPercentWeight) ? sugarPercentShare : sugarPercentWeight;

        parsedSurveyLunchs = {
            'alcool': {'value': alcool, 'percent': alcoolPercent, 'max': alcoolMax},
            'saturated': {'value': saturated, 'percent': saturatedPercent, 'max': saturatedMax},
            'sugar': {'value': sugar, 'percent': sugarPercent, 'max': sugarMaxShare},
            'salt': {'value': salt, 'percent': saltPercent, 'max': (((state.user.gender === 0) || (state.user.gender === false)) ? saltMaxF : saltMaxH)},
            'percentToNotReach': percentToNotReach
        };

        console.log('FOOD : parsedSurveyLunchs : ', parsedSurveyLunchs);
        actions.setSurveyLunchs(parsedSurveyLunchs);
    },

    setBalanceLunchs: newState => state => ({
        balanceLunchs: newState,
    }),

    setSurveyLunchs: newState => state => ({
        surveyLunchs: newState,
    }),

    parseReadableMealDate: (dat) => (state, actions) => {
        dat = dat.substr(0, 4) + '-' + dat.substr(4, 2) + '-' + dat.substr(6, 2);
        var date = new Date(Date.parse(new Date(dat)));
        var options = { month: 'long', day: 'numeric', weekday: 'long' };
        actions.setReadableMealDate(date.toLocaleDateString('fr-FR', options))
    },

    setReadableMealDate: newState => state => ({
        readableMealDate: newState,
    }),

    getRepartitionByDate: (ymdMealDate) => (state, actions) => {
        Lunch.getRepartitionByDate(ymdMealDate).then((res) => {
            actions.setParsedCompositionDayLunchs(res.data);
        });
    },

    setDataRising: newState => state => ({
        dataRising: newState,
    }),

    setIdealAETQ: newState => state => ({
        idealAETQ: newState,
    }),

    resetAddMealPopup: () => state => ({
        resetingModal: !state.resetingModal,
    }),
};
