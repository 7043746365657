import { t, h } from '@app/utils';
import { Avatar, Balloon, Form, Tile } from '@app/elements';

import logoStepSA from './ico-sa.png';
import logoStepSAx2 from './ico-sa@2x.png';

import logoStepDDR from './ico-ddr.png';
import logoStepDDRx2 from './ico-ddr@2x.png';

module.exports = {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <div style={{ position: 'relative' }}>
                <Tile>
                    <Tile.Content>
                        <p class='btzTitle btzXl' style={{ 'margin-bottom': '0', height: '115px', 'max-width': '330px', 'text-align': 'left' }}>
                            {t('Quelle donnée souhaitez-vous renseigner ?', {ns: 'register'})}
                        </p>
                    </Tile.Content>
                </Tile>

                <Form onsubmit={event => event.preventDefault()} style={{ 'margin': 'auto' }}>
                    <Form.Group style={{ 'white-space': 'nowrap', 'text-align': 'center' }}>
                        <Form.Radio
                            placeholder={t('Mes SA', {ns: 'register'})}
                            id='pregnancySA'
                            name='pregnancy'
                            image={logoStepSA}
                            imagex2={logoStepSAx2}
                            onclick={() => {
                                actions.onSAClick()
                            }}
                            required
                            checked={state.pregnancy.value === 'sa'}
                        />
                        <Form.Radio
                            placeholder={t('Ma DDR', {ns: 'register'})}
                            id='pregnancyDDR'
                            name='pregnancy'
                            image={logoStepDDR}
                            imagex2={logoStepDDRx2}
                            onclick={() => {
                                actions.onDDRClick()
                            }}
                            required
                            checked={state.pregnancy.value === 'ddr'}
                        />

                        <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap' }}>
                            <Tile.Icon>
                                <Avatar.Alfred kind={'info'} classes={'centered'} />
                            </Tile.Icon>
                            <Tile.Content>
                                <Balloon kind={'info'} style={{ 'white-space': 'normal' }}>
                                    <p style={{ 'text-align': 'left' }}>
                                        <font>
                                            {t('Sélectionnez l’un des deux boutons.', {ns: 'register'})}
                                            <br />{t('(Les SA correspondent à vos semaines d’aménorrhées, la DDR à la date de vos dernières règles).', {ns: 'register'})}
                                        </font>
                                    </p>
                                </Balloon>
                            </Tile.Content>
                        </Tile>
                    </Form.Group>
                </Form>
            </div>
        );
        return view;
    }
}
