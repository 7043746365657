import didYouKnowPROGRAMS from '@app/img/notif/did-you-know-programs.png';
import didYouKnowPROGRAMSx2 from '@app/img/notif/did-you-know-programs@2x.png';

import didYouKnowOBJECTIVES from '@app/img/notif/did-you-know-objectives.png';
import didYouKnowOBJECTIVESx2 from '@app/img/notif/did-you-know-objectives@2x.png';

import didYouKnowPROFIL from '@app/img/notif/did-you-know-profil.png';
import didYouKnowPROFILx2 from '@app/img/notif/did-you-know-profil@2x.png';

import didYouKnowOC from '@app/img/notif/did-you-know-oc.png';
import didYouKnowOCx2 from '@app/img/notif/did-you-know-oc@2x.png';

import didYouKnowDATAS from '@app/img/notif/did-you-know-datas.png';
import didYouKnowDATASx2 from '@app/img/notif/did-you-know-datas@2x.png';

import didYouKnowBADGES from '@app/img/notif/did-you-know-badges.png';
import didYouKnowBADGESx2 from '@app/img/notif/did-you-know-badges@2x.png';

import didYouKnowNUTRITION from '@app/img/notif/did-you-know-nutrition.png';
import didYouKnowNUTRITIONx2 from '@app/img/notif/did-you-know-nutrition@2x.png';

import didYouKnowFAVORITES from '@app/img/notif/did-you-know-favorites.png';
import didYouKnowFAVORITESx2 from '@app/img/notif/did-you-know-favorites@2x.png';

import icoNoMeteo from '@app/img/meteo/meteo-nometeo.png';
import icoNoMeteox2 from '@app/img/meteo/meteo-nometeo@2x.png';

import logoADHNumber from '@app/img/ico/ico-adh-number.png';
import logoADHNumberx2 from '@app/img/ico/ico-adh-number@2x.png';

export const NOTIF_ROUTES = {
    'ADH' : {'normal': logoADHNumber, 'retina': logoADHNumberx2, 'url': null},
    'METEO' : {'normal': icoNoMeteo, 'retina': icoNoMeteox2, 'url': null},
    'PROGRAMS' : {'normal': didYouKnowPROGRAMS, 'retina': didYouKnowPROGRAMSx2, 'url': '/programs'},
    'OBJECTIVES' : {'normal': didYouKnowOBJECTIVES, 'retina': didYouKnowOBJECTIVESx2, 'url': '/account/objectives'},
    'PROFIL' : {'normal': didYouKnowPROFIL, 'retina': didYouKnowPROFILx2, 'url': '/account/parameters'},
    'OC' : {'normal': didYouKnowOC, 'retina': didYouKnowOCx2, 'url': '/account/oc'},
    'DATAS' : {'normal': didYouKnowDATAS, 'retina': didYouKnowDATASx2, 'url': '/datas'},
    'BADGES' : {'normal': didYouKnowBADGES, 'retina': didYouKnowBADGESx2, 'url': '/account/rewards'},
    'NUTRITION' : {'normal': didYouKnowNUTRITION, 'retina': didYouKnowNUTRITIONx2, 'url': '/repas'},
    'FAVORITES' : {'normal': didYouKnowFAVORITES, 'retina': didYouKnowFAVORITESx2, 'url': '/account/favorites'}
};