import { t, h } from '@app/utils';
import { Card, VerticalScroll, Button } from '@app/elements';
import { dynMod, isDefined } from '@app/core';
import * as staticsconstants from '@app/constants/constants-statics';

export const view = (state, actions) => (props, children) => (
    <dummy>
        <p class='btzTimeline-btzCard-btzTitle btzTitle'>{t('Je choisis mon programme')}</p>
        {isDefined(state.program) ?
            <div class='btzProgramsRunning-btzProgramRunning'>
                <div class='btzBackgroundCover' style={{ 'background-image': 'url(' + (((state.program.media !== null) && (state.program.media !== undefined) && (state.program.media.url !== undefined)) ? state.program.media.url : '') + ')' }}></div>
                <p class='btzPageContentTexte'>{state.program.program.title}</p>

                <div class='btzProgramsRunning-btzProgramsRunningFooter'>
                    {(state.program.sessionToday === true) ?
                        <div>
                            <div class='col-6 col-sm-12 btzProgramsRunning-btzProgramsRunningFooter-btzProgramsRunningFooterLeft'>
                                <p class='btzPageContentSubTitle'>{t('Séance du jour', {ns: 'programs'})} #{state.program.sessionNumber}</p>
                                <p class='btzPageContentSubTexte'>{t('Durée', {ns: 'programs'})} : {state.program.sessionsTotal} séances</p>
                            </div>

                            <div class='col-6 col-sm-12 btzProgramsRunning-btzProgramsRunningFooter-btzProgramsRunningFooterRight'>
                                <Button primary active onclick={() => { if (isDefined(props.displayedon) && (props.displayedon === 'preview')) { return false; } else { actions.startProgramSession(state.program.program.id); } }} >
                                    {t('Commencer', {ns: 'programs'})}
                                </Button>
                            </div>
                        </div>
                        :
                        <div class='col-12'>
                            <p class='btzProgramsRunning-btzProgramsRunningFooter-btzNoProgram'>
                                {t('Pas de séance aujourd‘hui.', {ns: 'programs'})}
                                <br/>
                                {t('Rendez-vous le', {ns: 'programs'}) + ' ' + (state.program.nextSessionReadableDate !== null ? state.program.nextSessionReadableDate : 'Ø')}
                            </p>
                        </div>
                    }
                </div>
            </div>
            :
            <dummy>
                {state.programs === null ?
                    <div class='btzLoadingContainer-btzWrapper'>
                        <div class='btzLoadingContainer'></div>
                    </div>
                    :
                    <VerticalScroll controls={(state.programs.length == 0) ? false : true}>
                        {state.programs.map(item =>
                            <div class='col-4 col-xs-12' onclick={(e) => { actions.showProgramDetail(item.id) }}>
                                <div key={item.id} class='btzProgram-btzProgramRunning-btzProgramRunningWrapper'>
                                    <div class='btzBackgroundCover' style={{ 'background-image': 'url(' + (((item.media !== null) && (item.media !== undefined) && (item.media.url !== undefined)) ? item.media.url : '') + ')' }}></div>
                                    <p class='btzProgram-btzProgramRunning-btzProgramRunningTitle'>{item.title}</p>
                                </div>
                            </div>
                        )}
                    </VerticalScroll>
                }
            </dummy>
        }
    </dummy>
);
