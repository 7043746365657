export const actions = {
    onComponentCreate: (props) => (state, actions) => {
        console.log('serviceWorker : ', navigator, navigator.serviceWorker, navigator.serviceWorker.getRegistrations());
        var swFound = false;
        navigator.serviceWorker.getRegistrations().then(function(registrations) {
            console.log('registrations  = ', registrations);
            for (var registration of registrations) {
                registration.unregister();
                swFound = true;
            }
            if (swFound) {
                window.location.reload();
            }
        });
    },
};
