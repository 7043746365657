import { t, h } from '@app/utils';
import { Button, Card } from '@app/elements';

export const view = (state, actions) => (props, children) => (
    <Card kind={'challenge'}>
        <Card.Header>
            <Card.Image classes={'btzTimelineChallengeImg'}><img src={'/img/timeline/challenge/' + props.valueTheme + '.png'} srcset={`${'/img/timeline/challenge/' + props.valueTheme + '@2x.png'} 2x`} alt='' class='' /></Card.Image>
        </Card.Header>

        <Card.Body style={{ 'padding-top': '105px' }}>
            {!state.why &&
                <div>
                    <p class='btzTimeline-btzCard-btzDescription btzTitle'>{props.title}</p>
                    <div class='btzHr btzDottedHr'></div>
                    <div class='btzChallengeRun-btzChallengeTimeIndicator'>
                        <div class='btzChallengeRun-btzChallengeTimeIndicatorJauge'>
                            <div class='btzChallengeRun-btzChallengeTimeIndicatorInner-btzWrapper'>
                                <div class='btzChallengeRun-btzChallengeTimeIndicatorInner' style={{ 'width': props.percentDuration + '%' }}></div>
                            </div>
                            {props.labelDuration !== 0 ?
                                <p class='btzTexte'>{t('temps restant', {ns: 'timeline'})} : {props.labelDuration + (!isNaN(+props.labelDuration) ? (' ' + (+props.labelDuration > 1 ? t('jours', {ns: 'timeline'}) : t('jour', {ns: 'timeline'}))) : '')}</p>
                                :
                                <p class='btzTexte'>{t('TEMPS ÉCOULÉ !', {ns: 'timeline'})}</p>
                            }
                        </div>
                    </div>
                    <div class='btzHr btzDottedHr'></div>
                </div>
            }
        </Card.Body>

        <Card.Footer style={{ 'text-align': 'center' }}>
            <Button primary loading={(state.isLoading)} onclick={() => { actions.updateParentPropsSUCCESSfalse(props) }} >
                <p class='btzTextColored btzTextColored-btzGreyColored'>{t('Je n‘ai pas réussi', {ns: 'timeline'})}</p>
            </Button>
            <Button primary active loading={(state.isLoading)} onclick={() => { actions.updateParentPropsSUCCESStrue(props) }} >
                <p class='btzTextColored'>{t('J‘ai réussi', {ns: 'timeline'})}</p>
            </Button>
        </Card.Footer>
    </Card>
);
