import { handleErrors } from '@app/api/errors';
import { queueQueries, executeQuery } from '@app/api/controlleur';
import { appCustoSetting } from '@app/settings/settings';
const { API_URL } = appCustoSetting.appsettings;

async function checkInvitationCode(inviteCode) {
    return queueQueries(`${API_URL}/invitation-code/${inviteCode}/`, 'GET', 'application/json', true, true, true, false);
}

function setUserSettings(settings) {
    return queueQueries(`${API_URL}/settings/`, 'POST', 'application/json', true, true, true, true, true, settings);
}

function setUserPregnancy(dataPreg) {
    return queueQueries(`${API_URL}/user/pregnancy-dates/`, 'POST', 'application/json', true, true, true, true, true, dataPreg);
}

function setUserSponsor(sponsor) {
    return queueQueries(`${API_URL}/user/sponsor/`, 'POST', 'application/json', true, true, true, true, true, sponsor);
}

function downloadRGPDDatas() {
    return queueQueries(`${API_URL}/export/datas/`, 'GET');
}

function clearTimeline(userid) {
    return queueQueries(`${API_URL}/admin/users/clear-timelines/${userid}/`, 'POST');
}


/**
 * Calls API to create B2C user account
 * @see https://betterise-api-doc.betterise.me/#api-User-createUser
 * @param {String} accountType Can be "b2c", "company", or "mutual"
 * @param {String} accountEmail Account email
 * @param {String} accountPassword Account password
 * @return Response object
 */
async function createUser(accountType, fields) {
    return queueQueries(`${API_URL}/user/register/${accountType}/`, 'POST', 'application/json', true, true, true, false, true, fields);
}

function refreshUser(refresh, customer = null) {
    if (customer !== null) {
        if (['cerba'].indexOf(customer) > -1) {
            return executeQuery(`${API_URL}/${customer}/session/refresh/`, 'POST', 'application/json', true, true, true, false, true, refresh);
        }
    }
    return executeQuery(`${API_URL}/session/refresh/`, 'POST', 'application/json', true, true, true, false, true, refresh);
}



async function createUserB2C(accountEmail, accountPassword) {
    return await createUser(
        'b2c',
        {
            'email': accountEmail,
            'password': accountPassword
        }
    )
}

async function createUserMutual(accountEmail, accountPassword, mutualName) {
    return await createUser(
        'mutual',
        {
            'email': accountEmail,
            'password': accountPassword,
            'mutual': mutualName
        }
    )
}

async function createUserCompany(accountEmail, accountPassword) {
    return await createUser(
        'company',
        {
            'email': accountEmail,
            'password': accountPassword
        }
    )
}

async function createUserCompanyInvite(accountEmail, accountPassword, membershipId) {
    return await createUser(
        'company-invite',
        {
            'email': accountEmail,
            'password': accountPassword,
            'membership': membershipId
        }
    )
}

/**
 * Calls API to retrieve user informations
 * @see https://betterise-api-doc.betterise.me/#api-User-getUser
 * @return Response object
 */
function getUser(withHeader) {
    return queueQueries(`${API_URL}/user/`, 'GET', 'application/json', true, withHeader)
}
function getUserWithNoRedirect() {
    return queueQueries(`${API_URL}/user/`, 'GET', 'application/json', false, false, false);
}

function getTimezone() {
    return queueQueries(`${API_URL}/timezones/`, 'GET');
}

function getChallenges() {
    return queueQueries(`${API_URL}/challenge/`, 'GET');
}

function getNotifications() {
    return queueQueries(`${API_URL}/notifications/`, 'GET');
}

/**
 * Calls API to retrieve user profile informations
 * @see https://betterise-api-doc.betterise.me/#api-User-getUserProfile
 * @return Response object
 */
function getUserProfile() {
    return queueQueries(`${API_URL}/user/profile/`, 'GET');
}

function getUserQuestion(email) {
    return queueQueries(`${API_URL}/user/question/${email}/`, 'GET');
}

function getUserFavorite(pid) {
    return queueQueries(`${API_URL}/favorite/0/${pid}/`, 'GET');
}

function getUserGamification() {
    return queueQueries(`${API_URL}/gamification/`, 'GET');
}

function getUserGamificationStatus() {
    return queueQueries(`${API_URL}/gamification/status/`, 'GET');
}

function getUserObjectives() {
    return queueQueries(`${API_URL}/objectives/user/`, 'GET');
}

function getObjectives() {
    return queueQueries(`${API_URL}/objectives/`, 'GET');
}

function addObjective(idobj) {
    return queueQueries(`${API_URL}/objectives/${idobj}/`, 'POST');
}

function removeObjective(idobj) {
    return queueQueries(`${API_URL}/objectives/${idobj}/`, 'DELETE', 'text/plain');
}



/**
 * Calls API to update user informations
 * @see https://betterise-api-doc.betterise.me/#api-User-updateUser
 * @param {any} fields
 * @return Response object
 */
async function updateUser(userData) {
    return queueQueries(`${API_URL}/user/`, 'PUT', 'application/json', true, true, true, true, true, userData);
}

/**
 * Calls API to set new password
 * @see https://betterise-api-doc.betterise.me/#api-User-newPassword
 * @param {any} accountNewPassword New password
 * @return Response object
 */
async function newPassword(pwdData) {
    return queueQueries(`${API_URL}/session/new-password/`, 'POST', 'application/json', true, true, true, true, true, pwdData);
}


function getUserMoney(moneyData) {
    return queueQueries(`${API_URL}/stripe/`, 'POST', 'application/json', true, false, false, true, true, moneyData);
}

function sendFeedback(feedback) {
    return queueQueries(`${API_URL}/feedback/`, 'POST', 'application/json', true, true, true, true, true, feedback);
}

function getUserPayment() {
    return queueQueries(`${API_URL}/payment/`, 'GET');
}

function resiliate() {
    return queueQueries(`${API_URL}/user/`, 'DELETE');
}

function devicesAcknowledge(id, secret, verified) {
    return queueQueries(`${API_URL}/devices/acknowledge/${id}/${secret}/${verified}`, 'GET', 'application/json', true, true, true, false, true, null);
}

function sendAvatar(media) {
    let formData = new FormData();
    formData.append('media', media.media[0]);
    return queueQueries(`${API_URL}/user/media/`, 'POST', null, true, true, true, true, true, formData);
}


export const User = {
    checkInvitationCode,
    createUser,
    setUserPregnancy,
    refreshUser,
    createUserB2C,
    createUserCompany,
    createUserCompanyInvite,
    createUserMutual,
    getUser,
    getUserWithNoRedirect,
    getUserProfile,
    getChallenges,
    updateUser,
    newPassword,
    getUserFavorite,
    getUserGamification,
    getUserGamificationStatus,
    getUserObjectives,
    getObjectives,
    getNotifications,
    getTimezone,
    addObjective,
    removeObjective,
    getUserMoney,
    getUserPayment,
    getUserQuestion,
    setUserSettings,
    setUserSponsor,
    downloadRGPDDatas,
    resiliate,
    sendAvatar,
    sendFeedback,
    devicesAcknowledge,
    clearTimeline,
};
