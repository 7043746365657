export const state = {
    rendering: false,
    display: {
        why: false,
        accepted: false,
    },
    status: {
        success: false,
    }
};
