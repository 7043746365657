import { location } from '@hyperapp/router';
import { FETCHS_CONTROLLERS } from '@app/api/controlleur';
import { Loader } from '@app/api/loader';

const withRouting = () => (nextApp) => (initialState, initialActions, initialView, initialRoot) => {
    const enhancedActions = {
        cancelGET: () => {
            console.log('ABORTING ALL GET QUERIES ?')
            for (var g in FETCHS_CONTROLLERS.get) {
                console.log('ABORTING ALL GET QUERIES !!!!!!!!', FETCHS_CONTROLLERS.get[g])
                FETCHS_CONTROLLERS.get[g].abort()
                Loader.removeQuery();
            }
            FETCHS_CONTROLLERS.get = [];
        }
    }

    window.addEventListener('pushstate', enhancedActions.cancelGET);

    return nextApp(
        initialState,
        { ...initialActions, ...enhancedActions, ...location.actions },
        initialView,
        initialRoot,
    )
}

export { withRouting };