import { t, h } from '@app/utils';
import { addCSRFToken } from '@app/core';
import { allRoutes } from '@app/settings';
import { Button, Tile, Card, Balloon, Avatar, Convert, TextTransform } from '@app/elements';
import * as constants from '@app/constants';

import icoNoChange from '@app/img/dashboard/ico-dash-nochange.png';
import icoNoChangex2 from '@app/img/dashboard/ico-dash-nochange@2x.png';

import icoUp from '@app/img/dashboard/ico-dash-up.png';
import icoUpx2 from '@app/img/dashboard/ico-dash-up@2x.png';

import icoUpInverted from '@app/img/dashboard/ico-dash-up-yellow.png';
import icoUpInvertedx2 from '@app/img/dashboard/ico-dash-up-yellow@2x.png';

import icoUpUncolored from '@app/img/dashboard/ico-dash-up-uncolored.png';
import icoUpUncoloredx2 from '@app/img/dashboard/ico-dash-up-uncolored@2x.png';

import icoDown from '@app/img/dashboard/ico-dash-down.png';
import icoDownx2 from '@app/img/dashboard/ico-dash-down@2x.png';

import icoDownInverted from '@app/img/dashboard/ico-dash-down-green.png';
import icoDownInvertedx2 from '@app/img/dashboard/ico-dash-down-green@2x.png';

import icoDownUncolored from '@app/img/dashboard/ico-dash-down-uncolored.png';
import icoDownUncoloredx2 from '@app/img/dashboard/ico-dash-down-uncolored@2x.png';

import icoLegendSleep from '@app/img/food/ico-legend-sleep.png';
import icoLegendSleepx2 from '@app/img/food/ico-legend-sleep@2x.png';

import icoLegendSport from '@app/img/food/ico-legend-sport.png';
import icoLegendSportx2 from '@app/img/food/ico-legend-sport@2x.png';

import Questions from './questions';
import * as staticsconstants from '@app/constants/constants-statics';

export const view = (state, actions) => (props, children) => (
    <div id={props.kind} class={'btzDashboards-btzDashboardWrapper ' + (props.displayed === true ? '' : 'btzDashboards-btzDashboardWrapper-btzHidden')} style={{ 'position': 'relative' }}>
        <div key={'formulaire-' + props.kind}>
            {(state.displayQuestion === true) &&
                <div class='btzDashboards-btzDashboard-btzAbsoluteQuestion' data-val={state.defaultValue} data-displayQuestion={state.displayQuestion} data-theme={props.kind}>
                    <Questions updateParticularHeight={actions.updateParticularHeight} updateDataViz={actions.sendUniqThemeQueryAndUpdateDataViz} theme={props.kind} queryTheme={props.queryKind} date={state.defaultSelected + ''} readableDate={state.readableDate} cancelAction={actions.cancelQuestion} defaultValue={state.defaultValue} metrics={props.metrics} customer={props.customer} particularHeight={state.particularHeight}></Questions>
                </div>
            }
        </div>

        <Card kind={'datas'} style={{ 'margin-bottom': '2px' }}>
            <div class='btzDashboards-btzDashboard'>
                <div class='btzDashboards-btzDashboard-btzHeader'>
                    <p class='btzDashboards-btzDashboard-btzHeader-btzKind btzTextColored' data-theme={props.kind}>{props.readableKind}</p>
                    <p class='btzDashboards-btzDashboard-btzHeader-btzWeek'>{state.readableDate}</p>
                    <p class='btzDashboards-btzDashboard-btzHeader-btzInfos'>{t(state.theme.label)} {state.theme.unit}</p>
                    {((state.kind === 'sleep') || (state.kind === 'sport')) &&
                        <div class='btzDashboards-btzDashboard-btzLegend'>
                            <div class='btzDashboards-btzDashboard-btzLegendHover'>
                                {state.kind === 'sleep' ?
                                    <img src={icoLegendSleep} srcset={`${icoLegendSleepx2} 2x`} />
                                    :
                                    <img src={icoLegendSport} srcset={`${icoLegendSportx2} 2x`} />
                                }
                            </div>
                            <div class={'btzDashboards-btzDashboard-btzLegendHovered btz' + state.kind}>
                                {state.kind === 'sleep' ?
                                    <div>
                                        {constants.custo.DASH_SLEEP.filter((sleep) => sleep.inlegend === true).map((legend) =>
                                            <div class='btzDashboards-btzDashboard-btzLegendHovered-btEntry'>
                                                <div class='btzDashboards-btzDashboard-btzLegendHovered-btEntry-btzPuce' style={{ 'background-color': legend.color }}></div>
                                                {t(legend.readable)}
                                            </div>
                                        )}
                                    </div>
                                    :
                                    <div>
                                        {constants.custo.DASH_SPORTS.filter((sport) => sport.inlegend === true).map((legend) =>
                                            <div class='btzDashboards-btzDashboard-btzLegendHovered-btEntry'>
                                                <div class='btzDashboards-btzDashboard-btzLegendHovered-btEntry-btzPuce' style={{ 'background-color': legend.color }}></div>
                                                {t(legend.readable)}
                                            </div>
                                        )}
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>

                <div class='btzDashboards-btzDashboard-btzBody'>
                    <svg oncreate={actions.createDataViz} data-kind={props.kind} width={state.width} height={state.height}></svg>
                </div>
            </div>
        </Card>

        <div class='btzDashboards-btzDashboard'>
            <div class={'col-12 btzDashboards-btzDashboard-btzFooter p-0'}>
                <div class='col-6 col-sm-12 btzDashboards-btzDashboard-btzFooter-btzContentWrapper p-0'>
                    <Card kind={'datas'} style={{ 'margin-bottom': '0px', 'display': 'table', 'width': '100%' }}>
                        <Tile classes={'btzAlfred'}>
                            <Tile.Icon classes={''}>
                                <Avatar.Alfred classes={'centered'} />
                            </Tile.Icon>
                            <Tile.Content>
                                <Balloon kind={'info'} style={{ 'white-space': 'normal' }}>{state.risedData !== '' ? state.risedData : t('Je n‘ai aucune remarque pour vous, aujourd‘hui. Continuez à renseigner vos données.', {ns: 'datas'})}</Balloon>
                            </Tile.Content>
                        </Tile>
                    </Card>
                </div>

                {state.variations !== null &&
                    <div class='col-6 col-sm-12 btzDashboards-btzDashboard-btzFooter-btzContentWrapper p-0' style={{ 'text-align': 'left', 'margin-bottom': '2px' }}>
                        <div class='col-6 col-sm-12 p-0' style={{ 'vertical-align': 'middle' }}>
                            <Card kind={'datas'} style={{ 'margin-bottom': '0px' }}>
                                <div>
                                    <div class='btzDashboards-btzDashboard-btzInfos'>
                                        <div class='btzDashboards-btzDashboard-btzInfos-btzHeader'>
                                            <p class='btzDashboards-btzDashboard-btzInfos-btzHeader-btzTitle'>
                                                {t('VARIATIONS', {ns: 'datas'})}
                                            </p>
                                            <p class='btzDashboards-btzDashboard-btzInfos-btzHeader-btzSubTitle'>sur 24h</p>
                                        </div>
                                        {state.variations.diffYesterday !== undefined &&
                                            <div>
                                                {((state.variations.diffYesterday.diff === null) || (state.variations.diffYesterday.diff === 0)) &&
                                                    <img src={icoNoChange} srcset={`${icoNoChangex2} 2x`} alt='' class='btzDashboards-btzDashboard-btzInfos-btzHeader-btzVariations' />
                                                }
                                                {state.variations.diffYesterday.diff < 0 &&
                                                    <div>
                                                        {state.kind === 'followed-weight' &&
                                                            <img src={icoDownUncolored} srcset={`${icoDownUncoloredx2} 2x`} alt='' class='btzDashboards-btzDashboard-btzInfos-btzHeader-btzVariations' />
                                                        }
                                                        {state.kind !== 'followed-weight' &&
                                                            <img src={(state.variationsInverted ? icoDownInverted : icoDown)} srcset={`${(state.variationsInverted ? icoDownInvertedx2 : icoDownx2)} 2x`} alt='' class='btzDashboards-btzDashboard-btzInfos-btzHeader-btzVariations' />
                                                        }
                                                    </div>
                                                }
                                                {state.variations.diffYesterday.diff > 0 &&
                                                    <div>
                                                        {state.kind === 'followed-weight' &&
                                                            <img src={icoUpUncolored} srcset={`${icoUpUncoloredx2} 2x`} alt='' class='btzDashboards-btzDashboard-btzInfos-btzHeader-btzVariations' />
                                                        }
                                                        {state.kind !== 'followed-weight' &&
                                                            <img src={(state.variationsInverted ? icoUpInverted : icoUp)} srcset={`${(state.variationsInverted ? icoUpInvertedx2 : icoUpx2)} 2x`} alt='' class='btzDashboards-btzDashboard-btzInfos-btzHeader-btzVariations' />
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                    <div class='btzDashboards-btzDashboard-btzInfos-btzBody'>
                                        {state.variations.diffYesterday !== undefined &&
                                            <div>
                                                {(state.variations.diffYesterday.diff === null) &&
                                                    <p class=''>{t('pas assez de données pour cette analyse', {ns: 'datas'})}</p>
                                                }

                                                {(state.variations.diffYesterday.diff === 0) &&
                                                    <p class=''>{t('Identique', {ns: 'datas'})} <font oncreate={(el) => actions.getDate({'el' : el, 'dat' : state.variations.diffYesterday.date}) }></font></p>
                                                }

                                                {(state.variations.diffYesterday.diff !== null) &&
                                                    <div>
                                                        {state.variations.diffYesterday.diff < 0 &&
                                                            <p class=''>{t('En baisse de', {ns: 'datas'})} <b><Convert type={props.convertTo} userMetric={props.convertToMetric} from={props.convertFrom}>{Math.abs(state.variations.diffYesterday.diff)}</Convert></b> <font key={'variation-' + state.variations.diffYesterday.date} oncreate={(el) => actions.getDate({'el' : el, 'dat' : state.variations.diffYesterday.date}) }></font>.</p>
                                                        }
                                                        {state.variations.diffYesterday.diff > 0 &&
                                                            <p class=''>{t('En hausse de', {ns: 'datas'})} <b><Convert type={props.convertTo} userMetric={props.convertToMetric} from={props.convertFrom}>{Math.abs(state.variations.diffYesterday.diff)}</Convert></b> <font key={'variation-' + state.variations.diffYesterday.date} oncreate={(el) => actions.getDate({'el' : el, 'dat' : state.variations.diffYesterday.date}) }></font>.</p>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <div class='col-6 col-sm-12 p-0' style={{ 'vertical-align': 'middle' }}>
                            <Card kind={'datas'} style={{ 'margin-bottom': '0px' }}>
                                <div>
                                    <div class='btzDashboards-btzDashboard-btzInfos'>
                                        <div class='btzDashboards-btzDashboard-btzInfos-btzHeader'>
                                            <p class='btzDashboards-btzDashboard-btzInfos-btzHeader-btzTitle'>{t('VARIATIONS', {ns: 'datas'})}</p>
                                            <p class='btzDashboards-btzDashboard-btzInfos-btzHeader-btzSubTitle'>{t('sur 7 jours', {ns: 'datas'})}</p>
                                        </div>
                                        {state.variations.diffWeekAgo &&
                                            <div>
                                                {((state.variations.diffWeekAgo.diff === null) || (state.variations.diffWeekAgo.diff === 0)) &&
                                                    <img src={icoNoChange} srcset={`${icoNoChangex2} 2x`} alt='' class='btzDashboards-btzDashboard-btzInfos-btzHeader-btzVariations' />
                                                }
                                                {state.variations.diffWeekAgo.diff < 0 &&
                                                    <div>
                                                        {state.kind === 'followed-weight' &&
                                                            <img src={icoDownUncolored} srcset={`${icoDownUncoloredx2} 2x`} alt='' class='btzDashboards-btzDashboard-btzInfos-btzHeader-btzVariations' />
                                                        }
                                                        {state.kind !== 'followed-weight' &&
                                                            <img src={(state.variationsInverted ? icoDownInverted : icoDown)} srcset={`${(state.variationsInverted ? icoDownInvertedx2 : icoDownx2)} 2x`} alt='' class='btzDashboards-btzDashboard-btzInfos-btzHeader-btzVariations' />
                                                        }
                                                    </div>
                                                }
                                                {state.variations.diffWeekAgo.diff > 0 &&
                                                    <div>
                                                        {state.kind === 'followed-weight' &&
                                                            <img src={icoUpUncolored} srcset={`${icoUpUncoloredx2} 2x`} alt='' class='btzDashboards-btzDashboard-btzInfos-btzHeader-btzVariations' />
                                                        }
                                                        {state.kind !== 'followed-weight' &&
                                                            <img src={(state.variationsInverted ? icoUpInverted : icoUp)} srcset={`${(state.variationsInverted ? icoUpInvertedx2 : icoUpx2)} 2x`} alt='' class='btzDashboards-btzDashboard-btzInfos-btzHeader-btzVariations' />
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                    <div class='btzDashboards-btzDashboard-btzInfos-btzBody'>
                                        {state.variations.diffWeekAgo &&
                                            <div>
                                                {(state.variations.diffWeekAgo.diff === null) &&
                                                    <p class=''>{t('pas assez de données pour cette analyse', {ns: 'datas'})}</p>
                                                }

                                                {(state.variations.diffWeekAgo.diff === 0) &&
                                                    <p class=''>{t('Identique', {ns: 'datas'})} <font oncreate={(el) => actions.getDate({'el' : el, 'dat' : state.variations.diffWeekAgo.date}) }></font></p>
                                                }

                                                {(state.variations.diffWeekAgo.diff !== null) &&
                                                    <div>
                                                        {state.variations.diffWeekAgo.diff < 0 &&
                                                            <p class=''>{t('En baisse de', {ns: 'datas'})} <b><Convert type={props.convertTo} userMetric={props.convertToMetric} from={props.convertFrom}>{Math.abs(state.variations.diffWeekAgo.diff)}</Convert></b> <font key={'variation-' + state.variations.diffWeekAgo.date} oncreate={(el) => actions.getDate({'el' : el, 'dat' : state.variations.diffWeekAgo.date}) }></font>.</p>
                                                        }
                                                        {state.variations.diffWeekAgo.diff > 0 &&
                                                            <p class=''>{t('En hausse de', {ns: 'datas'})} <b><Convert type={props.convertTo} userMetric={props.convertToMetric} from={props.convertFrom}>{Math.abs(state.variations.diffWeekAgo.diff)}</Convert></b> <font key={'variation-' + state.variations.diffWeekAgo.date} oncreate={(el) => actions.getDate({'el' : el, 'dat' : state.variations.diffWeekAgo.date}) }></font>.</p>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                }

                <div class={((state.variations === null) ? 'col-3 btzDashboards-btzDashboard-btzFooter-btzContentWrapper' : 'col-4 btzDashboards-btzDashboard-btzInfos-btzWrapper') + ' col-sm-12 p-0'}>
                    <Card kind={'datas'} classes={((state.variations === null) ? '' : 'btzShowMore ') + (state.showMore === true ? 'active' : '')}>
                        <div>
                            <div class='btzDashboards-btzDashboard-btzInfos'>
                                <div class='btzDashboards-btzDashboard-btzInfos-btzHeader'>
                                    <p class='btzDashboards-btzDashboard-btzInfos-btzHeader-btzTitle'>
                                        {state.extremAndMeanConfig.mean &&
                                            t('EXTRÊMES & MOYENNE', {ns: 'datas'})
                                        }
                                        {state.extremAndMeanConfig.total &&
                                            t('EXTRÊMES & TOTAL', {ns: 'datas'})
                                        }
                                        {state.extremAndMeanConfig.weight &&
                                            t('MOYENNES', {ns: 'datas'})
                                        }
                                    </p>
                                    <p class='btzDashboards-btzDashboard-btzInfos-btzHeader-btzSubTitle'>{state.modeLabelPrefixed}</p>
                                </div>
                            </div>
                            {state.extremAndMean !== null &&
                                <div class='btzDashboards-btzDashboard-btzInfos-btzBody btzDashboards-btzDashboard-btzExtAndMean-btzBody'>
                                    {state.extremAndMeanConfig.min &&
                                        <p class=''><b>{t('Min', {ns: 'datas'})}</b> {state.modeLabel} : <Convert type={props.extrmConvertTo} userMetric={props.convertToMetric} from={props.convertFrom}>{state.extremAndMean.min}</Convert></p>
                                    }
                                    {state.extremAndMeanConfig.max &&
                                        <p class=''><b>{t('Max', {ns: 'datas'})}</b> {state.modeLabel} : <Convert type={props.extrmConvertTo} userMetric={props.convertToMetric} from={props.convertFrom}>{state.extremAndMean.max}</Convert></p>
                                    }
                                    {state.extremAndMeanConfig.mean &&
                                        <p class=''><b>{t('Moyenne', {ns: 'datas'})}</b> {state.modeLabel} : <Convert type={props.extrmConvertTo} userMetric={props.convertToMetric} from={props.convertFrom}>{state.extremAndMean.mean}</Convert></p>
                                    }
                                    {state.extremAndMeanConfig.total &&
                                        <p class=''><b>{t('Total', {ns: 'datas'})}</b> {state.modeLabel} : <Convert type={props.extrmConvertTo} userMetric={props.convertToMetric} from={props.convertFrom}>{state.extremAndMean.total}</Convert></p>
                                    }
                                    {state.extremAndMeanConfig.weight &&
                                        <p class=''><b>{t('Poids moyen', {ns: 'datas'})}</b> : <Convert type={props.extrmConvertTo} userMetric={props.convertToMetric} from={'weight'}>{state.extremAndMean.weight}</Convert></p>
                                    }
                                    {state.extremAndMeanConfig.imc &&
                                        <p class=''><b>{t('IMC moyen', {ns: 'datas'})}</b> : {state.extremAndMean.imc}</p>
                                    }
                                </div>
                            }
                        </div>
                    </Card>
                </div>
                {state.qualitySleep &&
                    <div class={((state.variations === null) ? 'col-3 btzDashboards-btzDashboard-btzFooter-btzContentWrapper' : 'col-4 btzDashboards-btzDashboard-btzInfos-btzWrapper') + ' col-sm-12 p-0'}>
                        <Card kind={'datas'} classes={((state.variations === null) ? '' : 'btzShowMore ') + (state.showMore === true ? 'active' : '')}>
                            <div style={{ 'max-height': '100%' }}>
                                <div class='btzDashboards-btzDashboard-btzInfos'>
                                    <div class='btzDashboards-btzDashboard-btzInfos-btzHeader'>
                                        <p class='btzDashboards-btzDashboard-btzInfos-btzHeader-btzTitle'>{t('QUALITÉ DE SOMMEIL', {ns: 'datas'})}</p>
                                        <p class='btzDashboards-btzDashboard-btzInfos-btzHeader-btzSubTitle'>{state.modeLabelPrefixed}</p>
                                    </div>
                                </div>
                                <div class='btzDashboards-btzDashboard-btzInfos-btzBody btzDashboards-btzDashboard-btzExtAndMean-btzBody'>
                                    {state.qualitySleep !== null &&
                                        <div class='btzDashboards-btzDashboard-btzInfos-btzBody-btzLegends'>
                                            <p class='btzDashboards-btzDashboard-btzInfos-btzBody-btzLegend'>{t('Très bien', {ns: 'datas'}) + ' : ' + (state.qualitySleep.filter((item) => +item.quality === 10).length > 0 ? state.qualitySleep.filter((item) => +item.quality === 10)[0].percent + '%' : 'Ø')}</p>
                                            <p class='btzDashboards-btzDashboard-btzInfos-btzBody-btzLegend'>{t('Bien', {ns: 'datas'}) + ' : ' + (state.qualitySleep.filter((item) => +item.quality === 7).length > 0 ? state.qualitySleep.filter((item) => +item.quality === 7)[0].percent + '%' : 'Ø')}</p>
                                            <p class='btzDashboards-btzDashboard-btzInfos-btzBody-btzLegend'>{t('Mal', {ns: 'datas'}) + ' : ' + (state.qualitySleep.filter((item) => +item.quality === 4).length > 0 ? state.qualitySleep.filter((item) => +item.quality === 4)[0].percent + '%' : 'Ø')}</p>
                                            <p class='btzDashboards-btzDashboard-btzInfos-btzBody-btzLegend'>{t('Très mal', {ns: 'datas'}) + ' : ' + (state.qualitySleep.filter((item) => +item.quality === 1).length > 0 ? state.qualitySleep.filter((item) => +item.quality === 1)[0].percent + '%' : 'Ø')}</p>
                                        </div>
                                    }
                                    <svg onupdate={actions.updateQualitySleepDataViz} data-kind={props.kind} width={78} height={78}></svg>
                                </div>
                            </div>
                        </Card>
                    </div>
                }
                {((state.kind === 'activity') || (state.kind === 'sport') || (state.kind === 'denivele') || (state.kind === 'sleep')) &&
                    <div class={((state.variations === null && state.mode === 'year' && state.kind !== 'sleep') ? 'col-3 btzDashboards-btzDashboard-btzFooter-btzContentWrapper' : 'col-4 btzDashboards-btzDashboard-btzInfos-btzWrapper') + ' col-sm-12 p-0'}>
                        {((staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()].modules.accountMod.oc === true) && (staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()].modules.accountMod.ocSpecific[state.kind] === true)) ?
                            <Card kind={'datas'} classes={((state.variations === null && state.mode === 'year' && state.kind !== 'sleep') ? '' : 'btzShowMore ') + (state.showMore === true ? 'active' : '')}>
                                <div style={{ 'max-height': '100%' }}>
                                    <div class='btzDashboards-btzDashboard-btzInfos'>
                                        <div class='btzDashboards-btzDashboard-btzInfos-btzHeader'>
                                            <p class='btzDashboards-btzDashboard-btzInfos-btzHeader-btzTitle'>
                                                {t('OBJET CONNECTÉ', {ns: 'datas'})}
                                            </p>
                                            {state.themeTracker !== null &&
                                                <p class='btzDashboards-btzDashboard-btzInfos-btzHeader-btzSubTitle'>
                                                    <TextTransform mode='capitalize'>{state.themeTracker.brand}</TextTransform> - {state.themeTracker.name}
                                                </p>
                                            }
                                        </div>
                                    </div>
                                    {state.themeTracker === null &&
                                        <div class='btzDashboards-btzDashboard-btzInfos-btzBody'>
                                            <p class=''>
                                                {t('Vous n’avez aucun objet connecté lié à cette donnée.', {ns: 'datas'})}
                                            </p>

                                            <Button primary active small style={{ 'margin': '5px auto 0', 'white-space': 'nowrap' }} onclick={() => {
                                                window.location.href = addCSRFToken(allRoutes['account.oc'].pathname);
                                            }}>
                                                {t('Connecter un objet', {ns: 'datas'})}
                                            </Button>
                                        </div>
                                    }
                                    {state.themeTracker !== null &&
                                        <div class='btzDashboards-btzDashboard-btzInfos-btzBody'>
                                            <img src={state.themeTracker.img} alt='' class='' style={{ 'height': '85px' }} />
                                        </div>
                                    }
                                </div>
                            </Card>
                            :
                            <div></div>
                        }
                    </div>
                }
                {((state.mode === 'year') && (state.kind === 'sleep')) &&
                    <div class='col-8 col-sm-12 p-0 btzDashboards-btzDashboard-btzInfos-btzWrapper'></div>
                }
                {((state.mode === 'year') && (state.kind !== 'sleep')) &&
                    <div class='col-3 col-sm-12 p-0 btzDashboards-btzDashboard-btzInfos-btzWrapper'></div>
                }
                {state.nShowMore < 3 &&
                    <div class='col-4 col-sm-12 p-0 btzDashboards-btzDashboard-btzInfos-btzWrapper'></div>
                }
                {state.nShowMore < 2 &&
                    <div class='col-4 col-sm-12 p-0 btzDashboards-btzDashboard-btzInfos-btzWrapper'></div>
                }
            </div>

            {((state.mode !== 'year') || ((state.mode === 'year') && (state.nShowMore - 2 > 0))) &&
                <Button primary small colored onclick={() => {
                    actions.setShowMore(!state.showMore)
                }} data-theme={props.kind}>
                    {state.showMore === true ? t('Afficher moins', {ns: 'datas'}) : t('Afficher tout', {ns: 'datas'}) + ' ' + '(' + ((state.mode === 'year') ? (state.nShowMore - 2) : state.nShowMore) + ')'}
                </Button>
            }
        </div>
    </div>
);