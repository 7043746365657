import { getClosest } from '@app/core';
import { t, h, Component } from '@app/utils';
import { Card, Link } from '@app/elements';
import './index.scss';

import icoValidQCM from '@app/img/ico/ico-valid-white.png';
import icoValidQCMx2 from '@app/img/ico/ico-valid-white@2x.png';

const state = {
    eval: null,
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        actions.setEval(props);
    },
    setEval: newState => state => ({
        eval: newState,
    }),
    selectThisAnswer: (el) => (state, actions) => {
        var toadd = false;
        if (el.target.getAttribute('data-kind') === 'qcm') {
            if (el.target.classList.contains('active')) {
                el.target.classList.remove('active');
                el.target.classList.remove('btzCurrentEvaluationMarker');
            } else {
                el.target.classList.add('active');
                el.target.classList.add('btzCurrentEvaluationMarker');
                toadd = true;
            }
        } else {
            var els = getClosest(el.target, '.btzOnboarding-btzButtonsWrapper').querySelectorAll('.btzOnBoarding-btzButton');
            for (var elem in els) {
                if (els[elem] && (els[elem].classList !== undefined)) {
                    els[elem].classList.remove('active');
                    els[elem].classList.remove('btzCurrentEvaluationMarker');
                }
            }

            el.target.classList.add('active');
            el.target.classList.add('btzCurrentEvaluationMarker');
            toadd = true;
        }

        return toadd;
    },
};

const view = (state, actions) => (props, children) => (
    <div>
        {props.displayedon == 'session' &&
            <Card kind={'evaluation'} id={'evaluation-' + props.id} data-theme={props.theme}>
                <div>
                    <Card.Header>
                        {state.eval !== null &&
                            <p class='btzTimeline-btzCard-btzDescription btzTexte'>{state.eval.title}</p>
                        }
                    </Card.Header>

                    <Card.Body>
                        {(state.eval !== null && (state.eval.forms.length > 0)) && (
                            <div key={'form-' + state.eval.id}>
                                {state.eval.forms[0].type.toLowerCase() == 'slider' && (
                                    <div class='p-12 btzOnboarding-btzButtonsWrapper btzOnboarding-btzButtonsWrapper-slider'>
                                        {(Array.apply(null, { length: state.eval.forms[0].datas.length }).map(Number.call, Number)).map((i) =>
                                            <div onclick={(el) => {
                                                props.addValues({name: state.eval.forms[0].tag, value: i, toadd: actions.selectThisAnswer(el)});
                                            }} data-id={state.eval.forms.id} data-tag={state.eval.forms[0].tag} data-value={i} data-kind='slider' class='btzOnBoarding-btzButton btzOnBoarding-btzButton-btzNumber'>{i}</div>
                                        )}
                                    </div>
                                )}
                                {/*state.eval.forms[0].type.toLowerCase() == 'number' && (
                                    <div class='btzOnboarding-btzButtonsWrapper btzOnboarding-btzButtonsWrapper-number'>
                                        <Counter key={state.eval.forms.id} class='btzCurrentEvaluationMarker' min={0} max={1000} did={state.eval.forms.id} kind='number' tag={state.eval.form[0].tag} label={state.eval.forms.label}></Counter>
                                    </div>
                                )*/}
                                {state.eval.forms[0].type.toLowerCase() == 'qcm' && (
                                    <div class='btzOnboarding-btzButtonsWrapper btzOnboarding-btzButtonsWrapper-qcm'>
                                        {state.eval.forms.map(item =>
                                            <div class='col-6 col-md-12'>
                                                {item.datas[0] !== undefined &&
                                                    <div onclick={(el) => {
                                                        props.addValues({name: item.tag, value: item.datas[0].value, toadd: actions.selectThisAnswer(el)});
                                                    }} data-id={state.eval.forms[0].id} data-tag={item.tag} data-kind='qcm' data-value={item.datas[0].value} class='btzOnBoarding-btzButton btzOnBoarding-btzQcm-btzLabel'>
                                                        <div class='btzOnboarding-btzButtonsWrapper-QcmPuce' data-theme={props.theme}>
                                                            <img src={icoValidQCM} srcset={`${icoValidQCMx2} 2x`} alt='' />
                                                        </div>
                                                        <p>{item.datas[0].name}</p>
                                                    </div>
                                                }
                                            </div>
                                        )}
                                    </div>
                                )}
                                {state.eval.forms[0].type.toLowerCase() == 'button' && (
                                    <div class='btzOnboarding-btzButtonsWrapper btzOnboarding-btzButtonsWrapper-button' data-length={state.eval.forms.length}>
                                        {state.eval.forms.map(item =>
                                            <div class='col-6 col-md-12'>
                                                {item.datas[0] !== undefined &&
                                                    <div onclick={(el) => {
                                                        props.addValues({name: state.eval.forms[0].tag, value: item.datas[0].value, toadd: actions.selectThisAnswer(el)});
                                                    }} data-id={state.eval.forms.id} data-tag={state.eval.forms[0].tag} data-kind='button' data-value={item.datas[0].value} class='btzOnBoarding-btzButton btzOnBoarding-btzSlider-btzLabel'>
                                                        <p>{item.datas[0].name}</p>
                                                    </div>
                                                }
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </Card.Body>
                </div>
            </Card>
        }

        {props.displayedon == 'summary' &&
            <div class='btzProgram-btzLeftMenu-btzEntry' data-theme={props.theme}><Link scrollto={'evaluation-' + props.id} offsetHeader={100}>{t('Évaluation', {ns: 'programs'})}</Link></div>
        }
    </div>
);

export default Component(state, actions, view, 'evaluation');
