import { t, h, Component, isLength } from '@app/utils';
import { convertMetricsLabels, convertMetrics, isDefined } from '@app/core';
import { Data } from '@app/api';
import * as constants from '@app/constants';
import { Tile, Balloon, Form, Button, Avatar, Counter } from '@app/elements';
import './index.scss';

import icoAddData from '@app/img/ico/ico-adddatas-white.png';
import icoAddDatax2 from '@app/img/ico/ico-adddatas-white@2x.png';

import icoAddDataCerba from '@app/img/ico/cerba/ico-adddatas-black.png';
import icoAddDataCerbax2 from '@app/img/ico/cerba/ico-adddatas-black@2x.png';

const FollowedWeightForm = Component(
    {
        questionStep: 0,
        question: ['Combien pesez-vous ? (en kg)', 'Combien mesurez-vous ? (en cm)'],
        i18nquestion: [t('Combien pesez-vous ? (en kg)', {ns: 'datas'}), t('Combien mesurez-vous ? (en cm)', {ns: 'datas'})],
        form: {
            type: ['number', 'number'],
            tag: ['PERSONAL_WEIGHT', 'PERSONAL_HEIGHT'],
            values: null,
            label: ['kilogrammes', 'cm']
        },
        source: 'betterise',
        date: null,
        readableDate: null,
        help: ['Saisissez votre poids.', 'Saisissez votre taille.'],
        i18nhelp: [t('Saisissez votre poids.', {ns: 'datas'}), t('Saisissez votre taille.', {ns: 'datas'})],
        i18nExtrakey: [t('poids', {ns: 'datas'}), t('taille', {ns: 'datas'})],
        error: '',
        activeValidation: false,
        formIsSubmitting: false,
        particularHeight: 0,
        values: [],
        defaultValue: null,
        metrics: null,
        currentHeight: null,
        currentWeight: null,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (state.values.length > 0) {
                state.values = [];
                // let newDefaultValues = [state.values[0].value, (state.values[1] !== undefined ? state.values[1].value : 0)];
                // actions.setDefaultValue(newDefaultValues);
            } else if (props.defaultValue) {
                actions.setDefaultValue(props.defaultValue);
            }
            if (props.particularHeight && (+props.particularHeight > 0)) {
                actions.setParticularHeight(props.particularHeight);
            }
            if (props.metrics) {
                state.question = [convertMetricsLabels(true, 'weight', props.metrics.weight, state.question[0]), convertMetricsLabels(true, 'height', props.metrics.height, state.question[1])];
                state.form.label = [convertMetricsLabels(false, 'weight', props.metrics.weight, state.form.label[0]), convertMetricsLabels(true, 'height', props.metrics.height, state.form.label[1])];
                actions.setMetrics(props.metrics);
            }
            //
            if (props.date) {
                actions.setReadableDate(actions.getDate(props.date));
                actions.setDate(actions.createDateObj(props.date));
            }

            let currentHeight = (state.values.filter((f) => f.tag === 'PERSONAL_HEIGHT').length > 0 ? state.values.filter((f) => f.tag === 'PERSONAL_HEIGHT')[0].value : (((props.defaultValue !== null) && (props.defaultValue[1] !== 'null')) ? props.defaultValue[1] : props.particularHeight))
            let currentWeight = (state.values.filter((f) => f.tag === 'PERSONAL_WEIGHT').length > 0 ? state.values.filter((f) => f.tag === 'PERSONAL_WEIGHT')[0].value : (((props.defaultValue !== null) && (props.defaultValue[0] !== 'null')) ? props.defaultValue[0] : ''))
            actions.setCurrentWeight(currentWeight);
            actions.setCurrentHeight(currentHeight);
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (state.values.length > 0) {
                let newDefaultValues = [state.values[0].value, (state.values[1] !== undefined ? state.values[1].value : 0)];
                actions.setDefaultValue(newDefaultValues);
            } else if (props.defaultValue) {
                actions.setDefaultValue(props.defaultValue);
            }
            // if (props.date) {
            //     actions.setReadableDate(actions.getDate(props.date));
            //     actions.setDate(actions.createDateObj(props.date));
            // }
        },
        switchValidationActivation: () => state => ({
            activeValidation: !state.activeValidation,
        }),
        setCurrentHeight: newState => state => ({
            currentHeight: newState,
        }),
        setCurrentWeight: (newState) => state => ({
            currentWeight: newState,
        }),
        setParticularHeight: newState => state => ({
            particularHeight: newState,
        }),
        sendData: (props) => (state, actions) => {
            let isValid = false;
            let notValidMessage = '';
            let weightFound = false, heightFound = false;
            let newParticularHeight = null;
            for (var value in state.values) {
                isValid = false;
                notValidMessage = '';
                if (state.values[value].tag === 'PERSONAL_WEIGHT') {
                    weightFound = true;
                    state.values[value].date = state.date;
                    // state.values[value].value = +state.defaultValue[0];
                    isValid = actions.isInputValid({name: 'weight', value: state.values[value].value})
                    notValidMessage = t('Veuillez renseigner un poids compris entre', {ns: 'datas'}) + ' ' + convertMetrics('metric', 'weight', state.metrics.weight, constants.custo.METRICS.weight.min) + convertMetricsLabels(true, 'weight', state.metrics.weight, 'kg') + ' ' + t('et', {ns: 'generals'}) + ' ' + convertMetrics('metric', 'weight', state.metrics.weight, constants.custo.METRICS.weight.max) + convertMetricsLabels(true, 'weight', state.metrics.weight, 'kg');
                } else if (state.values[value].tag === 'PERSONAL_HEIGHT') {
                    heightFound = true;
                    state.values[value].date = state.date;
                    // state.values[value].value = +state.defaultValue[1];
                    isValid = actions.isInputValid({name: 'height', value: state.values[value].value});
                    notValidMessage = t('Veuillez renseigner une taille comprise entre', {ns: 'datas'}) + ' ' + convertMetrics('metric', 'height', state.metrics.height, constants.custo.METRICS.height.min) + convertMetricsLabels(true, 'height', state.metrics.height, 'cm') + ' ' + t('et', {ns: 'generals'}) + ' ' + convertMetrics('metric', 'height', state.metrics.height, constants.custo.METRICS.height.max) + convertMetricsLabels(true, 'height', state.metrics.height, 'cm');
                    if (isValid) {
                        newParticularHeight = state.values[value].value;
                    }
                }
                if (!isValid) {
                    actions.setError(notValidMessage);
                    return false;
                }
            }

            if ((state.particularHeight == 0) && (state.questionStep !== 1)) {
                actions.updateQuestionStep(1);
            } else {
                if (!weightFound) {
                    if (isDefined(state.defaultValue) && state.defaultValue[0] && (+state.defaultValue[0] > 0)) {
                        state.values.push({tag: 'PERSONAL_WEIGHT', value: +state.defaultValue[0], date: state.date, source: state.source});
                    } else {
                        actions.setError('Veuillez renseigner un poids.');
                        return false;
                    }
                }
                if (!heightFound) {
                    if (+state.particularHeight === 0) {
                        actions.setError('Veuillez renseigner une taille.');
                        return false;
                    } else {
                        state.values.push({tag: 'PERSONAL_HEIGHT', value: +state.particularHeight, date: state.date, source: state.source});
                    }
                }

                actions.setFormIsSubmitting(true);
                Data.postData(state.values).then(function() {
                    if (+newParticularHeight > 0) {
                        props.updateParticularHeight(+newParticularHeight);
                        actions.setParticularHeight(+newParticularHeight);
                    }
                    props.updateDataViz(props);
                    props.cancelAction();
                    actions.setFormIsSubmitting(false);
                });
            }
        },
        isInputValid: ({ name, value }) => (state, actions) => {
            if ((name === undefined) || isNaN(value) || (value === null) || (value === 'null')) {
                return false;
            }

            if ((name === 'height') || (name === 'weight')) {
                value = convertMetrics('metric', name, ((name === 'height') ? state.metrics.height : state.metrics.weight), +value);
            }

            switch (name) {
                case 'height':
                    return ((+value > convertMetrics('metric', 'height', state.metrics.height, constants.custo.METRICS.height.min)) && (+value < convertMetrics('metric', 'height', state.metrics.height, constants.custo.METRICS.height.max)));
                case 'weight':
                    return ((+value > convertMetrics('metric', 'weight', state.metrics.weight, constants.custo.METRICS.weight.min)) && (+value < convertMetrics('metric', 'weight', state.metrics.weight, constants.custo.METRICS.weight.max)));
            }
        },
        setError: newState => state => ({
            error: newState,
        }),
        setFormIsSubmitting: (active) => (state, actions) => ({
            formIsSubmitting: active
        }),
        setReadableDate: newState => state => ({
            readableDate: newState
        }),
        setDate: newState => state => ({
            date: newState
        }),
        setDefaultValue: newState => state => ({
            defaultValue: newState
        }),
        setMetrics: newState => state => ({
            metrics: newState
        }),
        createDateObj: (date) => (state, actions) => {
            var parsedDate = {
                date: new Date(date.substr(0, 4) + '-' + date.substr(4, 2) + '-' + date.substr(6, 2)),
                timezone: 'UTC',
                timezone_type: 3
            }

            return parsedDate;
        },
        pushValues: (el) => (state, actions) => {
            if (state.error !== '') {
                actions.setError('');
            }
            var values = state.values;
            var tag = el.target.offsetParent.getAttribute('data-tag');
            var val = el.target.value;
            var date = state.date;
            var source = state.source;
            var found = false;
            if (isNaN(+val)) {
                return false;
            }
            for (var i = 0, len = values.length; i < len; i++) {
                if (values[i] !== undefined) {
                    if (values[i].tag === tag) {
                        values[i].value = +val;
                        found = true;
                    }
                }
            }
            if (!found) {
                values.push({tag: tag, value: +val, date: date, source: source});
            }
            state.values = values;
        },
        getDate: (dat) => {
            var date = new Date(Date.parse(new Date(dat.substr(0, 4) + '-' + dat.substr(4, 2) + '-' + dat.substr(6, 2))));
            var options = { month: 'long', day: 'numeric', year: 'numeric' };
            return date.toLocaleDateString('fr-FR', options);
        },
        updateQuestionStep: newState => state => ({
            questionStep: newState,
        })
    },
    (state, actions) => (props, children) => (
        <div key={'followed-weight'} class='btzDashboard-btzDashboardForm' id={props.id} idtimeline={props.idtimeline} idfavorite={props.idfavorite} data-id={props.did} data-tag={props.tag} data-value={state.count} data-kind={state.kind}>
            <div style={{ 'position': 'relative', 'height': '100%', 'display': 'table', 'width': '100%' }}>
                <Tile>
                    <div class='btzTitle-btzImage btzTitle-btzImage-absolute'>
                        <p class='btzSubTitle'>{state.readableDate}</p>
                        <p class='btzTitle btzXl'>{t(state.question[state.questionStep])}</p>
                    </div>
                    <Tile.Content classes='btzLeftHrArrowedOnboarding' style={{ 'text-align': 'center' }}>
                        {state.form && (
                            <div>
                                <div>
                                    {state.form.type[state.questionStep] == 'number' && (
                                        <div class='btzOnboarding-btzButtonsWrapper btzOnboarding-btzButtonsWrapper-number'>
                                            {state.questionStep === 0 ?
                                                <Counter id='followed-weight-weight' key={'counter-weight'} class='btzCurrentEvaluationMarker' min={0} max={1000} count={state.currentWeight} did={'single'} kind='number' floating={true} tag={state.form.tag[0]} label={t(state.form.label[0])} oninput={actions.pushValues} initialCount={70}></Counter>
                                                :
                                                <Counter id='followed-weight-height' key={'counter-height'} class='btzCurrentEvaluationMarker' min={0} max={1000} count={state.currentHeight} did={'single'} kind='number' floating={true} tag={state.form.tag[1]} label={t(state.form.label[1])} oninput={actions.pushValues} initialCount={180}></Counter>
                                            }
                                        </div>
                                    )}
                                </div>

                                <div style={{ 'height': '20px' }}>
                                    {((state.questionStep === 0) && (+state.particularHeight > 0)) && (
                                        <p class='btzTexte btzWeightMedium btzMedium' style={{ 'color': 'white', 'margin': '15px auto', 'text-align': 'center' }}>
                                            Taille : {convertMetrics('height', 'cm', (state.metrics === null) ? 0 : state.metrics.height, state.currentHeight)}
                                            {{
                                                ['betterise']: <img src={icoAddData} srcset={`${icoAddDatax2} 2x`} style={{ 'margin-left': '15px' }} alt='' onclick={() => {actions.updateQuestionStep(1);}} />,
                                                ['feelgood']: <img src={icoAddData} srcset={`${icoAddDatax2} 2x`} style={{ 'margin-left': '15px' }} alt='' onclick={() => {actions.updateQuestionStep(1);}} />,
                                                ['klesia_netflix']: <img src={icoAddData} srcset={`${icoAddDatax2} 2x`} style={{ 'margin-left': '15px' }} alt='' onclick={() => {actions.updateQuestionStep(1);}} />,
                                                ['cerba']: <img src={icoAddDataCerba} srcset={`${icoAddDataCerbax2} 2x`} style={{ 'margin-left': '15px' }} alt='' onclick={() => {actions.updateQuestionStep(1);}} />,
                                                ['vyv']: <img src={icoAddDataCerba} srcset={`${icoAddDataCerbax2} 2x`} style={{ 'margin-left': '15px' }} alt='' onclick={() => {actions.updateQuestionStep(1);}} />
                                            }[props.customer]}
                                        </p>
                                    )}
                                </div>
                            </div>
                        )}
                    </Tile.Content>
                </Tile>

                <Form onsubmit={() => null} style={{ 'min-height': 0 }}>
                    <Form.Group classes='btzForm-btzFooter'>
                        <Button primary small loading={(state.formIsSubmitting)} onclick={() => { if (!state.formIsSubmitting) { props.cancelAction(); } }} >
                            {t('Annuler', {ns: 'generals'})}
                        </Button>

                        <Button primary active loading={(state.formIsSubmitting)} onclick={() => {
                            if (!state.formIsSubmitting) {
                                actions.sendData(props);
                            }
                        }} >
                            {t('Valider', {ns: 'generals'})}
                        </Button>
                    </Form.Group>
                </Form>

                <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap', 'margin-top': '50px' }}>
                    <Tile.Icon classes={''}>
                        <Avatar.Alfred classes={'centered'} />
                    </Tile.Icon>
                    <Tile.Content>
                        <Balloon kind={state.error !== '' ? 'error' : 'info'} title={t('QUESTION', {ns: 'datas'}) + ' - ' + (state.questionStep + 1) + '/' + state.question.length} style={{ 'white-space': 'normal' }}>{(state.error !== '' ? state.error : t(state.help[state.questionStep]))}</Balloon>
                    </Tile.Content>
                </Tile>
            </div>
        </div>
    ),
    'followed-weightform'
);

export { FollowedWeightForm };
