import { allRoutes } from '@app/settings';
import { t, h, Component } from '@app/utils';
import * as constants from '@app/constants';
import { Grid, Link, Navbar, Enter } from '@app/elements';

import logoBurger from '@app/img/ico/ico-burger.png';
import logoBurgerx2 from '@app/img/ico/ico-burger@2x.png';


const state = {

}

const actions = {
    onComponentCreate: () => (state, actions) => {

    },
};

const view = (state, actions) => (props, children) => (
    <div key={'home'}>
        <Enter time={constants.custo.ANIM_DURATION} easing={constants.custo.ANIM_EASING} css={constants.custo.ANIM_CSS}>
            <Grid classes={'btzHeader'}>
                <Grid.Row classes={'btzHeaderWrapper btzHeaderWrapper-btzHeaderWrapperBlue'}>
                    <Grid.Col x={10} mx={'auto'}>
                        <Navbar>
                            <label for='show-menu' class='btzMenu-btzResponsiveMenu'>
                                <img id='btzBurgerMenu-btzBurgerMenuImg' src={logoBurger} srcset={`${logoBurgerx2} 2x`} alt='' />
                            </label>
                            <input id='show-menu' role='button' type='checkbox' />

                            <Navbar.Section classes='btzHeader-btzHeaderLeftHand'>
                                <ul>
                                    <li style={{ 'display': 'block' }}>
                                        <Link class={'btn btn-link'} to={allRoutes['home.index'].pathname} style={{ 'opacity': 1 }}>
                                            <img src={`${process.env.APP_WEB_URL}/__custo/cerba/logo-small.png`} srcset={`${process.env.APP_WEB_URL}/__custo/cerba/logo-small@2x.png`} alt='' />
                                        </Link>
                                    </li>
                                </ul>
                            </Navbar.Section>

                            <Navbar.Section classes='btzHeader-btzHeaderRightHand'>
                                <ul>
                                    <li class='btzHzader-btzHeaderRegisterMarker'><Link to={allRoutes['home.login'].pathname} class='btzHeader-btzConnect'>{t('Me connecter', {ns: 'login'})}</Link></li>
                                    <li class='btzHzader-btzHeaderRegisterMarker'><Link to={allRoutes['home.register'].pathname} class='btzHeader-btzRegister'>{t('Finaliser mon inscription', {ns: 'login'})}</Link></li>
                                </ul>
                            </Navbar.Section>
                        </Navbar>

                    </Grid.Col>
                </Grid.Row>
            </Grid>
            <Grid classes={'btzWrapper btzWrapperFullHeight btzVerticalAlign btzWrapperFullWidth'}>
                <Grid.Row classes={'btzWrapper-verticalAlignContent'}>
                    <Grid.Col x={12} mx={'auto'} class='m-0'>
                        {children}
                    </Grid.Col>
                </Grid.Row>
            </Grid>
        </Enter>
    </div>
);

export default Component(state, actions, view, 'layout');
