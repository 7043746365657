export const state = {
    resiliateFormDisplay: false,
    email: '',
    password: {
        isTouched: false,
        isValid: false,
        value: ''
    },
    api: {
        message: '',
        kind: 'info'
    },
    submitting: false,
};
