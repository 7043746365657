import { queueQueries, executeQuery } from '@app/api/controlleur';
import { appCustoSetting } from '@app/settings/settings';
const { API_URL } = appCustoSetting.appsettings;

function show(id) {
    return queueQueries(`${API_URL}/mail?id=${id}`, 'GET', 'application/json', true, true, true, false)
}

function confirmation(emailVerificationCode) {
    return executeQuery(`${API_URL}/mail/verify?code=${emailVerificationCode}`, 'GET', 'application/json', true, true, true, false)
}

function verification() {
    return executeQuery(`${API_URL}/mail/verify`, 'POST', 'application/json', true, true, true, true)
}

export const Mail = {
    show,
    confirmation,
    verification,
};


// $url = $app['conf']['api'] . 'mail/verify?code=' . $emailVerificationCode;