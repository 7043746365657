import { t, h, Component } from '@app/utils';
import { Grid, Navbar, Button, TopSticked, Enter } from '@app/elements';
import * as constants from '@app/constants';

import icoBack from '@app/img/ico/ico-prev-light.png';
import icoBackx2 from '@app/img/ico/ico-prev-light@2x.png';

import logoBurger from '@app/img/ico/ico-burger.png';
import logoBurgerx2 from '@app/img/ico/ico-burger@2x.png';

import imgHeaderFullWidth from '@app/img/timeline/img-timeline-header.png';
import imgHeaderFullWidthx2 from '@app/img/timeline/img-timeline-header@2x.png';

import { TopMenuView } from '@app/layouts/topmenu';
import { TopLogoView } from '@app/layouts/toplogo';

const actions = {
    goBack: () => state => {
        window.history.back();
    },
}

const view = (state, actions) => (props, children) => (
    <div key={props.page} class={props.page} data-theme={props.theme}>
        <Enter time={constants.custo.ANIM_DURATION} easing={constants.custo.ANIM_EASING} css={constants.custo.ANIM_CSS}>
            <Grid classes={'btzHeader'}>
                <Grid.Row classes={'btzHeaderWrapper'}>
                    <Grid.Col x={10} mx={'auto'}>
                        <Navbar>
                            <label for='show-menu' class='btzMenu-btzResponsiveMenu'>
                                <img id='btzBurgerMenu-btzBurgerMenuImg' src={logoBurger} srcset={`${logoBurgerx2} 2x`} alt='' />
                            </label>
                            <input id='show-menu' role='button' type='checkbox' />

                            <Navbar.Section classes='btzHeader-btzHeaderLeftHand'>
                                <TopLogoView {...props} customer={(props.customer || 'betterise')}></TopLogoView>
                            </Navbar.Section>

                            <Navbar.Section classes='btzHeader-btzHeaderRightHand'>
                                <TopMenuView {...props} customer={(props.customer || 'betterise')}></TopMenuView>
                            </Navbar.Section>
                        </Navbar>

                    </Grid.Col>
                </Grid.Row>
            </Grid>

            <Grid.Row classes={'btzHeaderImageFullWidthWrapper'}>
                <Grid.Col x={10} mx={'auto'}>
                    <img src={imgHeaderFullWidth} srcset={`${imgHeaderFullWidthx2} 2x`} alt='' />

                    <div class='btzHeaderImageFullWidthWrapper-btzContent'>
                        <div class='btzHeaderImageFullWidthWrapper-btzContent-btzWrapper'>
                            <p class='btzTitle btzXl' style={{ 'text-transform': 'inherit' }}>{props.readablePageLabel}</p>
                            <p class='btzSubTitle'>{props.readablePageDescription}</p>
                        </div>
                    </div>
                </Grid.Col>
                {((props.numSteps !== undefined) && (props.numSteps > 0)) &&
                    <Grid.Col x={10} mx={'auto'}>
                        <div class={'btzProgram-btzProgramPreviousStep' + ' ' + (props.prevSteps ? 'active' : '')}>
                            <Button flat active onclick={props.displayPreviousStep}>{t('Refaire une ancienne séance', {ns: 'programs'})}</Button>
                            <div class='btzProgram-btzProgramPreviousStep-btzWrapper'>
                                {Array.from({length: props.numSteps}, (_, i) => (
                                    <Button flat onclick={() => {
                                        if (+props.numSteps === (+i + 1)) {
                                            props.sendToDayStep()
                                        } else {
                                            props.sendToPreviousStep(i)
                                        }
                                    }}>
                                        {t('Séance', {ns: 'programs'}) + ' ' + (+i + 1) + (+props.numSteps === (+i + 1) ? (' (' + t('du jour', {ns: 'programs'}) + ')') : '')}
                                    </Button>
                                ))}
                            </div>
                        </div>
                    </Grid.Col>
                }
            </Grid.Row>

            <Grid classes={''} style={{ 'padding-bottom': '100px', 'padding-top': '20px' }}>
                <Grid.Row classes={'col-10 col-mx-auto'}>
                    <Grid.Col x={2} md={12} mx={'auto'} style={{ 'position': 'relative' }}>
                        <TopSticked topdetection={80} classes={'btzStickedWithNoStyle'} top={80}>
                            <div id='btzBackButtonLabelled' onclick={actions.goBack}>
                                <img src={icoBack} srcset={`${icoBackx2} 2x`} alt='' />
                                <p class=''>Retour</p>
                            </div>
                        </TopSticked>
                    </Grid.Col>
                    <Grid.Col x={10} md={12} mx={'auto'} style={{ 'position': 'relative' }}>
                        {children}
                    </Grid.Col>
                </Grid.Row>
            </Grid>
        </Enter>
    </div>
);

export default Component({}, actions, view, 'layout');