import * as constants from '@app/constants';

export const state = {
    dayLunchs: [],
    mealKinds: constants.custo.LUNCH,
    rendering: false,
    balanceLunchs: null,
    surveyLunchs: null,
    readableMealDate: null,
    dayLunchsId: null,
    parsedCompositionDayLunchs: null,
    dataRising: null,
    resetingModal: false,
    user: null,
    idealAETQ: null,
};
