import { t, h } from '@app/utils';
import { Card } from '@app/elements';

import imgNoFavorite from '@app/img/favorite/no-favorite.png';
import imgNoFavoritex2 from '@app/img/favorite/no-favorite@2x.png';


export const view = (state, actions) => (props, children) => (
    <div class='col-12 p-0'>
        <div class='col-3 col-md-12 p-0' style={{ 'vertical-align': 'middle', 'text-align': 'left' }}>
            <Card.Image><img data-customer={props.customer} src={imgNoFavorite} srcset={`${imgNoFavoritex2} 2x`} alt='' class='' /></Card.Image>
        </div>
        <div class='col-9 col-md-12' style={{ 'vertical-align': 'middle' }}>
            <p class='btzPageContentTexte' style={{ 'text-align': 'left' }}>{t('Vous n’avez pas ajouté', {ns: 'favorites'}) + ' ' + props.kind + ' ' + t('à vos favoris.', {ns: 'favorites'})}</p>
            <p class='btzPageContentSubTexte' style={{ 'text-align': 'left' }}>{t('Pour conserver un contenu en favori, cliquez sur le coeur associé à ce contenu.', {ns: 'favorites'})}</p>
        </div>
    </div>
);
