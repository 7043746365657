import { t, h } from '@app/utils';
import { Link } from '@app/elements';

import HomeLayout from '@app/layouts/home/layout';

import illuEnterprise from '@app/img/home/entreprises/illu-entreprises.png';
import illuEnterprisex2 from '@app/img/home/entreprises/illu-entreprises@2x.png';

export const view = (state, actions) => (props, children) => (
    <div key='entreprise'>
        <HomeLayout style={{ position: 'relative' }} page='enterprise'>
            <div class='btzHome-btzHeader btzHome-btzHeader-blue'>
                <div class='btzHome-btzHeader-btzContent'>
                    <div class='col-10'>
                        <p class='btzTitle btzXl'>{t('Entreprises', {ns: 'home'})}</p>
                        <p class='btzTexte col-10 col-md-12'>{t('Betterise est la plateforme digitale santé/ bien-être la plus aboutie du marché. A ce titre, nous travaillons dur avec de formidables partenaires pour que notre approche soit mise à disposition du plus grand nombre d’utilisateurs. Vous souhaitez proposer Betterise, ou votre propre service santé en marque blanche, à vos clients ou vos collaborateurs ? Bienvenue.', {ns: 'home'})}</p>
                    </div>
                </div>
            </div>

            <div class='btzHome-btzContent column'>
                <div class='col-10'>
                    <div class='col-6 col-md-12 btzLeftAnchored' style={{ 'vertical-align': 'top' }}>
                        <p class='btzTitle btzXl'>{t('Betterise pour vos clients', {ns: 'home'})}</p>
                        <p class='btzTexte'>{t('Notre modèle de vente de licence au volume s’adapte parfaitement à tous vos projets de communication autour de la santé et du bien être de vos clients. De plus, grâce à l’approche plateforme de Betterise, l’ensemble du contenu mais aussi de l’intelligence de Betterise peut être intégrée, via des API dédiées, au sein de votre service.', {ns: 'home'})}</p>
                        <p class='btzTexte'>{t('Oui, vous pouvez construire votre Betterise, à vos couleurs, en intégrant de manière très simple l’ensemble de notre contenu et de nos algorithmes dans votre projet. Aucune complexité. Aucun délai supplémentaire. Tout est prêt, déjà sur étagère.', {ns: 'home'})}</p>
                        <p class='btzTexte'>{t('Bien sûr, vous pouvez également choisir d’offrir Betterise, sous sa forme originelle, à vos clients. Nous pourrons même y intégrer votre système d’authentification.', {ns: 'home'})}</p>
                        <p class='btzTexte'>{t('Contactez-nous sur', {ns: 'home'})} <Link class='btzLink btzLink-btzUltraBasic' to={'mailto:b2b@betterise.me'}>b2b@betterise.me</Link>, {t('pour connaître notre politique de prix dégressif au volume.', {ns: 'home'})}</p>
                        <p class='btzTitle btzXl' style={{ 'margin-top': '80px' }}>{t('Betterise pour vos salariés.', {ns: 'home'})}</p>
                        <p class='btzTexte'>{t('Parce que nous pensons qu’une entreprise possède une véritable responsabilité quant à la santé de ses salariés, Betterise est aussi une plateforme de bien-être en entreprise, avec des fonctionnalités dédiées pour un déploiement facilité.', {ns: 'home'})}</p>
                        <p class='btzTexte'>{t('Betterise restera totalement indépendant de votre entreprise, vos salariés n’auront aucune inquiétude quant à l’utilisation qui sera faite de leurs données : vous ne faites que leur fournir l’accès à Betterise. Et ils vous remercieront pour cela.', {ns: 'home'})}</p>
                        <p class='btzTexte'>{t('Du fait de ces fonctionnalités, Betterise est la plateforme de corporate wellness la plus complète du marché. Vous souhaitez prendre soin de la santé de vos collaborateurs, via une approche confidentielle, ludique, non anxiogène, facile à déployer ? Contactez-nous par mail, ', {ns: 'home'})}<Link class='btzLink btzLink-btzUltraBasic' to={'mailto:b2b@betterise.me'}>b2b@betterise.me</Link></p>
                    </div>
                    <div class='col-6 col-md-12 btzRightAnchored' style={{ 'padding-top': '35px' }}>
                        <img src={illuEnterprise} srcset={`${illuEnterprisex2} 2x`} alt='' class='' />
                    </div>
                </div>
            </div>
        </HomeLayout>
    </div>
);
