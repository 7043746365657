import { t, h } from '@app/utils';
import { addCSRFToken } from '@app/core';
import { allRoutes } from '@app/settings';
import { Link } from '@app/elements';

import imgDefaultAvatar from '@app/img/default/def-avatar.png';
import imgDefaultAvatarx2 from '@app/img/default/def-avatar@2x.png';

export const MenuDefault = (props, actions) => (
    <ul style={{ 'float': 'right' }}>
        <li style={{ 'width': '125px' }}><Link class={props.page == 'timeline' ? 'active' : ''} to={addCSRFToken(allRoutes['private.timeline'].pathname)}>{t('Journal', {ns: 'generals'})}</Link></li>
        <li style={{ 'width': '170px' }}><Link class={props.page == 'food' ? 'active' : ''} to={addCSRFToken(allRoutes['private.food'].pathname)}>{t('Alimentation', {ns: 'generals'})}</Link></li>
        <li style={{ 'width': '130px' }}><Link class={props.page == 'datas' ? 'active' : ''} to={addCSRFToken(allRoutes['private.datas'].pathname)}>{t('Données', {ns: 'generals'})}</Link></li>
        <li style={{ 'width': '155px' }}><Link class={props.page == 'programs' ? 'active' : ''} to={addCSRFToken(allRoutes['private.programs'].pathname)}>{t('Programmes', {ns: 'generals'})}</Link></li>
        <li style={{ 'width': '180px' }}>
            <Link class={(['account', 'favorites', 'parameter', 'objectives', 'oc', 'rewards'].indexOf(props.page) > -1) ? 'btzThumbMarker active' : 'btzThumbMarker'} to={addCSRFToken(allRoutes['account.parameters'].pathname)} style={{ 'text-transform': 'capitalize' }}>
                <div class='btzAvatarHeaderTop'>
                    {((props.userMedia !== undefined) && (props.userMedia !== null)) ?
                        <img id='avatarThumb' src={props.userMedia} alt='' class='' />
                        :
                        <img id='avatarThumb' src={imgDefaultAvatar} srcset={`${imgDefaultAvatarx2} 2x`} data-customer={props.customer || 'betterise'} alt='' class='' />
                    }
                </div>
                <p>{t('Compte', {ns: 'generals'})}</p>
            </Link>
        </li>
    </ul>
);