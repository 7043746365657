import { h } from '@app/utils';
import { Tile, Card, Avatar, Balloon } from '@app/elements';

export const view = (state, actions) => (props, children) => (
    <Card kind={'alfred'}>
        <Card.Body>
            <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap' }}>
                <Tile.Icon classes={'col-3 col-xs-12'}>
                    <Avatar.Alfred classes={'centered'} />
                </Tile.Icon>
                <Tile.Content classes={'col-9 col-xs-12'}>
                    <Balloon style={{ 'white-space': 'normal' }}>
                        {props.description}
                    </Balloon>
                </Tile.Content>
            </Tile>
        </Card.Body>
    </Card>
);
