import { t, h, Component } from '@app/utils';
import { Convert } from '@app/elements';
import './index.scss';

const state = {

}

const actions = {
    onComponentCreate: () => (state, actions) => {

    }
};

const view = (state, actions) => (props, children) => (
    <div class='btzProgramsRecommanded-btzProgramRecommanded-btzProgramSecurity'>
        <p class='btzPageContentTexte btzXl'>{t('Point sécurité', {ns: 'programs'})}</p>
        <p class='btzPageContentSubTexte' style={{ 'white-space': 'normal' }} innerHTML={<Convert separator={''} is_xhtml={true} type='nl2br'>{props.security}</Convert>}></p>
    </div>
);

export default Component(state, actions, view, 'security');
