import { User } from '@app/api';
import { getQueryVariable } from '@app/core';

// used to authorise a new device, nothing to do with blocked password !!

export const actions = {
    onComponentCreate: (props) => (state, actions) => {
        let id = getQueryVariable('id', false);
        let secret = getQueryVariable('secret', false);
        let verified = getQueryVariable('verified', false);

        if (id && secret && verified) {
            User.devicesAcknowledge(id, secret, verified)
        } else {
            //
        }
    },
};
