import { h, t } from '@app/utils';
import { Button, Form, Tile, Card, Avatar, Balloon } from '@app/elements';

import logoStepMan from '@app/img/register/areyou-man.png';
import logoStepManx2 from '@app/img/register/areyou-man@2x.png';

import logoStepWoman from '@app/img/register/areyou-woman.png';
import logoStepWomanx2 from '@app/img/register/areyou-woman@2x.png';

export const ModalView = (props, actions) => (
    <Card classes={'btzInformations-btzInformationsModal'}>
        {props.displayed === props.current.kind &&
            <Card.Header classes={'btzCard-btzHeader-btzModal'} style={{ 'max-width': '350px', 'padding-top': '74px' }}>
                {((props.current !== null) && (props.current.kind !== 'none')) &&
                    <div class={'btzClassMarker-btz' + props.current.kind} key={props.current.kind}>
                        <p class='btzTitle btzXl'>{t(props.current.question)}</p>

                        <Form onsubmit={event => event.preventDefault()}>

                            {props.current.kind === 'gender' ?
                                <div key={'information-gender'}>
                                    <Form.Group style={{ 'white-space': 'nowrap', 'text-align': 'center' }}>
                                        <Form.Radio
                                            placeholder={t('Homme', {ns: 'generals'})}
                                            id={props.current.kind}
                                            name={props.current.kind}
                                            image={logoStepMan}
                                            imagex2={logoStepManx2}
                                            onclick={() => {
                                                props.send({
                                                    ns: 'gender',
                                                    key: 'value',
                                                    value: '1'
                                                })
                                                props.updateCurrent(props.current.kind);
                                                props.onSubmit(props.current.kind)
                                            }}
                                            required
                                            checked={props.current.value == true}
                                        />
                                        <Form.Radio
                                            placeholder={t('Femme', {ns: 'generals'})}
                                            id={props.current.kind}
                                            name={props.current.kind}
                                            image={logoStepWoman}
                                            imagex2={logoStepWomanx2}
                                            onclick={() => {
                                                props.send({
                                                    ns: 'gender',
                                                    key: 'value',
                                                    value: '0'
                                                })
                                                props.updateCurrent(props.current.kind);
                                                props.onSubmit(props.current.kind)
                                            }}
                                            required
                                            checked={props.current.value == false}
                                        />
                                    </Form.Group>
                                </div>
                                :
                                <div>

                                    {props.current.kind === 'email' &&
                                        <div key={'confirmemail-password'} data-type={'confirmemail-password'}>
                                            <Form.Group>
                                                <Form.Input
                                                    classes={'btzFullWidth'}
                                                    placeholder={t('Votre mot de passe', {ns: 'generals'})}
                                                    type={'password'}
                                                    id={'passwordemail'}
                                                    name={'passwordemail'}
                                                    onfocus={props.onBlur}
                                                    oninput={props.waitForLastInput} // fuck
                                                    error={props.passwordemail.isTouched && !props.passwordemail.isValid}
                                                    classes={(props.passwordemail.isTouched) ? (props.passwordemail.isValid ? 'is-success' : 'is-error') : ''}
                                                />
                                            </Form.Group>
                                        </div>
                                    }

                                    {props.current.kind === 'password' &&
                                        <div>
                                            <div key={'password'} data-type={'password'}>
                                                <Form.Group>
                                                    <Form.Input
                                                        classes={'btzFullWidth'}
                                                        placeholder={t(props.oldpassword.placeholder)}
                                                        type={'password'}
                                                        id={'oldpassword'}
                                                        name={'oldpassword'}
                                                        onfocus={props.onBlur}
                                                        oninput={props.waitForLastInput}
                                                        error={props.oldpassword.isTouched && !props.oldpassword.isValid}
                                                        classes={(props.oldpassword.isTouched) ? (props.oldpassword.isValid ? 'is-success' : 'is-error') : ''}
                                                    />
                                                </Form.Group>
                                            </div>
                                            {/*<div key={'password-secret'} data-type={'password'}>
                                                <Form.Group>
                                                    <Form.Input
                                                        classes={'btzFullWidth'}
                                                        placeholder={t('Votre réponse secrète', {ns: 'generals'})}
                                                        type={'text'}
                                                        id={'secret'}
                                                        name={'secret'}
                                                        onfocus={props.onBlur}
                                                        oninput={props.waitForLastInput} // fuck
                                                        error={props.secret.isTouched && !props.secret.isValid}
                                                        classes={(props.secret.isTouched) ? (props.secret.isValid ? 'is-success' : 'is-error') : ''}
                                                    />
                                                </Form.Group>
                                            </div>*/}
                                        </div>
                                    }

                                    {props.current.kind.indexOf('date') > -1 ?
                                        <Form.Group>
                                            <Form.Input
                                                classes={'btzFullWidth'}
                                                placeholderYear={t(props.current.placeholder.year)}
                                                placeholderMonth={t(props.current.placeholder.month)}
                                                placeholderDay={t(props.current.placeholder.day)}
                                                type={props.current.type}
                                                id={props.current.kind}
                                                name={props.current.kind}
                                                value={props.current.value}
                                                oninput={props.waitForLastInput}
                                                error={(props.current.isTouched.year && props.current.isTouched.month && props.current.isTouched.day) && (!props.current.isValid.year && !props.current.isValid.month && !props.current.isValid.day)}
                                                classes={(props.current.isTouched.year && props.current.isTouched.month && props.current.isTouched.day) ? ((props.current.isValid.year && props.current.isValid.month && props.current.isValid.day) ? 'is-success' : 'is-error') : ''}
                                            />
                                        </Form.Group>
                                        :
                                        <div data-type={props.current.type}>
                                            {props.current.type === 'dropdown' ?
                                                <Form.Group>
                                                    <Form.Listing
                                                        classes={'btzFullWidth'}
                                                        type={props.current.type}
                                                        id={props.current.kind}
                                                        name={props.current.kind}
                                                        value={props.current.value}
                                                        onfocus={props.onBlur}
                                                        oninput={props.waitForLastInput}
                                                        listing={props.listing}
                                                        withsearch={props.withsearch}
                                                        placeholder={t(props.placeholder)}
                                                        onclick={(event) => {
                                                            props.send({
                                                                ns: 'current',
                                                                key: 'value',
                                                                value: event.currentTarget.getAttribute('data-value')
                                                            })
                                                        }}
                                                        error={props.current.isTouched && !props.current.isValid}
                                                        classes={(props.current.isTouched) ? (props.current.isValid ? 'is-success' : 'is-error') : ''}
                                                    />
                                                </Form.Group>
                                                :
                                                <div>
                                                    <div>
                                                        {props.current.type === 'city' ?
                                                            <Form.Group>
                                                                <Form.Input
                                                                    classes={'btzFullWidth'}
                                                                    placeholder={t(props.current.placeholder)}
                                                                    type='text'
                                                                    id='city'
                                                                    name='city'
                                                                    value={props.current.value}
                                                                    onfocus={props.onBlur}
                                                                    oninput={(event) => {
                                                                        props.setCityValue(event.target.value);
                                                                        props.onCityInputDebouncedUpdate(event.target.value);
                                                                    }}
                                                                    error={props.current.isTouched && !props.current.isValid}
                                                                    classes={(props.current.isTouched) ? (props.current.isValid ? 'is-success' : 'is-error') : ''}
                                                                />
                                                            </Form.Group>
                                                            :
                                                            <Form.Group>
                                                                <Form.Input
                                                                    classes={'btzFullWidth'}
                                                                    placeholder={t(props.current.placeholder)}
                                                                    type={props.current.type}
                                                                    id={props.current.kind}
                                                                    name={props.current.kind}
                                                                    value={props.current.value}
                                                                    onfocus={props.onBlur}
                                                                    oninput={props.waitForLastInput}
                                                                    error={props.current.isTouched && !props.current.isValid}
                                                                    classes={(props.current.isTouched) ? (props.current.isValid ? 'is-success' : 'is-error') : ''}
                                                                />
                                                            </Form.Group>
                                                        }
                                                    </div>

                                                    {props.current.type === 'city' &&
                                                        <div>
                                                            {(props.city.values && (props.city.values.length > 0)) && (
                                                                <ul name='cities'>
                                                                    {props.city.values.map(city => (
                                                                        <button key={city}
                                                                            onclick={() => props.onCitiesListClick(city)}>{city}</button>
                                                                    ))}
                                                                </ul>
                                                            )}
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    }

                                    <Form.Group classes='btzForm-btzFooter'>
                                        <Button primary flat active={((props.current.isValid === true) || (props.current.isValid.year && props.current.isValid.month && props.current.isValid.day))} onclick={() => {
                                            props.onSubmit(props.current.kind);
                                        }}>
                                            {t('Enregistrer', {ns: 'generals'})}
                                        </Button>
                                    </Form.Group>
                                </div>
                            }
                        </Form>

                        {props.current.type !== 'dropdown' &&
                            <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap' }}>
                                <Tile.Icon>
                                    <Avatar.Alfred classes={'centered'} />
                                </Tile.Icon>
                                <Tile.Content>
                                    <Balloon kind={props.current.api.kind} style={{ 'white-space': 'normal' }}>
                                        {t(props.current.api.message)}
                                    </Balloon>
                                </Tile.Content>
                            </Tile>
                        }
                    </div>
                }
            </Card.Header>
        }
    </Card>
);