import { t, h, Component } from '@app/utils';
import { Tile } from '@app/elements';

import LoginLayout from '@app/layouts/register/layout';

const state = {
    //
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        //
    },
};

const view = (state, actions) => (props, children) => (
    <div key='onboardingend'>
        <LoginLayout page='onboardingend' customer={props.customer}>
            <div style={{ position: 'relative' }} class={'onboardingend-step'}>
                <dummy>
                    <Tile>
                        <Tile.Content>
                            <p class='btzSubTitle btzXl' style={{ 'margin-bottom': '0' }}>
                                {t('Merci pour vos réponses au questionnaire. Elles sont enregistrées.', {ns: 'onboarding'})}
                            </p>
                            <div class='btzHr btzHrSmallAndHeavy'></div>
                            <p class='btzSubTitle btzXl' style={{ 'margin-bottom': '0' }}>
                                {t('En cas de doute ou d’urgence sur votre état de santé contactez le secrétariat ou le 15.', {ns: 'onboarding'})}
                            </p>
                        </Tile.Content>
                    </Tile>
                </dummy>
            </div>
        </LoginLayout>
    </div>
);

export default Component(state, actions, view, 'onboardingend');
