export const state = {
    alfred: {
        isVisible: false,
    },
    cgu: {
        isAccepted: null,
    },
    cguInitialized: false,
    messageActive: false,
};
