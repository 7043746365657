export const state = {
    email: 'default@betterise.me',
    password: {
        isTouched: false,
        isValid: false,
        value: '',
    },
    passwordconfirm: {
        isTouched: false,
        isValid: false,
        value: '',
    },
    secret: {
        isTouched: false,
        isValid: false,
        value: '',
    },
    api: {
        message: '',
        kind: ''
    },
    rendering: false,
    customer: 'betterise',
    questionAnswered: true,
    question: '',
};
