import { User, Objectives } from '@app/api';
import { t, h, Component } from '@app/utils';
import { getUser, dynLoad } from '@app/core';
import './index.scss';

const state = {
    objectives: {
        all: [],
        user: [],
    },
    error: '',
    initialState: [],
    toprocess: [],
    messageActive: false,
    maxObjectives: 2,
    minObjectives: 1,
    modalDisplayed: null,
    userMedia: null,
    userId: null,
    language: 'fr_FR',
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        if (props.language) {
            actions.setLanguage(props.language);
        }
        if (process.env.APP_CUSTOMISATION.toLowerCase() === 'klesia_netflix') {
            actions.setMaxObjectives(1);
        }
        actions.getUserObjectives();
    },
    getUserObjectives: () => (state, actions) => {
        User.getUserObjectives().then(function(res) {
            var userObjectives = [];
            for (var obj in res.data) {
                obj = res.data[obj];
                obj.idobjective = obj.objective.id;
                obj.title = obj.objective.title;
                obj.media = obj.objective.media;
                obj.mode = 'remove';
                userObjectives.push(obj);
            }
            actions.setState({ns: 'objectives', key: 'user', value: userObjectives});

            actions.getListObjectives();
        });
    },
    getListObjectives: () => (state, actions) => {
        let userCallback = (sessionUser) => {
            let companyId = null;
            if (sessionUser.customer !== undefined) {
                companyId = sessionUser.customer;
            }
            let chars = {' ':'','/':'-','é':'e','-':''};
            if (companyId === null) {
                companyId = 'betteriseentreprise';
            }
            companyId = companyId.replace(/[ /é-]/g, m => chars[m]).toLowerCase();
            actions.setUserMedia(sessionUser.media.url);
            actions.setUserId(sessionUser.id);
            actions.getObjectivesListing(companyId);
        };
        getUser(userCallback);
    },
    getObjectivesListing: (companyId) => (state, actions) => {
        Objectives.getObjectives(state.language, companyId).then(function(res) {
            var objectives = [], initials = [];

            for (var obj in res.data) {
                obj = res.data[obj];
                obj.mode = 'add';
                obj.status = 0;
                obj.idobjective = obj.id;

                for (var userObj in state.objectives.user) {
                    userObj = state.objectives.user[userObj];
                    if (userObj.idobjective === obj.id) {
                        obj.mode = 'remove';
                        obj.status = 1;
                        obj.date = userObj.date;
                        obj.objective = userObj.objective;
                    }
                }
                objectives.push(obj);
                initials.push(JSON.parse(JSON.stringify(obj)))
            }
            actions.setState({ns: 'objectives', key: 'all', value: objectives});
            actions.setInitialState(initials);
        });
    },
    chooseThisObjectif: (props) => (state, actions) => {
        actions.setError('');
        var resampledObjAll = [];
        for (var objAll in state.objectives.all) {
            objAll = state.objectives.all[objAll];
            if (objAll.id === props.id) {
                objAll.mode = (objAll.mode === 'add' ? 'remove' : 'add');
                objAll.status = (objAll.status === 0 ? 1 : 0);
            }
            resampledObjAll.push(objAll);
        }
        actions.setState({ns: 'objectives', key: 'all', value: resampledObjAll});

        actions.parseToProcess(props);
    },
    confirmTheseObjectifRules: ({ev, props}) => (state, actions) => {
        var inUserList = 0;
        for (var obj in state.objectives.all) {
            obj = state.objectives.all[obj];
            if (obj.mode === 'remove') {
                inUserList++;
            }
        }
        if (inUserList < state.minObjectives) {
            actions.setError(t('Vous devez suivre au moins un objectif santé.', {ns: 'objectives'}));
            ev.stopPropagation();
            ev.cancelBubble = true;
            return;
        } else if (inUserList > state.maxObjectives) {
            if (state.maxObjectives === 2) {
                actions.setError(t('Vous pouvez suivre 2 objectifs santé au maximum.', {ns: 'objectives'}));
            } else if (state.maxObjectives === 1) {
                actions.setError(t('Vous pouvez suivre 1 objectif santé au maximum.', {ns: 'objectives'}));
            }
            ev.stopPropagation();
            ev.cancelBubble = true;
            return;
        }
        actions.setError('');
    },
    confirmTheseObjectif: (props) => (state, actions) => {
        let toSend = {};
        state.objectives.all.map((m, i) => toSend[i] = {'id': m.idobjective, 'state': m.status});

        Objectives.updateObjective(toSend).then(() => {
            actions.getUserObjectives();
            actions.setModalDisplayed(false);
            actions.displaySuccessMessage(true);
        });
    },
    cancelObjectivesModifications: () => (state, actions) => {
        actions.setToProcess([]);
        actions.setError('');
        // use initial list to reset the modal list
        var newAllState = [];
        for (var objInit in state.initialState) {
            objInit = state.initialState[objInit];
            newAllState.push(JSON.parse(JSON.stringify(objInit)))
        }
        actions.setState({ns: 'objectives', key: 'all', value: newAllState});
    },
    parseToProcess: (obj) => (state, actions) => {
        var found = false, foundIdx = null, newToProcess = state.toprocess;
        for (var toProcessObj in newToProcess) {
            if (newToProcess[toProcessObj].id === obj.id) {
                found = true;
                foundIdx = toProcessObj;
            }
        }

        if (!found) {
            // obj not found in obj to process, we can add it to array
            newToProcess.push(obj);
        } else {
            // obj was found, we must update his edition mode
            newToProcess[foundIdx].mode = (newToProcess[foundIdx].mode === 'add' ? 'remove' : 'add');
        }
        actions.setToProcess(newToProcess);
    },
    setFilter: newState => state => ({
        filter: newState
    }),
    setState: ({ ns, key, value }) => state => ({
        [ns]: { ...state[ns], ...{ [key]: value } },
    }),
    setInitialState: newState => state => ({
        initialState: newState,
    }),
    setUserMedia: newState => state => ({
        userMedia: newState,
    }),
    setLanguage: newState => state => ({
        language: newState,
    }),
    setMaxObjectives: newState => state => ({
        maxObjectives: newState,
    }),
    setUserId: newState => state => ({
        userId: newState,
    }),
    setToProcess: newState => state => ({
        toprocess: newState,
    }),
    displaySuccessMessage: newState => state => ({
        messageActive: newState,
    }),
    setModalDisplayed: newState => state => ({
        modalDisplayed: newState,
    }),
    setError: newState => state => ({
        error: newState,
    }),
};

const view = dynLoad('view', 'Account/objectives/views');

export default Component(state, actions, view, 'objectives');
