import { t, h, Component } from '@app/utils';
import { Card, Link, Convert } from '@app/elements';
import './index.scss';

const state = {
    program: null,
}

const actions = {
    onComponentCreate: () => (state, actions) => {

    }
};

const view = (state, actions) => (props, children) => (
    <div>
        {props.displayedon == 'session' &&
            <Card kind={'coachword'} id={'coachword-' + props.id}>
                <div>
                    <Card.Header>
                        {((props.mediaUrl !== undefined) && (props.mediaUrl !== null)) &&
                            <Card.Image class='btzCard-btzImageMedia'>
                                <Card.Media src={props.mediaUrl}></Card.Media>
                            </Card.Image>
                        }
                        <div class='' style={{ 'margin': '22px' }}>
                            <Card.Image class='btzCard-btzImageMedia' style={{ 'position': 'relative' }}>
                                {((props.coach !== undefined) && (props.coach.media !== null) && (props.coach.media.url !== undefined)) &&
                                    <img src={props.coach.media.url} alt='' class='btzCoachWord' />
                                }
                                <div style={{ 'position': 'absolute', 'bottom': '0', 'width': '100%' }}>
                                    <p class='btzPageContentTexte' style={{ 'text-align': 'center', 'margin-bottom': '5px' }}>{props.title}</p>
                                    <p class='btzPageContentSubTexte btzTextColored' data-theme={props.theme} style={{ 'text-align': 'center' }}>{t('Mot du coach', {ns: 'programs'})}</p>
                                </div>
                            </Card.Image>
                        </div>
                    </Card.Header>

                    <Card.Body classes={'btzTopCustomDotted'} style={{ 'white-space': 'normal' }}>
                        <p class='btzTimeline-btzCard-btzDescription btzTexte' innerHTML={<Convert separator={''} is_xhtml={true} type='nl2br'>{props.description}</Convert>}></p>
                    </Card.Body>
                </div>
            </Card>
        }

        {props.displayedon == 'summary' &&
            <div class='btzProgram-btzLeftMenu-btzEntry' data-theme={props.theme}><Link scrollto={'coachword-' + props.id} offsetHeader={100}>{t('Mot du coach', {ns: 'programs'})}</Link></div>
        }
    </div>
);

export default Component(state, actions, view, 'coachword');
