import { appCustoSetting } from '@app/settings/settings';
import { queueQueries } from '@app/api/controlleur';
const { API_URL } = appCustoSetting.appsettings;


async function updateObjective(objectives, withHeader = true) {
    return queueQueries(`${API_URL}/objectives/`, 'POST', 'application/json', true, withHeader, true, true, true, objectives);
}

/**
 * Calls API to add an objective
 * @see https://betterise-api-doc.betterise.me/#api-Objectives-addObjective
 * @return Response object
 */
async function addObjective(idObjective) {
    return queueQueries(`${API_URL}/objectives/${idObjective}/`, 'POST')
}

/**
 * Calls API to retrieve user' available new objectives
 * @see https://betterise-api-doc.betterise.me/#api-Objectives-getObjectives
 * @return Response object
 */
async function getObjectives(lang = 'fr_FR', customerId = 'betteriseentreprise') {
    return queueQueries(`${API_URL}/objectives/${lang}/${customerId ? customerId + '/' : ''}`, 'GET', 'application/json', true, true, true, false)
}

/**
 * Calls API to get user' ongoing objectives
 * @see https://betterise-api-doc.betterise.me/#api-Objectives-getUserObjectives
 * @return Response object
 */
async function getUserObjectives() {
    return queueQueries(`${API_URL}/objectives/user/`, 'GET')
}

export const Objectives = {
    addObjective,
    getObjectives,
    getUserObjectives,
    updateObjective
};
