import { t, h } from '@app/utils';
import { Button, Card, Modal } from '@app/elements';

import icoDeco from '@app/img/food/deco-blank.png';
import icoDecox2 from '@app/img/food/deco-blank@2x.png';

import { AddMeal } from '@app/modules/Food/AddMeal';

export const view = (state, actions) => (props, children) => (
    <Card kind={'nomeal-atall'}>
        <Card.Body>
            <div class='col-4 btzData-btzDatasWrapper'>
                <Card.Image classes='btzCard-btzImage-btzAskImage'><img data-customer={props.customer} src={icoDeco} srcset={`${icoDecox2} 2x`} alt='' class='btzCircledAndBGColored' /></Card.Image>
            </div>
            <div class='col-8 btzData-btzDatasWrapper'>
                <p class='btzPageContentTexte'>{t('Envie d’analyser vos repas, de comprendre votre nutrition, d’améliorer votre comportement alimentaire ?', {ns: 'food'})}</p>
                <p class='btzPageContentSubTexte' style={{ 'margin-bottom': '30px' }}>{t('Cliquez sur le bouton ci-dessous pour ajouter votre premier repas.', {ns: 'food'})}</p>
                <Modal content={<AddMeal customer={props.customer} userGender={props.userGender} ymdMealDate={state.ymdMealDate}></AddMeal>} styme={{ 'display': 'inline-block' }}>
                    <Button primary flat active>
                        {t('Ajouter un repas', {ns: 'food'})}
                    </Button>
                </Modal>
            </div>
        </Card.Body>
    </Card>
);
