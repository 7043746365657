import { t, h } from '@app/utils';
import { Avatar, Balloon, Button, Form, Tile } from '@app/elements';

import './index.scss'

export const view = (state, actions) => (props, children) => (
    <div style={{ position: 'relative' }}>
        <Tile>
            <Tile.Content>
                <p class='btzTitle btzXl' style={{ 'margin-bottom': '0', 'height': '115px' }}>
                    {t('Renseignez le code', {ns: 'register'})}<br />{t('communiqué par', {ns: 'register'})}<br />{t('votre entreprise.', {ns: 'register'})}</p>
            </Tile.Content>
        </Tile>

        <Form onsubmit={event => event.preventDefault()}>

            <Form.Group style={{ 'white-space': 'nowrap' }}>
                <Form.Input
                    placeholder={t('Votre code', {ns: 'register'})}
                    type='text'
                    id='idmember'
                    name='idmember'
                    value={state.entreprise.value}
                    oninput={(event) => actions.onEntrepriseInputDebouncedUpdate({string: event.target.value, props: props})}
                    onfocus={() => actions.onEntrepriseInputTouched()}
                    required
                />
            </Form.Group>

            <Form.Group classes='btzForm-btzFooter'>
                <Button primary block
                    active={(state.entreprise.isValid)}
                    onclick={(event) => {
                        // actions.updateParentProps(props)
                        actions.onStepSubmit(event)
                    }}>
                    {t('Valider', {ns: 'generals'})}
                </Button>
            </Form.Group>
        </Form>

        <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap' }}>
            <Tile.Icon>
                <Avatar.Alfred kind='error' classes={'centered'} />
            </Tile.Icon>
            <Tile.Content>
                {!state.api.message && (
                    <Balloon style={{ 'white-space': 'normal' }}>
                        <p style={{ 'text-align': 'left' }}>
                            <span class='btzBaloon-btzTitle'>{t('INSCRIPTION', {ns: 'register'})}</span>
                            <span> - 3/4</span>
                            <font>
                                <br />{t('Renseignez le code que votre entreprise vous a envoyé par email.', {ns: 'register'})}
                            </font>
                        </p>
                    </Balloon>
                )}
                {state.api.message === 'CompanyCodeNoEntry' && (
                    <Balloon kind='error' style={{ 'white-space': 'normal' }}>
                        {t('Merci de saisir un code.', {ns: 'register'})}
                    </Balloon>
                )}
                {state.api.message === 'CompanyCodeNotValid' && (
                    <Balloon kind='error' style={{ 'white-space': 'normal' }}>
                        {t('Veuillez renseigner un code correct.', {ns: 'register'})}
                    </Balloon>
                )}

            </Tile.Content>
        </Tile>
    </div >
);
