import { Cardline } from '@app/api';

export const actions = {
    onComponentCreate: (props) => (state, actions) => {
        //
    },
    rendering: () => state => ({
        rendering: !state.rendering,
    }),
    updateParentPropsWHYtrue: (props) => (state, actions) => {
        if (props) {
            props.send({
                ns: 'display',
                key: 'why',
                value: true
            })
        }
    },
    updateParentPropsREFUSED: (props) => (state, actions) => {
        if (props) {
            props.recommandedRefused();
        }
    },
    updateParentPropsACCEPTED: (props) => (state, actions) => {
        if (state.isLoading) {
            return false;
        }

        if (props) {
            actions.loading();
            Cardline.acceptChallenge(props.id, {accept: 1}).then(() => {
                actions.loading();
                props.send({
                    ns: 'display',
                    key: 'accepted',
                    value: true
                })
            });
        }
    },
    loading: () => state => ({
        isLoading: !state.isLoading,
    }),
    setRecommandeds: newState => state => ({
        recommandeds: newState,
    }),
    setRecommanded: newState => state => ({
        recommanded: newState,
        nRecommanded: --state.nRecommanded
    }),
};
