import { t, h } from '@app/utils';
import { dynMod, getCustomerInfos } from '@app/core';

import * as staticsconstants from '@app/constants/constants-statics';

import './../index.scss';

import ParameterLayout from '@app/layouts/parameters/layout';

import Abonnement from './../abonnement';
import DataProfil from './../dataprofil';
import Cb from './../cb';
import Health from './../health';
import Informations from './../informations';
import Resiliate from './../resiliate';
import Sponsor from './../sponsor';

module.exports = {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <div>
                {state.user !== null &&
                    <ParameterLayout page='parameter' readablePageLabel={t(staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()].menuNames.account.title)} readablePageDescription={t('Pour gérer au mieux votre accompagnement santé', {ns: 'account'})} hideSponsor={state.user.maxChildren > 0 ? false : true} hidePayment={((state.user.customer === null) || (state.user.customer === undefined)) ? false : true} userMedia={(state.user.media !== undefined ? state.user.media.url : '')} customer={props.customer}>
                        <Informations {...state.user} customer={props.customer}></Informations>
                        {/*<Health></Health>*/}
                        {state.user.maxChildren > 0 &&
                            <Sponsor {...state.user} customer={props.customer}></Sponsor>
                        }
                        {(((state.user.customer === null) || (state.user.customer === undefined)) && dynMod('accountMod.payment', 'isactive')) &&
                            <Cb></Cb>
                        }
                        <div class='col-12 p-0'>
                            <div class='col-6 col-sm-12' style={{ 'vertical-align': 'top', 'padding-left': '0px' }}>
                                {dynMod('accountMod.abonnement', 'isactive') &&
                                    <Abonnement {...state.user}></Abonnement>
                                }
                                <DataProfil customer={props.customer}></DataProfil>
                            </div>
                            <div class='col-6 col-sm-12' style={{ 'vertical-align': 'top', 'padding-right': '0px' }}>
                                <Resiliate {...state.user}></Resiliate>
                                <p class='btzAboveTitle btzSmall'>
                                    {t('Un problème technique ? Une question à poser ?', {ns: 'generals'})}<br/>
                                    {t('Contactez', {ns: 'generals'}) + ' ' + getCustomerInfos(props.customer)['helper'] + ' :'} <a href={'mailto:' + getCustomerInfos(props.customer)['mailto']}>{getCustomerInfos(props.customer)['mailto']}</a>
                                </p>
                            </div>
                        </div>
                    </ParameterLayout>
                }
            </div>
        );
        return view;
    }
}
