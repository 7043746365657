const appsettings = {
    WEB_URL: process.env.APP_WEB_URL,
    CDN_URL: process.env.APP_CDN_URL,
    API_URL: process.env.APP_API_URL,
    CUSTO_SSO: process.env.APP_CUSTOMISATION,
    L10N: process.env.APP_L10N,
    LANG: process.env.APP_LANG,
    FALLBACKLANG: process.env.APP_FALLBACKLANG,
    DEBUG: process.env.APP_DEBUG,
    STRIPE_KEY: process.env.APP_STRIPE_KEY,
    LANGUAGES: {'fr_FR' : 'Français', 'en_GB' : 'Anglais', 'pt_PT' : 'Portugais'},
    METRICS: {'0-0' : 'kg / cm', '0-1' : 'kg / feet', '1-0' : 'stones / cm', '1-1' : 'stones / feet', '2-0' : 'pounds / cm', '2-1' : 'pounds / feet'}
};

export { appsettings };