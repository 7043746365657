import { h } from '@app/utils';
import { isDefined } from '@app/core';

import EmptyLayout from '@app/layouts/empty/layout';

import Advice from './../../Timeline/cards/advice';
import Article from './../../Timeline/cards/article';
import Recipe from './../../Timeline/cards/recipe';
import Citation from './../../Timeline/cards/citation';
import Prenom from './../../Timeline/cards/prenom';

import ProgramAll from './../../Programs/all';
import ProgramRunning from './../../Programs/running';
import ProgramStart from './../../ProgramStart';
import ProgramSession from './../../ProgramSession';

export const view = (state, actions) => (props, children) => (
    <div key='adminpreview'>
        <EmptyLayout page='adminpreview'>
            <div class='publicshare-share'>
                {(isDefined(state.card) && isDefined(state.card.type)) &&
                    <div key={(isDefined(state.card) ? state.card.id : 'Ø')}>
                        <p class='btzTitle btzXl'>Preview {state.card.displayedLang}</p>
                        {(isDefined(state.card.type) && (String(state.card.type).indexOf('program') > -1)) ?
                            <div style={{ position: 'relative', 'margin': 'auto' }}>
                                {(state.card.type.indexOf('session') > -1) ?
                                    <ProgramSession {...state.card} displayedon='preview' customer={props.customer}></ProgramSession>
                                    :
                                    <dummy>
                                        <ProgramAll {...state.card} displayedon='preview' customer={props.customer}></ProgramAll>
                                        <ProgramRunning {...state.card} displayedon='preview' customer={props.customer}></ProgramRunning>
                                        <ProgramStart {...state.card} displayedon='preview' customer={props.customer}></ProgramStart>
                                    </dummy>
                                }
                            </div>
                            :
                            <div style={{ position: 'relative', 'max-width': '560px', 'margin': 'auto', 'padding-bottom': '80px' }}>
                                {state.card.type === 2 &&
                                    <Advice {...state.card} displayedon='share' customer={props.customer}></Advice>
                                }
                                {state.card.type === 20 &&
                                    <Citation {...state.card} displayedon='share' customer={props.customer}></Citation>
                                }
                                {state.card.type === 8 &&
                                    <Prenom {...state.card} displayedon='share' customer={props.customer}></Prenom>
                                }
                                {state.card.type === 18 &&
                                    <Article {...state.card} displayedon='share' customer={props.customer}></Article>
                                }
                                {state.card.type === 19 &&
                                    <Recipe {...state.card} displayedon='share' customer={props.customer}></Recipe>
                                }
                            </div>
                        }
                    </div>
                }
            </div>
        </EmptyLayout>
    </div>
);

/*
<a class="btz-button-gjensidige" href="https://itunes.apple.com/fr/app/iver/id1298542367?mt=8">
        {{ "Télécharger depuis l'App Store" | trans | upper }}
    </a>
</div>
<div class="col-xs-6">
    <a class="btz-button-gjensidige" href="https://play.google.com/store/apps/details?id=com.betterise.gjensidige&hl=fr ">
        {{ "Télécharger depuis Google Play" | trans | upper }}
    </a>
 */