export const state = {
    entreprise: {
        value: '',
        id: '',
        isTouched: true,
        isValid: false,
        isApproved: false,
    },
    api: {
        status: '',
        message: ''
    }
};
