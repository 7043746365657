import { h, Component } from '@app/utils';
import { dynLoad } from '@app/core';

const state = {
    uniqId: null,
    rendering: false,
    tmdate: null,
    timelines: [],
    user: {
        profil: null,
        alfred: null,
        challenges: {
            recommanded: null,
            running: []
        },
        objectives: null,
        notifications: [0],
        registrationDay: null,
    },
    cards: {
        advices: null,
        citations: null,
        articles: null,
        recipes: null,
        quizzs: null,
        welcome: null,
        offers: null,
        survey: null,
    },
    isLoading: false,
    isRegisterDay: false,
    displayedNotifications: false,
    mainTimelineLoaded: false,
}


const actions = dynLoad('action', 'Timeline/actions');
const view = dynLoad('view', 'Timeline/views');

export default Component(state, actions, view, 'timeline');
