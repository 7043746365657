import { t, h, Component } from '@app/utils';
import { Share } from '@app/api';
import './index.scss';

import ImgArrowLeft from './dp/dp-left-arrow.png';
import ImgArrowLeftx2 from './dp/dp-left-arrow@2x.png';

import ImgArrowRight from './dp/dp-right-arrow.png';
import ImgArrowRightx2 from './dp/dp-right-arrow@2x.png';

Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}
Date.isLeapYear = function (year) {
    return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0));
};

Date.getDaysInMonth = function (year, month) {
    return [31, (Date.isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
};

Date.prototype.isLeapYear = function () {
    return Date.isLeapYear(this.getFullYear());
};

Date.prototype.getDaysInMonth = function () {
    return Date.getDaysInMonth(this.getFullYear(), this.getMonth());
};

Date.prototype.addMonths = function (value, sub) {
    var n = this.getDate();
    this.setDate(1);
    if (!sub) {
        this.setMonth(this.getMonth() + value);
    } else {
        this.setMonth(this.getMonth() - value);
    }
    this.setDate(Math.min(n, this.getDaysInMonth()));
};

const DatePicker = Component(
    {
        date: null,
        mode: 'week',
        displayPeriod: true,
        displaySelect: true,
        firstDayDate: null,
        firstDay: null,
        lastDay: null,
        activeTopSticked: false,
        offsetTop: 65,
        fixed: false,
        noFutur: false,
        activePeriod: true,
        cols: {
            left: 2,
            center: 8,
            right: 2
        }
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.date) {
                state.date = props.date;
            }
            if (props.activeTopSticked) {
                state.activeTopSticked = props.activeTopSticked;
            }
            if (props.cols) {
                state.cols = props.cols;
            }

            if (props.activeTopSticked) {
                actions.activeTopSticked();
            }

            if (props.periodAction === undefined) {
                actions.setActivePeriod(false);
            }
            if (props.mode) {
                actions.changeMode(props.mode);
            }

            actions.parseDate();
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (props.date) {
                state.date = props.date;
            }

            if (props.periodAction === undefined) {
                actions.setActivePeriod(false);
            }

            actions.parseDate();
        },
        parseDate: () => (state, actions) => {
            var today = new Date().setHours(0,0,0,0);
            var todayDate = new Date(state.date.substr(0, 4) + '-' + state.date.substr(4, 2) + '-' + state.date.substr(6, 2)).setHours(0, 0, 0, 0);
            var refDate = new Date(state.date.substr(0, 4) + '-' + state.date.substr(4, 2) + '-' + state.date.substr(6, 2));

            var newRefDate = new Date(refDate.valueOf());
            newRefDate.setDate(newRefDate.getDate());

            var firstday = new Date(refDate.setDate(refDate.getDate() - refDate.getDay() + 1));
            var lastday = new Date(refDate.setDate(refDate.getDate() - refDate.getDay() + 7));

            if (((lastday > today) && state.mode !== 'day') || (todayDate >= today)) {
                actions.setNoFutur(true);
            } else {
                if (state.noFutur) {
                    actions.setNoFutur(false);
                }
            }

            var options = { month: 'long', day: 'numeric', year: 'numeric' };
            if (state.mode === 'day') {
                lastday = null;
                firstday = new Date(state.date.substr(0, 4) + '-' + state.date.substr(4, 2) + '-' + state.date.substr(6, 2));
                options = { weekday:'long', month: 'long', day: 'numeric' };
            } else if (state.mode === 'week') {
                lastday = new Date(Date.parse(new Date(lastday)));
                let storedLang = localStorage.getItem('language').replace('_', '-');
                lastday = lastday.toLocaleDateString(storedLang, options);
                options = { month: 'long', day: 'numeric' };
            } else {
                lastday = null;
                if (state.mode === 'month') {
                    options = { month: 'long', year: 'numeric' };
                    firstday = new Date(state.date.substr(0, 4) + '-' + state.date.substr(4, 2) + '-' + state.date.substr(6, 2));
                } else if (state.mode === 'year') {
                    options = { year: 'numeric' };
                }
            }

            var month = newRefDate.getUTCMonth() + 1;
            if (+month < 10) {
                month = ('0' + month);
            }
            var day = newRefDate.getUTCDate();
            if (+day < 10) {
                day = ('0' + day);
            }
            var year = newRefDate.getUTCFullYear();
            actions.setFirstDayDate(year + '' + month + '' + day);

            let storedLang = localStorage.getItem('language').replace('_', '-');
            firstday = new Date(Date.parse(new Date(firstday)));
            firstday = firstday.toLocaleDateString(storedLang, options);//
            if (firstday.indexOf('1') > -1) {
                let tmpfirstday = firstday.split(' ');
                if (+tmpfirstday[0] === 1) {
                    firstday = tmpfirstday[0] + t('er', {ns: 'generals'}) + ' ' + tmpfirstday[1];
                }
            }

            actions.setFirstDay(firstday);
            actions.setLastDay(lastday);
        },
        changeMode: newState => state => ({
            mode: newState,
        }),
        setDate: newState => state => ({
            date: newState,
        }),
        setFirstDay: newState => state => ({
            firstDay: newState,
        }),
        setFirstDayDate: newState => state => ({
            firstDayDate: newState,
        }),
        setLastDay: newState => state => ({
            lastDay: newState,
        }),
        setNoFutur: newState => state => ({
            noFutur: newState,
        }),
        setActivePeriod: newState => state => ({
            activePeriod: newState,
        }),
        activeTopSticked: () => (state, actions) => {
            window.addEventListener('scroll', actions.handleTopStickedEvent);
        },
        handleTopStickedEvent: () => (state, actions) => {
            let obj = document.querySelectorAll('.datepicker')[0];
            if (obj === undefined) {
                return false;
            }
            let distanceToTop = obj.getBoundingClientRect().top;

            if ((distanceToTop - state.offsetTop) < 0) {
                if (!state.fixed) {
                    actions.setFixed(true);
                }
            } else if (state.fixed) {
                actions.setFixed(false);
            }
        },
        switchToThe: (side) => (state, actions) => {
            var refDate = new Date(state.date.substr(0, 4) + '-' + state.date.substr(4, 2) + '-' + state.date.substr(6, 2));
            var factor = 7;
            var newRefDate = new Date(refDate.setHours(23,0,0,0).valueOf());

            if (state.mode === 'month') {
                // factor = 30;
                if (side === 'left') {
                    newRefDate.addMonths(1, true);
                } else if (side === 'right') {
                    // factor = 8;
                    newRefDate.addMonths(1, false);
                }
            } else {
                if (state.mode === 'year') {
                    factor = 365;
                } else if (state.mode === 'day') {
                    factor = 1;
                }

                if (side === 'left') {
                    newRefDate.setDate(newRefDate.getDate() - factor);
                } else if (side === 'right') {
                    // factor = 8;
                    newRefDate.setDate(newRefDate.getDate() + factor);
                }
            }


            var month = newRefDate.getUTCMonth() + 1;
            var day = newRefDate.getUTCDate();
            var year = newRefDate.getUTCFullYear();

            if (+month < 10) {
                month = '0' + month;
            }
            if (+day < 10) {
                day = '0' + day;
            }

            actions.setDate(year + '' + month + '' + day);
        },
        parentUpdateSelector: (props) => (state, actions) => {
            if (props !== null) {
                props.selectorAction(state.firstDayDate);
            }
        },
        parentUpdatePeriod: (props) => (state, actions) => {
            if (props !== null) {
                props.periodAction(state.mode);
            }
        },
        setFixed: newState => state => ({
            fixed: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <div class={'btzDatePickerFixedMarker ' + (state.fixed ? 'fixed' : '')}>
            <div class='btzDatePicker'>
                {state.activePeriod &&
                    <div class='col-5 col-sm-12 btzDatePicker-btzDatePeriod p-0'>
                        <div class='col-4 col-sm-12' style={{ 'padding': '0 10px' }}>
                            <div class='btzDatePicker-btzDatePeriod-btzButton' data-active={state.mode === 'week' ? true : false} onclick={() => {
                                actions.changeMode('week');
                                actions.parseDate();
                                actions.parentUpdatePeriod(props);
                            }}>
                                {t('Semaine', {ns: 'generals'})}
                            </div>
                        </div>
                        <div class='col-4 col-sm-12' style={{ 'padding': '0 10px' }}>
                            <div class='btzDatePicker-btzDatePeriod-btzButton' data-active={state.mode === 'month' ? true : false} onclick={() => {
                                actions.changeMode('month');
                                actions.parseDate();
                                actions.parentUpdatePeriod(props);
                            }}>
                                {t('Mois', {ns: 'generals'})}
                            </div>
                        </div>
                        <div class='col-4 col-sm-12' style={{ 'padding': '0 10px' }}>
                            <div class='btzDatePicker-btzDatePeriod-btzButton' data-active={state.mode === 'year' ? true : false} onclick={() => {
                                actions.changeMode('year');
                                actions.parseDate();
                                actions.parentUpdatePeriod(props);
                            }}>
                                {t('Année', {ns: 'generals'})}
                            </div>
                        </div>
                    </div>
                }
                <div class='col-7 col-sm-12 btzDatePicker-btzDateSelector p-0'>
                    <div class={'col-' + state.cols.left + ' col-xs-2 btzDatePicker-btzDateSelector-btzToTheLeft'} onclick={() => {
                        actions.switchToThe('left');
                        actions.parseDate();
                        actions.parentUpdateSelector(props);
                    }}>
                        <img src={ImgArrowLeft} srcset={`${ImgArrowLeftx2} 2x`} alt='' class='' />
                    </div>
                    <div class={'col-' + state.cols.center + ' col-xs-8 btzDatePicker-btzDateSelector-btzDates'}>
                        <div class={'col-' + (state.lastDay === null ? '12' : '6') + ' btzDatePicker-btzDateSelector-btzDates-btzDateLeft'}>
                            {state.firstDay}
                        </div>
                        {state.mode === 'week' &&
                            <p class='btzDatePicker-btzDateSelector-tiret'>{'-'}</p>
                        }
                        {state.lastDay !== null &&
                            <div class='col-6 btzDatePicker-btzDateSelector-btzDates-btzDateRight'>
                                {state.lastDay}
                            </div>
                        }
                    </div>
                    <div class={'col-' + state.cols.right + ' col-xs-2 btzDatePicker-btzDateSelector-btzToTheRight'} style={{ 'opacity': (state.noFutur ? '0.2' : '1') }} onclick={() => {
                        if (state.noFutur) {
                            return false;
                        }
                        actions.switchToThe('right');
                        actions.parseDate();
                        actions.parentUpdateSelector(props);
                    }}>
                        <img src={ImgArrowRight} srcset={`${ImgArrowRightx2} 2x`} alt='' class='' />
                    </div>
                </div>
            </div>
        </div>
    ),
    'datepicker'
);

export { DatePicker };
