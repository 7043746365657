import { isDefined, deepCopy } from '@app/core';
export const actions = {
    onComponentCreate: (props) => (state, actions) => {
        var callback = (previewCard) => {
            console.log('card : previewCard ', previewCard.data);
            if (!isDefined(previewCard.data)) {
                actions.setCard(null);
                return false;
            }
            let card = JSON.parse(previewCard.data);
            if (isDefined(card)) {
                let lang = card.displayedLang;
                for (var field in card) {
                    if (isDefined(card[field])) {
                        if (isDefined(card[field][lang])) {
                            card[field] = card[field][lang];
                        }
                        for (var subfield in card[field]) {
                            if (isDefined(card[field][subfield]) && isDefined(card[field][subfield][lang])) {
                                if (field === 'steps') {
                                    card[field][subfield].content = card[field][subfield][lang];
                                } else {
                                    card[field][subfield] = card[field][subfield][lang];
                                }
                                if (field.indexOf('media') > -1) {
                                    if (isDefined(card[field]['base64'][lang]) && (card[field]['base64'][lang] !== false)) {
                                        card.mediaId = {};
                                        card.media = card[field]['base64'][lang];
                                    }
                                }
                            }
                        }
                    }
                }
                // 'https://api-release.betterise.me/medias/' + card.mediaId + '/';
                if (card.mediaId) {
                    if (typeof card.mediaId !== 'object') {
                        if (String(card.type).indexOf('program') > -1) {
                            card.media = {};
                            card.media.url = process.env.APP_API_URL + '/medias/' + card.mediaId + '/';
                        } else {
                            card.media = process.env.APP_API_URL + '/medias/' + card.mediaId + '/';
                        }
                    } else {
                        if (String(card.type).indexOf('program') > -1) {
                            let tmpmedia = {};
                            tmpmedia.url = card.media;
                            card.media = tmpmedia;
                        }
                    }
                }
                if (card.mediaId === false) {
                    card.media = null;
                }
                if (card.type === 19) {
                    card.recipe = deepCopy(card);
                } else if (card.type === 18) {
                    card.article = deepCopy(card);
                }
                console.log('card : ', card);
                actions.setCard(card);
            }
        };
        window.addEventListener('message', callback, false);
    },
    setCard: newState => state => ({
        card: newState,
    })
};
