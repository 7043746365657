import { t, h } from '@app/utils';
import { redirectToWebOrApp } from '@app/core';
import { Button, Tile } from '@app/elements';

import logoSubscription from '@app/img/public/subscription/your-subscription.png';
import logoSubscriptionx2 from '@app/img/public/subscription/your-subscription@2x.png';

import PublicLayout from '@app/layouts/public/layout';

module.exports = {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <div key='publicemailconfirm'>
                <PublicLayout page='emailconfirm' customer={props.customer}>
                    <div class='publicemailconfirm-emailconfirm'>
                        <div style={{ position: 'relative' }}>
                            <Tile>
                                <div>
                                    <div class='btzTitle-btzImage btzTitle-btzImage-absolute col-md-12 col-4'>
                                        <img src={logoSubscription} srcset={`${logoSubscriptionx2} 2x`} alt='' class='' />
                                    </div>
                                    <Tile.Content classes='btzLeftHrArrowed col-xs-12 col-8'>
                                        <p class='btzTitle btzXl'>{t('Merci !', {ns: 'public'})}</p>
                                        <p class='btzTexte' style={{ 'max-width': '320px' }}>{t('Votre adresse e-mail est maintenant confirmée', {ns: 'public'}) + ' : '}<b>{state.email}</b></p>
                                        <Button flat active={state.emailConfirmed} onclick={() => {
                                            if (state.emailConfirmed === false) {
                                                // return false;
                                            }
                                            redirectToWebOrApp(props.customer);
                                        }}>
                                            {t('Accéder à', {ns: 'public'}) + ' ' + state.sharedFrom}
                                        </Button>
                                    </Tile.Content>
                                </div>
                            </Tile>
                        </div>
                    </div>
                </PublicLayout>
            </div>
        );
        return view;
    }
}
