import { appCustoSetting } from '@app/settings/settings';
import { queueQueries } from '@app/api/controlleur';
const { API_URL } = appCustoSetting.appsettings;

/**
 * Calls API to check email existence
 * @see https://betterise-api-doc.betterise.me/#api-Session-checkEmail
 * @param {string} accountEmail Account email
 * @return Response object
 */
async function checkEmail(accountEmail) {
    return queueQueries(`${API_URL}/user/checkemail/`, 'POST', 'application/json', true, false, true, false, true, {email: accountEmail}, true)
}

async function newPassword(newPwd) {
    return queueQueries(`${API_URL}/session/new-password/`, 'POST', 'application/json', true, false, true, true, true, {newpassword: newPwd}, true)
}

/**
 * Calls API to get a session token from user email and password
 * @see https://betterise-api-doc.betterise.me/#api-Session-createSession
 * @param {string} accountEmail Account email
 * @param {string} accountPassword Account password
 * @return Response object
 * @example
 * createSession(<email>, <password>).then(res => {
 *   localStorage.setItem("apitoken", `"${res.token}"`);
 *   localStorage.setItem("refresh", `"${res.refresh}"`);
 * }
 */
function createSession(accountEmail, accountPassword, customer = null) {
    if (customer !== null) {
        if (['cerba'].indexOf(customer) > -1) {
            return queueQueries(`${API_URL}/${customer}/session/`, 'POST', 'application/json', true, false, true, false, true, {email: accountEmail, password: accountPassword}, true)
        }
    }
    return queueQueries(`${API_URL}/session/`, 'POST', 'application/json', true, false, true, false, true, {email: accountEmail, password: accountPassword}, true)
}

function createMedicalSession(birthdate, medicalStudytoken) {
    return queueQueries(`${API_URL}/session/medical-study/`, 'POST', 'application/json', true, false, true, false, true, {birthdate: birthdate, medicalStudyToken: medicalStudytoken}, true, true)
}

/**
 * Calls API to reset password of an account identified by its email
 * @see https://betterise-api-doc.betterise.me/#api-Session-resetSession
 * @param {string} accountEmail Account email
 * @param {string} [mobileSchemeUrl=''] Mobile app scheme URL
 * @return Response object
 */
async function refreshSession(accountEmail, mobileSchemeUrl = '', customer = null) {
    if (customer !== null) {
        if (['cerba'].indexOf(customer) > -1) {
            return queueQueries(`${API_URL}/${customer}/session/refresh/`, 'POST', 'application/json', true, false, true, false, true, {email: accountEmail, scheme: mobileSchemeUrl}, true)
        }
    }
    return queueQueries(`${API_URL}/session/refresh/`, 'POST', 'application/json', true, false, true, false, true, {email: accountEmail, scheme: mobileSchemeUrl}, true)
}

async function resetSession(accountEmail, mobileSchemeUrl = '') {
    return queueQueries(`${API_URL}/session/reset/`, 'POST', 'application/json', true, false, true, false, true, {email: accountEmail, scheme: mobileSchemeUrl}, true)
}

export const Session = {
    checkEmail,
    createSession,
    createMedicalSession,
    refreshSession,
    resetSession,
    newPassword,
};
