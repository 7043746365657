import { guid } from './guid';
import { isPublicRoute } from './isPublicRoute';
import { getQueryVariable } from './getQueryVariable';
import { displayErrors } from './displayErrors';
import { t } from 'i18next'; // need to be removed to remove dependancy.

const checkForValidCSRFToken = function() {
    if (isPublicRoute()) {
        return true;
    }
    var isValid = true;
    var token = getQueryVariable('csrftoken', false);
    if (!token || token === '') {
        token = getQueryVariable('token', false);
        if (!token || token === '') {
            displayErrors(t('Votre session est corrompue. Veuillez vous connecter à nouveau.', {ns: 'generals'}) + ' (csrf)', 2000);
            isValid = false;
        } else {
            return true;
        }
    }

    var localToken = localStorage.getItem('csrftoken');
    if (token !== localToken) {
        displayErrors(t('Votre connexion est expirée. Veuillez vous connecter à nouveau.', {ns: 'generals'}) + ' (csrf)', 2000);
        isValid = false;
    }

    if (!isValid) {
        setTimeout(function() {
            localStorage.removeItem('apitoken');
            window.location.href = '/';
        }, 1500);
        return false
    }

    return true;
}

const addCSRFToken = function(string) {
    if (!checkForValidCSRFToken()) {
        return string;
    }

    var localToken = localStorage.getItem('csrftoken');
    var querySeparator = ((string.indexOf('?') > -1) ? '&' : '?');
    if (string.match('/[?&](csrftoken)=[^&]*/') !== null) {
        // eslint-disable-next-line
        string = string.replace('/([?&]csrftoken)=[^&]*/', $token);
    } else {
        string += (querySeparator + 'csrftoken=' + localToken);
    }
    return string;
}

const createAndSaveCSRFToken = function(force = false) {
    var existingCsrftoken = localStorage.getItem('csrftoken');
    if ((existingCsrftoken !== null) && (existingCsrftoken !== 'null') && (existingCsrftoken !== '') && (existingCsrftoken !== undefined) && !force) {
        return existingCsrftoken;
    }
    var csrfToken = guid();
    localStorage.setItem('csrftoken', csrfToken);

    var paramPrefix = '?';
    var newurl = window.location.protocol + '//' + window.location.host + window.location.pathname;
    if (newurl.indexOf('?') > -1) {
        paramPrefix = '&';
    }
    newurl = newurl + paramPrefix + 'csrftoken=' + csrfToken;
    window.history.pushState({path:newurl}, '', newurl);

    return csrfToken;
}

export { checkForValidCSRFToken, addCSRFToken, createAndSaveCSRFToken };