import { t, h, Component } from '@app/utils';
import { isDefined, convertObjectToArray } from '@app/core';
import { Card, Social,  Chart, Media, Convert, SimpleChart } from '@app/elements';

import '@app/modules/Food/index-addmeal.scss';

import { ViewAlimentDetail } from '@app/modules/Food/AddMeal/step-listing-entry/viewAlimentDetail';

const ModalViewRecipe = Component(
    {
        props: null,
        rendering: false,
    },
    {
        onComponentCreate: (props) => (state) => {
            if (props) {
                state.props = props;
            }
        },
        rendering: () => state => ({
            rendering: !state.rendering,
        }),
    },
    (state, actions) => (props, children) => (
        <div class='recipe-opened' style={{ 'height': '100%' }}>
            {props.alimentDetailDisplayed === true ?
                <div oncreate={(el) => props.scrollTop(el) } onupdate={(el) => props.scrollTop(el) } class='btzLunch-btzAliment-btzDetail-btzWrapper' style={{ 'overflow': 'auto', 'height': '100%', 'position': 'relative' }}>
                    <ViewAlimentDetail displayAlimentDetail={props.displayAlimentDetail} tagaliment={props.tagaliment} setKindOfInfos={props.setKindOfInfos} setDetailAliment={props.setDetailAliment} lunchAliment={null} addOneAliment={null}></ViewAlimentDetail>
                </div>
                :
                <Card kind={'recipe'} theme={props.valueTheme}>
                    {(isDefined(props) && (props !== false) && isDefined(props.title)) &&
                        <div>
                            <Card.Header classes={'btzCard-btzHeader-btzModal'}>
                                <Social kindcard={'recipe'} title={props.title} idfavorite={props.favoriteId} idtimeline={props.timeline} id={props.id} optfavid={props.optfavid} favorite={props.favorite} render={actions.rendering} propagate={props.propagate} displayedon={props.displayedon} cancelAction={props.propagateConfirmClosing}></Social>
                                {props.media !== undefined &&
                                    <Card.Image kind={props.media} classes={'add-class'} data-number={3}><Media src={props.media}></Media></Card.Image>
                                }
                                <p class='btzTimeline-btzCard-btzTitle btzTitle'>{props.title}</p>
                                <p class='btzTimeline-btzCard-btzSubTitle btzSubTitle btzTextColored' data-theme={'cooking'}>{t('Recette de cuisine', {ns: 'timeline'})}</p>
                            </Card.Header>

                            <Card.Body classes={'btzCard-btzBody-btzModal btzTopCustomDotted'} style={{ 'padding-bottom': '70px' }}>
                                <p class='btzTimeline-btzCard-btzDescription btzTexte' innerHTML={<Convert separator={''} is_xhtml={true} type='nl2br'>{props.description}</Convert>}></p>
                                <div class='btzTimeline-btzRecipe-btzInfos'>
                                    <div class='btzTimeline-btzRecipe-btzInfos-btzWrapper'>
                                        {isDefined(props.making) &&
                                            <SimpleChart value={props.making.value} unit={props.making.unit} values={props.making.values} color={'#FF6F41'}></SimpleChart>
                                        }
                                        <p class='btzLightTexte'>{t('préparation', {ns: 'timeline'})}</p>
                                    </div>
                                    <div class='btzTimeline-btzRecipe-btzInfos-btzWrapper'>
                                        {isDefined(props.baking) &&
                                            <SimpleChart value={props.baking.value} unit={props.baking.unit} values={props.baking.values} color={'#FF6F41'}></SimpleChart>
                                        }
                                        <p class='btzLightTexte'>{t('cuisson', {ns: 'timeline'})}</p>
                                    </div>
                                    <div class='btzTimeline-btzRecipe-btzInfos-btzWrapper'>
                                        {isDefined(props.rest) &&
                                            <SimpleChart value={props.rest.value} unit={props.rest.unit} values={props.rest.values} color={'#FF6F41'}></SimpleChart>
                                        }
                                        <p class='btzLightTexte'>{t('repos', {ns: 'timeline'})}</p>
                                    </div>
                                </div>

                                <div>
                                    <div class='btzTimeline-btzCard-btzTimeline-btzRecipe-btzTimeline-btzTitles'>
                                        <p class='btzTimeline-btzCard-btzTitle btzTitle btzTextColored btzXl' data-theme={'cooking'}>{t('Ingrédients', {ns: 'timeline'}) + ' (' + t('pour', {ns: 'timeline'}) + ' ' + (props.persons || '-') + ' ' + t('personne', {ns: 'timeline'}) + (props.persons > 1 ? 's' : '') + ')' }</p>
                                        <p class='btzTimeline-btzCard-btzTexte btzTexte' innerHTML={<Convert separator={'- '} is_xhtml={true} type='nl2br'>{props.ingredients}</Convert>}></p>
                                    </div>
                                    <div class='btzTimeline-btzCard-btzTimeline-btzRecipe-btzTimeline-btzTitles'>
                                        <p class='btzTimeline-btzCard-btzTitle btzTitle btzTextColored btzXl' data-theme={'cooking'}>{t('Préparation', {ns: 'timeline'})}</p>
                                        <div class='btzTimeline-btzCard-btzRecipe-btzIngredients'>
                                            {(isDefined(props.steps)) &&
                                                <div>
                                                    {convertObjectToArray(props.steps).sort((a, b) => +a.order - +b.order).filter((f) => (f.content !== '')).map((item, idx) =>
                                                        <div class='btzTimeline-btzCard-btzRecipe-btzIngredient' key={item.id}>
                                                            <div class='col-1 p-0' style={{ 'vertical-align': 'top' }}>
                                                                <div class='btzTimeline-btzCard-btzRecipe-btzIngredientPuce'>{(idx + 1)}</div>
                                                            </div>
                                                            <div class='col-11' style={{ 'padding-left': '20px' }}>
                                                                <p class='btzTimeline-btzCard-btzTexte btzTexte'>{item.content}</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div class='btzTimeline-btzCard-btzTimeline-btzRecipe-btzTimeline-btzTitles'>
                                        {props.alternative !== '' &&
                                            <div class=''>
                                                <p class='btzTimeline-btzCard-btzTitle btzTitle btzTextColored btzXl' data-theme={'cooking'}>{t('Variante', {ns: 'timeline'})}</p>
                                                <p class='btzTimeline-btzCard-btzTexte btzTexte' innerHTML={props.alternative}></p>
                                            </div>
                                        }
                                    </div>
                                    <div class='btzTimeline-btzCard-btzTimeline-btzRecipe-btzTimeline-btzTitles'>
                                        {((props.tags) && (props.tags.length > 0)) &&
                                            <div class='btzTags-btzListing'>
                                                {props.tags.filter((f) => (isDefined(f) && f.name !== '')).map((item) =>
                                                    <div class='btzTag-btzEntry' data-theme={'cooking'} onclick={() => { props.displayAlimentDetail(true); props.setTag(item.tag) }}>{item.name}</div>
                                                )}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </Card.Body>
                        </div>
                    }
                </Card>
            }

            {(!isDefined(props) || (props === false) || (props.title === undefined)) &&
                <div class='btzLoadingContainer-btzWrapper'>
                    <div class='btzLoadingContainer'></div>
                </div>
            }
        </div>
    ),
    'modal'
);

export { ModalViewRecipe };