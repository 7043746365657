import { t, h } from '@app/utils';
import { Button, Form, Tile } from '@app/elements';

import logoSubscription from '@app/img/public/subscription/your-subscription.png';
import logoSubscriptionx2 from '@app/img/public/subscription/your-subscription@2x.png';

import PublicLayout from '@app/layouts/public/layout';

export const view = (state, actions) => (props, children) => (
    <div key='publicsubscription'>
        <PublicLayout page='subscription' customer={props.customer}>
            <div class='publicsubscription-subscription'>
                <div style={{ position: 'relative' }}>
                    <Tile>
                        {state.kind === 'modification' ?
                            <div>
                                <div class='btzTitle-btzImage btzTitle-btzImage-absolute col-md-12 col-4'>
                                    <img src={logoSubscription} srcset={`${logoSubscriptionx2} 2x`} alt='' class='' />
                                </div>
                                {state.verified === true ?
                                    <Tile.Content classes='btzLeftHrArrowed col-xs-12 col-8'>
                                        <p class='btzTitle btzXl'>{t('Vos abonnements', {ns: 'public'})}</p>
                                        <p class='btzTexte' style={{ 'max-width': '320px' }}>{t('Pour gérer vos abonnements aux mails envoyés à l’adresse suivante, modifiez et validez les options ci-dessous : ', {ns: 'public'})}<b>{state.email}</b></p>
                                        <Form onsubmit={event => event.preventDefault()}>
                                            <Form.Group>
                                                <Button.SwitchCheckBox {...state} onclick={() => {
                                                    actions.setChecked(!state.checked);
                                                }}>
                                                    {t('Je veux recevoir les emails', {ns: 'public'})}
                                                </Button.SwitchCheckBox>
                                            </Form.Group>

                                            <Form.Group classes='btzForm-btzFooter'>
                                                <Button primary block active={!state.submitting} loading={state.submitting}
                                                    onclick={(event) => {
                                                        actions.onSubmit(event)
                                                    }}>
                                                    {t('Valider', {ns: 'generals'})}
                                                </Button>
                                            </Form.Group>
                                        </Form>
                                    </Tile.Content>
                                    :
                                    <Tile.Content classes='btzLeftHrArrowed col-xs-12 col-8'>
                                        <p class='btzTitle btzXl'>{t('Vos abonnements', {ns: 'public'})}</p>
                                        <p class='btzTexte' style={{ 'max-width': '320px' }}>{t('Vous devez vérifier votre email : ', {ns: 'public'})}<b>{state.email}</b></p>
                                        <Form onsubmit={event => event.preventDefault()}>

                                            <Form.Group classes='btzForm-btzFooter'>
                                                <Button primary block active={!state.sended} loading={state.submitting}
                                                    onclick={(event) => {
                                                        actions.sendVerificationMail(event);
                                                    }}>
                                                    {t('Vérifier mon email', {ns: 'public'})}
                                                </Button>
                                            </Form.Group>
                                        </Form>
                                    </Tile.Content>
                                }
                            </div>
                            :
                            <div>
                                <div class='btzTitle-btzImage btzTitle-btzImage-absolute col-md-12 col-4'>
                                    <img src={logoSubscription} srcset={`${logoSubscriptionx2} 2x`} alt='' class='' />
                                </div>
                                <Tile.Content classes='btzLeftHrArrowed col-xs-12 col-8'>
                                    <p class='btzTitle btzXl'>{t('Vos abonnements', {ns: 'public'})}</p>
                                    <p class='btzTexte' style={{ 'max-width': '320px' }}>{t('Votre demande de désabonnement concernant l’adresse suivante a bien été prise en compte', {ns: 'public'}) + ' : '}<b>{state.email}</b></p>
                                </Tile.Content>
                            </div>
                        }
                    </Tile>
                </div>
            </div>
        </PublicLayout>
    </div>

);