import { dynLoad, isDefined } from '@app/core';
import { t, h } from '@app/utils';
import { allRoutes } from '@app/settings';

import { Avatar, Balloon, Button, Form, Tile, Counter, DoubleInput, Convert } from '@app/elements';
import './../index.scss';

import OnboardingLayout from '@app/layouts/onboarding/layout';

import icoInfo from '@app/img/ico/ico-ask-white.png';
import icoInfox2 from '@app/img/ico/ico-ask-white@2x.png';

import icoValidQCM from '@app/img/ico/ico-valid-white.png';
import icoValidQCMx2 from '@app/img/ico/ico-valid-white@2x.png';

export const img = dynLoad('img', 'Onboarding/custo');
const ONBOARDING_IMG = img.ONBOARDING_IMG;

import * as constants from '@app/constants';

module.exports = {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <div class='specificFullHeightMobile'>
                {state.current.form &&
                    <OnboardingLayout page='onboarding' theme={state.current.theme} customer={props.customer}>
                        <div class='specificFullHeightMobile' style={{ position: 'relative' }}>
                            <Tile style={{ 'text-align': 'left' }}>
                                {(state.mobileOnboarding === true) ?
                                    <div class='btzTitle-btzImage btzTitle-btzImage-absolute col-md-12 col-5' style={{ 'max-width': '450px' }}>
                                        <p class='btzTitle btzXl'>{t('Question', {ns: 'onboarding'}) + ' ' + state.current.numCard + '/' + state.totalCards}</p>
                                        <p class='btzSubTitle'>{state.current.question}</p>
                                    </div>
                                    :
                                    <div class='btzTitle-btzImage btzTitle-btzImage-absolute col-md-12 col-5' style={{ 'max-width': '450px' }}>
                                        <p class='btzTitle btzXl' style={{ 'margin-left': '80px' }}>{state.current.question}</p>
                                    </div>
                                }
                                <Tile.Content classes='btzLeftHrArrowedOnboarding col-xs-12 col-7' style={{ 'text-align': 'center' }}>
                                    {state.current.form && (
                                        <div key={'form-' + state.current.form.id + '-' + state.current.numCard} class={'form-' + state.current.form.type + ' form-id-' + state.current.form.id + '-' + state.current.numCard}>
                                            {state.current.form.type == 'slider' && (
                                                <div class='btzOnboarding-btzButtonsWrapper btzOnboarding-btzButtonsWrapper-slider' style={{ 'max-width': '425px' }}>
                                                    {state.current.form.datas.map(item =>
                                                        <div onclick={actions.selectThisAnswer} data-id={state.current.form.id} data-tag={state.current.form.tag} data-kind='slider' data-value={item.value} class='btzOnBoarding-btzButton btzUnselectable btzOnBoarding-btzButton-btzNumber'>{item.name}</div>
                                                    )}
                                                </div>
                                            )}
                                            {state.current.form.type == 'number' && (
                                                <div class='btzOnboarding-btzButtonsWrapper btzOnboarding-btzButtonsWrapper-number'>
                                                    <Counter key={state.current.form.id} mobileOnboarding={state.mobileOnboarding} class='btzCurrentEvaluationMarker' floating={(((state.current.theme === 'followed-weight') && (!state.mobileOnboarding)) ? true : false)} min={0} max={1000} did={state.current.form.id} kind='number' tag={state.current.form.tag} label={t(state.current.form.label)} initialCount={state.specialInitialCount} isMobile={(state.mobileOnboarding)}></Counter>
                                                </div>
                                            )}
                                            {(['cryptedweight', 'cryptedtemperature', 'heartbeat'].indexOf(state.current.form.type.toLowerCase()) > -1) && (
                                                <div class='btzOnboarding-btzButtonsWrapper btzOnboarding-btzButtonsWrapper-number'>
                                                    <Counter key={state.current.form.id} mobileOnboarding={state.mobileOnboarding} class='btzCurrentEvaluationMarkerParent btzCurrentEvaluationMarker' floating={true} min={state.current.stepRanges[0].datas[0].min} max={state.current.stepRanges[0].datas[0].max} did={state.current.form.id} kind='number' tag={(isDefined(constants.custo.EVAL_TYPES_MEDICAL.filter((f) => f.key.toLowerCase() === state.current.form.type.toLowerCase())[0]) ? constants.custo.EVAL_TYPES_MEDICAL.filter((f) => f.key.toLowerCase() === state.current.form.type.toLowerCase())[0].tag[0] : '')} label={t(state.current.form.label)} default={state.current.stepRanges[0].datas[0].default} initialCount={state.current.stepRanges[0].datas[0].default} isMobile={(state.mobileOnboarding)}></Counter>
                                                </div>
                                            )}

                                            {/*state.current.form.type.toLowerCase() == 'cryptedweight' && (
                                                <div class='btzOnboarding-btzButtonsWrapper btzOnboarding-btzButtonsWrapper-number'>
                                                    <Counter key={state.current.form.id} mobileOnboarding={state.mobileOnboarding} class='btzCurrentEvaluationMarkerParent btzCurrentEvaluationMarker' floating={true} min={state.current.stepRanges[0].datas[0].min} max={state.current.stepRanges[0].datas[0].max} did={state.current.form.id} kind='number' tag={'CRYPTEDWEIGHT_VALUE'} label={t(state.current.form.label)} default={state.current.stepRanges[0].datas[0].default} initialCount={state.current.stepRanges[0].datas[0].default} isMobile={(state.mobileOnboarding)}></Counter>
                                                </div>
                                            )}
                                            {state.current.form.type.toLowerCase() == 'cryptedtemperature' && (
                                                <div class='btzOnboarding-btzButtonsWrapper btzOnboarding-btzButtonsWrapper-number'>
                                                    <Counter key={state.current.form.id} mobileOnboarding={state.mobileOnboarding} class='btzCurrentEvaluationMarkerParent btzCurrentEvaluationMarker' floating={true} min={state.current.stepRanges[0].datas[0].min} max={state.current.stepRanges[0].datas[0].max} did={state.current.form.id} kind='number' tag={'CRYPTEDTEMPERATURE_VALUE'} label={t(state.current.form.label)} default={state.current.stepRanges[0].datas[0].default} initialCount={state.current.stepRanges[0].datas[0].default} isMobile={(state.mobileOnboarding)}></Counter>
                                                </div>
                                            )}
                                            {state.current.form.type.toLowerCase() == 'heartbeat' && (
                                                <div class='btzOnboarding-btzButtonsWrapper btzOnboarding-btzButtonsWrapper-number'>
                                                    <Counter key={state.current.form.id} mobileOnboarding={state.mobileOnboarding} class='btzCurrentEvaluationMarkerParent btzCurrentEvaluationMarker' floating={true} min={state.current.stepRanges[0].datas[0].min} max={state.current.stepRanges[0].datas[0].max} did={state.current.form.id} kind='number' tag={'HEARTBEAT_VALUE'} label={t(state.current.form.label)} default={state.current.stepRanges[0].datas[0].default} initialCount={state.current.stepRanges[0].datas[0].default} isMobile={(state.mobileOnboarding)}></Counter>
                                                </div>
                                            )*/}
                                            {state.current.form.type.toLowerCase() == 'bloodpressure' && (
                                                <div class='btzOnboarding-btzButtonsWrapper btzOnboarding-btzButtonsWrapper-number'>
                                                    <DoubleInput key={state.current.form.id} mobileOnboarding={state.mobileOnboarding} class='btzCurrentEvaluationMarkerParent' floating={false} min={{'first': state.current.stepRanges[0].datas[0].min, 'second': state.current.stepRanges[1].datas[0].min}} max={{'first': state.current.stepRanges[0].datas[0].max, 'second': state.current.stepRanges[1].datas[0].max}} did={state.current.form.id} kind='number' tag={{'first': 'BLOODPRESSURE_SYSTOLIC', 'second': 'BLOODRESSURE_DIASTOLIC'}} label={t(state.current.form.label)} default={{'first': state.current.stepRanges[0].datas[0].default, 'second': state.current.stepRanges[1].datas[0].default}} initialCount={{'first': state.current.stepRanges[0].datas[0].default, 'second': state.current.stepRanges[1].datas[0].default}} isMobile={(state.mobileOnboarding)}></DoubleInput>
                                                </div>
                                            )}
                                            {state.current.form.type == 'textarea' && (
                                                <div class='btzOnboarding-btzButtonsWrapper btzOnboarding-btzButtonsWrapper-textarea'>
                                                    <textarea oncreate={(el) => { if (state.mobileOnboarding && el) { el.focus(); } el.value = '';  }} key={state.current.form.id}  class='btzCurrentEvaluationMarker' data-id={state.current.form.id} data-tag={state.current.form.tag} placeholder={t('Expliquez ici avec les mots de votre choix', {ns: 'onboarding'})} oninput={(el) => { if (el.target.value !== '') { actions.switchValidationActivation(true); } else { actions.switchValidationActivation(false); } }}></textarea>
                                                </div>
                                            )}
                                            {state.current.form.type == 'qcm' && (
                                                <div class='btzOnboarding-btzButtonsWrapper btzOnboarding-btzButtonsWrapper-qcm' style={{ 'margin-left': '48px' }}>
                                                    {state.current.form.datas.map(item =>
                                                        <div class='col-4 col-md-12' oncreate={actions.updateButtonHeight} style={{ 'min-height': (state.uniformButtonHeight > 0 ? (state.uniformButtonHeight + 'px') : 'auto') }}>
                                                            {item.datas.filter((f) => f.lang === state.language)[0] !== undefined &&
                                                                <div onclick={actions.selectThisAnswer} data-id={state.current.form.id} data-tag={item.tag} data-kind='qcm' data-value={item.datas.filter((f) => f.lang === state.language)[0].value} class='btzOnBoarding-btzButton btzOnBoarding-btzQcm-btzLabel'>
                                                                    <div class='btzOnboarding-btzButtonsWrapper-QcmPuce' data-theme={state.current.theme}>
                                                                        <img src={icoValidQCM} srcset={`${icoValidQCMx2} 2x`} alt='' />
                                                                    </div>
                                                                    <div class='btzOnboarding-btzButtonsWrapper-Label'>
                                                                        <p style={{ 'display': 'table', 'height': (state.uniformButtonHeight > 0 ? ((state.uniformButtonHeight - 20) + 'px') : 'auto') }}>
                                                                            <font style={{ 'display': 'table-cell', 'vertical-align': 'middle', 'text-align': 'left' }}>{item.datas.filter((f) => f.lang === state.language)[0].name}</font>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                            {state.current.form.type == 'button' && (
                                                <div class={'btzOnboarding-btzButtonsWrapper btzOnboarding-btzButtonsWrapper-button ' + state.current.name} data-length={state.current.form.datas.length}>
                                                    {state.current.form.datas.map(item =>
                                                        <dummy>
                                                            {state.current.name !== state.cerbaSportCoachDisplay ?
                                                                <div class='col-4 col-md-12'>
                                                                    {item.datas.filter((f) => f.lang === state.language)[0] !== undefined &&
                                                                        <div onclick={actions.selectThisAnswer} oncreate={actions.updateButtonHeight} data-id={state.current.form.id} data-tag={state.current.form.tag} data-kind='button' data-value={item.datas.filter((f) => f.lang === state.language)[0].value} class='btzOnBoarding-btzButton btzOnBoarding-btzSlider-btzLabel' style={{ 'min-height': (state.uniformButtonHeight > 0 ? (state.uniformButtonHeight + 'px') : 'auto') }}>
                                                                            <p class='btzUnselectable' style={{ 'padding': '10px 0' }}>
                                                                                {item.datas.filter((f) => f.lang === state.language)[0].name}
                                                                            </p>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                :
                                                                <div class='col-4 col-md-12 cerba-special-display' style={{ 'display': 'inline-block', 'margin': 'auto', 'vertical-align': 'top' }}>
                                                                    {(item.datas.filter((f) => f.lang === state.language)[0] !== undefined) &&
                                                                        <dummy>
                                                                            <div onclick={actions.selectThisAnswer} oncreate={actions.updateButtonHeight} data-id={state.current.form.id} data-tag={item.tag} data-kind='button' data-value={item.datas.filter((f) => f.lang === state.language)[0].value} class='btzOnBoarding-btzButton btzOnBoarding-btzSlider-btzLabel' style={{ 'display': 'table', 'min-height': (state.uniformButtonHeight > 0 ? (state.uniformButtonHeight + 'px') : 'auto') }}>
                                                                                <div style={{ 'position': 'relative', 'display': 'table-cell', 'vertical-align': 'middle' }}>
                                                                                    {(ONBOARDING_IMG[item.datas.filter((f) => f.lang === state.language)[0].name.toUpperCase()] !== undefined) &&
                                                                                        <img src={ONBOARDING_IMG[item.datas.filter((f) => f.lang === state.language)[0].name.toUpperCase() + '-small'].normal} srcset={`${ONBOARDING_IMG[item.datas.filter((f) => f.lang === state.language)[0].name.toUpperCase() + '-small'].retina} 2x`} alt='' />
                                                                                    }
                                                                                    <p class=''>
                                                                                        {(item.datas.filter((f) => f.lang === state.language)[0].name.toLowerCase() === 'fizix') ?
                                                                                            t('Le coaching par webcam', {ns: 'onboarding'})
                                                                                            :
                                                                                            t('L’accès  à une salle de sport', {ns: 'onboarding'})
                                                                                        }
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            {(item.datas.filter((f) => f.lang === state.language)[0].name.toLowerCase() === 'fizix') ?
                                                                                <a class='cerba-link' target='_blank' href={allRoutes['pdf.cerba.fizix'].pathname}>
                                                                                    <div class='btzInformations-btzImage'><img src={icoInfo} srcset={`${icoInfox2} 2x`} alt='' /></div>
                                                                                </a>
                                                                                :
                                                                                <a class='cerba-link' target='_blank' href='https://www.gymlib.com/'>
                                                                                    <div class='btzInformations-btzImage'><img src={icoInfo} srcset={`${icoInfox2} 2x`} alt='' /></div>
                                                                                </a>
                                                                            }
                                                                        </dummy>
                                                                    }
                                                                </div>
                                                            }
                                                        </dummy>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </Tile.Content>
                            </Tile>

                            <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap', 'padding-right': '50px' }}>
                                <Tile.Icon classes={''}>
                                    <Avatar.Alfred classes={'centered'} />
                                </Tile.Icon>
                                <Tile.Content>
                                    <Balloon kind={state.error !== '' ? 'error' : 'info'} title={(state.error !== '' ? '' : t('QUESTION', {ns: 'onboarding'}) + ' - <font>' + (state.idx + 1) + '/' + Object.keys(state.steps).length + '</font>')} style={{ 'white-space': 'normal' }}>
                                        <dummy innerHTML={<Convert separator={''} is_xhtml={true} type='nl2br'>{(state.error !== '' ? state.error : state.current.help)}</Convert>}></dummy>
                                    </Balloon>
                                </Tile.Content>
                            </Tile>

                            <Form onsubmit={() => null} style={{ 'min-height': 0 }}>
                                <Form.Group classes={'btzForm-btzFooter ' + ((state.current.skippableEval === true) ? 'btzForm-btzFooter-withSkippable' : '')}>
                                    {(state.current.skippableEval === true) &&
                                        <Button primary small onclick={() => { actions.nextCard(true) }}>
                                            {t('Passer', {ns: 'onboarding'})}
                                        </Button>
                                    }
                                    <Button primary active={state.activeValidation} loading={(state.formIsSubmitting)} onclick={() => { actions.nextCard(false) }} >
                                        {t('Enregistrer et continuer', {ns: 'onboarding'})}
                                    </Button>
                                </Form.Group>
                            </Form>
                        </div>
                    </OnboardingLayout>
                }
            </div>
        );
        return view;
    }
}
