import { h } from '@app/utils';
import './../index.scss';

import RegisterLayout from '@app/layouts/register/layout';

import StepDevice from './../step-device';
import StepCgu from './../step-cgu';
import StepPseudo from './../step-pseudo';
import StepObjectives from './../step-objectif';
import StepEnd from './../step-end';

module.exports = {
    initView: function() {
        let view = (state, actions) => ({ location, match, language }, children) => (
        //let view = (state, actions) => (props, children) => (
            <div key='register'>
                <RegisterLayout page='register' subpage={location.pathname} customer={state.customer} howthatwork={state.howthatwork}>

                    {/* STEP 1 */}
                    {location.pathname === '/home/register' && (
                        <StepCgu initial={state.user} send={actions.setState} customer={state.customer} />
                    )}

                    {/* STEP 1 */}
                    {location.pathname === '/home/register/cgu' && (
                        <StepCgu initial={state.user} send={actions.setState} customer={state.customer} />
                    )}

                    {/* STEP 2 */}
                    {location.pathname === '/home/register/pseudo' && (
                        <StepPseudo initial={state.user} send={actions.setState} customer={state.customer} />
                    )}

                    {/* STEP 3 */}
                    {location.pathname === '/home/register/objectives' && (
                        <StepObjectives initial={state.user} send={actions.setState} customer={state.customer} language={language} />
                    )}

                    {/* STEP 4 */}
                    {location.pathname === '/home/register/device' && (
                        <StepDevice initial={state.user} send={actions.setState} customer={state.customer} switchHowThatWork={actions.switchHowThatWork} />
                    )}

                    {/* STEP 5 */}
                    {location.pathname === '/home/register/end' && (
                        <StepEnd initial={state.user} send={actions.setState} customer={state.customer} />
                    )}

                </RegisterLayout>
            </div>
        );
        return view;
    }
}

export { StepDevice };
export { StepCgu };
export { StepPseudo };
export { StepObjectives };
export { StepEnd };
