import { h, Component } from '@app/utils';
import { Form } from '@app/elements';
import './index.scss';

// import ImgLess from './ico-less.png';
// import ImgLessx2 from './ico-less@2x.png';

const CustomInput = Component(
    {
        uniqName: 'uniq',
        onclick: null,
        oninput: null,
        value: '',
        maxValue: null,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.uniqName) {
                state.uniqName = props.uniqName;
            }
            if (props.onclick) {
                state.onclick = props.onclick;
            }
            if (props.oninput) {
                state.oninput = props.oninput;
            }
            if (props.maxValue) {
                state.maxValue = props.maxValue;
            }
            actions.setValue(props.uniqDefaultValue);
        },
        onComponentUpdate: (props) => (state, actions) => {
            actions.setValue(props.uniqDefaultValue);
        },
        setValue: newState => state => ({
            value: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <div class='btzCustomInput'>
            <div class=''>
                <Form.Group>
                    <div style={{ 'position': 'relative' }}>
                        <Form.Input
                            classes={'btzFullWidth ' + props.uniqClass}
                            type='text'
                            name={state.uniqName + '-custom'}
                            maxlength={props.uniqMaxLength}
                            value={state.value}
                            placeholder={'0'}
                            oninput={(el) => {
                                if (isNaN(el.target.value) || ((state.maxValue !== null) && (+el.target.value > state.maxValue))) {
                                    el.target.value = state.value;
                                }
                                state.value = el.target.value;
                                state.oninput(el);
                            }}
                            onclick={state.onclick}
                        />
                        <p style={{ 'position': 'absolute', 'bottom': '25px' }}>{props.uniqLabel}</p>
                    </div>
                </Form.Group>
            </div>
        </div>
    ),
    'custominput'
);

export { CustomInput };
