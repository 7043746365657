import { tryToLoginAndRedirect, getQueryVariable, isDefined } from '@app/core';
import { appCustoSetting } from '@app/settings/settings';
import * as staticsconstants from '@app/constants/constants-statics';

const { CUSTO_SSO } = appCustoSetting.appsettings;

export const actions = {
    onComponentCreate: (props) => (state, actions) => {
        tryToLoginAndRedirect();

        let openModal = getQueryVariable('openmodal', false);
        let orderReference = getQueryVariable('order_reference', false);
        if (isDefined(openModal) && ((openModal === 'true') || (openModal === true))) {
            actions.displayModal(true);
        } else if (isDefined(orderReference) && (orderReference !== '') && (orderReference !== false)) {
            actions.displayModal(true);
        }

        if (staticsconstants.CUSTOMERS[CUSTO_SSO.toLowerCase()] !== undefined) {
            let redirect = {};
            redirect.ios = staticsconstants.CUSTOMERS[CUSTO_SSO.toLowerCase()].appurl.ios;
            redirect.android = staticsconstants.CUSTOMERS[CUSTO_SSO.toLowerCase()].appurl.android;
            actions.setRedirect(redirect);
        }
    },
    displayModal: newState => state => ({
        displayModal: newState,
    }),
    setRedirect: newState => state => ({
        redirect: newState,
    }),
};
