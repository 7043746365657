const createUTCDate = function(date = null, withOffset = true) {
    var oDate = new Date();
    if (date !== null) {
        if (date.indexOf('.') > -1) {
            date = date.replace(' ', 'T') + 'Z';
        } else if (date.indexOf('/') > -1) {
            return date;
        }
        oDate = new Date(date);
    } else {
        oDate.setMilliseconds(0);
    }
    if (Object.prototype.toString.call(oDate) === '[object Date]') {
        let startday = oDate.getDate(), startmonth = oDate.getMonth(), startyear = oDate.getFullYear();
        let starthours = oDate.getUTCHours(), startminutes = oDate.getMinutes(), startseconds = oDate.getSeconds();
        if (+startday <  10) {
            startday = '0' + startday;
        }
        startmonth++;
        if (+startmonth <  10) {
            startmonth = '0' + +startmonth;
        }
        if (+startday <  10) {
            startday = '0' + +startday;
        }
        if (+starthours <  10) {
            starthours = '0' + +starthours;
        }
        if (+startminutes <  10) {
            startminutes = '0' + +startminutes;
        }
        if (+startseconds <  10) {
            startseconds = '0' + +startseconds;
        }
        oDate = startyear + '-' + startmonth + '-' + startday + 'T' + starthours + ':' + startminutes + ':' + startseconds + 'Z';
        oDate = new Date(oDate)
        var offset = (withOffset ? oDate.getTimezoneOffset() : 0);
        oDate.setTime(oDate.getTime() + (offset * 60 * -1000));
        return oDate;
    } else {
        return date;
    }
}

export { createUTCDate };