import { getDateObjFromYMD, addCSRFToken, dynLoad } from '@app/core';
import { allRoutes } from '@app/settings';
import { User, Session, Follow } from '@app/api';
import { t, h, Component, isLength } from '@app/utils';
import { debounce } from 'hyperapp-fx';
import * as staticsconstants from '@app/constants/constants-statics';
import './index.scss';

const state = {
    error: '',
    email: null,
    password: {
        isTouched: false,
        isValid: false,
        value: '',
    },
    userIsResiliated: false,
    messageActive: false,
    resiliationRunning: false,
    customer: null,
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        state.email = props.email;
        if (props.customer) {
            state.customer = props.customer;
        }

        let existingbool = localStorage.getItem('resiliationRunning');
        if ((existingbool !== null) && (existingbool !== undefined) && (existingbool !== '')) {
            actions.setResiliationRunning(existingbool == 'true' ? true : false);
        }
    },

    setState: ({ ns, key, value }) => state => ({
        [ns]: { ...state[ns], ...{ [key]: value } },
    }),

    onUpdate: ({ target: { name, value } }) => (state, actions) => {
        let isValid = actions.isInputValid({ name, value });
        // state[name].api.message = state[name].api.errors.valid;
        // state[name].api.kind = 'info';
        actions.setState({ns: name, key: 'isValid', value: isValid});
        actions.setState({ns: name, key: 'value', value: value});
    },

    waitForLastInput: ({ target }) => debounce(
        500,
        'onUpdate',
        { target },
    ),

    isInputValid: ({ name, value }) => (state, actions) => {
        if (name === undefined) {
            return false;
        }

        switch (name) {
            case 'password':
                return isLength(value, { min: 1});
            default:
                throw Error(`${name} has no validator assigned`);
        }
    },

    onBlur: ({ target: { name } }) => (state, actions) => {
        actions.setErrorState('');
        actions.setState({ns: name, key: 'isTouched', value: true});
    },

    onSubmit: () => (state, actions) => {
        let customer = staticsconstants.CUSTOMERS[state.customer];
        if (customer && (customer.resiliate !== undefined) && (customer.resiliate === 'no-pwd')) {
            User.resiliate().then(() => {
                actions.setUserIsResiliated(true);
                setTimeout(() => {
                    window.location.href = addCSRFToken(allRoutes['index'].pathname);
                }, 3000);
            });
        } else {
            if (state.password.isValid) {
                Session.createSession(state.email, state.password.value, state.customer).then((res) => {
                    if (res.data && res.data.code) {
                        actions.setApiErrors(res.data);
                    } else {
                        if (res.data === false) {
                            actions.setErrorState(t('Votre mot de passe semble invalide.', {ns: 'resiliate'}));
                        } else {
                            User.resiliate().then(() => {
                                actions.setUserIsResiliated(true);
                                setTimeout(() => {
                                    window.location.href = addCSRFToken(allRoutes['index'].pathname);
                                }, 3000);
                            });
                        }
                    }
                });
            } else {
                actions.setErrorState(t('Veuillez saisir votre mot de passe.', {ns: 'generals'}));
            }
        }
    },

    displaySuccessMessage: newState => state => ({
        messageActive: newState,
    }),

    setErrorState: newState => state => ({
        error: newState,
    }),

    setUserIsResiliated: newState => state => ({
        userIsResiliated: newState,
    }),

    setResiliationRunning: newState => state => ({
        resiliationRunning: newState,
    }),

    confirmUserCallResiliation: () => (state, actions) => {
        let todayObj = new Date().toISOString().split('T')[0].split('-').join('');
        let date = getDateObjFromYMD(todayObj);
        let type = 'UNSUBSCRIPTION';
        actions.setUserIsResiliated(false);

        actions.setResiliationRunning(true);
        localStorage.setItem('resiliationRunning', true);
        Follow.setAppointment(type, date).then((res) => {
            if (res.data.response === 'Ok') {
                actions.displaySuccessMessage(true);
            } else {
                actions.setResiliationRunning(false);
                localStorage.setItem('resiliationRunning', false);
            }
        })
    },
};

const view = dynLoad('view', 'Account/parameter/resiliate/views');

export default Component(state, actions, view, 'resiliate');
