import { h } from '@app/utils';

export const actions = {
    onComponentCreate: (props) => (state, actions) => {

    },
    getDate: ({el, dat}) => {
        var date = new Date(Date.parse(new Date(dat)));
        var options = { weekday: 'long', month: 'long', day: 'numeric' };
        el.innerHTML = (date.toLocaleDateString('fr-FR', options));
    },
    getTime: ({el, dat}) => {
        var date = new Date(Date.parse(new Date(dat)));
        var options = { hour: '2-digit', minute:'2-digit' };
        el.innerHTML = (date.toLocaleTimeString('fr-FR', options));
    },
};
