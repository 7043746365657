import { t, h, Component } from '@app/utils';
import { Cardline, Share } from '@app/api';
import { isDefined } from '@app/core';
import { Modal } from '@app/elements';
import * as staticsconstants from '@app/constants/constants-statics';
import './index.scss';

import ImgShowMore from './social/soc-more.png';
import ImgShowMorex2 from './social/soc-more@2x.png';

import ImgFb from './social/soc-facebook.png';
import ImgFbx2 from './social/soc-facebook@2x.png';

import ImgTw from './social/soc-twitter.png';
import ImgTwx2 from './social/soc-twitter@2x.png';

import ImgMail from './social/soc-mail.png';
import ImgMailx2 from './social/soc-mail@2x.png';

import ImgFav from './social/soc-favorite.png';
import ImgFavx2 from './social/soc-favorite@2x.png';

import ImgFavOn from './social/soc-favorite-on.png';
import ImgFavOnx2 from './social/soc-favorite-on@2x.png';

const Social = Component(
    {
        showMore: false,
        favorite: false,
        baseUrl: '',
        idtimeline: null,
        idcard: null,
        optfavid: null,
        idfavorite: null,
        title: '',
        kindcard: '',
        rendering: false,
        displayedon: '',
        readableCustomer: staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()].readablecustomer,
        submitting: false,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            state.idtimeline = (props.idtimeline || null);
            state.idfavorite = (props.idfavorite || null);
            state.favorite = (props.favorite || false);
            state.kindcard = (props.kindcard || '');
            state.title = (props.title || '');
            state.optfavid = (props.optfavid || null);

            state.readableCustomer = state.readableCustomer.charAt(0).toUpperCase() + state.readableCustomer.slice(1);
            if (props.id) {
                actions.setCardId(props.id);
            }
        },
        onComponentUpdate: (props) => (state) => {
            if (props.idfavorite) {
                state.idfavorite = props.idfavorite;
            }
            state.favorite = (props.favorite || false);
        },
        showMore: (event) => (state, actions) => {
            event.preventDefault();
            event.stopPropagation();

            actions.setShowMore();
        },
        setShowMore: () => state => ({ showMore: true }),
        shareFb: (props) => (state, actions) => {
            Share.shareThisCard(state.kindcard, state.idcard, 'facebook').then((res) => {
                actions.openPopup({kind : 'facebook', title : state.title, hashtag : null, res : res});
            });
        },
        shareTwitter: (props) => (state, actions) => {
            Share.shareThisCard(state.kindcard, state.idcard, 'twitter').then((res) => {
                actions.openPopup({kind : 'twitter', title : state.title, hashtag : state.readableCustomer, res : res});
            });
        },
        shareMail: (props) => (state, actions) => {
            Share.shareThisCard(state.kindcard, state.idcard, 'email').then((res) => {
                let parsedTitle = actions.getParsedTitle(state.kindcard);
                let parsedUrl = actions.getParsedUrl({url : res, title : state.title});
                actions.openPopup({kind : 'email', title : parsedTitle, hashtag : null, res : parsedUrl});
            });
        },
        getParsedTitle: ({kindcard}) => {
            var title = t('Cela peut t‘intéresser', {ns: 'generals'}) + '...';
            if (kindcard === 'advice') {
                title = t('Un conseil qui peut t‘interesser', {ns: 'generals'}) + '.';
            } else if (kindcard === 'recipe') {
                title = t('Une recette qui peut t‘interesser', {ns: 'generals'}) + '.';
            } else if (kindcard === 'article') {
                title = t('Un article qui peut t‘interesser', {ns: 'generals'}) + '.';
            } else if (kindcard === 'citation') {
                title = t('Une citation qui peut t‘interesser', {ns: 'generals'}) + '.';
            }
            return title;
        },
        getParsedUrl: ({url, title}) => (state, actions) => {
            if (!isDefined(url.data)) {
                url.data = [];
            }
            if (url.data === false) {
                url.data = [];
                url.data.url = (title + ' ' + t('Je l‘ai lu sur', {ns: 'generals'}) + ' ' + state.readableCustomer);
            } else {
                url.data.url = (title + ' ' + t('Je l‘ai lu sur', {ns: 'generals'}) + ' ' + state.readableCustomer + ' : ' + (isDefined(url.data) ? url.data.url : ''));
            }
            return url;
        },
        favorite: (props) => (state, actions) => {
            if (state.submitting) {
                return false;
            }
            actions.setSubmitting(true);
            if (props.displayedon !== 'favorite') {
                actions.setFavorited();
            }

            if (!state.favorite) {
                if (!isDefined(state.idtimeline) || !isDefined(state.idcard)) {
                    return false;
                }
                //
                Cardline.favoriteThisCard(state.idtimeline, (isDefined(state.optfavid) ? state.optfavid : state.idcard)).then((res) => {
                    actions.setFavoriteId(res.data.id);
                    actions.propagateFavoriteState(props);
                    actions.setSubmitting(false);
                });
            } else {
                actions.favoriteDeletionPropagated();
                actions.propagateFavoriteState(props);
            }
        },
        setFavoriteId: newState => state => ({
            idfavorite: newState
        }),
        setCardId: newState => state => ({
            idcard: newState
        }),
        setFavorited: () => state => ({
            favorite: !state.favorite
        }),
        propagateFavoriteState: (props) => (state, actions) => {
            props.propagate(state.idfavorite);
        },
        favoriteDeletionPropagated: (props) => (state, actions) => {
            if (!isDefined(state.idfavorite)) {
                return false;
            }
            Cardline.unfavoriteThisCard(state.idfavorite).then(() => {
                actions.setSubmitting(false);
            });
        },
        openPopup: ({kind, title, hashtag, res}) => (state, actions) => {
            var url = res.data.url;
            if (kind === 'facebook') {
                window.open('https://www.facebook.com/sharer.php?u=' + encodeURIComponent(url), 'PartageSocial', 'width=500,height=500,toolbar=no,scrollbars=yes,location=0,statusbar=no,menubar=no,resizable=yes,top:50,left:50');
            } else if (kind === 'twitter') {
                window.open('https://twitter.com/intent/tweet?url=' + encodeURIComponent(url) + '&text=' + encodeURIComponent(title) + '&hashtags=' + encodeURIComponent(hashtag));
            } else if (kind === 'email') {
                window.location.href = ('mailto:?subject=' + encodeURIComponent(title) + '&body=' + encodeURIComponent(url));
            }
        },
        rendering: () => state => ({
            rendering: !state.rendering,
        }),
        setSubmitting: newState => state => ({
            submitting: newState,
        })
    },
    (state, actions) => (props, children) => (
        <div class='btzSocial' id={props.id} idtimeline={props.idtimeline} idfavorite={props.idfavorite} data-id={props.did} data-tag={props.tag} data-value={state.count} data-kind={state.kind}>
            {props.displayedon == 'timeline' &&
                <button class='btzSocial-btzEntry' onclick={() => {
                    actions.favorite(props)
                }} data-favorite={state.favorite}>
                    <img src={ImgFav} srcset={`${ImgFavx2} 2x`} alt='' class='btzSocial-btzEntry-btzFavOff' />
                    <img src={ImgFavOn} srcset={`${ImgFavOnx2} 2x`} alt='' class='btzSocial-btzEntry-btzFavOn' />
                </button>
            }

            {props.displayedon == 'favorite' &&
                <div style={{ 'display': 'inline-block', 'vertical-align': 'top' }}>
                    <Modal content={''} confirm={true} confirmConfirm={() => {
                        actions.favorite(props);
                    }} cancelAction={(isDefined(props.cancelAction) ? props.cancelAction : null)} title={t('Suppression du favori', {ns: 'favorites'})} description={t('Confirmez-vous la suppression de ce favori ?', {ns: 'favorites'})}>
                        <button class='btzSocial-btzEntry' data-favorite={state.favorite}>
                            <img src={ImgFav} srcset={`${ImgFavx2} 2x`} alt='' class='btzSocial-btzEntry-btzFavOff' />
                            <img src={ImgFavOn} srcset={`${ImgFavOnx2} 2x`} alt='' class='btzSocial-btzEntry-btzFavOn' />
                        </button>
                    </Modal>
                </div>
            }

            <div class='btzSocial-btzSocials-Wrapper'>
                <button class='btzSocial-btzEntry btzSocial-btzEntry-displayMode' onclick={actions.showMore} data-displayed={state.showMore}>
                    <img src={ImgShowMore} srcset={`${ImgShowMorex2} 2x`} alt='' class='' style={{ 'margin': '-4px 0 0 2px' }} />
                </button>
                <div class='btzSocial-btzSocials-Wrapper-btzSocialDisplayed' data-displayed={state.showMore}>
                    <button class='btzSocial-btzEntry' onclick={actions.shareFb}>
                        <img src={ImgFb} srcset={`${ImgFbx2} 2x`} alt='' class='' />
                    </button>
                    <button class='btzSocial-btzEntry' onclick={actions.shareTwitter}>
                        <img src={ImgTw} srcset={`${ImgTwx2} 2x`} alt='' class='' />
                    </button>
                    <button class='btzSocial-btzEntry' onclick={actions.shareMail}>
                        <img src={ImgMail} srcset={`${ImgMailx2} 2x`} alt='' class='' />
                    </button>
                </div>
            </div>
        </div>
    ),
    'social'
);

export { Social };
