import { t } from '@app/utils';

const TRADKEYS_MENUNAMESI18N = [
    {'key' : t('Vrai', {ns: 'generals'})},
    {'key' : t('Faux', {ns: 'generals'})},
    {'key' : t('Journal', {ns: 'timeline'})},
    {'key' : t('Mon journal', {ns: 'timeline'})},
    {'key' : t('Alimentation', {ns: 'food'})},
    {'key' : t('Mon alimentation', {ns: 'food'})},
    {'key' : t('Votre alimentation', {ns: 'food'})},
    {'key' : t('Données', {ns: 'datas'})},
    {'key' : t('Mes données', {ns: 'datas'})},
    {'key' : t('Vos données', {ns: 'datas'})},
    {'key' : t('Programmes', {ns: 'programs'})},
    {'key' : t('Mes programmes', {ns: 'programs'})},
    {'key' : t('Vos programmes de coaching', {ns: 'programs'})},
    {'key' : t('Mes programmes de coaching', {ns: 'programs'})},
    {'key' : t('Compte', {ns: 'informations'})},
    {'key' : t('Votre compte', {ns: 'informations'})},
    {'key' : t('Mon compte', {ns: 'informations'})},
    {'key' : t('Paramètres', {ns: 'informations'})},
    {'key' : t('Vos informations', {ns: 'informations'})},
    {'key' : t('Mes informations', {ns: 'informations'})},
    {'key' : t('Favoris', {ns: 'favorites'})},
    {'key' : t('Vos favoris', {ns: 'favorites'})},
    {'key' : t('Mes favoris', {ns: 'favorites'})},
    {'key' : t('Badges', {ns: 'rewards'})},
    {'key' : t('Tous les badges', {ns: 'rewards'})},
    {'key' : t('Objets connectés', {ns: 'oc'})},
    {'key' : t('Vos objets connectés', {ns: 'oc'})},
    {'key' : t('Mes objets connectés', {ns: 'oc'})},
    {'key' : t('Objectifs', {ns: 'objectives'})},
    {'key' : t('Objectif en cours', {ns: 'objectives'})},
    {'key' : t('Mes paramètres', {ns: 'informations'})},
    {'key' : t('Mes badges', {ns: 'rewards'})},
    {'key' : t('Mes objectifs', {ns: 'objectives'})},
    {'key' : t('Vos programmes en cours', {ns: 'programs'})},
    {'key' : t('Mes programmes en cours', {ns: 'programs'})},
    {'key' : t('Les programmes recommandés pour vos objectifs', {ns: 'programs'})},
    {'key' : t('Les programmes recommandés pour mes objectifs', {ns: 'programs'})},
    {'key' : t('Vous ne pouvez pas vous connecter car votre compte est rattaché à une mutuelle non prise en charge par Betterise.', {ns: 'login'})},
    {'key' : t('Vous ne pouvez pas vous connecter car votre compte est rattaché à une mutuelle non prise en charge par Partena.', {ns: 'login'})},
    {'key' : t('Vous ne pouvez pas vous connecter car votre compte est rattaché à une mutuelle non prise en charge par Gjensidige.', {ns: 'login'})},
    {'key' : t('Vous ne pouvez pas vous connecter car votre compte est rattaché à une mutuelle non prise en charge par Eliott.', {ns: 'login'})},
    {'key' : t('Vous ne pouvez pas vous connecter car votre compte est rattaché à une mutuelle non prise en charge par Feelgood.', {ns: 'login'})},
    {'key' : t('Vous ne pouvez pas vous connecter car votre compte est rattaché à une mutuelle non prise en charge par B.E.', {ns: 'login'})},
    {'key' : t('Vous ne pouvez pas vous connecter car votre compte est rattaché à une mutuelle non prise en charge par Moi, Bientôt Maman.', {ns: 'login'})},
    {'key' : t('Vous ne pouvez pas vous connecter car votre compte est rattaché à une mutuelle non prise en charge par CoachCov.', {ns: 'login'})},
    {'key' : t('Vous ne pouvez pas vous connecter car votre compte est rattaché à une mutuelle non prise en charge par Theraflow.', {ns: 'login'})},
    {'key' : t('Vous ne pouvez pas vous connecter car votre compte est rattaché à une mutuelle non prise en charge par Theraflow.', {ns: 'login'})},
    {'key' : t('Vous ne pouvez pas vous connecter car votre compte est rattaché à une mutuelle non prise en charge par Klesia Netflix.', {ns: 'login'})},
    {'key' : t('Vous ne pouvez pas vous connecter car votre compte est rattaché à une mutuelle non prise en charge par Oncolaxy.', {ns: 'login'})},
];

const TRADKEYS_LANGUAGESI18N = {'fr_FR' : t('Français', {ns: 'generals'}), 'en_GB' : t('Anglais', {ns: 'generals'}), 'pt_PT' : t('Portugais', {ns: 'generals'})};
/*
IMPORTANT
    iosbanner && androidbanner must be updated in /public/appbanner/appb-[customer].js
 */
export const FOLLOW_TYPES = [
    {'key': 'question', 'value': 'QUESTION', 'keyI18n': t('question', {ns: 'follow'})},
    {'key': 'explication bio', 'value': 'BIO_CHECKUP_EXPLANATIONS', 'keyI18n': t('explication bio', {ns: 'follow'})},
    {'key': 'désabonnement', 'value': 'UNSUBSCRIPTION', 'keyI18n': t('désabonnement', {ns: 'follow'})},
    {'key': 'problème technique', 'value': 'TECHNICAL_PROBLEM', 'keyI18n': t('problème technique', {ns: 'follow'})},
    {'key': 'autres', 'value': 'OTHERS', 'keyI18n': t('autres', {ns: 'follow'})},
];

export const GAMIF_THEMES = [
    {'key': 'ACTIVITE', 'readable': 'Activité physique'},
    {'key': 'ALCOOL', 'readable': 'Alcool'},
    {'key': 'ANTICANCER', 'readable': 'Prévention Cancer'},
    {'key': 'AU TRAVAIL', 'readable': 'Au travail'},
    {'key': 'BONHEUR', 'readable': 'Psychologie du Bonheur'},
    {'key': 'COEUR EN FORME', 'readable': 'Coeur en forme'},
    {'key': 'DENIVELE', 'readable': 'Dénivelé'},
    //{'key': 'DISTANCE', 'readable': 'Distance'},
    {'key': 'FATIGUE', 'readable': 'Fatigue'},
    {'key': 'MAL DE DOS', 'readable': 'Mal de dos'},
    {'key': 'MORAL', 'readable': 'Moral'},
    {'key': 'MEMOIRE', 'readable': 'Mémoire'},
    {'key': 'NUTRITION', 'readable': 'Nutrition'},
    //{'key': 'SERVICE', 'readable': 'Service'},
    {'key': 'SOMMEIL', 'readable': 'Sommeil'},
    {'key': 'STRESS', 'readable': 'Stress'},
    {'key': 'TABAC', 'readable': 'Tabac'},
    {'key': 'USAGE', 'readable': 'Usage'},
    //{'key': 'LEVEL', 'readable': 'Niveau'}
];

export const DASH_SPORTS = [
    {'key': 'natation', 'readable': 'natation', 'i18nvalues': t('Natation', {ns: 'datas'}), 'color': '#F8E71C', 'kpi': 'ACTIVITY_SWIMMINGSTATS', 'inlegend': true},
    {'key': 'vélo', 'readable': 'vélo', 'i18nvalues': t('Vélo', {ns: 'datas'}), 'color': '#FF8000', 'kpi': 'ACTIVITY_BIKINGSTATS', 'inlegend': true},
    {'key': 'running', 'readable': 'running', 'i18nvalues': t('Running', {ns: 'datas'}), 'color': '#8B572A', 'kpi': 'ACTIVITY_RUNNINGSTATS', 'inlegend': true},
    {'key': 'fitness', 'readable': 'fitness', 'i18nvalues': t('Fitness', {ns: 'datas'}), 'color': '#2E2E2E', 'kpi': 'ACTIVITY_WORKOUTSTATS', 'inlegend': true},
    {'key': 'danse', 'readable': 'danse', 'i18nvalues': t('Danse', {ns: 'datas'}), 'color': '#FF0047', 'kpi': 'ACTIVITY_DANSESTATS', 'inlegend': true},
    {'key': 'rugby', 'readable': 'rugby', 'i18nvalues': t('Rugby', {ns: 'datas'}), 'color': '#5C00FF', 'kpi': 'ACTIVITY_RUGBYSTATS', 'inlegend': true},
    {'key': 'golf', 'readable': 'golf', 'i18nvalues': t('Golf', {ns: 'datas'}), 'color': '#7ED322', 'kpi': 'ACTIVITY_GOLFSTATS', 'inlegend': true},
    {'key': 'football', 'readable': 'football', 'i18nvalues': t('Football', {ns: 'datas'}), 'color': '#8EA369', 'kpi': 'ACTIVITY_FOOTBALLSTATS', 'inlegend': true},
    {'key': 'other', 'readable': 'autre', 'i18nvalues': t('Autre', {ns: 'datas'}), 'color': '#7EA322', 'kpi': 'OTHER', 'inlegend': false},
];

export const DASH_SLEEP = [
    {'key': 'très bien dormi', 'readable': 'très bien dormi', 'color': '#38CB81', 'quality': 10, 'inlegend': true},
    {'key': 'bien dormi', 'readable': 'bien dormi', 'color': '#F8E71C', 'quality': 7, 'inlegend': true},
    {'key': 'mal dormi', 'readable': 'mal dormi', 'color': '#F5A623', 'quality': 4, 'inlegend': true},
    {'key': 'très mal dormi', 'readable': 'très mal dormi', 'color': '#F53D3D', 'quality': 1, 'inlegend': true}
];

export const THEMES = {
    '0' : {'value' : '', 'label' : '', 'key' : 0, 'color' : ''},
    '1' : {'value' : 'food', 'label' : 'Nutrition', 'i18nLabel' : t('Nutrition', {ns: 'datas'}), 'key' : 1, 'color' : 'FF6F41', 'order': 12},
    '2' : {'value' : 'activity', 'label' : 'Activité physique', 'i18nLabel' : t('Activité physique', {ns: 'datas'}), 'key' : 2, 'color' : '049588', 'order': 1},
    '3' : {'value' : 'sleep', 'label' : 'Sommeil', 'i18nLabel' : t('Sommeil', {ns: 'datas'}), 'key' : 3, 'color' : '3E51B5', 'order': 16},
    '4' : {'value' : 'stress', 'label' : 'Stress', 'i18nLabel' : t('Stress', {ns: 'datas'}), 'key' : 4, 'color' : 'E81E63', 'order': 18},
    '5' : {'value' : 'shape', 'label' : 'Moral', 'i18nLabel' : t('Moral', {ns: 'datas'}), 'key' : 5, 'color' : 'FEC009', 'order': 11},
    '6' : {'value' : 'tobacco', 'label' : 'Tabac', 'i18nLabel' : t('Tabac', {ns: 'datas'}), 'key' : 6, 'color' : 'FF9A00', 'order': 20},
    '7' : {'value' : 'diabet', 'label' : 'Diabete', 'i18nLabel' : t('Diabete', {ns: 'datas'}), 'key' : 7, 'color' : '', 'order': 6},
    '8' : {'value' : 'alcool', 'label' : 'Alcool', 'i18nLabel' : t('Alcool', {ns: 'datas'}), 'key' : 8, 'color' : 'A62671', 'order': 2},
    '9' : {'value' : 'memory', 'label' : 'Mémoire', 'i18nLabel' : t('Mémoire', {ns: 'datas'}), 'key' : 9, 'color' : '00BABA', 'order': 10},
    '10' : {'value' : 'anticancer', 'label' : 'Prévention Cancer', 'i18nLabel' : t('Prévention Cancer', {ns: 'datas'}), 'key' : 10, 'color' : '', 'order': 13},
    '11' : {'value' : 'backache', 'label' : 'Mal de dos', 'i18nLabel' : t('Mal de dos', {ns: 'datas'}), 'key' : 11, 'color' : 'C83B4C', 'order': 9},
    '12' : {'value' : 'working-position', 'label' : 'Au travail', 'i18nLabel' : t('Au travail', {ns: 'datas'}), 'key' : 12, 'color' : '4EC9DF', 'order': 3},
    '13' : {'value' : 'shaped-heart', 'label' : 'Coeur', 'i18nLabel' : t('Coeur', {ns: 'datas'}), 'key' : 13, 'color' : '', 'order': 4},
    '14' : {'value' : 'happiness-psychology', 'label' : 'Psychologie du Bonheur', 'i18nLabel' : t('Psychologie du Bonheur', {ns: 'datas'}), 'key' : 14, 'color' : '', 'order': 15},
    '15' : {'value' : 'profil', 'label' : 'Profil', 'i18nLabel' : t('Profil', {ns: 'datas'}), 'key' : 15, 'color' : '', 'order': 14},
    '16' : {'value' : 'followed-weight', 'label' : 'Suivis de poids', 'i18nLabel' : t('Suivis de poids', {ns: 'datas'}), 'key' : 16, 'color' : '5F7D8B', 'order': 19},
    '17' : {'value' : 'happy-pregnancy', 'label' : 'Grossesse', 'i18nLabel' : t('Grossesse', {ns: 'datas'}), 'key' : 17, 'color' : '7481CF', 'order': 8},
    '18' : {'value' : 'tiredness', 'label' : 'Fatigue', 'i18nLabel' : t('Fatigue', {ns: 'datas'}), 'key' : 18, 'color' : '3576A1', 'order': 7},
    '19' : {'value' : 'sport', 'label' : 'Sport', 'i18nLabel' : t('Sport', {ns: 'datas'}), 'key' : 19, 'color' : '4CAF50', 'order': 17},
    '20' : {'value' : 'denivele', 'label' : 'Dénivelé', 'i18nLabel' : t('Dénivelé', {ns: 'datas'}), 'key' : 20, 'color' : '049588', 'order': 5},
    '21' : {'value' : 'waistsize', 'label' : 'Périm abdo', 'i18nLabel' : t('Périm abdo', {ns: 'datas'}), 'key' : 30, 'color' : '76D3CA', 'order': 20},
};

export const ARRTHEMES = [
    {'value' : 'all', 'label' : 'Tous', 'key' : 0, 'color' : '', 'tags' : ''},
    {'value' : 'food', 'label' : 'Nutrition', 'key' : 1, 'color' : 'FF6F41', 'tags' : ['NUTRITION']},
    {'value' : 'activity', 'label' : 'Activité physique', 'key' : 2, 'color' : '049588', 'tags' : ['ACTIVITY_WALK']},
    {'value' : 'sleep', 'label' : 'Sommeil', 'key' : 3, 'color' : '3E51B5', 'tags' : ['SLEEP_DURATION', 'SLEEP_QUALITY']},
    {'value' : 'stress', 'label' : 'Stress', 'key' : 4, 'color' : 'E81E63', 'tags' : ['STRESS_LEVEL']},
    {'value' : 'shape', 'label' : 'Moral', 'key' : 5, 'color' : 'FEC009', 'tags' : ['MORAL_LEVEL']},
    {'value' : 'tobacco', 'label' : 'Tabac', 'key' : 6, 'color' : 'FF9A00', 'tags' : ['SMOKE_QUANTITY']},
    {'value' : 'diabet', 'label' : 'Diabete', 'key' : 7, 'color' : '', 'tags' : ''},
    {'value' : 'alcool', 'label' : 'Alcool', 'key' : 8, 'color' : 'A62671', 'tags' : ['DRINK_QUANTITY']},
    {'value' : 'memory', 'label' : 'Mémoire', 'key' : 9, 'color' : '00BABA', 'tags' : ''},
    {'value' : 'anticancer', 'label' : 'Prévention Cancer', 'key' : 10, 'color' : '', 'tags' : ''},
    {'value' : 'backache', 'label' : 'Mal de dos', 'key' : 11, 'color' : 'C83B4C', 'tags' : ''},
    {'value' : 'working-position', 'label' : 'Au travail', 'key' : 12, 'color' : '4EC9DF', 'tags' : ''},
    {'value' : 'shaped-heart', 'label' : 'Coeur', 'key' : 13, 'color' : '', 'tags' : ''},
    {'value' : 'happiness-psychology', 'label' : 'Psychologie du Bonheur', 'key' : 14, 'color' : '', 'tags' : ''},
    {'value' : 'profil', 'label' : 'Profil', 'key' : 15, 'color' : '', 'tags' : ''},
    {'value' : 'followed-weight', 'label' : 'Suivis de poids', 'key' : 16, 'color' : '5F7D8B', 'tags' : ['PERSONAL_WEIGHT', 'PERSONAL_IMC']},
    {'value' : 'happy-pregnancy', 'label' : 'Grossesse', 'key' : 17, 'color' : '7481CF', 'tags' : ''},
    {'value' : 'tiredness', 'label' : 'Fatigue', 'key' : 18, 'color' : '3576A1', 'tags' : ['TIREDNESS_LEVEL']},
    {'value' : 'sport', 'label' : 'Sport', 'key' : 19, 'color' : '4CAF50', 'tags' : ['SPORT']},
    {'value' : 'denivele', 'label' : 'Dénivelé', 'key' : 20, 'color' : '049588', 'tags' : ['ACTIVITY_FLOORS']},
    {'value' : 'waistsize', 'label' : 'Périm abdo', 'key' : 30, 'color' : '76D3CA', 'tags' : ['PERSONAL_WAISTSIZE']},
];

export const LUNCH = [
    {'value' : '', 'label' : 'Petit-déjeuner', 'labelI18n' : t('Petit-déjeuner', {ns: 'food'}), 'key' : 1},
    {'value' : '', 'label' : 'Déjeuner', 'labelI18n' : t('Déjeuner', {ns: 'food'}), 'key' : 2},
    {'value' : '', 'label' : 'Diner', 'labelI18n' : t('Diner', {ns: 'food'}), 'key' : 5},
    {'value' : '', 'label' : 'En cas, apéritif', 'labelI18n' : t('En cas, apéritif', {ns: 'food'}), 'key' : 3},
    {'value' : '', 'label' : 'Boissons (hors repas)', 'labelI18n' : t('Boissons (hors repas)', {ns: 'food'}), 'key' : 4},
];

export const LUNCH_CONTEXT_FEEL = [
    {'value' : '', 'label' : {'gender-0' : 'J‘avais faim', 'gender-1' : 'J‘avais faim'}, 'labelI18n' : {'gender-0' : t('J‘avais faim', {ns: 'food'}), 'gender-1' : t('J‘avais faim', {ns: 'food'})}, 'key' : 'BEFORE_HUNGRY'},
    {'value' : '', 'label' : {'gender-0' : 'Je n‘avais pas faim', 'gender-1' : 'Je n‘avais pas faim'}, 'labelI18n' : {'gender-0' : t('Je n‘avais pas faim', {ns: 'food'}), 'gender-1' : t('Je n‘avais pas faim', {ns: 'food'})}, 'key' : 'BEFORE_NOTHUNGRY'},
    {'value' : '', 'label' : {'gender-0' : 'Impatiente', 'gender-1' : 'Impatient'}, 'labelI18n' : {'gender-0' : t('Impatiente', {ns: 'food'}), 'gender-1' : t('Impatient', {ns: 'food'})}, 'key' : 'BEFORE_IMPATIENT'},
    {'value' : '', 'label' : {'gender-0' : 'Fatiguée', 'gender-1' : 'Fatigué'}, 'labelI18n' : {'gender-0' : t('Fatiguée', {ns: 'food'}), 'gender-1' : t('Fatigué', {ns: 'food'})}, 'key' : 'BEFORE_TIRED'},
    {'value' : '', 'label' : {'gender-0' : 'Stressée', 'gender-1' : 'Stressé'}, 'labelI18n' : {'gender-0' : t('Stressée', {ns: 'food'}), 'gender-1' : t('Stressé', {ns: 'food'})}, 'key' : 'BEFORE_STRESSED'},
    {'value' : '', 'label' : {'gender-0' : 'Heureuse', 'gender-1' : 'Heureux'}, 'labelI18n' : {'gender-0' : t('Heureuse', {ns: 'food'}), 'gender-1' : t('Heureux', {ns: 'food'})}, 'key' : 'BEFORE_HAPPY'}
];

export const LUNCH_CONTEXT_EAT = [
    {'value' : '', 'label' : {'gender-0' : 'Assise', 'gender-1' : 'Assis'}, 'labelI18n' : {'gender-0' : t('Assise', {ns: 'food'}), 'gender-1' : t('Assis', {ns: 'food'})}, 'key' : 'DURING_SEATED'},
    {'value' : '', 'label' : {'gender-0' : 'Debout', 'gender-1' : 'Debout'}, 'labelI18n' : {'gender-0' : t('Debout', {ns: 'food'}), 'gender-1' : t('Debout', {ns: 'food'})}, 'key' : 'DURING_STANDING'},
    {'value' : '', 'label' : {'gender-0' : 'Devant un écran', 'gender-1' : 'Devant un écran'}, 'labelI18n' : {'gender-0' : t('Devant un écran', {ns: 'food'}), 'gender-1' : t('Devant un écran', {ns: 'food'})}, 'key' : 'DURING_TV'},
    {'value' : '', 'label' : {'gender-0' : 'Seule', 'gender-1' : 'Seul'}, 'labelI18n' : {'gender-0' : t('Seule', {ns: 'food'}), 'gender-1' : t('Seul', {ns: 'food'})}, 'key' : 'DURING_ALONE'},
    {'value' : '', 'label' : {'gender-0' : 'En groupe', 'gender-1' : 'En groupe'}, 'labelI18n' : {'gender-0' : t('En groupe', {ns: 'food'}), 'gender-1' : t('En groupe', {ns: 'food'})}, 'key' : 'DURING_GROUP'},
    {'value' : '', 'label' : {'gender-0' : 'En famille', 'gender-1' : 'En famille'}, 'labelI18n' : {'gender-0' : t('En famille', {ns: 'food'}), 'gender-1' : t('En famille', {ns: 'food'})}, 'key' : 'DURING_FAMILY'},
    {'value' : '', 'label' : {'gender-0' : 'Fatiguée', 'gender-1' : 'Fatigué'}, 'labelI18n' : {'gender-0' : t('Fatiguée', {ns: 'food'}), 'gender-1' : t('Fatigué', {ns: 'food'})}, 'key' : 'DURING_TIRED'},
    {'value' : '', 'label' : {'gender-0' : 'Stressée', 'gender-1' : 'Stressé'}, 'labelI18n' : {'gender-0' : t('Stressée', {ns: 'food'}), 'gender-1' : t('Stressé', {ns: 'food'})}, 'key' : 'DURING_STRESSED'},
    {'value' : '', 'label' : {'gender-0' : 'Détendue', 'gender-1' : 'Détendu'}, 'labelI18n' : {'gender-0' : t('Détendue', {ns: 'food'}), 'gender-1' : t('Détendu', {ns: 'food'})}, 'key' : 'DURING_RELAXED'},
    {'value' : '', 'label' : {'gender-0' : 'Vite', 'gender-1' : 'Vite'}, 'labelI18n' : {'gender-0' : t('Vite', {ns: 'food'}), 'gender-1' : t('Vite', {ns: 'food'})}, 'key' : 'DURING_QUICK'},
    {'value' : '', 'label' : {'gender-0' : 'J‘ai pris mon temps', 'gender-1' : 'J‘ai pris mon temps'}, 'labelI18n' : {'gender-0' : t('J‘ai pris mon temps', {ns: 'food'}), 'gender-1' : t('J‘ai pris mon temps', {ns: 'food'})}, 'key' : 'DURING_SLOW'},
    {'value' : '', 'label' : {'gender-0' : 'Trop mangé', 'gender-1' : 'Trop mangé'}, 'labelI18n' : {'gender-0' : t('Trop mangé', {ns: 'food'}), 'gender-1' : t('Trop mangé', {ns: 'food'})}, 'key' : 'DURING_TOOMUCH'},
    {'value' : '', 'label' : {'gender-0' : 'À l‘heure habituelle', 'gender-1' : 'À l‘heure habituelle'}, 'labelI18n' : {'gender-0' : t('À l‘heure habituelle', {ns: 'food'}), 'gender-1' : t('À l‘heure habituelle', {ns: 'food'})}, 'key' : 'DURING_ONTIME'},
];

export const LUNCH_CONTEXT_KIND = [
    {'value' : '', 'label' : {'gender-0' : 'Fait maison', 'gender-1' : 'Fait maison'}, 'labelI18n' : {'gender-0' : t('Fait maison', {ns: 'food'}), 'gender-1' : t('Fait maison', {ns: 'food'})}, 'key' : 'TYPE_HOMEMADE'},
    {'value' : '', 'label' : {'gender-0' : 'Industriel', 'gender-1' : 'Industriel'}, 'labelI18n' : {'gender-0' : t('Industriel', {ns: 'food'}), 'gender-1' : t('Industriel', {ns: 'food'})}, 'key' : 'TYPE_INDUSTRIAL'},
    {'value' : '', 'label' : {'gender-0' : 'Restaurant', 'gender-1' : 'Restaurant'}, 'labelI18n' : {'gender-0' : t('Restaurant', {ns: 'food'}), 'gender-1' : t('Restaurant', {ns: 'food'})}, 'key' : 'TYPE_RESTAURANT'},
    {'value' : '', 'label' : {'gender-0' : 'Restauration collective', 'gender-1' : 'Restauration collective'}, 'labelI18n' : {'gender-0' : t('Restauration collective', {ns: 'food'}), 'gender-1' : t('Restauration collective', {ns: 'datas'})}, 'key' : 'TYPE_CATERING'},
];

export const EVAL_TYPES_MEDICAL = [
    {'key' : 'Slider', 'value' : 'Slider', 'type': 1},
    {'key' : 'Button', 'value' : 'Button', 'type': 2},
    {'key' : 'QCM', 'value' : 'QCM', 'type': 3},
    {'key' : 'Textarea', 'value' : 'Textarea', 'type': 4},
    {'key' : 'CryptedWeight', 'value' : 'Poids', 'type': 5, 'tag': ['CRYPTEDWEIGHT_VALUE'], 'label': ['Poids'], 'i18nLabel': [t('Poids', {ns: 'datas'})]},
    {'key' : 'BloodPressure', 'value' : 'Tension', 'type': 6, 'tag': ['BLOODPRESSURE_SYSTOLIC', 'BLOODPRESSURE_DIASTOLIC'], 'label': ['Pression sanguine Systolique', 'Pression sanguine Diastolique'], 'i18nLabel': [t('Pression sanguine Systolique', {ns: 'datas'}), t('Pression sanguine Diastolique', {ns: 'datas'})]},
    {'key' : 'CryptedTemperature', 'value' : 'Température', 'type': 7, 'tag': ['CRYPTEDTEMPERATURE_VALUE'], 'label': ['Température'], 'i18nLabel': [t('Température', {ns: 'datas'})]},
    {'key' : 'Heartbeat', 'value' : 'BPM', 'type': 8, 'tag': ['HEARTBEAT_VALUE'], 'label': ['BPM'], 'i18nLabel': [t('BPM', {ns: 'datas'})]},
];

export const METRICS = {
    'height' : {
        'min' : 140,
        'max' : 220
    },
    'weight' : {
        'min' : 35,
        'max' : 200
    },
    'abdo' : {
        'min' : 40,
        'max' : 130
    }
};

// IE z-index is broken by translateY
// export const ANIM_CSS = { transform: 'translateY(-5px)', opacity: '0' };
export const ANIM_CSS = { opacity: '0' };
export const ANIM_EASING = 'ease-in-out';
export const ANIM_DURATION = 240;

export const FAMILIES_FRUTS = ['fruit', 'legume'];
export const FAMILIES_MEATS = ['viande', 'volaille', 'poisson', 'charcuterie', 'oeuf', 'soja'];
export const FAMILIES_FECULENTS = ['feculent', 'legume sec', 'cereales', 'pain et derives', 'pomme de terre'];
export const FAMILIES_MILK = ['dessert lacte', 'fromage', 'lait'];
export const FAMILIES_FAT = ['matiere grasse animale', 'matiere grasse vegetale', 'sauce'];
export const FAMILIES_CASUAL = ['produit sale', 'produit sucre'];
export const FAMILIES_WATER = ['eau'];
export const FAMILIES_OTHERDRINK = ['boisson sucree', 'boisson chaude', 'alcool'];

export const FAMILIES_ALIMENTS = [...FAMILIES_FRUTS, ...FAMILIES_MEATS, ...FAMILIES_FECULENTS, ...FAMILIES_MILK, ...FAMILIES_FAT, ...FAMILIES_CASUAL];
export const FAMILIES_DRINKS = [...FAMILIES_WATER, ...FAMILIES_OTHERDRINK];