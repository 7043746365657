import { appCustoSetting } from '@app/settings/settings';
import { queueQueries } from '@app/api/controlleur';
const { API_URL } = appCustoSetting.appsettings;

/**
 * Calls API to retrieve themes list with selection status.
 * @see https://betterise-api-doc.betterise.me/#api-Themes-getThemes
 * @return Response object
 */
async function getThemes() {
    return queueQueries(`${API_URL}/themes/`, 'GET', 'application/json', true, true, false)
}

/**
 * Calls API to set themes status.
 * @see https://betterise-api-doc.betterise.me/#api-Themes-setTheme
 * @param {any} themes Array of objects
 * @return Response object
 */
async function setTheme(themes = []) {
    return queueQueries(`${API_URL}/themes/`, 'POST', 'application/json', true, true, false, true, true, themes)
}

export const Themes = {
    getThemes,
    setTheme
};
