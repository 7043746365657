import { h, classMerge } from '@app/utils';

const Tile = (props, children) => {
    const {
        classes,
        ...otherProps
    } = props;

    const allClasses = classMerge([
        'btzTile',
        classes,
    ]);

    return (
        <div class={allClasses} {...otherProps}>
            {children}
        </div>
    );
};

const Icon = (props, children) => {
    const {
        classes,
        ...otherProps
    } = props;

    const allClasses = classMerge([
        'btzTile-btzIcon',
        classes,
    ]);

    return (
        <div class={allClasses} {...otherProps}>
            {children}
        </div>
    );
};

const Content = (props, children) => {
    const {
        title,
        subtitle,
        classes,
        ...otherProps
    } = props;

    const allClasses = classMerge([
        'btzTile-btzContent',
        classes,
    ]);

    return (
        <div class={allClasses} {...otherProps}>
            {title && (<p class='btzTile-btzContent-btzTitle'>{title}</p>)}
            {subtitle && (<p class='btzTile-btzContent-btzTitle'>{subtitle}</p>)}
            {children}
        </div>
    );
};

const Action = (props, children) => {
    const {
        classes,
        ...otherProps
    } = props;

    const allClasses = classMerge([
        'btzTile-btzContent-btzAction',
        classes,
    ]);

    return (
        <div class={allClasses} {...otherProps}>
            {children}
        </div>
    );
};

Tile.Icon = Icon;
Tile.Content = Content;
Tile.Action = Action;

export { Tile };
