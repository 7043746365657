import { h, Component } from '@app/utils';

import { Grid, Enter } from '@app/elements';
import * as constants from '@app/constants';

/* eslint-disable */
import logoBT from '@app/img/logo/logo-btz-small.png';
import logoBTx2 from '@app/img/logo/logo-btz-small@2x.png';
import logoBackTo from '@app/img/ico/ico-prev-light.png';
import logoBackTox2 from '@app/img/ico/ico-prev-light@2x.png';
/* eslint-enable */

const actions = {
    goBack: () => state => {
        window.history.back();
    }
}

const view = (state, actions) => (props, children) => (
    <div key={'onboarding'} class={props.page + ' specificFullHeightMobile'} data-theme={props.theme}>
        <Enter time={constants.custo.ANIM_DURATION} easing={constants.custo.ANIM_EASING} css={constants.custo.ANIM_CSS}>
            <Grid classes={'btzWrapper btzWrapperFullHeight btzVerticalAlign specificFullHeightMobile'} style={{ 'padding-bottom': '260px', 'padding-top': '85px', 'max-width': '1400px' }}>
                <Grid.Row classes={'btzWrapper-verticalAlignContent specificFullHeightMobile'}>
                    <Grid.Col classes={'specificFullHeightMobile'} x={12} mx={'auto'} style={{ 'position': 'relative' }}>
                        {children}
                    </Grid.Col>
                </Grid.Row>
            </Grid>
        </Enter>
    </div>
);

export default Component({}, actions, view, 'layout');