import { Component } from '@app/utils';
import { state } from './state';
import { actions } from './actions';
import { view } from './view';

import './../index.scss';

export { state, actions, view };

// used for lazy loading
export default Component(state, actions, view, 'publicsurveyresiliate');
