import { addCSRFToken, capitalize, isDefined, deepCopy } from '@app/core';
import { allRoutes } from '@app/settings';
import { Programs } from '@app/api';
import { t, h, Component } from '@app/utils';
import { Button, VerticalScroll, Modal } from '@app/elements';
import './index.scss';

import * as staticsconstants from '@app/constants/constants-statics';
import * as constants from '@app/constants';

import icoClose from '@app/img/ico/ico-close-white.png';
import icoClosex2 from '@app/img/ico/ico-close-white@2x.png';

const state = {
    running: [],
    currentCancelingID: null,
    initialize: false,
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        if (isDefined(props.displayedon) && (props.displayedon === 'preview')) {
            let program = props;
            program.program = deepCopy(program);
            parseRunning([program]);
        } else {
            Programs.getRunning().then(function(res) {
                parseRunning(res.data);
            });
        }

        function parseRunning(resData) {
            if (!isDefined(resData)) {
                return false;
            }
            var running = [], card = null;
            for (var reco in resData) {
                card = resData[reco];
                var cardTheme = actions.getTheme(card.theme);

                card.valueTheme = cardTheme.value;
                card.labelTheme = cardTheme.label;
                card.sessionToday = false;

                if ((card.nextSessionDate === null) || (actions.getRemainingTime(card) !== 0)) {
                    card.sessionToday = true;
                }
                card.nextSessionReadableDate = null;
                if (card.nextSessionDate && card.nextSessionDate.date) {
                    card.nextSessionReadableDate = capitalize(actions.getReadableFromYMD(card.nextSessionDate.date.split(' ')[0].split('-').join('')));
                }

                running.push(card);
            }
            actions.setRunningPrograms(running);
            actions.setInitialize(true);
        }
    },
    setRunningPrograms: newState => state => ({
        running: newState
    }),
    startProgramSession: (programid) => (state, actions) => {
        window.location.href = addCSRFToken(allRoutes['private.program.session'].pathname.replace(':programid', programid));
    },
    setInitialize: newState => state => ({
        initialize: newState,
    }),
    programCanceledPropagated: (props) => (state, actions) => {
        Programs.cancelProgram(state.currentCancelingID).then(function() {
            let running = state.running, newRunning = [];
            for (var run in running) {
                run = running[run];
                if (run.program.id !== state.currentCancelingID) {
                    newRunning.push(run);
                }
            }
            actions.setRunningPrograms(newRunning);
        });
    },
    setCancelProgramID: newState => state => ({
        currentCancelingID: newState,
    }),
    getTheme: (intTheme) => {
        for (var _t in constants.custo.THEMES) {
            if (constants.custo.THEMES[_t].key === intTheme) {
                return constants.custo.THEMES[_t];
            }
        }
        return {value : '', label : ''};
    },
    getRemainingTime: (card) => {
        if (!isDefined(card.nextSessionDate)) {
            return 0;
        }
        var startDate = new Date();
        var endDate = new Date(card.nextSessionDate.date.split(' ')[0]);

        if (endDate.getTime() > startDate.getTime()) {
            return 0;
        }

        // get total seconds between the times
        var delta = Math.abs(endDate.getTime() - startDate.getTime()) / 1000;

        // calculate (and subtract) whole days
        var days = Math.floor(delta / 86400);
        delta -= days * 86400;
        if (days === 0) {
            days = '';
        } else {
            days = days + 'j';
        }

        // calculate (and subtract) whole hours
        var hours = Math.floor(delta / 3600) % 24;
        delta -= hours * 3600;
        if (hours === 0) {
            hours = '00';
        }
        hours = hours + 'h';

        // calculate (and subtract) whole minutes
        var minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;
        if (minutes === 0) {
            '00';
        }
        minutes = minutes + 'min';
        return (days + hours + minutes);
    },
    getReadableFromYMD: (ymd) => (state, actions) => {
        var date = new Date(Date.parse(new Date(ymd.substr(0, 4) + '-' + ymd.substr(4, 2) + '-' + ymd.substr(6, 2))));
        var options = { weekday: 'long', month: 'long', day: 'numeric' };
        var readableDate = date.toLocaleDateString('fr-FR', options);
        return readableDate;
    },
};

const view = (state, actions) => (props, children) => (
    <div>
        {((state.initialize === true) && (state.running.length == 0)) ?
            <div></div>
            :
            <div id={'runningPrograms'} class='btzSimplePageBlock btzSimplePageBlock-btzSimplePageBlockNoPadding' style={{ 'padding-bottom': '25px' }}>
                <p class='btzTitle btzXl'>{t(staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()].menuNames.programs.running) + ' (' + state.running.length + ')'}</p>
                <p class='btzSubTitle'>{t('Retrouvez ci-dessous les prochaines séances des programmes que vous suivez.', {ns: 'programs'})}</p>

                <div key={state.initialize + '-' + state.running.length}>
                    <VerticalScroll controls={(state.running.length == 0) ? false : true}>
                        {((state.initialize === false) && (state.running.length == 0)) &&
                            <div>
                                <div class='col-6 col-xs-12'>
                                    <div class='btzLoadingContainer-btzWrapper'>
                                        <div class='btzLoadingContainer'></div>
                                    </div>
                                </div>
                                <div class='col-6 col-xs-12'>
                                    <div class='btzLoadingContainer-btzWrapper'>
                                        <div class='btzLoadingContainer'></div>
                                    </div>
                                </div>
                                <div class='col-6 col-xs-12'>
                                    <div class='btzLoadingContainer-btzWrapper'>
                                        <div class='btzLoadingContainer'></div>
                                    </div>
                                </div>
                            </div>
                        }

                        {state.running.map(item =>
                            <div class='col-6 col-xs-12'>
                                <div key={item.id} class='btzProgram-btzProgramRunning-btzProgramRunningWrapper'>
                                    <div class='btzBackgroundCover' style={{ 'background-image': 'url(' + (((item.media !== null) && (item.media !== undefined) && (item.media.url !== undefined)) ? item.media.url : '') + ')' }}></div>
                                    <div class='btzOverlayBlack'></div>

                                    {(isDefined(props.displayedon) && (props.displayedon === 'preview')) ?
                                        <dummy></dummy>
                                        :
                                        <Modal customClass={'btzModalCenter'} content={''} confirm={true} confirmConfirm={actions.programCanceledPropagated} title={item.program.title} description={t('Confirmez-vous l’arrêt de votre programme ? Vous perdrez tout votre historique.', {ns: 'programs'})}>
                                            <button class='btzCancelProgram' onclick={() => {
                                                actions.setCancelProgramID(item.program.id)
                                            }}>
                                                <img src={icoClose} srcset={`${icoClosex2} 2x`} alt='' class='' />
                                            </button>
                                        </Modal>
                                    }

                                    <div class='btzProgramsRunning-btzProgramRunning'>
                                        <p class='btzPageContentTexte'>{item.program.title}</p>

                                        <div class='btzProgramsRunning-btzProgramsRunningFooter'>
                                            {item.sessionToday === true ?
                                                <div>
                                                    <div class='col-6 col-sm-12 btzProgramsRunning-btzProgramsRunningFooter-btzProgramsRunningFooterLeft'>
                                                        <p class='btzPageContentSubTitle'>{t('Séance du jour', {ns: 'programs'})} #{item.sessionNumber}</p>
                                                        <p class='btzPageContentSubTexte'>{t('Durée', {ns: 'programs'})} : {item.sessionsTotal} séances</p>
                                                    </div>

                                                    <div class='col-6 col-sm-12 btzProgramsRunning-btzProgramsRunningFooter-btzProgramsRunningFooterRight'>
                                                        <Button primary active onclick={() => { if (isDefined(props.displayedon) && (props.displayedon === 'preview')) { return false; } else { actions.startProgramSession(item.program.id); } }} >
                                                            {t('Commencer', {ns: 'programs'})}
                                                        </Button>
                                                    </div>
                                                </div>
                                                :
                                                <div class='col-12'>
                                                    <p class='btzProgramsRunning-btzProgramsRunningFooter-btzNoProgram'>
                                                        {t('Pas de séance aujourd‘hui.', {ns: 'programs'})}
                                                        <br/>
                                                        {t('Rendez-vous le', {ns: 'programs'}) + ' ' + (item.nextSessionReadableDate !== null ? item.nextSessionReadableDate : 'Ø')}
                                                    </p>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </VerticalScroll>
                </div>
            </div>
        }
    </div>
);

export default Component(state, actions, view, 'runningPrograms');
