import { appCustoSetting } from '@app/settings/settings';
import { i18n } from '@app/utils';
import { en_GB, fr_FR, no_NO, pt_PT, de_DE } from '@app/locales';
const { FALLBACKLANG, DEBUG } = appCustoSetting.appsettings;

const withTranslation = (opts = {}) => (nextApp) => (initialState, initialActions, initialView, initialRoot) => {

    let lng = localStorage.getItem('language');
    if ((lng === undefined) || (lng === null) || (lng.length === 0) || (lng.indexOf('_') === -1)) {
        lng = 'fr_FR';
    }
    const options = {
        ...{
            // 'lng': settings.LANG,
            'lng': lng.split('_')[0],
            'fallbackLng': FALLBACKLANG,
            'debug': DEBUG,
            'nsSeparator': false,
            'keySeparator': false,
            'formatSeparator': false,
            'pluralSeparator': false,
            'contextSeparator': false,
            'joinArrays': true,
            'resources': {
                fr: { translation: fr_FR, onboarding: fr_FR, account: fr_FR, datas: fr_FR, follow: fr_FR, food: fr_FR, home: fr_FR, login: fr_FR, programs: fr_FR, public: fr_FR, register: fr_FR, timeline: fr_FR, generals: fr_FR, favorites: fr_FR, objectives: fr_FR, oc: fr_FR, rewards: fr_FR, abonnement: fr_FR, cb: fr_FR, dataprofil: fr_FR, health: fr_FR, informations: fr_FR, resiliate: fr_FR, sponsor: fr_FR },
                en: { translation: en_GB, onboarding: en_GB, account: en_GB, datas: en_GB, follow: en_GB, food: en_GB, home: en_GB, login: en_GB, programs: en_GB, public: en_GB, register: en_GB, timeline: en_GB, generals: en_GB, favorites: en_GB, objectives: en_GB, oc: en_GB, rewards: en_GB, abonnement: en_GB, cb: en_GB, dataprofil: en_GB, health: en_GB, informations: en_GB, resiliate: en_GB, sponsor: en_GB },
                no: { translation: no_NO, onboarding: no_NO, account: no_NO, datas: no_NO, follow: no_NO, food: no_NO, home: no_NO, login: no_NO, programs: no_NO, public: no_NO, register: no_NO, timeline: no_NO, generals: no_NO, favorites: no_NO, objectives: no_NO, oc: no_NO, rewards: no_NO, abonnement: no_NO, cb: no_NO, dataprofil: no_NO, health: no_NO, informations: no_NO, resiliate: no_NO, sponsor: no_NO },
                pt: { translation: pt_PT, onboarding: pt_PT, account: pt_PT, datas: pt_PT, follow: pt_PT, food: pt_PT, home: pt_PT, login: pt_PT, programs: pt_PT, public: pt_PT, register: pt_PT, timeline: pt_PT, generals: pt_PT, favorites: pt_PT, objectives: pt_PT, oc: pt_PT, rewards: pt_PT, abonnement: pt_PT, cb: pt_PT, dataprofil: pt_PT, health: pt_PT, informations: pt_PT, resiliate: pt_PT, sponsor: pt_PT },
                de: { translation: de_DE, onboarding: de_DE, account: de_DE, datas: de_DE, follow: de_DE, food: de_DE, home: de_DE, login: de_DE, programs: de_DE, public: de_DE, register: de_DE, timeline: de_DE, generals: de_DE, favorites: de_DE, objectives: de_DE, oc: de_DE, rewards: de_DE, abonnement: de_DE, cb: de_DE, dataprofil: de_DE, health: de_DE, informations: de_DE, resiliate: de_DE, sponsor: de_DE },
            },
            'interpolation': {
                'escapeValue': true,
                'formatSeparator': false
            },
        },
        ...opts
    }

    const enhancedState = {
        lang: options.lng
    }

    const enhancedActions = {
        initLang: () => {
            i18n.init(options)
        },
        changeLanguage: ({lang, updateStorage = true}) => {
            if (updateStorage) {
                localStorage.setItem('language', lang);
            }
            lang = lang.replace(/-/g, '_');
            if (lang.indexOf('_') > -1) {
                lang = lang.split('_')[0]; // get lower part to retrieve good translation file
            }
            console.log('NAVIGATOR : changeLanguage > ', lang);
            if ((options.resources[lang] === undefined) || (options.resources[lang].translation === undefined)) {
                lang = 'fr';
                console.log('NAVIGATOR : changeLanguage > failed, fallback > ', lang);
                localStorage.removeItem('language');
            }

            console.log('NAVIGATOR : changeLanguage > setting final lang to >>>>>>>>> ', lang);
            i18n.changeLanguage(lang);
            return ({ lang })
        }
    }
    enhancedActions.initLang();

    return nextApp(
        { ...initialState, ...enhancedState },
        { ...initialActions, ...enhancedActions },
        initialView,
        initialRoot,
    )
};

export { withTranslation };