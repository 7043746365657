import { h } from '@app/utils';
import { Card, Social } from '@app/elements';

import imgCitation from '@app/img/timeline/img-timeline-citation.png';
import imgCitationx2 from '@app/img/timeline/img-timeline-citation@2x.png';

export const ModalViewCitation = (props, actions) => (
    <Card kind={'citation'} theme={props.valueTheme}>
        <Card.Image kind={props.media} style={{ 'display': 'table', 'width': '100%', 'padding-top': '75px', 'position': 'absolute', 'top': '0', 'margin-top': '0' }}>
            <div style={{ 'display': 'table-cell', 'vertical-align': 'middle' }}>
                <img src={imgCitation} srcset={`${imgCitationx2} 2x`} alt='' class='' />
            </div>
        </Card.Image>
        <Card.Body classes='btzVerticalAlign btzCard-btzBody-btzModal btzDottedSpaced' style={{ 'padding-top': '75px' }}>
            <div class='btzWrapper-verticalAlignContent'>
                <p class='btzTimeline-btzCard-btzDescription btzTexte'>{props.title}</p>
                <Card.Header classes={'btzCard-btzHeader-btzModal'}>
                    <p class='btzTimeline-btzCard-btzTitle btzTitle' style={{ 'margin-top': '0px', 'padding-top': '0px' }}>{props.description}</p>
                    <Social kindcard={'citation'} title={props.description} style={{ 'position': 'relative' }} idfavorite={props.favoriteId} idtimeline={props.timeline} id={props.id} favorite={props.favorite} propagate={props.propagate} displayedon={props.displayedon} cancelAction={props.propagateConfirmClosing}></Social>
                </Card.Header>
            </div>
        </Card.Body>
    </Card>
);