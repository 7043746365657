import { h, classMerge } from '@app/utils';

const Toast = (props, children) => {
    const {
        type,
        toggle,
        classes,
        ...otherProps
    } = props;

    const allClasses = classMerge([
        'toast',
        type && `toast-${type}`,
        classes,
    ]);

    return (
        <div class={allClasses}
            onclick={toggle}
            {...otherProps}>
            <button class='btn btn-clear float-right'></button>
            {children}
        </div>
    );
};

export { Toast };
