import { dynLoad, getTodayYMD, addCSRFToken,isDefined } from '@app/core';
import { t, h } from '@app/utils';
import { allRoutes } from '@app/settings';
import * as staticsconstants from '@app/constants/constants-statics';

import { Avatar, Balloon, Button, Form, Tile, Counter, Convert } from '@app/elements';
import './../index.scss';

import logoBackTo from '@app/img/ico/ico-prev-light.png';
import logoBackTox2 from '@app/img/ico/ico-prev-light@2x.png';

import StepPregnancy from './../../Register/step-pregnancy';
import StepPregnancyKind from './../../Register/step-pregnancy-kind';

import OnboardingLayout from '@app/layouts/onboarding/layout';

import icoInfo from '@app/img/ico/ico-ask-white.png';
import icoInfox2 from '@app/img/ico/ico-ask-white@2x.png';

import loaderWaiting from '@app/img/loader.gif';
import loaderWaitingx2 from '@app/img/loader.gif';

import icoValidQCM from '@app/img/ico/ico-valid-white.png';
import icoValidQCMx2 from '@app/img/ico/ico-valid-white@2x.png';

import icoValidQCMCERBA from './../cerba/ico-valid-blue.png';
import icoValidQCMCERBAx2 from './../cerba/ico-valid-blue@2x.png';
import bgEndCerba from './../cerba/cerba-bg-end.png';

export const img = dynLoad('img', 'Onboarding/custo');
const ONBOARDING_IMG = img.ONBOARDING_IMG;

const SKIPPABLE_CARD_NAME = 'EVAL-CERBA-005';

module.exports = {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <div key={state.endBoard}>
                <OnboardingLayout page='onboarding' theme={(state.showPregnancyInputs ? 'step-pregnancy' : state.current.theme)} customer={props.customer}>
                    {state.showPregnancyInputs ?
                        <dummy>
                            {((state.showPregnancyInputsStep === 'ddr') || (state.showPregnancyInputsStep === 'sa')) && (
                                <div class='onboarding-pregnancyBackButton'>
                                    <img src={logoBackTo} srcset={`${logoBackTox2} 2x`} alt='' onclick={() => { actions.updatePregnancyKind('pregnancy') }} />
                                </div>
                            )}
                            {state.showPregnancyInputsStep === 'pregnancy' && (
                                <StepPregnancy initial={state.user} customer={props.customer} displayed={'onboarding'} updateKind={actions.updatePregnancyKind} />
                            )}
                            {((state.showPregnancyInputsStep === 'ddr') || (state.showPregnancyInputsStep === 'sa')) && (
                                <StepPregnancyKind initial={state.user} customer={props.customer} displayed={'onboarding'} pregnancyKind={state.showPregnancyInputsStep} />
                            )}
                        </dummy>
                        :
                        <dummy>
                            {state.current.form &&
                                <dummy>
                                    {state.endBoard === false ?
                                        <div style={{ position: 'relative' }}>
                                            <Tile style={{ 'text-align': 'left' }}>
                                                {(state.mobileOnboarding === true) ?
                                                    <div class='btzTitle-btzImage btzTitle-btzImage-absolute col-md-12 col-5' style={{ 'max-width': '450px' }}>
                                                        <p class='btzTitle btzXl'>{t('Question', {ns: 'onboarding'}) + ' ' + state.current.numCard + '/' + state.totalCards}</p>
                                                        <p class='btzSubTitle'>{state.current.question}</p>
                                                    </div>
                                                    :
                                                    <div class='btzTitle-btzImage btzTitle-btzImage-absolute col-md-12 col-5' style={{ 'max-width': '450px' }}>
                                                        <p class='btzTitle btzXl' style={{ 'margin-left': '80px' }}>{state.current.question}</p>
                                                    </div>
                                                }
                                                <Tile.Content classes='btzLeftHrArrowedOnboarding col-xs-12 col-7' style={{ 'text-align': 'center' }}>
                                                    {state.current.form && (
                                                        <div key={'form-' + state.current.form.id + '-' + state.current.numCard} class={'form-' + state.current.form.type + ' form-id-' + state.current.form.id + '-' + state.current.numCard}>
                                                            {state.current.form.type == 'slider' && (
                                                                <div class='btzOnboarding-btzButtonsWrapper btzOnboarding-btzButtonsWrapper-slider' style={{ 'max-width': '425px' }}>
                                                                    {state.current.form.datas.map(item =>
                                                                        <div onclick={actions.selectThisAnswer} data-id={state.current.form.id} data-tag={state.current.form.tag} data-kind='slider' data-value={item.value} class='btzOnBoarding-btzButton btzUnselectable btzOnBoarding-btzButton-btzNumber'>{item.name}</div>
                                                                    )}
                                                                </div>
                                                            )}
                                                            {((state.current.form.type == 'number') || (state.current.form.type == 'counter')) && (
                                                                <div class='btzOnboarding-btzButtonsWrapper btzOnboarding-btzButtonsWrapper-number'>
                                                                    <Counter key={state.current.form.id} mobileOnboarding={state.mobileOnboarding} class='btzCurrentEvaluationMarker' floating={(((state.current.theme === 'followed-weight') && (!state.mobileOnboarding)) ? true : false)} min={(isDefined(state.current.stepRanges[0].datas[0]) ? state.current.stepRanges[0].datas[0].min : (state.current.stepRanges[0].min))} max={(isDefined(state.current.stepRanges[0].datas[0]) ? state.current.stepRanges[0].datas[0].max : (state.current.stepRanges[0].max))} default={(isDefined(state.current.stepRanges[0].datas[0]) ? state.current.stepRanges[0].datas[0].default : (state.current.stepRanges[0].default))} did={state.current.form.id} kind='number' tag={state.current.form.tag} label={t(state.current.form.label)} initialCount={state.specialInitialCount} isMobile={(state.mobileOnboarding)}></Counter>
                                                                </div>
                                                            )}
                                                            {state.current.form.type == 'textarea' && (
                                                                <div class='btzOnboarding-btzButtonsWrapper btzOnboarding-btzButtonsWrapper-textarea'>
                                                                    <textarea oncreate={(el) => { if (state.mobileOnboarding && el) { el.focus(); } el.value = '';  }} key={state.current.form.id}  class='btzCurrentEvaluationMarker' data-id={state.current.form.id} data-tag={state.current.form.tag} placeholder={t('Expliquez ici avec les mots de votre choix', {ns: 'onboarding'})} oninput={(el) => { if (el.target.value !== '') { actions.switchValidationActivation(true); } else { actions.switchValidationActivation(false); } }}></textarea>
                                                                </div>
                                                            )}
                                                            {state.current.form.type == 'qcm' && (
                                                                <div class='btzOnboarding-btzButtonsWrapper btzOnboarding-btzButtonsWrapper-qcm' style={{ 'margin-left': '48px' }}>
                                                                    {state.current.form.datas.map(item =>
                                                                        <div class='col-4 col-md-12' oncreate={actions.updateButtonHeight} style={{ 'min-height': (state.uniformButtonHeight > 0 ? (state.uniformButtonHeight + 'px') : 'auto') }}>
                                                                            {item.datas.filter((f) => f.lang === state.language)[0] !== undefined &&
                                                                                <div onclick={actions.selectThisAnswer} data-id={state.current.form.id} data-tag={item.tag} data-kind='qcm' data-value={item.datas.filter((f) => f.lang === state.language)[0].value} class='btzOnBoarding-btzButton btzOnBoarding-btzQcm-btzLabel'>
                                                                                    <div class='btzOnboarding-btzButtonsWrapper-QcmPuce' data-theme={state.current.theme}>
                                                                                        {props.customer === 'cerba' ?
                                                                                            <img src={icoValidQCMCERBA} srcset={`${icoValidQCMCERBAx2} 2x`} alt='' />
                                                                                            :
                                                                                            <img src={icoValidQCM} srcset={`${icoValidQCMx2} 2x`} alt='' />
                                                                                        }
                                                                                    </div>
                                                                                    <div class='btzOnboarding-btzButtonsWrapper-Label'>
                                                                                        <p style={{ 'display': 'table', 'height': (state.uniformButtonHeight > 0 ? ((state.uniformButtonHeight - 20) + 'px') : 'auto') }}>
                                                                                            <font style={{ 'display': 'table-cell', 'vertical-align': 'middle', 'text-align': 'left' }}>{item.datas.filter((f) => f.lang === state.language)[0].name}</font>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                            {state.current.form.type == 'button' && (
                                                                <div class={'btzOnboarding-btzButtonsWrapper btzOnboarding-btzButtonsWrapper-button ' + state.current.name} data-length={state.current.form.datas.length}>
                                                                    {state.current.form.datas.map(item =>
                                                                        <dummy>
                                                                            {state.current.name !== state.cerbaSportCoachDisplay ?
                                                                                <div class='col-4 col-md-12'>
                                                                                    {item.datas.filter((f) => f.lang === state.language)[0] !== undefined &&
                                                                                        <div onclick={actions.selectThisAnswer} oncreate={actions.updateButtonHeight} data-id={state.current.form.id} data-tag={state.current.form.tag} data-kind='button' data-value={item.datas.filter((f) => f.lang === state.language)[0].value} class='btzOnBoarding-btzButton btzOnBoarding-btzSlider-btzLabel' style={{ 'min-height': (state.uniformButtonHeight > 0 ? (state.uniformButtonHeight + 'px') : 'auto') }}>
                                                                                            <p class='btzUnselectable' style={{ 'padding': '10px 0' }}>
                                                                                                {item.datas.filter((f) => f.lang === state.language)[0].name}
                                                                                            </p>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                                :
                                                                                <div class='col-4 col-md-12 cerba-special-display' style={{ 'display': 'inline-block', 'margin': 'auto', 'vertical-align': 'top' }}>
                                                                                    {(item.datas.filter((f) => f.lang === state.language)[0] !== undefined) &&
                                                                                        <dummy>
                                                                                            <div onclick={actions.selectThisAnswer} oncreate={actions.updateButtonHeight} data-id={state.current.form.id} data-tag={item.tag} data-kind='button' data-value={item.datas.filter((f) => f.lang === state.language)[0].value} class='btzOnBoarding-btzButton btzOnBoarding-btzSlider-btzLabel' style={{ 'display': 'table', 'min-height': (state.uniformButtonHeight > 0 ? (state.uniformButtonHeight + 'px') : 'auto') }}>
                                                                                                <div style={{ 'position': 'relative', 'display': 'table-cell', 'vertical-align': 'middle' }}>
                                                                                                    {(ONBOARDING_IMG[item.datas.filter((f) => f.lang === state.language)[0].name.toUpperCase()] !== undefined) &&
                                                                                                        <img src={ONBOARDING_IMG[item.datas.filter((f) => f.lang === state.language)[0].name.toUpperCase() + '-small'].normal} srcset={`${ONBOARDING_IMG[item.datas.filter((f) => f.lang === state.language)[0].name.toUpperCase() + '-small'].retina} 2x`} alt='' />
                                                                                                    }
                                                                                                    <p class=''>
                                                                                                        {(item.datas.filter((f) => f.lang === state.language)[0].name.toLowerCase() === 'fizix') ?
                                                                                                            t('Le coaching par webcam', {ns: 'onboarding'})
                                                                                                            :
                                                                                                            t('L’accès  à une salle de sport', {ns: 'onboarding'})
                                                                                                        }
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                            {(item.datas.filter((f) => f.lang === state.language)[0].name.toLowerCase() === 'fizix') ?
                                                                                                <a class='cerba-link' target='_blank' href={allRoutes['pdf.cerba.fizix'].pathname}>
                                                                                                    <div class='btzInformations-btzImage'><img src={icoInfo} srcset={`${icoInfox2} 2x`} alt='' /></div>
                                                                                                </a>
                                                                                                :
                                                                                                <a class='cerba-link' target='_blank' href='https://www.gymlib.com/'>
                                                                                                    <div class='btzInformations-btzImage'><img src={icoInfo} srcset={`${icoInfox2} 2x`} alt='' /></div>
                                                                                                </a>
                                                                                            }
                                                                                        </dummy>
                                                                                    }
                                                                                </div>
                                                                            }
                                                                        </dummy>
                                                                    )}

                                                                    {state.current.name === state.cerbaSportCoachDisplay &&
                                                                        <div class='col-4 col-md-12 cerba-special-display' style={{ 'display': 'inline-block', 'margin': 'auto', 'vertical-align': 'top' }}>
                                                                            <div onclick={actions.selectThisAnswer} oncreate={actions.updateButtonHeight} data-id={state.current.form.id} data-tag='-1' data-kind='button' data-value='-1' class='btzOnBoarding-btzButton btzOnBoarding-btzSlider-btzLabel' style={{ 'min-height': (state.uniformButtonHeight > 0 ? (state.uniformButtonHeight + 'px') : 'auto') }}>
                                                                                <div style={{ 'position': 'relative', 'display': 'table-cell', 'vertical-align': 'middle' }}>
                                                                                    <p class=''>
                                                                                        {t('Je n’ai pas encore fait mon choix', {ns: 'onboarding'})}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </Tile.Content>
                                            </Tile>

                                            {state.targetCards !== 58 ?
                                                <Form onsubmit={() => null} style={{ 'min-height': 0 }}>
                                                    <Form.Group classes='btzForm-btzFooter'>
                                                        {(staticsconstants.CUSTOMERS[state.customer.toLowerCase()].modules.onboardingskip === true) &&
                                                            <Button primary small onclick={() => { actions.nextCard(true) }}>
                                                                {t('Passer', {ns: 'onboarding'})}
                                                            </Button>
                                                        }

                                                        {(state.current.name === SKIPPABLE_CARD_NAME) &&
                                                            <div class='answer-later'>
                                                                <Button primary bordered onclick={() => { actions.nextCard(true) }}>
                                                                    {t('Répondre plus tard', {ns: 'onboarding'})}
                                                                </Button>
                                                            </div>
                                                        }

                                                        {(state.mobileOnboarding === true) ?
                                                            <Button primary active={state.activeValidation} loading={(state.formIsSubmitting)} onclick={() => { actions.nextCard(false) }} >
                                                                {t('Enregistrer et continuer', {ns: 'onboarding'})}
                                                            </Button>
                                                            :
                                                            <Button primary active={state.activeValidation} loading={(state.formIsSubmitting)} onclick={() => { actions.nextCard(false) }} >
                                                                {t('Valider', {ns: 'onboarding'})}
                                                            </Button>
                                                        }
                                                    </Form.Group>
                                                </Form>
                                                :
                                                <Form onsubmit={() => null} style={{ 'min-height': 0 }}>
                                                    <Form.Group classes='btzForm-btzFooter'>
                                                        <Button primary active={state.activeValidation} loading={(state.formIsSubmitting)} onclick={() => { actions.sendTimelineAnswer() }} >
                                                            {t('Enregistrer', {ns: 'onboarding'})}
                                                        </Button>
                                                    </Form.Group>
                                                </Form>
                                            }

                                            <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap', 'padding-right': '50px' }}>
                                                <Tile.Icon classes={''}>
                                                    <Avatar.Alfred classes={'centered'} />
                                                </Tile.Icon>
                                                <Tile.Content>
                                                    <Balloon kind={state.error !== '' ? 'error' : 'info'} title={(state.error !== '' ? '' : t('QUESTION', {ns: 'onboarding'}) + ' - <font>' + (state.idx + 1) + '/' + Object.keys(state.steps).length + '</font>')} style={{ 'white-space': 'normal' }}>
                                                        <dummy innerHTML={<Convert separator={''} is_xhtml={true} type='nl2br'>{(state.error !== '' ? state.error : state.current.help)}</Convert>}></dummy>
                                                    </Balloon>
                                                </Tile.Content>
                                            </Tile>
                                        </div>
                                        :
                                        <div style={{ position: 'relative' }}>
                                            <div class='btzFullBg' style={{ position: 'relative', 'background-image': 'url(' + bgEndCerba + ')' }}></div>
                                            <Tile style={{ 'position': 'absolute' }}>
                                                <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap' }}>
                                                    <Tile.Icon>
                                                        <Avatar.Alfred classes={'centered'} />
                                                    </Tile.Icon>
                                                    <Tile.Content style={{ 'border': 'none' }}>
                                                        <Balloon kind={'info'} style={{ 'white-space': 'normal' }}>
                                                            <font innerHTML={<Convert separator={''} is_xhtml={true} type='nl2br'>{t('Découvrez dès à présent votre programme personnalisé.', {ns: 'onboarding'}) + '\n\n' + t('Je vous rappellerai très rapidement pour affiner votre profil avec vous, programmer nos rendez-vous et vous faire bénéficier de nos offres partenaires.', {ns: 'onboarding'}) + '\n\n' + t('À très vite!', {ns: 'onboarding'})}</Convert>}></font>
                                                        </Balloon>
                                                    </Tile.Content>
                                                </Tile>
                                            </Tile>

                                            <Form onsubmit={() => null} style={{ 'min-height': 0 }}>
                                                <Form.Group classes='btzForm-btzFooter'>
                                                    {state.activeEndSubmit ?
                                                        <Button primary active onclick={() => {
                                                            localStorage.setItem('onboarding-viewed-' + state.user.id + '-' + getTodayYMD(), true);
                                                            window.location.href = addCSRFToken(allRoutes['private.timeline'].pathname);
                                                        }} >
                                                            {t('Commencer', {ns: 'onboarding'})}
                                                        </Button>
                                                        :
                                                        <img style={{ 'display': 'block', 'margin': '28px auto', 'max-height': '60px' }} src={loaderWaiting} srcset={`${loaderWaitingx2} 2x`} alt='' />
                                                    }
                                                </Form.Group>
                                            </Form>
                                        </div>
                                    }
                                </dummy>
                            }
                        </dummy>
                    }
                </OnboardingLayout>
            </div>
        );
        return view;
    }
}
