import { t, h } from '@app/utils';
import { Card, Button, Convert } from '@app/elements';
import { addCSRFToken, isDefined } from '@app/core';
import { allRoutes } from '@app/settings';
import * as staticsconstants from '@app/constants/constants-statics';

import imgDefaultAvatar from '@app/img/default/def-avatar.png';
import imgDefaultAvatarx2 from '@app/img/default/def-avatar@2x.png';

import imgProfil from '@app/img/timeline/img-timeline-profil.png';
import imgProfilx2 from '@app/img/timeline/img-timeline-profil@2x.png';

module.exports = {
    initView: function() {
        const viewProfil = (state, actions) => (props, children) => (
            <div>
                <Card kind={'profil'}>
                    <Card.Header>
                        <Card.Image><img src={imgProfil} srcset={`${imgProfilx2} 2x`} alt='' class='' /></Card.Image>
                    </Card.Header>
                    <Card.Body style={{ 'margin-right': '5px', 'padding-bottom': '15px', 'text-align': 'left' }}>
                        <Card.Image>
                            {(props.url != null && props.default != true) ?
                                <img src={props.url} alt='' class='' />
                                :
                                <img src={imgDefaultAvatar} srcset={`${imgDefaultAvatarx2} 2x`} alt='' class='' data-customer={props.customer} />
                            }
                        </Card.Image>
                        <div style={{ 'display': 'inline-block', 'padding': '13px 0px 0 10px', 'vertical-align': 'top' }}>
                            <p class='btzTimeline-btzCard-btzTitle btzTexte' style={{ 'font-size': '14px', 'white-space': 'nowrap', 'overflow': 'hidden', 'text-overflow': 'ellipsis' }}><b>{props.nick}</b></p>
                            {(+props.seniority > 2) ?
                                <p class='btzTimeline-btzCard-btzTitle btzTexte'>
                                    {(props.gender === false ? t('Inscrite depuis', {ns: 'timeline'}) : t('Inscrit depuis', {ns: 'timeline'}))} <b>{props.seniority} {t('jours', {ns: 'timeline'})}</b>
                                </p>
                                :
                                <p class='btzTimeline-btzCard-btzTitle btzTexte'>
                                    {(+props.seniority === 1) ?
                                        (props.gender === false ? t('Inscrite depuis', {ns: 'timeline'}) : t('Inscrit depuis', {ns: 'timeline'})) + ' ' + t('aujourd‘hui', {ns: 'timeline'})
                                        :
                                        (props.gender === false ? t('Inscrite depuis', {ns: 'timeline'}) : t('Inscrit depuis', {ns: 'timeline'})) + ' ' + t('hier', {ns: 'timeline'})
                                    }
                                </p>
                            }
                            {(state.objectives.length > 0) &&
                                <p class='btzTimeline-btzCard-btzTitle btzTexte'>
                                    <b>{state.objectives.length + ' ' + (state.objectives.length > 1 ? t('objectifs', {ns: 'timeline'}) : t('objectif', {ns: 'timeline'}))}</b>{' ' + t('en cours', {ns: 'timeline'})}
                                </p>
                            }
                        </div>
                    </Card.Body>
                </Card>

                {(staticsconstants.CUSTOMERS[props.customer.toLowerCase()].modules.dashboard === true) &&
                    <div style={{ 'position': 'relative' }}>
                        {state.objectives.map((item) =>
                            <Card kind={'objective'}>
                                <Card.Body style={{ 'margin': '0px' }}>
                                    <div style={{ 'margin': '0 20px' }}>
                                        <div class='col-12' style={{ 'vertical-align': 'middle', 'padding': '0px', 'text-align': 'left', 'white-space': 'nowrap' }}>
                                            {item.media !== null &&
                                                <Card.Image style={{ 'display': 'inline-block', 'vertical-align': 'middle' }}><img src={item.media.url} alt='' class='' /></Card.Image>
                                            }
                                            <p class='btzPageContentSubTexte btzXl' style={{ 'display': 'inline-block', 'vertical-align': 'middle', 'margin-left': '15px', 'max-width': '73%', 'white-space': 'normal' }}>{item.title}</p>
                                        </div>
                                    </div>

                                    <div class='btzProfil-btzObjectives'>
                                        {item.data.map((thm) =>
                                            <div class='btzProfil-btzObjective'>
                                                <div class='bordered' data-theme={thm.valueTheme}>
                                                    <div class='col-6 p-0' style={{ 'vertical-align': 'middle' }}>
                                                        <p class='btzPageContentTexte'>{t(thm.labelTheme)}</p>
                                                        <p class='btzPageContentSubTexte' style={{ 'white-space': 'nowrap', 'margin-top': '-2px' }} title={t(thm.labelLabel)}>{t(thm.labelLabel)}</p>
                                                    </div>
                                                    <div class='col-6' style={{ 'vertical-align': 'middle', 'position': 'relative' }}>
                                                        {thm.valueTheme === 'drink' ?
                                                            <div style={{ 'text-align': 'right' }}>
                                                                {(isDefined(thm.total !== null) && (thm.total !== '') && (+thm.total > 0)) ?
                                                                    <p class='btzProfil-btzObjective-btzValue' data-length={String(thm.total).length}><Convert type={thm.convertto} kind={'smart'}>{thm.total}</Convert></p>
                                                                    :
                                                                    <div class='btzProfil-btzObjective-btzWaterWrapper'>
                                                                        <p key={'drinks-' + state.drink} class='btzProfil-btzObjective-btzValue' data-length={String(state.drink).length}>{state.drink}</p>
                                                                        <Button primary block active flat onclick={thm.onclick} style={{ 'padding': '4px 10px' }}>
                                                                            {t('+1 verre', {ns: 'timeline'})}
                                                                        </Button>
                                                                    </div>
                                                                }
                                                            </div>
                                                            :
                                                            <div style={{ 'text-align': 'right' }}>
                                                                {(isDefined(thm.total !== null) && (thm.total !== '') && (+thm.total > 0)) ?
                                                                    <p class='btzProfil-btzObjective-btzValue' data-length={String(thm.total).length}><Convert type={thm.convertto} kind={'smart'}>{thm.total}</Convert></p>
                                                                    :
                                                                    <Button primary block active flat onclick={() => {
                                                                        if (thm.valueTheme === 'food') {
                                                                            window.location.href = addCSRFToken(allRoutes['private.food'].pathname);
                                                                        } else {
                                                                            window.location.href = addCSRFToken(allRoutes['private.datas'].pathname + '?theme=' + thm.valueTheme);
                                                                        }
                                                                    }}>
                                                                        {t('Ajouter', {ns: 'timeline'})}
                                                                    </Button>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </Card.Body>
                            </Card>
                        )}
                    </div>
                }
            </div>
        );
        return viewProfil;
    }
}