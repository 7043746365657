import { t, h } from '@app/utils';

export const actions = {
    onComponentCreate: (props) => (state, actions) => {

    },
    getDate: ({el, dat}) => {
        var date = new Date(Date.parse(new Date(dat)));
        var options = { weekday: 'long', month: 'long', day: 'numeric' };
        el.innerHTML = (t('Effectué le', {ns: 'generals'}) + ' ' + date.toLocaleDateString('fr-FR', options));
    }
};
