import { t, h } from '@app/utils';
import { Avatar, Balloon, Button, Form, Tile } from '@app/elements';
import './index.scss';

export const view = (state, actions) => (props, children) => (
    <div style={{ position: 'relative' }}>
        <Tile>
            <Tile.Content>
                <p class='btzTitle btzXl' style={{ 'margin-bottom': '0', height: '115px' }}>{t('Quelle est votre', {ns: 'register'})}<br />{t('mutuelle ?', {ns: 'register'})}</p>
            </Tile.Content>
        </Tile>


        <Form onsubmit={event => event.preventDefault()}>

            <Form.Group style={{ 'white-space': 'nowrap' }}>
                <Form.Input
                    placeholder={t('Votre mutuelle', {ns: 'register'})}
                    type='text'
                    id='customer'
                    name='customer'
                    value={state.mutual.value}
                    oninput={(event) => {
                        actions.setMutualValue(event.target.value);
                        actions.onMutualInputDebouncedUpdate(event.target.value);
                    }}
                    onfocus={() => actions.onMutualInputTouched()}
                    required
                />
            </Form.Group>

            <Form.Group classes='btzForm-btzFooter'>
                <Button primary block
                    active={!!(Object.keys(state.mutual.choosen).length > 0)}
                    loading={state.form.isSubmitting}
                    onclick={(event) => {
                        actions.updateParentProps(props)
                        actions.onStepSubmit(event)
                    }}>
                    {t('Valider', {ns: 'generals'})}
                </Button>
            </Form.Group>
        </Form>

        {state.mutuals.values && (
            <ul name='mutuals'>
                {state.mutuals.values.map(mutual => (
                    <button key={mutual.id}
                        onclick={(event) => actions.onMutualsListClick(event.target.innerText.toUpperCase())}>{mutual.name.toLowerCase()}</button>
                ))}
            </ul>
        )}
        <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap' }}>
            <Tile.Icon>
                <Avatar.Alfred kind={(!state.api.message ? 'info' : 'error')} classes={'centered'} />
            </Tile.Icon>
            <Tile.Content>
                {!state.api.message && (
                    <Balloon kind='info' style={{ 'white-space': 'normal' }}>
                        <p style={{ 'text-align': 'left' }}>
                            <span class='btzBaloon-btzTitle'>{t('INSCRIPTION', {ns: 'register'})}</span>
                            <span> - 3/4</span>
                            <font>
                                <br />{t('Renseignez les premiers caractères et choisissez dans la liste.', {ns: 'register'})}
                            </font>
                        </p>
                    </Balloon>
                )}
                {state.api.message === 'MutualNoEntry' && (
                    <Balloon kind={'error'} style={{ 'white-space': 'normal' }}>
                        {t('Merci de saisir une mutuelle.', {ns: 'register'})}
                    </Balloon>
                )}
                {state.api.message === 'MutualNoExists' && (
                    <Balloon kind={'error'} style={{ 'white-space': 'normal' }}>
                        {t('Veuillez sélectionner une mutuelle dans la liste déroulante.', {ns: 'register'})}
                    </Balloon>
                )}
                {state.api.message === 'MutualNotSelected' && (
                    <Balloon kind={'error'} style={{ 'white-space': 'normal' }}>
                        {t('Veuillez sélectionner une mutuelle dans la liste déroulante.', {ns: 'register'})}
                    </Balloon>
                )}
                {state.api.message === 'MutualNotPartner' && (
                    <Balloon kind={'error'} style={{ 'white-space': 'normal' }}>
                        {t('La mutuelle que vous avez sélectionnée n‘est pas encore partenaire de Betterise.', {ns: 'register'})}
                    </Balloon>
                )}
            </Tile.Content>
        </Tile>
    </div>
);
