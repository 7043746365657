import { t, h } from '@app/utils';
import { isDefined } from '@app/core';
import { Card, Media, Convert } from '@app/elements';

import offerGymlib from './cerba/offer-gymlib.png';
import offerGymlibx2 from './cerba/offer-gymlib@2x.png';

import offerFizix from './cerba/offer-fizix.png';
import offerFizixx2 from './cerba/offer-fizix@2x.png';

export const view = (state, actions) => (props, children) => (
    <Card kind={'offer'} theme={props.valueTheme}>
        {props.displayedon == 'timeline' &&
            <div>
                <Card.Header>
                    <Card.Image kind={props.media} style={{ 'min-height': '100px' }}>
                        {((props.media !== undefined) && (props.media !== null)) &&
                            <Media src={props.media}></Media>
                        }
                    </Card.Image>
                </Card.Header>

                <Card.Body classes='btzTopCustomDotted' style={{ 'position': 'relative' }}>
                    <div class='crbHole crbHole-crbHoleLeft'></div>
                    <div class='crbHole crbHole-crbHoleRight'></div>

                    <p class='btzTimeline-btzCard-btzDescription btzTexte' style={{ 'padding-bottom': '25px', 'max-width': '75%', 'margin': '20px auto auto' }} innerHTML={<Convert separator={''} is_xhtml={true} type='nl2br'>{props.description}</Convert>}></p>
                    {((props.name) && (props.name.toLowerCase().indexOf('gymlib') > -1)) &&
                        <div style={{ 'margin': '25px auto', 'display': 'block', 'line-height': '0' }}>
                            <img src={offerGymlib} srcset={`${offerGymlibx2} 2x`} alt='' class='' />
                        </div>
                    }
                    {((props.name) && (props.name.toLowerCase().indexOf('fizix') > -1)) &&
                        <div style={{ 'margin': '25px auto', 'display': 'block', 'line-height': '0' }}>
                            <img src={offerFizix} srcset={`${offerFizixx2} 2x`} alt='' class='' />
                        </div>
                    }
                    {isDefined(props.url) &&
                        <a href={props.url} target={'_blank'}>
                            {t('Je découvre l’offre', {ns: 'timeline'})}
                        </a>
                    }
                </Card.Body>
            </div>
        }
    </Card>
);
