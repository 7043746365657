import { h } from '@app/utils';

module.exports = {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <div></div>
        );
        return view;
    }
}
