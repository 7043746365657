import { delay } from 'hyperapp-fx';

export const actions = {
    onComponentCreate: () => {
        const TIMER = 6 * 1000;

        return delay(TIMER, 'goNextPage', {});
    },

    goNextPage: () => {
        window.main.location.go('/home/register/pseudo');
    },
};
