import { t, h, Component } from '@app/utils';
import { Button, Modal, Card } from '@app/elements';
import './index.scss';

import { ModalView } from './modalView';

const state = {

}

const actions = {
    onComponentCreate: () => (state, actions) => {

    },
};

const view = (state, actions) => (props, children) => (
    <Card id={'health'} key={'param-health'} classes={'btzPageCard btzPageCard-btzPageCardNoPadding'} kind={'param'}>
        <Card.Header classes={'btzCard-btzHeader-btzPage'}>
            <div class='col-12'>
                <p class='btzTimeline-btzCard-btzTitle btzPageContentTitle'>{t('Votre profil santé', {ns: 'health'})}</p>
                <p class='btzTimeline-btzCard-btzSubTitle btzPageContentSubTitle'>{t('Répondez aux questions ou modifiez vos réponses pour compléter votre profil.', {ns: 'health'})}</p>
            </div>
        </Card.Header>

        <Card.Body>
            <div>
                <Modal content={<ModalView {...props} error={state.error}></ModalView>}>
                    <Button primary active flat style={{ 'color': 'white' }}>
                        {t('Accéder aux questions', {ns: 'health'})}
                    </Button>
                </Modal>
            </div>
        </Card.Body>

        <Card.Footer></Card.Footer>
    </Card>
);

export default Component(state, actions, view, 'health');
