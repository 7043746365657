import { t, h } from '@app/utils';
import { Avatar, Balloon, Button, Form, Tile } from '@app/elements';
import * as staticsconstants from '@app/constants/constants-statics';

module.exports = {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <div style={{ position: 'relative' }}>
                <Tile>
                    <Tile.Content>
                        <p class='btzTitle btzXl' style={{ 'margin-bottom': '0', height: '115px', 'width': '350px', 'margin-left': '60px' }}>
                            {t('Connectez-vous avec', {ns: 'login'})}<br />{t('vos identifiants', {ns: 'login'})}
                        </p>
                    </Tile.Content>
                </Tile>

                <Form onsubmit={event => event.preventDefault()}>

                    <Form.Group>
                        <Form.Input
                            classes={'btzFullWidth'}
                            placeholder={t('adresse@mail.com', {ns: 'login'})}
                            type='email'
                            id='email'
                            name='email'
                            dynResize={true}
                            // value={props.initial.value}
                            oninput={actions.waitForLastInput}
                            oncreate={actions.onCreate}
                            onfocus={actions.onBlur}
                            // onchange={e => console.warn(e.value)}
                            error={state.isTouched.email && !state.isValid.email}
                            classes={(state.isTouched.email) ? (state.isValid.email ? 'is-success' : 'is-error') : ''}
                            required
                        />
                        <div style={{ 'max-width': '300px' }}>
                            <Form.Input
                                classes={'btzFullWidth'}
                                placeholder={t('mot de passe', {ns: 'login'})}
                                type='password'
                                id='password'
                                name='password'
                                dynResize={true}
                                oninput={actions.waitForLastInput}
                                oncreate={actions.onCreate}
                                onfocus={actions.onBlur}
                                onclick={actions.togglePasswordInput}
                                error={state.isTouched.password && !state.isValid.password}
                                classes={(state.isTouched.password) ? (state.isValid.password ? 'is-success' : 'is-error') : ''}
                                required
                            />
                        </div>
                    </Form.Group>

                    <div class='btzDidYouForgetYourPwd'>
                        <Button link block style={{ display: 'block' }} onclick={() => actions.goToNextStep()}>
                            {t('Mot de passe oublié ?', {ns: 'login'})}
                        </Button>
                    </div>

                    <Form.Group classes='btzForm-btzFooter'>
                        <Button primary active={(state.isValid.email && state.isValid.password)} loading={(state.formIsSubmitting)} onclick={actions.onSubmit}>
                            {t('Valider', {ns: 'login'})}
                        </Button>
                    </Form.Group>
                </Form>

                <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap' }}>
                    <Tile.Icon>
                        <Avatar.Alfred kind={state.api.kind} classes={'centered'} />
                    </Tile.Icon>
                    <Tile.Content>
                        <Balloon kind={state.api.kind} title={t('CONNEXION', {ns: 'login'})} atagTitle={(staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()] !== undefined) ? staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()].mailto : '#'} atag={'mailto:' + staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()].mailto} style={{ 'white-space': 'normal' }}>
                            {state.api.message}
                        </Balloon>
                    </Tile.Content>
                </Tile>
            </div>
        );
        return view;
    }
}
