import { t } from '@app/utils'
import { getQueryVariable, isDefined } from '@app/core';
import { Mail } from '@app/api';

import * as staticsconstants from '@app/constants/constants-statics';

export const actions = {
    onComponentCreate: (props) => (state, actions) => {
        let redirectObj = staticsconstants.CUSTOMERS[props.customer].redirect;
        if (isDefined(redirectObj)) {
            let sharedFrom = redirectObj.sharedFrom;
            let gotoLabel = redirectObj.gotoLabel;
            let gotoUrl = redirectObj.gotoUrl;
            if (isDefined(sharedFrom) && (sharedFrom !== '')) {
                actions.setSharedFrom(sharedFrom);
            }
            if (isDefined(gotoLabel) && (gotoLabel !== '')) {
                actions.setGoToLabel(gotoLabel);
            }
            if (isDefined(gotoUrl) && (gotoUrl !== '')) {
                actions.setGoToUrl(gotoUrl);
            }
        }
        //
        let emailVerificationCode = getQueryVariable('emailVerificationCode', false);
        if ((emailVerificationCode === undefined) || (emailVerificationCode === false) || (emailVerificationCode === null) || (emailVerificationCode === '')) {
            actions.setEmail(t('veuillez fournir un code de vérification.', {ns: 'public'}));
            return false;
        }
        Mail.confirmation(emailVerificationCode).then((res) => {
            if (res.data && res.data.email) {
                actions.setEmail(res.data.email);
                actions.setEmailConfirmed(true);
            } else {
                actions.setEmail(t('adresse email déjà confirmée.', {ns: 'public'}));
                actions.setEmailConfirmed(false);
            }
        });
    },
    setEmailConfirmed: newState => state => ({
        emailConfirmed: newState,
    }),
    setEmail: newState => state => ({
        email: newState,
    }),
    setCustomerSsoName: newState => state => ({
        customerSsoName: newState,
    }),
    setSharedFrom: newState => state => ({
        sharedFrom: newState,
    }),
    setGoToLabel: newState => state => ({
        goToLabel: newState,
    }),
    setGoToUrl: newState => state => ({
        goToUrl: newState,
    }),
};