import { t, h, i18n } from '@app/utils';
import { Link } from '@app/elements';

import imgTopBannerBHT from '@app/img/logo/logo-bt-health-tech.png';
import imgTopBannerBHTx2 from '@app/img/logo/logo-bt-health-tech@2x.png';

import './index.scss';

export const TopBannerView = (props, actions) => (
    <div>
        {((props.customer === 'betterise') && (props.browserlang !== undefined)) && (
            <div id='btzTopViewBanner'>
                <div class='btzTopViewBanner-wrapper'>
                    <img src={imgTopBannerBHT} srcset={`${imgTopBannerBHTx2} 2x`} alt='' class='' />
                    <div class='btzTopViewBanner-wrapperContent'>
                        <p class='btzTopViewBanner-wrapperContent-title'>{t('Un produit', {ns: 'generals'}) + ' '}<font>{t('Betterise Health Tech', {ns: 'generals'})}</font></p>
                        <p class='btzTopViewBanner-wrapperContent-description'>{t('Pour construire votre service digital en santé et mieux suivre vos patients, découvrez notre plateforme et tous nos produits', {ns: 'generals'}) + ' '}</p>
                        {i18n.language === 'fr' ?
                            <Link class={'btn btn-link'} to={'https://betterise-healthtech.com/fr/'}>{t('en cliquant ici', {ns: 'generals'}) + '.'}</Link>
                            :
                            <Link class={'btn btn-link'} to={'https://betterise-healthtech.com/us/'}>{t('en cliquant ici', {ns: 'generals'}) + '.'}</Link>
                        }
                    </div>
                </div>
            </div>
        )}
    </div>
);