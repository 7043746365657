import { h, Component } from '@app/utils';
import { getUser, dynLoad } from '@app/core';
import './index.scss';

const state = {
    userMedia: null,
    userThemes: [],
    informationDisplayed: true,
}

const actions = {
    onComponentCreate: () => (state, actions) => {
        let userCallback = (sessionUser) => {
            actions.setUserMedia((sessionUser.media !== null) ? sessionUser.media.url : '');
            actions.setUserThemes(sessionUser.themes);
        };
        getUser(userCallback);

        let removeProgramInformation = localStorage.getItem('removeProgramInformation');
        if ((removeProgramInformation !== null) && (removeProgramInformation !== undefined) && (removeProgramInformation !== '')) {
            if (removeProgramInformation == 'true') {
                actions.removeInformation();
            }
        }
    },
    setUserMedia: newState => state => ({
        userMedia: newState,
    }),
    setUserThemes: newState => state => ({
        userThemes: newState,
    }),
    removeInformation: () => state => ({
        informationDisplayed: false,
    }),
    noMoreInformation: () => (state, actions) => {
        let newbool = true;
        let existingbool = localStorage.getItem('removeProgramInformation');
        if ((existingbool !== null) && (existingbool !== undefined) && (existingbool !== '')) {
            newbool = (existingbool == 'true' ? false : true);
        }
        localStorage.setItem('removeProgramInformation', newbool);
    }
};

const view = dynLoad('view', 'Programs/views');
export default Component(state, actions, view, 'programs');