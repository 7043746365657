const getTodayYMD = function() {
    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();

    if (+day < 10) {
        day = '0' + day;
    }
    if (+month < 10) {
        month = '0' + month;
    }

    return (year + '' + month + '' + day);
}

export { getTodayYMD };