import { h } from '@app/utils';
import { Card } from '@app/elements';

import icoAskWhite from '@app/img/ico/ico-ask-white.png';
import icoAskWhitex2 from '@app/img/ico/ico-ask-white@2x.png';

export const view = (state, actions) => (props, children) => (
    <div>
        {props.title !== '' &&
            <div class='btzObjectives-btzObjective' data-mode={props.mode} onclick={() => {
                if (props.displayedon !== 'user') {
                    props.chooseObjectif(props)
                }}
            }>
                <div class='col-3' style={{ 'vertical-align': 'middle', 'padding-right': '30px', 'padding-left': '0px' }}>
                    {props.media !== null &&
                        <Card.Image style={{ 'float': 'right' }}><img src={props.media.url} alt='' class='' /></Card.Image>
                    }
                </div>
                <div class='col-9 p-0' style={{ 'vertical-align': 'middle', 'text-align': 'left' }}>
                    <p class='btzPageContentSubTexte btzXl'>{props.title}</p>
                    {((props.displayedon === 'user') && (props.date !== undefined)) &&
                        <p class='btzTimeline-btzCard-btzSubTitle btzPageContentSubTitle' oncreate={(el) => actions.getDate({'el' : el, 'dat' : props.date.date}) }></p>
                    }
                </div>
            </div>
        }
    </div>
);