import {
    Login,
    Register,
    About,
    Developer,
    Enterprise,
    Home,
    Scientific,
    Technology,
    Onboarding,
    OnboardingEnd,
    OnboardingDone,
    Timeline,
    Food,
    Datas,
    Follow,
    Programs,
    ProgramStart,
    ProgramSession,
    Account,
    Favorites,
    Objectives,
    Oc,
    Parameter,
    Rewards,
    Abonnement,
    Cb,
    Health,
    Informations,
    Resiliate,
    Sponsor,
    PublicSurvey,
    PublicSurveyObjective,
    PublicSurveyResiliate,
    PublicMaintenance,
    PublicExpire,
    PublicExpireSponsor,
    PublicSubscription,
    PublicBlocked,
    PublicAuthorize,
    PublicEmailConfirm,
    PublicEmailShow,
    PublicPasswordReset,
    PublicSecret,
    PublicShare,
    PublicResiliate,
    PublicCb,
    PublicObjectifs,
    TransitWebToApp,
} from '@app/modules';

const allRoutes = {
    'index': {
        label: 'Accueil',
        pathname: '/',
        component: Home,
        parent: false,
    },
    'home.index': {
        label: 'Accueil',
        pathname: '/home',
        component: Home,
        parent: false,
    },
    'home.login.medical': {
        label: 'Connexion',
        pathname: '/medical-study/:medicalStudytoken/:timelineId/',
        component: Login,
        parent: true,
    },
    'home.login': {
        label: 'Connexion',
        pathname: '/home/login',
        component: Login,
        parent: true,
    },
    'home.login.recovery': {
        label: 'Recovery',
        pathname: '/home/login/recovery',
        component: Login,
        parent: true,
    },
    'home.register': {
        label: 'S\'inscrire',
        pathname: '/home/register',
        component: Register,
        parent: true,
    },
    'home.technology': {
        label: 'Technologie',
        pathname: '/home/tech',
        component: Technology,
        parent: false,
    },
    'home.scientific': {
        label: 'Scientifique',
        pathname: '/home/scien',
        component: Scientific,
        parent: false,
    },
    'home.enterprise': {
        label: 'Entreprise',
        pathname: '/home/entre',
        component: Enterprise,
        parent: false,
    },
    'home.about': {
        label: 'À propos',
        pathname: '/home/apropos',
        component: About,
        parent: false,
    },
    'home.developer': {
        label: 'Developpeur',
        pathname: '/home/dev',
        component: Developer,
        parent: false,
    },
    'private.onboarding': {
        label: 'Onboarding',
        pathname: '/onboarding',
        component: Onboarding,
        parent: false
    },
    'private.endonboarding': {
        label: 'Onboarding Terminé',
        pathname: '/onboarding/end',
        component: OnboardingEnd,
        parent: false
    },
    'private.doneonboarding': {
        label: 'Onboarding Déjà Répondu',
        pathname: '/onboarding/done',
        component: OnboardingDone,
        parent: false
    },
    'private.timeline': {
        label: 'Timeline',
        pathname: '/timeline',
        component: Timeline,
        parent: false
    },
    'private.food': {
        label: 'Repas',
        pathname: '/repas',
        component: Food,
        parent: false
    },
    'private.datas': {
        label: 'Données',
        pathname: '/datas',
        component: Datas,
        parent: false
    },
    'private.follow': {
        label: 'Données',
        pathname: '/eliott/follow',
        component: Follow,
        parent: false
    },
    'private.programs': {
        label: 'Programmes',
        pathname: '/programs',
        component: Programs,
        parent: false
    },
    'private.program.start': {
        label: 'Programmes',
        pathname: '/program/:programid',
        component: ProgramStart,
        parent: false
    },
    'private.program.session': {
        label: 'Programmes Session',
        pathname: '/program/:programid/session',
        component: ProgramSession,
        parent: false
    },
    'private.account': {
        label: 'Compte',
        pathname: '/account',
        component: Account,
        parent: false
    },
    'private.what.is.klesia_netflix': {
        label: 'Klesia Netflix',
        pathname: '/what/is/klesianetflix',
        component: null,
        parent: false
    },
    'account.favorites': {
        label: 'Favoris',
        pathname: '/account/favorites',
        component: Favorites,
        parent: false
    },
    'account.objectives': {
        label: 'Objectifs',
        pathname: '/account/objectives',
        component: Objectives,
        parent: false
    },
    'account.oc': {
        label: 'Objects Connectés',
        pathname: '/account/oc',
        component: Oc,
        parent: false
    },
    'account.parameters': {
        label: 'Paramétres',
        pathname: '/account/parameters',
        component: Parameter,
        parent: false
    },
    'account.rewards': {
        label: 'Récompenses',
        pathname: '/account/rewards',
        component: Rewards,
        parent: false
    },
    'parameters.abonnement': {
        label: 'Abonnement',
        pathname: '/parameter/abonnement',
        component: Abonnement,
        parent: false
    },
    'parameters.cb': {
        label: 'Carte bancaire',
        pathname: '/parameter/cb',
        component: Cb,
        parent: false
    },
    'parameters.health': {
        label: 'Votre profil santé',
        pathname: '/parameter/health',
        component: Health,
        parent: false
    },
    'parameters.informations': {
        label: 'Informations',
        pathname: '/parameter/informations',
        component: Informations,
        parent: false
    },
    'parameters.resiliate': {
        label: 'Résiliation',
        pathname: '/parameter/resiliate',
        component: Resiliate,
        parent: false
    },
    'parameters.sponsor': {
        label: 'Parrainage',
        pathname: '/parameter/sponsor',
        component: Sponsor,
        parent: false
    },
    'public.survey': {
        label: 'Enquête',
        pathname: '/public/survey',
        component: PublicSurvey,
        parent: false
    },
    'public.survey.objective': {
        label: 'Impressions Changement d’objectifs',
        pathname: '/public/survey/objective',
        component: PublicSurveyObjective,
        parent: false
    },
    'public.survey.resiliate': {
        label: 'Impressions résiliation',
        pathname: '/public/survey/resiliate',
        component: PublicSurveyResiliate,
        parent: false
    },
    'public.maintenance': {
        label: 'Maintenance',
        pathname: '/public/maintenance',
        component: PublicMaintenance,
        parent: false
    },
    'public.expire': {
        label: 'Impressions résiliation',
        pathname: '/public/expire',
        component: PublicExpire,
        parent: false
    },
    'public.expire.sponsor': {
        label: 'Impressions résiliation',
        pathname: '/public/expire/sponsor',
        component: PublicExpireSponsor,
        parent: false
    },
    'public.subscription': {
        label: 'Impressions résiliation',
        pathname: '/public/subscription',
        component: PublicSubscription,
        parent: false
    },
    'public.blocked': {
        label: 'Impressions résiliation',
        pathname: '/public/blocked',
        component: PublicBlocked,
        parent: false
    },
    'public.authorize': {
        label: 'Autorisation',
        pathname: '/public/authorize',
        component: PublicAuthorize,
        parent: false
    },
    'public.email.confirm': {
        label: 'Impressions résiliation',
        pathname: '/public/email/confirm',
        component: PublicEmailConfirm,
        parent: false
    },
    'public.email.show': {
        label: 'Aperçu email',
        pathname: '/public/email/show/:emailid',
        component: PublicEmailShow,
        parent: false
    },
    'public.password.reset': {
        label: 'Impressions résiliation',
        pathname: '/public/password/reset',
        component: PublicPasswordReset,
        parent: false
    },
    'public.secret': {
        label: 'Votre question secréte',
        pathname: '/public/secret',
        component: PublicSecret,
        parent: false
    },
    'public.share': {
        label: 'Partage Public',
        pathname: '/public/share/:shareid',
        component: PublicShare,
        parent: false
    },
    'public.resiliate': {
        label: 'Impressions résiliation',
        pathname: '/public/resiliate',
        component: PublicResiliate,
        parent: false
    },
    'public.transit.webtoapp': {
        label: 'Transition vers App',
        pathname: '/transit/webtoapp',
        component: TransitWebToApp,
        parent: false
    },
    'public.transit.objectives': {
        label: 'Transition vers Objectifs',
        pathname: '/transit/objectifs',
        component: PublicObjectifs,
        parent: false
    },
    'public.cb': {
        label: 'Paiement Invalid',
        pathname: '/public/cb',
        component: PublicCb,
        parent: false
    },
    'pdf.cga': {
        label: 'Voir les CGA',
        pathname: '/__pdf/pdf-cga.pdf',
        component: null,
        parent: false
    },
    'pdf.cgu': {
        label: 'Voir les CGU',
        pathname: '/__pdf/pdf-cgu.pdf',
        component: null,
        parent: false
    },
    'pdf.iver.cgu': {
        label: 'Voir les CGU',
        pathname: '/__pdf/iver/iver_cgu.pdf',
        component: null,
        parent: false
    },
    'pdf.partena.cgu': {
        label: 'Voir les CGU',
        pathname: '/__pdf/partena/partena_cgu.pdf',
        component: null,
        parent: false
    },
    'pdf.auchan.cgu': {
        label: 'Voir les CGU',
        pathname: '/__pdf/pdf-auchan-cgu-:lang.pdf',
        component: null,
        parent: false
    },
    'pdf.cerba.cgu': {
        label: 'Voir les CGU',
        pathname: '/__pdf/cerba/cerba-cgu.pdf',
        component: null,
        parent: false
    },
    'pdf.cerba.fizix': {
        label: 'Voir les CGU fizix',
        pathname: '/__pdf/cerba/fizix-presentation.pdf',
        component: null,
        parent: false
    },
    'pdf.cerba.gymlib': {
        label: 'Voir les CGU gymlib',
        pathname: '/__pdf/cerba/gymlib-presentation.pdf',
        component: null,
        parent: false
    },
    'pdf.cerba.gymlib.tutoinscription': {
        label: 'Voir le tutoriel d‘inscription gymlib',
        pathname: '/__pdf/cerba/gymlib-tuto-inscription.pdf',
        component: null,
        parent: false
    },
    'pdf.vyv.cgu': {
        label: 'Voir les CGU',
        pathname: '/__pdf/vyv/pdf-cgu.pdf',
        component: null,
        parent: false
    },
    'pdf.coronavirus.cgu': {
        label: 'Voir les CGU',
        pathname: '/__pdf/coronavirus/coachcov-cgu.pdf',
        component: null,
        parent: false
    },
};

export { allRoutes };
