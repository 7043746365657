import { t, h } from '@app/utils';
import { Button, Card, Social, Modal, SimpleChart, Trim, Media, Convert } from '@app/elements';
import { isDefined, convertObjectToArray } from '@app/core';

import { ModalViewRecipe } from './modalView';

export const view = (state, actions) => (props, children) => (
    <Card kind={'recipe'} theme={props.valueTheme}>
        {props.displayedon == 'timeline' &&
            <div>
                <Card.Header>
                    <Social kindcard={'recipe'} displayedon={'timeline'} title={props.recipe.title} idfavorite={props.favoriteId} idtimeline={props.timeline} id={props.recipe.id} optfavid={props.id} favorite={state.favorite} render={actions.rendering} propagate={actions.updateParentProps}></Social>
                    {props.recipe.media !== undefined &&
                        <Card.Image kind={props.recipe.media}><Media src={props.recipe.media}></Media></Card.Image>
                    }
                    <p class='btzTimeline-btzCard-btzTitle btzTitle'>{props.recipe.title}</p>
                    <p class='btzTimeline-btzCard-btzSubTitle btzSubTitle btzTextColored' data-theme={'cooking'}>{t('Recette de cuisine', {ns: 'timeline'})}</p>
                </Card.Header>

                <Card.Body classes='btzTopCustomDotted'>
                    <p class='btzTimeline-btzCard-btzDescription btzTexte'><Trim char={250}>{props.recipe.description}</Trim></p>
                    {isDefined(state.detail) &&
                        <div class='btzTimeline-btzRecipe-btzInfos'>
                            <div class='btzTimeline-btzRecipe-btzInfos-btzWrapper'>
                                <SimpleChart value={state.detail.making.value} unit={state.detail.making.unit} values={state.detail.making.values} color={'#FF6F41'}></SimpleChart>
                                <p class='btzLightTexte'>{t('préparation', {ns: 'timeline'})}</p>
                            </div>
                            <div class='btzTimeline-btzRecipe-btzInfos-btzWrapper'>
                                <SimpleChart value={state.detail.baking.value} unit={state.detail.baking.unit} values={state.detail.baking.values} color={'#FF6F41'}></SimpleChart>
                                <p class='btzLightTexte'>{t('cuisson', {ns: 'timeline'})}</p>
                            </div>
                            <div class='btzTimeline-btzRecipe-btzInfos-btzWrapper'>
                                <SimpleChart value={state.detail.rest.value} unit={state.detail.rest.unit} values={state.detail.rest.values} color={'#FF6F41'}></SimpleChart>
                                <p class='btzLightTexte'>{t('repos', {ns: 'timeline'})}</p>
                            </div>
                        </div>
                    }
                </Card.Body>
                <Card.Footer>
                    <Modal content={<ModalViewRecipe {...state.detail} id={props.recipe.id} optfavid={props.id} setTag={actions.setTag} alimentDetailDisplayed={state.alimentDetailDisplayed} favorite={state.favorite} propagate={actions.updateParentProps} displayedon={'timeline'} tagaliment={state.tagaliment} displayAlimentDetail={actions.displayAlimentDetail} detailAliment={state.detailAliment} setDetailAliment={actions.setDetailAliment} kindOfInfos={state.kindOfInfos} setKindOfInfos={actions.setKindOfInfos} scrollTop={actions.scrollTop}></ModalViewRecipe>}>
                        <Button primary data-id={props.id} data-recipeid={props.recipe.id} onclick={actions.getDetailRecipe} >
                            <p class='btzTextColored' data-theme={'cooking'}>{t('LIRE', {ns: 'timeline'})}</p>
                        </Button>
                    </Modal>
                </Card.Footer>
            </div>
        }

        {props.displayedon == 'favorite' &&
            <Modal modalDisplayed={state.modalDisplayed} content={<ModalViewRecipe {...state.detail} setTag={actions.setTag} alimentDetailDisplayed={state.alimentDetailDisplayed} favorite={state.favorite} propagate={props.propagate} displayedon={'favorite'} tagaliment={state.tagaliment} displayAlimentDetail={actions.displayAlimentDetail} detailAliment={state.detailAliment} setDetailAliment={actions.setDetailAliment} kindOfInfos={state.kindOfInfos} setKindOfInfos={actions.setKindOfInfos} scrollTop={actions.scrollTop} propagateConfirmClosing={() => actions.setModalDisplayed(false)}></ModalViewRecipe>}>
                <Button primary data-id={props.id} data-recipeid={props.recipe.id} onclick={actions.getDetailRecipe} >
                    <div>
                        <Card.Header>
                            {props.recipe.media !== undefined &&
                                <Card.Image kind={props.recipe.media}><Media src={props.recipe.media}></Media></Card.Image>
                            }
                            <p class='btzTimeline-btzCard-btzTitle btzTitle'>{props.recipe.title}</p>
                            <p class='btzTimeline-btzCard-btzSubTitle btzSubTitle btzTextColored' data-theme={'cooking'}>{t('Recette de cuisine', {ns: 'timeline'})}</p>
                            <p class='btzLightTexte' oncreate={(el) => actions.getDate({'el' : el, 'dat' : (isDefined(props.date) ? props.date.date : null)}) }></p>
                        </Card.Header>
                    </div>
                </Button>
            </Modal>
        }

        {props.displayedon == 'share' &&
            <div>
                <Card.Header classes={'btzCard-btzHeader-btzModal'}>
                    {props.recipe.media !== undefined &&
                        <Card.Image kind={props.recipe.media}><Media src={props.recipe.media}></Media></Card.Image>
                    }
                    <p class='btzTimeline-btzCard-btzTitle btzTitle'>{props.recipe.title}</p>
                    {((props.customer === undefined) || ((props.customer && (props.customer !== 'maternite')))) ?
                        <p class='btzTimeline-btzCard-btzSubTitle btzSubTitle btzTextColored' data-theme={'cooking'}>{t('Recette de cuisine', {ns: 'timeline'})}</p>
                        :
                        <div class='btzTimeline-btzCard-btzTimes'>
                            <div class='btzTimeline-btzCard-btzTime'>
                                <div class='btzTimeline-btzCard-btzRecipe-btzIngredientPuce btzSmallOne'></div>
                                <p class='btzLightTexte'>{t('préparation', {ns: 'timeline'}) + ' : ' + props.recipe.making.value + props.recipe.making.unit}</p>
                            </div>
                            <div class='btzTimeline-btzCard-btzTime'>
                                <div class='btzTimeline-btzCard-btzRecipe-btzIngredientPuce btzSmallOne'></div>
                                <p class='btzLightTexte'>{t('cuisson', {ns: 'timeline'}) + ' : ' + props.recipe.baking.value + props.recipe.baking.unit}</p>
                            </div>
                            <div class='btzTimeline-btzCard-btzTime'>
                                <div class='btzTimeline-btzCard-btzRecipe-btzIngredientPuce btzSmallOne'></div>
                                <p class='btzLightTexte'>{t('repos', {ns: 'timeline'}) + ' : ' + props.recipe.rest.value + props.recipe.rest.unit}</p>
                            </div>
                        </div>
                    }
                </Card.Header>

                <Card.Body classes={'btzCard-btzBody-btzModal btzDottedSpaced btzTopCustomDotted'} style={{ 'padding-bottom': '70px' }}>
                    <p class='btzTimeline-btzCard-btzDescription btzTexte'>{props.recipe.description}</p>

                    {((props.customer === undefined) || ((props.customer && (props.customer !== 'maternite')))) &&
                        <div class='btzTimeline-btzRecipe-btzInfos'>
                            <div class='btzTimeline-btzRecipe-btzInfos-btzWrapper'>
                                {props.recipe.making !== undefined &&
                                    <SimpleChart value={props.recipe.making.value} unit={props.recipe.making.unit} values={props.recipe.rest.values} color={'#FF6F41'}></SimpleChart>
                                }
                                <p class='btzLightTexte'>{t('préparation', {ns: 'timeline'})}</p>
                            </div>
                            <div class='btzTimeline-btzRecipe-btzInfos-btzWrapper'>
                                {props.recipe.baking !== undefined &&
                                    <SimpleChart value={props.recipe.baking.value} unit={props.recipe.baking.unit} values={props.recipe.rest.values} color={'#FF6F41'}></SimpleChart>
                                }
                                <p class='btzLightTexte'>{t('cuisson', {ns: 'timeline'})}</p>
                            </div>
                            <div class='btzTimeline-btzRecipe-btzInfos-btzWrapper'>
                                {props.recipe.rest !== undefined &&
                                    <SimpleChart value={props.recipe.rest.value} unit={props.recipe.rest.unit} values={props.recipe.rest.values} color={'#FF6F41'}></SimpleChart>
                                }
                                <p class='btzLightTexte'>{t('repos', {ns: 'timeline'})}</p>
                            </div>
                        </div>
                    }

                    <div>
                        <div class='btzTimeline-btzCard-btzTimeline-btzRecipe-btzTimeline-btzTitles'>
                            <p class='btzTimeline-btzCard-btzTitle btzTitle btzTextColored btzXl' data-theme={'cooking'}>{t('Ingrédients', {ns: 'timeline'})}</p>
                            {((props.customer === undefined) || ((props.customer && (props.customer !== 'maternite')))) ?
                                <dummy></dummy>
                                :
                                <p class='btzTimeline-btzCard-btzTitle btzTimeline-btzCard-btzTitle-persons' data-theme={'cooking'}>{t('pour', {ns: 'timeline'}) + ' ' + props.recipe.persons + ' ' + t('personnes', {ns: 'timeline'})}</p>
                            }
                            <p class='btzTimeline-btzCard-btzTexte btzTexte' innerHTML={<Convert separator={((props.customer === undefined) || ((props.customer && (props.customer !== 'maternite')))) ? '- ' : "<div class='btzTimeline-btzCard-btzRecipe-btzIngredientPuce btzSmallOne'></div>"} is_xhtml={true} type='nl2br'>{props.recipe.ingredients}</Convert>}></p>
                        </div>
                        <div class='btzTimeline-btzCard-btzTimeline-btzRecipe-btzTimeline-btzTitles'>
                            <p class='btzTimeline-btzCard-btzTitle btzTitle btzTextColored btzXl' data-theme={'cooking'}>{t('Préparation', {ns: 'timeline'})}</p>
                            <div class='btzTimeline-btzCard-btzRecipe-btzIngredients'>
                                {props.recipe.steps !== undefined &&
                                    <div>
                                        {convertObjectToArray(props.recipe.steps).sort((a, b) => +a.order - +b.order).filter((f) => (f.content !== '')).map((item, idx) =>
                                            <div class='btzTimeline-btzCard-btzRecipe-btzIngredient' key={item.id}>
                                                <div class='col-2 p-0'>
                                                    <div class='btzTimeline-btzCard-btzRecipe-btzIngredientPuce'>{(idx + 1)}</div>
                                                </div>
                                                <div class='col-10'>
                                                    <p class='btzTimeline-btzCard-btzTexte btzTexte'>{item.content}</p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                }
                            </div>
                        </div>
                        <div class='btzTimeline-btzCard-btzTimeline-btzRecipe-btzTimeline-btzTitles'>
                            {props.recipe.alternative !== '' &&
                                <div class=''>
                                    <p class='btzTimeline-btzCard-btzTitle btzTitle btzTextColored btzXl' data-theme={'cooking'}>{t('Variante', {ns: 'timeline'})}</p>
                                    <p class='btzTimeline-btzCard-btzTexte btzTexte' innerHTML={props.recipe.alternative}></p>
                                </div>
                            }
                        </div>
                    </div>
                </Card.Body>
            </div>
        }
    </Card>
);
