import { appCustoSetting } from '@app/settings/settings';
import { queueQueries } from '@app/api/controlleur';
const { API_URL } = appCustoSetting.appsettings;

/**
 * Calls API to retrieve city by name
 * @param {string} [country='fr'] Two letters country code
 * @param {string} [name=''] City name
 * @return Response object
 */
function getCitiesByName(country = 'fr', name = '') {
    return queueQueries(`${API_URL}/city/${country}/${name}/`, 'GET')
}

function getCitiesByLatLng(lat, lng) {
    return queueQueries(`${API_URL}/city/geolocation/${lat}/${lng}/`, 'GET')
}

export const City = {
    getCitiesByName,
    getCitiesByLatLng,
};
