import { t, h } from '@app/utils';
import { Tile, Modal, Form, Button } from '@app/elements';

import imgObjectifs from './img-objectifs.png';
import imgObjectifsx2 from './img-objectifs@2x.png';

import PublicLayout from '@app/layouts/public/layout';
import { ModalView } from './modalView';

export const view = (state, actions) => (props, children) => (
    <div key='publicobjectifs'>
        <PublicLayout page='webtoapp' customer={state.customerSsoName}>
            <div class='publicobjectifs-objectifs'>
                <div class='btzHeader-btzShadowHeader' style={{ position: 'relative' }}>
                    <Tile>
                        <div>
                            <div class='btzImage-btzAbsoluteImage'>
                                <img src={imgObjectifs} srcset={`${imgObjectifsx2} 2x`} alt='' class='' />
                            </div>
                            <Tile.Content classes='col-12'>
                                <p class='btzTitle btzXl'>{t('Choisissez vos nouveaux objectifs santé !', {ns: 'public'})}</p>
                                <p class='btzTexte'>{t('Nous vous l’avions annoncé précédement par email, l’un des principaux changements de cette nouvelle version est : les objectifs !', {ns: 'public'})}</p>
                                <p class='btzTexte'>{t('Vous pouvez maintenant choisir quels objectifs santé vous souhaitez suivre pour continuer à prendre soin de votre santé !', {ns: 'public'})}</p>
                            </Tile.Content>
                        </div>
                    </Tile>
                </div>

                <Form>
                    <Form.Group classes='btzForm-btzFooter'>
                        <Modal modalDisplayed={state.modalDisplayed} cancelAction={actions.cancelObjectivesModifications} content={<ModalView {...props} objectives={state.objectives.all} error={state.error} info={state.info} chooseObjectif={actions.chooseThisObjectif} confirmObjectifRules={actions.confirmTheseObjectifRules} updateError={() => {actions.setError('');}}></ModalView>}>
                            <Button colored primary style={{ 'color': 'white' }} onclick={() => {
                                actions.setModalDisplayed(true);
                            }}>
                                {t('Sélectionnez vos objectifs santé', {ns: 'public'})}
                            </Button>
                        </Modal>
                    </Form.Group>
                </Form>
            </div>
        </PublicLayout>
    </div>
);