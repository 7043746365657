import { appCustoSetting } from '@app/settings/settings';
import { queueQueries } from '@app/api/controlleur';
const { API_URL } = appCustoSetting.appsettings;

function getUser() {
    return queueQueries(`${API_URL}/user`, 'GET');
}

function getAppointments() {
    return queueQueries(`${API_URL}/cerba/appointments`, 'GET');
}

function getAppointmentsByType(type) {
    return queueQueries(`${API_URL}/cerba/appointments/${type}`, 'GET');
}

function getAppointmentsByTypeAndPeriod(type, start, end) {
    return queueQueries(`${API_URL}/cerba/appointments/${type}/${start}/${end}`, 'GET');
}

function getAppointmentsById(id) {
    return queueQueries(`${API_URL}/cerba/appointment/${id}`, 'GET');
}

function setAppointment(type, date) {
    return queueQueries(`${API_URL}/cerba/appointment`, 'POST', 'application/json', true, true, true, true, true, {type: type, date: date});
}

/*function setSurvey(type, date) {
    queueQueries(`${API_URL}/cerba/appointment`, 'POST', 'application/json', true, true, true, true, true, {type: type, date: date});
}*/

export const Follow = {
    getUser,
    getAppointments,
    getAppointmentsByType,
    getAppointmentsByTypeAndPeriod,
    getAppointmentsById,
    setAppointment
};
