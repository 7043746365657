import { t, h } from '@app/utils';
import { Button, Tile, Avatar, Balloon, Form } from '@app/elements';
import { StripeWrapper } from '@app/modules/Register/step-payment/stripeWrapper';

import logoCb from '@app/img/public/cb/you-are-paying.png';
import logoCbx2 from '@app/img/public/cb/you-are-paying@2x.png';

import PublicLayout from '@app/layouts/public/layout';

export const view = (state, actions) => (props, children) => (
    <div key='publiccb'>
        <PublicLayout page='cb' customer={props.customer}>
            <div class='publiccb-cb'>
                <div style={{ position: 'relative' }}>
                    <Tile style={{ 'padding-bottom': '120px' }}>
                        <div class='btzTitle-btzImage btzTitle-btzImage-absolute col-md-12 col-4'>
                            <img src={logoCb} srcset={`${logoCbx2} 2x`} alt='' class='' />
                        </div>
                        <Tile.Content classes='btzLeftHrArrowed col-xs-12 col-8'>

                            {state.cbFormDisplay === false ?
                                <div>
                                    <p class='btzTitle btzXl'>{t('Une mise à jour de vos données bancaires est nécessaire', {ns: 'cb'})}</p>
                                    <p class='btzTexte' style={{ 'margin': '0 0 10px 0' }}>{t('Il peut il y avoir plusieurs raisons à cela', {ns: 'cb'}) + ' :'}</p>
                                    <div class='btzCustomUl-btzUl'>
                                        <div>
                                            <div class='btzCustomUl-btzLi'>{t('votre carte bancaire est expirée', {ns: 'cb'})}</div>
                                            <div class='btzCustomUl-btzLi'>{t('vous avez stoppé les prélèvements Betterise', {ns: 'cb'})}</div>
                                            <div class='btzCustomUl-btzLi'>{t('un problème technique - contactez', {ns: 'cb'}) + ' ' + t('alfred@betterise.me', {ns: 'public'})}</div>
                                        </div>
                                    </div>

                                    <div class='' style={{ 'margin': '30px 0' }}>
                                        <p class=''>
                                            {t('Si vous faites partie des deux premiers cas de figure, cliquez sur le bouton ci-dessous pour effectuer la mise à jour de vos informations bancaires.', {ns: 'cb'})}
                                        </p>
                                    </div>

                                    <Button flat active onclick={() => {
                                        actions.displayCbForm();
                                        actions.setApiErrors({
                                            message: t('Renseignez un moyen de paiement.', {ns: 'cb'}),
                                            kind: 'info',
                                        })
                                    }}>{t('Mettre à jour', {ns: 'generals'})}</Button>
                                </div>
                                :
                                <div>
                                    <p class='btzTitle btzXl' style={{ 'max-width': '360px' }}>{t('Renseignez vos coordonnées bancaires.', {ns: 'cb'})}</p>
                                    <Form onsubmit={event => event.preventDefault()}>
                                        <div key={'nochange'}>
                                            <StripeWrapper customActions={actions} stripe={state.stripe} />
                                        </div>

                                        <Form.Group classes='btzForm-btzFooter'>
                                            <Button primary block active={((state.allFieldsAreValid === true) && (!state.ensureOneSubmit))} onclick={(event) => {
                                                actions.onStepSubmit({event: event, props: props});
                                            }}>
                                                {t('Valider', {ns: 'generals'})}
                                            </Button>
                                        </Form.Group>
                                    </Form>
                                </div>
                            }

                            <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap' }}>
                                <Tile.Icon>
                                    <Avatar.Alfred classes={'centered'} />
                                </Tile.Icon>
                                <Tile.Content>
                                    <Balloon kind={state.apiErrorsStatus} style={{ 'white-space': 'normal' }}>
                                        {state.apiErrors}
                                    </Balloon>
                                </Tile.Content>
                            </Tile>
                        </Tile.Content>
                    </Tile>
                </div>
            </div>
        </PublicLayout>
    </div>

);