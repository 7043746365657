import { t, h } from '@app/utils';

import PageLayout from '@app/layouts/page/splitted/programs/layout';

import * as staticsconstants from '@app/constants/constants-statics';

import All from './../all';
import Recommanded from './../recommanded';
import Running from './../running';

module.exports = {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <PageLayout page='programs' readablePageLabel={t(staticsconstants.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()].menuNames.programs.title)} readablePageDescription={t('Pour vous aider à atteindre vos objectifs, jour après jour', {ns: 'programs'})} userMedia={state.userMedia} customer={props.customer}>
                <Running></Running>
                <Recommanded></Recommanded>
                <All userThemes={state.userThemes} customer={props.customer}></All>
            </PageLayout>
        );
        return view;
    }
}
