import { t, h, Component } from '@app/utils';
import { addCSRFToken } from '@app/core';
import { allRoutes } from '@app/settings';
import { User } from '@app/api';
import { Button } from '@app/elements';
import './index.scss';

import icoCloseWhite from '@app/img/ico/ico-close-white-big.png';
import icoCloseWhitex2 from '@app/img/ico/ico-close-white-big@2x.png';

import bgStarsMediax2 from './deco-notif-media@2x.png';
import bgStarsPointsx2 from './deco-notif-points@2x.png';

import bgFlowerLevel from './deco-notif-lvlup@2x.png';
import bgFlowerLevelx2 from './deco-notif-lvlup@2x.png';

import imgClose from './ico-close-black-big.png';
import imgClosex2 from './ico-close-black-big@2x.png';

import starsLevelx2 from './stars-notif-lvlup@2x.png';


const GamifNotifications = Component(
    {
        notifications: [],
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.location && (props.location.state !== undefined) && (props.location.state.pathname.indexOf('public') > -1)) {
                return;
            }
            //
            window.addEventListener('displayGamifNotification', () => {
                User.getNotifications().then((res) => {
                    let notifications = res.data;
                    let lastNotifications = [];
                    for (var notif in notifications) {
                        lastNotifications.push(notifications[notif]);
                    }
                    actions.pendNotifications(lastNotifications);
                    // actions.setNotifications(lastNotifications);
                });
            }, false);

            // let temp = [{
            //    "id":"54579640c5057534068b4563",
            //    "date":{
            //        "date":"2014-11-03 14:50:40",
            //        "timezone_type":3,
            //        "timezone":"UTC"
            //    },
            //    "message":"Vous avez répondu à toutes les questions",
            //    "type":"ACHIEVEMENT",
            //    "media": {
            //        "id": "5819b4496fcf760c538b4d3e",
            //        "type": "image/jpeg",
            //        "length": "1121319",
            //        "url": "http://betterise-api.local/medias/5819b4496fcf760c538b4d3e"
            //    },
            //    "points": 5,
            //    "totalPoints": 3045,
            //    "badges": 6,
            //    "level": 2,
            //    }, {
            //    "id":"54579640c5057534068b4569",
            //    "date":{
            //        "date":"2014-11-03 14:50:40",
            //        "timezone_type":3,
            //        "timezone":"UTC"
            //    },
            //    "message":"Vous avez répondu à toutes les questions",
            //    "type":"ACHIEVEMENT",
            //    "media": {
            //        "id": "5819b4496fcf760c538b4d3e",
            //        "type": "image/jpeg",
            //        "length": "1121319",
            //        "url": "http://betterise-api.local/medias/5819b4496fcf760c538b4d3e"
            //    },
            //    "points": 5,
            //    "totalPoints": 3045,
            //    "badges": 6,
            //    "level": 2,
            //    }];
            // actions.pendNotifications(temp);
            //actions.setNotifications(temp);
        },
        pendNotifications: (notifications) => (state, actions) => {
            for (var notification in notifications) {
                actions.pendNotification({idx: notification, obj: notifications[notification]});
            }
        },
        pendNotification: ({idx, obj}) => (state, actions) => {
            let notifs = null, locNotif = obj;
            let tmpSetTimeout = setTimeout(() => {
                clearTimeout(tmpSetTimeout);
                notifs = state.notifications;
                notifs.push(locNotif);
                actions.setNotifications(notifs);
            }, (+idx * 380));
        },
        timeBeforeRemovingNotification: (notifid) => (state, actions) => {
            let tmt = setTimeout(() => {
                clearTimeout(tmt);
                actions.removeNotification(notifid);
            }, 4000);
        },
        removeNotification: (notifid) => (state, actions) => {
            let notifications = state.notifications, foundNotification = null;

            for (let notification in notifications) {
                foundNotification = notifications[notification];
                if (foundNotification.id === notifid) {
                    notifications.splice(notifications.indexOf(foundNotification), 1);
                    break;
                }
            }
            actions.setNotifications(notifications);
        },
        setNotifications: newState => state => ({
            notifications: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <div>
            <div class='gamifnotifications-action'>
                {state.notifications.filter((f) => f.type === 'ACTION').map(item =>
                    <div class='' onclick={() => {actions.removeNotification(item.id)}} oncreate={() => {actions.timeBeforeRemovingNotification(item.id)}}>
                        <div class='btzNotifMessage btzNotifMessage-notActive' data-kind={item.type}>
                            <div class='btzNotiftextContent btzNotiftextContentPoints' style={{ 'background-image': 'url(' + bgStarsPointsx2 + ')' }}>{'+ '}<font>{ item.points }</font><br/>{' ' + t('points', {ns: 'generals'})}</div>
                            <div class='btzNotiftextContent' style={{ 'background-image': 'url(' + bgStarsPointsx2 + ')' }}>{item.message}</div>
                        </div>
                    </div>
                )}
            </div>
            <div class='gamifnotifications-achievment'>
                {state.notifications.filter((f) => f.type === 'ACHIEVEMENT').map(item =>
                    <div class='btzNotifMessage btzNotifMessage-notActive' data-kind={item.type} onclick={() => {actions.removeNotification(item.id)}} oncreate={() => {actions.timeBeforeRemovingNotification(item.id)}}>
                        <div class='btzNotifMediaWrapper' style={{ 'background-image': 'url(' + bgStarsMediax2 + ')' }}>
                            <img class='btzNotifMedia' src={((item.media !== null) && (item.media.url !== undefined)) ? item.media.url : null} alt='' />
                        </div>
                        <div class='btzNotiftextContent'>{item.message}</div>
                        <div class='btzNotiftextContent btzNotiftextContentPoints' style={{ 'background-image': 'url(' + bgStarsPointsx2 + ')' }}>{'+ '}<font>{ item.points }</font>{' ' + t('points', {ns: 'generals'})}</div>
                    </div>
                )}
            </div>
            <div class='gamifnotifications-level'>
                {state.notifications.filter((f) => f.type === 'LEVEL').map(item =>
                    <div>
                        <div class='btzNotifMessage btzNotifMessage-notActive' data-kind={item.type} onclick={() => {actions.removeNotification(item.id)}}>
                            <div class='btzModal-btzCloseModal' style='' onclick={() => actions.removeNotification(item.id)}>
                                <img src={imgClose} srcset={`${imgClosex2} 2x`} alt='' class='' />
                            </div>
                            <div class='btzTitle btzXl'>{t('Bravo', {ns: 'generals'}) + ' !'}</div>
                            <div class='btzNotiftextContent'>{t('Vous avez atteint le niveau suivant', {ns: 'generals'}) + ' !'}</div>
                            {((item.media) && (item.media !== null) && (item.media.url !== undefined)) ?
                                <div class='btzNotifMediaWrapper' style={{ 'background-image': 'url(' + starsLevelx2 + ')' }}>
                                    <img class='btzNotifMedia' src={item.media.url} srcset={`${item.media.url} 2x`} alt='' />
                                </div>
                                :
                                <div class='btzNotifMediaWrapper' style={{ 'background-image': 'url(' + starsLevelx2 + ')' }}>
                                    <img class='btzNotifMedia' src={bgFlowerLevel} srcset={`${bgFlowerLevelx2} 2x`} alt='' />
                                    <div class='btzNotiftextContent btzNotiftextContentPoints'>{item.level}</div>
                                </div>
                            }

                            <div class='btzNotifMediaFooter' style={{ 'padding-left': '40px', 'margin-bottom': '60px' }}>
                                <div class='btzNotifMediaFooters'>
                                    <p class='btzNotifMediaFooters-btzLabel'>{t('Points', {ns: 'generals'})}</p>
                                    <p class='btzNotifMediaFooters-btzValue'>{item.totalPoints}</p>
                                </div>
                                <div class='btzNotifMediaFooters' style={{ 'padding-right': '85px' }}>
                                    <p class='btzNotifMediaFooters-btzLabel'>{t('Badges', {ns: 'generals'})}</p>
                                    <p class='btzNotifMediaFooters-btzValue'>{item.badges}</p>
                                </div>
                            </div>

                            <Button primary active small onclick={() => {
                                window.location.href = addCSRFToken(allRoutes['account.rewards'].pathname);
                            }}>
                                {t('Rendez-vous sur la page Badges', {ns: 'generals'})}
                            </Button>
                        </div>
                        <div class='btzNotifMessage-btzNotifOverlay' onclick={() => {actions.removeNotification(item.id)}}></div>
                    </div>
                )}
            </div>
        </div>
    ),
    'gamifnotifications'
);

export { GamifNotifications };
