export const actions = {
    onComponentCreate: (props) => {
        if (props.customer && (props.customer === 'cerba')) {
            props.gotonext();
            return;
        }
        setTimeout(() => {
            props.gotonext();
        }, 3000);
    },
};
