export const state = {
    rendering: false,
    savedItem: null,
    detail: null,
    detailGetted: false,
    favorite: false,
    detailAliment: null,
    kindOfInfos: null,
    alimentDetailDisplayed: false,
    tagaliment: null,
    modalDisplayed: false,
};
