import { h, Component } from '@app/utils';
import { dynLoad } from '@app/core';

export const arrowImgLoad = dynLoad('arrow', 'VerticalScroll/custo/arrow', 'elements');
const arrowImgObj = arrowImgLoad.ARROWS;

import './index.scss';

const VerticalScroll = Component(
    {
        id: null,
        obj: null,
        controls: true,
        step: 1,
        refWidth: null,
        refChildWidth: null,
        refNumOfChildInOneWidth: 0,
        refTarget: null,
        refScrollObj: null,
        refScrollObjTranslater: null,
        refScrollValue: 1,
        leftControlsDisplayed: '',
        rightControlsDisplayed: 'active',
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            state.id = '_' + Math.random().toString(36).substr(2, 9);
            if (props.controls !== undefined) {
                state.controls = props.controls;
            }
            if (props.step !== undefined) {
                state.step = props.step;
            }
        },
        onScrollToTheRight: () => (state, actions) => {
            actions.onScrolling(true);
        },
        onScrollToTheLeft: () => (state, actions) => {
            actions.onScrolling(false);
        },
        onScrolling: (bool) => (state, actions) => {
            if ((!bool && (state.leftControlsDisplayed === '')) || (bool && (state.rightControlsDisplayed === ''))) {
                return false;
            }

            var newRefScrollValue = state.refScrollValue;
            if ((state.setScrollTarget === null) || (state.setScrollTarget === undefined)) {
                state.obj = document.getElementById(state.id);
                state.refScrollObj = state.obj.getElementsByClassName('btzVerticalScroll-scroller')[0];
                state.refScrollObjTranslater = state.obj.getElementsByClassName('btzVerticalScroll-translater')[0];
                state.refTarget = state.refScrollObjTranslater.children[0];
                if (state.refTarget === undefined) {
                    return false;
                }
                state.refWidth = state.refTarget.offsetWidth; // one width of scroller, the step scroll
                if (state.obj.getElementsByClassName('btzVerticalScroll-translater')[0].children[0] !== undefined) {
                    state.refChildWidth = state.obj.getElementsByClassName('btzVerticalScroll-translater')[0].children[0].offsetWidth;
                }
                state.refNumOfChildInOneWidth = Math.round(state.refWidth / state.refChildWidth)
            }
            if (state.obj.getElementsByClassName('btzVerticalScroll-translater')[0].children.length === 0) {
                return false;
            }
            let refMax = ((state.obj.getElementsByClassName('btzVerticalScroll-translater')[0].children.length - state.refNumOfChildInOneWidth) * state.refChildWidth);

            if (bool !== null) {
                let side = 1;
                if (!bool) {
                    side = -1;
                }

                newRefScrollValue += (state.refWidth * state.step * side);
            }

            let leftControlsDisplayed = 'active';
            let rightControlsDisplayed = 'active';

            let rightContainerOffset = (state.refScrollObj.offsetWidth - state.refWidth);
            if (newRefScrollValue <= 1) {
                leftControlsDisplayed = '';
                newRefScrollValue = 1;
            } else if (newRefScrollValue >= (refMax - rightContainerOffset)) {
                rightControlsDisplayed = '';
                newRefScrollValue = (refMax - rightContainerOffset);
            }

            actions.setLeftControlState(leftControlsDisplayed);
            actions.setRightControlState(rightControlsDisplayed);
            actions.setRefScrollValue(newRefScrollValue);
        },
        setLeftControlState: newState => state => ({
            leftControlsDisplayed: newState
        }),
        setRightControlState: newState => state => ({
            rightControlsDisplayed: newState
        }),
        setRefScrollValue: newState => state => ({
            refScrollValue: newState
        }),
    },
    (state, actions) => (props, children) => (
        <div id={state.id} key={state.id} class='btzVerticalScroll-nowrap'>
            {state.controls &&
                <div class={'btzVerticalScroll-btzVerticalControls btzVerticalScroll-btzVerticalControls-left ' + state.leftControlsDisplayed} onclick={actions.onScrollToTheLeft}>
                    <img src={arrowImgObj.arrow_left.normal} srcset={`${arrowImgObj.arrow_left.retina} 2x`} alt='' class='' />
                </div>
            }

            <div class='btzVerticalScroll-scroller' style={{ 'transform': 'translateX(-1px)', 'position': 'relative' }}>
                {/*<div class='btzVerticalScroll-translater' data-left={state.refScrollValue} style={{ 'transform': 'translateX(-' + state.refScrollValue + 'px)' }}>{children}</div>*/}
                <div class='btzVerticalScroll-translater' data-left={state.refScrollValue} style={{ 'margin-left': '-' + state.refScrollValue + 'px' }}>{children}</div>
            </div>

            {state.controls &&
                <div class={'btzVerticalScroll-btzVerticalControls btzVerticalScroll-btzVerticalControls-right ' + state.rightControlsDisplayed} onclick={actions.onScrollToTheRight}>
                    <img src={arrowImgObj.arrow_right.normal} srcset={`${arrowImgObj.arrow_right.retina} 2x`} alt='' class='' />
                </div>
            }
        </div>
    ),
    'verticalscroll'
);

export { VerticalScroll };
