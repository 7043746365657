import { t } from '@app/utils';

export const state = {
    userid: null,
    objectives: {
        all: [],
        user: [],
    },
    error: '',
    info: 'Cliquez sur un objectif pour le sélectionner/désélectionner.',
    i18nInfo: t('Cliquez sur un objectif pour le sélectionner/désélectionner.', {ns: 'public'}),
    toprocess: [],
    maxObjectives: 2,
    minObjectives: 1,
    modalDisplayed: null,
    customerSsoName: null,
    language: 'fr_FR',
};
