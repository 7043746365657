import { t, h } from '@app/utils';
import { Avatar, Balloon, Form, Tile } from '@app/elements';

import logoStepEntreprise from '@app/img/register/areyou-enterprise.png';

import logoStepMutuelle from '@app/img/register/areyou-mutual.png';

import logoStepMyselfMan from '@app/img/register/areyou-man-buste.png';

import logoStepMyselfWoman from '@app/img/register/areyou-woman-buste.png';

export const view = (state, actions) => (props, children) => (
    <div key={state.whopay.value} class={'whopay-' + state.whopay.value} style={{ 'position': 'relative' }}>
        <Tile>
            <Tile.Content>
                <p class='btzTitle btzXl' style={{ 'margin-bottom': '0', 'height': '115px' }}>{t('Qui prend en charge', {ns: 'register'})}<br />{t('votre accès au service ?', {ns: 'register'})}</p>
            </Tile.Content>
        </Tile>

        <Form onsubmit={event => event.preventDefault()}>

            <Form.Group style={{ 'text-align': 'center' }}>
                <Form.Radio
                    placeholder={t('Votre entreprise', {ns: 'register'})}
                    id='B2B-entreprise'
                    name='customer'
                    image={logoStepEntreprise}
                    checked={state.whopay.value === 'company'}
                    onclick={() => {
                        actions.onCompanyClick()
                        actions.updateParentProps(props)
                    }}
                    required
                />
                <Form.Radio
                    placeholder={t('Votre mutuelle', {ns: 'register'})}
                    id='B2B-mutuelle'
                    name='customer'
                    image={logoStepMutuelle}
                    checked={state.whopay.value === 'mutual'}
                    onclick={() => {
                        actions.onMutualClick()
                        actions.updateParentProps(props)
                    }}
                    required
                />
                <Form.Radio
                    placeholder={t('Vous-même', {ns: 'register'})}
                    id='B2C'
                    name='customer'
                    image={props.initial.gender === 1 ? logoStepMyselfMan : logoStepMyselfWoman}
                    checked={state.whopay.value === 'b2c'}
                    onclick={() => {
                        actions.onIndividualClick()
                        actions.updateParentProps(props)
                    }}
                    required
                />

                <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap' }}>
                    <Tile.Icon>
                        <Avatar.Alfred kind={'info'} classes={'centered'} />
                    </Tile.Icon>
                    <Tile.Content>
                        <Balloon kind={'info'} style={{ 'white-space': 'normal' }}>
                            <p style={{ 'text-align': 'left' }}>
                                <span class='btzBaloon-btzTitle'>{t('INSCRIPTION', {ns: 'register'})}</span>
                                <span> - 3/4</span>
                                <font>
                                    <br />{t('Sélectionnez votre situation.', {ns: 'register'})}
                                </font>
                            </p>
                        </Balloon>
                    </Tile.Content>
                </Tile>
            </Form.Group>
        </Form>

    </div>
);