import { t, h, Component } from '@app/utils';
import { dynLoad } from '@app/core';
import { Card, Button, Modal, TextTransform } from '@app/elements';
import './index.scss';

import logoArrow from '@app/img/trackers/btn-show-list.png';
import logoArrowx2 from '@app/img/trackers/btn-show-list@2x.png';

export const validImg = dynLoad('valid-img', 'Account/oc/tracker/custo');
const VALID_IMG = validImg.VALID_IMG;

const state = {
    brand: '',
    brandCapitalize: '',
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        if (props.brand) {
            state.brand = props.brand;
            state.brandCapitalize = props.brand.charAt(0).toUpperCase() + props.brand.slice(1);
        }
    },
    getDate: ({el, dat}) => {
        if (dat === null) {
            el.innerHTML = t('aucune synchronisation', {ns: 'oc'});
            return;
        }
        if ((dat.indexOf('Z') === -1) && (dat.indexOf('T') === -1)) {
            dat = dat.split(' ');
            dat = dat[0] + 'T' + dat[1] + 'Z';
        }
        var date = new Date(Date.parse(new Date(dat)));
        var options = { month: 'long', day: 'numeric', year: 'numeric' };
        el.innerHTML = (t('synchronisé le', {ns: 'oc'}) + ' ' + date.toLocaleDateString('fr-FR', options));
    },
};

const view = (state, actions) => (props, children) => (
    <Card kind={'ocBrand'} data-theme={props.valueTheme} classes={props.active + ' ' + props.kind}>
        {props.kind == 'brand' &&
            <div class='btzOc-btzOcBrand'>
                <Card.Header>
                    <Card.Image><img src={props.img} srcset={`${props.img} 2x`} alt='' class='' /></Card.Image>
                </Card.Header>

                <Card.Body>
                    <Card.Image>
                        <div onclick={() => props.selectTrackerList(props)}>
                            <img style={{ 'display': 'block', 'margin': 'auto' }} src={logoArrow} srcset={`${logoArrowx2} 2x`} alt='' class='' />
                        </div>
                    </Card.Image>
                </Card.Body>
            </div>
        }

        {props.kind == 'tracker' &&
            <div class='btzOc-btzOcTracker'>
                <Card.Header>
                    <Card.Image><img src={props.img} srcset={`${props.img} 2x`} alt='' class='' /></Card.Image>
                </Card.Header>

                <Card.Body>
                    <p class='btzPageContentTexte'>{props.name}</p>
                    <p class='btzPageContentSubTexte'><TextTransform mode={'capitalize'}>{props.brand}</TextTransform></p>

                    {(props.exclude.indexOf(props.brand) === -1) ?
                        <Button primary active onclick={() => {
                            props.connectTracker(props);
                        }}>
                            {t('Connecter', {ns: 'oc'})}
                        </Button>
                        :
                        <Button primary>
                            {t('Connecter', {ns: 'oc'})}
                        </Button>
                    }
                </Card.Body>
            </div>
        }

        {props.active == 'btzActive' &&
            <div>
                <div class='btzOc-btzTrackerActive'>
                    <img src={VALID_IMG.valid.normal} srcset={`${VALID_IMG.valid.retina} 2x`} alt='' class='' />
                </div>
                <div class='btzActiveOverlay'></div>
            </div>
        }

        {props.kind == 'conflict' &&
            <div class='btzOc-btzOcConflict' onclick={() => {
                props.selectTracker(props);
            }}>
                <Card.Header>
                    <Card.Image><img src={props.img} srcset={`${props.img} 2x`} alt='' class='' /></Card.Image>
                </Card.Header>

                <Card.Body>
                    <p class='btzPageContentTexte'>{props.name}</p>
                    <p class='btzPageContentSubTexte'><TextTransform mode={'capitalize'}>{props.brand}</TextTransform></p>
                </Card.Body>
            </div>
        }

        {props.kind == 'historique' &&
            <div class='btzOc-btzOcConflict' onclick={() => {
                props.selectHistorique(props);
            }}>
                <Card.Header>
                    <Card.Image><img src={props.img} srcset={`${props.img} 2x`} alt='' class='' /></Card.Image>
                </Card.Header>

                <Card.Body>
                    <p class='btzPageContentTexte'>{props.name}</p>
                    <p class='btzPageContentSubTexte'><TextTransform mode={'capitalize'}>{props.brand}</TextTransform></p>
                </Card.Body>
            </div>
        }

        {props.kind == 'connected' &&
            <div class='btzOc-btzOcConnected'>
                <div class='col-2 col-md-6 col-xs-12 p-0' style={{ 'vertical-align': 'middle' }}>
                    <Card.Image><img src={props.imgbrand} srcset={`${props.imgbrand} 2x`} alt='' class='' style={{ 'height': '22px' }} /></Card.Image>
                </div>

                <div class='col-2 col-md-6 col-xs-12 p-0' style={{ 'vertical-align': 'middle' }}>
                    <p class='btzPageContentTexte'>{props.name}</p>
                    <p class='btzPageContentSubTexte'><TextTransform mode={'capitalize'}>{props.brand}</TextTransform></p>
                </div>

                <div class='col-4 col-md-6 col-xs-12 btzPageContentSubTexte' style={{ 'vertical-align': 'middle' }} oncreate={(el) => actions.getDate({'el' : el, 'dat' : props.lastupdate}) }></div>

                <div class='col-4 col-md-6 col-xs-12 btzOc-btzRemoveTracker' style={{ 'vertical-align': 'middle' }}>
                    <Modal key={'modal-deco'} content={''} confirm={true} confirmConfirm={() => {
                        props.connectTracker(props);
                    }} title={'Déconnexion de votre objet connecté'} description={t('Vous êtes sur le point de déconnecter votre', {ns: 'oc'}) + ' ' + state.brandCapitalize + ' ' + props.name + '. ' + t('Votre historique sera conservé.\nConfirmez-vous vouloir déconnecter cet objet ?', {ns: 'oc'})}>
                        <Button primary dangerous>
                            {t('Déconnecter', {ns: 'oc'})}
                        </Button>
                    </Modal>
                </div>
            </div>
        }
    </Card>
);

export default Component(state, actions, view, 'oc');
