export const state = {
    blockedStep: 0,
    api: {
        message: '',
        code: 200,
    },
    values: {
        email: '',
    },
    isTouched: {
        email: false,
    },
    isValid: {
        email: false,
    },
    displayEmailInput: false,
    formIsSubmitting: false,
    emailInputDisplayed: false,
};
