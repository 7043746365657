import { h } from '@app/utils';
import { isDefined } from '@app/core';
import { Button, Card, Modal, Social } from '@app/elements';

import imgCitation from '@app/img/timeline/img-timeline-citation.png';
import imgCitationx2 from '@app/img/timeline/img-timeline-citation@2x.png';

import { ModalViewCitation } from './../modalView';

module.exports = {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <Card kind={'citation'} theme={props.valueTheme}>
                {props.displayedon == 'timeline' &&
                    <div>
                        <Card.Image kind={'image'}><img src={imgCitation} srcset={`${imgCitationx2} 2x`} alt='' class='' /></Card.Image>
                        <Card.Body classes='btzVerticalAlign btzDottedSpaced'>
                            <div class='btzWrapper-verticalAlignContent'>
                                <p class='btzTimeline-btzCard-btzDescription btzTexte'>{props.description}</p>
                                <Card.Header>
                                    <p class='btzTimeline-btzCard-btzTitle btzTitle'>{props.title}</p>
                                    <Social kindcard={'citation'} displayedon={'timeline'} title={props.description} style={{ 'position': 'relative' }} idfavorite={props.favoriteId} idtimeline={props.timeline} id={props.id} favorite={state.favorite} propagate={actions.updateParentProps}></Social>
                                </Card.Header>
                            </div>
                        </Card.Body>
                    </div>
                }

                {props.displayedon == 'favorite' &&
                    <Modal modalDisplayed={state.modalDisplayed} content={<ModalViewCitation {...props} favorite={state.favorite} propagate={props.propagate} displayedon={'favorite'} propagateConfirmClosing={() => actions.setModalDisplayed(false)}></ModalViewCitation>}>
                        <Button primary data-id={props.id} onclick={() => actions.setModalDisplayed(true)} >
                            <div>
                                <Card.Header>
                                    <Card.Image kind={'image'}><img src={imgCitation} srcset={`${imgCitationx2} 2x`} alt='' class='' /></Card.Image>
                                    <p class='btzTimeline-btzCard-btzTitle btzTitle'>{props.title}</p>
                                    <p class='btzTimeline-btzCard-btzSubTitle btzSubTitle btzTextColored' data-theme={props.valueTheme}>{props.labelTheme}</p>
                                    <p class='btzLightTexte' oncreate={(el) => actions.getDate({'el' : el, 'dat' : (isDefined(props.date) ? props.date.date : null)}) }></p>
                                </Card.Header>
                            </div>
                        </Button>
                    </Modal>
                }

                {props.displayedon == 'share' &&
                    <div>
                        <Card.Image kind={'image'} style={{ 'display': 'table', 'width': '100%', 'padding-top': '75px' }}>
                            <div style={{ 'display': 'table-cell', 'vertical-align': 'middle' }}>
                                <img src={imgCitation} srcset={`${imgCitationx2} 2x`} alt='' class='' />
                            </div>
                        </Card.Image>
                        <Card.Body classes='btzVerticalAlign btzCard-btzBody-btzModal btzDottedSpaced' style={{ 'padding-top': '75px' }}>
                            <div class='btzWrapper-verticalAlignContent'>
                                <p class='btzTimeline-btzCard-btzDescription btzTexte'>{props.description}</p>
                                <Card.Header classes={'btzCard-btzHeader-btzModal'}>
                                    <p class='btzTimeline-btzCard-btzTitle btzTitle' style={{ 'margin-top': '0px', 'padding-top': '0px', 'font-size': '22px' }}>{props.title}</p>
                                </Card.Header>
                            </div>
                        </Card.Body>
                    </div>
                }
            </Card>
        );
        return view;
    }
}
