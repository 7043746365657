export const state = {
    question: '',
    secret: {
        isTouched: false,
        isValid: false,
        value: '',
    },
    api: {
        message: '',
        kind: 'info'
    },
    rendering: false,
};
