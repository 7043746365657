import { addCSRFToken, isDefined } from '@app/core';
import { allRoutes } from '@app/settings';

const redirectToOnboarding = function(userid, date, timelineid = null) {
    if (!isDefined(userid)) {
        userid = 'null';
    }
    let viewed = localStorage.getItem('onboarding-viewed-' + userid + '-' + date);
    if (!isDefined(timelineid) && ((viewed === true) || (viewed === 'true'))) {
        window.location.href = addCSRFToken(allRoutes['private.timeline'].pathname);
    } else {
        window.location.href = addCSRFToken(allRoutes['private.onboarding'].pathname + (isDefined(timelineid) ? ('?timelineid=' + timelineid) : ''));
    }
}

export { redirectToOnboarding };