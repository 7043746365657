import { t, h, Component, debounce, isEmpty, isLength } from '@app/utils';
import { Button, Form, Tile, Avatar, Balloon } from '@app/elements';
import { trimSpecialChar, isDefined } from '@app/core';
import * as constants from '@app/constants';
import { Lunch } from '@app/api';

import imgDefault from '@app/img/food/deco-blank.png';
import imgDefaultx2 from '@app/img/food/deco-blank@2x.png';

import logoNotFound from '@app/img/food/deco-no-aliment-tosuggest.png';
import logoNotFoundx2 from '@app/img/food/deco-no-aliment-tosuggest@2x.png';

import logoNotResult from '@app/img/food/deco-search-aliment-notfound.png';
import logoNotResultx2 from '@app/img/food/deco-search-aliment-notfound@2x.png';

import logoNotResultLunch from '@app/img/food/deco-blank.png';
import logoNotResultLunchx2 from '@app/img/food/deco-blank@2x.png';

import icoResetSearch from '@app/img/ico/ico-reset-search.png';
import icoResetSearchx2 from '@app/img/ico/ico-reset-search@2x.png';

import icoValid from '@app/img/ico/ico-valid-circle-green.png';
import icoValidx2 from '@app/img/ico/ico-valid-circle-green@2x.png';

import icoNotValid from '@app/img/ico/ico-valid-not-triangle-red.png';

import blankAlcool from '@app/img/food/blank-alcool.png';
import blankAlcoolx2 from '@app/img/food/blank-alcool@2x.png';

import blankSugar from '@app/img/food/blank-sugar.png';
import blankSugarx2 from '@app/img/food/blank-sugar@2x.png';

import blankSalt from '@app/img/food/blank-salt.png';
import blankSaltx2 from '@app/img/food/blank-salt@2x.png';

import blankFat from '@app/img/food/blank-fat.png';
import blankFatx2 from '@app/img/food/blank-fat@2x.png';

import { ListingEntry } from './../step-listing-entry';
import { StepEnd } from './../step-end';

const StepListings = Component(
    {
        mealKind: null,
        mealContFeel: [],
        mealContEat: [],
        mealContKind: [],
        mealDate: null,
        mealName: '',
        mealUsual: false,
        displayed: '',
        compDisplayed: 'fat',
        search: '',
        searchQueried: false,
        suggest: [],
        habits: [],
        searchedAliments: [],
        existingLunchId: null,
        lunchAliments: [],
        lastStep: false,
        compositionsAliments: null,
        user: null,
        oneSendAtOnce: false,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if ((props.existingLunch) && (props.existingLunch.id !== undefined)) {
                actions.setExistingLunchId(props.existingLunch.id);
                actions.setMealUsual(props.existingLunch.usual);
                if (props.existingLunch.aliments !== null) {
                    actions.resetLunch();
                    if (props.existingLunch.aliments !== undefined) {
                        for (var i = 0, len = props.existingLunch.aliments.length; i < len; i++) {
                            actions.addOneAliment(props.existingLunch.aliments[i]);
                        }
                    }
                }
            } else {
                if ((props.resetLunchAliments) && (props.resetLunchAliments === true)) {
                    actions.resetLunch();
                } else if ((props.existingLunch !== null) && (props.existingLunch !== undefined)) {
                    actions.resetLunch();
                    if (props.existingLunch.aliments !== undefined) {
                        for (var j = 0, lenj = props.existingLunch.aliments.length; j < lenj; j++) {
                            actions.addOneAliment(props.existingLunch.aliments[j]);
                        }
                    }
                }
            }

            if (props.displayed) {
                actions.setDisplayed(props.displayed);
            }
            if (props.lastStep) {
                actions.displayLastStep(props.lastStep);
            }
            if (props.mealKind) {
                actions.setMealKind(props.mealKind);
            }
            if (props.mealContFeel) {
                actions.setMealContFeel(props.mealContFeel);
            } else {
                actions.setMealContFeel([]);
            }
            if (props.mealContEat) {
                actions.setMealContEat(props.mealContEat);
            } else {
                actions.setMealContEat([]);
            }
            if (props.mealContKind) {
                actions.setMealContKind(props.mealContKind);
            } else {
                actions.setMealContKind([]);
            }

            if (props.mealDate) {
                actions.setMealDate(props.mealDate);
            }
            if (props.mealUsual) {
                actions.setMealUsual(props.mealUsual);
            }
            if (props.mealName) {
                actions.setMealName(props.mealName);
            }
            if (props.compositionsAliments) {
                actions.setCompositionsAliments(props.compositionsAliments);
            }
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (props.displayed) {
                actions.setDisplayed(props.displayed);
            }
            if (props.lastStep) {
                actions.displayLastStep(props.lastStep);
            }
            if (props.mealKind) {
                actions.setMealKind(props.mealKind);
            }
            if (props.mealContFeel) {
                actions.setMealContFeel(props.mealContFeel);
            } else {
                actions.setMealContFeel([]);
            }
            if (props.mealContEat) {
                actions.setMealContEat(props.mealContEat);
            } else {
                actions.setMealContEat([]);
            }
            if (props.mealContKind) {
                actions.setMealContKind(props.mealContKind);
            } else {
                actions.setMealContKind([]);
            }
            if (props.mealDate) {
                actions.setMealDate(props.mealDate);
            }
            if (props.mealUsual) {
                actions.setMealUsual(props.mealUsual);
            }
            if (props.mealName) {
                actions.setMealName(props.mealName);
            }
            if (props.compositionsAliments) {
                actions.setCompositionsAliments(props.compositionsAliments);
            }

            // if ((props.existingLunch) && (props.existingLunch.id !== undefined)) {
            //     actions.setExistingLunchId(props.existingLunch.id);
            //     actions.setMealUsual(props.existingLunch.usual);
            //     if (props.existingLunch.aliments !== null) {
            //         actions.resetLunch();
            //         for (var i = 0, len = props.existingLunch.aliments.length; i < len; i++) {
            //             actions.addOneAliment(props.existingLunch.aliments[i]);
            //         }
            //     }
            // } else {
            //     if ((props.resetLunchAliments) && (props.resetLunchAliments === true)) {
            //         actions.resetLunch();
            //     } else if ((props.existingLunch !== null) && (props.existingLunch !== undefined)) {
            //         actions.resetLunch();
            //         for (var j = 0, lenj = props.existingLunch.aliments.length; j < lenj; j++) {
            //             actions.addOneAliment(props.existingLunch.aliments[j]);
            //         }
            //     }
            // }
        },
        getUsualLunchsByType: (mealKind) => (state, actions) => {
            if ((state.habits !== null) && (state.habits.length > 0)) {
                actions.setDisplayed('habits');
                return false;
            }
            Lunch.getUsualLunchsByType(mealKind, true, 0).then((res) => {
                if (res.data.length === 0) {
                    actions.setHabits([]);
                } else {
                    actions.setHabits(res.data);
                }
                actions.setDisplayed('habits');
            });
        },
        getSuggest: (mealKind) => (state, actions) => {
            if ((state.suggest !== null) && (state.suggest.length > 0)) {
                actions.setDisplayed('suggest');
                return false;
            }
            Lunch.getSuggest(mealKind).then((res) => {
                if (res.data.length === 0) {
                    actions.setSuggest(null);
                } else {
                    actions.setSuggest(res.data);
                }
                actions.setDisplayed('suggest');
            });
        },
        onAlimentsSearching: string => (state, actions) => {
            if (isEmpty(string) || isLength(string, { max: 2 })) {
                actions.setSearchQueried(false);
                actions.setSearchedAliments([]);
                return false;
            }

            actions.setSearchQueried(false);
            Lunch.searchAlimentsByFamilies((+state.mealKind === 4 ? trimSpecialChar(constants.custo.FAMILIES_DRINKS) : trimSpecialChar([...constants.custo.FAMILIES_ALIMENTS, ...constants.custo.FAMILIES_DRINKS])), string)
                .then(({ data }) => {
                    actions.setSearchedAliments(data);
                    actions.setSearchQueried(true);
                })
        },
        onAlimentsSearchingDebounce: (string) => (state, actions) => debounce(
            600,
            'onAlimentsSearching',
            string
        ),
        setDisplayed: newState => state => ({
            displayed: newState
        }),
        setDisplayedComp:  newState => state => ({
            compDisplayed: newState
        }),
        setSearchedAliments: newState => state => ({
            searchedAliments: newState
        }),
        setSuggest: newState => state => ({
            suggest: newState
        }),
        setHabits: newState => state => ({
            habits: newState
        }),
        setSearchValue: newState => state => ({
            search: newState
        }),
        displayLastStep: newState => state => ({
            lastStep: newState
        }),
        addOneAliment: (aliment) => (state, actions) => {
            let lunchAliments = state.lunchAliments;
            let existingAliment = lunchAliments.filter((f) => (((f.key !== undefined) && (f.key === aliment.key)) || ((f.id !== undefined) && (f.id === aliment.id))))[0];
            if (existingAliment !== undefined) {
                existingAliment.count++;
            } else {
                aliment.count = 1;
                lunchAliments.push(aliment);
            }
            actions.setLunchAliments(lunchAliments);
        },
        removeOneAliment: (aliment) => (state, actions) => {
            let lunchAliments = state.lunchAliments;
            let existingAliment = lunchAliments.filter((f) => (((f.key !== undefined) && (f.key === aliment.key)) || ((f.id !== undefined) && (f.id === aliment.id))))[0];
            if (existingAliment !== undefined) {
                existingAliment.count--;
                if (existingAliment.count < 0) {
                    existingAliment.count = 0;
                }
            }
            actions.setLunchAliments(lunchAliments);
        },
        setLunchAliments: newState => state => ({
            lunchAliments: newState,
        }),
        setHabitsToAddInLunch: (habits) => (state, actions) => {
            for (var habit in habits.aliments) {
                actions.addOneAliment(habits.aliments[habit]);
            }
        },
        sendLunch: () => (state, actions) => {
            if (state.oneSendAtOnce) {
                return false;
            }

            let alimentsId = [];
            // for (var aliment in state.lunchAliments) {
            //     alimentsId.push(state.lunchAliments[aliment].id);
            // }
            for (var aliment in state.lunchAliments) {
                for (var i = 0, len = state.lunchAliments[aliment].count; i < len; i++) {
                    alimentsId.push(state.lunchAliments[aliment].id);
                }
            }

            let data = {
                type: state.mealKind,
                name: state.mealName,
                comment: 'none',
                aliments: alimentsId,
                usual: state.mealUsual,
                date: actions.createDateObj(state.mealDate),
                contexts: [...state.mealContKind, ...state.mealContFeel,  ...state.mealContEat],
            }

            actions.setOneSendAtOnce(true);
            if (state.existingLunchId !== null) {
                Lunch.editLunch(data, state.existingLunchId).then((res) => {
                    actions.resetSearch();

                    var event = new CustomEvent('updateLunchListing');
                    window.dispatchEvent(event);
                    document.body.classList.remove('btzOverflowHidden');

                    var eventbis = new CustomEvent('editLunchSuccess');
                    window.dispatchEvent(eventbis);

                    var eventbisbis = new CustomEvent('setLastAddedId', {detail: {id: res.data.id}});
                    window.dispatchEvent(eventbisbis);

                    actions.setOneSendAtOnce(false);
                    actions.resetLunch();
                });
            } else {
                Lunch.sendLunch(data).then((res) => {
                    actions.resetSearch();

                    var event = new CustomEvent('updateLunchListing');
                    window.dispatchEvent(event);
                    document.body.classList.remove('btzOverflowHidden');

                    var eventbis = new CustomEvent('addLunchSuccess');
                    window.dispatchEvent(eventbis);

                    var eventbisbis = new CustomEvent('setLastAddedId', {detail: {id: res.data.id}});
                    window.dispatchEvent(eventbisbis);

                    actions.setOneSendAtOnce(false);
                    actions.resetLunch();
                });
            }
        },
        deleteLunch: (lunchid) => (state, actions) => {
            Lunch.deleteLunch(lunchid).then(() => {
                var event = new CustomEvent('updateLunchListing');
                window.dispatchEvent(event);
            });
        },
        removeLunchFromHabit: (lunchid) => (state, actions) => {
            let habits = state.habits;
            let parsedHabits = [];
            for (var habit in habits) {
                if (habits[habit].id !== lunchid) {
                    parsedHabits.push(habits[habit]);
                }
            }
            actions.setHabits(parsedHabits);
            document.body.classList.remove('btzOverflowHidden');
            let data = {
                usual: false,
            }

            Lunch.editLunch(data, lunchid).then(() => {
                var event = new CustomEvent('updateLunchListing');
                window.dispatchEvent(event);
            });
        },
        setSearchQueried: newState => state => ({
            searchQueried: newState,
        }),
        resetSearch: () => (state, actions) => {
            actions.setSearchQueried(false);
            actions.setSearchedAliments([]);
            actions.setSearchValue('');
        },
        resetLunch: () => (state, actions) => {
            actions.setLunchAliments([]);
            actions.setMealContFeel([]);
            actions.setMealContEat([]);
            actions.setMealContKind([]);
        },
        createDateObj: (date) => (state, actions) => {
            var parsedDate = {
                date: new Date(date.substring(0, 4) + '-' + date.substring(4, 6) + '-' + date.substring(6, 8)),
                timezone: 'UTC',
                timezone_type: 3
            }

            return parsedDate;
        },
        setMealKind: newState => state => ({
            mealKind: newState,
        }),
        addMealContFeel: (mFeel) => (state, actions) => {
            let existingContFeels = state.mealContFeel;
            if (existingContFeels.indexOf(mFeel) > -1) {
                existingContFeels.splice(existingContFeels.indexOf(mFeel), 1);
            } else {
                existingContFeels.push(mFeel);
            }
            actions.setMealContFeel(existingContFeels);
        },
        setMealContFeel: newState => state => ({
            mealContFeel: newState,
        }),
        addMealContEat: (mEat) => (state, actions) => {
            let existingContEats = state.mealContEat;
            if (existingContEats.indexOf(mEat) > -1) {
                existingContEats.splice(existingContEats.indexOf(mEat), 1);
            } else {
                existingContEats.push(mEat);
            }
            actions.setMealContEat(existingContEats);
        },
        setMealContEat: newState => state => ({
            mealContEat: newState,
        }),
        addMealContKind: (mKind) => (state, actions) => {
            let existingContKinds = state.mealContKind;
            if (existingContKinds.indexOf(mKind) > -1) {
                existingContKinds.splice(existingContKinds.indexOf(mKind), 1);
            } else {
                existingContKinds.push(mKind);
            }
            actions.setMealContKind(existingContKinds);
        },
        setMealContKind: newState => state => ({
            mealContKind: newState,
        }),
        setMealDate: newState => state => ({
            mealDate: newState,
        }),
        setMealUsual: newState => state => ({
            mealUsual: newState,
        }),
        setMealName: newState => state => ({
            mealName: newState,
        }),
        setExistingLunchId: newState => state => ({
            existingLunchId: newState,
        }),
        setCompositionsAliments: newState => state => ({
            compositionsAliments: newState,
        }),
        setOneSendAtOnce: newState => state => ({
            oneSendAtOnce: newState,
        })
    },
    (state, actions) => (props, children) => (
        <div key={'listingmode-' + state.editMealDate + '-' + state.editMealKind}>
            {((state.displayed === 'habits') || (state.displayed === 'suggest') || (state.lastStep === true)) ?
                <div class='btzData-btzFood-btzListing'>
                    {state.lastStep === false ?
                        <div>
                            <div class='btzModal-btzPaddingWrapper'>
                                <Form onsubmit={event => event.preventDefault()}>
                                    <Form.Group style={{ 'white-space': 'nowrap' }}>
                                        <Form.Input
                                            placeholder={t('Recherchez un aliment / une boisson', {ns: 'food'})}
                                            type='text'
                                            id='search'
                                            name='search'
                                            value={state.search}
                                            oninput={(event) => {
                                                actions.setSearchQueried(false);
                                                actions.setSearchValue(event.target.value);
                                                actions.onAlimentsSearchingDebounce(event.target.value);
                                            }}
                                        />
                                        {state.search.length > 0 &&
                                            <img src={icoResetSearch} srcset={`${icoResetSearchx2} 2x`} alt='' class='' style={{ 'margin': '-24px 6px 0 0', 'float': 'right', 'cursor': 'pointer', 'position': 'relative', 'z-index': 99 }} onclick={() => {actions.resetSearch()}} />
                                        }
                                    </Form.Group>
                                </Form>
                            </div>

                            {(isDefined(state.searchedAliments) && (state.searchedAliments.length === 0) && (state.search === '')) ?
                                <div oncreate={() => actions.getSuggest(state.mealKind)}>
                                    <div class='btzData-btzFood-btzListing-btzHeader'>
                                        <p style={{ 'width': '145px', 'line-height': '1.5' }} class={'btzData-btzFood-btzListing-btzHeader-btzEntry ' + (state.displayed === 'suggest' ? 'active' : '')} onclick={() => actions.getSuggest(state.mealKind)}>{state.mealKind === 4 ? t('Boissons suggérées', {ns: 'food'}) : t('Aliments suggérés', {ns: 'food'})}</p>
                                        {state.mealKind !== 4 &&
                                            <p style={{ 'width': '125px', 'line-height': '1.5' }} class={'btzData-btzFood-btzListing-btzHeader-btzEntry ' + (state.displayed === 'habits' ? 'active' : '')} onclick={() => actions.getUsualLunchsByType(state.mealKind)}>{t('Repas habituels', {ns: 'food'})}</p>
                                        }
                                    </div>

                                    <div class='btzData-btzFood-btzListing-btzListings btzModal-btzPaddingWrapper' style={{ 'height': 'calc(100vh - 405px)', 'overflow-y': 'auto' }}>
                                        <div class={'btzData-btzFood-btzListing-btzListing ' + (state.displayed === 'suggest' ? 'active' : '')} data-listing={'suggest'}>
                                            {((state.suggest !== null) && (state.suggest.length > 0) && (state.lunchAliments !== null)) ?
                                                <div>
                                                    {state.suggest.map(item =>
                                                        <ListingEntry key={'suggest-listing-' + state.lunchAliments.filter((f) => f.count > 0).length} id={item.id} key={item.id} {...item} lunchAliment={state.lunchAliments.filter((lunch) => lunch.id === item.id)} addOneAliment={actions.addOneAliment} removeOneAliment={actions.removeOneAliment} showAddButton={true}></ListingEntry>
                                                    )}
                                                    {/*state.habitsToAdd.aliments !== undefined &&
                                                        <div>
                                                            {state.habitsToAdd.aliments.map(item =>
                                                                <ListingEntry id={item.id} key={item.id} {...item} lunchAliment={state.lunchAliments.filter((lunch) => lunch.id === item.id)} addOneAliment={actions.addOneAliment} removeOneAliment={actions.removeOneAliment}></ListingEntry>
                                                            )}
                                                        </div>
                                                    */}
                                                </div>
                                                :
                                                <div class='btzData-btzFood-btzListing-btzListing-btzNotFound'>
                                                    <div class='btzTitle-btzImage'>
                                                        <img data-customer={props.customer} src={logoNotFound} srcset={`${logoNotFoundx2} 2x`} alt='' style={{ 'height': '116px', 'width': '116px' }} />
                                                    </div>

                                                    {state.mealKind === 4 ?
                                                        <p class='btzPageContentTexte' style={{ 'font-size': '14px' }}>{t('Nous n’avons aucune boisson à vous suggérer sur ce type de repas pour le moment.', {ns: 'food'})}</p>
                                                        :
                                                        <p class='btzPageContentTexte' style={{ 'font-size': '14px' }}>{t('Nous n’avons aucun aliment à vous suggérer sur ce type de repas pour le moment.', {ns: 'food'})}</p>
                                                    }
                                                    {state.mealKind === 4 ?
                                                        <p class='btzPageContentSubTexte'>{t('N’hésitez pas à utiliser la recherche pour ajouter des boissons.', {ns: 'food'})}</p>
                                                        :
                                                        <p class='btzPageContentSubTexte'>{t('N’hésitez pas à utiliser la recherche pour ajouter des aliments.', {ns: 'food'})}</p>
                                                    }
                                                </div>
                                            }
                                        </div>

                                        <div class={'btzData-btzFood-btzListing-btzListing ' + (state.displayed === 'habits' ? 'active' : '')} data-listing={'habits'}>
                                            {((state.habits !== null) && (state.habits.length > 0)) ?
                                                <div>
                                                    {state.habits.map(item =>
                                                        <div>
                                                            <ListingEntry id={item.id} key={item.id} {...item} lunchAliment={((state.lunchAliments !== null) ? state.lunchAliments.filter((lunch) => lunch.id === item.id) : [])} listingKind={'habits'} deleteLunch={actions.deleteLunch} removeLunchFromHabit={actions.removeLunchFromHabit} setHabitsToAddInLunch={() => actions.setHabitsToAddInLunch(item)} showAddButton={true}></ListingEntry>
                                                        </div>
                                                    )}
                                                </div>
                                                :
                                                <div class='btzData-btzFood-btzListing-btzListing-btzNotFound'>
                                                    <div class='btzTitle-btzImage'>
                                                        <img data-customer={props.customer} src={logoNotResultLunch} srcset={`${logoNotResultLunchx2} 2x`} alt='' class='' style={{ 'height': '116px', 'width': '116px' }} />
                                                    </div>
                                                    <p class='btzPageContentTexte' style={{ 'font-size': '14px' }}>
                                                        {t('Vous n’avez aucun repas habituel enregistré sur ce type de repas.', {ns: 'food'})}
                                                    </p>
                                                    <p class='btzPageContentSubTexte'>
                                                        {t('Créez un premier repas puis enregistrez le comme « habituel » avant de valider.', {ns: 'food'})}
                                                    </p>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                :
                                <div class='btzModal-btzPaddingWrapper' style={{ 'height': 'calc(100vh - 360px)', 'overflow-y': 'auto' }}>
                                    {((state.search !== '') && (state.searchedAliments.length === 0) && (state.searchQueried === true)) ?
                                        <div class='btzData-btzFood-btzListing-btzListing-btzNotFound'>
                                            <div class='btzTitle-btzImage'>
                                                <img data-customer={props.customer} src={logoNotResult} srcset={`${logoNotResultx2} 2x`} alt='' class='' />
                                            </div>
                                            <p class='btzPageContentTexte' style={{ 'font-size' : '14px', 'margin-bottom': '10px' }}>
                                                {t('Oups, malgré beaucoup d’effort de notre côté, aucun résultat n’a été trouvé.', {ns: 'food'})}
                                            </p>
                                            <p class='btzPageContentSubTexte'>
                                                {t('Pouvez-vous réessayer ?', {ns: 'food'})}
                                            </p>
                                        </div>
                                        :
                                        <div>
                                            {Array.isArray(state.searchedAliments) &&
                                                <div key={'listing-' + state.searchedAliments.length + '-' + state.search}>
                                                    {state.searchedAliments.map(item =>
                                                        <ListingEntry id={item.id} key={item.id} {...item} lunchAliment={state.lunchAliments.filter((lunch) => lunch.id === item.id)} addOneAliment={actions.addOneAliment} removeOneAliment={actions.removeOneAliment} showAddButton={true}></ListingEntry>
                                                    )}
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            }

                            {state.mealKind === 4 ?
                                <div class='btzFood-btzModal-btzFooter'>
                                    <Button flat active={state.lunchAliments.filter((f) => f.count > 0)[0] !== undefined} onclick={() => {
                                        if (state.lunchAliments.filter((f) => f.count > 0)[0] === undefined) {
                                            // choose at least on aliment
                                        } else {
                                            actions.sendLunch();
                                        }
                                    }}>
                                        <p key={'next-' + state.lunchAliments.filter((f) => f.count > 0).length}>{t('Valider', {ns: 'generals'}) + ' (' + state.lunchAliments.filter((f) => f.count > 0).length + ')'}</p>
                                    </Button>
                                </div>
                                :
                                <div class='btzFood-btzModal-btzFooter btzFood-btzModal-btzFooter-btzFloatRight' style={{ 'text-align': 'right', 'height': '60px' }}>
                                    {state.lunchAliments !== null &&
                                        <div>
                                            <div class='btzFood-btzModal-btzFooter-btzRecap'>
                                                {state.lunchAliments.filter((item, idx) => idx < 6).map(item =>
                                                    <div class='btzFood-btzModal-btzFooter-btzRecap-btzImg'>
                                                        {((item.media === null) || (item.media === undefined)) ?
                                                            <img src={imgDefault} srcset={`${imgDefaultx2} 2x`} alt='' class='' />
                                                            :
                                                            <img src={item.media.url} srcset={`${item.media.url} 2x`} alt='' class='' />
                                                        }
                                                    </div>
                                                )}
                                                {state.lunchAliments.filter((f) => f.count > 0).length > 6 &&
                                                    <p class='btzFood-btzModal-btzFooter-btzRecap-btzSixMore btzPageContentSubTexte'>{'+' + (state.lunchAliments.length - 6)}</p>
                                                }
                                                {state.lunchAliments.filter((f) => f.count > 0).length === 0 &&
                                                    <p class='btzPageContentSubTexte' style={{ 'padding': '4px 0', 'margin': '10px 0 0' }}>{t('aucun aliment ajouté', {ns: 'food'})}</p>
                                                }
                                            </div>
                                            <Button primary flat active={state.lunchAliments.filter((f) => f.count > 0)[0] !== undefined} onclick={() => {
                                                if (state.lunchAliments.filter((f) => f.count > 0)[0] === undefined) {
                                                    // choose at least on aliment
                                                } else {
                                                    props.displayLastStep(true);
                                                }
                                            }}>
                                                <p key={'next-' + state.lunchAliments.filter((f) => f.count > 0).length}>{t('Suivant', {ns: 'generals'}) + ' (' + state.lunchAliments.filter((f) => f.count > 0).length + ')'}</p>
                                            </Button>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                        :
                        <StepEnd updateUsualState={actions.setMealUsual} mealUsual={state.mealUsual} lunchAliments={state.lunchAliments} deleteLunch={actions.deleteLunch} removeLunchFromHabit={actions.removeLunchFromHabit} sendLunch={actions.sendLunch} displayLastStep={actions.displayLastStep} addOneAliment={actions.addOneAliment} removeOneAliment={actions.removeOneAliment} setMealName={actions.setMealName} {...state} mealContFeels={props.mealContFeels} mealContEats={props.mealContEats} mealContKinds={props.mealContKinds} mealContexts={props.mealContexts} mealCont={state.mealCont} addMealContFeel={actions.addMealContFeel} addMealContEat={actions.addMealContEat} addMealContKind={actions.addMealContKind} gender={props.userGender} editing={(props.editing || false)}></StepEnd>
                    }
                </div>
                :
                <div>
                    <div class='btzModal-btzPaddingWrapper'>
                        <p class='btzTitle btzXl btzUglyOverride' style={{ 'text-transform': 'uppercase' }}>{props.readableMealDate}</p>
                        <p class='col-12 btzLightTexte' style={{ 'text-align': 'left', 'font-size': '13px', 'padding-left': '0px' }}>
                            {state.compDisplayed === 'fat' &&
                                <span>{t('Un aliment est considéré comme gras s’il contient : plus de 10% de lipides et 4% d’AG saturés', {ns: 'food'})}</span>
                            }
                            {state.compDisplayed === 'sugar' &&
                                <span>{t('Un aliment est considéré comme sucré s’il contient : plus de 15g de sucre pour 100g', {ns: 'food'})}</span>
                            }
                            {state.compDisplayed === 'salt' &&
                                <span>{t('Un aliment est considéré comme salé s’il contient : plus de 1g de sel pour 100g', {ns: 'food'})}</span>
                            }
                            {state.compDisplayed === 'alcool' &&
                                <span>{t('Quel que soit le type d’alcool, un verre standard contient entre 10 et 13g d’alcool', {ns: 'food'})}</span>
                            }
                        </p>
                    </div>

                    <div class='btzData-btzFood-btzListing' style={{ 'margin-top': '10px' }}>
                        <div class='btzData-btzFood-btzListing-btzHeader'>
                            {((props.surveyLunchs !== null) && (props.surveyLunchs !== undefined)) &&
                                <div style={{ 'text-align': 'left' }}>
                                    <p style={{ 'width': '70px', 'line-height': '1.5' }} class={'btzData-btzFood-btzListing-btzHeader-btzEntry ' + (state.compDisplayed === 'fat' ? 'active' : '')} onclick={() => actions.setDisplayedComp('fat')}>
                                        {t('Gras', {ns: 'food'})}
                                        {props.surveyLunchs.saturated.percent >= 50 ?
                                            <img src={icoNotValid} srcset={`${icoNotValid} 2x`} alt='' class='' />
                                            :
                                            <img src={icoValid} srcset={`${icoValidx2} 2x`} alt='' class='' />
                                        }
                                    </p>
                                    <p style={{ 'width': '80px', 'line-height': '1.5' }} class={'btzData-btzFood-btzListing-btzHeader-btzEntry ' + (state.compDisplayed === 'sugar' ? 'active' : '')} onclick={() => actions.setDisplayedComp('sugar')}>
                                        {t('Sucrés', {ns: 'food'})}
                                        {props.surveyLunchs.sugar.percent >= 50 ?
                                            <img src={icoNotValid} srcset={`${icoNotValid} 2x`} alt='' class='' />
                                            :
                                            <img src={icoValid} srcset={`${icoValidx2} 2x`} alt='' class='' />
                                        }
                                    </p>
                                    <p style={{ 'width': '70px', 'line-height': '1.5' }} class={'btzData-btzFood-btzListing-btzHeader-btzEntry ' + (state.compDisplayed === 'salt' ? 'active' : '')} onclick={() => actions.setDisplayedComp('salt')}>
                                        {t('Salés', {ns: 'food'})}
                                        {props.surveyLunchs.salt.percent >= 50 ?
                                            <img src={icoNotValid} srcset={`${icoNotValid} 2x`} alt='' class='' />
                                            :
                                            <img src={icoValid} srcset={`${icoValidx2} 2x`} alt='' class='' />
                                        }
                                    </p>
                                    <p style={{ 'width': '80px', 'line-height': '1.5' }} class={'btzData-btzFood-btzListing-btzHeader-btzEntry ' + (state.compDisplayed === 'alcool' ? 'active' : '')} onclick={() => actions.setDisplayedComp('alcool')}>
                                        {t('Alcool', {ns: 'food'})}
                                        {props.surveyLunchs.alcool.percent >= 50 ?
                                            <img src={icoNotValid} srcset={`${icoNotValid} 2x`} alt='' class='' />
                                            :
                                            <img src={icoValid} srcset={`${icoValidx2} 2x`} alt='' class='' />
                                        }
                                    </p>
                                </div>
                            }
                        </div>

                        {state.compositionsAliments !== null &&
                            <div class='btzData-btzFood-btzListing-btzListings btzData-btzFood-btzListing-btzListings-btzViewAliments btzModal-btzPaddingWrapper'>
                                <div class={'btzData-btzFood-btzListing-btzListing ' + (state.compDisplayed === 'fat' ? 'active' : '')} data-listing={'fat'}>
                                    {((state.compositionsAliments.fat !== null) && (state.compositionsAliments.fat.length > 0)) ?
                                        <div>
                                            {state.compositionsAliments.fat.map(item =>
                                                <ListingEntry key={item.id} {...item} listingKind={'composition'} composition={'fat'}></ListingEntry>
                                            )}
                                        </div>
                                        :
                                        <div class='btzData-btzFood-btzListing-btzListing-btzBlank'>
                                            <img data-customer={props.customer} src={blankFat} srcset={`${blankFatx2} 2x`} alt='' class='' />
                                            <p class='btzPageContentTexte'>{t('Vous n’avez consommé aucun aliment gras aujourd’hui !', {ns: 'food'})}</p>
                                        </div>
                                    }
                                </div>

                                <div class={'btzData-btzFood-btzListing-btzListing ' + (state.compDisplayed === 'sugar' ? 'active' : '')} data-listing={'sugar'}>
                                    {((state.compositionsAliments.sugar !== null) && (state.compositionsAliments.sugar.length > 0)) ?
                                        <div>
                                            {state.compositionsAliments.sugar.map(item =>
                                                <ListingEntry key={item.id} {...item} listingKind={'composition'} composition={'sugar'}></ListingEntry>
                                            )}
                                        </div>
                                        :
                                        <div class='btzData-btzFood-btzListing-btzListing-btzBlank'>
                                            <img data-customer={props.customer} src={blankSugar} srcset={`${blankSugarx2} 2x`} alt='' class='' />
                                            <p class='btzPageContentTexte'>{t('Vous n’avez consommé aucun aliment sucré aujourd’hui !', {ns: 'food'})}</p>
                                        </div>
                                    }
                                </div>

                                <div class={'btzData-btzFood-btzListing-btzListing ' + (state.compDisplayed === 'salt' ? 'active' : '')} data-listing={'salt'}>
                                    {((state.compositionsAliments.salt !== null) && (state.compositionsAliments.salt.length > 0)) ?
                                        <div>
                                            {state.compositionsAliments.salt.map(item =>
                                                <ListingEntry key={item.id} {...item} listingKind={'composition'} composition={'salt'}></ListingEntry>
                                            )}
                                        </div>
                                        :
                                        <div class='btzData-btzFood-btzListing-btzListing-btzBlank'>
                                            <img data-customer={props.customer} src={blankSalt} srcset={`${blankSaltx2} 2x`} alt='' class='' />
                                            <p class='btzPageContentTexte'>{t('Vous n’avez consommé aucun aliment salé aujourd’hui !', {ns: 'food'})}</p>
                                        </div>
                                    }
                                </div>

                                <div class={'btzData-btzFood-btzListing-btzListing ' + (state.compDisplayed === 'alcool' ? 'active' : '')} data-listing={'alcool'}>
                                    {((state.compositionsAliments.alcool !== null) && (state.compositionsAliments.alcool.length > 0)) ?
                                        <div>
                                            {state.compositionsAliments.alcool.map(item =>
                                                <ListingEntry key={item.id} {...item} listingKind={'composition'} composition={'alcool'}></ListingEntry>
                                            )}
                                        </div>
                                        :
                                        <div class='btzData-btzFood-btzListing-btzListing-btzBlank'>
                                            <img data-customer={props.customer} src={blankAlcool} srcset={`${blankAlcoolx2} 2x`} alt='' class='' />
                                            <p class='btzPageContentTexte'>{t('Vous n’avez consommé aucune boisson alcoolisée aujourd’hui !', {ns: 'food'})}</p>
                                        </div>
                                    }
                                </div>

                                <Tile classes={'btzAlfred'} style={{ 'white-space': 'nowrap', 'bottom': '0', 'padding': '15px 30px 30px', 'background-color': 'white' }}>
                                    <Tile.Icon classes={''}>
                                        <Avatar.Alfred classes={'centered'} />
                                    </Tile.Icon>
                                    <Tile.Content>
                                        <Balloon kind={'info'} style={{ 'white-space': 'normal' }}>
                                            {state.compDisplayed === 'fat' &&
                                                <span>{t('Il est recommandé de ne pas dépasser le seuil de 12% de vos apports en Acides Gras saturés.', {ns: 'food'})}</span>
                                            }
                                            {state.compDisplayed === 'sugar' &&
                                                <span>{t('Il est recommandé de ne pas dépasser le seuil de 100g de sucre par jour, ou 20% de vos apports.', {ns: 'food'})}</span>
                                            }
                                            {state.compDisplayed === 'salt' &&
                                                <span>
                                                    {props.userGender === true ?
                                                        <font>{t('Étant un homme, il est recommandé de ne pas dépasser le seuil de 8g de sel par jour.', {ns: 'food'})}</font>
                                                        :
                                                        <font>{t('Étant une femme, il est recommandé de ne pas dépasser le seuil de 6.5g de sel par jour.', {ns: 'food'})}</font>
                                                    }
                                                </span>
                                            }
                                            {state.compDisplayed === 'alcool' &&
                                                <span>
                                                    <font>{t('Il est recommandé de ne pas dépasser le seuil de 2 verres d’alcool par jour.', {ns: 'food'})}</font>
                                                </span>
                                            }
                                        </Balloon>
                                    </Tile.Content>
                                </Tile>
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    ),
    'addmeallisting'
);

export { StepListings };
