import { t, h, Component } from '@app/utils';
import './index.scss';

const state = {
    //
}

const actions = {
    onComponentCreate: () => (state, actions) => {
        //
    },
};

const view = (state, actions) => (props, children) => (
    <div key={props.page} class={props.page} data-theme={props.theme}>
        <div class='btzSpecificPage'>
            <div class='btzSpecificPage-btzWrapper' style={{ 'text-align': 'left' }}>
                <img src={`${process.env.APP_WEB_URL}/__custo/${props.customer}/logo-textual.png`} srcset={`${process.env.APP_WEB_URL}/__custo/${props.customer}/logo-textual@2x.png`} alt='' />
                <p class=''>
                    {t('KLESIA est un des acteurs majeurs de la protection sociale proposant des offres innovantes en matière de santé, de prévoyance et de retraite.')}
                </p>
                <p class=''>
                    {t('Le groupe s’engage dans la mise en œuvre d’actions de prévention au service de la santé de l’ensemble de ses clients.')}
                </p>
                <p class=''>
                    {t('Une raison d’être et une mission : Etre assureur d’intérêt général, c’est garantir un avenir serein et contribuer à la qualité de vie pour tous.')}
                </p>
                <p class=''>
                    {t('Jeunes ou moins jeunes, actifs ou retraités, valides ou en situation de handicap, pharmaciens ou restaurateurs, transporteurs ou fleuristes…, KLESIA prend soin de tous ceux qui prennent soin des autres.')}
                </p>
            </div>
        </div>
    </div>
);

export default Component(state, actions, view, 'what_is_klesia_netflix');
