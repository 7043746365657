import { t, h } from '@app/utils';
import { Button } from '@app/elements';

import icoBalance from '@app/img/follow/balance.png';
import icoBalancex2 from '@app/img/follow/balance@2x.png';

import icoDownload from '@app/img/ico/ico-download.png';
import icoDownloadx2 from '@app/img/ico/ico-download@2x.png';

export const view = (state, actions) => (props, children) => (
    <div>
        <div class='btzFollow-btzFollowEntry btzFollow-btzFollowEntryNutritional btzTopCustomDotted btzTopCustomDotted2px'>
            <div class='col-3' style={{ 'vertical-align': 'middle', 'padding-right': '30px', 'padding-left': '0px' }}>
                <div class='btzRoundedImage'><img src={icoBalance} srcset={`${icoBalancex2} 2x`} alt='' class='' /></div>
            </div>
            <div class='col-9 p-0' style={{ 'vertical-align': 'middle', 'text-align': 'left' }}>
                <p class='btzPageContentSubTexte btzXl'>{t('Bilan consultation', {ns: 'follow'})}</p>
                <p class='btzTimeline-btzCard-btzSubTitle btzPageContentSubTitle' oncreate={(el) => actions.getDate({'el' : el, 'dat' : props.date}) }></p>
                {props.status === 'COMPLETED' &&
                    <Button primary active flat small style={{ 'margin': '30px 0' }} onclick={() => {
                        props.getAppointmentsById(props.id);
                    }}>
                        <img src={icoDownload} srcset={`${icoDownloadx2} 2x`} alt='' class='' />
                        <p>{t('Mon compte rendu', {ns: 'follow'})}</p>
                    </Button>
                }
            </div>
        </div>
    </div>
);