const convertMetrics = function(from, type, metric, value) {
    if ((+metric === 0) || (value === null)) {
        if (from === 'metric') {
            return value;
        }
        return value + type;
    }

    metric = +metric;
    if (from === 'weight') {
        if (metric === 1) {
            let stone = Math.floor(value / 6.35029);
            let remainingPound = Math.fmod(value, 6.35029);
            let pound = (remainingPound / 0.4536);

            value = stone + '.' + Math.floor(pound);
        } else {
            value = (value * 2.2046);
        }
    } else if ((from === 'height') || (from === 'abdo')) {
        if ((metric === 1) || (metric === 2)) {
            let feet = Math.floor(value / 30.48);
            let remainingInch = Math.fmod(value, 30.48);
            let inch = (remainingInch / 2.54);

            value = feet + '.' + Math.floor(inch);
        } else {
            value = Math.round(value, 1);
        }
    } else if (from === 'metric') {
        if (type === 'weight') {
            if (metric === 1) {
                let values = String(value).split('.');
                let stone = values[0];
                let pound = 0;
                if (values.length > 1) {
                    pound = values[1];
                }
                stone = +stone * 6.35029;
                pound = +pound * 0.4536;
                value = stone + pound;
            } else {
                value = (value / 2.2046);
            }
            type = '';
        } else if ((type === 'height') || (type === 'abdo')) {
            if ((metric === 1) || (metric === 2)) {
                let values = String(value).split('.');
                let feet = values[0];
                let inch = 0;
                if (values.length > 1) {
                    inch = values[1];
                }
                feet = +feet * 30.48;
                inch = +inch * 2.54;
                value = feet + inch;
            } else {

            }
            type = '';
        }
    }

    if (from != 'metric') {
        type = convertMetricsLabels((+type.length <= 2) ? true : false, from, metric, type);
        return (Math.round(value * 10) / 10) + type;
    } else {
        return (Math.round(value * 10) / 10);
    }

}

const convertMetricsLabels = function(short, from, metric, value) {
    if (+metric === 0) {
        return value;
    }

    let toreplace = null;
    if (short) {
        if (from === 'weight') {
            toreplace = (+metric === 1) ? 'st' : 'lb';
            value = value.replace('kg', toreplace);
        } else {
            toreplace = (+metric === 1) ? 'ft' : 'Ø';
            value = value.replace('cm', toreplace);
        }
    } else {
        if (from === 'weight') {
            toreplace = (+metric === 1) ? 'stone' : 'pound';
            value = value.replace('kilogramme', toreplace);
        } else {
            toreplace = (+metric === 1) ? 'feet' : 'Ø';
            value = value.replace('centimètre', toreplace);
        }
    }

    return value;
}

export { convertMetrics, convertMetricsLabels };